import { SecondaryButton } from '../../../../modules/common/components/Button'
import { convertCentsToLocaleUsd } from '../../../../utils/conversion'
import EyeIcon from '../../common/components/EyeIcon'
import { PayoutStatus } from '../enums/PayoutStatus'
import { PayoutDetails } from '../http/types/PayoutDetails'
import DateTimeFormatted from '../../../../modules/dateTime/components/DateTimeFormatted'
import PayoutStatusPill from '../../../../modules/payout/components/PayoutStatusPill'

export interface PayoutDetailsTableProps {
    payoutDetailsData: PayoutDetails[]
    onViewButtonClicked?: (payoutId: string) => void
    onMarkAsPaidButtonClicked?: (payoutId: string) => void
}
const PayoutDetailsTable = ({
    payoutDetailsData,
    onViewButtonClicked,
    onMarkAsPaidButtonClicked,
}: PayoutDetailsTableProps) => {
    return (
        <div
            className="mt-3 mb-2 border border-gray-200 rounded-lg overflow-hidden shadow-md shadow-black"
            style={{
                boxShadow:
                    '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
            }}
        >
            <p className="text-xs font-semibold my-5 pl-4">{payoutDetailsData[0].tenant_name}</p>
            <p className="text-center text-xs font-semibold mb-4">Payout information</p>
            <table className="w-full">
                <thead className="w-full">
                    <tr className="h-11 flex items-center w-full bg-gray-50">
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            PAYOUT ID
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            TENANT ID
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            TIME OF PAYOUT CREATION
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            STATUS
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            TOTAL AMOUNT DUE
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            ACTIONS
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {payoutDetailsData.length > 0 &&
                        payoutDetailsData.map((payoutDetailsItem, id) => (
                            <tr className="h-18 flex items-center w-full" key={id}>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    {payoutDetailsItem.id}
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    {payoutDetailsItem.tenant_id}
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    <DateTimeFormatted
                                        date={new Date(payoutDetailsItem.created_at)}
                                    />
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal ">
                                    <PayoutStatusPill status={payoutDetailsItem.status} />
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal ">
                                    {convertCentsToLocaleUsd(
                                        BigInt(payoutDetailsItem.total_amount_due),
                                    )}
                                </td>
                                <td className="flex-1 flex-row items-center justify-start text-gray-500 text-sm text-left pl-6 font-normal ">
                                    {!!onViewButtonClicked && (
                                        <SecondaryButton
                                            className="mt-1 px-2.5 py-1 flex items-center justify-between gap-1"
                                            onClick={() =>
                                                onViewButtonClicked(payoutDetailsItem.id)
                                            }
                                        >
                                            <EyeIcon />
                                            View Payout
                                        </SecondaryButton>
                                    )}
                                    {!!onMarkAsPaidButtonClicked &&
                                        payoutDetailsItem.status === PayoutStatus.UNPAID && (
                                            <SecondaryButton
                                                className="mt-1 px-2.5 py-1 flex items-center justify-between gap-1"
                                                onClick={() => {
                                                    void onMarkAsPaidButtonClicked(
                                                        payoutDetailsItem.id,
                                                    )
                                                }}
                                            >
                                                <img src="/assets/icon_payout.svg" alt="payout" />
                                                Mark Payout As Paid
                                            </SecondaryButton>
                                        )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    )
}

export default PayoutDetailsTable
