import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { PrimaryButton } from '../../../../modules/common/components/Button'

export enum UploadFileTypes {
    PDF = 'application/pdf',
    JPEG = 'image/jpeg',
    PNG = 'image/png',
}

interface UploadFileFormProps {
    onFileSubmitted: (file: File) => void
    onClose: () => void
    message: string
    fileTypes: UploadFileTypes[]
}

const UploadFileForm = ({ onFileSubmitted, onClose, message, fileTypes }: UploadFileFormProps) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const [error, setError] = useState<string>('')
    const fileInputRef = useRef<HTMLInputElement>(null)

    const getImageTypeName = (uploadFileType: UploadFileTypes) => {
        switch (uploadFileType) {
            case UploadFileTypes.PDF:
                return 'PDF'
            case UploadFileTypes.JPEG:
                return 'JPEG'
            case UploadFileTypes.PNG:
                return 'PNG'
            default:
                throw `File Type Not Supported`
        }
    }

    const defineErrorMessage = (fileTypes: UploadFileTypes[]): string => {
        return fileTypes.reduce(
            (acc, currentValue, currentIndex) =>
                `${
                    acc +
                    `${currentIndex !== 0 ? ', or ' : ' '}` +
                    getImageTypeName(currentValue) +
                    ' file.'
                }`,
            'Please select a',
        )
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null
        if (file && fileTypes.includes(file.type as UploadFileTypes)) {
            setSelectedFile(file)
            setError('')
        } else {
            setError(defineErrorMessage(fileTypes))
        }
    }

    const handleFormSubmit = (event: FormEvent) => {
        event.preventDefault()

        if (!selectedFile) {
            setError('No file selected!')
            return
        }
        onFileSubmitted(selectedFile)
        setSelectedFile(null)
        if (fileInputRef.current) {
            fileInputRef.current.value = ''
        }
    }

    useEffect(() => {
        return () => {
            if (fileInputRef.current) {
                fileInputRef.current.value = ''
            }
        }
    }, [])

    return (
        <div className="flex items-center justify-center h-[200px] bg-gray-100">
            <form
                onSubmit={handleFormSubmit}
                className="bg-white p-6 rounded shadow-md w-full h-full"
            >
                <h2 className="text-lg font-semibold text-gray-700 mb-2">{message}</h2>
                <input
                    type="file"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                />
                <div className="h-5">
                    <p className="text-red-400">{error}</p>
                </div>
                <div className="flex flex-row justify-end gap-4">
                    <button
                        onClick={onClose}
                        type="button"
                        className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                    >
                        Close
                    </button>
                    <PrimaryButton type="submit" disabled={!selectedFile}>
                        Confirm
                    </PrimaryButton>
                </div>
            </form>
        </div>
    )
}

export default UploadFileForm
