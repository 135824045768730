import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Page } from '../../../modules/common/enums/Pages'
import { useRegistryStore } from '../../../modules/registry/context/store'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import CreateProjectForm from '../../modules/projects/components/CreateProjectForm'

const AdminCreateProject = (): JSX.Element => {
    const { fetchRegistries } = useRegistryStore()
    const navigate = useNavigate()

    useEffect(() => {
        fetchRegistries()
    }, [])

    const onProjectSavedHandler = async (): Promise<void> => {
        navigate(Page.ADMIN_PROJECTS)
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.PROJECTS}>
            <CreateProjectForm onProjectSaved={onProjectSavedHandler} />
        </AdminLayout>
    )
}

export default AdminCreateProject
