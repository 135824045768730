import { ToastMessageText } from '../enums/ToastMessageText'
import { ToastMessageType } from '../enums/ToastMessageType'

export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE'

export type ToastMessageAction = SetToastMessageAction

export interface SetToastMessageAction {
    type: typeof SET_TOAST_MESSAGE
    payload: SetToastMessagePayload
}

export interface SetToastMessagePayload {
    message: ToastMessage | null
}

export interface ToastMessage {
    text: ToastMessageText | string
    type: ToastMessageType
}
