import { getDecimalSeparator } from './getDecimalSeparator'
import { getThousandSeparator } from './getThousandSeparator'

/**
 * Converting from cents to USD, returning a stringified version of the format $12.30
 */

export const convertCentsToLocaleUsd = (number: bigint | number | string): string => {
    if (Number.isNaN(Number(number)))
        return Number(0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    if (typeof number === 'number' || typeof number === 'string') {
        number = BigInt(number)
    }
    if (number.toString().length < 4) {
        return (Number(number) / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        })
    }
    const dollars = number / BigInt(100)
    const cents = (number % BigInt(100)).toString()
    if (cents.length === 1) {
        return dollars
            .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            .slice(0, -2)
            .concat(`0${cents}`)
    }
    return dollars
        .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        .slice(0, -2)
        .concat(cents)
}

/**
 * Converting from cents to USD, returning a stringified version of the format 12.30
 */

export const convertCentsToUsd = (number: string | number): string => {
    const dollars = BigInt(number) / BigInt(100)
    const cents = (BigInt(number) % BigInt(100)).toString()

    return dollars.toString().concat('.', cents)
}

/**
 * Converting from USD to cents, returning number
 */

export const convertUsdToCents = (dollars: number | string): number =>
    Math.round(100 * Number(dollars))

/**
 * Converting from grams to tonnes, returning commmified string: 1,000,000 grams = 1 metric tonne
 */

export function convertGramsToMetricTonesLocaleString(
    grams: number | string,
    locale: string = 'en-US',
): string {
    if (typeof grams === 'string') grams = Number(grams)
    const metricTons = grams / 1_000_000 // 1 metric ton = 1,000,000 grams
    return metricTons.toLocaleString(locale, { maximumFractionDigits: 6 })
}

/**
 * Converting from grams to tonnes, returning a number
 */
export function convertGramsToMetricTonnes(grams: number | string): number {
    return Number(grams) / 1_000_000 // 1 metric ton = 1,000,000 grams
}

/**
 * Converting from tonnes to grams, returning a number
 */
export const convertMetricTonesToGramsNumeric = (value: number): number => {
    return Number(value) * 1_000_000
}

/**
 * Converting the tonnage as a commmified string
 */
export function convertTonnesToLocaleString(metricTons: number, locale: string = 'en-US'): string {
    return metricTons.toLocaleString(locale, { maximumFractionDigits: 6 })
}

// export function convertGramsToTonnesLocaleString(grams: number, locale: string = 'en-US'): string {
//     return convertTonnesToLocaleString(Math.floor(convertGramsToMetricTonnes(grams)), locale)
// }

// export const convertGramsToMetricTonesNumber = (value: string): number => {
//     return Number(value) / 1000000 // 1 metric ton = 1,000,000 grams
// }

/**
 * Converting a commified string into a number
 */
export const convertLocaleNumericStringToNumber = (numberString: string): number => {
    const numberStringWithReplacedThousandSeparator = numberString
        .split(getThousandSeparator())
        .join('')
    if (getDecimalSeparator() === '.') {
        return Number(parseFloat(numberStringWithReplacedThousandSeparator).toFixed(2))
    }
    const decimalsSeparatorRegex = new RegExp(getDecimalSeparator(), 'g')
    return parseFloat(
        numberStringWithReplacedThousandSeparator.split(decimalsSeparatorRegex).join('.'),
    )
}

export const bigIntToLocale = (value: bigint | number): string =>
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
