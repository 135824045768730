import { Pagination, ToggleSwitch } from 'flowbite-react'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { User } from '../../../../modules/user/types/User'
import { useUserStore } from '../../../../modules/user/context/store'
import LoadingSpinner from '../../../../modules/loadingSpinner/components/LoadingSpinner'
import ConfirmationModal from '../../common/components/ConfirmationModal'
import { AdminUserApi } from '../http/AdminUserApi'
import { Page } from '../../../../modules/common/enums/Pages'
import { SearchTenantsResponse } from '../../tenant/http/types/SearchTenantsResponse'
import { generatePathWithQueryString } from '../http/utils/generatePathWithQueryString'
import { locationNameFromValue } from '../../../../modules/common/enums/Locations'
import Roles from './Roles'
import ManageRolesModal from './ManageRolesModal'
import ApiKeysModal from './ApiKeysModal'

interface UsersTableInterface {
    searchTenantsResponse: SearchTenantsResponse | null
    onPageChangeHandler: (pageNumber: number) => void
    onRoleUpdated: () => void
}

interface ToggleIsUserActiveProps {
    user: User
    onChange: () => void
}

const ToggleIsUserActive = ({ user, onChange }: ToggleIsUserActiveProps): JSX.Element | null => {
    const [isToggleDisabled, setIsToggleDisabled] = useState<boolean>(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)

    const setIsUserActiveHandler = async (): Promise<void> => {
        if (isToggleDisabled) return
        setIsToggleDisabled(true)
        await AdminUserApi.setIsUserActive(user.id, !user.is_active)
        onChange()
        setIsToggleDisabled(false)
        setShowConfirmationModal(false)
    }

    const { user: userFromStore } = useUserStore()
    // disable the toggle for the authenticated user in order to prevent disabling itself
    if (userFromStore!.id === user.id) return null
    return (
        <div className="relative flex items-center justify-left min-w-[120px]">
            <ToggleSwitch
                checked={user.is_active}
                label={user.is_active ? 'Active' : 'Inactive'}
                onChange={() => setShowConfirmationModal(true)}
            />
            <div className="absolute">{isToggleDisabled && <LoadingSpinner />}</div>
            <ConfirmationModal
                show={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={setIsUserActiveHandler}
            />
        </div>
    )
}

interface ToggleMfaProps {
    user: User
    onChange: () => void
}

const ToggleMfa = ({ user, onChange }: ToggleMfaProps): JSX.Element | null => {
    const [isToggleDisabled, setIsToggleDisabled] = useState<boolean>(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)

    const setIsMfaEnabledHandler = async (): Promise<void> => {
        await AdminUserApi.setIsTwoFactorAuthetnicationEnabled(user.id, !user.is_mfa_enabled)
        onChange()
        setIsToggleDisabled(false)
        setShowConfirmationModal(false)
    }

    // disable the toggle for the authenticated user in order to prevent disabling itself
    return (
        <div className="relative flex items-center justify-left min-w-[120px]">
            <ToggleSwitch
                checked={user.is_mfa_enabled}
                label={user.is_mfa_enabled ? 'Enabled' : 'Disabled'}
                onChange={() => setShowConfirmationModal(true)}
            />
            <div className="absolute">{isToggleDisabled && <LoadingSpinner />}</div>
            <ConfirmationModal
                show={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={setIsMfaEnabledHandler}
            />
        </div>
    )
}

const UsersTable = ({
    searchTenantsResponse,
    onPageChangeHandler,
    onRoleUpdated,
}: UsersTableInterface): JSX.Element => {
    const [showManageRolesModalForUser, setShowManageRolesModalForUser] = useState<User | null>(
        null,
    )
    const [showManageApiKeysModalForUser, setShowManageApiKeysModalForUser] = useState<User | null>(
        null,
    )

    return (
        <div className="h-[95%]">
            <div className="h-[95%] mt-2 shadow-md sm:rounded-lg">
                <table className="w-full mx-auto overflow-x-scroll break-normal">
                    <thead className="h-12 items-center w-[100%] bg-gray-100 rounded-md">
                        <tr className="text-gray-500 font-semibold text-xs text-center">
                            <th className="">TENANT ID</th>
                            <th className="">ENTITY NAME</th>
                            <th className="">TENANT TYPE</th>
                            <th className="">TAX ID</th>
                            <th className="">LOCATION</th>
                            <th className="">TX FEE %</th>
                            <th className="">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody className="gap-y-3">
                        {searchTenantsResponse?.results.map((tenant) => (
                            <Fragment key={tenant.id}>
                                <tr className="h-12 items-center bg-gray-300 rounded-t-lg mt-3 text-gray-500 text-sm text-center">
                                    <td>{tenant.id}</td>
                                    <td>{tenant.entity_name ?? '-'}</td>
                                    <td className="">{tenant.type}</td>
                                    <td>{tenant.tax_id ?? '-'}</td>
                                    <td>{locationNameFromValue(tenant.location)}</td>
                                    <td>
                                        {(
                                            Number(tenant.platform_fee_percentage || 0) / 100
                                        ).toFixed(2)}
                                        %
                                    </td>
                                    <td>
                                        <Link
                                            to={generatePathWithQueryString(
                                                Page.ADMIN_EDIT_TENANT,
                                                {
                                                    id: tenant.id,
                                                },
                                            )}
                                            className="w-6 h-6 cursor-pointer flex mx-auto"
                                            title="Edit Tenant"
                                        >
                                            <img
                                                src="/assets/icon_pencil_alt.svg"
                                                alt="icon_trash"
                                            />
                                        </Link>
                                    </td>
                                </tr>
                                <tr className="items-center w-full p-3" key={`user-${tenant.id}`}>
                                    <td className="w-full" colSpan={7}>
                                        <table className="w-full border border-gray-50 sell-orders-table overflow-x-scroll break-normal bg-gray-200 rounded-lg">
                                            <thead>
                                                <tr className="h-11 items-center w-full bg-gray-50">
                                                    <th className="text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                        ID
                                                    </th>
                                                    <th className="text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                        NAME
                                                    </th>
                                                    <th className="text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                        EMAIL
                                                    </th>
                                                    <th className="text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                        IS ACTIVE
                                                    </th>
                                                    <th className="text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                        CREATED AT
                                                    </th>
                                                    <th className="text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                        API KEYS
                                                    </th>
                                                    <th className="text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                        ROLES
                                                    </th>
                                                    <th className="text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                        MFA ENABLED
                                                    </th>
                                                    <th className="text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                        ACTIONS
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tenant.users?.map((user) => (
                                                    <tr
                                                        className="h-18 items-center w-full bg-gray-100 rounded-md mt-1"
                                                        key={Math.random().toString()}
                                                    >
                                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                                            {user.id}
                                                        </td>
                                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                                            {user.first_name} {user.last_name}
                                                        </td>
                                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                                            {user.email}
                                                        </td>
                                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                                            <ToggleIsUserActive
                                                                user={user}
                                                                onChange={() =>
                                                                    // refresh table
                                                                    onPageChangeHandler(
                                                                        searchTenantsResponse
                                                                            ?.pagination
                                                                            .current_page ?? 1,
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                        {/* eslint-disable indent */}
                                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                                            {user.created_at
                                                                ? new Date(
                                                                      user.created_at,
                                                                  ).toLocaleDateString()
                                                                : ''}
                                                        </td>
                                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                                            <button
                                                                className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px]"
                                                                onClick={() =>
                                                                    setShowManageApiKeysModalForUser(
                                                                        user,
                                                                    )
                                                                }
                                                            >
                                                                Manage
                                                            </button>
                                                        </td>
                                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                                            <div className="flex flex-row">
                                                                <Roles user={user} />
                                                                <button
                                                                    onClick={() => {
                                                                        setShowManageRolesModalForUser(
                                                                            user,
                                                                        )
                                                                    }}
                                                                    title="Edit Roles"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth={1.5}
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
                                                                        />
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                                            <ToggleMfa
                                                                user={user}
                                                                onChange={() =>
                                                                    onPageChangeHandler(
                                                                        searchTenantsResponse
                                                                            ?.pagination
                                                                            .current_page ?? 1,
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                                            <Link
                                                                to={generatePathWithQueryString(
                                                                    Page.ADMIN_EDIT_USER,
                                                                    { id: user.id },
                                                                )}
                                                                className="w-6 h-6 cursor-pointer flex mx-auto"
                                                                title="Edit User"
                                                            >
                                                                <img
                                                                    src="/assets/icon_pencil_alt.svg"
                                                                    alt="icon_trash"
                                                                />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr className="h-12 items-center bg-gray-300 rounded-lg">
                                    <td colSpan={7}></td>
                                </tr>
                                <tr className="h-12 items-center bg-white rounded-b-lg mb-3">
                                    <td colSpan={7}></td>
                                </tr>
                            </Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-center mt-4 h-[5%]">
                {searchTenantsResponse?.pagination &&
                    searchTenantsResponse?.pagination.total_items >
                        searchTenantsResponse?.pagination.per_page && (
                        <Pagination
                            currentPage={searchTenantsResponse.pagination.current_page}
                            totalPages={searchTenantsResponse.pagination.total_pages}
                            onPageChange={onPageChangeHandler}
                        />
                    )}
            </div>
            <ManageRolesModal
                user={showManageRolesModalForUser}
                onUpdate={async () => {
                    onRoleUpdated()
                    setShowManageRolesModalForUser(null)
                }}
                onClose={() => setShowManageRolesModalForUser(null)}
            />
            <ApiKeysModal
                show={!!showManageApiKeysModalForUser}
                user={showManageApiKeysModalForUser!}
                onClose={() => setShowManageApiKeysModalForUser(null)}
            />
        </div>
    )
}

export default UsersTable
