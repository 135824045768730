/* eslint-disable indent */
import { AdminAction, AlertMessage, SET_ALERT_MESSAGE } from './types'

export interface AdminStoreState {
    alertMessage: AlertMessage | null
}

export const initialAdminStoreState: AdminStoreState = {
    alertMessage: null,
}

export const adminReducer = (
    state = initialAdminStoreState,
    action: AdminAction,
): AdminStoreState => {
    switch (action.type) {
        case SET_ALERT_MESSAGE:
            return {
                ...state,
                alertMessage: action.payload.alertMessage,
            }
        default:
            return state
    }
}
