import { useState } from 'react'
import BaseModal from '../../modal/components/BaseModal'
import { PrimaryButton, SecondaryButton } from '../../common/components/Button'
import * as Sentry from '@sentry/react'
import { InvoiceDto } from '../http/types/InvoiceDto'
import { InvoiceApi } from '../http/InvoiceApi'
import ModalButtonContainer from '../../modal/components/ModalButtonContainer'

interface Props {
    show: boolean
    invoice: InvoiceDto
    onClose?: (success: boolean) => any
}

export const PayInvoiceModal = ({ show, invoice, onClose = () => {} }: Props): JSX.Element => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [enableButtons, setEnableButtons] = useState<boolean>(true)

    const closeInvoice = async (): Promise<void> => {
        try {
            setEnableButtons(false)
            setError(null)
            setShowSpinner(true)
            await InvoiceApi.close(invoice.id)
            setEnableButtons(true)
            setShowSpinner(false)
            onClose(true)
        } catch (err) {
            setEnableButtons(true)
            setError(
                'There was a problem closing your invoice. Please refresh the page and try again.',
            )
            setShowSpinner(false)
            Sentry.captureException(err)
        }
    }

    const close = (): any => {
        setShowSpinner(false)
        setError(null)
        onClose(false)
    }

    if (!show) {
        // exit early
        return <></>
    }

    return (
        <BaseModal
            show={show}
            title={`Would you like to pay this invoice?`}
            id="cancel-buy-order-modal"
            position="center"
            onClose={close}
            error={error}
            showSpinner={showSpinner}
            bottomComponent={
                <ModalButtonContainer>
                    <SecondaryButton onClick={close} disabled={!enableButtons}>
                        Close
                    </SecondaryButton>
                    <PrimaryButton onClick={closeInvoice} disabled={!enableButtons}>
                        Pay Invoice
                    </PrimaryButton>
                </ModalButtonContainer>
            }
        >
            <p>We'll send you an email with details on how to settle.</p>
            <p className="mt-4">
                You may continue to make purchases, these will be added to a new invoice.
            </p>
        </BaseModal>
    )
}

export default PayInvoiceModal
