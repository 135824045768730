import { FeatureFlagName } from '../enums/FeatureFlagName'
import { FeatureFlagApi } from '../http/FeatureFlagApi'
import { FeatureFlag } from '../types/FeatureFlag'
import { setFeatureFlagsAction } from './actions'
import { initialFeatureFlagsState, FeatureFlagsReducer } from './reducer'
import { createContext, useContext, useReducer } from 'react'

export const FeatureFlagStore = createContext(initialFeatureFlagsState)

export const useFeatureFlagStore = (): any => useContext(FeatureFlagStore)

export const FeatureFlagStoreProvider = ({ children }: { children: any }): any => {
    const [state, dispatch] = useReducer(FeatureFlagsReducer, initialFeatureFlagsState)

    const setFeatureFlags = (response: FeatureFlag[]): void => {
        setFeatureFlagsAction(response, dispatch)
    }

    const fetchFeatureFlags = async (): Promise<void> => {
        const response = await FeatureFlagApi.getAll()
        if (response) {
            setFeatureFlags(response.data)
        }
    }

    const isFeatureFlagEnabled = (name: FeatureFlagName | string): boolean => {
        const featureFlag = state.featureFlags.find((ff) => ff.name === name)
        return featureFlag !== undefined && featureFlag.enabled_system_wide
    }

    return (
        <FeatureFlagStore.Provider
            value={{
                featureFlags: state.featureFlags,
                setFeatureFlags,
                fetchFeatureFlags,
                isFeatureFlagEnabled,
            }}
        >
            {children}
        </FeatureFlagStore.Provider>
    )
}
