import { useEffect, useState } from 'react'
import { RetireeDto } from '../http/types/RetirementRequestDto'
import { useUserStore } from '../../user/context/store'

export interface Props {
    retirees: RetireeDto[]
}

const RetireeName = ({ retirees }: Props): JSX.Element => {
    const [retireeName, setRetireeName] = useState<string>()
    const [classNames, setClassNames] = useState<string>()
    const { user } = useUserStore()

    useEffect(() => {
        if (retirees.length === 0) {
            setRetireeName(
                user?.tenant?.entity_name ? user?.tenant?.entity_name : 'Retired in your name',
            )
        } else if (retirees.length === 1) {
            setRetireeName(retirees[0].name)
        } else {
            setRetireeName('multiple')
            setClassNames('italic')
        }
    }, retirees)

    return <span className={classNames}>{retireeName}</span>
}

export default RetireeName
