import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorResponse } from '../../../../modules/http/types/ErrorResponse'
import { useAdminStore } from '../../../../modules/admin/context/store'
import { Page } from '../../../../modules/common/enums/Pages'
import { generatePathWithQueryString } from '../../user/http/utils/generatePathWithQueryString'
import { PayoutApi } from '../http/api'
import { PayoutDetails } from '../http/types/PayoutDetails'
import PayoutDetailsTable from './PayoutDetailsTable'
import PayoutTradesTable from './PayoutTradesTable'

const PayoutDetailsOverview = () => {
    const navigate = useNavigate()
    const { payoutId } = useParams<{ payoutId: string }>()
    const [payoutDetails, setPayoutDetails] = useState<PayoutDetails | null>(null)
    const { setShowAlertMessage } = useAdminStore()

    const fetchPayoutDetails = async () => {
        if (payoutId) {
            const response = await PayoutApi.getPayoutDetails(payoutId)
            if (response.data) {
                setPayoutDetails(response.data)
            }
        }
    }

    useEffect(() => {
        void fetchPayoutDetails()
    }, [payoutId])

    const onMarkAsPaidButtonClickedHandler = async (payoutId: string) => {
        try {
            const response = await PayoutApi.setPayoutAsPaid(payoutId)
            if (response?.status === 200) {
                setShowAlertMessage({
                    text: `Payout with id ${payoutId} was successfully marked as PAID.`,
                    color: 'success',
                })
                navigate(
                    generatePathWithQueryString(Page.ADMIN_SELLER_PAYOUTS, {
                        sellerTenantId: payoutDetails?.tenant_id,
                    }),
                )
            }
        } catch (e: any) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.status && err.response?.status < 500) {
                setShowAlertMessage({
                    text: err.response?.data?.message,
                    color: 'failure',
                })
            } else {
                throw e
            }
        }
    }

    return (
        <div className="flex flex-col h-full">
            <div>
                {!!payoutDetails && (
                    <PayoutDetailsTable
                        payoutDetailsData={[payoutDetails]}
                        onMarkAsPaidButtonClicked={onMarkAsPaidButtonClickedHandler}
                    />
                )}
            </div>
            <div className="overflow-auto">
                {!!payoutDetails?.trades && payoutDetails?.trades?.length > 0 && (
                    <div>
                        <p className="text-center text-xs font-semibold">
                            Sales included in this payout
                        </p>
                        <PayoutTradesTable trades={payoutDetails?.trades ?? []} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default PayoutDetailsOverview
