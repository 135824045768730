import {
    convertCentsToLocaleUsd,
    convertGramsToMetricTonesLocaleString,
} from '../../../utils/conversion'
import { ModerateDangerButton, PrimaryButton } from '../../common/components/Button'
import { SellOrderStatus } from '../../sellOrders/enums/SelOrderStatus'
import { SellOrder } from '../../sellOrders/types/SellOrder'
import { useUserStore } from '../../user/context/store'

interface ModifySellOrderFormProps {
    sellOrder: SellOrder | null
    onCancel: () => void
    onEdit: () => void
}

const ModifySellOrderForm = ({
    sellOrder,
    onCancel,
    onEdit,
}: ModifySellOrderFormProps): JSX.Element => {
    const { user } = useUserStore()
    if (
        !sellOrder ||
        sellOrder.tenant_id !== user?.tenant_id ||
        sellOrder.status !== SellOrderStatus.LIVE
    )
        return <></>
    return (
        <div
            className="min-w-96 pt-8 border border-gray-200 rounded-2xl buy-credits-form h-full"
            role="root"
        >
            <div className="px-8 pb-3 border-b border-gray-200" data-testid="header">
                <h3 className="mb-4 text-2xl font-bold text-gray-700">Your Sell Order</h3>
                <div className="flex gap-1 h-12">
                    {sellOrder?.price && (
                        <p
                            className="text-sm text-gray-500 font-semibold items-center flex flex-row"
                            data-testid="price-label"
                        >
                            <span className="text-3xl text-gray-500 font-medium leading-[38px]">
                                {convertCentsToLocaleUsd(BigInt(sellOrder?.price))}
                            </span>
                            <span className="pl-2 text-sm text-gray-500 font-semibold flex-1 break-normal">
                                price per credit before platform fee
                            </span>
                        </p>
                    )}
                </div>
                <div>
                    <p
                        className="text-sm text-primary-700 font-semibold"
                        data-testid="quantity-label"
                    >
                        Quantity:{' '}
                        {typeof sellOrder?.quantity === 'string' &&
                            convertGramsToMetricTonesLocaleString(Number(sellOrder?.quantity))}
                    </p>
                </div>
            </div>
            <div className="flex h-30 items-center justify-center px-4 gap-3">
                <PrimaryButton
                    type="submit"
                    className="w-full"
                    onClick={onEdit}
                    data-testid="edit-button"
                >
                    <span className="flex justify-center items-center gap-3">
                        <img src="/assets/icon_edit_white.svg" alt="icon_edit" />
                        Edit
                    </span>
                </PrimaryButton>
                <ModerateDangerButton
                    className="w-full"
                    onClick={onCancel}
                    data-testid="cancel-button"
                >
                    Cancel Order
                </ModerateDangerButton>
            </div>
        </div>
    )
}

export default ModifySellOrderForm
