import React from 'react'

interface StatsProps {
    title: string
    timeString?: string
    amount: string
    unitLabel?: string
    trend?: 'up' | 'down'
    trendPercentage?: number
    subStatAmount?: string
    subStatUnitLabel?: string
    children?: React.ReactNode
    additionalClassNames?: string
}

const UpArrow = (): JSX.Element => {
    return (
        <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="arrow-up">
                <path
                    id="Icon"
                    d="M6.33337 9.5V2.5M6.33337 2.5L2.83337 6M6.33337 2.5L9.83337 6"
                    stroke="#12B76A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    )
}

const DownArrow = (): JSX.Element => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="arrow-down">
                <path
                    id="Icon"
                    d="M6.00003 2.5V9.5M6.00003 9.5L9.50003 6M6.00003 9.5L2.50003 6"
                    stroke="#F04438"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    )
}

const Badge = ({
    trend,
    trendPercentage,
}: {
    trend: 'up' | 'down'
    trendPercentage: number
}): JSX.Element => {
    return (
        <div
            className={`flex flex-row items-center gap-1 pl-2.5 pr-2 py-0.5 rounded-2xl ${
                trend === 'up' ? 'bg-success-50' : 'bg-error-50'
            }`}
        >
            {trend === 'up' ? <UpArrow /> : <DownArrow />}
            <p
                className={`text-sm ${
                    trend === 'up' ? 'text-success-700' : 'text-error-700'
                }  font-semibold`}
            >
                {trendPercentage}%
            </p>
        </div>
    )
}

const Stats = ({
    title,
    timeString,
    amount,
    trend,
    trendPercentage,
    unitLabel,
    subStatAmount,
    subStatUnitLabel,
    children,
    additionalClassNames = '',
}: StatsProps): JSX.Element => {
    return (
        <div
            className={`w-full flex flex-col rounded-lg border border-gray-200 p-6 ${additionalClassNames}`}
            style={{
                boxShadow:
                    '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}
        >
            <div className="flex flex-row justify-between mb-2">
                <p className="text-sm text-gray-500 font-semibold">{title}</p>
                {timeString ? (
                    <p className="text-xs text-gray-400 font-semibold">{timeString}</p>
                ) : null}
            </div>
            <div className="flex flex-row justify-between items-center">
                <p className="text-3xl leading-[38px] text-gray-900 font-semibold">
                    {amount}
                    <span className="text-sm text-gray-500 font-semibold">{unitLabel}</span>
                    {subStatAmount ? (
                        <span className="text-2xl text-gray-500 font-semibold">{' + '}</span>
                    ) : (
                        <> </>
                    )}
                    <span className="text-xl text-gray-600 font-semibold">{subStatAmount}</span>
                    <span className="text-xs text-gray-500 font-semibold">{subStatUnitLabel}</span>
                </p>
                <>
                    {trend && trendPercentage ? (
                        <Badge trend={trend} trendPercentage={trendPercentage} />
                    ) : null}
                </>
            </div>
            <div>{children}</div>
        </div>
    )
}

export default Stats
