// @ts-nocheck
import { setAlertMessage } from './actions'
import { adminReducer, initialAdminStoreState } from './reducer'
import { AlertMessage } from './types'
import { createContext, useContext, useEffect, useReducer } from 'react'

export const AdminStore = createContext(initialAdminStoreState)

export const useAdminStore = (): PageBannerContext => useContext(AdminStore)

export const AdminStoreProvider = ({ children }): any => {
    const [state, dispatch] = useReducer(adminReducer, initialAdminStoreState)

    const setShowAlertMessage = (alertMessage: AlertMessage | null) => {
        setAlertMessage(alertMessage, dispatch)
    }

    useEffect(() => {
        if (state.alertMessage) {
            const timer = setTimeout(() => setShowAlertMessage(null), 3000)

            // clear timeout
            return () => clearTimeout(timer)
        }
    }, [state.alertMessage])

    return (
        <AdminStore.Provider
            value={{
                state: state,
                setShowAlertMessage,
            }}
        >
            {children}
        </AdminStore.Provider>
    )
}
