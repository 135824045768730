import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useAdminStore } from '../../../modules/admin/context/store'
import { SecondaryButton, PrimaryButton } from '../../../modules/common/components/Button'
import { Page } from '../../../modules/common/enums/Pages'
import { convertGramsToMetricTonesLocaleString } from '../../../utils/conversion'
import AdminLayout from '../../modules/common/components/AdminLayout'
import ConfirmationModal from '../../modules/common/components/ConfirmationModal'
import { CopyButton } from '../../modules/common/components/CopyButton'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import { UnbridgeRequestApi } from '../../unbridge/http/api'
import { CancelUnbridgeRequestPayload } from '../../unbridge/http/types/CancelUnbridgeRequestPayload'
import { UnbridgeRequest } from '../../unbridge/http/types/UnbridgeRequest'
import { AdminFeatureFlagApi } from '../../modules/featureFlag/http/AdminFeatureFlagApi'
import { UnbridgeRequestStatuses } from '../../../modules/withdrawal/enums/UnbridgeRequestStatuses'
import RegistryIcon from '../../../modules/registry/components/RegistryIcon'
import WithdrawalStatusPill from '../../../modules/withdrawal/components/WithdrawalStatusPill'
import DateTimeFormatted from '../../../modules/dateTime/components/DateTimeFormatted'
import ID from '../../../modules/common/components/ID'

const AdminUnbridgeRequests = (): JSX.Element => {
    const [unbridgeRequests, setUnbridgeRequests] = useState<UnbridgeRequest[]>(
        [] as UnbridgeRequest[],
    )
    const [bridgeIntegrationEnabled, setBridgeIntegrationEnabled] = useState<boolean>(false)
    const [
        showSetInProgressConfirmationModalForUnbridgeRequest,
        setshowSetInProgressConfirmationModalForUnbridgeRequest,
    ] = useState<UnbridgeRequest | null>(null)
    const { setShowAlertMessage } = useAdminStore()

    const navigate = useNavigate()

    const getAllRequests = async (): Promise<void> => {
        const response = await UnbridgeRequestApi.getAllRequests()
        setUnbridgeRequests(response.data)
    }

    const getBridgeIntegrationEnabled = async (): Promise<void> => {
        const response = await AdminFeatureFlagApi.isBridgeIntegrationEnabled()
        setBridgeIntegrationEnabled(response.data)
    }
    const handleReturnClick = async (
        cancelRequestPayload: CancelUnbridgeRequestPayload,
    ): Promise<void> => {
        await UnbridgeRequestApi.cancel(cancelRequestPayload)
        await getAllRequests()
    }

    useEffect(() => {
        void getAllRequests()
        void getBridgeIntegrationEnabled()
    }, [])

    const setUnbridgeRequestInProgress = async (): Promise<void> => {
        try {
            if (showSetInProgressConfirmationModalForUnbridgeRequest) {
                await UnbridgeRequestApi.setInProgress(
                    showSetInProgressConfirmationModalForUnbridgeRequest?.unbridge_request_id,
                )
                setshowSetInProgressConfirmationModalForUnbridgeRequest(null)
                getAllRequests()
            }
        } catch (error) {
            if (
                error instanceof AxiosError &&
                error.response?.data.message &&
                error.response?.data.statusCode === 422
            ) {
                const errorMessage = error.response.data.message
                setShowAlertMessage({
                    text: errorMessage,
                    color: 'failure',
                })
                return
            } else {
                setShowAlertMessage({
                    text: 'Something went wrong please try again later',
                    color: 'failure',
                })
                throw error
            }
        } finally {
            setshowSetInProgressConfirmationModalForUnbridgeRequest(null)
        }
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.UNBRIDGE_REQUESTS}>
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                Request Id
                            </th>
                            <th scope="col" className="py-3 px-6">
                                User
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Tenant
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Tenant Id
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Project
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Project Registry Id
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Registry
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Vintage
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Bridge Vintage Id
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Quantity
                            </th>
                            <th scope="col" className="py-3 px-6 min-w-48">
                                Request Created At (UTC)
                            </th>
                            <th scope="col" className="py-3 px-6 min-w-48">
                                Status
                            </th>
                            <th scope="col" className="py-3 px-6 min-w-48">
                                Unbridged At (UTC)
                            </th>
                            <th scope="col" className="py-3 px-6 min-w-48">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {unbridgeRequests.map((request: UnbridgeRequest) => {
                            return (
                                <tr
                                    key={request.unbridge_request_id}
                                    className="bg-white border-b hover:bg-gray-50"
                                >
                                    <td className="py-4 px-6">
                                        <ID>{request.unbridge_request_id}</ID>
                                    </td>
                                    <td className="py-4 px-6">{request.user_name}</td>
                                    <td className="py-4 px-6">{request.tenant_name}</td>
                                    <td className="py-4 px-6">{request.tenant_id}</td>
                                    <td className="py-4 px-6">{request.project_name}</td>
                                    <td className="py-4 px-6">{request.registry_project_id}</td>
                                    <td className="py-4 px-6">
                                        <RegistryIcon registryId={request.registry_id} />
                                    </td>
                                    <td className="py-4 px-6">{request.vintage_year}</td>
                                    <td className="py-4 px-6">
                                        {request.bridge_vintage_id ? (
                                            <div className="flex flex-row gap-1">
                                                <span>{request.bridge_vintage_id}</span>
                                                <CopyButton text={request.bridge_vintage_id} />
                                            </div>
                                        ) : (
                                            'N/A'
                                        )}
                                    </td>
                                    <td className="py-4 px-6">
                                        {' '}
                                        {convertGramsToMetricTonesLocaleString(
                                            Number(request?.quantity),
                                        )}
                                    </td>
                                    <td className="py-4 px-6">
                                        <DateTimeFormatted date={request.created_at} />
                                    </td>
                                    <td className="py-4 px-6">
                                        <WithdrawalStatusPill status={request.status} />
                                    </td>
                                    <td className="py-4 px-6">
                                        <DateTimeFormatted date={request.unbridged_at} />
                                    </td>
                                    <td className="py-4 px-6 flex flex-col h-full justify-center items-center">
                                        {request.status === UnbridgeRequestStatuses.PENDING && (
                                            <SecondaryButton
                                                type="submit"
                                                className="mt-1.5 px-2.5 py-1"
                                                onClick={async () => {
                                                    setshowSetInProgressConfirmationModalForUnbridgeRequest(
                                                        request,
                                                    )
                                                }}
                                            >
                                                In progress
                                            </SecondaryButton>
                                        )}
                                        {request.status === UnbridgeRequestStatuses.IN_PROGRESS &&
                                            !bridgeIntegrationEnabled && (
                                                <PrimaryButton
                                                    onClick={() =>
                                                        navigate(
                                                            generatePath(
                                                                Page.ADMIN_COMPLETE_UNBRIDGE_REQUEST,
                                                                {
                                                                    id: request.unbridge_request_id,
                                                                },
                                                            ),
                                                        )
                                                    }
                                                    title="Complete Request"
                                                    className="mt-1.5 px-2.5 py-1"
                                                    disabled={
                                                        request.status !==
                                                        UnbridgeRequestStatuses.IN_PROGRESS
                                                    }
                                                >
                                                    Complete
                                                </PrimaryButton>
                                            )}
                                        {([UnbridgeRequestStatuses.PENDING].includes(
                                            request.status,
                                        ) ||
                                            ([UnbridgeRequestStatuses.IN_PROGRESS].includes(
                                                request.status,
                                            ) &&
                                                !bridgeIntegrationEnabled)) && (
                                            <PrimaryButton
                                                onClick={() =>
                                                    handleReturnClick({
                                                        unbridge_request_id:
                                                            request.unbridge_request_id,
                                                        user_id: request.user_id,
                                                        tenant_id: request.tenant_id,
                                                    })
                                                }
                                                title="Return Request"
                                                className="mt-1.5 px-2.5 py-1"
                                            >
                                                Return
                                            </PrimaryButton>
                                        )}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <ConfirmationModal
                    show={!!showSetInProgressConfirmationModalForUnbridgeRequest}
                    onClose={() => setshowSetInProgressConfirmationModalForUnbridgeRequest(null)}
                    onConfirm={setUnbridgeRequestInProgress}
                    question={
                        <p>
                            Are you sure you want to set unbridge request:{' '}
                            <b>
                                {
                                    showSetInProgressConfirmationModalForUnbridgeRequest?.unbridge_request_id
                                }
                            </b>{' '}
                            in progress
                        </p>
                    }
                />
            </div>
        </AdminLayout>
    )
}

export default AdminUnbridgeRequests
