import { ConfigFactory } from '../../config/ConfigFactory'
import errorInterceptor from './interceptors/errorInterceptor'
import requestInterceptor from './interceptors/requestInterceptor'
import axios, { AxiosInstance, RawAxiosRequestHeaders } from 'axios'

export const CreateHttpClient = async (useAuthToken = true): Promise<AxiosInstance> => {
    const headers: RawAxiosRequestHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }

    const httpClient = axios.create({
        baseURL: ConfigFactory.getConfig().serverUrl,
        withCredentials: true,
        headers,
        timeout: 10000,
    })

    if (!useAuthToken) {
        return httpClient
    }

    httpClient.interceptors.request.use(requestInterceptor, undefined)
    httpClient.interceptors.response.use(undefined, errorInterceptor(httpClient))

    return httpClient
}
