import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { AdminRetirementRequestDto } from '../http/types/AdminRetirementRequestDto'
import { CompleteRetirementRequestParams } from '../http/types/CompleteRetirementRequestParams'
import { convertGramsToMetricTonesLocaleString } from '../../../../utils/conversion'
import FieldErrorLabel from '../../common/components/FieldErrorLabel'
import { TagButton } from '../../../../modules/common/components/TagButton'
import ConfirmationModal from '../../common/components/ConfirmationModal'

interface CompleteRetirementRequestFormProps {
    retirementRequest: AdminRetirementRequestDto
    onCompleteRequestHandler: (params: CompleteRetirementRequestParams) => void
    apiResponseError?: string | string[]
}

interface CompleteRetirementRequestForm {
    retirement_request_id: string
    tenant_id: string
    tenant_name: string
    quantity: number
    month: string
    bridge_project_id: string
    bridge_vintage_id: string
    proof_of_retirement_nft_id: string
    proof_of_retirement_nft_url: string
    retired_at: string
    retired_serials: string[]
}

const CompleteRetirementRequestForm = ({
    retirementRequest,
    onCompleteRequestHandler,
    apiResponseError,
}: CompleteRetirementRequestFormProps): JSX.Element => {
    const [retiredSerials, setRetiredSerials] = useState<string[]>([])
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm<CompleteRetirementRequestForm>()
    const onSubmit = () => {
        setShowConfirmationModal(true)
    }

    const onCompleteRetirementRequest = () => {
        onCompleteRequestHandler({
            retirement_request_id: retirementRequest.id,
            proof_of_retirement_nft_id: getValues().proof_of_retirement_nft_id,
            proof_of_retirement_nft_url: getValues().proof_of_retirement_nft_url,
            retired_at: getValues().retired_at,
            retired_serials: retiredSerials,
        })
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex gap-4">
                    <div className="mb-6 text-left w-1/3">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Retirement Request Id
                        </label>
                        <input
                            type="text"
                            readOnly={true}
                            disabled={true}
                            value={retirementRequest.id}
                            {...register('retirement_request_id')}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6 text-left w-1/3">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Quantity
                        </label>
                        <input
                            type="text"
                            readOnly={true}
                            disabled={true}
                            value={convertGramsToMetricTonesLocaleString(
                                Number(retirementRequest.quantity),
                            )}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6 text-left w-1/3">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Month
                        </label>
                        <input
                            type="text"
                            readOnly={true}
                            disabled={true}
                            value={retirementRequest?.month || '-'}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                    </div>
                </div>
                <div className="flex gap-4">
                    <div className="mb-6 text-left w-1/2">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Tenant Id
                        </label>
                        <input
                            type="text"
                            readOnly={true}
                            disabled={true}
                            value={retirementRequest.tenant_id}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6 text-left w-1/2">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Tenant Name
                        </label>
                        <input
                            type="text"
                            readOnly={true}
                            disabled={true}
                            value={retirementRequest.tenant_name}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                    </div>
                </div>
                <div className="flex gap-4">
                    <div className="mb-6 text-left w-1/2">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Bridge project ID
                        </label>
                        <input
                            type="text"
                            readOnly={true}
                            disabled={true}
                            value={retirementRequest.bridge_project_id ?? '-'}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6 text-left w-1/2">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Bridge vintage ID
                        </label>
                        <input
                            type="text"
                            readOnly={true}
                            disabled={true}
                            value={retirementRequest.bridge_project_id ?? '-'}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                    </div>
                </div>
                <div className="flex gap-4">
                    <div className="mb-6 text-left w-1/3">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Proof of Retirement Id
                        </label>
                        <input
                            type="text"
                            placeholder={'Enter Proof of Retirement Request NFT Id'}
                            {...register('proof_of_retirement_nft_id', {
                                required: {
                                    value: true,
                                    message: 'Proof or retirement nft ID is required',
                                },
                                min: 1,
                            })}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                        <div className="h-5">
                            <FieldErrorLabel
                                error={errors.proof_of_retirement_nft_id?.message ?? ''}
                            />
                        </div>
                    </div>
                    <div className="mb-6 text-left w-1/3">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Proof of retirement nft url
                        </label>
                        <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Proof of retirement nft url"
                            {...register('proof_of_retirement_nft_url', {
                                required: {
                                    value: true,
                                    message: 'Proof of retirement nft URL is required',
                                },
                                min: 5,
                            })}
                        />
                        <div className="h-5">
                            <FieldErrorLabel
                                error={errors.proof_of_retirement_nft_url?.message ?? ''}
                            />
                        </div>
                    </div>
                    <div className="mb-6 text-left w-1/3">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Time Retired At On Bridge (UTC)
                        </label>
                        <input
                            type="text"
                            placeholder={'Enter The Time Retired At On Bridge'}
                            {...register('retired_at', {
                                required: {
                                    value: true,
                                    message: 'Time retired at is required',
                                },
                                validate: {
                                    isDateTimeString: (value) =>
                                        !isNaN(Date.parse(value)) ||
                                        'Value must be date time string, example 2023-05-01T00:00:00.000Z',
                                },
                                pattern: {
                                    value: /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/,
                                    message:
                                        'Value must be date time string, example 2023-05-01T00:00:00.000Z',
                                },
                            })}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                        <div className="h-5">
                            <FieldErrorLabel error={errors.retired_at?.message ?? ''} />
                        </div>
                    </div>
                </div>
                <div className="flex gap-4">
                    <div className="mb-6 text-left w-1/2">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Retirement serials On Bridge
                        </label>
                        <input
                            type="text"
                            placeholder={'Enter Retired Serials on Bridge'}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            {...register('retired_serials', {
                                required: {
                                    value: retiredSerials.length < 1,
                                    message: 'Retired serials can not be empty',
                                },
                                onBlur: (e) => {
                                    if (e.currentTarget.value.length > 0) {
                                        setRetiredSerials([
                                            ...retiredSerials,
                                            e.currentTarget.value,
                                        ])
                                        e.currentTarget.value = ''
                                    }
                                },
                            })}
                        />
                        <div className="h-5">
                            <FieldErrorLabel error={errors.retired_serials?.message ?? ''} />
                        </div>
                    </div>
                    <div className="pt-6 text-left w-1/2">
                        {retiredSerials.map((retiredSerial) => (
                            <TagButton
                                disabled={false}
                                name={retiredSerial}
                                key={retiredSerial}
                                onClose={() =>
                                    setRetiredSerials(
                                        retiredSerials.filter((s) => s !== retiredSerial),
                                    )
                                }
                            />
                        ))}
                    </div>
                </div>
                <div className="my-2">
                    {Array.isArray(apiResponseError) ? (
                        apiResponseError.map((err) => <FieldErrorLabel key={err} error={err} />)
                    ) : (
                        <FieldErrorLabel error={apiResponseError ?? ''} />
                    )}
                </div>
                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button
                        data-modal-toggle="defaultModal"
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        Complete Retirement Request
                    </button>
                </div>
            </form>
            <ConfirmationModal
                show={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={onCompleteRetirementRequest}
                question={
                    <p>
                        Are you sure you want to complete retirement request:{' '}
                        <b>{retirementRequest?.id}</b>
                    </p>
                }
            />
        </>
    )
}

export default CompleteRetirementRequestForm
