import { Link } from 'react-router-dom'
import { Tab, TabModes } from '../types/Tab'
import { useUserStore } from '../../user/context/store'

interface SubMenuInterface<T> {
    selectedItem: T
    tabs: Tab<T>[]
    onselect?: (tabName: T) => void
    id?: string
}

const SubMenu = <T,>({ selectedItem, onselect, tabs, id }: SubMenuInterface<T>) => {
    const { checkUserHasAtLeastOneRole } = useUserStore()

    const allowedTabs = tabs.filter((tab) => {
        if (!tab.allowedRoles) {
            return true
        }
        return checkUserHasAtLeastOneRole(tab.allowedRoles)
    })

    return (
        <div className="flex flex-row text-sm md:text-base justify-between flex-wrap gap-4" id={id}>
            {allowedTabs.map(({ type, text, mode, linkData }) => {
                const isSelected = selectedItem === type
                if (mode === TabModes.link) {
                    if (!linkData) {
                        throw new Error(
                            `Tab with mode LINK must include linkData Type: ${type} Text: ${text}`,
                        )
                    }
                    return (
                        <div
                            key={String(type)}
                            id={String(text)}
                            className={`flex flex-row items-center font-semibold`}
                        >
                            <Link
                                key={String(type)}
                                to={linkData.url}
                                className={`pb-4 select-none 
                                ${
                                    isSelected
                                        ? 'border-b-2 border-orange-500 text-gray-900'
                                        : 'text-gray-500 hover:text-gray-900 border-b-2 border-transparent'
                                }
                            `}
                            >
                                {text}
                            </Link>
                        </div>
                    )
                } else {
                    // default to button mode
                    if (!onselect) {
                        throw new Error(
                            'Must provide onselect when supplying BUTTON mode sub menu tabs',
                        )
                    }
                    return (
                        <div
                            key={String(type)}
                            id={String(text)}
                            className={`flex flex-row items-center font-bold`}
                            onClick={() => onselect(type)}
                        >
                            <div
                                className={`pb-4 cursor-pointer
                                ${
                                    isSelected
                                        ? 'border-solid border-b-2 border-orange-500 text-gray-900'
                                        : 'text-gray-500 hover:text-gray-900'
                                }
                            `}
                            >
                                {text}
                            </div>
                        </div>
                    )
                }
            })}
            <div className="flex-1"></div>
        </div>
    )
}

export default SubMenu
