import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import PayoutsTable from '../../modules/payouts/components/PayoutsTable'

interface Props {}

const AdminPayouts = ({}: Props): JSX.Element => {
    return (
        <AdminLayout selectedItem={AdminSubMenuActions.SELLER_PAYOUTS}>
            <PayoutsTable />
        </AdminLayout>
    )
}

export default AdminPayouts
