import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AdminFeatureFlagApi } from '../../modules/featureFlag/http/AdminFeatureFlagApi'
import { useAdminStore } from '../../../modules/admin/context/store'
import { Page } from '../../../modules/common/enums/Pages'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'

const AdminEditFeatureFlag = (): JSX.Element => {
    const { setShowAlertMessage } = useAdminStore()

    const { id } = useParams<{ id: string }>()
    const [description, setDescription] = useState<string>('')
    const navigate = useNavigate()

    const fetchFeatureFlag = async (): Promise<void> => {
        if (!id) return
        try {
            const response = await AdminFeatureFlagApi.getById(id)
            setDescription(response?.data.description ?? '')
        } catch (e) {
            setShowAlertMessage({
                color: 'failure',
                text: 'An unexpected error has occurred, please try again',
            })
            throw e
        }
    }

    useEffect(() => {
        void fetchFeatureFlag()
    }, [id])

    const onSaveHandler = async (): Promise<void> => {
        if (id) {
            try {
                await AdminFeatureFlagApi.patch(id, {
                    description,
                })
                setShowAlertMessage({
                    color: 'success',
                    text: 'Successfully saved',
                })
                navigate(Page.ADMIN_FEATURE_FLAG)
            } catch (e) {
                setShowAlertMessage({
                    color: 'failure',
                    text: 'An unexpected error has occurred, please try again',
                })
                throw e
            }
        }
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.FEATURE_FLAGS}>
            <div>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        void onSaveHandler()
                    }}
                >
                    <div className="flex flex-col mb-6">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Description
                        </label>
                        <textarea
                            value={description}
                            rows={10}
                            onChange={(e) => {
                                setDescription(e.target.value)
                            }}
                            className="w-2/3 rounded-lg border-gray-300 text-gray-500 font-normal text-base numeric-input-without-arrows"
                        />
                    </div>
                    <div className="flex items-center pt-6 space-x-2 border-t border-gray-200 rounded-b">
                        <input
                            type="submit"
                            value="Save"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        />
                    </div>
                </form>
            </div>
        </AdminLayout>
    )
}

export default AdminEditFeatureFlag
