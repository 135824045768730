import { AxiosResponse } from 'axios'
import HttpService from '../../../../modules/http/httpService'
import { CreateVintagePayload } from './types/CreateVintagePayload'
import { SearchVintagesParams } from './types/SearchVintagesParams'
import { SearchVintagesResponse } from './types/SearchVintagesResponse'

export interface VintageApiInterface {
    create(payload: CreateVintagePayload): Promise<AxiosResponse<void> | undefined>
    adminSearch(
        params: SearchVintagesParams,
    ): Promise<AxiosResponse<SearchVintagesResponse> | undefined>
}

export const AdminVintageApi: VintageApiInterface = Object.freeze({
    create(payload: CreateVintagePayload): Promise<AxiosResponse<void> | undefined> {
        return HttpService.post('/admin/vintages', payload)
    },

    adminSearch(
        params: SearchVintagesParams,
    ): Promise<AxiosResponse<SearchVintagesResponse> | undefined> {
        return HttpService.get('/admin/vintages', null, params)
    },
})
