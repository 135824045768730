import { useEffect, useState } from 'react'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Page } from '../../../modules/common/enums/Pages'
import { AxiosError } from 'axios'
import { ErrorResponse } from '../../../modules/http/types/ErrorResponse'
import { AdminTenantApi } from '../../modules/tenant/http/AdminTenantApi'
import { GetTenantResponse } from '../../modules/tenant/http/types/GetTenantResponse'
import { Locations } from '../../../modules/common/enums/Locations'
import FieldErrorLabel from '../../modules/common/components/FieldErrorLabel'
import { TagButton } from '../../../modules/common/components/TagButton'

export interface UpdateTentantFormData {
    location: Locations | null
    payout_paid_notification_emails: string[] | null
    invoice_settled_notification_emails: string[] | null
    short_id: string | null
    platform_fee_percentage: number | null
}

const EditTenant = (): JSX.Element => {
    const [currentTenant, setCurrentTenant] = useState<GetTenantResponse | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false)
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useForm<UpdateTentantFormData>()

    const onSubmit = async (form: UpdateTentantFormData): Promise<void> => {
        try {
            setIsFormDisabled(true)
            await AdminTenantApi.patch(id!, {
                location: form.location ?? undefined,
                payout_paid_notification_emails: form.payout_paid_notification_emails ?? undefined,
                invoice_settled_notification_emails:
                    form.invoice_settled_notification_emails ?? undefined,
                short_id: form.short_id ?? undefined,
                platform_fee_percentage: Number(form.platform_fee_percentage) ?? undefined,
            })
            navigate(Page.ADMIN_USERS)
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.data.message) setError(err.response.data?.message.toString())
        } finally {
            setIsFormDisabled(false)
        }
    }

    const getTenant = async (): Promise<void> => {
        const response = await AdminTenantApi.getTenantById(id!)
        setCurrentTenant(response?.data)
        setValue('location', response?.data?.location || null, { shouldValidate: true })
        setValue(
            'payout_paid_notification_emails',
            response?.data?.payout_paid_notification_emails || null,
            { shouldValidate: true },
        )
        setValue(
            'invoice_settled_notification_emails',
            response?.data?.invoice_settled_notification_emails || null,
            { shouldValidate: true },
        )
        setValue('short_id', response?.data?.short_id ?? null, { shouldValidate: true })
        setValue(
            'platform_fee_percentage',
            Number(response?.data?.platform_fee_percentage) ?? null,
            {
                shouldValidate: true,
            },
        )
    }

    useEffect(() => {
        void getTenant()
    }, [])

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.USERS}>
            <div>
                <form className="flex flex-col mt-8 w-90 mx-auto" onSubmit={handleSubmit(onSubmit)}>
                    <div>Editing Tenant: {id!}</div>
                    <div>Tenant Name: {currentTenant ? currentTenant.entity_name : ''}</div>
                    <div className="flex flex-col mt-3">
                        <label
                            htmlFor="location"
                            className="text-sm text-left font-semibold text-gray-700"
                        >
                            Location
                        </label>
                        <select
                            id="location"
                            className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            {...register('location', {
                                required: true,
                            })}
                        >
                            <option value={'Empty'}>Empty</option>
                            {Object.entries(Locations)
                                .sort((locA, locB) => {
                                    if (locA > locB) return 1
                                    if (locA < locB) return -1
                                    return 0
                                })
                                .map(([location, code]) => (
                                    <option key={location} value={code}>
                                        {location}
                                    </option>
                                ))}
                        </select>
                        <div className="h-5">
                            {errors?.location?.type === 'required' && (
                                <FieldErrorLabel error="Location is required" />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col mt-3">
                        <label
                            htmlFor="location"
                            className="text-sm text-left font-semibold text-gray-700"
                        >
                            Short ID
                        </label>
                        <input
                            type="text"
                            placeholder="Short ID"
                            {...register('short_id', {
                                required: false,
                            })}
                            className="block p-4 mb-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                        <div className="h-5">
                            {errors?.short_id?.type === 'required' && (
                                <FieldErrorLabel error="Name is required" />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col mt-3">
                        <label
                            htmlFor="location"
                            className="text-sm text-left font-semibold text-gray-700"
                        >
                            Platform Fee Percentage (x100)
                        </label>
                        <input
                            type="text"
                            placeholder="Platform Fee Percentage"
                            {...register('platform_fee_percentage', {
                                required: true,
                            })}
                            className="block p-4 mb-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                        <div className="h-5">
                            {errors?.platform_fee_percentage?.type === 'required' && (
                                <FieldErrorLabel error="Platform Fee Percentage is required" />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                <div>Payout Paid Notification Emails</div>
                                <div>(always sent to accounts@thallo.io)</div>
                            </label>
                            <input
                                type="email"
                                placeholder={'Lose focus to add to list'}
                                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onBlur={(e) => {
                                    if (e.currentTarget.value.length > 0) {
                                        setValue(
                                            'payout_paid_notification_emails',
                                            [
                                                ...(getValues('payout_paid_notification_emails') ||
                                                    []),
                                                e.currentTarget.value,
                                            ],
                                            { shouldValidate: true },
                                        )
                                        e.currentTarget.value = ''
                                    }
                                }}
                            />
                        </div>
                        <div className="pt-6 text-left flex">
                            {(watch('payout_paid_notification_emails') || []).map(
                                (email: string) => {
                                    return (
                                        <TagButton
                                            disabled={false}
                                            name={email}
                                            key={email}
                                            onClose={() =>
                                                setValue(
                                                    'payout_paid_notification_emails',
                                                    (
                                                        getValues(
                                                            'payout_paid_notification_emails',
                                                        ) || []
                                                    ).filter((e) => e !== email),
                                                    { shouldValidate: true },
                                                )
                                            }
                                        />
                                    )
                                },
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                <div>Invoice Settled Notification Emails</div>
                                <div>(always sent to accounts@thallo.io)</div>
                            </label>
                            <input
                                type="email"
                                placeholder={'Lose focus to add to list'}
                                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onBlur={(e) => {
                                    if (e.currentTarget.value.length > 0) {
                                        setValue(
                                            'invoice_settled_notification_emails',
                                            [
                                                ...(getValues(
                                                    'invoice_settled_notification_emails',
                                                ) || []),
                                                e.currentTarget.value,
                                            ],
                                            { shouldValidate: true },
                                        )
                                        e.currentTarget.value = ''
                                    }
                                }}
                            />
                        </div>
                        <div className="pt-6 text-left flex">
                            {(watch('invoice_settled_notification_emails') || []).map(
                                (email: string) => {
                                    return (
                                        <TagButton
                                            disabled={false}
                                            name={email}
                                            key={email}
                                            onClose={() =>
                                                setValue(
                                                    'invoice_settled_notification_emails',
                                                    (
                                                        getValues(
                                                            'invoice_settled_notification_emails',
                                                        ) || []
                                                    ).filter((e) => e !== email),
                                                    { shouldValidate: true },
                                                )
                                            }
                                        />
                                    )
                                },
                            )}
                        </div>
                    </div>

                    <input
                        disabled={isFormDisabled}
                        type="submit"
                        className="h-11 mt-6 rounded-lg text-white bg-primary-600 hover:cursor-pointer"
                        name="Save Tenant"
                    />
                    {error && <FieldErrorLabel error={error} />}
                </form>
            </div>
        </AdminLayout>
    )
}

export default EditTenant
