import { OrderType } from '../enums/OrderType'
import { SearchMyOrdersResponse } from '../http/types/SearchMyOrdersResponse'
import {
    ModalType,
    MyOrdersAction,
    SET_CURRENT_MODAL,
    SET_IS_LOADING,
    SET_ORDERS_RESPONSE,
    SET_SELECTED_ORDER_TYPE,
} from './types'

export interface MyOrdersState {
    searchMyOrdersResponse: SearchMyOrdersResponse | null
    selectedOrderType: OrderType
    isLoading: boolean
    currentModal: ModalType
}

export const initialMyOrdersState: MyOrdersState = {
    searchMyOrdersResponse: null,
    selectedOrderType: OrderType.TRADE_HISTORY,
    isLoading: false,
    currentModal: null,
}

export const OrdersReducer = (
    state = initialMyOrdersState,
    action: MyOrdersAction,
): MyOrdersState => {
    switch (action.type) {
        case SET_SELECTED_ORDER_TYPE:
            return {
                ...state,
                selectedOrderType: action.payload,
            }
        case SET_ORDERS_RESPONSE:
            return {
                ...state,
                searchMyOrdersResponse: action.payload.response,
            }
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading,
            }
        case SET_CURRENT_MODAL:
            return {
                ...state,
                currentModal: action.payload.modal,
            }
        default:
            return state
    }
}
