import { useEffect, useState } from 'react'
import Table, { Column, TextAlignment, Values } from '../../table/components/Table'
import DateTimeFormatted from '../../dateTime/components/DateTimeFormatted'
import ProjectName from '../../project/components/ProjectName'
import Location from '../../project/components/Location'
import RegistryIcon from '../../registry/components/RegistryIcon'
import {
    convertCentsToLocaleUsd,
    convertGramsToMetricTonesLocaleString,
} from '../../../utils/conversion'
import { BuyOrderTradeDto } from '../http/types/BuyOrderDto'

type Columns =
    | 'id'
    | 'date'
    | 'project_name'
    | 'vintage'
    | 'location'
    | 'registry'
    | 'price'
    | 'quantity'
    | 'total'

interface Props {
    trades: BuyOrderTradeDto[]
}

const BuyOrderTradesTable = ({ trades }: Props): JSX.Element => {
    const [tradeData, setTradeData] = useState<Values<Columns>[]>([])

    useEffect(() => {
        setTradeData(
            trades.map((trade) => {
                return {
                    id: {
                        value: <div>{trade.id}</div>,
                    },
                    date: {
                        value: <DateTimeFormatted date={new Date(trade.created_at)} />,
                    },
                    project_name: {
                        value: (
                            <ProjectName
                                squareImageUrl={trade.project_data.square_image_url}
                                type={trade.project_data.type}
                                name={trade.project_data.name}
                                id={trade.project_data.id}
                                sellOrderId={trade.sell_order_id}
                            />
                        ),
                    },
                    vintage: {
                        value: trade.vintage,
                    },
                    location: {
                        value: (
                            <Location
                                location={trade.project_data.location}
                                showFullCountryName={false}
                            />
                        ),
                    },
                    registry: {
                        value: <RegistryIcon registryId={trade.project_data.registry_id} />,
                    },
                    price: {
                        value: (
                            <div className="font-bold">
                                {convertCentsToLocaleUsd(trade.gross_unit_price)}
                            </div>
                        ),
                        textAlignment: TextAlignment.RIGHT,
                    },
                    quantity: {
                        value: convertGramsToMetricTonesLocaleString(Number(trade.quantity)),
                        textAlignment: TextAlignment.RIGHT,
                    },
                    total: {
                        value: (
                            <div className="font-bold">
                                {convertCentsToLocaleUsd(trade.gross_total_cost)}
                            </div>
                        ),
                        textAlignment: TextAlignment.RIGHT,
                    },
                }
            }),
        )
    }, [trades])

    const columns: Column<Columns>[] = [
        {
            name: 'id',
            display: 'Trade',
        },
        {
            name: 'date',
            display: 'Date',
        },
        {
            name: 'project_name',
            display: 'Project',
        },
        {
            name: 'vintage',
            display: 'Vintage',
        },
        {
            name: 'location',
            display: 'Location',
        },
        {
            name: 'registry',
            display: 'Registry',
        },
        {
            name: 'price',
            display: 'Unit Price',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'quantity',
            display: 'Quantity',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'total',
            display: 'Total',
            textAlignment: TextAlignment.RIGHT,
        },
    ]

    return <Table columns={columns} data={tradeData} />
}

export default BuyOrderTradesTable
