import { Link } from 'react-router-dom'

interface Props {
    children: React.ReactNode
    link?: string
}

const ID = ({ children, link }: Props): JSX.Element => {
    if (!link) {
        return <div className="whitespace-nowrap">{children}</div>
    }

    return (
        <Link className="link whitespace-nowrap" to={link}>
            {children}
        </Link>
    )
}

export default ID
