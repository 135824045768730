export interface FieldErrorLabelProps {
    error: string
    className?: string
}

const FieldErrorLabel = ({ error, className = '' }: FieldErrorLabelProps) => {
    return <p className={`text-xs text-red-500 ${className}`}>{error}</p>
}

export default FieldErrorLabel
