import React, { forwardRef } from 'react'
import { ProjectType } from '../../../modules/project/enums/ProjectType'
import { useSellOrdersStore } from '../../../modules/sellOrders/context/store'
import withClickOutside from './withClickOutside'

interface Option {
    value: keyof typeof ProjectType
    label: string
}
const ProjectTypeFilter = forwardRef(({}, ref: any): JSX.Element => {
    const {
        shouldShowProjectTypeFilter,
        searchSellOrdersResponse,
        searchSellOrderFilters,
        isFormDisabled,
        setShouldShowProjectTypeFilter,
        setSearchSellOrderFilters,
    } = useSellOrdersStore()

    const onProjectTypeClickHandler = (): void => {
        setShouldShowProjectTypeFilter(!shouldShowProjectTypeFilter)
    }

    const options = (Object.keys(ProjectType) as Array<keyof typeof ProjectType>)
        .filter((key) => {
            if ((searchSellOrdersResponse?.active_filters.project_types.length ?? 0) > 0) {
                return searchSellOrdersResponse?.active_filters.project_types.includes(
                    ProjectType[key],
                )
            }
            return false
        })
        .map((key) => {
            return {
                value: key,
                label: ProjectType[key],
            }
        })

    const onProjectTypeChangeHandler = (isChecked: boolean, option: Option): void => {
        if (isChecked) {
            setSearchSellOrderFilters({
                ...searchSellOrderFilters,
                projectType: [...searchSellOrderFilters.projectType, ProjectType[option.value]],
            })
        } else {
            setSearchSellOrderFilters({
                ...searchSellOrderFilters,
                projectType: searchSellOrderFilters.projectType.filter(
                    (type: string) => type !== option.label,
                ),
            })
        }
    }

    return (
        <div className="flex flex-col w-full sm:w-1/3 lg:w-[224px] relative" ref={ref}>
            <label className="text-gray-700 text-sm font-semibold mb-1.5">Type</label>
            <button
                disabled={isFormDisabled}
                className={`w-full h-11 bg-white rounded-lg border border-gray-300 ${
                    isFormDisabled ? 'bg-gray-100' : null
                }`}
                onClick={(e) => {
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation()
                    e.preventDefault()
                    onProjectTypeClickHandler()
                }}
            >
                <div className="px-4 flex items-center justify-between">
                    <div className="flex gap-4 items-center">
                        <svg
                            width="18"
                            height="20"
                            viewBox="0 0 18 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.725 5.80008L9 10.0084L16.275 5.80008M9 18.4001V10.0001M16.5 13.3334V6.66675C16.4997 6.37448 16.4225 6.08742 16.2763 5.83438C16.13 5.58134 15.9198 5.37122 15.6667 5.22508L9.83333 1.89175C9.57997 1.74547 9.29256 1.66846 9 1.66846C8.70744 1.66846 8.42003 1.74547 8.16667 1.89175L2.33333 5.22508C2.08022 5.37122 1.86998 5.58134 1.72372 5.83438C1.57745 6.08742 1.5003 6.37448 1.5 6.66675V13.3334C1.5003 13.6257 1.57745 13.9127 1.72372 14.1658C1.86998 14.4188 2.08022 14.6289 2.33333 14.7751L8.16667 18.1084C8.42003 18.2547 8.70744 18.3317 9 18.3317C9.29256 18.3317 9.57997 18.2547 9.83333 18.1084L15.6667 14.7751C15.9198 14.6289 16.13 14.4188 16.2763 14.1658C16.4225 13.9127 16.4997 13.6257 16.5 13.3334Z"
                                stroke="#667085"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <p className="text-gray-900 text-base font-normal">
                            {searchSellOrderFilters.projectType.length
                                ? `Type (${searchSellOrderFilters.projectType.length})`
                                : 'Any'}
                        </p>
                    </div>
                    <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1.5L6 6.5L11 1.5"
                            stroke="#667085"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </button>
            {shouldShowProjectTypeFilter && (
                <div
                    className="absolute bg-white top-20 rounded-lg z-10 w-full lg:w-[224px] max-h-80 overflow-auto"
                    style={{
                        boxShadow:
                            '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
                    }}
                >
                    <div>
                        {options.map((option: Option) => {
                            const isSelected = searchSellOrderFilters.projectType.includes(
                                option.label as ProjectType,
                            )
                            return (
                                <div
                                    className="flex flex-row items-center py-2.5 pl-4"
                                    key={option.label}
                                >
                                    <label className="custom-checkbox">
                                        <input
                                            className="w-4 h-4 rounded"
                                            type="checkbox"
                                            checked={isSelected}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                onProjectTypeChangeHandler(
                                                    event.target.checked,
                                                    option,
                                                )
                                            }}
                                        />
                                        <span></span>
                                    </label>
                                    <p
                                        className={`gray-800 text-base font-normal leading-6 ml-3 cursor-pointer`}
                                        onClick={() => {
                                            onProjectTypeChangeHandler(!isSelected, option)
                                        }}
                                    >
                                        {option.label}
                                    </p>
                                </div>
                            )
                        })}
                        {options.length === 0 && (
                            <p
                                className={`gray-800 text-base font-normal leading-6 py-2.5 flex flex-row items-center ml-3 cursor-pointer`}
                            >
                                No project types
                            </p>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
})
export default withClickOutside(ProjectTypeFilter)
