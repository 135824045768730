import { useEffect, useState } from 'react'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import { AccountType } from '../../../modules/user/enums/AcountType'
import { Role } from '../../../modules/user/types/Role'
import { Tenant } from '../../../modules/tenant/types/Tenant'
import { useForm } from 'react-hook-form'
import { AdminUserApi } from '../../modules/user/http/AdminUserApi'
import { useNavigate } from 'react-router-dom'
import { Page } from '../../../modules/common/enums/Pages'
import { AxiosError } from 'axios'
import { ErrorResponse } from '../../../modules/http/types/ErrorResponse'
import { AdminTenantApi } from '../../modules/tenant/http/AdminTenantApi'
import { ToggleSwitch } from 'flowbite-react'
import FieldErrorLabel from '../../modules/common/components/FieldErrorLabel'
import ChooseAccountType from '../../modules/user/components/ChooseAccountType'
import { Locations } from '../../../modules/common/enums/Locations'
import { getEmailRegex } from '../../../utils/getEmailRegex'

interface FormData {
    companyName?: string
    taxId?: string
    tenantId?: string
    email: string
    firstName: string
    lastName: string
    location: Locations
    shortId?: string
}

const CreateUser = (): JSX.Element => {
    const [accountType, setAccountType] = useState<AccountType>(AccountType.ENTITY)
    const [isExistingEntitySelected, setIsSelectedEntitySelected] = useState<boolean>(true)
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [existingRoles, setExistingRoles] = useState<Role[]>([])
    const [selectedRoles, setSelectedRoles] = useState<Role[]>([])
    const [tenants, setTenants] = useState<Tenant[]>([])
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>()
    const navigate = useNavigate()

    const onSubmit = async (data: FormData): Promise<void> => {
        try {
            setIsFormDisabled(true)
            setError(null)
            await AdminUserApi.createUser({
                account_type: accountType,
                email: data.email,
                first_name: data.firstName,
                last_name: data.lastName,
                company_name: data.companyName,
                tax_id: data.taxId && data.taxId?.length > 0 ? data.taxId : undefined,
                tenant_id: data.tenantId && data.tenantId.length ? data.tenantId : undefined,
                role_ids: selectedRoles.map((role) => role.id),
                location: data.location,
                short_name: data.shortId ?? undefined,
            })
            navigate(Page.ADMIN_USERS)
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (err?.response?.status && err?.response?.status < 500) {
                setError(
                    Array.isArray(err?.response?.data?.message)
                        ? err?.response?.data?.message.join(', ')
                        : err?.response?.data?.message,
                )
            } else {
                setError('An unexpected error has occurred, please try again')
                throw e
            }
        } finally {
            setIsFormDisabled(false)
        }
    }

    const fetchExistingRoles = async (): Promise<void> => {
        const response = await AdminUserApi.getAllRoles()
        if (response) {
            setExistingRoles(response.data)
        }
    }

    useEffect(() => {
        void fetchExistingRoles()
    }, [])

    const fetchTenants = async () => {
        const tenantsResponse = await AdminTenantApi.getEntityTenants()
        if (tenantsResponse) {
            setTenants(tenantsResponse.data)
        }
    }

    useEffect(() => {
        void fetchTenants()
    }, [])

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.USERS}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col mt-8 w-[600px] mx-auto"
            >
                <ChooseAccountType
                    accountType={accountType}
                    onAccountTypeChange={(accountType) => {
                        if (accountType === 'existing entity') {
                            setAccountType(AccountType.ENTITY)
                            setIsSelectedEntitySelected(true)
                        } else {
                            setAccountType(accountType)
                            setIsSelectedEntitySelected(false)
                        }
                    }}
                    isExistingEntitySelected={isExistingEntitySelected}
                    isDisabled={isFormDisabled}
                />
                {accountType === AccountType.ENTITY && isExistingEntitySelected && (
                    <div className="flex flex-col mt-8">
                        <label className="text-sm font-semibold text-gray-700">
                            Select Tenant*
                        </label>
                        <select
                            id="small"
                            className="block p-2 mt-1.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            {...register('tenantId', {
                                required: true,
                                minLength: 1,
                            })}
                        >
                            <option value={''}>Choose</option>
                            {tenants.map((tenant) => (
                                <option key={tenant.id} value={tenant.id}>
                                    {tenant.entity_name} - {tenant.id}
                                </option>
                            ))}
                        </select>
                        <div className="h-5">
                            {errors?.tenantId?.type === 'required' && (
                                <FieldErrorLabel error="Tenant is required" />
                            )}
                        </div>
                    </div>
                )}

                {accountType === AccountType.ENTITY && !isExistingEntitySelected && (
                    <div className="flex flex-col mt-8">
                        <label className="text-sm font-semibold text-gray-700">Company Name*</label>
                        <input
                            disabled={isFormDisabled}
                            type="text"
                            placeholder="Company Name"
                            {...register('companyName', {
                                required: true,
                            })}
                            className="h-11 rounded-lg border-gray-300 mt-1.5"
                        />
                        <div className="h-5">
                            {errors?.companyName?.type === 'required' && (
                                <FieldErrorLabel error="Company Name is required" />
                            )}
                        </div>
                    </div>
                )}
                <div
                    className={`flex flex-col ${accountType === AccountType.ENTITY ? 'mt-3' : 'mt-8'}`}
                >
                    <label className="text-sm font-semibold text-gray-700">Email*</label>
                    <input
                        type="email"
                        disabled={isFormDisabled}
                        placeholder="Email"
                        {...register('email', {
                            required: true,
                            pattern: getEmailRegex(),
                        })}
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    <div className="h-5">
                        {errors?.email?.type === 'required' && (
                            <FieldErrorLabel error="Email is required" />
                        )}
                        {errors?.email?.type === 'pattern' && (
                            <FieldErrorLabel error="Invalid email" />
                        )}
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm font-semibold text-gray-700">First Name*</label>
                    <input
                        type="text"
                        disabled={isFormDisabled}
                        placeholder="Enter First Name"
                        {...register('firstName', {
                            required: true,
                        })}
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    <div className="h-5">
                        {errors?.firstName?.type === 'required' && (
                            <FieldErrorLabel error="First Name is required" />
                        )}
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm font-semibold text-gray-700">Last Name*</label>
                    <input
                        disabled={isFormDisabled}
                        type="text"
                        placeholder="Enter Last Name"
                        {...register('lastName', {
                            required: true,
                        })}
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    <div className="h-5">
                        {errors?.lastName?.type === 'required' && (
                            <FieldErrorLabel error="Last Name is required" />
                        )}
                    </div>
                </div>
                {(accountType === AccountType.ENTITY || accountType === AccountType.INDIVIDUAL) &&
                    !isExistingEntitySelected && (
                        <div className="flex flex-col mt-3">
                            <label className="text-sm font-semibold text-gray-700">Tax Id</label>
                            <input
                                disabled={isFormDisabled}
                                type="text"
                                placeholder="Tax Id"
                                {...register('taxId', {
                                    required: false,
                                })}
                                className="h-11 rounded-lg border-gray-300 mt-1.5"
                            />
                        </div>
                    )}

                <div className="flex flex-col mt-8">
                    <label className="text-sm mb-1.5 font-semibold text-gray-700">Roles*</label>
                    {existingRoles.map((role: Role) => {
                        return (
                            <div key={role.id} className="mb-2">
                                <ToggleSwitch
                                    checked={selectedRoles.some((r: Role) => r.id === role.id)}
                                    label={role.display_name}
                                    onChange={(checked) => {
                                        if (checked) {
                                            setSelectedRoles([...selectedRoles, role])
                                        } else {
                                            setSelectedRoles(
                                                selectedRoles.filter((r) => role.id !== r.id),
                                            )
                                        }
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>

                {(accountType === AccountType.ENTITY || accountType === AccountType.INDIVIDUAL) &&
                    !isExistingEntitySelected && (
                        <>
                            <div className="flex flex-col mt-3">
                                <label
                                    htmlFor="location"
                                    className="text-sm text-left font-semibold text-gray-700"
                                >
                                    Location*
                                </label>
                                <select
                                    id="location"
                                    className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                    {...register('location', {
                                        required: true,
                                        minLength: 1,
                                    })}
                                >
                                    <option value={''}></option>
                                    {Object.entries(Locations)
                                        .sort((locA, locB) => {
                                            if (locA > locB) return 1
                                            if (locA < locB) return -1
                                            return 0
                                        })
                                        .map(([location, code]) => (
                                            <option key={location} value={code}>
                                                {location}
                                            </option>
                                        ))}
                                </select>
                                <div className="h-5">
                                    {errors?.location?.type === 'required' && (
                                        <FieldErrorLabel error="Location is required" />
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col mt-3">
                                <label
                                    htmlFor="location"
                                    className="text-sm text-left font-semibold text-gray-700"
                                >
                                    Short ID
                                </label>
                                <input
                                    type="text"
                                    id="tenant_short_id"
                                    placeholder="Short ID"
                                    {...register('shortId', {
                                        required: true,
                                    })}
                                    className="block p-4 mb-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                                <div className="h-5">
                                    {errors?.shortId?.type === 'required' && (
                                        <FieldErrorLabel error="Name is required" />
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                <input
                    disabled={isFormDisabled}
                    type="submit"
                    value="Create account"
                    className={`h-11 mt-6 rounded-lg text-white ${
                        isFormDisabled ? 'bg-primary-200' : 'bg-primary-600'
                    }`}
                />
                {error && <FieldErrorLabel error={error} />}
            </form>
        </AdminLayout>
    )
}

export default CreateUser
