import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { ErrorResponse } from '../../http/types/ErrorResponse'
import {
    convertMetricTonesToGramsNumeric,
    convertUsdToCents,
    convertGramsToMetricTonnes,
    convertCentsToUsd,
    convertLocaleNumericStringToNumber,
    convertGramsToMetricTonesLocaleString as convertGramsToMetricTonnesLocaleString,
    convertCentsToLocaleUsd,
} from '../../../utils/conversion'
import { PrimaryButton, SecondaryButton } from '../../common/components/Button'
import { PatchMyOrderPayload } from '../../myOrders/http/types/PatchMyOrderPayload'
import { SellOrder } from '../../sellOrders/types/SellOrder'
import { useToastMessageStore } from '../../toastMessage/context/store'
import { ToastMessageType } from '../../toastMessage/enums/ToastMessageType'
import { Vintage } from '../../vintage/types/Vintage'
import { Project } from '../../project/types/Project'
import BaseModal from '../../modal/components/BaseModal'
import { MyOrderApi } from '../../myOrders/http/api'
import { FormElement } from '../../form/types/Field'
import { useForm } from 'react-hook-form'
import { ListItem } from '../../form/types/ListItem'
import { Button } from '../../form/types/Button'
import Form from '../../form/components/Form'
import { ActionName } from '../../action/enums/ActionName'
import CreditsConfirmation from './CreditsConfirmation'
import { CarbonCreditBalanceSellOrder } from '../../carbonCreditBalance/types/CarbonCreditBalance'
import { VintageCarbonCreditBalance } from '../../vintage/types/VintageCarbonCreditBalance'
import { getCentsNumFromDollarStr } from '../../../utils/getCentsNumFromDollarStr'

interface EditSellOrderProps {
    show: boolean
    onClose: (reload?: boolean) => Promise<void>
    onSaved: () => void
    sellOrder: SellOrder | null
    carbonCreditBalance: CarbonCreditBalanceSellOrder | VintageCarbonCreditBalance | null
    vintage: Vintage
    project: Project
}

type ValidationErrors = {
    quantity?: string[]
    price?: string[]
    unhandled?: string[]
}

type FormData = {
    quantity: number
    price: string
}

const EditSellOrderModal = ({
    show,
    onClose,
    onSaved,
    sellOrder,
    carbonCreditBalance,
    vintage,
    project,
}: EditSellOrderProps) => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [errors, setErrors] = useState<ValidationErrors | null>(null)
    const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false)
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true)
    const { setShowToastMessage } = useToastMessageStore()

    const form = useForm<FormData>({
        defaultValues: {
            quantity: convertGramsToMetricTonnes(Number(sellOrder?.quantity ?? 0)),
            price: convertCentsToUsd(Number(sellOrder?.price ?? 0)),
        },
    })

    useEffect(() => {
        if (!sellOrder) return
        form.setValue('quantity', convertGramsToMetricTonnes(sellOrder.quantity))
        form.setValue('price', convertCentsToUsd(sellOrder.price))
    }, [sellOrder])

    const isSellOrderUpdated = (): boolean => {
        const quantity = form.getValues('quantity')
        const price = form.getValues('price')
        const priceInCents = convertUsdToCents(price).toString()
        return (
            priceInCents !== sellOrder?.price ||
            String(convertMetricTonesToGramsNumeric(quantity)) !== sellOrder?.quantity
        )
    }

    useEffect(() => {
        const quantity = form.getValues('quantity')
        const price = convertLocaleNumericStringToNumber(form.getValues('price'))
        const isDisabled =
            !price ||
            convertUsdToCents(price) === 0 ||
            quantity === 0 ||
            showSpinner ||
            !isSellOrderUpdated() ||
            !!errors?.price?.length ||
            !!errors?.quantity?.length
        setSubmitDisabled(isDisabled)
    }, [form.watch(['quantity', 'price'])])

    if (!vintage || !sellOrder || !project) return <></>
    const close = async (reload?: boolean) => {
        await onClose(reload)
        setShowSpinner(false)
        form.reset()
        setErrors(null)
        setDisplayConfirmation(false)
    }

    const saveChanges = async () => {
        try {
            if (!sellOrder.id) return
            setErrors(null)
            setShowSpinner(true)
            const price = convertLocaleNumericStringToNumber(form.getValues('price'))
            const payload: PatchMyOrderPayload = {
                updated_at: sellOrder.updated_at,
            }
            if (sellOrder?.quantity !== String(form.getValues('quantity'))) {
                payload['quantity'] = convertMetricTonesToGramsNumeric(
                    form.getValues('quantity'),
                ).toString()
            }
            if (sellOrder.price !== convertUsdToCents(price).toString()) {
                payload['price'] = convertUsdToCents(price).toString()
            }
            await MyOrderApi.patch(sellOrder.id, payload)
            setShowToastMessage({
                text: 'Sell Order Updated',
                type: ToastMessageType.SUCCESS,
            })
            onSaved()
            close(true)
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            if (error.response?.data.message && Array.isArray(error.response?.data.message)) {
                const validationErrors = error.response?.data.message
                const priceValidationErrors = validationErrors?.filter((err) => err.match('price'))
                const quantityValidationErrors = validationErrors?.filter((err) =>
                    err.match('quantity'),
                )
                setErrors({
                    quantity: quantityValidationErrors,
                    price: priceValidationErrors,
                    unhandled: [],
                })
            } else {
                setErrors({ unhandled: ['An unexpected error has occurred, please try again'] })
                throw e
            }
        } finally {
            setShowSpinner(false)
        }
    }

    const fields: FormElement<FormData>[] = [
        {
            name: 'quantity',
            displayName: 'Quantity',
            type: 'number_slider',
            required: true,
            min: 1,
            max: Number(
                BigInt(
                    Math.floor(convertGramsToMetricTonnes(carbonCreditBalance?.liquid_amount ?? 0)),
                ) + BigInt(convertGramsToMetricTonnes(sellOrder.quantity)),
            ),
            step: 1,
            id: 'edit-sell-order-quantity',
        },
        {
            name: 'price',
            displayName: 'Price per credit',
            type: 'dollar',
            required: true,
            min: 0,
            max: 9999,
            step: 0.01,
            id: 'edit-sell-order-price',
        },
    ]

    const listItems: ListItem[] = [
        {
            label: ['Project'],
            value: project.name,
        },
        {
            label: ['Vintage'],
            value: vintage.vintage.toString(),
        },
        {
            label: ['Current Quantity'],
            value: convertGramsToMetricTonnesLocaleString(sellOrder.quantity),
        },
        {
            label: ['Current Price'],
            value: convertCentsToLocaleUsd(sellOrder.price),
        },
    ]
    const title = 'Edit Sell Order'
    const buttons: Button[] = [
        {
            type: 'button',
            className: 'w-1/2',
            onClick: close,
            disabled: showSpinner,
            id: 'close',
            variation: SecondaryButton,
            label: 'Close',
        },
        {
            type: 'submit',
            className: 'flex-1',
            disabled: submitDisabled,
            id: 'update-order',
            variation: PrimaryButton,
            label: 'Update Order',
        },
    ]

    return (
        <BaseModal
            show={show}
            id="modalEditSellOrder"
            position="center"
            error={errors}
            showSpinner={showSpinner}
            onClose={close}
        >
            {!displayConfirmation && (
                <Form
                    title={title}
                    onSubmit={() => {
                        setDisplayConfirmation(true)
                    }}
                    formId="edit-sell-order-form"
                    fields={fields}
                    headerListItems={listItems}
                    buttons={buttons}
                    form={form}
                    buttonWrapperClassName="order-1 w-1/2"
                />
            )}
            {displayConfirmation && (
                <CreditsConfirmation
                    actionName={ActionName.UPDATE_SELL_ORDER}
                    showSpinner={showSpinner}
                    price={form.getValues('price')}
                    quantity={convertMetricTonesToGramsNumeric(form.getValues('quantity'))}
                    vintage={vintage}
                    project={project}
                    pricePerCredit={form.getValues('price')}
                    pricePerCreditCents={getCentsNumFromDollarStr(form.getValues('price'))}
                    onClose={close}
                    onConfirm={saveChanges}
                    retireeName={undefined}
                />
            )}
        </BaseModal>
    )
}

export default EditSellOrderModal
