export enum ActionName {
    SELL = 'sell',
    RETIRE = 'retire',
    RETURN = 'return',
    PURCHASE = 'purchase',
    BUY_RETIRE = 'buy_retire',
    INTEREST = 'interest',
    CANCEL = 'cancel',
    UPDATE_SELL_ORDER = 'update_sell_order',
    CANCEL_SELL_ORDER = 'cancel_sell_order',
}
