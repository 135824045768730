import { FieldValues, PathValue, UseFormReturn, Path } from 'react-hook-form'
import { Alignment, Field, FormElement, Placement } from '../types/Field'
import Note, { NoteType } from '../../note/components/Note'
import { ListItem } from '../types/ListItem'
import OverviewListItem from '../../common/components/OverviewListItem'
import { isEven } from '../../../utils/isEven'
import React, { Fragment, useState } from 'react'
import { Button } from '../types/Button'
import { Tooltip } from 'react-tooltip'
import AlignMiddle from '../../common/components/AlignMiddle'
import { calculatePercentage, calculatePercentageOf } from '../../../utils/calculatePercentage'
import QuantitySlider from '../../credits/components/QuantitySlider'
import { SecondaryButton } from '../../common/components/Button'
import 'rc-slider/assets/index.css'

interface Props<T extends FieldValues> {
    title?: string | React.ReactNode
    onSubmit: (data: FieldValues) => void
    formId: string
    formClassName?: string
    fields: FormElement<T>[]
    headerListItems?: ListItem[]
    children?: React.ReactNode
    buttons?: Button[]
    buttonWrapperClassName?: string
    buttonInnerWrapperClassName?: string
    buttonAlignment?: Alignment
    form: UseFormReturn<T>
    footer?: React.ReactNode
    error?: string | null
    wrapperClassName?: string
    outerWrapperClassName?: string
}

const handleFocus = (e: React.FocusEvent<HTMLInputElement>): void => e.target.select()

const input = <T extends FieldValues>(
    field: Field<T>,
    form: UseFormReturn<T>,
    hasError: boolean,
): JSX.Element => {
    switch (field.type) {
        case 'radio':
            return (
                <div className="flex flex-row gap-2">
                    <input
                        type="radio"
                        id={field.id}
                        {...form.register(field.name, {
                            required: field.required ?? false,
                            ...field.options,
                        })}
                        className="rounded-md border border-gray-300"
                    />
                    <label htmlFor={field.name} className="text-sm text-gray-700 font-semibold">
                        {field.displayName}
                    </label>
                </div>
            )
        case 'number_slider':
            const [percentages, setPercentages] = useState<number>(0)
            const onSliderChangedHandler = (value: number | number[]): void => {
                if (Array.isArray(value)) return
                let actualValue = calculatePercentageOf(Number(field.max) ?? 0, value)
                const decimal = actualValue - Math.floor(actualValue)
                if (decimal > 0 && decimal < 0.5) {
                    actualValue = Math.floor(actualValue)
                    value = calculatePercentage(actualValue, Number(field.max) ?? 0)
                }
                if (decimal >= 0.5) {
                    actualValue = Math.ceil(actualValue)
                    value = calculatePercentage(actualValue, Number(field.max) ?? 0)
                }
                setPercentages(value)
                form.setValue(field.name, actualValue as PathValue<T, Path<T>>)
            }
            const onMaxButtonClickedHandler = (): void => {
                form.setValue(field.name, (field.max ?? 0) as PathValue<T, Path<T>>)
                setPercentages(100)
            }
            const onAmountOfCreditsChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
                let value = Number(e.target.value)
                const min = Number(field.min) ?? 0
                const max = Number(field.max) ?? 0
                if (value < min || isNaN(value)) {
                    value = min
                }
                if (value > max) {
                    value = max
                }
                form.setValue(field.name, Number(value.toFixed()) as PathValue<T, Path<T>>)
                setPercentages(calculatePercentage(value, max))
            }
            return (
                <div className="flex flex-col gap-1">
                    <div className="flex flex-row gap-2">
                        <input
                            id={field.id}
                            className={`border-0 py-2.5 rounded-lg w-full focus:ring-0 shadow-input hover:shadow-input-hover focus:shadow-input-focussed ${field.className}`}
                            type="number"
                            onFocus={handleFocus}
                            step={field.step ?? 1}
                            min={field.min ?? 0}
                            max={field.max ?? 1_000}
                            formNoValidate={false}
                            {...form.register(field.name, {
                                required: field.required ?? false,
                                onChange: onAmountOfCreditsChangeHandler,
                                ...field.options,
                            })}
                            placeholder={field.placeholder}
                        />
                        <SecondaryButton onClick={onMaxButtonClickedHandler} type="button">
                            Max
                        </SecondaryButton>
                    </div>
                    <div className="">
                        <QuantitySlider onChange={onSliderChangedHandler} value={percentages} />
                    </div>
                    <div className="mb-2">
                        <AlignMiddle direction="row" childClassName="-order-1 flex flex-row gap-2">
                            <div className="font-semibold">Available quantity (tCO2e):</div>
                            <div className="text-left">
                                {(field.max ?? 0).toLocaleString('en-US', {
                                    maximumFractionDigits: 6,
                                })}
                            </div>
                        </AlignMiddle>
                    </div>
                </div>
            )
        case 'number':
            return (
                <input
                    id={field.id}
                    className={`py-2.5 rounded-lg w-full outline-none border-0 focus:ring-0 shadow-input hover:shadow-input-hover focus:shadow-input-focussed ${field.className}`}
                    type="number"
                    onFocus={handleFocus}
                    step={field.step ?? 1}
                    min={field.min ?? 0}
                    max={field.max ?? 1_000}
                    formNoValidate={false}
                    {...form.register(field.name, {
                        required: field.required ?? false,
                        onChange: field.onChange,
                        ...field.options,
                    })}
                    placeholder={field.placeholder}
                />
            )
        case 'dollar':
            return (
                <input
                    id={field.id}
                    className={`bg-dollar bg-no-repeat bg-left bg-[length:20px_20px] py-2.5 px-6 rounded-lg w-full outline-none border-0 focus:ring-0 shadow-input hover:shadow-input-hover focus:shadow-input-focussed ${field.className}`}
                    type="number"
                    onFocus={handleFocus}
                    step={field.step ?? 1}
                    min={field.min ?? 0}
                    max={field.max ?? 1_000}
                    formNoValidate={false}
                    {...form.register(field.name, {
                        required: field.required ?? false,
                        onChange: field.onChange,
                        ...field.options,
                    })}
                    placeholder={field.placeholder}
                />
            )
        case 'text':
            return (
                <input
                    type="text"
                    id={field.id}
                    {...form.register(field.name, {
                        required: field.required ?? false,
                        ...field.options,
                    })}
                    className={`rounded-xl border-0 focus:ring-0 shadow-input flex-1 min-h-6 transition duration-150 ease-in hover:shadow-input-hover focus:shadow-input-focussed ${field.className}`}
                    placeholder={field.placeholder}
                />
            )
        case 'textarea':
            return (
                <textarea
                    disabled={field.disabled ?? false}
                    id={field.id}
                    {...form.register(field.name, {
                        required: field.required ?? false,
                        ...field.options,
                    })}
                    className={`rounded-xl border-0 focus:ring-0 shadow-input flex-1 min-h-6 transition duration-150 ease-in hover:shadow-input-hover focus:shadow-input-focussed ${field.className}`}
                    placeholder={field.placeholder}
                    rows={field.rows}
                />
            )
        case 'checkbox':
            return (
                <AlignMiddle direction="column">
                    <input
                        type={field.type}
                        id={field.id}
                        {...form.register(field.name, {
                            required: field.required ?? false,
                            ...field.options,
                        })}
                        className={`rounded-md border border-gray-300 flex-1 h-6 w-6 ${field.className}`}
                        autoComplete={field.autoComplete}
                        placeholder={field.placeholder}
                    />
                </AlignMiddle>
            )
        default:
            return (
                <input
                    type={field.type}
                    id={field.id}
                    {...form.register(field.name, {
                        required: field.required ?? false,
                        ...field.options,
                    })}
                    //className={`rounded-xl border-0 ring-1 ring-gray-300 flex-1 min-h-6 transition duration-150 ease-in hover:ring-orange-500 hover:ring-2 focus:ring-orange-300 focus:ring-2 shadow-[inset 0 0 0 1 rgb(255 0 0), 0 0 0 var(--lns-formFieldBorderWidthFocus) var(--lns-color-focusRing);] ${field.className}`}
                    className={`rounded-xl border-0 focus:ring-0 ${hasError ? 'shadow-input-error' : 'shadow-input'} flex-1 min-h-6 transition duration-150 ease-in hover:shadow-input-hover focus:shadow-input-focussed ${field.className}`}
                    autoComplete={field.autoComplete}
                    placeholder={field.placeholder}
                />
            )
    }
}

const Form = <T extends FieldValues>({
    title,
    onSubmit,
    formId,
    formClassName = 'mt-6 mr-6 ml-5.5',
    fields,
    headerListItems = [],
    children,
    buttons = [],
    buttonWrapperClassName,
    buttonInnerWrapperClassName,
    buttonAlignment = Alignment.NEXTLINE,
    form,
    footer,
    error,
    wrapperClassName = 'py-3 border-b border-gray-200',
    outerWrapperClassName = 'mb-4',
}: Props<T>): JSX.Element => {
    return (
        <div className={outerWrapperClassName}>
            <div className={wrapperClassName}>
                {title && <h3 className="mb-4 text-2xl font-bold text-gray-700">{title}</h3>}
                {headerListItems.length > 0 && (
                    <div className="flex flex-col my-8">
                        {headerListItems.map((item, index) => {
                            return (
                                <OverviewListItem
                                    label={item.label}
                                    value={item.value}
                                    bgColorClassName={isEven(index) ? 'bg-gray-50' : 'bg-white'}
                                    key={`${index}-${item.label}`}
                                />
                            )
                        })}
                    </div>
                )}
            </div>
            <form className={formClassName} onSubmit={form.handleSubmit(onSubmit)} id={formId}>
                <div className="flex flex-col gap-2">
                    {fields.map((field, index) => {
                        if (React.isValidElement(field))
                            return <div key={`${formId}_field_${index}`}>{field}</div>
                        field = field as Field<T>
                        const hasErrors: boolean = Boolean(
                            form.formState.errors?.[field.name]?.message,
                        )
                        return (
                            <div className="flex flex-col" key={`${formId}_field_${index}`}>
                                <div
                                    className={`flex gap-2 w-full ${field.alignment === Alignment.INLINE ? 'flex-row' : 'flex-col'} ${field.spacing ?? ''} ${field.hide ? 'hidden' : ''}`}
                                >
                                    <label
                                        htmlFor={field.name}
                                        className={
                                            field.labelClassName ??
                                            `text-sm text-gray-700 font-semibold`
                                        }
                                    >
                                        {field.displayName}
                                        {field.required && !(field.hideAsterix ?? false) && (
                                            <span className="text-red-500">*</span>
                                        )}
                                    </label>
                                    {field.alignment === Alignment.INLINE && (
                                        <div className="flex-1"></div>
                                    )}
                                    {field.description && (
                                        <div className="text-xs italic text-gray-500">
                                            {field.description}
                                        </div>
                                    )}
                                    <div
                                        className={`flex ${field.children?.alignment === Alignment.INLINE ? 'flex-row' : 'flex-col'} ${field.placement === Placement.BEFORE ? '-order-2' : ''} gap-2`}
                                    >
                                        {field.inputOverride && field.inputOverride}
                                        {!field.inputOverride && (
                                            <>{input(field, form, hasErrors)}</>
                                        )}
                                        {field.children?.value && field.children.value}
                                    </div>
                                </div>
                                {hasErrors && (
                                    <div className="text-xs text-red-500 mt-2">
                                        {String(form.formState.errors?.[field.name]?.message)}
                                    </div>
                                )}
                            </div>
                        )
                    })}
                    {children && children}
                </div>
                {error && <Note type={NoteType.ERROR}>{error}</Note>}
                {buttons.length > 0 && (
                    <AlignMiddle
                        direction="row"
                        childClassName={`${buttonWrapperClassName ?? 'order-1 flex-1'}`}
                    >
                        <div
                            className={`mt-7 flex ${buttonAlignment === Alignment.INLINE ? 'flex-row' : 'flex-col'} xs:flex-row gap-2 items-end ${buttonInnerWrapperClassName}`}
                        >
                            {buttons.map((button, index) => {
                                return (
                                    <Fragment key={`${formId}_button_${index}`}>
                                        <button.variation
                                            type={button.type}
                                            className={button.className}
                                            onClick={button.onClick}
                                            disabled={button.disabled}
                                            id={button.id}
                                        >
                                            {button.label}
                                        </button.variation>
                                        {button.tooltip && button.disabled && (
                                            <Tooltip anchorSelect={`#${button.id}`} place="top">
                                                {button.tooltip}
                                            </Tooltip>
                                        )}
                                    </Fragment>
                                )
                            })}
                        </div>
                    </AlignMiddle>
                )}
                {footer && footer}
            </form>
        </div>
    )
}

export default Form
