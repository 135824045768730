import { generatePath, Params } from 'react-router-dom'

export declare type QS<Key extends string = string> = {
    readonly [key in Key]: string | number | string[] | number[]
}

export const generatePathWithQueryString = (
    path: string,
    params: Params = {},
    qs: QS = {},
): string => {
    const generatedPath = generatePath(path, params)
    if (Object.keys(qs).length) {
        return Object.keys(qs).reduce((acc: string, key: string) => {
            if (acc === generatedPath) {
                return `${acc}?${key}=${qs[key]}`
            }
            return `${acc}&${key}=${qs[key]}`
        }, generatedPath)
    }
    return generatedPath
}
