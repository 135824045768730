import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AdminCarbonCreditBalanceApi } from '../../modules/carbonCreditBalance/http/api'
import { useAdminStore } from '../../../modules/admin/context/store'
import { SingleCarbonCreditBalance } from '../../../modules/carbonCreditBalance/types/SingleCarbonCreditBalance'
import { Page } from '../../../modules/common/enums/Pages'
import {
    convertMetricTonesToGramsNumeric,
    convertGramsToMetricTonesLocaleString,
    convertGramsToMetricTonnes,
} from '../../../utils/conversion'
import AdminLayout from '../../modules/common/components/AdminLayout'
import ConfirmationModal from '../../modules/common/components/ConfirmationModal'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'

const AdminEditCarbonCreditBalance = (): JSX.Element => {
    const { setShowAlertMessage } = useAdminStore()
    const { id } = useParams<{ id: string }>()

    const [carbonCreditBalance, setCarbonCreditBalance] =
        useState<SingleCarbonCreditBalance | null>(null)
    const [liquidAmount, setLiquidAmount] = useState<string>('')
    const [caasLiquidAmount, setCaasLiquidAmount] = useState<string>('')
    const [originalLiquidAmount, setOriginalLiquidAmount] = useState<string>('')
    const [originalCaasLiquidAmount, setOriginalCaasLiquidAmount] = useState<string>('')
    const [originalEscrowAmount, setOriginalEscrowAmount] = useState<string>('')
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)

    const navigate = useNavigate()

    const fetchCarbonCreditBalance = async (): Promise<void> => {
        if (!id) return
        try {
            const response = await AdminCarbonCreditBalanceApi.adminGetById(id)
            if (response) {
                setCarbonCreditBalance(response.data)
                setLiquidAmount(String(convertGramsToMetricTonnes(response.data.liquid_amount)))
                setCaasLiquidAmount(
                    String(convertGramsToMetricTonnes(response.data.caas_liquid_amount)),
                )
                setOriginalLiquidAmount(response.data.liquid_amount)
                setOriginalCaasLiquidAmount(response.data.caas_liquid_amount)
                setOriginalEscrowAmount(response.data.sell_order_amount)
            }
        } catch (e) {
            setShowAlertMessage({
                color: 'failure',
                text: 'An unexpected error has occurred, please try again',
            })
            throw e
        }
    }

    useEffect(() => {
        void fetchCarbonCreditBalance()
    }, [id])

    const saveChanges = async (): Promise<void> => {
        if (id) {
            try {
                await AdminCarbonCreditBalanceApi.patch(id, {
                    liquid_amount: convertMetricTonesToGramsNumeric(
                        Number(liquidAmount),
                    ).toString(),
                    caas_liquid_amount: convertMetricTonesToGramsNumeric(
                        Number(caasLiquidAmount),
                    ).toString(),
                })
                setShowAlertMessage({
                    color: 'success',
                    text: 'Successfully saved',
                })
                navigate(Page.ADMIN_CARBON_CREDIT_BALANCES)
            } catch (e) {
                setShowAlertMessage({
                    color: 'failure',
                    text: 'An unexpected error has occurred, please try again',
                })
                throw e
            }
        }
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.CARBON_CREDIT_BALANCES}>
            <div>
                <div className="mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">Project name</label>
                    <p>{carbonCreditBalance?.vintage?.project?.name}</p>
                </div>
                <div className="mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">Vintage</label>
                    <p>{carbonCreditBalance?.vintage?.vintage}</p>
                </div>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setShowConfirmationModal(true)
                    }}
                >
                    <div className="flex flex-col mb-6">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Available Balance(tCO2)
                        </label>
                        <input
                            type="number"
                            step={0.000001}
                            min={0.000001}
                            value={Number(liquidAmount)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                // [0,9] and empty string
                                const regex = /^[0-9]|^$/
                                if (regex.test(e.target.value)) {
                                    setLiquidAmount(!!e.target.value ? e.target.value : '0')
                                }
                            }}
                            onFocus={(e: React.FocusEvent<HTMLInputElement>): void =>
                                e.target.select()
                            }
                            className="w-full lg:w-44 rounded-lg border-gray-300 text-gray-500 font-normal text-base numeric-input-without-arrows"
                        />
                    </div>
                    <div className="flex flex-col mb-6">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Available CaaS Balance(tCO2)
                        </label>
                        <input
                            type="number"
                            step={0.000001}
                            min={0}
                            value={Number(caasLiquidAmount)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                // [0,9] and empty string
                                const regex = /^[0-9]|^$/
                                if (regex.test(e.target.value)) {
                                    setCaasLiquidAmount(!!e.target.value ? e.target.value : '0')
                                }
                            }}
                            onFocus={(e: React.FocusEvent<HTMLInputElement>): void =>
                                e.target.select()
                            }
                            className="w-full lg:w-44 rounded-lg border-gray-300 text-gray-500 font-normal text-base numeric-input-without-arrows"
                        />
                    </div>
                    <div className="flex items-center pt-6 space-x-2 border-t border-gray-200 rounded-b">
                        <input
                            disabled={originalLiquidAmount === liquidAmount}
                            type="submit"
                            value="Save"
                            className={`text-white ${
                                originalLiquidAmount === liquidAmount
                                    ? 'bg-blue-800'
                                    : 'bg-blue-700'
                            } hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                        />
                    </div>
                </form>
            </div>
            <ConfirmationModal
                show={showConfirmationModal}
                onClose={() => {
                    setShowConfirmationModal(false)
                    setLiquidAmount(originalLiquidAmount)
                    setCaasLiquidAmount(originalCaasLiquidAmount)
                }}
                onConfirm={saveChanges}
                question={
                    <span>
                        <div>
                            Edit Carbon Credit Balance:
                            <span className="pl-1 font-semibold text-gray-700">
                                {carbonCreditBalance?.vintage.project.name}
                            </span>
                        </div>
                        <div>
                            Entity Name:
                            <span className="pl-1 font-semibold text-gray-700">
                                {carbonCreditBalance?.tenant.entity_name}
                            </span>
                        </div>
                        <div>
                            <span> Vintage: </span>
                            <span className="pl-1 font-semibold text-gray-700">
                                {carbonCreditBalance?.vintage.vintage}
                            </span>
                        </div>
                        <div>
                            <span>Old Available Balance(tCO2): </span>
                            <span className="pl-1 font-semibold text-gray-700">
                                {convertGramsToMetricTonesLocaleString(
                                    Number(originalLiquidAmount),
                                )}
                            </span>
                        </div>
                        <div>
                            <span>New Available Balance(tCO2): </span>
                            <span className="pl-1 font-semibold text-gray-700">
                                {convertGramsToMetricTonesLocaleString(
                                    convertMetricTonesToGramsNumeric(Number(liquidAmount)),
                                )}
                            </span>
                        </div>
                        <div>
                            <span>Old Available CaaS Balance(tCO2): </span>
                            <span className="pl-1 font-semibold text-gray-700">
                                {convertGramsToMetricTonesLocaleString(
                                    Number(originalCaasLiquidAmount),
                                )}
                            </span>
                        </div>
                        <div>
                            <span>New Available CaaS Balance(tCO2): </span>
                            <span className="pl-1 font-semibold text-gray-700">
                                {convertGramsToMetricTonesLocaleString(
                                    convertMetricTonesToGramsNumeric(Number(caasLiquidAmount)),
                                )}
                            </span>
                        </div>
                        <div>
                            <span>Currently For Sale(tCO2): </span>
                            <span className="pl-1 font-semibold text-gray-700">
                                {convertGramsToMetricTonesLocaleString(
                                    Number(originalEscrowAmount),
                                )}
                            </span>
                        </div>
                    </span>
                }
            />
        </AdminLayout>
    )
}

export default AdminEditCarbonCreditBalance
