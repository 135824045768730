import { useEffect, useState } from 'react'
import Table, { Column, TextAlignment, Values } from '../../table/components/Table'
import { Vintage } from '../../vintage/types/Vintage'
import Tokenised from './Tokenised'
import {
    convertGramsToMetricTonesLocaleString,
    convertGramsToMetricTonnes,
} from '../../../utils/conversion'
import CreateSellOrderModal from '../../credits/components/CreateSellOrderModal'
import { Project } from '../../project/types/Project'
import { CarbonCreditBalanceSingleResult } from '../types/CarbonCreditBalance'
import AlignMiddle from '../../common/components/AlignMiddle'
import { VintageCarbonCreditBalance } from '../../vintage/types/VintageCarbonCreditBalance'
import { useUserStore } from '../../user/context/store'
import BuyRetireModal from '../../buyCredits/components/BuyRetireModal'
import { ActionName } from '../../action/enums/ActionName'
import UnbridgeCreditsModal from '../../credits/components/UnbridgeCreditsModal'
import { ListPlus, GlobeHemisphereWest, HandWithdraw } from '@phosphor-icons/react'
import Action from '../../common/components/Action'

interface Props {
    vintages: Vintage[]
    project: Project | CarbonCreditBalanceSingleResult
    reloadProject: () => Promise<void>
    hideEmptyBalances: boolean
    setDisplayTile?: (displayTile: boolean) => void
}

type Columns =
    | 'vintage'
    | 'liquid_amount'
    | 'caas_liquid_amount'
    | 'retired_amount'
    | 'sold_amount'
    | 'sell_order_amount'
    | 'pending_retirement_amount'
    | 'tokenised'
    | 'actions'

const CarbonCreditBalance = ({
    vintages,
    project,
    reloadProject,
    hideEmptyBalances,
    setDisplayTile = () => {},
}: Props): JSX.Element => {
    const [columns, setColumns] = useState<Column<Columns>[]>([])
    const [data, setData] = useState<Values<Columns>[]>([])
    const [showCreateSellOrderModal, setShowCreateSellOrderModal] = useState<boolean>(false)
    const [showUnbridgeModal, setShowUnbridgeModal] = useState<boolean>(false)
    const [selectedVintage, setSelectedVintage] = useState<Vintage | null>(null)
    const [selectedCarbonCreditBalance, setSelectedCarbonCreditBalance] =
        useState<VintageCarbonCreditBalance | null>(null)
    const [showBuyModal, setShowBuyModal] = useState<boolean>(false)
    const { isCaasProUser, isMarketplaceUser } = useUserStore()
    const caasProUser = isCaasProUser()
    const marketplaceUser = isMarketplaceUser()
    useEffect(() => {
        if (!vintages || vintages.length === 0) return
        const vintageMap: Map<string, Vintage> = new Map(
            vintages.map((vintage) => {
                return [vintage.id, vintage]
            }),
        )
        const balances = vintages.flatMap((vintage) => {
            return vintage.carbon_credit_balances ?? []
        })
        if (balances.length === 0) {
            return
        }

        const actions = (balance: VintageCarbonCreditBalance) => {
            let actions: JSX.Element[] = []

            if (convertGramsToMetricTonnes(balance.liquid_amount) > 0) {
                actions.push(
                    <Action
                        key={`sell-credits-${balance.vintage_id}`}
                        id={`sell-credits-${balance.vintage_id}`}
                        IconElement={ListPlus}
                        tooltip="Sell Credits"
                        dataSelector="sell-credits"
                        onClick={() => {
                            setSelectedVintage(vintageMap.get(balance.vintage_id) ?? null)
                            setShowCreateSellOrderModal(true)
                        }}
                    />,
                )
            }

            if (convertGramsToMetricTonnes(balance.liquid_amount) > 0 && caasProUser) {
                actions.push(
                    <Action
                        key={`retire-credits-${balance.vintage_id}`}
                        id={`retire-credits-${balance.vintage_id}`}
                        IconElement={GlobeHemisphereWest}
                        tooltip="Retire Credits"
                        dataSelector="retire-credits"
                        onClick={() => {
                            setSelectedVintage(vintageMap.get(balance.vintage_id) ?? null)
                            setSelectedCarbonCreditBalance(balance)
                            setShowBuyModal(true)
                        }}
                    />,
                )
            }

            if (convertGramsToMetricTonnes(balance.liquid_amount) > 0 && marketplaceUser) {
                actions.push(
                    <Action
                        key={`unbridge-credits-${balance.vintage_id}`}
                        id={`unbridge-credits-${balance.vintage_id}`}
                        IconElement={HandWithdraw}
                        tooltip="Withdraw Credits"
                        dataSelector="unbridge-credits"
                        onClick={() => {
                            setSelectedVintage(vintageMap.get(balance.vintage_id) ?? null)
                            setSelectedCarbonCreditBalance(balance)
                            setShowUnbridgeModal(true)
                        }}
                    />,
                )
            }

            return (
                <div className="flex flex-row gap-4">
                    {actions.map((action) => {
                        return action
                    })}
                </div>
            )
        }
        const cols: Column<Columns>[] = [
            {
                name: 'vintage',
                display: 'Vintage',
                textAlignment: TextAlignment.RIGHT,
            },
            {
                name: 'liquid_amount',
                display: 'Hold Balance',
                textAlignment: TextAlignment.RIGHT,
            },
            {
                name: 'caas_liquid_amount',
                display: 'CaaS Balance',
                textAlignment: TextAlignment.RIGHT,
            },
            {
                name: 'sell_order_amount',
                display: 'For Sale',
                textAlignment: TextAlignment.RIGHT,
            },
            {
                name: 'sold_amount',
                display: 'Sold',
                textAlignment: TextAlignment.RIGHT,
            },
            {
                name: 'pending_retirement_amount',
                display: 'Pending Retirement',
                textAlignment: TextAlignment.RIGHT,
            },
            {
                name: 'retired_amount',
                display: 'Retired',
                textAlignment: TextAlignment.RIGHT,
            },
            {
                name: 'tokenised',
                display: 'Tokenized',
                textAlignment: TextAlignment.CENTRE,
            },
            {
                name: 'actions',
                display: 'Action',
                textAlignment: TextAlignment.CENTRE,
            },
        ]

        setColumns(cols)

        let balancesToDisplay = balances

        if (hideEmptyBalances) {
            balancesToDisplay = balances.filter((balance) => {
                return Number(balance.liquid_amount) > 0 || Number(balance.caas_liquid_amount) > 0
            })
        }

        const values: Values<Columns>[] = balancesToDisplay.map((balance) => {
            return {
                vintage: {
                    value: vintageMap.get(balance.vintage_id)?.vintage ?? '',
                    textAlignment: TextAlignment.RIGHT,
                    className: 'w-[80px] 2xl:w-[100px]',
                },
                liquid_amount: {
                    value: convertGramsToMetricTonesLocaleString(Number(balance.liquid_amount)),
                    textAlignment: TextAlignment.RIGHT,
                    className: 'w-[80px] 2xl:w-[100px]',
                },
                caas_liquid_amount: {
                    value: convertGramsToMetricTonesLocaleString(
                        Number(balance.caas_liquid_amount),
                    ),
                    textAlignment: TextAlignment.RIGHT,
                    className: 'w-[80px] 2xl:w-[100px]',
                },
                sell_order_amount: {
                    value: convertGramsToMetricTonesLocaleString(Number(balance.sell_order_amount)),
                    textAlignment: TextAlignment.RIGHT,
                    className: 'w-[80px] 2xl:w-[100px]',
                },
                sold_amount: {
                    value: convertGramsToMetricTonesLocaleString(Number(balance.sold_amount)),
                    textAlignment: TextAlignment.RIGHT,
                    className: 'w-[80px] 2xl:w-[100px]',
                },
                pending_retirement_amount: {
                    value: convertGramsToMetricTonesLocaleString(
                        Number(balance.pending_retirement_amount),
                    ),
                    textAlignment: TextAlignment.RIGHT,
                    className: 'w-[90px] 2xl:w-[100px]',
                },
                retired_amount: {
                    value: convertGramsToMetricTonesLocaleString(Number(balance.retired_amount)),
                    textAlignment: TextAlignment.RIGHT,
                    className: 'w-[80px] 2xl:w-[100px]',
                },
                tokenised: {
                    value: (
                        <AlignMiddle direction="row">
                            <Tokenised backedBySource={balance.backed_by_source} />
                        </AlignMiddle>
                    ),
                    className: 'w-[83px] 2xl:w-[100px]',
                    textAlignment: TextAlignment.CENTRE,
                },
                actions: {
                    value: actions(balance),
                    textAlignment: TextAlignment.CENTRE,
                    className: 'min-w-[80px]',
                },
            }
        })
        setData(values)
    }, [vintages, hideEmptyBalances])

    useEffect(() => {
        if (hideEmptyBalances && data.length === 0) {
            setDisplayTile(false)
        } else {
            setDisplayTile(true)
        }
    })

    if (!vintages || vintages.length === 0 || data.length === 0) {
        return <></>
    }

    const onModalClose = (reload: boolean = false) => {
        setShowCreateSellOrderModal(false)
        setShowUnbridgeModal(false)
        if (!reload) return
        reloadProject()
    }

    return (
        <div>
            <Table columns={columns} data={data} />
            <CreateSellOrderModal
                show={showCreateSellOrderModal && !!selectedVintage}
                onClose={onModalClose}
                vintage={selectedVintage}
                project={project}
            />
            <UnbridgeCreditsModal
                show={showUnbridgeModal && !!selectedVintage}
                onClose={onModalClose}
                vintage={selectedVintage}
                project={project}
            />
            <BuyRetireModal
                carbonCreditBalance={selectedCarbonCreditBalance}
                vintage={selectedVintage}
                project={project}
                show={showBuyModal}
                showPurchaseComplete={false}
                setShowPurchaseComplete={() => {}}
                setShow={setShowBuyModal}
                defaultAction={ActionName.RETIRE}
                onComplete={() => {
                    reloadProject()
                }}
            />
        </div>
    )
}

export default CarbonCreditBalance
