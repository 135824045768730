import StatusPill, { CircleStyle, Colour } from '../../statusPill/components/StatusPill'
import { TradeHistoryTypes } from '../enums/TradeHistoryTypes'

interface Props {
    type: TradeHistoryTypes
    width?: string
}

const TradeHsitoryTypePill = ({ type, width }: Props): JSX.Element => {
    switch (type) {
        case TradeHistoryTypes.BUY:
            return (
                <StatusPill colour={Colour.GREEN} circleStyle={CircleStyle.NONE} width={width}>
                    Buy
                </StatusPill>
            )
        case TradeHistoryTypes.SELL:
            return (
                <StatusPill colour={Colour.RED} circleStyle={CircleStyle.NONE} width={width}>
                    Sell
                </StatusPill>
            )
        default:
            throw new Error(`Unknown buy order status: ${status}`)
    }
}

export default TradeHsitoryTypePill
