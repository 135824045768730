import { AxiosResponse } from 'axios'
import HttpService from '../../../../modules/http/httpService'
import { EmptyResponse } from '../../../../modules/http/types/EmptyResponse'
import { ImageFileType } from '../../../../modules/common/enums/ImageFileType'
import { SearchProjectParams } from './types/SearchProjectParams'
import { SearchProjectsResponse } from './types/SearchProjectsResponse'
import { Project } from '../../../../modules/project/types/Project'
import { SaveProjectPayload } from './types/SaveProjectPayload'
import { ProjectImageBusinessType } from '../enums/ProjectImageBusinessType'
import { GeneratePreSignedUrlResponse } from './types/GeneratePreSignedUrlResponse'

export interface ProjectApiInterface {
    search(params: SearchProjectParams): Promise<AxiosResponse<SearchProjectsResponse> | undefined>
    getById(id: string): Promise<AxiosResponse<Project> | undefined>
    create(payload: SaveProjectPayload): Promise<AxiosResponse<EmptyResponse> | undefined>
    generateSignedUrl(
        id: string,
        imageType: ProjectImageBusinessType,
        fileType: ImageFileType,
    ): Promise<AxiosResponse<GeneratePreSignedUrlResponse> | undefined>
    confirmUpload(
        id: string,
        imageType: ProjectImageBusinessType,
        fileType: ImageFileType,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
}

export const AdminProjectApi: ProjectApiInterface = Object.freeze({
    search(
        params: SearchProjectParams,
    ): Promise<AxiosResponse<SearchProjectsResponse> | undefined> {
        let queryString: any = {
            name: params.name,
            relations: params.relations,
        }
        if (params.perPage) {
            queryString = {
                ...queryString,
                per_page: String(params.perPage),
            }
        }
        if (params.page) {
            queryString = {
                ...queryString,
                page: String(params.page),
            }
        }
        return HttpService.get('/admin/projects', null, queryString)
    },
    getById(id: string): Promise<AxiosResponse<Project> | undefined> {
        return HttpService.get('/admin/projects', id)
    },
    create(payload: SaveProjectPayload): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('/admin/projects', payload)
    },
    generateSignedUrl(
        id: string,
        imageType: ProjectImageBusinessType,
        fileType: ImageFileType,
    ): Promise<AxiosResponse<GeneratePreSignedUrlResponse> | undefined> {
        return HttpService.post('/admin/projects/signed-url', {
            project_id: id,
            image_type: imageType,
            file_type: fileType,
        })
    },
    confirmUpload(
        id: string,
        imageType: ProjectImageBusinessType,
        fileType: ImageFileType,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('/admin/projects/confirm-upload', {
            project_id: id,
            image_type: imageType,
            file_type: fileType,
        })
    },
})
