import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { SellOrder } from '../../sellOrders/types/SellOrder'
import { EmptyResponse } from '../../http/types/EmptyResponse'
import { PatchMyOrderPayload } from './types/PatchMyOrderPayload'
import { SearchMyOrdersParams } from './types/SearchMyOrdersParams'
import { SearchMyOrdersResponse } from './types/SearchMyOrdersResponse'

export interface MyOrderInterface {
    search(params: SearchMyOrdersParams): Promise<AxiosResponse<SearchMyOrdersResponse> | undefined>
    delete(sellOrder: SellOrder): Promise<AxiosResponse<EmptyResponse> | undefined>
    patch(id: string, payload: PatchMyOrderPayload): Promise<AxiosResponse<SellOrder> | undefined>
    get(id: string): Promise<AxiosResponse<SellOrder> | undefined>
}

export const MyOrderApi: MyOrderInterface = Object.freeze({
    search(
        params: SearchMyOrdersParams,
    ): Promise<AxiosResponse<SearchMyOrdersResponse> | undefined> {
        return HttpService.get('/my-orders', null, params)
    },
    delete(sellOrder: SellOrder): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.delete(`/my-orders/${sellOrder.id}`, {
            updated_at: sellOrder.updated_at,
        })
    },
    patch(id: string, payload: PatchMyOrderPayload): Promise<AxiosResponse<SellOrder> | undefined> {
        return HttpService.patch(`/my-orders/${id}`, payload)
    },
    get(id: string): Promise<AxiosResponse<SellOrder> | undefined> {
        return HttpService.get(`/my-orders`, id)
    },
})
