import { Page } from '../../../modules/common/enums/Pages'
import SubMenu from '../../../modules/subMenu/components/SubMenu'
import { TabModes } from '../../../modules/subMenu/types/Tab'
import { RoleName } from '../../../modules/user/enums/RoleName'
import { OrderSubMenuActions } from '../enums/OrderSubMenuActions'

interface OrdersSubMenuInterface {
    selectedItem: OrderSubMenuActions
}

const OrdersSubMenu = ({ selectedItem }: OrdersSubMenuInterface): JSX.Element => {
    return (
        <div className="my-6 w-full">
            <SubMenu<OrderSubMenuActions>
                tabs={[
                    {
                        type: OrderSubMenuActions.BUY_ORDERS,
                        text: 'Buy Orders',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ORDERS_BUY_ORDERS,
                        },
                        allowedRoles: [RoleName.CAAS_USER, RoleName.CAAS_PRO],
                    },
                    {
                        type: OrderSubMenuActions.SELL_ORDERS,
                        text: 'Sell Orders',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ORDERS_SELL_ORDERS,
                        },
                        allowedRoles: [RoleName.CAAS_PRO, RoleName.MARKETPLACE_USER],
                    },
                    {
                        type: OrderSubMenuActions.ALL_TRADES,
                        text: 'All Trades',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ORDERS_ALL_TRADES,
                        },
                        allowedRoles: [
                            RoleName.CAAS_USER,
                            RoleName.CAAS_PRO,
                            RoleName.MARKETPLACE_USER,
                        ],
                    },
                    {
                        type: OrderSubMenuActions.INVOICES,
                        text: 'Invoices',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ORDERS_INVOICES,
                        },
                        allowedRoles: [RoleName.CAAS_USER, RoleName.CAAS_PRO],
                    },
                    {
                        type: OrderSubMenuActions.PAYOUTS,
                        text: 'Payouts',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ORDERS_PAYOUTS,
                        },
                        allowedRoles: [RoleName.CAAS_PRO, RoleName.MARKETPLACE_USER],
                    },
                ]}
                selectedItem={selectedItem}
                id="orders-sub-menu"
            />
        </div>
    )
}

export default OrdersSubMenu
