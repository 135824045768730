import { convertGramsToMetricTonnes, convertCentsToLocaleUsd } from '../../../utils/conversion'
import { actionButtonText, actionText, actionType, confirmationDescription } from '../../action'
import { ActionName } from '../../action/enums/ActionName'
import { DangerButton, PrimaryButton, SecondaryButton } from '../../common/components/Button'
import { Vintage } from '../../vintage/types/Vintage'
import OverviewListItem from '../../common/components/OverviewListItem'
import { Project } from '../../project/types/Project'
import AlignMiddle from '../../common/components/AlignMiddle'
import { CarbonCreditBalanceSingleResult } from '../../carbonCreditBalance/types/CarbonCreditBalance'
import Note, { NoteType } from '../../note/components/Note'
import { getUSDCurrencyString } from '../../../utils/getUSDCurrencyString'

interface BuyCreditsFormProps {
    actionName: ActionName
    price: number | string
    quantity: number
    vintage?: Vintage
    project: Project | CarbonCreditBalanceSingleResult
    showSpinner: boolean
    pricePerCredit?: string
    pricePerCreditCents: number
    subtitle?: JSX.Element
    onClose: () => void
    onBack?: () => void
    onConfirm: () => Promise<void>
    retireeName?: string
    isSubmitButtonEnabled?: boolean
}

const CreditsConfirmation = ({
    actionName,
    showSpinner,
    price,
    quantity,
    vintage,
    project,
    pricePerCredit,
    pricePerCreditCents,
    subtitle,
    onClose,
    onBack,
    onConfirm,
    retireeName,
    isSubmitButtonEnabled = true,
}: BuyCreditsFormProps): JSX.Element => {
    const confirmButton = (actionName: ActionName): JSX.Element => {
        if (
            [ActionName.RETURN, ActionName.CANCEL, ActionName.CANCEL_SELL_ORDER].includes(
                actionName,
            )
        ) {
            return (
                <DangerButton
                    className={`w-40 break-words`}
                    disabled={showSpinner || !isSubmitButtonEnabled}
                    onClick={onConfirm}
                    type="submit"
                    id="confirm_purchase"
                >
                    {actionButtonText(actionName)}
                </DangerButton>
            )
        }
        return (
            <PrimaryButton
                className={`w-40 break-words`}
                disabled={showSpinner || !isSubmitButtonEnabled}
                onClick={onConfirm}
                type="submit"
                id="confirm_purchase"
            >
                {actionButtonText(actionName)}
            </PrimaryButton>
        )
    }
    return (
        <>
            <div className="p-6" id="purchase_confirmation">
                <div className="text-2xl font-bold text-gray-700 text-left pb-2 border-b border-gray-200">
                    <div>{actionText(actionName)}</div>
                    {subtitle && <div>{subtitle}</div>}
                </div>
                <div className="flex flex-col my-8">
                    <OverviewListItem
                        label={['Project']}
                        key="project"
                        value={project.name ?? ''}
                        bgColorClassName="bg-gray-50"
                    />
                    <OverviewListItem
                        label={['Vintage']}
                        key="vintage"
                        value={String(vintage?.vintage)}
                        bgColorClassName="bg-white"
                    />
                    <OverviewListItem
                        label={['Quantity (tCO2e)']}
                        key="quantity"
                        value={String(convertGramsToMetricTonnes(quantity))}
                        bgColorClassName="bg-gray-50"
                    />
                    {[
                        ActionName.SELL,
                        ActionName.UPDATE_SELL_ORDER,
                        ActionName.CANCEL_SELL_ORDER,
                    ].includes(actionName) && [
                        <OverviewListItem
                            label={['Price per credit']}
                            key="price"
                            value={getUSDCurrencyString(pricePerCreditCents)}
                            bgColorClassName="bg-white"
                        />,
                        <p
                            className="font-semibold text-gray-700 mb-1.5 mt-2 text-xl px-6"
                            key="total_price"
                        >
                            Total Price:
                            <span className="ml-1">
                                {getUSDCurrencyString(
                                    pricePerCreditCents *
                                        convertGramsToMetricTonnes(quantity.toString()),
                                )}
                            </span>
                        </p>,
                    ]}
                    {[ActionName.PURCHASE, ActionName.BUY_RETIRE, ActionName.CANCEL].includes(
                        actionName,
                    ) && [
                        <OverviewListItem
                            label={['Price Per Credit']}
                            key="price_per_credit"
                            value={Number(pricePerCredit ?? '0').toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                            bgColorClassName="bg-white"
                        />,
                        ![ActionName.CANCEL].includes(actionName) ? (
                            <OverviewListItem
                                label={['Purchase Type']}
                                key="purchase_type"
                                value={actionType(actionName)}
                                bgColorClassName="bg-gray-50"
                            />
                        ) : undefined,
                        [ActionName.BUY_RETIRE].includes(actionName) ? (
                            <OverviewListItem
                                label={['Retiree']}
                                key="retiree_name"
                                value={retireeName || ''}
                                bgColorClassName="bg-white"
                            />
                        ) : undefined,
                        <p
                            className="font-semibold text-gray-700 mb-1.5 mt-2 text-xl px-6"
                            key="total_price"
                        >
                            Total Price:
                            <span className="ml-1">{convertCentsToLocaleUsd(BigInt(price))}</span>
                        </p>,
                    ]}

                    {confirmationDescription(actionName) && (
                        <div
                            className="mt-4 text-sm font-normal text-gray-500 text-center"
                            id="confirmation_alert"
                        >
                            <div>
                                {' '}
                                {[
                                    ActionName.INTEREST,
                                    ActionName.SELL,
                                    ActionName.PURCHASE,
                                ].includes(actionName) ? (
                                    <Note type={NoteType.INFO}>
                                        {confirmationDescription(actionName)}
                                    </Note>
                                ) : (
                                    <Note type={NoteType.WARNING}>
                                        {confirmationDescription(actionName)}
                                    </Note>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <AlignMiddle direction="row" childClassName="order-1 flex-1">
                    <div className="flex flex-col xs:flex-row gap-2 items-end">
                        {onBack && (
                            <SecondaryButton
                                type="button"
                                onClick={() => onBack()}
                                disabled={showSpinner}
                            >
                                Back
                            </SecondaryButton>
                        )}
                        <SecondaryButton
                            type="button"
                            onClick={() => onClose()}
                            disabled={showSpinner}
                        >
                            Close
                        </SecondaryButton>
                        {confirmButton(actionName)}
                    </div>
                </AlignMiddle>
            </div>
        </>
    )
}

export default CreditsConfirmation
