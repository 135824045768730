import { SearchCarbonCreditBalancesResponse } from '../http/types/SearchCarbonCreditBalancesResponse'
import { SelectedFilters } from './reducer'

export const SET_SEARCH_RESPONSE = 'SET_SEARCH_RESPONSE'
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS'
export const SET_PROJECT_TYPE_FILTER_VISIBLE = 'SET_PROJECT_TYPE_FILTER_VISIBLE'
export const SET_IS_FORM_DISABLED = 'SET_IS_FORM_DISABLED'
export const SET_IS_CARBON_CREDIT_FILTERS_MODAL_VISIBLE =
    'SET_IS_CARBON_CREDIT_FILTERS_MODAL_VISIBLE'
export const SET_SHOW_ALL_LOCATIONS = 'SET_SHOW_ALL_LOCATIONS'
export const SET_SHOW_ALL_SDGS = 'SET_SHOW_ALL_SDGS'

export type CarbonCreditFiltersAction =
    | SetSearchResponseAction
    | SetSelectedFiltersAction
    | SetProjectTypeFilterVisibleAction
    | SetIsFormDisabledAction
    | SetIsCarbonCreditFiltersModalVisibleAction
    | SetShowAllLocationsAction
    | SetShowAlSdgsAction

export interface SetIsCarbonCreditFiltersModalVisibleAction {
    type: typeof SET_IS_CARBON_CREDIT_FILTERS_MODAL_VISIBLE
    payload: SetIsCarbonCreditFiltersModalVisiblePayload
}

export interface SetIsCarbonCreditFiltersModalVisiblePayload {
    isFiltersModalVisible: boolean
}

export interface SetShowAllLocationsAction {
    type: typeof SET_SHOW_ALL_LOCATIONS
    payload: SetShowAllLocationsPayload
}

export interface SetShowAllLocationsPayload {
    show: boolean
}

export interface SetShowAlSdgsAction {
    type: typeof SET_SHOW_ALL_SDGS
    payload: SetShowAlSdgsPayload
}

export interface SetShowAlSdgsPayload {
    show: boolean
}

export interface SetSelectedFiltersAction {
    type: typeof SET_SELECTED_FILTERS
    payload: SetSelectedFiltesPayload
}

export interface SetSelectedFiltesPayload {
    filters: SelectedFilters
}

export interface SetSearchResponseAction {
    type: typeof SET_SEARCH_RESPONSE
    payload: SetSearchResponsePayload
}

export interface SetSearchResponsePayload {
    response: SearchCarbonCreditBalancesResponse
}

export interface SetProjectTypeFilterVisibleAction {
    type: typeof SET_PROJECT_TYPE_FILTER_VISIBLE
    payload: {
        isVisible: boolean
    }
}

export interface SetIsFormDisabledAction {
    type: typeof SET_IS_FORM_DISABLED
    payload: { isDisabled: boolean }
}
