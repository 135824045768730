import { Page } from '../../common/enums/Pages'
import { CookieKey } from '../enums/CookieKey'
import { setCookie, deleteCookie, getCookie } from '../useCookie'
import { setIsAcceptCookiesBannerVisibleAction } from './actions'
import { cookiesManagementReducer, initialState } from './reducer'
import { createContext, useContext, useEffect, useReducer } from 'react'

export const CookiesManagementStore = createContext(initialState)
// @ts-nocheck
export const useCookiesManagementStore = () => useContext(CookiesManagementStore)

export const CookiesManagementStoreProvider = ({ children }: { children: JSX.Element }): any => {
    const [state, dispatch] = useReducer(cookiesManagementReducer, initialState)

    const setShowAcceptCookiesBannerVisible = (isVisible: boolean) => {
        setIsAcceptCookiesBannerVisibleAction(isVisible, dispatch)
    }

    const acceptCookies = () => {
        setCookie(CookieKey.COOKIES_ACCEPTED_AT, new Date().toDateString(), {
            expires: new Date(2099, 1, 1).toUTCString(),
        })

        deleteCookie(CookieKey.COOKIES_DECLINED_AT)
        setShowAcceptCookiesBannerVisible(false)
    }

    const declineCookies = () => {
        setCookie(CookieKey.COOKIES_DECLINED_AT, new Date().toDateString(), {
            expires: 'Max-Age',
        })
        deleteCookie(CookieKey.JWT_TOKEN)
        deleteCookie(CookieKey.REFRESH_TOKEN)
        setShowAcceptCookiesBannerVisible(false)
        if (
            !window.location.href.includes(Page.SIGNIN) &&
            !window.location.href.includes(Page.FORGOT_PASSOWRD) &&
            !window.location.href.includes(Page.SIGNUP)
        ) {
            window.location.href = Page.SIGNIN
        }
    }

    const areCookiesAccepted = () => !!getCookie(CookieKey.COOKIES_ACCEPTED_AT)

    const areCookiesDeclined = () => !!getCookie(CookieKey.COOKIES_DECLINED_AT)

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!areCookiesAccepted() && !areCookiesDeclined()) {
                setShowAcceptCookiesBannerVisible(true)
            }
        }, 1000)

        // clear timeout
        return () => clearTimeout(timer)
    }, [!state.state.isAcceptCookiesBannerVisible])

    return (
        <CookiesManagementStore.Provider
            value={{
                state: {
                    isAcceptCookiesBannerVisible: state.state.isAcceptCookiesBannerVisible,
                },
                acceptCookies,
                declineCookies,
                areCookiesAccepted,
                areCookiesDeclined,
                setShowAcceptCookiesBannerVisible,
                // setShowAlertMessage,
            }}
        >
            {children}
        </CookiesManagementStore.Provider>
    )
}
