import { SortSellOrderColumns } from '../../myOrders/context/types'
import { ProjectLocation } from '../../project/enums/ProjectLocation'
import { ProjectType } from '../../project/enums/ProjectType'
import { SDG } from '../../project/enums/SDG'
import { SortOrder } from '../http/types/SearchSellOrdersParams'
import { SearchSellOrdersResponse } from '../http/types/SearchSellOrdersResponse'
import {
    SellOrdersAction,
    SET_IS_FILTERS_MODAL_VISIBLE,
    SET_IS_FORM_DISABLED,
    SET_SEARCH_SELL_ORDERS_RESPONSE,
    SET_SEARCH_SELL_ORDER_FILTERS,
    SET_SHOULD_SHOW_PRICE_FILTERS,
    SET_SHOULD_SHOW_PROJECT_TYPE_FILTER,
    SET_SHOW_ALL_LOCATIONS,
    SET_SHOW_ALL_SDGS,
} from './types'

interface Price {
    min: number
    max: number
}

export interface SearchSellOrderFilters {
    projectName: string
    projectType: ProjectType[]
    price: Price
    locations: ProjectLocation[]
    sdgs: SDG[]
    vintages: number[]
    sortByColumnName: SortSellOrderColumns | null
    sortByOrder: SortOrder | null
}

export const defaultFilters: SearchSellOrderFilters = {
    projectName: '',
    projectType: [],
    price: {
        min: 0,
        max: 0,
    },
    locations: [],
    sdgs: [],
    vintages: [],
    sortByColumnName: null,
    sortByOrder: null,
}

export interface SellOrdersState {
    searchSellOrdersResponse: SearchSellOrdersResponse | null
    shouldShowPriceFilters: boolean
    shouldShowProjectTypeFilter: boolean
    searchSellOrderFilters: SearchSellOrderFilters
    isFormDisabled: boolean
    isFiltersModalVisible: boolean
    showAllLocations: boolean
    showAllSdgs: boolean
    setSearchSellOrdersResponse: (response: SearchSellOrdersResponse | null) => void
    setSearchSellOrderFilters: (filters: SearchSellOrderFilters) => void
    setShouldShowPriceFilters: (show: boolean) => void
    setIsFormDisabled: (isDisabled: boolean) => void
    setIsFiltersModalVisible: (isVisible: boolean) => void
    setShowAllLocations: (show: boolean) => void
    setShowAllSdgs: (show: boolean) => void
    setShouldShowProjectTypeFilter: (show: boolean) => void
    reset: () => void
}

export const inititalSellOrdersState: SellOrdersState = {
    searchSellOrdersResponse: null,
    shouldShowPriceFilters: false,
    shouldShowProjectTypeFilter: false,
    searchSellOrderFilters: defaultFilters,
    isFormDisabled: false,
    isFiltersModalVisible: false,
    showAllLocations: false,
    showAllSdgs: false,
    setSearchSellOrdersResponse: () => {},
    setSearchSellOrderFilters: () => {},
    setShouldShowPriceFilters: () => {},
    setIsFormDisabled: () => {},
    setIsFiltersModalVisible: () => {},
    setShowAllLocations: () => {},
    setShowAllSdgs: () => {},
    setShouldShowProjectTypeFilter: () => {},
    reset: () => {},
}

export const SellOrdersReducer = (
    state = inititalSellOrdersState,
    action: SellOrdersAction,
): SellOrdersState => {
    switch (action.type) {
        case SET_SEARCH_SELL_ORDERS_RESPONSE:
            return {
                ...state,
                searchSellOrdersResponse: action.payload.response,
            }
        case SET_SEARCH_SELL_ORDER_FILTERS:
            return {
                ...state,
                searchSellOrderFilters: action.payload.filters,
            }
        case SET_SHOULD_SHOW_PRICE_FILTERS:
            return {
                ...state,
                shouldShowPriceFilters: action.payload.show,
            }
        case SET_IS_FORM_DISABLED:
            return {
                ...state,
                isFormDisabled: action.payload.isDisabled,
            }
        case SET_IS_FILTERS_MODAL_VISIBLE:
            return {
                ...state,
                isFiltersModalVisible: action.payload.isVisible,
            }
        case SET_SHOW_ALL_LOCATIONS:
            return {
                ...state,
                showAllLocations: action.payload.show,
            }
        case SET_SHOW_ALL_SDGS:
            return {
                ...state,
                showAllSdgs: action.payload.show,
            }
        case SET_SHOULD_SHOW_PROJECT_TYPE_FILTER:
            return {
                ...state,
                shouldShowProjectTypeFilter: action.payload.show,
            }
        default:
            return state
    }
}
