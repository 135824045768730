export enum Page {
    MARKET = '/',
    SIGNIN = '/signin',
    FORGOT_PASSOWRD = '/forgot-password/',
    CONFIRM_2FA = '/confirm-2fa/',
    EMAIL_VERIFICATION = '/email-verification/:email', // TODO: v2: DO WE NEED?
    SET_NEW_PASSWORD = '/set-new-password/:hash',
    SIGNUP = '/signup', // TODO: v2: remove
    WALLET = '/wallet', // TODO: v2: remove
    INVENTORY = '/inventory',
    ORDERS = '/orders',
    MY_CREDIT = '/my-credit/:carbonCreditBalanceId', // TODO: v2: remove
    PROJECT = '/project/:id',
    CONTACT_SUPPORT = '/contact-support',
    CHANGE_PASSWORD = '/change-password',
    TERMS_AND_CONDITIONS = '/terms-and-conditions',
    LINK_BANK_ACCOUNT = '/link-bank-account', // TODO: v2: remove
    ACCOUNT_SETTINGS_DETAILS = '/account-settings/details',
    ACCOUNT_SETTINGS_SECURITY = '/account-settings/security',
    RETURN_CREDITS = '/return-credits', // TODO: v2: remove - just have a Withdraw button the CCB screen with a popup for them to withdraw the credits to the registry
    // and MAYBE a New Withdrawal button on the credit-withdrawals screen which shows a pop up and allows selection of a project/vintage
    CAAS_DASHBOARD = '/dashboard',

    // NEW
    ORDERS_BUY_ORDERS = '/orders/buy-orders',
    ORDERS_BUY_ORDER = '/buy-order/:id',
    ORDERS_SELL_ORDERS = '/orders/sell-orders',
    ORDERS_ALL_TRADES = '/orders/all-trades',
    ORDERS_INVOICES = '/orders/invoices',
    ORDERS_INVOICE = '/invoice/:id',
    ORDERS_PAYOUTS = '/orders/payouts',
    ORDERS_PAYOUT = '/payout/:id',
    ORDERS_SELL_ORDER = '/sell-order/:id',
    RETIREMENTS = '/retirements',
    RETIREMENT = '/retirement/:id',
    PUBLIC_RETIREMENT = 'public/retirement/:id',
    PUBLIC_RETIREMENT_VERIFY = 'public/verify-retirement', // TODO: v2: we probably don't need this yet, just including so we don't forget about it
    CREDIT_WITHDRAWALS = '/credit-withdrawals',

    // ADMIN
    ADMIN_PROJECTS = '/admin/projects',
    ADMIN_CREATE_PROJECT = '/admin/projects/create',
    ADMIN_CREATE_VINTAGE = '/admin/projects/:id/vintages/create',
    ADMIN_USERS = '/admin/users',
    ADMIN_CREATE_USER = '/admin/users/create',
    ADMIN_EDIT_USER = '/admin/users/:id/edit',
    ADMIN_CARBON_CREDIT_BALANCES = '/admin/carbon-credit-balances',
    ADMIN_CREATE_CARBON_CREDIT_BALANCE = '/admin/carbon-credit-balances/create',
    ADMIN_EDIT_CARBON_CREDIT_BALANCE = '/admin/carbon-credit-balances/:id/edit',
    ADMIN_FEATURE_FLAG = '/admin/feature-flags',
    ADMIN_EDIT_FEATURE_FLAG = '/admin/feature-flags/:id/edit',
    ADMIN_EDIT_PROJECT = '/admin/projects/:id/edit',
    ADMIN_EDIT_TENANT = '/admin/tenant/:id/edit',
    ADMIN_INVOICES = '/admin/invoices',
    ADMIN_INVOICE_DETAILS = '/admin/invoices/:id',
    ADMIN_UNBRIDGE_REQUESTS = '/admin/unbridge-requests',
    ADMIN_COMPLETE_UNBRIDGE_REQUEST = '/admin/unbridge-requests/:id/complete',
    ADMIN_RETIREMENT_REQUESTS = '/admin/retirement-requests',
    ADMIN_COMPLETE_RETIREMENT_REQUEST = '/admin/retirement-requests/:id/complete',
    ADMIN_TRANSACTION_FEE = '/admin/transaction-fee',
    ADMIN_EDIT_TRANSACTION_FEE = '/admin/transaction-fee/:tenantId/edit',
    ADMIN_REPORTS = '/admin/reports',
    ADMIN_PAYOUTS = '/admin/payouts/overview',
    ADMIN_SELLER_PAYOUTS = '/admin/payouts/seller/:sellerTenantId',
    ADMIN_PAYOUTS_DETAILS = '/admin/payouts/:payoutId',
    ADMIN_BUY_ORDERS = '/admin/buy-orders',
}
