import { Modal, ToggleSwitch } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { User } from '../../../../modules/user/types/User'
import { ApiKeyType } from '../http/types/ApiKeyResponse'
import { AdminUserApi } from '../http/AdminUserApi'
import { CopyButton } from '../../common/components/CopyButton'

interface ApiKeysModalProps {
    show: boolean
    user: User
    onClose: () => void
}

enum ApiKeyModalType {
    CREATE_API_KEY = 'CREATE_API_KEY',
    COPY_API_KEY = 'COPY_API_KEY',
}

const ApiKeysModal = ({ show, user, onClose }: ApiKeysModalProps): JSX.Element => {
    const API_KEYS_MAX_COUNT = 2
    const [apiKeys, setApiKeys] = useState<ApiKeyType[]>([])
    const [showApiKeyCreateModal, setShowApiKeyCreateModal] = useState<ApiKeyModalType | null>(null)
    const [apiKeyName, setApiKeyName] = useState<string>('')
    const [apiKey, setApiKey] = useState<null | string>(null)

    const generateApiKey = async (userId: string, apiKeyName: string) => {
        const response = await AdminUserApi.generateApiKeyByUserId(userId, apiKeyName)
        setShowApiKeyCreateModal(ApiKeyModalType.COPY_API_KEY)
        if (response) {
            setApiKey(response.data.api_key)
        }
    }

    const fetchApiKeys = async () => {
        const response = await AdminUserApi.getAllApiKeysByUserId(user.id)
        if (response) {
            setApiKeys(response.data.results)
        }
    }

    const closeSecureModal = () => {
        setApiKey(null)
        void fetchApiKeys()
        setApiKeyName('')
        setShowApiKeyCreateModal(null)
    }

    const activeApiKeysCount = apiKeys.filter((apiKey) => apiKey.is_active).length

    const canGenerateNewApiKey = (): boolean => {
        return apiKeys.filter((apiKey) => apiKey.is_active).length < API_KEYS_MAX_COUNT
    }

    useEffect(() => {
        if (user) {
            void fetchApiKeys()
        }
    }, [user])

    if (showApiKeyCreateModal === ApiKeyModalType.CREATE_API_KEY) {
        return (
            <Modal size="2xl" show={showApiKeyCreateModal === ApiKeyModalType.CREATE_API_KEY}>
                <div className="p-6 text-center">
                    <h3 className="text-lg font-normal text-gray-500">
                        Please set a name to a new Api Key
                    </h3>
                    <div className="flex flex-col my-8">
                        <label className="text-sm text-left font-semibold text-gray-700">
                            ApiKey name:
                        </label>
                        <input
                            type="text"
                            placeholder="Key Name"
                            onBlur={(e) => setApiKeyName(e.target.value)}
                            className="h-11 rounded-lg border-gray-300 mt-1.5"
                        />
                    </div>
                    <div className="w-full flex justify-around">
                        <button
                            onClick={closeSecureModal}
                            type="button"
                            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                        >
                            Close
                        </button>
                        <button
                            disabled={apiKeyName.length === 0}
                            onClick={() => void generateApiKey(user.id, apiKeyName)}
                            type="button"
                            className="text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10"
                        >
                            Generate
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
    if (showApiKeyCreateModal === ApiKeyModalType.COPY_API_KEY && apiKey) {
        return (
            <Modal size="2xl" show={showApiKeyCreateModal === ApiKeyModalType.COPY_API_KEY}>
                <div className="p-6 text-center">
                    <h3 className="text-lg font-normal text-gray-500">
                        Please copy and save new API key.
                    </h3>
                    <div className="my-8 bg-gray-300 flex justify-between items-center">
                        <p
                            className="w-[90%] px-2"
                            style={{
                                wordWrap: 'break-word' /* All browsers since IE 5.5+ */,
                                overflowWrap: 'break-word',
                            }}
                        >
                            {apiKey}
                        </p>
                        <CopyButton text={apiKey} />
                    </div>
                    <button
                        onClick={closeSecureModal}
                        type="button"
                        className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                    >
                        Close
                    </button>
                </div>
            </Modal>
        )
    }

    return (
        <Modal show={show} size="6xl" id="modal">
            <div className="h-[90vh] overflow-x-auto p-10 text-center">
                <div className="h-full flex flex-col justify-between">
                    <h3 className="text-lg font-normal text-gray-500">Api Keys Management</h3>
                    <div className="w-full flex justify-between">
                        <button
                            disabled={!canGenerateNewApiKey()}
                            className={`px-5 py-2.5 flex justify-center items-center border border-black mb-2 rounded-lg ${
                                !canGenerateNewApiKey()
                                    ? 'bg-gray-200 cursor-not-allowed border-gray-100'
                                    : ''
                            }`}
                            onClick={() => setShowApiKeyCreateModal(ApiKeyModalType.CREATE_API_KEY)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-4"
                                viewBox="0 0 448 512"
                            >
                                <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                            </svg>
                            <p className="inline-block ml-2">Generate Api Key</p>
                        </button>
                        <div>
                            <span>
                                Active Api keys: <b>{activeApiKeysCount}</b>
                            </span>
                            <span className="inline-block ml-4">
                                Max count possible: <b>{API_KEYS_MAX_COUNT}</b>
                            </span>
                        </div>
                    </div>
                    <div className="my-4 h-[80%] overflow-y-auto">
                        <table className="h-full w-full">
                            <thead className="h-[10%]">
                                <tr className="h-11 flex items-center w-full bg-gray-50">
                                    <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                        ID
                                    </th>
                                    <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                        NAME
                                    </th>
                                    <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                        IS ACTIVE
                                    </th>
                                    <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                        CREATED AT
                                    </th>
                                    <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                        UPDATED AT
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="h-[80%]">
                                {apiKeys?.map((apiKey) => {
                                    return (
                                        <tr
                                            key={apiKey.id}
                                            className="h-18 flex items-center w-full border-b-2 border-gray-200"
                                        >
                                            <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                {apiKey.id}
                                            </td>
                                            <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                {apiKey.name}
                                            </td>
                                            <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                {apiKey.is_active}
                                                <ToggleSwitch
                                                    label=""
                                                    key={`${apiKey.id}toggleSwitch`}
                                                    disabled={
                                                        !apiKey.is_active && !canGenerateNewApiKey()
                                                    }
                                                    checked={apiKey.is_active}
                                                    onChange={() => {
                                                        void AdminUserApi.setApiKeyIsActive(
                                                            apiKey.id,
                                                            !apiKey.is_active,
                                                        ).then(() => {
                                                            void fetchApiKeys()
                                                        })
                                                    }}
                                                />
                                            </td>
                                            <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                {apiKey.created_at}
                                            </td>
                                            <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                {apiKey.updated_at}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <button
                        onClick={onClose}
                        type="button"
                        className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                    >
                        Close
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ApiKeysModal
