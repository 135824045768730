import { Link, generatePath } from 'react-router-dom'
import EllipsisText from '../../common/components/EllipsisText'
import ProjectSquareImage from '../../project/components/ProjectSquareImage'
import { Project } from '../../project/types/Project'
import { Vintage } from '../../vintage/types/Vintage'
import { CarbonCreditBalanceSingleResult } from '../types/CarbonCreditBalance'
import CarbonCreditBalance from './CarbonCreditBalance'
import { Page } from '../../common/enums/Pages'
import ProjectDetailsTable from '../../project/components/ProjectDetailsTable'
import AlignMiddle from '../../common/components/AlignMiddle'
import { useState } from 'react'

interface Props {
    vintages: Vintage[]
    project: Project | CarbonCreditBalanceSingleResult
    reloadProject: () => Promise<void>
    hideEmptyBalances: boolean
}

const ProjectDetailCarbonCreditBalance = ({
    vintages,
    project,
    reloadProject,
    hideEmptyBalances,
}: Props): JSX.Element => {
    const [displayTile, setDisplayTile] = useState<boolean>(true)
    if (
        !vintages ||
        vintages.length === 0 ||
        vintages.flatMap((vintage) => {
            return vintage.carbon_credit_balances ?? []
        }).length === 0
    )
        return <></>

    const originUrl = window.location.origin
    const url = new URL(
        generatePath(Page.PROJECT, {
            id: project.id,
        }),
        originUrl,
    )

    return (
        <div
            className={`flex flex-col shadow-md ${displayTile ? '' : 'hidden'}`}
            data-type="inventory-card"
        >
            <div className="flex flex-col md:flex-row">
                <Link className="link" to={url.href.replace(originUrl, '')}>
                    <div className="flex flex-row gap-2">
                        <AlignMiddle direction="column" childClassName="-order-1">
                            <ProjectSquareImage
                                squareImageUrl={project.square_image_url}
                                type={project.type}
                                height="h-40"
                                width="w-40"
                                rounded="rounded-lg"
                                id={project.id}
                            />
                        </AlignMiddle>
                        <div className="md:hidden pr-2 text-sm">
                            <EllipsisText text={project.name} maxLength={70} />
                        </div>
                    </div>
                </Link>
                <div className="flex flex-col p-2 gap-3 w-full">
                    <div className="hidden md:block">
                        <Link className="link" to={url.href.replace(originUrl, '')}>
                            <EllipsisText text={project.name} maxLength={70} />
                        </Link>
                    </div>
                    <ProjectDetailsTable project={project} />
                </div>
            </div>
            <CarbonCreditBalance
                vintages={vintages}
                project={project}
                reloadProject={reloadProject}
                hideEmptyBalances={hideEmptyBalances}
                setDisplayTile={setDisplayTile}
            />
        </div>
    )
}

export default ProjectDetailCarbonCreditBalance
