import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { PrimaryButton } from '../../../modules/common/components/Button'
import { Page } from '../../../modules/common/enums/Pages'
import { getEmailRegex } from '../../../utils/getEmailRegex'
import { Alignment, FormElement } from '../../../modules/form/types/Field'
import { Button } from '../../../modules/form/types/Button'
import Form from '../../../modules/form/components/Form'

export interface SignInFormProps {
    signInError: null | string
    onSubmit: (payload: SigninForm) => void
}

export interface SigninForm {
    email: string
    password: string
    rememberMe: boolean
}

const SignInForm = ({ signInError, onSubmit }: SignInFormProps) => {
    const form = useForm<SigninForm>()
    const submitHandler = async () => {
        const data = form.getValues()
        onSubmit(data)
    }
    const fields: FormElement<SigninForm>[] = [
        {
            name: 'email',
            type: 'email',
            displayName: 'Email',
            required: true,
            hideAsterix: true,
            options: {
                required: true,
                pattern: getEmailRegex(),
            },
            autoComplete: 'email',
            placeholder: 'Enter your email',
            id: 'login-username',
        },
        {
            displayName: 'Password',
            type: 'password',
            name: 'password',
            required: true,
            hideAsterix: true,
            options: {
                required: true,
            },
            autoComplete: 'current-password',
            placeholder: 'Enter your password',
            id: 'login-password',
        },
        {
            name: 'rememberMe',
            displayName: 'Remember for 30 days',
            type: 'checkbox',
            id: 'login-rememeber',
            alignment: Alignment.INLINE,
            className: 'w-6',
        },
        <Link
            className="text-primary-700 font-semibold text-sm cursor-pointer"
            to={Page.FORGOT_PASSOWRD}
            title="Forgot password page"
        >
            Forgot password
        </Link>,
    ]

    const buttons: Button[] = [
        {
            type: 'submit',
            label: 'Sign in',
            id: 'login-submit',
            variation: PrimaryButton,
            className: 'w-full',
        },
    ]

    const footer: React.ReactNode = (
        <p className="flex justify-center mt-3">
            <span className="text-gray-500 font-normal text-sm mr-1">New to the platform?</span>{' '}
            <Link
                to={Page.SIGNUP}
                rel="forgot password page"
                className="text-primary-700 font-semibold text-sm cursor-pointer"
            >
                Register Interest
            </Link>
        </p>
    )

    return (
        <Form
            title=""
            onSubmit={submitHandler}
            formId="signin-form"
            fields={fields}
            buttons={buttons}
            form={form}
            footer={footer}
            error={signInError}
            formClassName="w-full mt-2"
            buttonWrapperClassName="order-1 w-full px-2"
        />
    )
}

export default SignInForm
