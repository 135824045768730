import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { bigIntToLocale } from '../../../utils/conversion'
import { RegistryName } from '../../registry/enums/RegistryName'

interface RegistryData {
    label: string
    value: number
}
interface RegistryProps {
    data: RegistryData[]
}

const Registry = ({ data }: RegistryProps): JSX.Element => {
    ChartJS.register(ArcElement, Tooltip)

    const backgroundColorMap = new Map([
        [RegistryName.GOLD_STANDARD, '#5A6060'],
        [RegistryName.VERRA, '#D56A2F'],
        [RegistryName.GLOBAL_CARBON_COUNCIL, '#D8B0A1'],
        [RegistryName.AMERICAN_CARBON_REGISTRY, '#FEF3E5'],
        [RegistryName.CLIMATE_ACTION_RESERVE, '#804E9B'],
        [RegistryName.BIOCARBON_REGISTRY, '#40518B'],
        [RegistryName.CERCARBONO_ECOREGISTRY, '#84A5DF'],
        [RegistryName.PLAN_VIVO, '#F3A074'],
        [RegistryName.UNFCCC_CLEAN_DEVELOPMENT_MECHANISM, '#D58AFF'],
        [RegistryName.PURO, '#7390CA'],
        [RegistryName.CENTIGRADE, '#096F5C'],
        [RegistryName.SOCIAL_CARBON, '#00A5E2'],
    ])

    const datasets = {
        labels: data.map((d) => d.label),
        datasets: [
            {
                data,
                backgroundColor: data.map((d) => backgroundColorMap.get(d.label as RegistryName)),
                borderWidth: 1,
            },
        ],
    }

    const options = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context: any) => {
                        const value = context.parsed || 0
                        return `${bigIntToLocale(value)} (tCO2e)`
                    },
                },
            },
        },
    }

    return (
        <div
            className="w-full flex flex-col rounded-lg border border-gray-200 h-full justify-between"
            style={{
                boxShadow:
                    '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}
        >
            <div>
                <div className="flex flex-row items-center justify-between px-6 pt-6">
                    <p className="text-lg font-semibold text-gray-900">Registry</p>
                    <p className="hidden text-xs leading-[18px] font-semibold text-gray-400">
                        This Year
                    </p>
                </div>
                <p className="text-gray-500 px-6 text-base font-normal">Issuer</p>
                <div className={`flex ${data.length === 0 ? 'h-full' : ''} justify-center py-6`}>
                    {data.length > 0 && (
                        <div className="w-60 h-60">
                            <Doughnut data={datasets} id="my-chart" options={options} />
                        </div>
                    )}
                    {data.length === 0 && (
                        <div className="flex justify-center h-full items-end">
                            <p className="text-base italic font-semibold text-gray-400">No data</p>
                        </div>
                    )}
                </div>
                <div className="grid grid-cols-2 px-6">
                    {data.map((data, index) => {
                        return (
                            <div key={index} className="mb-1 flex flex-row gap-2 items-center">
                                <div
                                    className="w-2 h-2 rounded"
                                    style={{
                                        backgroundColor: backgroundColorMap.get(
                                            data.label as RegistryName,
                                        ),
                                    }}
                                />
                                <p className="text-gray-500 text-sm font-normal">{data.label}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="hidden flex-row items-center justify-end p-4 bg-gray-100 bottom-0 w-full">
                <button
                    className="text-sm font-semibold border border-gray-300 px-4 py-2.5 rounded-lg"
                    style={{
                        boxShadow:
                            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
                    }}
                >
                    More Details
                </button>
            </div>
        </div>
    )
}

export default Registry
