export enum SDG {
    NO_POVERTY = 'No Poverty',
    ZERO_HUNGER = 'Zero Hunger',
    HEALTH_AND_WELL_BEING = 'Health and Well Being',
    QUALITY_EDUCATION = 'Quality Education',
    GENDER_EQUALITY = 'Gender Equality',
    WATER_BENEFIT = 'Water Benefit',
    CLEAN_ENERGY = 'Clean Energy',
    ECONOMIC_GROWTH = 'Economic Growth',
    INFRASTRUCTURE = 'Infrastructure',
    REDUCED_INEQUALITIES = 'Reduced Inequalities',
    SUSTAINABLE_CITIES = 'Sustainable Cities',
    RESPONSIBLE_CONSUMPTION_AND_PRODUCTION = 'Responsible Consumption and Production',
    CLIMATE_ACTION = 'Climate Action',
    LIFE_BELOW_WATER = 'Life Below Water',
    LIFE_ON_LAND = 'Life On Land',
    PEACE_AND_JUSTICE = 'Peace and Justice',
    PARTNERSHIPS_FOR_THE_GOALS = 'Partnerships for the Goals',
}
