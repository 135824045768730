import { EmptyResponse } from '../../http/types/EmptyResponse'
import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { SignInResponse } from '../../auth/http/types/SignInResponse'
import { SubCustomerResponse } from '../types/SubCustomerResponse'
import { ChangePasswordPayload } from './types/ChangePasswordPayload'
import { CreateSubCustomerPayload } from './types/CreateSubCustomerPayload'
import { IsResetPasswordHashValid } from './types/IsResetPasswordHashValid'
import { ToggleMfaRequest } from './types/ToggleMfaRequest'
import { SendEmailSupportRequestPayload } from './types/SendEmailSupportRequestPayload'

export interface UserApiInterface {
    changePassword(
        payload: ChangePasswordPayload,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
    forgotPassword(
        email: string,
        grecaptcha_token: string,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
    validateForgotPasswordHash(
        hash: string,
    ): Promise<AxiosResponse<IsResetPasswordHashValid> | undefined>
    setNewPassword(
        forgotPasswordRequestHash: string,
        password: string,
        grecaptcha_token: string,
    ): Promise<AxiosResponse<SignInResponse> | undefined>
    getUserDetails(): Promise<AxiosResponse<any>>
    getSubCustomers(): Promise<AxiosResponse<SubCustomerResponse[]>>
    createSubCustomer(
        payload: CreateSubCustomerPayload,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
    setMfaEnabled(payload: ToggleMfaRequest): Promise<AxiosResponse<EmptyResponse> | undefined>
    sendEmailSupportRequest(
        payload: SendEmailSupportRequestPayload,
        requestId: string,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
}

export const UserApi: UserApiInterface = Object.freeze({
    changePassword(
        payload: ChangePasswordPayload,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('/users/password', payload)
    },
    async forgotPassword(
        email: string,
        grecaptcha_token: string,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('/users/forgot-password', { email, grecaptcha_token })
    },
    validateForgotPasswordHash(hash: string): Promise<AxiosResponse<IsResetPasswordHashValid>> {
        return HttpService.get('/users/forgot-password', hash)
    },
    async setNewPassword(
        forgotPasswordRequestHash: string,
        password: string,
        grecaptcha_token: string,
    ): Promise<AxiosResponse<SignInResponse> | undefined> {
        return HttpService.post('/users/set-new-password', {
            forgot_password_hash: forgotPasswordRequestHash,
            password,
            grecaptcha_token,
        })
    },
    getUserDetails(): Promise<AxiosResponse<any>> {
        return HttpService.get('/users/user-details')
    },
    getSubCustomers(): Promise<AxiosResponse<SubCustomerResponse[]>> {
        return HttpService.get('/users/sub-customers')
    },
    createSubCustomer(
        payload: CreateSubCustomerPayload,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('/users/sub-customers', payload)
    },
    setMfaEnabled(payload: ToggleMfaRequest): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('/two-factor-authentication', payload)
    },
    sendEmailSupportRequest(
        payload: SendEmailSupportRequestPayload,
        requestId: string,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('/email-support/send-request-to-support', payload, {
            'request-id': requestId,
        })
    },
})
