import React from 'react'

interface Props {
    label: string
    children: React.ReactNode
}

const RecordField = ({ label, children }: Props): JSX.Element => {
    return (
        <div className="" data-type="record">
            <div className="text-gray-500 text-sm mb-2">{label}</div>
            <div>{children}</div>
        </div>
    )
}

export default RecordField
