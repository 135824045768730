import HamburgerMenu from './HamburgerMenu'
import Logo from './Logo'
import NavBar, { NavBarItemName } from './NavBar'
import UserAvatar from './UserAvatar'
import UserMenu from './UserMenu'
import { useState } from 'react'

interface HeaderProps {
    renderMenu: boolean
    selectedNavBarItem?: NavBarItemName
}

const Header = ({ renderMenu = true, selectedNavBarItem }: HeaderProps): JSX.Element => {
    const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState<boolean>(false)

    const toggleHamburgerMenu = (): void => setIsHamburgerMenuOpen(!isHamburgerMenuOpen)

    if (renderMenu && !selectedNavBarItem) {
        throw new Error('Must supply selectedNavBarItem when renderMenu is true')
    }

    return (
        <nav className="container mx-auto min-h-18 pt-4 px-2 sm:px-8 " id="header">
            <div className="h-12 flex items-center gap-8">
                <div className="relative flex items-center justify-between h-16 w-full">
                    <div className="flex items-center">
                        {renderMenu && (
                            <div className="flex flex-row gap-2 items-center">
                                <div className="block lg:hidden">
                                    <HamburgerMenu
                                        isOpen={isHamburgerMenuOpen}
                                        onClick={toggleHamburgerMenu}
                                    />
                                </div>
                            </div>
                        )}
                        <Logo />
                        {renderMenu && (
                            <div className="hidden lg:block">
                                <NavBar selectedNavBarItem={selectedNavBarItem!} />
                            </div>
                        )}
                    </div>
                    <div className="flex items-center">
                        <UserMenu />
                        <UserAvatar />
                    </div>
                </div>
            </div>
            {isHamburgerMenuOpen && (
                <NavBar isMobileVersion={true} selectedNavBarItem={selectedNavBarItem!} />
            )}
        </nav>
    )
}

export default Header
