import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import { Link, useParams } from 'react-router-dom'
import BuyOrderStatusPill from '../../modules/buyOrders/components/BuyOrderStatusPill'
import SubSection from '../../modules/common/components/SubSection'
import { SecondaryButton } from '../../modules/common/components/Button'
import RecordField from '../../modules/recordDetails/components/RecordField'
import { convertCentsToLocaleUsd } from '../../utils/conversion'
import BuyOrderTradesTable from '../../modules/buyOrders/components/BuyOrderTradesTable'
import { Fragment, useEffect, useState } from 'react'
import { BuyOrderStatus } from '../../modules/buyOrders/http/enums/BuyOrderStatus'
import RecordDetailActions from '../../modules/recordDetails/components/RecordDetailActions'
import { BuyOrderDto } from '../../modules/buyOrders/http/types/BuyOrderDto'
import CancelBuyOrderModal from '../../modules/buyOrders/components/CancelBuyOrderModal'
import * as Sentry from '@sentry/react'
import Note, { NoteType } from '../../modules/note/components/Note'
import LoadingSpinner from '../../modules/loadingSpinner/components/LoadingSpinner'
import { BuyOrderApi } from '../../modules/buyOrders/http/BuyOrderApi'
import ID from '../../modules/common/components/ID'
import RetirementsTable from '../../modules/retirement/components/RetirementsTable'

const BuyOrder = (): JSX.Element => {
    const [buyOrder, setBuyOrder] = useState<BuyOrderDto>()
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string | null>(null)
    const { id } = useParams()

    const getRecord = async (): Promise<void> => {
        try {
            setErrMsg(null)
            setIsLoading(true)
            setBuyOrder((await BuyOrderApi.getById(id!)).data)
            setIsLoading(false)
        } catch (err) {
            setErrMsg(
                'There was a problem loading your buy order. Please refresh the page to try again.',
            )
            setIsLoading(false)
            Sentry.captureException(err)
        }
    }

    useEffect(() => {
        getRecord()
    }, [id])

    return (
        <Layout
            title="Buy Order"
            subTitle={buyOrder ? buyOrder.id : ''}
            dataCy="buy-order-layout"
            selectedNavBarItem={NavBarItemName.ORDERS}
            topRightComponent={
                !isLoading && buyOrder && buyOrder.status === BuyOrderStatus.OPEN ? (
                    <RecordDetailActions>
                        <SecondaryButton
                            onClick={() => {
                                setShowCancelModal(true)
                            }}
                        >
                            Cancel Order
                        </SecondaryButton>
                    </RecordDetailActions>
                ) : (
                    <></>
                )
            }
            topLeftComponent={
                <Link className="link text-sm" to="/orders/buy-orders">
                    {'<< All Buy Orders'}
                </Link>
            }
        >
            <>
                {isLoading && <LoadingSpinner></LoadingSpinner>}
                {errMsg && <Note type={NoteType.ERROR}>{errMsg}</Note>}
                {buyOrder && !isLoading && !errMsg && (
                    <Fragment>
                        {(buyOrder.status === BuyOrderStatus.OPEN ||
                            buyOrder.status === BuyOrderStatus.PENDING_SETTLEMENT ||
                            buyOrder.status === BuyOrderStatus.CLOSED) && (
                            <div className="mb-8">
                                <Note type={NoteType.INFO}>
                                    Please pay invoice{' '}
                                    <ID link={`/invoice/${buyOrder.invoice_id}`}>
                                        {buyOrder.invoice_id}
                                    </ID>{' '}
                                    to complete the buy order
                                </Note>
                            </div>
                        )}
                        <div className="grid grid-rows-4 md:grid-cols-4 md:grid-rows-1 gap-4">
                            <RecordField label="Status">
                                <BuyOrderStatusPill status={buyOrder.status} />
                            </RecordField>
                            <RecordField label="Invoice">
                                <ID link={`/invoice/${buyOrder.invoice_id}`}>
                                    {buyOrder.invoice_id}
                                </ID>
                            </RecordField>
                            <RecordField label="Total">
                                <div className="font-bold">
                                    {convertCentsToLocaleUsd(buyOrder.total)}
                                </div>
                            </RecordField>
                        </div>
                        <SubSection title="Trades">
                            <BuyOrderTradesTable trades={buyOrder.trades} />
                        </SubSection>
                        <SubSection title="">
                            <RetirementsTable buyOrderId={buyOrder.id} displayHeading={true} />
                        </SubSection>
                    </Fragment>
                )}
                {!buyOrder && !isLoading && !errMsg && (
                    <Note type={NoteType.INFO}>No buy order found</Note>
                )}
                <CancelBuyOrderModal
                    buyOrder={buyOrder!}
                    show={showCancelModal && !!buyOrder}
                    onClose={(success) => {
                        setShowCancelModal(false)
                        if (success) {
                            getRecord()
                        }
                    }}
                />
            </>
        </Layout>
    )
}

export default BuyOrder
