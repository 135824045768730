import { ToggleSwitch } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { User } from '../../../../modules/user/types/User'
import { Role } from '../../../../modules/user/types/Role'
import ConfirmationModal from '../../common/components/ConfirmationModal'
import { AdminUserApi } from '../http/AdminUserApi'

interface ManageRolesModalProps {
    user: User | null
    onUpdate: () => void
    onClose: () => void
}
const ManageRolesModal = ({ user, onUpdate, onClose }: ManageRolesModalProps): JSX.Element => {
    const [roles, setRoles] = useState<Role[]>(user?.roles ?? [])
    const [existingRoles, setExistingRoles] = useState<Role[]>([])
    const [showAreYouSure, setShowAreYouSure] = useState<boolean>(false)

    const fetchExistingRoles = async (): Promise<void> => {
        const response = await AdminUserApi.getAllRoles()
        if (response) {
            setExistingRoles(response.data)
        }
    }

    useEffect(() => {
        if (user) {
            fetchExistingRoles()
            setRoles(user.roles ?? [])
        }
    }, [user])

    const onConfirm = async (): Promise<void> => {
        if (user) {
            await AdminUserApi.updateUserRoles(
                roles.map((role) => role.id),
                user.id,
            )
            setShowAreYouSure(false)
            setRoles([])
            onUpdate()
        }
    }

    return (
        <ConfirmationModal
            show={!!user}
            onClose={() => {
                setShowAreYouSure(false)
                setRoles([])
                onClose()
            }}
            onConfirm={() => {
                if (!showAreYouSure) {
                    setShowAreYouSure(true)
                } else {
                    onConfirm()
                }
            }}
            confirmButtonText={showAreYouSure ? 'Are you sure?' : 'Confirm'}
            question={
                <div className="p-6 flex flex-col">
                    <p className="mb-5 text-lg font-normal text-gray-500">
                        User: {user?.first_name} {user?.last_name}
                    </p>
                    <p className="text-lg font-normal text-gray-500 text-left mb-2">Roles:</p>
                    {existingRoles.map((existingRole: Role) => {
                        return (
                            <div key={existingRole.id} className="mb-4">
                                <ToggleSwitch
                                    checked={roles.some(
                                        (role: Role) => role.id === existingRole.id,
                                    )}
                                    label={existingRole.display_name}
                                    onChange={(checked) => {
                                        if (checked) {
                                            setRoles([...roles, existingRole])
                                        } else {
                                            setRoles(
                                                roles.filter((role) => role.id !== existingRole.id),
                                            )
                                        }
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
            }
        />
    )
}

export default ManageRolesModal
