import { Link, useSearchParams } from 'react-router-dom'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import { Page } from '../../../modules/common/enums/Pages'
import UsersTable from '../../modules/user/components/UsersTable'
import { useEffect, useState } from 'react'
import { SearchTenantsResponse } from '../../modules/tenant/http/types/SearchTenantsResponse'
import { SearchUserParams } from '../../modules/user/http/types/SearchUserParams'
import { AdminTenantApi } from '../../modules/tenant/http/AdminTenantApi'

const Users = (): JSX.Element => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchTenantsResponse, setSearchTenantsResponse] =
        useState<SearchTenantsResponse | null>(null)

    const searchUsers = async (params: Partial<SearchUserParams> = {}): Promise<void> => {
        const response = await AdminTenantApi.search(params)
        if (response) {
            setSearchTenantsResponse(response.data)
        }
    }

    useEffect(() => {
        void searchUsers({
            page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
            per_page: 100,
        })
    }, [])

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.USERS}>
            <div className="flex justify-end max-h-[5%]">
                <Link
                    to={Page.ADMIN_CREATE_USER}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                    </svg>
                    Add User
                </Link>
            </div>
            <UsersTable
                searchTenantsResponse={searchTenantsResponse}
                onPageChangeHandler={(pageNumber: number) => {
                    setSearchParams({ page: String(pageNumber) })
                    void searchUsers({
                        page: pageNumber,
                    })
                }}
                onRoleUpdated={() => {
                    void searchUsers({ page: Number(searchParams.get('page') ?? 1) })
                }}
            />
        </AdminLayout>
    )
}

export default Users
