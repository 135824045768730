import { useEffect } from 'react'
import { Page } from '../../common/enums/Pages'
import { NavBarItemName } from './NavBar'
import { Link } from 'react-router-dom'

export enum NavBarItemTextColor {
    GRAY_500 = 'gray-500',
    GRAY_700 = 'gray-700',
}

interface NavBarItemProps {
    text: NavBarItemName
    route: Page
    isSelected?: boolean
    color?: NavBarItemTextColor
}

const NavBarItem = ({ text, route, isSelected = false }: NavBarItemProps): JSX.Element => {
    useEffect(() => {
        if (isSelected) {
            document.title = `${text} | Thallo`
        }
    })

    return (
        <Link
            className={`px-3 flex items-center cursor-pointer rounded-md select-none text-nowrap ${
                isSelected ? 'bg-orange-400' : 'hover:bg-orange-400 text-gray-900 hover:text-white'
            }`}
            to={route}
        >
            <p className={`text-base ${isSelected ? 'text-white' : ``}`}>{text}</p>
        </Link>
    )
}

export default NavBarItem
