import { ActionName } from '../../action/enums/ActionName'
import { Project } from '../../project/types/Project'
import { SellOrder } from '../../sellOrders/types/SellOrder'
import { Vintage } from '../../vintage/types/Vintage'
import BuyCreditsDetails from '../../buyCredits/components/BuyCreditsDetails'
import ModifySellOrderForm from '../../project/components/ModifySellOrderForm'
import { useEffect, useState } from 'react'
import ConfirmCancelingSellOrderModal from './ConfirmCancelingSellOrderModal'
import { useUserStore } from '../../user/context/store'
import EditSellOrderModal from './EditSellOrderModal'
import { VintageCarbonCreditBalance } from '../../vintage/types/VintageCarbonCreditBalance'

interface Props {
    sellOrder: SellOrder | null
    vintages: Map<number, Vintage>
    project: Project | null
    vintage: Vintage | null
    onChangeSellOrder: (sellOrderId: string) => void
    refreshProject: () => Promise<void>
}

const Credits = ({ sellOrder, vintage, project, refreshProject }: Props): JSX.Element => {
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [action, setAction] = useState<ActionName | null>(null)
    const [carbonCreditBalance, setCarbonCreditBalance] =
        useState<VintageCarbonCreditBalance | null>(null)

    const { user, isCaasProUser, isCaasUser, isMarketplaceUser } = useUserStore()

    const caasUser = isCaasUser()
    const caasProUser = isCaasProUser()
    const marketplaceUser = isMarketplaceUser()

    useEffect(() => {
        if (user?.tenant_id === sellOrder?.tenant_id) {
            setAction(ActionName.SELL)
            return
        }
        if (caasUser || caasProUser || marketplaceUser) {
            setAction(ActionName.PURCHASE)
            return
        }
    }, [sellOrder])

    useEffect(() => {
        if (!vintage) return
        const balance = vintage.carbon_credit_balances?.[0] ?? null
        setCarbonCreditBalance(balance)
    }, [sellOrder])

    if (!action || !sellOrder) return <></>

    if ([ActionName.BUY_RETIRE, ActionName.PURCHASE, ActionName.INTEREST].includes(action)) {
        return (
            <div className="">
                <BuyCreditsDetails sellOrder={sellOrder} vintage={vintage} project={project} />
            </div>
        )
    }
    if ([ActionName.SELL, ActionName.UPDATE_SELL_ORDER].includes(action) && sellOrder) {
        return (
            <div className="">
                <ModifySellOrderForm
                    sellOrder={sellOrder}
                    onCancel={() => setShowCancelModal(true)}
                    onEdit={() => setShowEditModal(true)}
                />
                <ConfirmCancelingSellOrderModal
                    show={showCancelModal}
                    sellOrder={sellOrder}
                    onClose={() => setShowCancelModal(false)}
                    onSellOrderDeleted={() => refreshProject()}
                />
                <EditSellOrderModal
                    show={showEditModal}
                    onClose={async (reload: boolean = false) => {
                        setShowEditModal(false)
                        if (reload) await refreshProject()
                    }}
                    onSaved={async () => {
                        setShowEditModal(false)
                        await refreshProject()
                    }}
                    sellOrder={sellOrder}
                    carbonCreditBalance={carbonCreditBalance}
                    vintage={vintage!}
                    project={project!!}
                />
            </div>
        )
    }
    return <></>
}

export default Credits

export const sortArrayNewestFirst = <T,>(arr: T[], dateField: keyof T) => {
    arr.sort((a, b) => {
        if (a[dateField] > b[dateField]) {
            return -1
        } else if (a[dateField] < b[dateField]) {
            return 1
        }
        return 0
    })
}
