import { Controller, useForm } from 'react-hook-form'
import { RegistryName } from '../../../../modules/registry/enums/RegistryName'
import { useUserStore } from '../../../../modules/user/context/store'
import { Alignment, FormElement } from '../../../../modules/form/types/Field'
import Select, { MultiValue } from 'react-select'
import { PrimaryButton, SecondaryButton } from '../../../../modules/common/components/Button'
import { Button } from '../../../../modules/form/types/Button'
import Form from '../../../../modules/form/components/Form'
import { FormApi } from '../../../../modules/form/http/api'
import {
    AskUsContinents,
    AskUsPriceRanges,
    AskUsRequest,
} from '../../../../modules/form/http/types/AskUsRequest'

interface Props {
    setError: (error: string | null) => void
    onSuccess: () => void
    onClose: () => void
    setShowSpinner: (show: boolean) => void
}

type FormData = {
    name: string
    email: string
    project_description: string
    project_type: string
    indicative_volume: string
    registry?: RegistryName[]
    price_range?: AskUsPriceRanges[]
    location?: AskUsContinents[]
}

const AskUsForm = ({ setError, onSuccess, onClose, setShowSpinner }: Props) => {
    const { user } = useUserStore()
    const form = useForm<FormData>({
        defaultValues: {
            name: user?.tenant?.entity_name ?? '',
            email: user?.email || '',
        },
    })

    const registyOptions: { value: RegistryName | string; label: string }[] = [
        { value: RegistryName.GOLD_STANDARD, label: 'Gold Standard' },
        { value: RegistryName.VERRA, label: 'Verra' },
        { value: RegistryName.AMERICAN_CARBON_REGISTRY, label: 'American Carbon Registry (ACR)' },
        { value: RegistryName.CLIMATE_ACTION_RESERVE, label: 'Climate Action Reserve (CAR)' },
        { value: RegistryName.BIOCARBON_REGISTRY, label: 'BioCarbon Registry' },
        { value: RegistryName.PURO, label: 'Puro.earth' },
        { value: RegistryName.SOCIAL_CARBON, label: 'Social Carbon' },
        { value: 'Other', label: 'Other' },
    ]

    const fields: FormElement<FormData>[] = [
        {
            name: 'project_description',
            displayName: 'Project Description & Specifications',
            type: 'textarea',
            required: true,
            description: `Please describe what you're looking for.`,
        },
        {
            name: 'project_type',
            displayName: 'Project Type - Free Form',
            type: 'text',
            required: true,
            description: `Is there a specific project type (avoidance versus removal) or methodology (afforestation, biochar etc.) that you are looking for?`,
        },
        {
            name: 'indicative_volume',
            displayName: 'Indicative Volume',
            type: 'number',
            required: true,
            description: `Please provide an approximate and indicative volume for your request.`,
        },
        {
            name: 'registry',
            displayName: 'Registry Information',
            description: `Please choose the registry or registries that you'd like the credits to be issued from.`,
            type: 'select',
            required: false,
            spacing: 'pt-1',
            inputOverride: (
                <Controller
                    name="registry"
                    control={form.control}
                    render={({ field }) => {
                        return (
                            <Select
                                className="w-full md:w-[400px]"
                                id="registry-select"
                                options={registyOptions}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                onChange={(
                                    newValue: MultiValue<{
                                        value: RegistryName | string
                                        label: string
                                    }>,
                                ) => {
                                    field.onChange(
                                        newValue?.flatMap((singleValue) => singleValue.value) ||
                                            null,
                                    )
                                }}
                            />
                        )
                    }}
                    rules={{
                        required: false,
                    }}
                    defaultValue={undefined}
                />
            ),
        },
        {
            name: 'price_range',
            displayName: 'Price Range',
            description: `Please provide an indicative price`,
            type: 'select',
            required: false,
            spacing: 'pt-1',
            inputOverride: (
                <Controller
                    name="price_range"
                    control={form.control}
                    render={({ field }) => {
                        return (
                            <Select
                                className="w-full md:w-[400px]"
                                id="price-range-select"
                                options={Object.entries(AskUsPriceRanges).map(([key, value]) => {
                                    return {
                                        value: key as AskUsPriceRanges,
                                        label: value,
                                    }
                                })}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                onChange={(
                                    newValue: MultiValue<{
                                        value: AskUsPriceRanges
                                        label: string
                                    }>,
                                ) => {
                                    field.onChange(
                                        newValue?.flatMap((singleValue) => singleValue.value) ||
                                            null,
                                    )
                                }}
                            />
                        )
                    }}
                    rules={{
                        required: false,
                    }}
                    defaultValue={undefined}
                />
            ),
        },
        {
            name: 'location',
            displayName: 'Project Location - Continent',
            type: 'select',
            required: false,
            spacing: 'pt-1',
            inputOverride: (
                <Controller
                    name="location"
                    control={form.control}
                    render={({ field }) => {
                        return (
                            <Select
                                className="w-full md:w-[400px]"
                                id="continent-select"
                                options={Object.entries(AskUsContinents).map(([key, value]) => {
                                    return {
                                        value: key as AskUsContinents,
                                        label: value,
                                    }
                                })}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                onChange={(
                                    newValue: MultiValue<{
                                        value: AskUsContinents
                                        label: string
                                    }>,
                                ) => {
                                    field.onChange(
                                        newValue?.flatMap((singleValue) => singleValue.value) ||
                                            null,
                                    )
                                }}
                            />
                        )
                    }}
                    rules={{
                        required: false,
                    }}
                    defaultValue={undefined}
                />
            ),
        },
    ]

    const close = () => {
        form.reset()
        onClose()
    }

    const buttons: Button[] = [
        {
            type: 'button',
            label: 'Close',
            id: 'close-ask-us',
            onClick: close,
            variation: SecondaryButton,
        },
        {
            type: 'submit',
            label: 'Submit',
            id: 'submit-ask-us',
            disabled: !form.formState.isValid,
            variation: PrimaryButton,
        },
    ]

    const onSubmit = async (): Promise<void> => {
        const signupRequest: AskUsRequest = {
            project_description: form.getValues('project_description'),
            project_type: form.getValues('project_type'),
            indicative_volume: form.getValues('indicative_volume'),
            registry: form.getValues('registry') ?? [],
            price_range: form.getValues('price_range') ?? [],
            location: form.getValues('location') ?? [],
        }

        try {
            setError(null)
            setShowSpinner(true)
            await FormApi.askUs(signupRequest)
            onSuccess()
        } catch (e) {
            setError('An error occurred. Please try again later.')
        } finally {
            setShowSpinner(false)
        }
    }

    return (
        <Form
            title="Tell us about your perfect project"
            onSubmit={onSubmit}
            formId="ask-us-form"
            fields={fields}
            headerListItems={[]}
            buttons={buttons}
            buttonAlignment={Alignment.INLINE}
            form={form}
        />
    )
}

export default AskUsForm
