const SettleIcon = (): JSX.Element => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 84 64"
        enableBackground="new 0 0 84 64"
        className="w-6 h-6"
    >
        <g>
            <g>
                <path d="M11.5,25c-0.8-0.8-0.8-2.1,0-3c0.8-0.8,2.2-0.8,3,0c0.8,0.8,0.8,2.1,0,3C13.7,25.8,12.4,25.8,11.5,25z" />
            </g>
        </g>
        <path
            d="M83.9,23L60.7,0.2L48.8,11.9l2.3,2.2l-1.6,1.6c0,0-0.1,0-0.1,0l-6.9-0.5c-1.8-0.1-3.6,0.5-4.9,1.8l-0.1,0.1l-2-0.6
	c-0.1-0.1-0.2-0.2-0.3-0.3l-1.9-1.8l2-2L23.3,0.8L0.1,23.6L12,35.2l2.5-2.5l5.4,7.4l-1.4,1.3c-0.8,0.8-1.3,1.9-1.3,3.2
	c0,1.3,0.5,2.6,1.5,3.6l0.1,0.1c0.6,0.6,1.4,1,2.2,1.3c0,0.1,0,0.2,0,0.4c0,1.3,0.5,2.4,1.3,3.3l0.1,0.1c0.8,0.8,1.8,1.2,2.9,1.3
	c0.1,1.1,0.6,2.1,1.4,2.9l0.1,0.1c0.9,0.8,2,1.3,3.3,1.3c0.2,0.9,0.7,1.8,1.4,2.5c1,1,2.4,1.6,3.7,1.6c1.2,0,2.2-0.4,3-1.2l1.7-1.7
	l0.4,0.4c0.9,0.9,2.3,1.4,3.6,1.4c1.4,0,2.7-0.5,3.7-1.4c0.6-0.6,1-1.4,1.2-2.2c0.3,0,0.7,0.1,1,0.1c1.3,0,2.6-0.4,3.4-1.2
	c0.7-0.7,1.1-1.5,1.4-2.4c0.1,0,0.2,0,0.4,0c1.3,0,2.6-0.4,3.6-1.3c0.8-0.8,1.3-1.9,1.4-3.1c1.2-0.1,2.3-0.5,3.1-1.3
	c0.9-0.9,1.4-2.2,1.4-3.6c0-1.5-0.5-2.9-1.5-3.8l-0.2-0.2l4-6.4l2.8-2.6l2.3,2.2L83.9,23z M60.7,5.8L78.1,23L72,29.1L54.5,11.9
	L60.7,5.8z M5.9,23.6L23.3,6.4l6.2,6.1L12,29.6L5.9,23.6z M25.7,43l-2.8,2.8c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.6-0.1-0.8-0.4l0,0
	c-0.2-0.2-0.4-0.5-0.4-0.8c0-0.1,0-0.2,0.1-0.3l2.8-2.8c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.6,0.1,0.9,0.4c0.2,0.2,0.4,0.5,0.4,0.8
	C25.8,42.8,25.8,42.9,25.7,43z M25.2,50.5c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.4,0.2-0.9,0.6-1.2l3.8-3.8c0.4-0.4,0.9-0.6,1.3-0.6
	c0.2,0,0.4,0,0.6,0.2c0.2,0.1,0.2,0.4,0.2,0.5c0,0.4-0.2,0.9-0.6,1.2l-3.8,3.8c-0.4,0.4-0.9,0.6-1.3,0.6
	C25.7,50.7,25.4,50.6,25.2,50.5L25.2,50.5z M29.5,54.7c-0.2-0.2-0.2-0.5-0.2-0.6c0-0.3,0.1-0.6,0.4-0.8l3.4-3.3
	c0.2-0.2,0.5-0.4,0.9-0.4c0.2,0,0.5,0,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.6c0,0.3-0.1,0.6-0.4,0.8l-3.4,3.3c-0.2,0.2-0.5,0.4-0.9,0.4
	C30.1,55,29.8,54.9,29.5,54.7L29.5,54.7z M38.1,56.4L35.4,59c0,0-0.6,0.1-1.1-0.4c-0.3-0.3-0.4-0.6-0.4-0.8c0-0.1,0-0.1,0-0.1
	l2.6-2.6c0,0,0.1,0,0.2,0c0.2,0,0.6,0.1,0.9,0.4c0.3,0.3,0.4,0.6,0.4,0.8C38.1,56.3,38.1,56.4,38.1,56.4z M60.3,46.1
	c-0.3,0.3-1.4,0.3-1.8-0.1l-4.4-4.4c-0.8-0.8-2-0.8-2.8,0c-0.8,0.8-0.8,2,0,2.8l4.4,4.4c0,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.1,0.1,0.2
	c0.2,0.2,0.2,1.1-0.2,1.4c-0.3,0.3-1.6,0.2-1.9-0.1c-0.1-0.1-0.2-0.3-0.4-0.4l-3.5-3.4c-0.8-0.8-2.1-0.8-2.8,0
	c-0.8,0.8-0.8,2.1,0,2.8l3.5,3.4c0,0,0.1,0.2,0.1,0.5c0,0.3-0.1,0.6-0.3,0.8c-0.4,0.2-1.7,0.1-2-0.2l-3-3.1c-0.8-0.8-2-0.8-2.8-0.1
	c-0.8,0.8-0.8,2-0.1,2.8l1.9,2c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.2,0.4,0.2,0.8c0,0.4-0.1,0.7-0.2,0.8c-0.3,0.3-1.4,0.3-1.7,0
	l-1.1-1.1c0-0.1,0-0.2,0-0.4c0-1.3-0.6-2.7-1.6-3.7c-0.5-0.5-1.1-0.9-1.7-1.2c0.1-0.3,0.1-0.6,0.1-0.9c0-1.3-0.5-2.6-1.5-3.5
	c-0.5-0.5-1.2-0.9-1.9-1.1c0-0.3,0.1-0.5,0.1-0.8c0-1.3-0.5-2.5-1.4-3.4c-0.9-0.9-2.1-1.3-3.4-1.3c-0.5,0-1,0.1-1.4,0.2
	c-0.3-0.6-0.6-1.1-1.1-1.6c-1-1-2.3-1.5-3.7-1.5c-0.5,0-1,0.1-1.4,0.2l-5.7-7.8l12.9-12.7l1.9,1.9c0.4,0.4,0.8,0.7,1.3,1l0.6,0.2
	l-5,5c-0.9,0.9-1.4,2.1-1.4,3.4c0,1.5,0.7,3.1,1.8,4.2c1.2,1.2,2.9,1.9,4.5,1.9c1.2,0,2.3-0.4,3.3-1.3l5.5-5.7l17.3,16.7
	c0.1,0.1,0.3,0.5,0.3,1C60.5,45.8,60.4,46.1,60.3,46.1z M63.8,32.5l-3.9,6.1L45.6,24.8c0.4-0.8,0.3-1.7-0.4-2.3
	c-0.8-0.8-2.1-0.8-2.8,0l-0.8,0.8c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.1,0.2l-6.7,6.9c-0.3,0.3-1.5,0-2.1-0.6
	c-0.4-0.4-0.6-0.9-0.6-1.4c0-0.4,0.1-0.5,0.2-0.5l0.8-0.7h0l7.6-7.5c0.5-0.5,1.1-0.7,1.8-0.6l6.9,0.5c1.2,0.1,2.4-0.3,3.2-1.2
	l1.7-1.6l12.9,12.7L63.8,32.5z"
        />
    </svg>
)

export default SettleIcon
