import { SearchSellOrdersResponse } from '../http/types/SearchSellOrdersResponse'
import { SearchSellOrderFilters } from './reducer'
import {
    SetIsFiltersModalVisibleAction,
    SetIsFormDisabledAction,
    SetSearchSellOrderFiltersAction,
    SetSearchSellOrdersResponseAction,
    SetShouldShowPriceFiltersAction,
    SetShouldShowProjectTypeFilterAction,
    SetShowAllLocationsAction,
    SetShowAlSdgsAction,
    SET_IS_FILTERS_MODAL_VISIBLE,
    SET_IS_FORM_DISABLED,
    SET_SEARCH_SELL_ORDERS_RESPONSE,
    SET_SEARCH_SELL_ORDER_FILTERS,
    SET_SHOULD_SHOW_PRICE_FILTERS,
    SET_SHOULD_SHOW_PROJECT_TYPE_FILTER,
    SET_SHOW_ALL_LOCATIONS,
    SET_SHOW_ALL_SDGS,
} from './types'
import { Dispatch } from 'react'

export const setSearchSellOrdersResponseAction = (
    response: SearchSellOrdersResponse | null,
    dispatch: Dispatch<SetSearchSellOrdersResponseAction>,
): void => {
    dispatch({
        type: SET_SEARCH_SELL_ORDERS_RESPONSE,
        payload: { response },
    })
}

export const setSearchSellOrderFiltersAction = (
    filters: SearchSellOrderFilters,
    dispatch: Dispatch<SetSearchSellOrderFiltersAction>,
): void => {
    dispatch({
        type: SET_SEARCH_SELL_ORDER_FILTERS,
        payload: { filters },
    })
}

export const setShouldShowPriceFiltersAction = (
    show: boolean,
    dispatch: Dispatch<SetShouldShowPriceFiltersAction>,
): void => {
    dispatch({
        type: SET_SHOULD_SHOW_PRICE_FILTERS,
        payload: { show },
    })
}

export const setIsFormDisabledAction = (
    isDisabled: boolean,
    dispatch: Dispatch<SetIsFormDisabledAction>,
): void => {
    dispatch({
        type: SET_IS_FORM_DISABLED,
        payload: { isDisabled },
    })
}

export const setIsFiltersModalVisibleAction = (
    isVisible: boolean,
    dispatch: Dispatch<SetIsFiltersModalVisibleAction>,
): void => {
    dispatch({
        type: SET_IS_FILTERS_MODAL_VISIBLE,
        payload: { isVisible },
    })
}

export const setShowAllLocationsAction = (
    show: boolean,
    dispatch: Dispatch<SetShowAllLocationsAction>,
): void => {
    dispatch({
        type: SET_SHOW_ALL_LOCATIONS,
        payload: { show },
    })
}

export const setShowAllSdgsAction = (
    show: boolean,
    dispatch: Dispatch<SetShowAlSdgsAction>,
): void => {
    dispatch({
        type: SET_SHOW_ALL_SDGS,
        payload: { show },
    })
}

export const setShouldShowProjectTypeFilterAction = (
    show: boolean,
    dispatch: Dispatch<SetShouldShowProjectTypeFilterAction>,
): void => {
    dispatch({
        type: SET_SHOULD_SHOW_PROJECT_TYPE_FILTER,
        payload: { show },
    })
}
