import { generatePath, useNavigate } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../../modules/common/components/Button'
import { Page } from '../../../modules/common/enums/Pages'
import {
    SearchSellOrdersParams,
    SortOrder,
} from '../../../modules/sellOrders/http/types/SearchSellOrdersParams'
import { SellOrder } from '../../../modules/sellOrders/types/SellOrder'
import { useUserStore } from '../../../modules/user/context/store'
import { RoleName } from '../../../modules/user/enums/RoleName'
import { Role } from '../../../modules/user/types/Role'
import Location from '../../../modules/project/components/Location'
import './css/market.css'
import Table, { Column, TextAlignment, Values } from '../../../modules/table/components/Table'
import Sdgs from '../../../modules/project/components/Sdgs'
import RegistryIcon from '../../../modules/registry/components/RegistryIcon'
import {
    convertGramsToMetricTonesLocaleString,
    convertCentsToLocaleUsd,
    convertUsdToCents,
} from '../../../utils/conversion'
import {
    SellOrderTableColumns,
    SortSellOrderColumns,
} from '../../../modules/myOrders/context/types'
import ProjectName from '../../../modules/project/components/ProjectName'
import Tokenised from '../../../modules/carbonCreditBalance/components/Tokenised'
import { useSellOrdersStore } from '../../../modules/sellOrders/context/store'
import { SellOrderApi } from '../../../modules/sellOrders/http/SellOrderApi'
import LoadingWrapper from '../../../modules/loadingWrapper/components/LoadingWrapper'
import AlignMiddle from '../../../modules/common/components/AlignMiddle'
import MarketCardFormat from './MarketCardFormat'
import { Display } from '../enums/display'
import { useState } from 'react'
import ContextSwitcher, { ContextOption } from '../../../modules/common/components/ContextSwitcher'
import AskUsModal from './AskUsModal'
import Action from '../../../modules/common/components/Action'
import { Eye, ShoppingCart } from '@phosphor-icons/react'

interface TableProps {}

const MarketOrders = ({}: TableProps): JSX.Element => {
    const navigate = useNavigate()
    const [display, setDisplay] = useState<Display>(Display.CARD)
    const [showAskUsForm, setShowAskUsForm] = useState<boolean>(false)

    const { user } = useUserStore()
    const {
        searchSellOrderFilters,
        searchSellOrdersResponse,
        setSearchSellOrderFilters,
        setSearchSellOrdersResponse,
        setIsFormDisabled,
    } = useSellOrdersStore()

    const searchSellOrders = async (params: SearchSellOrdersParams): Promise<void> => {
        setIsFormDisabled(true)
        const response = await SellOrderApi.search(params)
        if (response) {
            setSearchSellOrdersResponse(response.data)
        }
        setIsFormDisabled(false)
    }

    const onSort = async (
        sortByColumnName: SortSellOrderColumns | null,
        sortByOrder: SortOrder | null,
    ): Promise<void> => {
        if (sortByColumnName) {
            setSearchSellOrderFilters({
                ...searchSellOrderFilters,
                sortByColumnName: sortByColumnName,
                sortByOrder,
            })
        } else {
            setSearchSellOrderFilters({
                ...searchSellOrderFilters,
                sortByColumnName: null,
                sortByOrder: null,
            })
        }
    }

    const search = async (): Promise<void> => {
        let queryParams: SearchSellOrdersParams = {
            name: searchSellOrderFilters.projectName,
            type: searchSellOrderFilters.projectType,
            location: searchSellOrderFilters.locations,
            sdg: searchSellOrderFilters.sdgs,
            vintage: searchSellOrderFilters.vintages,
        }
        if (searchSellOrderFilters.price.min) {
            queryParams = {
                ...queryParams,
                min_price: convertUsdToCents(searchSellOrderFilters.price.min),
            }
        }
        if (searchSellOrderFilters.price.max) {
            queryParams = {
                ...queryParams,
                max_price: convertUsdToCents(searchSellOrderFilters.price.max),
            }
        }
        if (searchSellOrderFilters.sortByColumnName) {
            queryParams = {
                ...queryParams,
                sort_by_column_name: searchSellOrderFilters.sortByColumnName,
            }
        }
        if (searchSellOrderFilters.sortByOrder) {
            queryParams = {
                ...queryParams,
                sort_order: searchSellOrderFilters.sortByOrder,
            }
        }
        await searchSellOrders(queryParams)
    }

    const showViewOrBuyButton = (sellOrder: SellOrder): JSX.Element => {
        const marketplaceRole = user?.roles.find(
            (role: Role) => role.name === RoleName.MARKETPLACE_USER,
        )
        const caasRole = user?.roles.find((role: Role) =>
            [RoleName.CAAS_USER, RoleName.CAAS_PRO].includes(role.name),
        )

        const originUrl = window.location.origin

        const url = new URL(
            generatePath(Page.PROJECT, {
                id: sellOrder.carbon_credit_balance!.vintage.project.id,
            }),
            originUrl,
        )

        url.searchParams.append('sell_order_id', sellOrder.id)
        url.searchParams.append('vintage', String(sellOrder.carbon_credit_balance!.vintage.vintage))

        const path = url.href.replace(originUrl, '')

        if (
            (caasRole || marketplaceRole) &&
            sellOrder.carbon_credit_balance!.tenant_id !== user?.tenant_id
        ) {
            return (
                <PrimaryButton
                    className="flex flex-row w-20"
                    onClick={() => {
                        navigate(path)
                    }}
                >
                    <Action
                        id={`buy-credits-${sellOrder.id}`}
                        IconElement={ShoppingCart}
                        tooltip="Buy Credits"
                        highlight={false}
                    />
                    <AlignMiddle direction="row" className="w-full">
                        Buy
                    </AlignMiddle>
                </PrimaryButton>
            )
        } else {
            return (
                <Action
                    id={`view-credits-${sellOrder.id}`}
                    IconElement={Eye}
                    tooltip="View Sell Order"
                    onClick={() => {
                        navigate(path)
                    }}
                    dataSelector="view-sell-order"
                />
            )
        }
    }

    const data: Values<SellOrderTableColumns>[] = (searchSellOrdersResponse?.sell_orders ?? []).map(
        (sellOrder): Values<SellOrderTableColumns> => {
            return {
                project_name: {
                    value: (
                        <ProjectName
                            squareImageUrl={
                                sellOrder!.carbon_credit_balance!.vintage.project.square_image_url
                            }
                            type={sellOrder!.carbon_credit_balance!.vintage.project.type}
                            name={sellOrder!.carbon_credit_balance!.vintage.project.name}
                            id={sellOrder!.carbon_credit_balance!.vintage.project.id}
                            sellOrderId={sellOrder.id}
                        />
                    ),
                },
                project_type: { value: sellOrder?.carbon_credit_balance!.vintage.project.type },
                sdgs: {
                    value: (
                        <Sdgs
                            sdgs={sellOrder?.carbon_credit_balance!.vintage.project.sdgs}
                            numberOfSdgsToShow={2}
                            iconWrapperClassName="-ml-4 first:ml-0 border-2 border-white"
                            id={sellOrder.id}
                        />
                    ),
                },
                vintage: { value: sellOrder?.carbon_credit_balance!.vintage.vintage },
                location: {
                    value: (
                        <Location
                            location={sellOrder?.carbon_credit_balance!.vintage.project.location}
                        />
                    ),
                },
                project_registry: {
                    value: (
                        <RegistryIcon
                            registryId={
                                sellOrder?.carbon_credit_balance!.vintage.project.registry.id
                            }
                        />
                    ),
                },
                tokenized: {
                    value: (
                        <AlignMiddle direction="row">
                            <Tokenised
                                backedBySource={sellOrder.carbon_credit_balance!.backed_by_source}
                            />
                        </AlignMiddle>
                    ),
                    textAlignment: TextAlignment.CENTRE,
                },
                quantity: {
                    value: convertGramsToMetricTonesLocaleString(Number(sellOrder?.quantity)),
                    textAlignment: TextAlignment.RIGHT,
                },
                price: {
                    value: sellOrder?.price ? (
                        <span className="font-bold">
                            {convertCentsToLocaleUsd(BigInt(sellOrder?.price))}
                        </span>
                    ) : (
                        <></>
                    ),
                    textAlignment: TextAlignment.RIGHT,
                },
                action: {
                    value: showViewOrBuyButton(sellOrder),
                    textAlignment: TextAlignment.CENTRE,
                },
            }
        },
    )

    const columns: Column<SellOrderTableColumns>[] = [
        {
            name: 'project_name',
            display: 'Project Name',
            sortable: true,
            onSort: (order: SortOrder | null, reset: boolean) => {
                if (reset) return onSort(null, null)
                onSort('project_name', order)
            },
        },
        {
            name: 'project_type',
            display: 'Type',
            sortable: true,
            onSort: (order: SortOrder | null, reset: boolean) => {
                if (reset) return onSort(null, null)
                onSort('project_type', order)
            },
        },
        {
            name: 'sdgs',
            display: 'SDGs',
            sortable: false,
            tooltip:
                'The sustainable development goals that are positively impacted by this project',
        },
        {
            name: 'vintage',
            display: 'Vintage',
            sortable: true,
            onSort: (order: SortOrder | null, reset: boolean) => {
                if (reset) return onSort(null, null)
                onSort('vintage', order)
            },
        },
        {
            name: 'location',
            display: 'Location',
            sortable: true,
            onSort: (order: SortOrder | null, reset: boolean) => {
                if (reset) return onSort(null, null)
                onSort('location', order)
            },
        },
        {
            name: 'project_registry',
            display: 'Registry',
            sortable: true,
            tooltip: 'The registry on which the carbon credit has been issued',
            onSort: (order: SortOrder | null, reset: boolean) => {
                if (reset) return onSort(null, null)
                onSort('project_registry', order)
            },
        },
        {
            name: 'tokenized', // 'backed_by_source
            display: 'Tokenized',
            sortable: false,
            tooltip: 'Credits with this symbol are tokenized on the blockchain',
        },
        {
            name: 'quantity',
            display: 'Quantity',
            sortable: true,
            tooltip: 'One unit is equal to one tCO2e (tonnes of carbon dioxide equivalent)',
            onSort: (order: SortOrder | null, reset: boolean) => {
                if (reset) return onSort(null, null)
                onSort('quantity', order)
            },
        },
        {
            name: 'price',
            display: 'Price',
            sortable: true,
            tooltip: 'Price per credit',
            onSort: (order: SortOrder | null, reset: boolean) => {
                if (reset) return onSort(null, null)
                onSort('price', order)
            },
        },
        {
            name: 'action',
            display: 'Action',
            sortable: false,
        },
    ]

    const tabs: ContextOption<Display>[] = [
        { value: Display.CARD, display: 'Card' },
        { value: Display.TABLE, display: 'List' },
    ]

    return (
        <LoadingWrapper
            waitFor={search}
            errMsg="There was a problem loading the Market. Please refresh the page to try again."
            dependencyChanges={[searchSellOrderFilters]}
        >
            <div className="flex flex-col min-h-1/2 gap-2">
                <div className="mb-2 flex">
                    <ContextSwitcher
                        options={tabs}
                        onSelect={setDisplay}
                        selected={display}
                        id="market-context-switcher"
                    />
                </div>
                <div className={display === Display.CARD ? '' : 'hidden'}>
                    <AlignMiddle direction="row">
                        <MarketCardFormat setShowAskUsForm={setShowAskUsForm} />
                    </AlignMiddle>
                </div>
                <div className={display === Display.TABLE ? '' : 'hidden'}>
                    <Table
                        columns={columns}
                        data={data}
                        highlightOnHover={true}
                        id="market-table"
                    />
                    <div className="hover:pointer mt-7.5">
                        <div className="flex mx-auto px-5">
                            <div className="text-center align-middle p-2">
                                Can't find your perfect project? Let us source it for you.
                            </div>
                            <SecondaryButton
                                onClick={() => {
                                    setShowAskUsForm(true)
                                }}
                                className=""
                            >
                                Find Your Perfect Project
                            </SecondaryButton>
                        </div>
                    </div>
                </div>
            </div>
            <AskUsModal
                show={showAskUsForm}
                onClose={() => {
                    setShowAskUsForm(false)
                }}
            />
        </LoadingWrapper>
    )
}

export default MarketOrders
