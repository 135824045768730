import { useState } from 'react'
import { SellOrder } from '../../sellOrders/types/SellOrder'
import BaseModal from '../../modal/components/BaseModal'
import { MyOrderApi } from '../../myOrders/http/api'
import { SecondaryButton, DangerButton } from '../../common/components/Button'
import ModalButtonContainer from '../../modal/components/ModalButtonContainer'
import { confirmationDescription } from '../../action'
import { ActionName } from '../../action/enums/ActionName'

interface ConfirmCancelingSellOrderModalProps {
    show: boolean
    sellOrder: SellOrder | null
    onSellOrderDeleted: () => void
    onClose?: (show: boolean) => void
}
export const ConfirmCancelingSellOrderModal = ({
    show,
    sellOrder,
    onSellOrderDeleted,
    onClose = () => {},
}: ConfirmCancelingSellOrderModalProps): JSX.Element => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [enableButtons, setEnableButtons] = useState<boolean>(true)

    const cancelSellOrder = async (): Promise<void> => {
        try {
            setEnableButtons(false)
            setError(null)
            setShowSpinner(true)
            if (sellOrder) {
                await MyOrderApi.delete(sellOrder)
                onSellOrderDeleted()
            }
        } catch (e) {
            setError('An unexpected error has occurred, please try again')
            throw e
        } finally {
            setShowSpinner(false)
            setEnableButtons(true)
            onClose(false)
        }
    }

    const close = () => {
        setShowSpinner(false)
        onClose(false)
    }

    return (
        <BaseModal
            show={show}
            title={`Would you like to cancel this sell order?`}
            id="cancel-sell-order-modal"
            position="center"
            onClose={close}
            error={error}
            showSpinner={showSpinner}
            bottomComponent={
                <ModalButtonContainer>
                    <SecondaryButton onClick={close} disabled={!enableButtons}>
                        Close
                    </SecondaryButton>
                    <DangerButton onClick={cancelSellOrder} disabled={!enableButtons}>
                        Cancel Order
                    </DangerButton>
                </ModalButtonContainer>
            }
        >
            <p>{confirmationDescription(ActionName.CANCEL_SELL_ORDER)}</p>
        </BaseModal>
    )
}

export default ConfirmCancelingSellOrderModal
