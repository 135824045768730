import { useEffect, useState } from 'react'
import { SellOrder } from '../types/SellOrder'
import LoadingWrapper from '../../loadingWrapper/components/LoadingWrapper'
import Table, { Column, TextAlignment, Values } from '../../table/components/Table'
import { MyOrderApi } from '../../myOrders/http/api'
import ProjectName from '../../project/components/ProjectName'
import DateTime from '../../dateTime/components/DateTimeFormatted'
import Location from '../../project/components/Location'
import RegistryIcon from '../../registry/components/RegistryIcon'
import {
    convertCentsToLocaleUsd,
    convertGramsToMetricTonesLocaleString,
} from '../../../utils/conversion'
import SellOrderStatusPill from './SellOrderStatusPill'
import Note, { NoteType } from '../../note/components/Note'
import ConfirmCancelingSellOrderModal from '../../credits/components/ConfirmCancelingSellOrderModal'
import EditSellOrderModal from '../../credits/components/EditSellOrderModal'
import { useUserStore } from '../../user/context/store'
import { SellOrderStatus } from '../enums/SelOrderStatus'
import { Project } from '../../project/types/Project'
import ID from '../../common/components/ID'
import Action from '../../common/components/Action'
import { PencilSimpleLine, XCircle } from '@phosphor-icons/react'

interface Props {
    project?: Project
    displayHeading?: boolean
    hideNoResults?: boolean
    onReload?: () => Promise<void>
}

type Columns =
    | 'id'
    | 'status'
    | 'date'
    | 'project_name'
    | 'project_type'
    | 'vintage'
    | 'location'
    | 'registry'
    | 'quantity'
    | 'sold_amount'
    | 'total_sold'
    | 'price'
    | 'action'

const SellOrderTable = ({
    project,
    displayHeading,
    hideNoResults = false,
    onReload,
}: Props): JSX.Element => {
    const [sellOrderData, setSellOrderData] = useState<Values<Columns>[]>([])
    const [sellOrders, setSellOrders] = useState<SellOrder[]>([])
    const [sellOrder, setSellOrder] = useState<SellOrder | null>(null)
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const { user, isCaasUser } = useUserStore()

    const caasUser = isCaasUser()

    const getActions = (sellOrder: SellOrder): JSX.Element => {
        if (sellOrder.status !== SellOrderStatus.LIVE || sellOrder.tenant_id !== user?.tenant_id)
            return <></>
        return (
            <div className="flex flex-row gap-4">
                <Action
                    id={`edit-${sellOrder.id}`}
                    IconElement={PencilSimpleLine}
                    tooltip="Edit"
                    onClick={() => {
                        setSellOrder(sellOrder)
                        setShowEditModal(true)
                    }}
                />
                <Action
                    id={`cancel-${sellOrder.id}`}
                    IconElement={XCircle}
                    tooltip="Cancel"
                    onClick={() => {
                        setSellOrder(sellOrder)
                        setShowCancelModal(true)
                    }}
                />
            </div>
        )
    }

    const getSellOrders = async (): Promise<void> => {
        const response = await MyOrderApi.search({ project_id: project?.readable_id })
        if (response) {
            setSellOrders(response.data.results)
        }
    }

    useEffect(() => {
        const data: Values<Columns>[] = sellOrders.map((sellOrder) => {
            const project = sellOrder.carbon_credit_balance?.vintage.project!
            return {
                id: {
                    value: <ID link={`/sell-order/${sellOrder.id}`}>{sellOrder.id}</ID>,
                    className: 'whitespace-nowrap',
                },
                status: {
                    value: <SellOrderStatusPill status={sellOrder.status} />,
                },
                date: {
                    value: (
                        <DateTime
                            monthAndYearOnly={true}
                            date={new Date(sellOrder.created_at!)}
                        ></DateTime>
                    ),
                    className: 'w-[120px]',
                },
                project_name: {
                    value: (
                        <ProjectName
                            squareImageUrl={project.square_image_url}
                            type={project.type}
                            name={project.name}
                            id={project.id}
                            sellOrderId={sellOrder.id}
                        />
                    ),
                },
                project_type: {
                    value: project.type!,
                },
                vintage: {
                    value: sellOrder.vintage,
                    textAlignment: TextAlignment.RIGHT,
                },
                location: {
                    value: <Location location={project.location} />,
                },
                registry: {
                    value: <RegistryIcon registryId={project.registry.id} />,
                },
                quantity: {
                    value: convertGramsToMetricTonesLocaleString(Number(sellOrder.quantity)),
                    textAlignment: TextAlignment.RIGHT,
                },
                sold_amount: {
                    value: convertGramsToMetricTonesLocaleString(Number(sellOrder.sold_quantity)),
                    textAlignment: TextAlignment.RIGHT,
                },
                total_sold: {
                    value: (
                        <div className="font-bold">
                            {convertCentsToLocaleUsd(Number(sellOrder.total_sold))}
                        </div>
                    ),
                    textAlignment: TextAlignment.RIGHT,
                },
                price: {
                    value: (
                        <div className="font-bold">{convertCentsToLocaleUsd(sellOrder.price)}</div>
                    ),
                    textAlignment: TextAlignment.RIGHT,
                },
                action: {
                    value: getActions(sellOrder),
                },
            }
        })
        setSellOrderData(data)
    }, [sellOrders])

    const columns: Column<Columns>[] = [
        {
            name: 'id',
            display: 'Sell Order',
        },
        {
            name: 'status',
            display: 'Status',
        },
        {
            name: 'date',
            display: 'Date',
        },
        {
            name: 'project_name',
            display: 'Project',
        },
        {
            name: 'project_type',
            display: 'Type',
        },
        {
            name: 'vintage',
            display: 'Vintage',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'location',
            display: 'Location',
        },
        {
            name: 'registry',
            display: 'Registry',
        },
        {
            name: 'quantity',
            display: 'For Sale',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'sold_amount',
            display: 'Sold',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'price',
            display: 'Price',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'total_sold',
            display: 'Total Sold',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'action',
            display: 'Action',
            textAlignment: TextAlignment.CENTRE,
        },
    ]

    const heading: React.ReactNode = (
        <h3 className="text-gray-900 font-semibold text-xl mb-2">Sell Orders</h3>
    )

    if (caasUser) return <></>

    return (
        <>
            <LoadingWrapper
                waitFor={getSellOrders}
                errMsg="Error loading Sell Orders"
                dependencyChanges={[project]}
            >
                {sellOrderData.length > 0 && (
                    <>
                        {displayHeading && heading}
                        <Table columns={columns} data={sellOrderData} id="sell-order-table" />
                    </>
                )}
                {sellOrderData.length === 0 && !hideNoResults && (
                    <Note type={NoteType.INFO}>No sell orders found</Note>
                )}
            </LoadingWrapper>
            <ConfirmCancelingSellOrderModal
                show={showCancelModal}
                sellOrder={sellOrder}
                onClose={() => {
                    setShowCancelModal(false)
                    setSellOrder(null)
                }}
                onSellOrderDeleted={async () => {
                    onReload ? await onReload() : await getSellOrders()
                    setSellOrder(null)
                }}
            />
            <EditSellOrderModal
                show={showEditModal}
                onClose={async (reload: boolean = false) => {
                    setShowEditModal(false)
                    if (reload) {
                        onReload ? await onReload() : await getSellOrders()
                    }
                    setSellOrder(null)
                }}
                onSaved={async () => {
                    setShowEditModal(false)
                    onReload ? await onReload() : await getSellOrders()
                    setSellOrder(null)
                }}
                sellOrder={sellOrder}
                carbonCreditBalance={sellOrder?.carbon_credit_balance!}
                vintage={sellOrder?.carbon_credit_balance?.vintage!}
                project={sellOrder?.carbon_credit_balance?.vintage?.project!}
            />
        </>
    )
}

export default SellOrderTable
