import React from 'react'

interface Props {
    children: React.ReactNode
    direction: 'row' | 'column'
    className?: string
    childClassName?: string
    bufferClassName?: string
    id?: string
    onClick?: () => void
}

const AlignMiddle = ({
    children,
    direction,
    className = '',
    childClassName = 'order-0',
    bufferClassName = '',
    id,
    onClick = () => {},
}: Props): JSX.Element => {
    return (
        <div
            className={`flex ${direction === 'column' ? 'flex-col' : 'flex-row'} ${className}`}
            id={id}
            onClick={onClick}
        >
            <div className={`flex-1 ${bufferClassName}`}></div>
            <div className={childClassName}>{children}</div>
            <div className={`flex-1 ${bufferClassName}`}></div>
        </div>
    )
}

export default AlignMiddle
