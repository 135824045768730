import { useEffect, useState } from 'react'
import Table, { Column, TextAlignment, Values } from '../../table/components/Table'
import { BuyOrderApi } from '../http/BuyOrderApi'
import BuyOrderStatusPill from './BuyOrderStatusPill'
import DateTime from '../../dateTime/components/DateTimeFormatted'
import { BuyOrderStatus } from '../http/enums/BuyOrderStatus'
import ProjectName from '../../project/components/ProjectName'
import {
    convertCentsToLocaleUsd,
    convertGramsToMetricTonesLocaleString,
} from '../../../utils/conversion'
import { Project } from '../../project/types/Project'
import { SearchBuyOrderParams } from '../http/types/SearchBuyOrderParams'
import CancelBuyOrderModal from './CancelBuyOrderModal'
import { BuyOrderDto } from '../http/types/BuyOrderDto'
import LoadingSpinner from '../../loadingSpinner/components/LoadingSpinner'
import Note, { NoteType } from '../../note/components/Note'
import * as Sentry from '@sentry/react'
import ID from '../../common/components/ID'
import { useUserStore } from '../../user/context/store'
import Action from '../../common/components/Action'
import { XCircle } from '@phosphor-icons/react'

type BuyOrderColumns =
    | 'id'
    | 'status'
    | 'date'
    | 'invoice_id'
    | 'project_name'
    | 'vintage'
    | 'price'
    | 'quantity'
    | 'total'
    | 'action'

interface Props {
    project: Project | null
    displayHeading?: boolean
    hideNoResults?: boolean
}

const BuyOrdersTable = ({
    project,
    displayHeading = false,
    hideNoResults = false,
}: Props): JSX.Element => {
    const [buyOrderData, setBuyOrderData] = useState<Values<BuyOrderColumns>[]>([])
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
    const [selectedBuyOrder, setSelectedBuyOrder] = useState<BuyOrderDto | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string | null>(null)
    const { isCaasUser, isCaasProUser } = useUserStore()

    const caasUser = isCaasUser()
    const caasProUser = isCaasProUser()

    const getBuyOrders = async (): Promise<void> => {
        if (!caasUser && !caasProUser) return
        try {
            setErrMsg(null)
            setIsLoading(true)
            const searchParams: SearchBuyOrderParams = {
                project_id: project?.id,
            }
            const buyOrders = (await BuyOrderApi.search(searchParams)).data.buy_orders
            setBuyOrderData(
                buyOrders.map((buyOrder) => {
                    if (buyOrder.trades.length !== 1) {
                        throw new Error(
                            `Unexpected number of trades on buy order. Trade count: ${buyOrder.trades.length} Buy order id: ${buyOrder.id}`,
                        )
                    }
                    const trade = buyOrder.trades[0]
                    return {
                        id: {
                            value: <ID link={`/buy-order/${buyOrder.id}`}>{buyOrder.id}</ID>,
                        },
                        status: {
                            value: (
                                <BuyOrderStatusPill status={buyOrder.status}></BuyOrderStatusPill>
                            ),
                        },
                        date: {
                            value: <DateTime date={new Date(buyOrder.created_at)}></DateTime>,
                        },
                        invoice_id: {
                            value: (
                                <ID link={`/invoice/${buyOrder.invoice_id}`}>
                                    {buyOrder.invoice_id}
                                </ID>
                            ),
                        },
                        project_name: {
                            value: (
                                <ProjectName
                                    squareImageUrl={trade.project_data.square_image_url}
                                    type={trade.project_data.type}
                                    name={trade.project_data.name}
                                    id={trade.project_data.id}
                                    sellOrderId={trade.sell_order_id}
                                />
                            ),
                        },
                        vintage: {
                            value: trade.vintage,
                            textAlignment: TextAlignment.RIGHT,
                        },
                        price: {
                            value: (
                                <div className="font-bold">
                                    {convertCentsToLocaleUsd(trade.gross_unit_price)}
                                </div>
                            ),
                            textAlignment: TextAlignment.RIGHT,
                        },
                        quantity: {
                            value: convertGramsToMetricTonesLocaleString(Number(trade.quantity)),
                            textAlignment: TextAlignment.RIGHT,
                        },
                        total: {
                            value: (
                                <div className="font-bold">
                                    {convertCentsToLocaleUsd(buyOrder.total)}
                                </div>
                            ),
                            textAlignment: TextAlignment.RIGHT,
                        },
                        action: {
                            value:
                                buyOrder.status === BuyOrderStatus.OPEN ? (
                                    <Action
                                        key={`buy-order-${buyOrder.id}`}
                                        id={`buy-order-${buyOrder.id}`}
                                        IconElement={XCircle}
                                        tooltip="Cancel"
                                        onClick={() => {
                                            setSelectedBuyOrder(buyOrder)
                                            setShowCancelModal(true)
                                        }}
                                    />
                                ) : (
                                    <></>
                                ),
                            textAlignment: TextAlignment.RIGHT,
                        },
                    }
                }),
            )
            setIsLoading(false)
        } catch (err) {
            setErrMsg(
                'There was a problem loading your buy orders. Please refresh the page to try again.',
            )
            setIsLoading(false)
            Sentry.captureException(err)
        }
    }

    useEffect(() => {
        getBuyOrders()
    }, [])

    const columns: Column<BuyOrderColumns>[] = [
        {
            name: 'id',
            display: 'Buy Order',
        },
        {
            name: 'status',
            display: 'Status',
        },
        {
            name: 'date',
            display: 'Date',
        },
        {
            name: 'invoice_id',
            display: 'Invoice',
        },
        {
            name: 'project_name',
            display: 'Project',
        },
        {
            name: 'vintage',
            display: 'Vintage',
        },
        {
            name: 'price',
            display: 'Unit Price',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'quantity',
            display: 'Quantity',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'total',
            display: 'Total',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'action',
            display: 'Action',
        },
    ]

    const heading: React.ReactNode = (
        <h3 className="text-gray-900 font-semibold text-xl mb-2">Buy Orders</h3>
    )

    return (
        <>
            {isLoading && <LoadingSpinner></LoadingSpinner>}
            {errMsg && <Note type={NoteType.ERROR}>{errMsg}</Note>}
            {buyOrderData.length > 0 && !isLoading && !errMsg && (
                <>
                    {displayHeading && heading}
                    <Table columns={columns} data={buyOrderData} />
                    <CancelBuyOrderModal
                        buyOrder={selectedBuyOrder!}
                        show={showCancelModal && !!selectedBuyOrder}
                        onClose={(success) => {
                            setShowCancelModal(false)
                            setSelectedBuyOrder(undefined)
                            if (success) {
                                getBuyOrders()
                            }
                        }}
                    />
                </>
            )}
            {buyOrderData.length === 0 && !hideNoResults && !isLoading && !errMsg && (
                <Note type={NoteType.INFO}>No buy orders found</Note>
            )}
        </>
    )
}

export default BuyOrdersTable
