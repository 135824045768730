import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import RecordField from '../../modules/recordDetails/components/RecordField'
import { useUserStore } from '../../modules/user/context/store'
import AccountSettingsSubMenu from './components/AccountSettingsSubMenu'
import { AccountSettingsSubMenuActions } from './enums/AccountSettingsSubMenuActions'

const AccountDetails = (): JSX.Element => {
    const { user } = useUserStore()

    return (
        <Layout title="Account Settings" subTitle="" selectedNavBarItem={NavBarItemName.NONE}>
            <AccountSettingsSubMenu selectedItem={AccountSettingsSubMenuActions.ACCOUNT_DETAILS} />
            <div className="flex flex-col gap-6">
                <RecordField label="Name">{`${user?.first_name} ${user?.last_name}`}</RecordField>
                <RecordField label="Email">{user?.email}</RecordField>
                <RecordField label="Company Name">{user?.tenant?.entity_name}</RecordField>
            </div>
        </Layout>
    )
}

export default AccountDetails
