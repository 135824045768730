import { Link, useParams } from 'react-router-dom'
import Layout from '../../common/components/Layout'
import { NavBarItemName } from '../../header/components/NavBar'
import { SellOrder as SellOrderType } from '../types/SellOrder'
import { useState } from 'react'
import LoadingWrapper from '../../loadingWrapper/components/LoadingWrapper'
import SellOrderStatusPill from './SellOrderStatusPill'
import { convertCentsToLocaleUsd, convertGramsToMetricTonnes } from '../../../utils/conversion'
import RecordField from '../../recordDetails/components/RecordField'
import { MyOrderApi } from '../../myOrders/http/api'
import RegistryIcon from '../../registry/components/RegistryIcon'
import ProjectName from '../../project/components/ProjectName'
import Location from '../../project/components/Location'
import TradeHistoryTable from '../../tradeHistory/components/TradeHistoryTable'
import { SecondaryButton } from '../../common/components/Button'
import RecordDetailActions from '../../recordDetails/components/RecordDetailActions'
import ConfirmCancelingSellOrderModal from '../../credits/components/ConfirmCancelingSellOrderModal'
import EditSellOrderModal from '../../credits/components/EditSellOrderModal'

interface Props {}

const SellOrder = ({}: Props): JSX.Element => {
    const [sellOrder, setSellOrder] = useState<SellOrderType | null>()
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const { id } = useParams()

    const loadOrder = async (): Promise<void> => {
        const response = await MyOrderApi.get(id!)
        if (!response) return
        setSellOrder(response.data)
    }
    return (
        <Layout
            title="Sell Order"
            subTitle={sellOrder ? sellOrder.id : ''}
            dataCy="sell-order-layout"
            selectedNavBarItem={NavBarItemName.ORDERS}
            topRightComponent={
                sellOrder ? (
                    <RecordDetailActions>
                        <SecondaryButton
                            onClick={() => {
                                setShowCancelModal(true)
                            }}
                        >
                            Cancel Order
                        </SecondaryButton>
                        <SecondaryButton
                            onClick={() => {
                                setShowEditModal(true)
                            }}
                        >
                            Edit Order
                        </SecondaryButton>
                    </RecordDetailActions>
                ) : (
                    <></>
                )
            }
            topLeftComponent={
                <Link className="link text-sm" to="/orders/sell-orders">
                    {'<< All Sell Orders'}
                </Link>
            }
        >
            <LoadingWrapper waitFor={loadOrder} errMsg="Error loading the sell order">
                {sellOrder && (
                    <div className="flex flex-col gap-4">
                        <div className="grid grid-rows-4 md:grid-cols-4 md:grid-rows-1 gap-4">
                            <RecordField label="Status">
                                <SellOrderStatusPill status={sellOrder!.status} />
                            </RecordField>
                            <RecordField label="Price">
                                <div className="font-bold">
                                    {convertCentsToLocaleUsd(sellOrder!.price)}
                                </div>
                            </RecordField>
                            <RecordField label="For Sale">
                                <div className="font-bold">
                                    {convertGramsToMetricTonnes(sellOrder!.quantity)}
                                </div>
                            </RecordField>
                            <RecordField label="Sold">
                                <div className="font-bold">
                                    {convertGramsToMetricTonnes(sellOrder!.sold_quantity!)}
                                </div>
                            </RecordField>
                            <RecordField label="Total Sold">
                                <div className="font-bold">
                                    {convertCentsToLocaleUsd(sellOrder!.total_sold!)}
                                </div>
                            </RecordField>
                            <RecordField label="Vintage">
                                <div className="font-bold">{sellOrder!.vintage}</div>
                            </RecordField>
                            <RecordField label="Registry">
                                <RegistryIcon
                                    registryId={
                                        sellOrder.carbon_credit_balance?.vintage.project.registry_id
                                    }
                                />
                            </RecordField>
                            <RecordField label="Project">
                                <ProjectName
                                    squareImageUrl={
                                        sellOrder.carbon_credit_balance?.vintage.project
                                            .square_image_url
                                    }
                                    type={sellOrder.carbon_credit_balance?.vintage.project.type!}
                                    name={sellOrder.carbon_credit_balance?.vintage.project.name!}
                                    id={sellOrder.carbon_credit_balance?.vintage.project.id!}
                                    sellOrderId={sellOrder.id}
                                />
                            </RecordField>
                            <RecordField label="Location">
                                <Location
                                    location={
                                        sellOrder.carbon_credit_balance?.vintage.project.location!
                                    }
                                />
                            </RecordField>
                        </div>
                        <TradeHistoryTable
                            sellOnly={true}
                            sellOrderId={sellOrder.id}
                            displayHeading={true}
                        />
                    </div>
                )}
            </LoadingWrapper>
            <ConfirmCancelingSellOrderModal
                show={showCancelModal}
                sellOrder={sellOrder ?? null}
                onClose={() => {
                    setShowCancelModal(false)
                }}
                onSellOrderDeleted={async () => {
                    await loadOrder()
                }}
            />
            <EditSellOrderModal
                show={showEditModal}
                onClose={async (reload: boolean = false) => {
                    setShowEditModal(false)
                    if (reload) {
                        await loadOrder()
                    }
                }}
                onSaved={async () => {
                    setShowEditModal(false)
                    await loadOrder()
                }}
                sellOrder={sellOrder ?? null}
                carbonCreditBalance={sellOrder?.carbon_credit_balance!}
                vintage={sellOrder?.carbon_credit_balance?.vintage!}
                project={sellOrder?.carbon_credit_balance?.vintage?.project!}
            />
        </Layout>
    )
}

export default SellOrder
