import SignInSignUpLayout from '../signup/components/SignInSignUpLayout'
import { IconName } from '../signup/enums/IconName'
import ConfirmMFAForm from './components/ConfirmMFAForm'

const ConfirmMFA = (): JSX.Element => {
    return (
        <SignInSignUpLayout
            iconName={IconName.KEY}
            title="Two Factor Authentication"
            subtitle="Check your email for the verification code"
        >
            <ConfirmMFAForm />
        </SignInSignUpLayout>
    )
}

export default ConfirmMFA
