import { Modal } from 'flowbite-react'

interface ConfirmationModalProps {
    confirmButtonText?: string
    cancelButtonText?: string
    question?: string | JSX.Element
    show: boolean
    onClose: () => void
    onConfirm: () => void
}

const ConfirmationModal = ({
    show,
    onClose,
    onConfirm,
    question = 'Are you sure?',
    confirmButtonText = 'Yes',
    cancelButtonText = 'Cancel',
}: ConfirmationModalProps): JSX.Element => {
    return (
        <Modal show={show} size="md" id="confirmation-modal">
            <div className="p-6 text-center">
                <svg
                    className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500">{question}</h3>
                <button
                    onClick={onConfirm}
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                    {confirmButtonText}
                </button>
                <button
                    onClick={onClose}
                    type="button"
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                >
                    {cancelButtonText}
                </button>
            </div>
        </Modal>
    )
}

export default ConfirmationModal
