import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { Project } from '../types/Project'

interface Api {
    get(id: string): Promise<AxiosResponse<Project>>
}

export const ProjectApi: Api = Object.freeze({
    get(id: string): Promise<AxiosResponse<Project>> {
        return HttpService.get('/project', id)
    },
})
