import { useNavigate, useParams } from 'react-router-dom'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import { AdminIliDto, AdminInvoiceDto } from '../../modules/invoice/http/types/AdminInvoiceDto'
import { useEffect, useState } from 'react'
import { CostType } from '../../modules/invoice/enums/CostType'
import {
    convertCentsToLocaleUsd,
    convertGramsToMetricTonesLocaleString,
} from '../../../utils/conversion'
import { PaginationResponseDto } from '../../../modules/http/types/PaginationResponseDto'
import { AdminInvoiceApi } from '../../modules/invoice/http/AdminInvoiceApi'
import { PrimaryButton } from '../../../modules/common/components/Button'
import { Page } from '../../../modules/common/enums/Pages'
import { formatNumberToPercStr } from '../../../utils/formatNumberToPercStr'
import AdminInvoicesTable from '../../modules/invoice/components/AdminInvoicesTable'

const AdminInvoice = (): JSX.Element => {
    const [invoice, setInvoice] = useState<AdminInvoiceDto | null>(null)
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()

    const fetchInvoiceDetails = async (): Promise<void> => {
        if (id) {
            const response = await AdminInvoiceApi.getById(id)
            if (response) {
                setInvoice(response.data)
            }
        }
    }

    useEffect(() => {
        void fetchInvoiceDetails()
    }, [])

    const getDescriptionCellValue = (invoiceLineItem: AdminIliDto): JSX.Element | null => {
        switch (invoiceLineItem.cost_type) {
            case CostType.CREDITS:
                return (
                    <>
                        <div>
                            Buy Order Id: <b>{invoiceLineItem.buy_order_id}</b>
                        </div>
                        <div>
                            Trade Id: <b>{invoiceLineItem.trade_id}</b>
                        </div>
                        <div>
                            Project: <b>{invoiceLineItem.project_data.name}</b>
                        </div>
                        <div>
                            Vintage: <b>{invoiceLineItem.vintage}</b>
                        </div>
                    </>
                )
            case CostType.PLATFORM_FEE:
                return (
                    <>
                        <div>
                            Trade Id: <b>{invoiceLineItem.trade_id}</b>
                        </div>
                        <div>
                            Project: <b>{invoiceLineItem.project_data.name}</b>
                        </div>
                        <div>
                            Vintage: <b>{invoiceLineItem.vintage}</b>
                        </div>
                        <div>
                            Platform Fee:{' '}
                            <b>
                                {formatNumberToPercStr(
                                    invoiceLineItem.platform_fee_percentage ?? 0,
                                )}
                            </b>
                        </div>
                    </>
                )
            default:
                return null
        }
    }

    const getQuantity = (invoiceLineItem: AdminIliDto): string => {
        switch (invoiceLineItem.cost_type) {
            case CostType.CREDITS:
                return convertGramsToMetricTonesLocaleString(Number(invoiceLineItem.quantity))
            case CostType.PLATFORM_FEE:
                return invoiceLineItem.quantity
            default:
                throw new Error(
                    `Unknown cost type ${invoiceLineItem.cost_type} on invoice line item id ${invoiceLineItem.id}`,
                )
        }
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.INVOICES}>
            <div className="mt-10">
                <h1 className="text-gray-500 text-lg font-bold text-center">Invoice Information</h1>
                {invoice ? (
                    <AdminInvoicesTable
                        searchInvoicesResponse={{
                            invoices: [invoice],
                            pagination: {} as PaginationResponseDto,
                        }}
                        onPageChangeHandler={() => null}
                        onInvoiceActionHandler={() => fetchInvoiceDetails()}
                        showViewInvoiceDetailsButton={false}
                    />
                ) : null}
                <div className="flex flex-col items-center mt-5 mb-4">
                    <PrimaryButton onClick={() => navigate(Page.ADMIN_INVOICES)}>
                        Back
                    </PrimaryButton>
                </div>
            </div>
            <h1 className="flex-col items-center text-gray-500 text-lg font-bold text-center">
                Invoice Line Items
            </h1>
            <table className="w-full text-center block md:table overflow-x-auto scrollbar-hide">
                <thead className="w-full">
                    <tr className="h-11 items-center w-full bg-gray-50">
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            INVOICE LINE ITEM ID
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            BUY ORDER ID
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            SELL ORDER ID
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3 min-w-[150px]">
                            COST TYPE
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3 min-w-[300px]">
                            DESCRIPTION
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            NET UNIT PRICE
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            GROSS UNIT PRICE
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            QUANTITY
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            NET TOTAL PRICE
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            PLATFORM FEE %
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            TOTAL PLATFORM FEE
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            GROSS TOTAL PRICE
                        </th>
                    </tr>
                </thead>
                <tbody className="whitespace-nowrap">
                    {invoice?.invoice_line_items.map((invoiceLineItem) => (
                        <tr className="py-2 border-b items-center w-full" key={invoiceLineItem.id}>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {invoiceLineItem.id}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {invoiceLineItem.buy_order_id}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {invoiceLineItem.sell_order_id}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal min-w-[150px] break-all">
                                {invoiceLineItem.cost_type}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal min-w-[300px] break-all">
                                {getDescriptionCellValue(invoiceLineItem)}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {convertCentsToLocaleUsd(invoiceLineItem.net_unit_price)}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {convertCentsToLocaleUsd(invoiceLineItem.gross_unit_price)}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {getQuantity(invoiceLineItem)}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {convertCentsToLocaleUsd(invoiceLineItem.net_total_cost)}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {formatNumberToPercStr(
                                    invoiceLineItem.platform_fee_percentage ?? 0,
                                )}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {convertCentsToLocaleUsd(invoiceLineItem.platform_fee_amount ?? 0)}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {convertCentsToLocaleUsd(invoiceLineItem.gross_total_cost)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <h1 className="flex-col items-center text-gray-500 text-lg font-bold text-center mt-8">
                CANCELLED Invoice Line Items
            </h1>
            <table className="w-full text-center block md:table overflow-x-auto scrollbar-hide">
                <thead className="w-full">
                    <tr className="h-11 items-center w-full bg-gray-50">
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            INVOICE LINE ITEM ID
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            BUY ORDER ID
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            SELL ORDER ID
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3 min-w-[150px]">
                            COST TYPE
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3 min-w-[300px]">
                            DESCRIPTION
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            NET UNIT PRICE
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            GROSS UNIT PRICE
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            QUANTITY
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            NET TOTAL PRICE
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            PLATFORM FEE %
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            TOTAL PLATFORM FEE
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            GROSS TOTAL PRICE
                        </th>
                    </tr>
                </thead>
                <tbody className="whitespace-nowrap">
                    {invoice?.cancelled_line_items.map((invoiceLineItem) => (
                        <tr className="py-2 border-b items-center w-full" key={invoiceLineItem.id}>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {invoiceLineItem.id}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {invoiceLineItem.buy_order_id}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {invoiceLineItem.sell_order_id}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal min-w-[150px] break-all">
                                {invoiceLineItem.cost_type}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal min-w-[300px] break-all">
                                {getDescriptionCellValue(invoiceLineItem)}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {convertCentsToLocaleUsd(invoiceLineItem.net_unit_price)}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {convertCentsToLocaleUsd(invoiceLineItem.gross_unit_price)}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {getQuantity(invoiceLineItem)}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {convertCentsToLocaleUsd(invoiceLineItem.net_total_cost)}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {formatNumberToPercStr(
                                    invoiceLineItem.platform_fee_percentage ?? 0,
                                )}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {convertCentsToLocaleUsd(invoiceLineItem.platform_fee_amount ?? 0)}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal break-all">
                                {convertCentsToLocaleUsd(invoiceLineItem.gross_total_cost)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </AdminLayout>
    )
}

export default AdminInvoice
