import { formatNumberWithCommas } from './formatNumberWithCommas'

export const getUSDCurrencyString = (cents: number | string): string => {
    const centsStr = cents.toString()

    if (centsStr.length === 0) {
        return '$0.00'
    }
    if (centsStr.length === 1) {
        return `$0.0${centsStr}`
    }
    if (centsStr.length === 2) {
        return `$0.${centsStr}`
    }

    // we have both whole dollars and cents

    // strip the last 2 chars off - these are the cents
    const centsPart = centsStr.slice(-2)
    const dollarPart = centsStr.slice(0, -2)

    // TODO: format the dollarPart to include commas

    return `$${formatNumberWithCommas(Number(dollarPart))}.${centsPart}`
}
