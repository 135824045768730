import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AdminProjectApi } from '../../modules/projects/http/api'
import { Project } from '../../../modules/project/types/Project'
import { Page } from '../../../modules/common/enums/Pages'
import AdminLayout from '../../modules/common/components/AdminLayout'
import EditProjectForm from '../../modules/projects/components/EditProjectForm'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'

const AdminEditProject = (): JSX.Element => {
    const [project, setProject] = useState<Project>()

    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()

    const fetchProjectById = async (id: string) => {
        const response = await AdminProjectApi.getById(id)
        setProject(response?.data)
    }

    useEffect(() => {
        if (id) {
            void fetchProjectById(id)
        }
    }, [id])

    const onProjectSavedHandler = async (): Promise<void> => {
        navigate(Page.ADMIN_PROJECTS)
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.PROJECTS}>
            <>
                {!project && <div>Project Not found.</div>}
                {project && (
                    <EditProjectForm projectData={project} onProjectSaved={onProjectSavedHandler} />
                )}
            </>
        </AdminLayout>
    )
}

export default AdminEditProject
