import {
    ModerateDangerButton,
    SecondaryButton,
    PrimaryButton,
} from '../../../modules/common/components/Button'
import CheckBoxWrapper from '../../../modules/common/components/CheckBoxWrapper'
import SdgLogo from '../../../modules/project/components/SdgLogo'
import { ProjectLocation } from '../../../modules/project/enums/ProjectLocation'
import { SDG } from '../../../modules/project/enums/SDG'
import { useSellOrdersStore } from '../../../modules/sellOrders/context/store'
import { SellOrderApi } from '../../../modules/sellOrders/http/SellOrderApi'
import { SearchSellOrdersParams } from '../../../modules/sellOrders/http/types/SearchSellOrdersParams'
import { Modal, ModalBody, ModalFooter } from 'flowbite-react'
import { range } from 'lodash'
import { useEffect, useState } from 'react'
import { convertUsdToCents } from '../../../utils/conversion'
import { getCountryNameFromCountryCode } from '../../../utils/getCountryNameFromCountryCode'
import { pluralize } from '../../../utils/pluralize'

interface ClearAllProps {
    onClear: () => void
}

const ClearAll = ({ onClear }: ClearAllProps): JSX.Element => {
    return (
        <p className="text-xs text-primary-600 underline cursor-pointer" onClick={onClear}>
            Clear all
        </p>
    )
}

/* eslint-disable indent */
const MarketFiltersModal = (): JSX.Element => {
    const {
        searchSellOrderFilters,
        isFiltersModalVisible,
        searchSellOrdersResponse,
        showAllLocations,
        isFormDisabled,
        setIsFiltersModalVisible,
        setSearchSellOrderFilters,
        setShowAllLocations,
    } = useSellOrdersStore()

    const [selectedLocations, setSelectedLocations] = useState<ProjectLocation[]>(
        searchSellOrderFilters.locations,
    )
    const [selectedSdgs, setSelectedSdgs] = useState<SDG[]>([])
    const [selectedVintages, setSelectedVintages] = useState<number[]>([])
    const [projectsCount, setProjectsCount] = useState<number>(0)

    const getProjectsCount = async (): Promise<void> => {
        let queryParams: SearchSellOrdersParams = {
            count_only: true,
            name: searchSellOrderFilters.projectName,
            type: searchSellOrderFilters.projectType,
            location: selectedLocations,
            sdg: selectedSdgs,
            vintage: selectedVintages,
        }
        if (searchSellOrderFilters.price.min) {
            queryParams = {
                ...queryParams,
                min_price: convertUsdToCents(searchSellOrderFilters.price.min),
            }
        }
        if (searchSellOrderFilters.price.max) {
            queryParams = {
                ...queryParams,
                max_price: convertUsdToCents(searchSellOrderFilters.price.max),
            }
        }
        const response = await SellOrderApi.search(queryParams)
        if (response) {
            setProjectsCount(response.data.pagination.total_items)
        }
    }

    const clearAllModalFilters = () => {
        setSelectedLocations([])
        setSelectedSdgs([])
        setSelectedVintages([])
    }

    const shouldDisableClearButton = () => {
        return !selectedSdgs.length && !selectedVintages.length && !selectedLocations.length
    }

    useEffect(() => {
        if (isFiltersModalVisible) {
            void getProjectsCount()
        }
    }, [selectedLocations, selectedSdgs, selectedVintages, isFiltersModalVisible])

    useEffect(() => {
        setProjectsCount(searchSellOrdersResponse?.pagination?.total_items ?? 0)
    }, [searchSellOrdersResponse])

    // default values
    useEffect(() => {
        setSelectedLocations(searchSellOrderFilters?.locations ?? [])
        setSelectedSdgs(searchSellOrderFilters?.sdgs ?? [])
        setSelectedVintages(searchSellOrderFilters?.vintages ?? [])
    }, [searchSellOrderFilters, isFiltersModalVisible])

    return (
        <Modal show={isFiltersModalVisible} size="7xl" id="modalMarketFilters">
            <div
                className="overflow-hidden overflow-x-hidden"
                style={{
                    height: 'calc(100vh - 100px)',
                }}
            >
                <ModalBody style={{ padding: '24px 24px 0 24px' }}>
                    <div
                        className="flex-col overflow-y-auto overflow-x-hidden"
                        style={{
                            maxHeight: 'calc(100vh - 250px)',
                        }}
                    >
                        {/* Locations */}
                        <div className="pt-6 px-42">
                            <div>
                                <div className="flex flex-row items-center h-7.5">
                                    <p className="text-xl text-gray-700 mr-3 font-semibold">
                                        Location
                                    </p>
                                    <ClearAll onClear={() => setSelectedLocations([])} />
                                </div>
                                <p className="text-gray-500 text-sm leading-5">
                                    The location where the projects carbon credits have been created
                                </p>
                                <div className="flex flex-row flex-wrap pt-9">
                                    {(
                                        Object.keys(ProjectLocation) as Array<
                                            keyof typeof ProjectLocation
                                        >
                                    )
                                        .filter((key) => {
                                            if (
                                                (searchSellOrdersResponse?.active_filters.locations
                                                    .length ?? 0) > 0
                                            ) {
                                                return searchSellOrdersResponse?.active_filters.locations.includes(
                                                    ProjectLocation[key],
                                                )
                                            }
                                            return false
                                        })
                                        .slice(
                                            0,
                                            showAllLocations
                                                ? Object.keys(ProjectLocation).length
                                                : 8,
                                        )
                                        .map((key) => (
                                            <div key={key} className="flex items-center mb-4 w-1/4">
                                                <CheckBoxWrapper
                                                    key={key}
                                                    checked={selectedLocations.includes(
                                                        ProjectLocation[key],
                                                    )}
                                                    disabled={isFormDisabled}
                                                    label={
                                                        <div className="flex gap-3">
                                                            {getCountryNameFromCountryCode(
                                                                ProjectLocation[key],
                                                            )}
                                                        </div>
                                                    }
                                                    onChange={(checked: boolean) => {
                                                        if (checked) {
                                                            setSelectedLocations([
                                                                ...selectedLocations,
                                                                ProjectLocation[key],
                                                            ])
                                                        } else {
                                                            setSelectedLocations(
                                                                selectedLocations.filter(
                                                                    (location: ProjectLocation) =>
                                                                        location !==
                                                                        ProjectLocation[key],
                                                                ),
                                                            )
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="h-12 mt-1.5 flex justify-center items-center">
                                <p
                                    className="text-sm font-semibold text-primary-700 text-center cursor-pointer align-middle"
                                    onClick={() => setShowAllLocations(!showAllLocations)}
                                >
                                    {(
                                        Object.keys(ProjectLocation) as Array<
                                            keyof typeof ProjectLocation
                                        >
                                    ).filter((key) => {
                                        if (
                                            (searchSellOrdersResponse?.active_filters.locations
                                                .length ?? 0) > 0
                                        ) {
                                            return searchSellOrdersResponse?.active_filters.locations.includes(
                                                ProjectLocation[key],
                                            )
                                        }
                                        return false
                                    }).length > 8 && (showAllLocations ? 'View Less' : 'View More')}
                                </p>
                            </div>
                        </div>
                        <hr className="mt-3.5 mb-5" />
                        {/* SDGS */}
                        <div className="pt-4.5 pl-42">
                            <div>
                                <div className="flex flex-row items-center h-7.5">
                                    <p className="text-xl text-gray-700 mr-3 font-semibold">SDGs</p>
                                    <ClearAll onClear={() => setSelectedSdgs([])} />
                                </div>
                                <p className="text-gray-500 text-sm leading-5">
                                    The sustainable development goals which are positively impacted
                                    by the project
                                </p>
                                <div className="flex flex-row flex-wrap pt-9">
                                    {(Object.keys(SDG) as Array<keyof typeof SDG>).map((key) => (
                                        <div key={key} className="flex items-center mb-4 w-1/4">
                                            <CheckBoxWrapper
                                                disabled={isFormDisabled}
                                                key={key}
                                                checked={selectedSdgs.includes(SDG[key])}
                                                label={
                                                    <div className="flex gap-3">
                                                        <SdgLogo sdg={SDG[key]} />
                                                        {SDG[key]}
                                                    </div>
                                                }
                                                onChange={(checked: boolean) => {
                                                    if (checked) {
                                                        setSelectedSdgs([...selectedSdgs, SDG[key]])
                                                    } else {
                                                        setSelectedSdgs(
                                                            selectedSdgs.filter(
                                                                (sdg: SDG) => sdg !== SDG[key],
                                                            ),
                                                        )
                                                    }
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <hr className="mt-3.5 mb-5" />
                        {/* SDGS */}
                        <div className="pt-4.5 pl-42">
                            <div>
                                <div className="flex flex-row items-center h-7.5">
                                    <p className="text-xl text-gray-700 mr-3 font-semibold">
                                        Vintage
                                    </p>
                                    <ClearAll onClear={() => setSelectedVintages([])} />
                                </div>
                                <p className="text-gray-500 text-sm leading-5">
                                    The year during which the carbon credit was created
                                </p>
                                <div className="flex flex-row flex-wrap pt-9">
                                    {range(2010, new Date().getFullYear() + 2)
                                        .filter((vintage) => {
                                            if (
                                                (searchSellOrdersResponse?.active_filters.vintages
                                                    .length ?? 0) > 0
                                            ) {
                                                return searchSellOrdersResponse?.active_filters.vintages.includes(
                                                    vintage,
                                                )
                                            }
                                            return false
                                        })
                                        .map((vintage) => (
                                            <div
                                                key={vintage}
                                                className="flex items-center mb-4 w-1/4"
                                            >
                                                <CheckBoxWrapper
                                                    disabled={isFormDisabled}
                                                    key={vintage}
                                                    checked={selectedVintages.includes(vintage)}
                                                    label={
                                                        <div className="flex gap-3">
                                                            <p>{vintage}</p>
                                                        </div>
                                                    }
                                                    onChange={(checked: boolean) => {
                                                        if (checked) {
                                                            setSelectedVintages([
                                                                ...selectedVintages,
                                                                vintage,
                                                            ])
                                                        } else {
                                                            setSelectedVintages(
                                                                selectedVintages.filter(
                                                                    (v: number) => v !== vintage,
                                                                ),
                                                            )
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter
                    style={{
                        paddingLeft: 24,
                        paddingRight: 36,
                        paddingTop: 34,
                        paddingBottom: 28,
                    }}
                >
                    <div className="flex justify-between w-full items-center h-15">
                        <ModerateDangerButton
                            className="w-16"
                            disabled={shouldDisableClearButton()}
                            onClick={clearAllModalFilters}
                        >
                            {/* <span className="flex justify-center items-center gap-3">
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 2.00008V8.00008M1 8.00008H7M1 8.00008L5.64 3.64008C6.71475 2.56479 8.04437 1.77928 9.50481 1.35685C10.9652 0.934417 12.5089 0.888827 13.9917 1.22433C15.4745 1.55984 16.8482 2.26551 17.9845 3.27549C19.1209 4.28548 19.9828 5.56686 20.49 7.00008M23 18.0001V12.0001M23 12.0001H17M23 12.0001L18.36 16.3601C17.2853 17.4354 15.9556 18.2209 14.4952 18.6433C13.0348 19.0657 11.4911 19.1113 10.0083 18.7758C8.52547 18.4403 7.1518 17.7346 6.01547 16.7247C4.87913 15.7147 4.01717 14.4333 3.51 13.0001"
                                        stroke="#F85149"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                Clear
                            </span> */}
                            Clear
                        </ModerateDangerButton>
                        <div className="flex gap-3">
                            <SecondaryButton onClick={() => setIsFiltersModalVisible(false)}>
                                Close
                            </SecondaryButton>
                            <PrimaryButton
                                className="min-w-44"
                                disabled={projectsCount === 0}
                                onClick={() => {
                                    setSearchSellOrderFilters({
                                        ...searchSellOrderFilters,
                                        locations: selectedLocations,
                                        sdgs: selectedSdgs,
                                        vintages: selectedVintages,
                                    })
                                    setIsFiltersModalVisible(false)
                                }}
                            >
                                <span className="flex justify-center items-center gap-3">
                                    <img src="/assets/icon_save_white.svg" alt="icon_save_white" />
                                    {projectsCount > 0
                                        ? `Show ${projectsCount} ${pluralize(
                                              projectsCount,
                                              'Result',
                                          )}`
                                        : 'No Results Found'}
                                </span>
                            </PrimaryButton>
                        </div>
                    </div>
                </ModalFooter>
            </div>
        </Modal>
    )
}

export default MarketFiltersModal
