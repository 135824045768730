import { useRef, useEffect } from 'react'
import { useSellOrdersStore } from '../../../modules/sellOrders/context/store'

export default function withClickOutside(WrappedComponent: any) {
    const Component = () => {
        const { setShouldShowProjectTypeFilter } = useSellOrdersStore()

        const ref = useRef<any>()

        useEffect(() => {
            const handleClickOutside = (event: any) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShouldShowProjectTypeFilter(false)
                }
            }
            document.addEventListener('mousedown', handleClickOutside)
        }, [ref])

        return <WrappedComponent ref={ref} />
    }

    return Component
}
