import { AxiosResponse } from 'axios'
import { EmptyResponse } from '../../http/types/EmptyResponse'
import { User } from '../../user/types/User'
import { SignInResponse } from './types/SignInResponse'
import { TwoFactorAuthenticationCodeRequiredResponse } from './types/TwoFactorAuthenticationCodeRequiredResponse'
import HttpService from '../../http/httpService'

export interface AuthenticationApiInterface {
    signin(
        email: string,
        password: string,
        rememberMe: boolean,
        gRecaptchaToken: string,
    ): Promise<
        AxiosResponse<SignInResponse | TwoFactorAuthenticationCodeRequiredResponse> | undefined
    >
    refreshToken(refreshToken: string): Promise<AxiosResponse<SignInResponse> | undefined>
    getAuthenticatedUser(): Promise<AxiosResponse<User> | undefined>
    signout(): Promise<AxiosResponse<EmptyResponse> | undefined>
    submitOtpCode(
        mfaCode: string,
        gRecaptchaToken: string,
    ): Promise<AxiosResponse<SignInResponse> | undefined>
}

export const AuthenticationApi: AuthenticationApiInterface = Object.freeze({
    async signin(
        email: string,
        password: string,
        rememberMe: boolean = false,
        gRecaptchaToken: string,
    ): Promise<
        AxiosResponse<SignInResponse | TwoFactorAuthenticationCodeRequiredResponse> | undefined
    > {
        return HttpService.post('/signin', {
            email,
            password,
            grecaptcha_token: gRecaptchaToken,
            remember_me: rememberMe,
        })
    },
    refreshToken(refreshToken: string): Promise<AxiosResponse<SignInResponse> | undefined> {
        return HttpService.post('/refresh-token', {
            refresh_token: refreshToken,
        })
    },
    getAuthenticatedUser(): Promise<AxiosResponse<User> | undefined> {
        return HttpService.get('/authenticated-user')
    },

    signout(): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.get('/signout')
    },
    async submitOtpCode(
        mfaCode: string,
        gRecaptchaToken: string,
    ): Promise<AxiosResponse<SignInResponse> | undefined> {
        return await HttpService.post('/signin/2fa', {
            grecaptcha_token: gRecaptchaToken,
            mfaCode,
        })
    },
})
