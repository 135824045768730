import { useState } from 'react'
import { CarbonCreditBalanceSingleResult } from '../../modules/carbonCreditBalance/types/CarbonCreditBalance'
import { CarbonCreditBalanceApi } from '../../modules/carbonCreditBalance/http/api'
import LoadingWrapper from '../../modules/loadingWrapper/components/LoadingWrapper'
import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import ProjectDetailCarbonCreditBalance from '../../modules/carbonCreditBalance/components/ProjectDetailCarbonCreditBalance'
import AlignMiddle from '../../modules/common/components/AlignMiddle'
import ToggleSwitch from '../../modules/common/components/ToggleSwitch'
import Note, { NoteType } from '../../modules/note/components/Note'

interface Props {}

const Inventory = ({}: Props): JSX.Element => {
    const [balances, setBalances] = useState<CarbonCreditBalanceSingleResult[]>([])
    const [showEmptyBalances, setShowEmptyBalances] = useState<boolean>(false)

    const getBalances = async (): Promise<void> => {
        const response = await CarbonCreditBalanceApi.search({})
        if (!response) return
        setBalances(response.data.results)
    }

    return (
        <Layout
            title=""
            subTitle=""
            dataCy="inventory-layout"
            selectedNavBarItem={NavBarItemName.INVENTORY}
        >
            <LoadingWrapper
                waitFor={getBalances}
                errMsg="There was a problem loading your inventory. Please refresh the page to try again."
            >
                <div className="flex flex-col gap-4 w-full lg:w-[70%] mx-auto">
                    <AlignMiddle direction="row" childClassName="order-1">
                        <ToggleSwitch
                            id="show-empty-balances-toggle"
                            label="Show zero balances"
                            checked={showEmptyBalances}
                            onChange={() => {
                                setShowEmptyBalances(!showEmptyBalances)
                            }}
                        ></ToggleSwitch>
                    </AlignMiddle>
                    {balances.map((balance) => {
                        return (
                            <ProjectDetailCarbonCreditBalance
                                vintages={balance.vintages}
                                project={balance}
                                reloadProject={getBalances}
                                key={balance.id}
                                hideEmptyBalances={!showEmptyBalances}
                            />
                        )
                    })}
                    {balances.length === 0 && <Note type={NoteType.INFO}>No inventory found</Note>}
                </div>
            </LoadingWrapper>
        </Layout>
    )
}

export default Inventory
