import React from 'react'
import { RegistryIds } from '../enums/RegistryIds'

interface RegistryIconProps {
    registryId?: RegistryIds | string
    registryLink?: string
    classNames?: string
}

const RegistryIcon = ({
    registryId,
    registryLink,
    classNames,
}: RegistryIconProps): JSX.Element | null => {
    const icons: Record<RegistryIds, JSX.Element> = {
        [RegistryIds.GLOBAL_CARBON_COUNCIL]: (
            <img className="h-10" src="/assets/registry_logo/global-carbon-council.png" />
        ),
        [RegistryIds.GOLD_STANDARD]: (
            <img className="h-4 bg-transparent" src="/assets/registry_logo/gold-standard.png" />
        ),
        [RegistryIds.VERRA]: <img className="h-7.5" src="/assets/registry_logo/verra.png" />,
        [RegistryIds.AMERICAN_CARBON_REGISTRY]: (
            <img className="h-7.5" src="/assets/registry_logo/american-carbon-registry.png" />
        ),
        [RegistryIds.CLIMATE_ACTION_RESERVE]: (
            <img className="h-7.5" src="/assets/registry_logo/climate-action-reserve.png" />
        ),
        [RegistryIds.BIOCARBON_REGISTRY]: (
            <img className="h-7.5" src="/assets/registry_logo/bio-carbon-registry.png" />
        ),
        [RegistryIds.CERCARBONO_ECOREGISTRY]: (
            <img className="h-7.5" src="/assets/registry_logo/cercarbono.png" />
        ),
        [RegistryIds.PLAN_VIVO]: (
            <img className="h-7.5" src="/assets/registry_logo/plan-vivo.png" />
        ),
        [RegistryIds.UNFCCC_CLEAN_DEVELOPMENT_MECHANISM]: (
            <img className="h-7.5 bg-[#1c436b]" src="/assets/registry_logo/unfccc.png" />
        ),
        [RegistryIds.PURO]: <img className="h-7.5" src="/assets/registry_logo/puro.earth.png" />,
        [RegistryIds.CENTIGRADE]: (
            <img className="h-10" src="/assets/registry_logo/centigrade.png" />
        ),
        [RegistryIds.SOCIAL_CARBON]: (
            <img className="h-[21px]" src="/assets/registry_logo/social_carbon.png" />
        ),
    }

    if (!icons[registryId as RegistryIds]) return null
    return (
        <div className={`min-w-[150px] flex gap-2 ${classNames}`}>
            {icons[registryId as RegistryIds]}{' '}
            {registryLink && (
                <a href={registryLink} target="_blank" title="Project Registry">
                    <img src="/assets/icon_external_link.svg" alt="icon_external_link" />
                </a>
            )}
        </div>
    )
}

export default RegistryIcon
