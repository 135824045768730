interface Props {
    label: string[]
    value: string
    bgColorClassName: 'bg-white' | 'bg-gray-50'
}

export const OverviewListItem = ({ label, value, bgColorClassName }: Props): JSX.Element => {
    return (
        <div className={`flex flex-row ${bgColorClassName}`} role="root">
            <div className="flex items-center">
                <p
                    className="text-base w-[180px] font-semibold text-gray-900 pl-6 flex flex-col"
                    data-testid="label-text"
                >
                    {label.map((text) => (
                        <span key={text}>{text}</span>
                    ))}
                </p>
            </div>
            <div className="p-4">
                <p className="text-sm font-normal text-gray-500" data-testid="value-text">
                    {value}
                </p>
            </div>
        </div>
    )
}

export default OverviewListItem
