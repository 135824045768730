import { useEffect, useState } from 'react'
import {
    convertCentsToLocaleUsd,
    convertGramsToMetricTonesLocaleString,
} from '../../../utils/conversion'
import { SellOrder } from '../../sellOrders/types/SellOrder'
import Table, { Column, Values } from '../../table/components/Table'
import { Vintage } from '../../vintage/types/Vintage'
import { SellOrderStatus } from '../../sellOrders/enums/SelOrderStatus'

interface Props {
    sellOrder: SellOrder | null
    vintages: Map<number, Vintage>
    onSelect: (sellOrderId: string) => void
}

type Columns = 'price' | 'quantity' | 'vintage'

const PriceAndQuantityDetails = ({ sellOrder, vintages, onSelect }: Props): JSX.Element => {
    const [displayData, setDisplayData] = useState<Values<Columns>[]>([])
    const [displayOptions, setDisplayOptions] = useState<Values<Columns>[]>([])
    const columns: Column<Columns>[] = [
        {
            name: 'vintage',
            display: 'Vintage',
        },
        {
            name: 'price',
            display: 'Price',
        },
        {
            name: 'quantity',
            display: 'Quantity',
        },
    ]

    const isValidSellOrder = (order: SellOrder | null): boolean => {
        return !!order && order.status === SellOrderStatus.LIVE && order.quantity !== String(0)
    }

    useEffect(() => {
        const data: Values<Columns>[] = [
            {
                price: {
                    value: convertCentsToLocaleUsd(BigInt(sellOrder?.price ?? String(0))),
                    className: 'font-bold',
                },
                quantity: {
                    value: isValidSellOrder(sellOrder)
                        ? convertGramsToMetricTonesLocaleString(
                              Number(sellOrder?.quantity ?? String(0)),
                          )
                        : 'Sold Out',
                },
                vintage: {
                    value: sellOrder?.vintage ?? '-',
                },
            },
        ]
        const options: Values<Columns>[] = Array.from(vintages.values()).flatMap((vintage) => {
            return (vintage.sell_orders ?? []).reduce((acc: Values<Columns>[], order) => {
                if (order.status !== SellOrderStatus.LIVE || order.quantity === String(0))
                    return acc
                acc.push({
                    price: {
                        value: convertCentsToLocaleUsd(BigInt(order.price)),
                        className: 'font-bold',
                    },
                    quantity: {
                        value: convertGramsToMetricTonesLocaleString(Number(order.quantity)),
                    },
                    vintage: {
                        value: order.vintage,
                    },
                })
                return acc
            }, [])
        })
        setDisplayData(data)
        setDisplayOptions(options)
    }, [sellOrder, vintages])

    if (!vintages) return <></>

    const isSelected = (option: Values<Columns>) =>
        option.vintage.value === displayData[0].vintage.value &&
        option.price.value === displayData[0].price.value

    const onOptionSelect = (option: Values<Columns>) => {
        const order = vintages.get(Number(option.vintage.value))?.sell_orders?.find((o) => {
            return (
                convertCentsToLocaleUsd(BigInt(o.price)) === option.price.value &&
                convertGramsToMetricTonesLocaleString(Number(o.quantity)) === option.quantity.value
            )
        })
        onSelect(order!.id)
    }
    return (
        <div className="mt-1 border border-gray-200 shadow-sm rounded-lg">
            <Table
                columns={columns}
                data={displayData}
                highlightOnHover={false}
                overflow="overflow-hidden"
                rounded="lg:rounded-bl-lg"
                className="h-full border-none"
                selectable={true}
                options={displayOptions}
                isSelected={isSelected}
                onOptionSelect={onOptionSelect}
            />
        </div>
    )
}

export default PriceAndQuantityDetails
