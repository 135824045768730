import AlignMiddle from '../../common/components/AlignMiddle'
import { ProjectType } from '../enums/ProjectType'
import { ProjectTypeImgType, getProjectTypeImgSrc } from '../getProjectTypeImgSrc'

interface Props {
    squareImageUrl?: string
    type: ProjectType
    width?: string
    height?: string
    rounded?: string
    id: string
}

const ProjectSquareImage = ({
    squareImageUrl,
    type,
    width = 'w-[32px]',
    height = 'h-[32px]',
    rounded = 'rounded-full',
    id,
}: Props): JSX.Element => {
    return (
        <AlignMiddle direction="column" className="" bufferClassName={width}>
            <img
                src={
                    squareImageUrl
                        ? squareImageUrl
                        : getProjectTypeImgSrc(type, ProjectTypeImgType.SQUARE)
                }
                id={id}
                alt="icon_project_avatar"
                className={`${width} ${height} ${rounded}`}
                onError={() => {
                    // if for some reason the square image url is not available, use the default image
                    if (!squareImageUrl) return
                    const img = document.getElementById(id) as HTMLImageElement
                    img.src = getProjectTypeImgSrc(type, ProjectTypeImgType.SQUARE)
                }}
            />
        </AlignMiddle>
    )
}

export default ProjectSquareImage
