import { AlertMessage, SetAlertMessageAction, SET_ALERT_MESSAGE } from './types'
import { Dispatch } from 'react'

export const setAlertMessage = (
    alertMessage: AlertMessage | null,
    dispatch: Dispatch<SetAlertMessageAction>,
): void => {
    dispatch({
        type: SET_ALERT_MESSAGE,
        payload: { alertMessage },
    })
}
