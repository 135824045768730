import { useEffect, useState } from 'react'
import {
    convertGramsToMetricTonnes,
    convertMetricTonesToGramsNumeric,
} from '../../../utils/conversion'
import { SecondaryButton, PrimaryButton } from '../../common/components/Button'
import { Vintage } from '../../vintage/types/Vintage'
import { Project } from '../../project/types/Project'
import BaseModal from '../../modal/components/BaseModal'
import { FormElement } from '../../form/types/Field'
import { useForm } from 'react-hook-form'
import { ListItem } from '../../form/types/ListItem'
import { Button } from '../../form/types/Button'
import Form from '../../form/components/Form'
import { generateUniqueId } from '../../../utils/generateUniqueId'
import CreditsConfirmation from './CreditsConfirmation'
import { ActionName } from '../../action/enums/ActionName'
import { CarbonCreditBalanceSingleResult } from '../../carbonCreditBalance/types/CarbonCreditBalance'
import { UnbridgeRequestApi } from '../../withdrawal/http/api'

interface Props {
    show: boolean
    onClose: (reload?: boolean) => void
    vintage: Vintage | null
    project: Project | CarbonCreditBalanceSingleResult
}

type ValidationErrors = {
    quantity?: string[]
    unhandled?: string[]
}

type FormData = {
    quantity: number
}

const UnbridgeCreditsModal = ({ show, onClose, vintage, project }: Props) => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [errors, setErrors] = useState<ValidationErrors | string[] | null>(null)
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
    const [requestId, setRequestId] = useState<string | null>(null)
    const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false)
    const form = useForm<FormData>({
        defaultValues: {
            quantity: 0,
        },
    })

    const close = (reload?: boolean) => {
        form.reset()
        setErrors(null)
        setRequestId(null)
        setDisplayConfirmation(false)
        onClose(reload)
    }

    useEffect(() => {
        const isDisabled = form.getValues('quantity') === 0
        setIsButtonDisabled(isDisabled)
    }, [form.watch(['quantity'])])

    useEffect(() => {
        setRequestId(generateUniqueId())
    }, [])

    if (!vintage) return <></>

    const saveChanges = async () => {
        setErrors(null)
        setShowSpinner(true)
        try {
            if (vintage.id) {
                await UnbridgeRequestApi.requestUnbridge(
                    {
                        vintage_id: vintage.id,
                        quantity: String(
                            convertMetricTonesToGramsNumeric(form.getValues('quantity')),
                        ),
                    },
                    String(requestId),
                )
            }
            close(true)
        } catch (e: unknown) {
            setErrors(['An unexpected error has occurred, please try again'])
            throw e
        } finally {
            setShowSpinner(false)
            form.reset()
        }
    }

    const fields: FormElement<FormData>[] = [
        {
            name: 'quantity',
            displayName: 'Quantity',
            type: 'number_slider',
            required: true,
            min: 1,
            max: Math.floor(
                convertGramsToMetricTonnes(vintage.carbon_credit_balances?.[0]?.liquid_amount!),
            ),
            step: 1,
            id: 'unbridge-credits-amount',
        },
    ]

    const listItems: ListItem[] = [
        {
            label: ['Project'],
            value: project.name,
        },
        {
            label: ['Vintage'],
            value: vintage.vintage.toString(),
        },
    ]
    const title: string = 'Withdraw Credits'
    const buttons: Button[] = [
        {
            type: 'button',
            className: '',
            onClick: close,
            disabled: showSpinner,
            id: 'close-unbridge',
            variation: SecondaryButton,
            label: 'Close',
        },
        {
            type: 'submit',
            className: '',
            disabled: isButtonDisabled,
            id: 'unbridge-credits',
            variation: PrimaryButton,
            label: 'Review Withdrawal',
        },
    ]
    return (
        <BaseModal
            show={show}
            id="modal-unbridge-credits"
            position="center"
            error={errors}
            showSpinner={showSpinner}
            onClose={close}
        >
            {!displayConfirmation && (
                <Form
                    title={title}
                    onSubmit={() => {
                        setDisplayConfirmation(true)
                    }}
                    formId="create-sell-order-form"
                    fields={fields}
                    headerListItems={listItems}
                    buttons={buttons}
                    form={form}
                />
            )}
            {displayConfirmation && (
                <CreditsConfirmation
                    actionName={ActionName.RETURN}
                    showSpinner={showSpinner}
                    price={0}
                    pricePerCreditCents={0}
                    quantity={convertMetricTonesToGramsNumeric(form.getValues('quantity'))}
                    vintage={vintage}
                    project={project}
                    onClose={() => {
                        close()
                    }}
                    onConfirm={saveChanges}
                    retireeName={undefined}
                    isSubmitButtonEnabled={!isButtonDisabled}
                />
            )}
        </BaseModal>
    )
}

export default UnbridgeCreditsModal
