import { BuyOrderStatus } from '../../buyOrders/http/enums/BuyOrderStatus'
import StatusPill, { CircleStyle, Colour } from '../../statusPill/components/StatusPill'

interface Props {
    status: BuyOrderStatus
}

const TradeHistoryStatusPill = ({ status }: Props): JSX.Element => {
    switch (status) {
        case BuyOrderStatus.CANCELLED:
            return (
                <StatusPill colour={Colour.RED} circleStyle={CircleStyle.FILLED}>
                    Cancelled
                </StatusPill>
            )
        case BuyOrderStatus.CLOSED:
        case BuyOrderStatus.OPEN:
        case BuyOrderStatus.PENDING_SETTLEMENT:
            return (
                <StatusPill colour={Colour.YELLOW} circleStyle={CircleStyle.SEMI_FILLED}>
                    Pending
                </StatusPill>
            )
        case BuyOrderStatus.SETTLED:
            return (
                <StatusPill colour={Colour.GREEN} circleStyle={CircleStyle.FILLED}>
                    Complete
                </StatusPill>
            )

        default:
            throw new Error(`Unknown trade status: ${status}`)
    }
}

export default TradeHistoryStatusPill
