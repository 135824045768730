import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BuyOrderStatus } from '../../../modules/buyOrders/http/enums/BuyOrderStatus'
import { useAdminStore } from '../../../modules/admin/context/store'
import { TradeHistoryType } from '../../../modules/tradeHistory/enums/TradeHistoryType'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { SearchBuyOrderResponse } from '../../modules/buyOrder/http/types/SearchBuyOrderResponse'
import { AdminBuyOrderApi } from '../../modules/buyOrder/http/api'
import { AdminSearchBuyOrder } from '../../modules/buyOrder/http/types/BuyOrder'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import AdminBuyOrderTable from '../../modules/buyOrder/components/BuyOrderTable'

const AdminBuyOrders = (): JSX.Element => {
    const [searchBuyOrdersResponse, setSearchBuyOrdersResponse] =
        useState<SearchBuyOrderResponse | null>(null)
    const { setShowAlertMessage } = useAdminStore()
    const [searchParams, setSearchParams] = useSearchParams()

    const getBuyOrders = async (): Promise<void> => {
        const response = await AdminBuyOrderApi.search({
            page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
        })
        if (response) {
            setSearchBuyOrdersResponse(response.data)
        }
    }

    const canCancelBuyOrder = (buyOrder: AdminSearchBuyOrder): boolean => {
        return (
            ![BuyOrderStatus.SETTLED, BuyOrderStatus.CANCELLED].includes(buyOrder.status) &&
            !buyOrder.trades.find((trade) => trade.type === TradeHistoryType.FRACTIONALISED)
        )
    }

    const cancelBuyOrder = async (buyOrder: AdminSearchBuyOrder): Promise<void> => {
        try {
            await AdminBuyOrderApi.cancel(buyOrder.id)
            getBuyOrders()
        } catch (e: any) {
            setShowAlertMessage({
                text: e.response.data.message,
                color: 'failure',
            })
        }
    }

    useEffect(() => {
        getBuyOrders()
    }, [searchParams])

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.BUY_ORDERS}>
            <div className="mx-auto">
                <AdminBuyOrderTable
                    buyOrdersResponse={searchBuyOrdersResponse}
                    canCancelBuyOrder={canCancelBuyOrder}
                    cancel={cancelBuyOrder}
                    onPageChangeHandler={(pageNumber: number) => {
                        setSearchParams({ page: String(pageNumber) })
                    }}
                    isAdmin={true}
                />
            </div>
        </AdminLayout>
    )
}

export default AdminBuyOrders
