import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import RetirementsTable from '../../modules/retirement/components/RetirementsTable'

const Retirements = (): JSX.Element => {
    return (
        <Layout
            title="Retirements"
            subTitle="Manage all of your retirements in one place"
            dataCy="retirements-layout"
            selectedNavBarItem={NavBarItemName.RETIREMENTS}
        >
            <RetirementsTable />
        </Layout>
    )
}

export default Retirements
