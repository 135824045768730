import React, { MouseEventHandler } from 'react'

interface ButtonProps {
    disabled?: boolean
    icon?: JSX.Element
    className?: string
    children?: React.ReactNode
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined
    type?: 'submit' | 'button'
    title?: string
    id?: string
    clickFirstChild?: boolean
}

export const PrimaryButton = ({
    className = 'w-28',
    disabled,
    children,
    onClick,
    type,
    title,
    ...props
}: ButtonProps): JSX.Element => {
    return (
        <button
            className={`border rounded-lg font-semibold text-nowrap p-2 text-sm 
            ${
                disabled
                    ? 'bg-gray-200 border-gray-200 text-gray-500 '
                    : 'text-white bg-primary-400 border-primary-400 hover:bg-primary-500 cursor-pointer '
            }
            ${className ? className : ''}`}
            title={title}
            disabled={disabled}
            onClick={onClick ? onClick : undefined}
            type={type ? type : 'button'}
            style={{
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            }}
            {...props}
        >
            {children}
        </button>
    )
}

export const SecondaryButton = ({
    className = 'w-28',
    disabled,
    children,
    onClick,
    clickFirstChild,
    type,
    title,
    ...props
}: ButtonProps): JSX.Element => {
    if (clickFirstChild && onClick) {
        throw new Error('You cannot specify both clickElementId && onClick')
    }
    if (clickFirstChild) {
        onClick = (e) => {
            const child = e.currentTarget.children[0] as HTMLElement
            child.click()
        }
    }
    return (
        <button
            className={`border rounded-lg font-semibold text-nowrap p-2 text-sm 
            ${
                disabled
                    ? 'bg-gray-200 border-gray-200 text-gray-500 '
                    : 'hover:bg-gray-100 border-gray-200 bg-white text-gray-600 cursor-pointer '
            }
            ${className ? className : ''}`}
            title={title}
            disabled={disabled}
            onClick={onClick ? onClick : undefined}
            type={type ? type : 'button'}
            style={{
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            }}
            {...props}
        >
            {children}
        </button>
    )
}

export const DangerButton = ({
    className = 'w-28',
    disabled,
    children,
    onClick,
    type,
    title,
    ...props
}: ButtonProps): JSX.Element => {
    return (
        <button
            className={`border rounded-lg font-semibold text-nowrap p-2 text-sm 
            ${
                disabled
                    ? 'bg-gray-200 border-gray-200 text-gray-500 '
                    : 'hover:bg-red-700 border-red-600 bg-red-600 text-white cursor-pointer '
            }
            ${className ? className : ''}`}
            title={title}
            disabled={disabled}
            onClick={onClick ? onClick : undefined}
            type={type ? type : 'button'}
            style={{
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            }}
            {...props}
        >
            {children}
        </button>
    )
}

export const ModerateDangerButton = ({
    className = 'w-28',
    disabled,
    children,
    onClick,
    type,
    title,
    ...props
}: ButtonProps): JSX.Element => {
    return (
        <button
            className={`border rounded-lg font-semibold text-nowrap p-2 text-sm 
            ${
                disabled
                    ? 'bg-gray-200 border-gray-200 text-gray-500 '
                    : 'hover:bg-gray-100 border-error-500 text-error-500 bg-white cursor-pointer '
            }
            ${className ? className : ''}`}
            title={title}
            disabled={disabled}
            onClick={onClick ? onClick : undefined}
            type={type ? type : 'button'}
            style={{
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            }}
            {...props}
        >
            {children}
        </button>
    )
}
