interface CheckBoxWrapperProps {
    onChange: (checked: boolean) => void
    checked?: boolean
    label: string | JSX.Element
    disabled?: boolean
}

const CheckBoxWrapper = ({
    label,
    onChange,
    checked = false,
    disabled = false,
}: CheckBoxWrapperProps): JSX.Element => {
    return (
        <div className="flex gap-1 items-center">
            <input
                disabled={disabled}
                id="default-checkbox"
                type="checkbox"
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
                className="w-5 h-5 rounded-md"
            />
            <label className="text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>
        </div>
    )
}

export default CheckBoxWrapper
