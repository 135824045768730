import { AxiosResponse } from 'axios'
import HttpService from '../../../../modules/http/httpService'
import { Tenant } from '../../../../modules/tenant/types/Tenant'
import { SearchRetirementRequestsParams } from './types/SearchRetirementRequestsParams'
import { SearchRetirementRequestResponses } from './types/SearchRetirementRequestResponses'
import { CompleteRetirementRequestParams } from './types/CompleteRetirementRequestParams'
import { AdminRetirementRequestDto } from './types/AdminRetirementRequestDto'

export interface AdminRetirementRequestsApiInterface {
    search(
        params: SearchRetirementRequestsParams,
    ): Promise<AxiosResponse<SearchRetirementRequestResponses>>
    searchTenantsForRetirementRequests(search: string): Promise<AxiosResponse<Tenant[]>>
    getById(id: string): Promise<AxiosResponse<AdminRetirementRequestDto> | undefined>
    setInProgress(id: string): Promise<AxiosResponse<void> | undefined>
    close(id: string): Promise<AxiosResponse<void> | undefined>
    complete(params: CompleteRetirementRequestParams): Promise<AxiosResponse<void> | undefined>
}

export const AdminRetirementRequestsApi: AdminRetirementRequestsApiInterface = Object.freeze({
    search(
        params: SearchRetirementRequestsParams,
    ): Promise<AxiosResponse<SearchRetirementRequestResponses>> {
        return HttpService.get('/admin/retirement-requests', null, params)
    },
    searchTenantsForRetirementRequests(search: string): Promise<AxiosResponse<Tenant[]>> {
        return HttpService.get('/admin/retirement-requests/tenants', null, { search: search })
    },
    getById(id: string): Promise<AxiosResponse<AdminRetirementRequestDto> | undefined> {
        return HttpService.get('/admin/retirement-requests', id)
    },
    setInProgress(id: string): Promise<AxiosResponse<void> | undefined> {
        return HttpService.post(`/admin/retirement-requests/${id}/set-in-progress`, {})
    },
    close(id: string): Promise<AxiosResponse<void> | undefined> {
        return HttpService.post(`/admin/retirement-requests/${id}/close`, {})
    },
    complete(params: CompleteRetirementRequestParams): Promise<AxiosResponse<void> | undefined> {
        return HttpService.post(`/admin/retirement-requests/complete`, params)
    },
})
