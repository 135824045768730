// valid:
// 0.1 = 1
// 100.65 = 10065
// .1 = 1
// .12 = 12
// 1.12 = 112
// $1.23 = 123

import { isPureNumeric } from './isPureNumeric'

// $1,000.34
export const getCentsNumFromDollarStr = (dollarStr: string): number => {
    // check if it starts with the dollar sign: $
    if (dollarStr.startsWith('$')) {
        dollarStr = dollarStr.substring(1)
    }

    if (dollarStr.length === 0) {
        return 0
    }

    // check for commas and remove
    dollarStr = dollarStr.split(',').join('')

    // check for decimal point - ensure cents part doesn't have more than 2 chars
    const dollarStrParts = dollarStr.split('.')
    if (dollarStrParts.length > 2) {
        throw new Error(`More than one "." found in dollar string. Dollar string: ${dollarStr}`)
    }
    let centsNum = 0
    if (dollarStrParts.length === 2) {
        // there is a cents part
        const centsPart = dollarStrParts[1]
        if (centsPart.length > 2) {
            throw new Error(
                `The cents part of the dollar string is more than 2 chars. Dollar string: ${dollarStr}`,
            )
        }
        // ensure the string only contains numeric values
        if (!isPureNumeric(centsPart)) {
            throw new Error(
                `The cents part of the dollar string contains alpha chars. Dollar string: ${dollarStr}`,
            )
        }
        if (centsPart.length === 1) {
            // this is "tens" of cents so multiply up e.g. $0.8 is actually 80 cents, not 8
            centsNum = Number(centsPart) * 10
        } else {
            centsNum = Number(centsPart)
        }
    }

    const dollarPart = dollarStrParts[0]
    if (!isPureNumeric(dollarPart)) {
        throw new Error(
            `The dollar part of the dollar string contains alpha chars. Dollar string: ${dollarStr}`,
        )
    }
    const dollarNum = Number(dollarPart)

    return dollarNum * 100 + centsNum
}
