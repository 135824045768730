import { AccountType } from '../../../../modules/user/enums/AcountType'

interface AccountTypeOptionProps {
    accountType: AccountType
    setAccountType: (AccountType: AccountType) => void
    isSelected: boolean
    iconName: string
    label: string
}

interface ChooseAccountTypeProps {
    onAccountTypeChange: (accountType: AccountType | 'existing entity') => void
    accountType?: AccountType
    isExistingEntitySelected?: boolean
    isDisabled?: boolean
}

const AccountTypeOption = ({
    setAccountType,
    accountType,
    isSelected,
    iconName,
    label,
}: AccountTypeOptionProps): JSX.Element => {
    if (isSelected) {
        return (
            <div
                onClick={() => setAccountType(accountType)}
                style={{
                    boxShadow:
                        '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                }}
                className="w-full h-full font-semibold text-black flex items-center justify-center gap-2.5 cursor-pointer bg-white rounded-md"
            >
                <p>{label}</p>
                <img src={`/assets/${iconName}_dark.svg`} alt={iconName} />
            </div>
        )
    }
    return (
        <div
            onClick={() => setAccountType(accountType)}
            className="w-full h-full font-semibold text-gray-500 flex items-center justify-center gap-2.5 cursor-pointer"
        >
            <p>{label}</p>
            <img src={`/assets/${iconName}_light.svg`} alt={iconName} />
        </div>
    )
}

const ChooseAccountType = ({
    onAccountTypeChange,
    accountType = AccountType.ENTITY,
    isExistingEntitySelected = true,
    isDisabled = false,
}: ChooseAccountTypeProps): JSX.Element => {
    return (
        <div className="bg-gray-50 h-45 border border-gray-100 rounded-lg p-1 flex gap-1 items-center justify-center">
            <AccountTypeOption
                accountType={AccountType.INDIVIDUAL}
                isSelected={accountType === AccountType.INDIVIDUAL}
                setAccountType={(accountType: AccountType) => {
                    if (!isDisabled) {
                        onAccountTypeChange(accountType)
                    }
                }}
                label="Individual"
                iconName="icon_user"
            />
            <AccountTypeOption
                accountType={AccountType.ENTITY}
                isSelected={accountType === AccountType.ENTITY && !isExistingEntitySelected}
                setAccountType={(accountType: AccountType) => {
                    if (!isDisabled) {
                        onAccountTypeChange(accountType)
                    }
                }}
                label="Entity"
                iconName="icon_home"
            />
            <AccountTypeOption
                accountType={AccountType.ENTITY}
                isSelected={accountType === AccountType.ENTITY && isExistingEntitySelected}
                setAccountType={() => {
                    if (!isDisabled) {
                        onAccountTypeChange('existing entity')
                    }
                }}
                label="Existing Entity"
                iconName="icon_home"
            />
        </div>
    )
}

export default ChooseAccountType
