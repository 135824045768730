import { User } from '../types/User'
import { SET_USER, SetUserAction } from './types'
import { Dispatch } from 'react'

export const setUserAction = (user: User | null, dispatch: Dispatch<SetUserAction>): void => {
    dispatch({
        type: SET_USER,
        payload: { user },
    })
}
