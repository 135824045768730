import AlignMiddle from '../../common/components/AlignMiddle'

interface Props {
    children: React.ReactNode
}

const ModalButtonContainer = ({ children }: Props): JSX.Element => {
    return (
        <AlignMiddle direction="row" childClassName="order-1 flex-1">
            <div className="flex flex-col xs:flex-row gap-2 items-end">{children}</div>
        </AlignMiddle>
    )
}

export default ModalButtonContainer
