import { generatePath, Link } from 'react-router-dom'
import AlignMiddle from '../../common/components/AlignMiddle'
import EllipsisText from '../../common/components/EllipsisText'
import { ProjectType } from '../enums/ProjectType'
import { Page } from '../../common/enums/Pages'
import ProjectSquareImage from './ProjectSquareImage'

export enum ProjectNameDisplay {
    ROW,
    CARD,
}
interface Props {
    squareImageUrl?: string
    type: ProjectType
    name: string
    id: string
    sellOrderId?: string
    display?: ProjectNameDisplay
    vintage?: string | number
}

const imageDisplay = (
    display: ProjectNameDisplay,
    type: ProjectType,
    id: string,
    squareImageUrl?: string,
): JSX.Element => {
    let width = 'w-[32px]'
    let height = 'h-[32px]'
    let rounded = 'rounded-full'

    if (display === ProjectNameDisplay.CARD) {
        width = 'w-full'
        height = 'aspect-square'
        rounded = 'rounded-lg'
    }

    return (
        <ProjectSquareImage
            squareImageUrl={squareImageUrl}
            type={type}
            height={height}
            width={width}
            rounded={rounded}
            id={id}
        />
    )
}

const displayClassName = (display: ProjectNameDisplay): string => {
    switch (display) {
        case ProjectNameDisplay.CARD:
            return 'flex flex-col gap-2 text-sm font-semibold'
        case ProjectNameDisplay.ROW:
            return 'flex flex-row gap-2 text-sm min-w-[180px]'
    }
}

const nameClassName = (display: ProjectNameDisplay): string => {
    switch (display) {
        case ProjectNameDisplay.CARD:
            return 'p-2 lg:h-[65px] sm:h-[90px]'
        case ProjectNameDisplay.ROW:
            return ''
    }
}

const childClassName = (display: ProjectNameDisplay): string => {
    switch (display) {
        case ProjectNameDisplay.CARD:
            return '-order-1'
        case ProjectNameDisplay.ROW:
            return ''
    }
}

const ProjectName = ({
    squareImageUrl,
    type,
    name,
    id,
    sellOrderId,
    display = ProjectNameDisplay.ROW,
    vintage,
}: Props): JSX.Element => {
    const originUrl = window.location.origin

    const url = new URL(
        generatePath(Page.PROJECT, {
            id,
        }),
        originUrl,
    )
    if (sellOrderId) url.searchParams.set('sell_order_id', sellOrderId)
    return (
        <Link
            className="link"
            to={url.href.replace(originUrl, '')}
            state={{ vintage: String(vintage ?? '') }}
        >
            <div className={displayClassName(display)}>
                <AlignMiddle direction="column">
                    {imageDisplay(display, type, id, squareImageUrl)}
                </AlignMiddle>
                <AlignMiddle
                    direction="column"
                    className={nameClassName(display)}
                    childClassName={childClassName(display)}
                >
                    <EllipsisText text={name} maxLength={45} />
                </AlignMiddle>
            </div>
        </Link>
    )
}

export default ProjectName
