import { AxiosError, AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { ErrorResponse } from '../../http/types/ErrorResponse'
import {
    convertUsdToCents,
    convertGramsToMetricTonnes,
    convertMetricTonesToGramsNumeric,
} from '../../../utils/conversion'
import { SecondaryButton, PrimaryButton } from '../../common/components/Button'
import { Vintage } from '../../vintage/types/Vintage'
import { Project } from '../../project/types/Project'
import BaseModal from '../../modal/components/BaseModal'
import { FormElement } from '../../form/types/Field'
import { useForm } from 'react-hook-form'
import { ListItem } from '../../form/types/ListItem'
import { Button } from '../../form/types/Button'
import Form from '../../form/components/Form'
import { SellOrderApi } from '../../sellOrders/http/SellOrderApi'
import { generateUniqueId } from '../../../utils/generateUniqueId'
import CreditsConfirmation from './CreditsConfirmation'
import { ActionName } from '../../action/enums/ActionName'
import { CarbonCreditBalanceSingleResult } from '../../carbonCreditBalance/types/CarbonCreditBalance'
import { generatePath, useNavigate } from 'react-router-dom'
import { Page } from '../../common/enums/Pages'
import { CreateSellOrderResponse } from '../../sellOrders/http/types/CreateSellOrderResponse'
import { getCentsNumFromDollarStr } from '../../../utils/getCentsNumFromDollarStr'

interface EditSellOrderProps {
    show: boolean
    onClose: (reload?: boolean) => void
    vintage: Vintage | null
    project: Project | CarbonCreditBalanceSingleResult
}

type ValidationErrors = {
    quantity?: string[]
    price?: string[]
    unhandled?: string[]
}

type FormData = {
    quantity: number
    price: string
}

const CreateSellOrderModal = ({ show, onClose, vintage, project }: EditSellOrderProps) => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [errors, setErrors] = useState<ValidationErrors | string[] | null>(null)
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
    const [requestId, setRequestId] = useState<string | null>(null)
    const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false)
    const navigate = useNavigate()
    const form = useForm<FormData>({
        defaultValues: {
            quantity: 0,
            price: String(0.0),
        },
    })

    const close = (reload?: boolean) => {
        form.reset()
        setErrors(null)
        setRequestId(null)
        setDisplayConfirmation(false)
        onClose(reload)
    }

    useEffect(() => {
        const isDisabled = form.getValues('price') === String(0) || form.getValues('quantity') === 0
        setIsButtonDisabled(isDisabled)
    }, [form.watch(['price', 'quantity'])])

    useEffect(() => {
        setRequestId(generateUniqueId())
    }, [])

    if (!vintage) return <></>

    const saveChanges = async () => {
        setErrors(null)
        setShowSpinner(true)
        const carbonCreditBalance = vintage.carbon_credit_balances?.[0]
        try {
            let response: AxiosResponse<CreateSellOrderResponse> | undefined
            if (vintage.id) {
                response = await SellOrderApi.create(
                    {
                        carbon_credit_balance_id: carbonCreditBalance?.id!,
                        quantity: String(
                            convertMetricTonesToGramsNumeric(form.getValues('quantity')),
                        ),
                        price: String(convertUsdToCents(Number(form.getValues('price')))),
                    },
                    String(requestId),
                )
            }
            close(true)
            if (!response) return
            navigate(generatePath(Page.ORDERS_SELL_ORDER, { id: response!.data.id }))
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (
                err.response?.status === 400 &&
                err.response.data?.message === 'Sell order already exists'
            ) {
                setErrors(['Sell order already exists'])
            } else {
                setErrors(['An unexpected error has occurred, please try again'])
                throw e
            }
        } finally {
            setShowSpinner(false)
            form.reset()
        }
    }

    const fields: FormElement<FormData>[] = [
        {
            name: 'quantity',
            displayName: 'Quantity',
            type: 'number_slider',
            required: true,
            min: 1,
            max: Math.floor(
                convertGramsToMetricTonnes(vintage.carbon_credit_balances?.[0]?.liquid_amount!),
            ),
            step: 1,
            id: 'sell-quantity',
        },
        {
            name: 'price',
            displayName: 'Price per credit',
            type: 'dollar',
            required: true,
            min: 0,
            max: 9999,
            step: 0.01,
            id: 'sell-price',
        },
    ]

    const listItems: ListItem[] = [
        {
            label: ['Project'],
            value: project.name,
        },
        {
            label: ['Vintage'],
            value: vintage.vintage.toString(),
        },
    ]
    const title: string = 'Create Sell Order'
    const buttons: Button[] = [
        {
            type: 'button',
            className: '',
            onClick: close,
            disabled: showSpinner,
            id: 'close',
            variation: SecondaryButton,
            label: 'Close',
        },
        {
            type: 'submit',
            className: '',
            disabled: isButtonDisabled,
            id: 'create-order',
            variation: PrimaryButton,
            label: 'Sell Credits',
        },
    ]
    return (
        <BaseModal
            show={show}
            id="create-sell-order-modal"
            position="center"
            error={errors}
            showSpinner={showSpinner}
            onClose={close}
        >
            {!displayConfirmation && (
                <Form
                    title={title}
                    onSubmit={() => {
                        setDisplayConfirmation(true)
                    }}
                    formId="create-sell-order-form"
                    fields={fields}
                    headerListItems={listItems}
                    buttons={buttons}
                    form={form}
                />
            )}
            {displayConfirmation && (
                <CreditsConfirmation
                    actionName={ActionName.SELL}
                    showSpinner={showSpinner}
                    price={form.getValues('price')}
                    quantity={convertMetricTonesToGramsNumeric(form.getValues('quantity'))}
                    vintage={vintage}
                    project={project}
                    pricePerCredit={form.getValues('price')}
                    pricePerCreditCents={getCentsNumFromDollarStr(form.getValues('price'))}
                    onClose={() => {
                        close()
                    }}
                    onConfirm={saveChanges}
                    retireeName={undefined}
                    isSubmitButtonEnabled={!isButtonDisabled}
                />
            )}
        </BaseModal>
    )
}

export default CreateSellOrderModal
