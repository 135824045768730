import { Fragment } from 'react'
import { Link, generatePath } from 'react-router-dom'
import { AdminBuyOrderTradeDto } from '../../invoice/http/types/AdminInvoiceDto'
import { TradeHistoryType } from '../../../../modules/tradeHistory/enums/TradeHistoryType'
import { Page } from '../../../../modules/common/enums/Pages'
import {
    ProjectTypeImgType,
    getProjectTypeImgSrc,
} from '../../../../modules/project/getProjectTypeImgSrc'
import EllipsisText from '../../../../modules/common/components/EllipsisText'
import { convertCentsToLocaleUsd, convertGramsToMetricTonnes } from '../../../../utils/conversion'
import RegistryIcon from '../../../../modules/registry/components/RegistryIcon'
import RetirementStatusPill from '../../../../modules/retirement/components/RetirementStatusPill'
import AlignMiddle from '../../../../modules/common/components/AlignMiddle'
import DownloadableImage from '../../../../modules/common/components/DownloadableImage'

interface BuyOrderTradeHistoryTableProps {
    tradeHistory: AdminBuyOrderTradeDto[]
    isAdmin: boolean
}

const type = (tradeType: TradeHistoryType): string => {
    const mapping: Record<TradeHistoryType, string> = {
        [TradeHistoryType.FRACTIONALISED]: 'Fractionalised',
        [TradeHistoryType.HOLD]: 'Buy & Hold',
        [TradeHistoryType.RETIRE]: 'Buy & Retire',
    }

    return mapping[tradeType]
}

const BuyOrderTradeHistoryTable = ({
    tradeHistory = [],
    isAdmin = false,
}: BuyOrderTradeHistoryTableProps): JSX.Element => {
    return (
        <div className="mx-auto my-2 border border-t-0 border-gray-200 rounded-lg shadow-md w-full overflow-x-scroll scrollbar-hide">
            <table className="block md:table mx-auto w-full whitespace-nowrap">
                <thead className="">
                    <tr className="h-11 items-center bg-gray-50">
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            Trade ID
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            Registry
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            Project
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            Vintage
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">Type</th>
                        {isAdmin && (
                            <Fragment>
                                <th className="text-gray-500 font-semibold text-xs text-left px-3">
                                    Net Unit Price
                                </th>
                            </Fragment>
                        )}
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            {isAdmin ? 'Gross ' : ''} Unit Price
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            Quantity
                        </th>
                        {isAdmin && (
                            <Fragment>
                                <th className="text-gray-500 font-semibold text-xs text-left px-3">
                                    Net Total
                                </th>
                                <th className="text-gray-500 font-semibold text-xs text-left px-3">
                                    Platform Fee %
                                </th>
                                <th className="text-gray-500 font-semibold text-xs text-left px-3">
                                    Platform Fee
                                </th>
                            </Fragment>
                        )}
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            {isAdmin ? 'Gross ' : ''} Total
                        </th>
                        {isAdmin && (
                            <Fragment>
                                <th className="text-gray-500 font-semibold text-xs text-left px-3">
                                    Seller Tenant ID
                                </th>
                                <th className="text-gray-500 font-semibold text-xs text-left px-3">
                                    Seller Tenant Name
                                </th>
                            </Fragment>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {tradeHistory.map((trade) => (
                        <Fragment key={trade.id}>
                            <tr className="h-18 items-center">
                                <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                    {trade.id}
                                </td>
                                <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                    <div className="flex items-center gap-2.5 min-w-[150px]">
                                        <RegistryIcon
                                            registryId={trade.project_data.registry_id}
                                            registryLink={trade.project_data.registry_url}
                                        />
                                    </div>
                                </td>
                                <td className="text-gray-500 text-sm text-left px-3 font-normal max-w-[286px] min-w-[240px] break-normal">
                                    <Link
                                        className="w-full flex gap-3 items-center"
                                        to={generatePath(Page.ORDERS_SELL_ORDER, {
                                            id: trade.sell_order_id,
                                        })}
                                    >
                                        <img
                                            src={
                                                trade.project_data.square_image_url
                                                    ? trade.project_data.square_image_url
                                                    : getProjectTypeImgSrc(
                                                          trade.project_data.type,
                                                          ProjectTypeImgType.SQUARE,
                                                      )
                                            }
                                            alt="icon_project_avatar"
                                            title="Project Profile"
                                            className="h-[32px] w-[32px] rounded-full"
                                        />
                                        <p
                                            className="whitespace-normal"
                                            title={trade.project_data.name}
                                        >
                                            <EllipsisText
                                                text={trade.project_data.name}
                                                maxLength={40}
                                            />
                                        </p>
                                    </Link>
                                </td>
                                <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                    {trade.vintage}
                                </td>
                                <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                    {type(trade.type)}
                                </td>
                                {isAdmin && (
                                    <Fragment>
                                        <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                            {convertCentsToLocaleUsd(trade.net_unit_price!)}
                                        </td>
                                    </Fragment>
                                )}
                                <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                    {convertCentsToLocaleUsd(trade.gross_unit_price)}
                                </td>
                                <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                    {convertGramsToMetricTonnes(trade.quantity)}
                                </td>
                                {isAdmin && (
                                    <Fragment>
                                        <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                            {convertCentsToLocaleUsd(trade.net_total_cost!)}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                            {(+trade.platform_fee_percentage / 100).toFixed(2)} %
                                        </td>
                                        <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                            {convertCentsToLocaleUsd(trade.platform_fee_amount)}
                                        </td>
                                    </Fragment>
                                )}
                                <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                    {convertCentsToLocaleUsd(trade.gross_total_cost)}
                                </td>
                                {isAdmin && (
                                    <Fragment>
                                        <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                            {trade.seller_tenant_id}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                            {trade.seller_tenant_name}
                                        </td>
                                    </Fragment>
                                )}
                            </tr>
                            {trade.retirement && (
                                <tr className="">
                                    <th
                                        scope="row"
                                        className="text-gray-500 font-semibold text-xs text-left p-3"
                                    >
                                        Retirement Requests
                                    </th>
                                    <td
                                        colSpan={isAdmin ? 13 : 7}
                                        className="text-gray-500 font-semibold text-xs text-left"
                                    >
                                        {/* {trade.retirement_requests.map((retirement) => {
                                            return ( */}
                                        <Fragment key={trade.retirement.id}>
                                            <div className="flex gap-2 pb-2">
                                                <div className="rounded-md border-gray-500 border-[1px] p-2 flex flex-row">
                                                    <div className="flex flex-col text-gray-500 font-semibold text-xs text-left">
                                                        <div className="flex flex-col flex-1">
                                                            <span className="flex-1"></span>
                                                            <span>Id</span>
                                                            <span className="flex-1"></span>
                                                        </div>
                                                        <div className="flex flex-col flex-1">
                                                            <span className="flex-1"></span>
                                                            <span>Status</span>
                                                            <span className="flex-1"></span>
                                                        </div>
                                                        {trade.retirement
                                                            .proof_of_retirement_nft_url && (
                                                            <div className="flex flex-col flex-1">
                                                                <span className="flex-1"></span>
                                                                <span>Certificate</span>
                                                                <span className="flex-1"></span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="flex flex-col text-gray-500 text-sm text-left px-3 font-normal gap-1">
                                                        <div>{trade.retirement.id}</div>
                                                        <div className="">
                                                            <RetirementStatusPill
                                                                status={trade.retirement.status}
                                                            />
                                                        </div>
                                                        {trade.retirement
                                                            .proof_of_retirement_nft_url &&
                                                            trade.retirement.retired_at && (
                                                                <AlignMiddle
                                                                    direction="column"
                                                                    onClick={() => {}}
                                                                >
                                                                    <DownloadableImage
                                                                        url={
                                                                            trade.retirement
                                                                                .proof_of_retirement_nft_url
                                                                        }
                                                                        heightClassName="h-[351px]"
                                                                        widthClassName="w-[544px]"
                                                                        overlayClassNames="rounded-3xl"
                                                                    />
                                                                </AlignMiddle>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                        {/* ) */}
                                        {/* })} */}
                                    </td>
                                </tr>
                            )}
                        </Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default BuyOrderTradeHistoryTable
