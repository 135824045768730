import React from 'react'

interface Props {
    title: string
    children: React.ReactNode
}

const SubSection = ({ title, children }: Props): JSX.Element => {
    return (
        <div className="mt-6">
            <h3 className="text-gray-900 font-semibold text-xl mb-2">{title}</h3>
            {children}
        </div>
    )
}

export default SubSection
