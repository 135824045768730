import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useUserStore } from '../../../user/context/store'
import { RoleName } from '../../../user/enums/RoleName'
import { Role } from '../../../user/types/Role'
import { Page } from '../../../common/enums/Pages'

interface RouterGuardProps {
    children: JSX.Element | JSX.Element[]
    isAuthenticatedUserArea?: boolean
    userRoles?: Role[]
    pageRoles?: RoleName[]
}

const RouterGuard = ({
    children,
    isAuthenticatedUserArea,
    userRoles,
    pageRoles,
}: RouterGuardProps): JSX.Element => {
    const { user, checkUserHasAtLeastOneRole } = useUserStore()
    const location = useLocation()
    if (isAuthenticatedUserArea && !user) {
        return <Navigate to={Page.SIGNIN} />
    }
    // prevent the authenticated users from viewing /signin and /signup pages and redirect them to the home page
    if (user && [Page.SIGNIN as string, Page.SIGNUP as string].includes(location.pathname)) {
        return <Navigate to={Page.MARKET} />
    }

    if (pageRoles) {
        if (checkUserHasAtLeastOneRole(pageRoles)) {
            return <React.Fragment>{children}</React.Fragment>
        } else {
            const isUserAdmin = userRoles?.some((role: Role) => role.name === RoleName.ADMIN)
            if (isUserAdmin) {
                return <Navigate to={Page.ADMIN_USERS} />
            }
            return <Navigate to={Page.MARKET} />
        }
    }

    return <React.Fragment>{children}</React.Fragment>
}

export default RouterGuard
