import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { UnbridgeRequestApi } from '../../unbridge/http/api'
import { Page } from '../../../modules/common/enums/Pages'
import { convertGramsToMetricTonesLocaleString } from '../../../utils/conversion'
import AdminLayout from '../../modules/common/components/AdminLayout'
import FieldErrorLabel from '../../modules/common/components/FieldErrorLabel'
import { CompleteUnbridgeRequestPayload } from '../../unbridge/http/types/CompleteUnbridgeRequestPayload'
import { UnbridgeRequest } from '../../unbridge/http/types/UnbridgeRequest'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'

export interface CompleteUnbridgeRequestForm {
    unbridge_request_id: string
    user_name: string
    tenant_name: string
    project_name: string
    quantity: string
    vintage_year: number
    on_chain_request_id: string
    unbridged_at: string
}

const AdminCompleteUnbridgeRequest = (): JSX.Element => {
    const { id } = useParams<{ id: string }>()
    const [request, setRequest] = useState<UnbridgeRequest | null>(null)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<CompleteUnbridgeRequestForm>()

    const navigate = useNavigate()

    const fetchUnbridgeRequest = async (id: string): Promise<void> => {
        const res = await UnbridgeRequestApi.getPendingRequest(id)
        setRequest(res.data)
    }

    const onSubmit = async (data: any): Promise<void> => {
        if (!request) {
            return
        }

        const payload: CompleteUnbridgeRequestPayload = {
            unbridge_request_id: request.unbridge_request_id,
            on_chain_request_id: data.on_chain_request_id,
            unbridged_at: data.unbridged_at,
        }
        await UnbridgeRequestApi.complete(payload)
        navigate(Page.ADMIN_UNBRIDGE_REQUESTS)
    }

    useEffect(() => {
        if (id) {
            void fetchUnbridgeRequest(id)
        }
    }, [])

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.UNBRIDGE_REQUESTS}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Unbridge Request Id
                    </label>
                    <input
                        type="text"
                        readOnly={true}
                        value={request?.unbridge_request_id || 'Unbridge Request Id Not Found'}
                        {...register('unbridge_request_id', {
                            required: true,
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                </div>
                <div className="h-5">
                    {errors?.unbridge_request_id?.type === 'required' && (
                        <FieldErrorLabel error="Unbridge request id is required" />
                    )}
                </div>
                <div className="mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        User Name
                    </label>
                    <input
                        type="text"
                        readOnly={true}
                        value={request?.user_name || 'User Name Not Found'}
                        {...register('user_name', {
                            required: true,
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                </div>
                <div className="mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Tenant Name
                    </label>
                    <input
                        type="text"
                        readOnly={true}
                        value={request?.tenant_name || 'Tenant Name Not Found'}
                        {...register('tenant_name', {
                            required: true,
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                </div>
                <div className="mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Project Name
                    </label>
                    <input
                        type="text"
                        readOnly={true}
                        value={request?.project_name || 'Project Name Not Found'}
                        {...register('project_name', {
                            required: true,
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                </div>
                <div className="mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Quantity
                    </label>
                    <input
                        type="text"
                        readOnly={true}
                        value={convertGramsToMetricTonesLocaleString(
                            Number(request?.quantity ?? '0'),
                        )}
                        {...register('quantity', {
                            required: true,
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                </div>
                <div className="mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Vintage Year
                    </label>
                    <input
                        type="text"
                        readOnly={true}
                        value={request?.vintage_year || 'Vintage Not Found'}
                        {...register('vintage_year', {
                            required: true,
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                </div>
                <div className="mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        On Chain Request Id
                    </label>
                    <input
                        type="text"
                        placeholder={'Enter On Chain Request Id'}
                        {...register('on_chain_request_id', {
                            required: { value: true, message: 'On chain request id is required' },
                            pattern: {
                                value: /^\d+$/,
                                message: 'Not a valid number format',
                            },
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    <div className="h-5">
                        <FieldErrorLabel error={errors.on_chain_request_id?.message ?? ''} />
                    </div>
                </div>
                <div className="mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Time Unbridged At On Bridge (UTC)
                    </label>
                    <input
                        type="text"
                        placeholder={'Enter The Time Unbridged At On Bridge'}
                        {...register('unbridged_at', {
                            required: {
                                value: true,
                                message: 'Time unbridged_at is required',
                            },
                            validate: {
                                isDateTimeString: (value) =>
                                    !isNaN(Date.parse(value)) ||
                                    'Value must be date time string, example 2023-05-01T00:00:00.000Z',
                            },
                            pattern: {
                                value: /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/,
                                message:
                                    'Value must be date time string, example 2023-05-01T00:00:00.000Z',
                            },
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    <div className="h-5">
                        <FieldErrorLabel error={errors.unbridged_at?.message ?? ''} />
                    </div>
                </div>
                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button
                        data-modal-toggle="defaultModal"
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        Complete Unbridging
                    </button>
                </div>
            </form>
        </AdminLayout>
    )
}

export default AdminCompleteUnbridgeRequest
