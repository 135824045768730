import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    ChartData,
    ChartOptions,
    TooltipItem,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { Radar } from 'react-chartjs-2'
import { convertGramsToMetricTonnes, bigIntToLocale } from '../../../utils/conversion'

interface CreditMethodologyProps {
    labels: string[]
    data: string[]
}

const CreditMethodology = ({ labels, data }: CreditMethodologyProps): JSX.Element => {
    ChartJS.register(PointElement, LineElement, Filler, RadialLinearScale)
    const [logData, setLogData] = useState<number[] | null>(null)
    const [datasets, setDataSets] = useState<ChartData<'radar', number[]> | null>(null)

    useEffect(() => {
        // We have figured out that if a RadarChart has less than 5 elements it looks ugly so here we are ensuring that has at least 5
        const labelsArrayWithAtLeastFiveElements: string[] | string[][] = labels
        const dataArrayWithAtLeastFiveElements: string[] = data

        const lengthOfLabels = labelsArrayWithAtLeastFiveElements.length
        const lengthOfData = dataArrayWithAtLeastFiveElements.length

        if (lengthOfLabels < 5) {
            labelsArrayWithAtLeastFiveElements.push(...new Array(5 - lengthOfLabels).fill(''))
        }

        if (lengthOfData < 5) {
            dataArrayWithAtLeastFiveElements.push(...new Array(5 - lengthOfData).fill(0))
        }
        const values = dataArrayWithAtLeastFiveElements.map((value: string) => {
            return Math.log(convertGramsToMetricTonnes(Number(value)))
        })
        setLogData(values)
        const sets: ChartData<'radar', number[]> = {
            labels: labelsArrayWithAtLeastFiveElements,
            datasets: [
                {
                    data: values,
                    backgroundColor: 'rgba(228,	99,	19, 0.5)',
                    borderColor: '#BE5D29',
                    borderWidth: 2,
                    // color: 'red',
                },
            ],
        }
        setDataSets(sets)
    }, [data])

    const options: ChartOptions<'radar'> = {
        scales: {
            r: {
                grid: {
                    color: '#F2F4F7',
                },
                pointLabels: {
                    color: '#667085',
                    callback: (label: string) =>
                        label
                            .replace(/&\s/, '&_')
                            .split(/\s/)
                            .map((portion: string) => portion.replace(/&_/, '& ')),
                },
                ticks: {
                    display: false,
                },
                min: 0,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context: TooltipItem<'radar'>) => {
                        const index = context.dataIndex
                        const originalValue = data[index]
                        const value = convertGramsToMetricTonnes(Number(originalValue))
                        return `${bigIntToLocale(value)} (tCO2e)`
                    },
                },
            },
            legend: {
                display: false,
                position: 'top',
                labels: {
                    boxWidth: 0,
                },
            },
        },
    }

    return (
        <div
            className="w-full flex flex-col justify-between rounded-lg border border-gray-200 h-full"
            style={{
                boxShadow:
                    '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}
        >
            <div>
                <div className="flex flex-row items-center justify-between px-6 pt-6">
                    <p className="text-lg font-semibold text-gray-900">Credit Methodology</p>
                    <p className="hidden text-xs leading-[18px] font-semibold text-gray-400">
                        This Year
                    </p>
                </div>
                <p className="text-gray-500 px-6 text-base font-normal">Project Type</p>
                <div className="w-full h-full mt-4">
                    {logData && datasets && <Radar data={datasets} options={options} />}
                    {!logData && (
                        <div className="flex justify-center items-end h-full">
                            <p className="text-base italic font-semibold text-gray-400">No data</p>
                        </div>
                    )}
                </div>
            </div>
            <div className="hidden flex-row items-center justify-end p-4 bg-gray-100 bottom-0 w-full">
                <button
                    className="text-sm font-semibold border border-gray-300 px-4 py-2.5 rounded-lg"
                    style={{
                        boxShadow:
                            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
                    }}
                >
                    More Details
                </button>
            </div>
        </div>
    )
}

export default CreditMethodology
