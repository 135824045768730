import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { AdminProjectApi } from '../http/api'
import CheckBoxWrapper from '../../../../modules/common/components/CheckBoxWrapper'
import SdgLogo from '../../../../modules/project/components/SdgLogo'
import { ProjectLocation } from '../../../../modules/project/enums/ProjectLocation'
import { ProjectType } from '../../../../modules/project/enums/ProjectType'
import { SDG } from '../../../../modules/project/enums/SDG'
import { useRegistryStore } from '../../../../modules/registry/context/store'
import { Registry } from '../../../../modules/registry/types/Registry'
import { getCountryNameFromCountryCode } from '../../../../utils/getCountryNameFromCountryCode'
import FieldErrorLabel from '../../common/components/FieldErrorLabel'

export interface CreateProjectFields {
    name: string
    description: string
    type: ProjectType
    location: ProjectLocation
    registry_id: string
    registry_url: string
    registry_project_id: string
    sdgs: []
    bridge_project_id: string
}

export interface CreateProjectFormInterface {
    onProjectSaved: () => void
}

const CreateProjectForm = ({ onProjectSaved }: CreateProjectFormInterface): JSX.Element => {
    const [selectedSDGs, setSelectedSDGs] = useState<SDG[]>([])
    const { registries } = useRegistryStore()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<CreateProjectFields>()

    const onSubmit = async (data: any): Promise<void> => {
        await AdminProjectApi.create({
            ...data,
            sdgs: selectedSDGs,
        })
        onProjectSaved()
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6 text-left">
                <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Name
                </label>
                <input
                    type="text"
                    placeholder="Name"
                    {...register('name', {
                        required: true,
                    })}
                    className="block p-4 mb-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                <div className="h-5">
                    {errors?.name?.type === 'required' && (
                        <FieldErrorLabel error="Name is required" />
                    )}
                </div>
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/3 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Project type
                    </label>
                    <select
                        id="small"
                        className="block p-2 mb-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        {...register('type', {
                            required: true,
                            validate: (type: ProjectType | 'Choose') => {
                                return type !== 'Choose'
                            },
                        })}
                    >
                        <option>Choose</option>
                        {(Object.keys(ProjectType) as Array<keyof typeof ProjectType>).map(
                            (key) => (
                                <option key={key} value={ProjectType[key]}>
                                    {ProjectType[key]}
                                </option>
                            ),
                        )}
                    </select>
                    <div className="h-5">
                        {errors?.type?.type === 'validate' && (
                            <FieldErrorLabel error="Type is required" />
                        )}
                    </div>
                </div>
                <div className="w-1/3 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Registry
                    </label>
                    <select
                        id="small"
                        className="block p-2 mb-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        {...register('registry_id', {
                            required: true,
                            validate: (type: string) => {
                                return type !== 'Choose'
                            },
                        })}
                    >
                        <option>Choose</option>
                        {registries.map((registry: Registry) => (
                            <option key={registry.id} value={registry.id}>
                                {registry.name}
                            </option>
                        ))}
                    </select>
                    <div className="h-5">
                        {errors?.registry_id?.type === 'validate' && (
                            <FieldErrorLabel error="Registry is required" />
                        )}
                    </div>
                </div>
                <div className="w-1/3 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Location
                    </label>
                    <select
                        id="small"
                        className="block p-2 mb-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        {...register('location', {
                            required: true,
                            validate: (type: ProjectLocation | 'Choose') => {
                                return type !== 'Choose'
                            },
                        })}
                    >
                        <option>Choose</option>
                        {(Object.keys(ProjectLocation) as Array<keyof typeof ProjectLocation>).map(
                            (key) => (
                                <option key={key} value={ProjectLocation[key]}>
                                    {getCountryNameFromCountryCode(ProjectLocation[key])}
                                </option>
                            ),
                        )}
                    </select>
                    <div className="h-5">
                        {errors?.location?.type === 'validate' && (
                            <FieldErrorLabel error="Location is required" />
                        )}
                    </div>
                </div>
            </div>
            <div className="mb-6 text-left">
                <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Description
                </label>
                <textarea
                    rows={5}
                    id="large-input"
                    placeholder="Desription"
                    className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('description', {
                        required: false,
                    })}
                />
            </div>
            <div className="flex gap-4">
                <div className="mb-6 text-left w-1/2">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Registry url
                    </label>
                    <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Registry url"
                        {...register('registry_url', {
                            required: false,
                        })}
                    />
                </div>
                <div className="mb-6 text-left w-1/2">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Registry project id
                    </label>
                    <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Registry Project id"
                        {...register('registry_project_id', {
                            required: false,
                        })}
                    />
                </div>
            </div>
            <div className="mb-6 text-left">
                <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    SDGs
                </label>
                <div>
                    <div className="flex flex-wrap mt-4">
                        {(Object.keys(SDG) as Array<keyof typeof SDG>).map((key) => {
                            return (
                                <div className="w-1/4" key={key}>
                                    <CheckBoxWrapper
                                        key={key}
                                        checked={selectedSDGs.includes(SDG[key])}
                                        label={
                                            <div className="flex">
                                                <SdgLogo sdg={SDG[key]} />
                                                {SDG[key]}
                                            </div>
                                        }
                                        onChange={(checked: boolean) => {
                                            if (checked) {
                                                setSelectedSDGs([...selectedSDGs, SDG[key]])
                                            } else {
                                                setSelectedSDGs(
                                                    selectedSDGs.filter((sdg) => sdg !== SDG[key]),
                                                )
                                            }
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="flex gap-4">
                <div className="mb-6 text-left w-1/2">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Bridge project id
                    </label>
                    <input
                        type="text"
                        className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Bridge project id"
                        {...register('bridge_project_id', {
                            required: true,
                        })}
                    />
                    <div className="h-5">
                        {errors?.bridge_project_id?.type === 'required' && (
                            <FieldErrorLabel error="Bridge project id is required" />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                    data-modal-toggle="defaultModal"
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    Save
                </button>
            </div>
        </form>
    )
}

export default CreateProjectForm
