import { AxiosResponse } from 'axios'
import { Registry } from '../types/Registry'
import HttpService from '../../http/httpService'

export interface RegistryInterface {
    getAll(): Promise<AxiosResponse<Registry[]>>
}

export const RegistryApi: RegistryInterface = Object.freeze({
    getAll(): Promise<AxiosResponse<Registry[]>> {
        return HttpService.get('/admin/registries')
    },
})
