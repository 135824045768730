import { useEffect, useState } from 'react'
import Layout from '../../modules/common/components/Layout'
import SubSection from '../../modules/common/components/SubSection'
import ToggleSwitch from '../../modules/common/components/ToggleSwitch'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import { useUserStore } from '../../modules/user/context/store'
import AccountSettingsSubMenu from './components/AccountSettingsSubMenu'
import { AccountSettingsSubMenuActions } from './enums/AccountSettingsSubMenuActions'
import Note, { NoteType } from '../../modules/note/components/Note'
import DisableMfaModal from './components/DisableMfaModal'
import { UserApi } from '../../modules/user/http/UserApi'
import { useToastMessageStore } from '../../modules/toastMessage/context/store'
import { ToastMessageType } from '../../modules/toastMessage/enums/ToastMessageType'
import Form from '../../modules/form/components/Form'
import { FormElement } from '../../modules/form/types/Field'
import { useForm } from 'react-hook-form'
import { Button } from '../../modules/form/types/Button'
import { PrimaryButton } from '../../modules/common/components/Button'
import { AxiosError } from 'axios'
import * as Sentry from '@sentry/react'
import { ErrorResponse } from '../../modules/http/types/ErrorResponse'

const AccountSecurity = (): JSX.Element => {
    const [mfaToggleValue, setMfaToggleValue] = useState<boolean>(false)
    const [mfaToggleEnabled, setMfaToggleEnabled] = useState<boolean>(true)
    const [showDisableMfaModal, setShowDisableMfaModal] = useState<boolean>(false)
    const form = useForm<FormData>()
    const { user, fetchUser } = useUserStore()
    const { setShowToastMessage } = useToastMessageStore()

    const enableMfa = async (): Promise<void> => {
        try {
            setMfaToggleEnabled(false)
            await UserApi.setMfaEnabled({
                is_mfa_enabled: true,
            })
            await fetchUser()
            setMfaToggleEnabled(true)
            setShowToastMessage({
                text: 'MFA Enabled',
                type: ToastMessageType.SUCCESS,
            })
        } catch (e) {
            setMfaToggleEnabled(true)
            setShowToastMessage({
                text: 'There was a problem enabling MFA. Please refresh the page and try again.',
                type: ToastMessageType.ERROR,
            })
            throw e
        }
    }

    const submitChangePassword = async (
        currentPassword: string,
        newPassword: string,
    ): Promise<void> => {
        try {
            await UserApi.changePassword({
                password: newPassword,
                old_password: currentPassword,
            })
            setShowToastMessage({
                text: 'Password successfully changed',
                type: ToastMessageType.SUCCESS,
            })
            form.reset()
        } catch (e: unknown) {
            const error = e as AxiosError<ErrorResponse>
            if (error.response?.status === 400 || error.response?.status === 422) {
                const message = Array.isArray(error.response?.data?.message)
                    ? error.response?.data?.message[1] ??
                      'An unexpected error has occurred, please try again'
                    : error.response?.data?.message
                setShowToastMessage({
                    text: message,
                    type: ToastMessageType.ERROR,
                })
            } else {
                setShowToastMessage({
                    text: 'An unexpected error has occurred, please try again',
                    type: ToastMessageType.ERROR,
                })
                Sentry.captureException(e)
            }
        }
    }

    useEffect(() => {
        setMfaToggleValue(user?.is_mfa_enabled ?? false)
    }, [user])

    type FormData = {
        currentPwd: string
        newPwd: string
        confirmPwd: string
    }

    const fields: FormElement<FormData>[] = [
        {
            name: 'currentPwd',
            displayName: 'Current password',
            type: 'password',
            id: 'current-password',
            options: {
                required: {
                    message: 'Current password cannot be blank',
                    value: true,
                },
            },
        },
        {
            name: 'newPwd',
            displayName: 'New password',
            type: 'password',
            id: 'new-password',
            options: {
                required: {
                    message: 'New password cannot be blank',
                    value: true,
                },
                minLength: {
                    message: 'New password must be at least 8 characters long',
                    value: 8,
                },
            },
        },
        {
            name: 'confirmPwd',
            displayName: 'Confirm password',
            type: 'password',
            id: 'confirm-new-password',
            options: {
                required: {
                    message: 'Confirm password cannot be blank',
                    value: true,
                },
                validate: () => {
                    if (form.getValues('newPwd') !== form.getValues('confirmPwd')) {
                        return 'New passwords do not match'
                    }
                },
            },
        },
    ]

    const buttons: Button[] = [
        {
            type: 'submit',
            id: 'save-change-password',
            variation: PrimaryButton,
            label: 'Save',
        },
    ]

    return (
        <Layout title="Account Settings" subTitle="" selectedNavBarItem={NavBarItemName.NONE}>
            <AccountSettingsSubMenu selectedItem={AccountSettingsSubMenuActions.SECURITY} />
            <SubSection title="Change Password">
                <Form
                    onSubmit={() => {
                        submitChangePassword(form.getValues('currentPwd'), form.getValues('newPwd'))
                    }}
                    formId="create-sell-order-form"
                    fields={fields}
                    buttons={buttons}
                    form={form}
                    wrapperClassName=""
                    formClassName="max-w-[300px]"
                    outerWrapperClassName="mb-6"
                />
            </SubSection>
            <hr />
            <SubSection title="Multi-Factor Authentication">
                {mfaToggleValue && (
                    <Note type={NoteType.INFO} containerClassNames="mb-4">
                        Multi-Factor Authentication makes your account more secure. We will email
                        you an authentication code when you login to help protect your account.
                    </Note>
                )}
                {!mfaToggleValue && (
                    <Note type={NoteType.WARNING} containerClassNames="mb-4">
                        Multi-Factor Authentication makes your account more secure. We recommend you
                        enable MFA.
                    </Note>
                )}
                <ToggleSwitch
                    label="Enable MFA"
                    checked={mfaToggleValue}
                    onChange={(checked: boolean) => {
                        if (!checked) {
                            setShowDisableMfaModal(true)
                        } else {
                            enableMfa()
                        }
                    }}
                    disabled={!mfaToggleEnabled}
                />
                <DisableMfaModal
                    show={showDisableMfaModal}
                    onClose={(success) => {
                        setShowDisableMfaModal(false)
                        if (success) {
                            fetchUser()
                        }
                    }}
                />
            </SubSection>
        </Layout>
    )
}

export default AccountSecurity
