import { generatePath, useNavigate } from 'react-router-dom'
import { SearchProjectsResponse } from '../http/types/SearchProjectsResponse'
import { Project } from '../../../../modules/project/types/Project'
import { Pagination } from 'flowbite-react'
import { Page } from '../../../../modules/common/enums/Pages'
import SdgLogo from '../../../../modules/project/components/SdgLogo'
import { Vintage } from '../../../../modules/vintage/types/Vintage'
import { generatePathWithQueryString } from '../../user/http/utils/generatePathWithQueryString'

interface ProjectsTableInterface {
    searchProjectsResponse: SearchProjectsResponse | null
    onPageChangeHandler: (pageNumber: number) => void
}

const ProjectsTable = ({
    searchProjectsResponse,
    onPageChangeHandler,
}: ProjectsTableInterface): JSX.Element => {
    const navigate = useNavigate()
    return (
        <div className="h-[95%]">
            <div className="h-[95%] overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                NAME
                            </th>
                            <th scope="col" className="py-3 px-6">
                                TYPE
                            </th>
                            <th scope="col" className="py-3 px-6">
                                SDGs
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Location
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Registry
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Bridge Project ID
                            </th>
                            <th scope="col" className="py-3 px-6 min-w-48">
                                Vintages
                            </th>
                            <th scope="col" className="py-3 px-6 min-w-48">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody className="h-[40vh] overflow-y-auto">
                        {searchProjectsResponse?.projects.map((project: Project) => {
                            return (
                                <tr key={project.id} className="bg-white border-b hover:bg-gray-50">
                                    <th scope="row" className="py-4 px-6 font-medium text-gray-900">
                                        {project.name}
                                    </th>
                                    <td className="py-4 px-6">{project.type}</td>
                                    <td className="py-4 px-6">
                                        <div className="flex">
                                            {project.sdgs.map((sdg) => {
                                                return <SdgLogo key={sdg} sdg={sdg} />
                                            })}
                                        </div>
                                    </td>
                                    <td className="py-4 px-6">{project.location}</td>
                                    <td className="py-4 px-6">{project?.registry?.name}</td>
                                    <td className="py-4 px-6">{project?.bridge_project_id}</td>
                                    <td className="py-4 px-6">
                                        <div className="flex flex-col">
                                            <div className="flex flex-wrap gap-2">
                                                {project?.vintages.map((vintage: Vintage) => {
                                                    return (
                                                        <span
                                                            key={vintage.id}
                                                            className="bg-gray-200 h-9 w-9 flex justify-center items-center rounded-full"
                                                        >
                                                            {vintage.vintage}
                                                        </span>
                                                    )
                                                })}
                                                <button
                                                    onClick={() =>
                                                        navigate(
                                                            generatePath(
                                                                Page.ADMIN_CREATE_VINTAGE,
                                                                {
                                                                    id: project.id,
                                                                },
                                                            ),
                                                            {
                                                                state: {
                                                                    a: 1,
                                                                },
                                                            },
                                                        )
                                                    }
                                                    title="Create Vintage"
                                                    className="bg-gray-200 h-9 w-9 flex justify-center items-center rounded-full"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="py-4 px-6">
                                        <button
                                            title="Edit Project"
                                            className="flex items-center justify-between border border-gray-200 rounded-lg font-semibold bg-white text-gray-600 hover:bg-gray-100 cursor-pointer mt-1 px-2.5 py-1"
                                            onClick={() =>
                                                navigate(
                                                    generatePathWithQueryString(
                                                        Page.ADMIN_EDIT_PROJECT,
                                                        {
                                                            id: project.id,
                                                        },
                                                    ),
                                                )
                                            }
                                        >
                                            <img
                                                src="/assets/icon_pencil_alt.svg"
                                                width={20}
                                                height={20}
                                                alt="icon_trash"
                                            />
                                            <span>Edit</span>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-center mt-4">
                {searchProjectsResponse?.pagination &&
                    searchProjectsResponse?.pagination.total_items >
                        searchProjectsResponse?.pagination.per_page && (
                        <Pagination
                            currentPage={searchProjectsResponse.pagination.current_page}
                            totalPages={searchProjectsResponse.pagination.total_pages}
                            onPageChange={onPageChangeHandler}
                        />
                    )}
            </div>
        </div>
    )
}

export default ProjectsTable
