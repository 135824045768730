import { useCookiesManagementStore } from '../context/store'

const AcceptCookies = (): JSX.Element | null => {
    const { state, acceptCookies, declineCookies } = useCookiesManagementStore()
    if (!state.isAcceptCookiesBannerVisible) return null
    return (
        <div className="flex fixed top-0 left-0 h-full w-full bg-[rgba(0,0,0,0.5)] z-10">
            <div className="flex flex-1 items-end">
                <div className="bg-[rgba(0,0,0,0.4)] h-full sm:h-[300px] pt-28 sm:pt-0 w-full">
                    <h1 className="text-white px-20 pt-10">Accept Cookies</h1>
                    <p className="text-white px-20 mt-4">
                        At Thallo we use cookies to help ensure that our website and services are
                        able to function properly. These cookies are necessary and so are set
                        automatically.
                    </p>
                    <div className="mt-20 flex gap-4 justify-center">
                        <button
                            className="bg-primary-600 p-3.5 rounded-lg text-white"
                            onClick={acceptCookies}
                            data-testid="accept-cookies"
                        >
                            Accept All Cookies
                        </button>
                        <button
                            className="bg-[rgba(0,0,0,0)] border border-white p-3.5 rounded-lg text-white"
                            onClick={declineCookies}
                        >
                            Decline All Cookies
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AcceptCookies
