import { IConfig } from './IConfig'

export class ConfigFactory {
    private static config?: IConfig

    public static getConfig(): IConfig {
        if (ConfigFactory.config === undefined) {
            ConfigFactory.config = {
                serverUrl: process.env.REACT_APP_SERVER_URL!!,
                googleSiteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY!!,
                sentryEnabled: this.getBooleanEnvVar('REACT_APP_SENTRY_ENABLED'),
                sentryUrl: process.env.REACT_APP_SENTRY_URL!!,
                sentryTracingSampleRate: Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE!!),
                thalloEnv: process.env.REACT_APP_THALLO_ENV!!,
                secureCookies: this.getBooleanEnvVar('REACT_APP_SECURE_COOKIES'),
            }
        }

        return ConfigFactory.config
    }

    private static getBooleanEnvVar(varName: string): boolean {
        if (process.env[varName] === undefined) {
            throw new Error(`Missing env var ${varName}. Must be "true" or "false"`)
        }

        if (process.env[varName] === 'true') {
            return true
        } else if (process.env[varName] === 'false') {
            return false
        } else {
            throw new Error(
                `Invalid value for env var ${varName} value ${process.env[varName]}. Must be "true" or "false"`,
            )
        }
    }
}
