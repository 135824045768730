import { useEffect, useState } from 'react'
import Table, { Column, TextAlignment, Values } from '../../table/components/Table'
import DateTime from '../../dateTime/components/DateTimeFormatted'
import ProjectName from '../../project/components/ProjectName'
import { convertGramsToMetricTonesLocaleString } from '../../../utils/conversion'
import { Project } from '../../project/types/Project'
import LoadingSpinner from '../../loadingSpinner/components/LoadingSpinner'
import Note, { NoteType } from '../../note/components/Note'
import * as Sentry from '@sentry/react'
import { RetirementRequestsApi } from '../http/api'
import { RetirementStatuses } from '../enums/RetirementStatuses'
import RetirementStatusPill from './RetirementStatusPill'
import RegistryIcon from '../../registry/components/RegistryIcon'
import { useUserStore } from '../../user/context/store'
import { SearchRetirementRequestsParams } from '../http/types/SearchRetirementRequestsParams'
import { TradeHistoryType } from '../../tradeHistory/enums/TradeHistoryType'
import ID from '../../common/components/ID'
import RetireeName from './RetireeName'
import Action from '../../common/components/Action'
import { DownloadSimple } from '@phosphor-icons/react'
import ImageDownload from '../../common/components/ImageDownload'

type RetirementColumns =
    | 'id'
    | 'status'
    | 'month'
    | 'project_name'
    | 'vintage'
    | 'registry'
    | 'quantity'
    | 'retiree_name'
    | 'buy_order'
    | 'action'

interface Props {
    project?: Project
    buyOrderId?: string
    displayHeading?: boolean
    hideNoResults?: boolean
}

const RetirementsTable = ({
    project,
    buyOrderId,
    displayHeading = false,
    hideNoResults = false,
}: Props): JSX.Element => {
    const [retirementData, setRetirementData] = useState<Values<RetirementColumns>[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string | null>(null)
    const { isCaasUser, isCaasProUser } = useUserStore()

    const caasUser = isCaasUser()
    const caasProUser = isCaasProUser()

    const getRetirements = async (): Promise<void> => {
        if (!caasUser && !caasProUser) return
        try {
            setErrMsg(null)
            setIsLoading(true)
            const searchParams: SearchRetirementRequestsParams = {
                project_id: project?.id,
                buy_order_id: buyOrderId,
            }
            const retirements = (await RetirementRequestsApi.search(searchParams))?.data.retirements
            if (!retirements) {
                throw new Error('Unable to get retirements')
            }
            setRetirementData(
                retirements.map((retirement) => {
                    const canDisplayBuyOrder = retirement.trades.every((trade) => {
                        return trade.type !== TradeHistoryType.HOLD
                    })
                    let buyOrderId: string | undefined
                    if (retirement.trades.length === 1) {
                        buyOrderId = retirement.trades[0].buy_order_id
                    }
                    return {
                        id: {
                            value: <ID link={`/retirement/${retirement.id}`}>{retirement.id}</ID>,
                        },
                        status: {
                            value: (
                                <RetirementStatusPill
                                    status={retirement.status}
                                ></RetirementStatusPill>
                            ),
                        },
                        month: {
                            value: (
                                <DateTime
                                    date={new Date(retirement.month)}
                                    monthAndYearOnly={true}
                                ></DateTime>
                            ),
                        },
                        project_name: {
                            value: (
                                <ProjectName
                                    squareImageUrl={retirement.project_data.square_image_url}
                                    type={retirement.project_data.type}
                                    name={retirement.project_data.name}
                                    id={retirement.project_data.id}
                                    vintage={retirement.vintage}
                                />
                            ),
                        },
                        vintage: {
                            value: retirement.vintage,
                            textAlignment: TextAlignment.RIGHT,
                        },
                        registry: {
                            value: (
                                <RegistryIcon registryId={retirement.project_data.registry_id} />
                            ),
                        },
                        quantity: {
                            value: convertGramsToMetricTonesLocaleString(
                                Number(retirement.quantity),
                            ),
                            textAlignment: TextAlignment.RIGHT,
                        },
                        retiree_name: {
                            value: <RetireeName retirees={retirement.retirees} />,
                        },
                        buy_order: {
                            value:
                                buyOrderId && canDisplayBuyOrder ? (
                                    <ID link={`/buy-order/${buyOrderId}`}>{buyOrderId}</ID>
                                ) : (
                                    <></>
                                ),
                        },
                        action: {
                            value:
                                retirement.status === RetirementStatuses.COMPLETE ? (
                                    <ImageDownload url={retirement.proof_of_retirement_nft_url}>
                                        <Action
                                            key={`retirement-download-${retirement.id}`}
                                            id={`retirement-download-${retirement.id}`}
                                            IconElement={DownloadSimple}
                                            tooltip="Download"
                                        />
                                    </ImageDownload>
                                ) : (
                                    <></>
                                ),
                            textAlignment: TextAlignment.RIGHT,
                        },
                    }
                }),
            )
            setIsLoading(false)
        } catch (err) {
            setErrMsg(
                'There was a problem loading your retirements. Please refresh the page to try again.',
            )
            setIsLoading(false)
            Sentry.captureException(err)
        }
    }

    useEffect(() => {
        getRetirements()
    }, [])

    const columns: Column<RetirementColumns>[] = [
        {
            name: 'id',
            display: 'Retirement',
        },
        {
            name: 'status',
            display: 'Status',
        },
        {
            name: 'month',
            display: 'Month',
        },
        {
            name: 'project_name',
            display: 'Project',
        },
        {
            name: 'vintage',
            display: 'Vintage',
        },
        {
            name: 'registry',
            display: 'Registry',
        },
        {
            name: 'quantity',
            display: 'Quantity',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'retiree_name',
            display: 'Retiree',
        },
        {
            name: 'buy_order',
            display: 'Buy Order',
        },
        {
            name: 'action',
            display: '',
        },
    ]

    const heading: React.ReactNode = (
        <h3 className="text-gray-900 font-semibold text-xl mb-2">Retirements</h3>
    )

    return (
        <>
            {isLoading && <LoadingSpinner></LoadingSpinner>}
            {errMsg && <Note type={NoteType.ERROR}>{errMsg}</Note>}
            {retirementData.length > 0 && !isLoading && !errMsg && (
                <>
                    {displayHeading && heading}
                    <Table columns={columns} data={retirementData} />
                </>
            )}
            {retirementData.length === 0 && !hideNoResults && !isLoading && !errMsg && (
                <Note type={NoteType.INFO}>No retirements found</Note>
            )}
        </>
    )
}

export default RetirementsTable
