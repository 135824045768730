import { Pagination } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAdminStore } from '../../../../modules/admin/context/store'
import { SecondaryButton } from '../../../../modules/common/components/Button'
import { Page } from '../../../../modules/common/enums/Pages'
import { convertCentsToLocaleUsd } from '../../../../utils/conversion'
import EyeIcon from '../../common/components/EyeIcon'
import { generatePathWithQueryString } from '../../user/http/utils/generatePathWithQueryString'
import { PayoutApi } from '../http/api'
import { SearchPayoutsParams } from '../http/types/SearchPayoutsParams'
import { SearchPayoutsResponse } from '../http/types/SearchPayoutsResponse'

const PayoutsTable = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchPayoutsResponse, setSearchPayoutsResponse] =
        useState<SearchPayoutsResponse | null>(null)
    const [isFetching, setIsFetching] = useState<boolean>(false)

    const navigate = useNavigate()
    const { setShowAlertMessage } = useAdminStore()

    const searchPayouts = async (params: SearchPayoutsParams) => {
        try {
            setIsFetching(true)
            const response = await PayoutApi.searchPayouts(params)
            if (response?.data) {
                setSearchPayoutsResponse(response.data)
            }
        } finally {
            setIsFetching(false)
        }
    }

    const createPayout = async (tenantId: string) => {
        try {
            const response = await PayoutApi.createPayout(tenantId)
            if (response?.data?.id) {
                setShowAlertMessage({
                    text: `Payout for tenant ${tenantId} created successfully.`,
                    color: 'success',
                })
                navigate(
                    generatePathWithQueryString(Page.ADMIN_PAYOUTS_DETAILS, {
                        payoutId: response?.data?.id,
                    }),
                )
            }
        } catch (e: any) {
            setShowAlertMessage({
                text: e.response?.data?.message,
                color: 'failure',
            })
        }
    }

    useEffect(() => {
        void searchPayouts({
            page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
        })
    }, [searchParams])

    return (
        <div
            className="mt-6 mb-2 border border-gray-200 rounded-lg overflow-hidden shadow-md shadow-black"
            style={{
                boxShadow:
                    '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
            }}
        >
            <table className="w-full">
                <thead className="w-full">
                    <tr className="h-11 flex items-center w-full bg-gray-50">
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            TENANT ID
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            TENANT NAME
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            TOTAL SOLD BUT NOT SETTLED
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            TOTAL SOLD AND SETTLED
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            TOTAL PAYOUT PENDING
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            TOTAL PAID OUT
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            ACTIONS
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={7}>
                            {isFetching && <div className="p-6 text-gray-600">Fetching data</div>}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={7}>
                            {!!searchPayoutsResponse &&
                                !isFetching &&
                                searchPayoutsResponse?.results?.length === 0 && (
                                    <div className="p-6 text-gray-600">No payouts found</div>
                                )}
                        </td>
                    </tr>
                    {!!searchPayoutsResponse &&
                        !isFetching &&
                        searchPayoutsResponse?.results?.map((payout) => (
                            <tr className="h-18 flex items-center w-full" key={payout.tenant_id}>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    {payout.tenant_id}
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    {payout.tenant_name ?? '-'}
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    {convertCentsToLocaleUsd(BigInt(payout.total_sold_not_settled))}
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    {convertCentsToLocaleUsd(BigInt(payout.total_sold_settled))}
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    {convertCentsToLocaleUsd(BigInt(payout.total_payout_pending))}
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    {convertCentsToLocaleUsd(BigInt(payout.total_paid_out))}
                                </td>
                                <td className="flex-1 flex-row items-center justify-start text-gray-500 text-sm text-left font-normal">
                                    {(BigInt(payout.total_payout_pending) > BigInt(0) ||
                                        BigInt(payout.total_paid_out) > BigInt(0)) && (
                                        <SecondaryButton
                                            className="mt-1 px-2.5 py-1 flex items-center justify-between gap-1"
                                            onClick={() => {
                                                navigate(
                                                    generatePathWithQueryString(
                                                        Page.ADMIN_SELLER_PAYOUTS,
                                                        {
                                                            sellerTenantId: payout.tenant_id,
                                                        },
                                                    ),
                                                )
                                            }}
                                        >
                                            <EyeIcon />
                                            View Payouts
                                        </SecondaryButton>
                                    )}
                                    {BigInt(payout.total_sold_settled) > BigInt(0) && (
                                        <SecondaryButton
                                            className="mt-1 px-2.5 py-1 flex items-center justify-between gap-1"
                                            onClick={() => {
                                                void createPayout(payout.tenant_id)
                                            }}
                                        >
                                            <img src="/assets/icon_payout.svg" alt="payout" />
                                            Create Payout
                                        </SecondaryButton>
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <div className="flex justify-center mt-4">
                {searchPayoutsResponse?.pagination &&
                    searchPayoutsResponse.pagination.total_items >
                        searchPayoutsResponse.pagination.per_page && (
                        <Pagination
                            currentPage={searchPayoutsResponse?.pagination.current_page}
                            totalPages={searchPayoutsResponse?.pagination.total_pages}
                            onPageChange={(page: number) => setSearchParams({ page: String(page) })}
                        />
                    )}
            </div>
        </div>
    )
}

export default PayoutsTable
