import AlignMiddle from '../../common/components/AlignMiddle'
import { Project } from '../../project/types/Project'
import { Vintage } from '../../vintage/types/Vintage'
import { CarbonCreditBalanceSingleResult } from '../types/CarbonCreditBalance'
import CarbonCreditBalance from './CarbonCreditBalance'

interface Props {
    vintages: Vintage[]
    project: Project | CarbonCreditBalanceSingleResult
    reloadProject: () => Promise<void>
}

const ProjectCarbonCreditBalance = ({ vintages, project, reloadProject }: Props): JSX.Element => {
    if (
        !vintages ||
        vintages.length === 0 ||
        vintages.flatMap((vintage) => {
            return vintage.carbon_credit_balances ?? []
        }).length === 0
    )
        return <></>

    return (
        <div className="w-full">
            <h3 className="text-gray-900 font-semibold text-xl mb-2">Carbon Credit Balances</h3>
            <AlignMiddle direction="row" childClassName="-order-1 w-full lg:w-[70%]">
                <CarbonCreditBalance
                    vintages={vintages}
                    project={project}
                    reloadProject={reloadProject}
                    hideEmptyBalances={false}
                />
            </AlignMiddle>
        </div>
    )
}

export default ProjectCarbonCreditBalance
