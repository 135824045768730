import { SellOrder } from '../types/SellOrder'
import { PurchaseAndRetirePayload } from './types/PurchaseAndRetirePayload'
import { PurchasePayload } from './types/PurchasePayload'
import { SaveSellOrderPayload } from './types/SaveSellOrderPayload'
import { SearchSellOrdersParams } from './types/SearchSellOrdersParams'
import { SearchSellOrdersResponse } from './types/SearchSellOrdersResponse'
import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { SortSellOrderColumns } from '../../myOrders/context/types'
import { PurchaseResponseDto } from './types/PurchaseResponseDto'
import { PurchaseRetireResponseDto } from './types/PurchaseRetireResponseDto'
import { CreateSellOrderResponse } from './types/CreateSellOrderResponse'

export interface SellOrderApiInterface {
    search(
        params: SearchSellOrdersParams,
    ): Promise<AxiosResponse<SearchSellOrdersResponse> | undefined>
    getById(id: string): Promise<AxiosResponse<SellOrder> | undefined>
    create(
        payload: SaveSellOrderPayload,
        requestId: string,
    ): Promise<AxiosResponse<CreateSellOrderResponse> | undefined>
    purchaseAndRetire(
        payload: PurchaseAndRetirePayload,
        requestId: string,
    ): Promise<AxiosResponse<PurchaseRetireResponseDto> | undefined>
    purchase(
        payload: PurchasePayload,
        requestId: string,
    ): Promise<AxiosResponse<PurchaseResponseDto> | undefined>
}

export const SellOrderApi: SellOrderApiInterface = Object.freeze({
    search(
        params: SearchSellOrdersParams,
    ): Promise<AxiosResponse<SearchSellOrdersResponse> | undefined> {
        return HttpService.get('/sell-orders', null, params)
    },
    getById(id: string): Promise<AxiosResponse<SellOrder> | undefined> {
        return HttpService.get('/sell-orders', id)
    },
    create(
        payload: SaveSellOrderPayload,
        requestId: string,
    ): Promise<AxiosResponse<CreateSellOrderResponse> | undefined> {
        return HttpService.post('/sell-orders', payload, { 'request-id': requestId })
    },
    purchaseAndRetire(
        payload: PurchaseAndRetirePayload,
        requestId: string,
    ): Promise<AxiosResponse<PurchaseRetireResponseDto> | undefined> {
        return HttpService.post('sell-orders/purchase-retire', payload, {
            'request-id': requestId,
        })
    },
    purchase(
        payload: PurchasePayload,
        requestId: string,
    ): Promise<AxiosResponse<PurchaseResponseDto> | undefined> {
        return HttpService.post('sell-orders/purchase', payload, {
            'request-id': requestId,
        })
    },
})

export const mapSortByColumnName = (columnName: string): SortSellOrderColumns | null => {
    switch (columnName) {
        case 'Project Name':
            return 'project_name'
        case 'Type':
            return 'project_type'
        case 'Vintage':
            return 'vintage'
        case 'Location':
            return 'project_location'
        case 'Registry':
            return 'project_registry'
        case 'Quantity':
            return 'quantity'
        case 'Price':
            return 'price'
        default:
            return null
    }
}
