import { Registry } from '../types/Registry'
import { SetRegistriesAction, SET_REGISTRIES } from './types'
import { Dispatch } from 'react'

export const setRegistriesAction = (
    registries: Registry[],
    dispatch: Dispatch<SetRegistriesAction>,
): void => {
    dispatch({
        type: SET_REGISTRIES,
        payload: { registries },
    })
}
