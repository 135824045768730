import { useState } from 'react'
import BaseModal from '../../../modules/modal/components/BaseModal'
import AskUsForm from './forms/AskUsForm'

interface Props {
    show: boolean
    onClose: () => void
}

const AskUsModal = ({ show, onClose }: Props): JSX.Element => {
    const [error, setError] = useState<string | null>(null)
    const [showSpinner, setShowSpinner] = useState<boolean>(false)

    const close = () => {
        setError(null)
        onClose()
    }
    return (
        <BaseModal
            show={show}
            id="create-sell-order-modal"
            position="center"
            error={error}
            showSpinner={showSpinner}
            onClose={close}
        >
            <AskUsForm
                setError={setError}
                onSuccess={close}
                onClose={close}
                setShowSpinner={setShowSpinner}
            />
        </BaseModal>
    )
}

export default AskUsModal
