import React, { HTMLInputAutoCompleteAttribute, HTMLInputTypeAttribute } from 'react'
import { FieldPath, FieldValues, RegisterOptions } from 'react-hook-form'

export enum Alignment {
    INLINE,
    NEXTLINE,
}

export enum Placement {
    BEFORE,
    AFTER,
}

export type Field<T extends FieldValues> = {
    name: FieldPath<T>
    displayName: string
    type?: HTMLInputTypeAttribute | 'number_slider' | 'dollar'
    options?: RegisterOptions<T, FieldPath<T>>
    rules?: Omit<
        RegisterOptions<T, FieldPath<T>>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
    >
    inputOverride?: JSX.Element
    className?: string
    required?: boolean
    hideAsterix?: boolean
    tooltip?: string
    id?: string
    min?: number | string
    max?: number | string
    step?: number
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    disabled?: boolean
    autoComplete?: HTMLInputAutoCompleteAttribute
    placeholder?: string
    alignment?: Alignment
    placement?: Placement
    labelClassName?: string
    children?: { value: React.ReactNode; alignment?: Alignment }
    multiSelect?: boolean
    spacing?: string
    hide?: boolean
    description?: string
    rows?: number
}

export type FormElement<T extends FieldValues> = Field<T> | React.ReactNode
