import React from 'react'

interface TitleProps {
    title: string
    subTitle: string
    showDividingLine: boolean
    topRightComponent?: JSX.Element
    topLeftComponent?: JSX.Element
}

const Title = ({
    title,
    subTitle,
    showDividingLine,
    topRightComponent,
    topLeftComponent,
}: TitleProps): JSX.Element => {
    return (
        <React.Fragment>
            {topLeftComponent && <div className="mb-4">{topLeftComponent}</div>}
            <div className="flex flex-col gap-2 md:flex-row md:gap-0">
                <div className="flex flex-col">
                    <h2 className="text-[30px] leading-[38px] text-gray-900 font-medium mb-1">
                        {title}
                    </h2>
                    <p className="mt-1 text-gray-500 text-base font-normal">{subTitle}</p>
                </div>
                <div className="flex-1"></div>
                {topRightComponent}
            </div>
            {showDividingLine && <hr className="my-6 h-px border-none bg-gray-200" />}
        </React.Fragment>
    )
}

export default Title
