import { FeatureFlag } from '../types/FeatureFlag'
import { FeatureFlagsAction, SET_FEATURE_FLAGS } from './types'

export interface FeatureFlagState {
    featureFlags: FeatureFlag[]
    setFeatureFlags: (response: FeatureFlag[]) => void
    fetchFeatureFlags: () => Promise<void>
    isFeatureFlagEnabled: (name: string) => boolean
}

export const initialFeatureFlagsState: FeatureFlagState = {
    featureFlags: [],
    setFeatureFlags: () => {},
    fetchFeatureFlags: () => Promise.resolve(),
    isFeatureFlagEnabled: () => false,
}

export const FeatureFlagsReducer = (
    state = initialFeatureFlagsState,
    action: FeatureFlagsAction,
): FeatureFlagState => {
    switch (action.type) {
        case SET_FEATURE_FLAGS:
            return {
                ...state,
                featureFlags: action.payload.featureFlags,
            }
        default:
            return state
    }
}
