/* eslint-disable indent */
import { SET_TOAST_MESSAGE, ToastMessage, ToastMessageAction } from './types'

export interface ToastMessageState {
    message: ToastMessage | null
}

export const initialToastMessageStoreState: ToastMessageState = {
    message: null,
}

export const toastMessageReducer = (
    state = initialToastMessageStoreState,
    action: ToastMessageAction,
): ToastMessageState => {
    switch (action.type) {
        case SET_TOAST_MESSAGE:
            return {
                ...state,
                message: action.payload.message,
            }
        default:
            return state
    }
}
