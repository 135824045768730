// import ToastMessage from '../components/ToastMessage'
// import LeftSection from '../components/authentication/LeftSection'
// import RightSection from '../components/authentication/RightSection'
// import { useToastMessageStore } from '../context/toastMessage/store'

import ToastMessage from '../../modules/toastMessage/components/ToastMessage'
import { useToastMessageStore } from '../../modules/toastMessage/context/store'
import LeftSection from './components/LeftSection'
import RightSection from './components/RightSection'

const SignIn = (): JSX.Element => {
    const { state } = useToastMessageStore()
    return (
        <div className="bg-black flex flex-row min-h-screen">
            {state.message && <ToastMessage type={state.message.type} text={state.message.text} />}
            <div className="hidden lg:block w-1/2 min-h-screen">
                <LeftSection />
            </div>
            <div className="w-full lg:w-1/2 min-h-screen">
                <RightSection />
            </div>
        </div>
    )
}

export default SignIn
