import { convertCentsToLocaleUsd } from '../../../../utils/conversion'

interface PriceFilterFormProps {
    minPrice: string
    maxPrice: string
    marketMinimum: number
    marketMaximum: number
    onMinPriceChange: (value: string) => void
    onMaxPriceChange: (value: string) => void
}

const PriceFilterForm = ({
    minPrice,
    maxPrice,
    marketMinimum,
    marketMaximum,
    onMinPriceChange,
    onMaxPriceChange,
}: PriceFilterFormProps): JSX.Element => {
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>): void => e.target.select()
    return (
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-12.5">
            <div>
                <label className="text-gray-700 text-sm font-semibold">Min. Price</label>
                <div className="relative my-1.5">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <p className="text-gray-500 font-normal text-base">$</p>
                    </div>
                    <input
                        type="number"
                        value={minPrice}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            // [0,9] and empty string
                            const regex = /^[0-9]|^$/
                            if (regex.test(e.target.value)) {
                                onMinPriceChange(e.target.value)
                            }
                        }}
                        step={0.1}
                        min={marketMinimum / 100}
                        max={marketMaximum / 100}
                        onFocus={handleFocus}
                        className="lg:w-44 pl-6 rounded-lg border-gray-300 text-gray-500 font-normal text-base w-full numeric-input-without-arrows"
                    />
                </div>
                <p className="text-sm leading-5 font-normal text-gray-500">
                    Market Minimum{' '}
                    <span className="text-primary-500">
                        {convertCentsToLocaleUsd(BigInt(marketMinimum))}
                    </span>
                </p>
            </div>
            <div>
                <label className="text-gray-700 text-sm font-semibold">Max. Price</label>
                <div className="relative my-1.5">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <p className="text-gray-500 font-normal text-base">$</p>
                    </div>
                    <input
                        type="number"
                        step={0.1}
                        min={marketMinimum / 100}
                        max={marketMaximum / 100}
                        value={maxPrice}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            // [0,9] and empty string
                            const regex = /^[0-9]|^$/
                            if (regex.test(e.target.value)) {
                                onMaxPriceChange(e.target.value)
                            }
                        }}
                        onFocus={handleFocus}
                        className="w-full lg:w-44 pl-6 rounded-lg border-gray-300 text-gray-500 font-normal text-base numeric-input-without-arrows"
                    />
                </div>
                <p className="text-sm leading-5 font-normal text-gray-500">
                    Market Maximum{' '}
                    <span className="text-primary-500">
                        {convertCentsToLocaleUsd(BigInt(marketMaximum))}
                    </span>
                </p>
            </div>
        </div>
    )
}

export default PriceFilterForm
