import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import { Link, useParams } from 'react-router-dom'
import SubSection from '../../modules/common/components/SubSection'
import { PrimaryButton, SecondaryButton } from '../../modules/common/components/Button'
import RecordField from '../../modules/recordDetails/components/RecordField'
import { convertCentsToLocaleUsd } from '../../utils/conversion'
import { Fragment, useEffect, useState } from 'react'
import RecordDetailActions from '../../modules/recordDetails/components/RecordDetailActions'
import { InvoiceDto } from '../../modules/invoice/http/types/InvoiceDto'
import { InvoiceApi } from '../../modules/invoice/http/InvoiceApi'
import { InvoiceStatus } from '../../modules/invoice/enums/InvoiceStatus'
import InvoiceStatusPill from '../../modules/invoice/components/InvoiceStatusPill'
import DateTimeFormatted from '../../modules/dateTime/components/DateTimeFormatted'
import IlisTable from '../../modules/invoice/components/IlisTable'
import CancelledIlisTable from '../../modules/invoice/components/CancelledIlisTable'
import LoadingSpinner from '../../modules/loadingSpinner/components/LoadingSpinner'
import Note, { NoteType } from '../../modules/note/components/Note'
import PayInvoiceModal from '../../modules/invoice/components/PayInvoiceModal'
import * as Sentry from '@sentry/react'
import DownloadFromUrl from '../../modules/uploadDownload/components/DownloadFromUrl'
import { useToastMessageStore } from '../../modules/toastMessage/context/store'
import { ToastMessageType } from '../../modules/toastMessage/enums/ToastMessageType'

const Invoice = (): JSX.Element => {
    const [invoice, setInvoice] = useState<InvoiceDto>()
    const [showPayModal, setShowPayModal] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string | null>(null)
    // download PDF stuff - start
    const [signedUrl, setSignedUrl] = useState<string>('')
    const [filename, setFilename] = useState<string>('')
    const [triggerDownload, setTriggerDownload] = useState<boolean>(false)
    // download PDF stuff - end
    const { id } = useParams()
    const { setShowToastMessage } = useToastMessageStore()

    const getRecord = async (): Promise<void> => {
        try {
            setErrMsg(null)
            setIsLoading(true)
            setInvoice((await InvoiceApi.getById(id!)).data)
            setIsLoading(false)
        } catch (err) {
            setErrMsg(
                'There was a problem loading your invoice. Please refresh the page to try again.',
            )
            setIsLoading(false)
            Sentry.captureException(err)
        }
    }

    useEffect(() => {
        getRecord()
    }, [id])

    const handleInvoiceDownload = async (invoiceId: string): Promise<void> => {
        try {
            const signedUrlData = (await InvoiceApi.generateInvoiceDownloadSignedUrl(invoiceId))
                ?.data
            if (!signedUrlData) {
                throw new Error(`signedUrlData is falsey. Invoice id: ${invoiceId}`)
            }
            setTriggerDownload(false)
            setSignedUrl(signedUrlData.url)
            setFilename(signedUrlData.filename)
            setTriggerDownload(true)
        } catch (err) {
            setShowToastMessage({
                text: 'There was a problem downloading your invoice. Please try again later.',
                type: ToastMessageType.ERROR,
            })
            Sentry.captureException(err)
        }
    }

    return (
        <Layout
            title="Invoice"
            subTitle={invoice ? invoice.id : ''}
            dataCy="invoice-layout"
            selectedNavBarItem={NavBarItemName.ORDERS}
            topRightComponent={
                invoice && (
                    <RecordDetailActions>
                        {invoice.status === InvoiceStatus.OPEN && (
                            <PrimaryButton
                                onClick={() => {
                                    setShowPayModal(true)
                                }}
                            >
                                Pay Invoice
                            </PrimaryButton>
                        )}
                        {invoice.invoice_uploaded && (
                            <>
                                <SecondaryButton
                                    onClick={() => {
                                        handleInvoiceDownload(invoice.id)
                                    }}
                                >
                                    Download
                                </SecondaryButton>
                                <DownloadFromUrl
                                    triggerDownload={triggerDownload}
                                    url={signedUrl}
                                    filename={filename}
                                />
                            </>
                        )}
                    </RecordDetailActions>
                )
            }
            topLeftComponent={
                <Link className="link text-sm" to="/orders/invoices">
                    {'<< All Invoices'}
                </Link>
            }
        >
            <>
                {isLoading && <LoadingSpinner></LoadingSpinner>}
                {errMsg && <Note type={NoteType.ERROR}>{errMsg}</Note>}
                {invoice && !isLoading && !errMsg && (
                    <Fragment>
                        <div className="grid grid-rows-4 md:grid-cols-4 md:grid-rows-1 gap-4">
                            <RecordField label="Status">
                                <InvoiceStatusPill status={invoice.status} />
                            </RecordField>
                            <RecordField label="Month">
                                <DateTimeFormatted
                                    date={new Date(invoice.month)}
                                    monthAndYearOnly={true}
                                />
                            </RecordField>
                            <RecordField label="Month Invoice Number">
                                #{invoice.month_index}
                            </RecordField>
                            <RecordField label="Total">
                                <div className="font-bold">
                                    {invoice.status === InvoiceStatus.CANCELLED
                                        ? convertCentsToLocaleUsd(invoice.cancelled_total)
                                        : convertCentsToLocaleUsd(invoice.total)}
                                </div>
                            </RecordField>
                        </div>
                        {invoice.invoice_line_items.length > 0 && (
                            <SubSection title="Line Items">
                                <IlisTable ilis={invoice.invoice_line_items} />
                            </SubSection>
                        )}
                        {invoice.cancelled_line_items.length > 0 && (
                            <SubSection title="Cancelled Line Items">
                                <CancelledIlisTable cancelledIlis={invoice.cancelled_line_items} />
                            </SubSection>
                        )}
                    </Fragment>
                )}
                {!invoice && !isLoading && !errMsg && (
                    <Note type={NoteType.INFO}>No invoice found</Note>
                )}
                <PayInvoiceModal
                    invoice={invoice!}
                    show={showPayModal && !!invoice}
                    onClose={(success) => {
                        setShowPayModal(false)
                        if (success) {
                            getRecord()
                        }
                    }}
                />
            </>
        </Layout>
    )
}

export default Invoice
