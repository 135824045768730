import { ImageFileType } from '../common/enums/ImageFileType'
import { ProjectType } from './enums/ProjectType'

export enum ProjectTypeImgType {
    BANNER,
    SQUARE,
}

export interface ImageAndExtension {
    name: string
    extension: ImageFileType
}

export const getProjectImageNameAndExtension = (projectType: ProjectType): ImageAndExtension => {
    const mappings = new Map([
        [
            ProjectType.AFFORESTATION_REFORESTATION_AND_RESTORATION,
            { name: 'AFOLU_REDD', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.AGRICULTURAL_METHANE_RECOVERY,
            { name: 'Agriculture_Methane_recovery', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.AVOIDED_DEFORESTATION,
            { name: 'Avoided_Deforestation', extension: ImageFileType.JPG },
        ],
        [ProjectType.BIOCHAR, { name: 'Biochar', extension: ImageFileType.JPG }],
        [
            ProjectType.BIOENERGY_CARBON_CAPTURE_AND_STORAGE,
            { name: 'Bioenergy_Carbon_Capture___Storage', extension: ImageFileType.JPG },
        ],
        [ProjectType.BIOGAS, { name: 'Biogas', extension: ImageFileType.JPG }],
        [ProjectType.BIOMASS, { name: 'Biomass', extension: ImageFileType.JPG }],
        [ProjectType.BOREHOLES, { name: 'Borehole', extension: ImageFileType.JPG }],
        [
            ProjectType.BRICK_MANUFACTURING,
            { name: 'Brick_Manufacturing', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.CAROBNATED_BUILDING_MATERIAL,
            { name: 'Carbonated_Building_Materials', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.CEMENT_AND_CONCRETE,
            { name: 'Cement___Concrete', extension: ImageFileType.JPG },
        ],
        [ProjectType.COOKSTOVES, { name: 'Cookstoves', extension: ImageFileType.PNG }],
        [
            ProjectType.DIRECT_AIR_CAPTURE,
            { name: 'Direct_Air_Capture', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.DOMESTIC_ENERGY_EFFICIENCY,
            { name: 'Domestic_Energy_Efficiency', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.DOMESTIC_FUEL_EFFICIENCY_BIODIGESTERS,
            { name: 'Domestic_Fuel_Efficiency_Biodigesters', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.DOMESTIC_FUEL_EFFICIENCY_LIGHTING,
            { name: 'Domestic_Fuel_Efficiency_Lighting', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.ENERGY_EFFICIENCY,
            { name: 'Energy_Efficiency', extension: ImageFileType.PNG },
        ],
        [
            ProjectType.ENHANCED_OIL_RECOVERY,
            { name: 'Enhanced_Oil_Recovery', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.ENHANCED_WEATHERING,
            { name: 'Enhanced_Weathering', extension: ImageFileType.JPG },
        ],
        [ProjectType.FEED_ADDITIVES, { name: 'Feed_Additives', extension: ImageFileType.JPG }],
        [ProjectType.FUEL_SWITCH, { name: 'Fuel_Switch', extension: ImageFileType.JPG }],
        [
            ProjectType.FUGITIVE_EMISSIONS,
            { name: 'Fugitive_Emissions', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.GEOLOGICALLY_STORED,
            { name: 'Geologically_Stored', extension: ImageFileType.JPG },
        ],
        [ProjectType.GEOTHERMAL, { name: 'Geothermal', extension: ImageFileType.JPG }],
        [ProjectType.GRASSLANDS, { name: 'Grasslands', extension: ImageFileType.JPG }],
        [ProjectType.HYDRO, { name: 'Hydro', extension: ImageFileType.JPG }],
        [
            ProjectType.IMPROVED_FOREST_MANAGEMENT,
            { name: 'Improved_Forest_Management', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.INDUSTRIAL_ENERGY_EFFICIENCY,
            { name: 'Industrial_Energy_Efficiency', extension: ImageFileType.JPG },
        ],
        [ProjectType.IRRIGATION, { name: 'Irrigation', extension: ImageFileType.JPG }],
        [ProjectType.LANDFILL_GAS, { name: 'Landfill_Gas', extension: ImageFileType.JPG }],
        [ProjectType.MANGROVES, { name: 'Mangroves', extension: ImageFileType.JPG }],
        [ProjectType.METAL_PRODUCTION, { name: 'Metal_production', extension: ImageFileType.JPG }],
        [ProjectType.METHANE_CAPTURE, { name: 'Methane_Capture', extension: ImageFileType.JPG }],
        [
            ProjectType.N2O_FROM_ADIPIC_ACID,
            { name: 'N2O_from_Adipic_Acid', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.N2O_FROM_NITRIC_ACID,
            { name: 'N2O_from_Nitric_Acid', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.NON_OIL_RECYCLING,
            { name: 'Non-Oil_Recycling', extension: ImageFileType.JPG },
        ],
        [ProjectType.OIL_RECYCLING, { name: 'Oil_Recycling', extension: ImageFileType.JPG }],
        [ProjectType.OTHER_TRANSPORT, { name: 'Other_transport', extension: ImageFileType.JPG }],
        [
            ProjectType.OZONE_DEPLETING_SUBSTANCES,
            { name: 'Ozone_Depleting_Substances', extension: ImageFileType.JPG },
        ],
        [ProjectType.PEATLANDS, { name: 'Peatlands', extension: ImageFileType.JPG }],
        [ProjectType.REDD_PLUS, { name: 'REDD+', extension: ImageFileType.JPG }],
        [
            ProjectType.REGENERATIVE_AGRICULTURE,
            { name: 'Regenerative_Agriculture', extension: ImageFileType.JPG },
        ],
        [ProjectType.RENEWABLES, { name: 'Renewables', extension: ImageFileType.JPG }],
        [
            ProjectType.SEAGRASS_AND_SEAWEEDS,
            { name: 'Seagrass___Seaweeds', extension: ImageFileType.JPG },
        ],
        [
            ProjectType.SOIL_AMENDMENT_BASED_ON_CARBON_STORAGE_METHODOLOGY,
            {
                name: 'Soil_Amendment_based_on_Carbon_Storage_Methodology',
                extension: ImageFileType.JPG,
            },
        ],
        [ProjectType.SOLAR, { name: 'Solar', extension: ImageFileType.JPG }],
        [
            ProjectType.SULPHUR_EMISSIONS,
            { name: 'Sulphur_Emissions', extension: ImageFileType.JPG },
        ],
        [ProjectType.SYNTHETIC_FUELS, { name: 'Synthetic_fuels', extension: ImageFileType.JPG }],
        [
            ProjectType.WASTE_HEAT_RECOVERY,
            { name: 'Waste_Heat_Recovery', extension: ImageFileType.JPG },
        ],
        [ProjectType.WATER, { name: 'Water', extension: ImageFileType.JPG }],
        [ProjectType.WATER_FILTERS, { name: 'Water_Filters', extension: ImageFileType.JPG }],
        [
            ProjectType.WETLAND_RESTORATION,
            { name: 'Wetland_Restoration', extension: ImageFileType.JPG },
        ],
        [ProjectType.WIND, { name: 'Wind', extension: ImageFileType.JPG }],
        [
            ProjectType.WOODEN_BUILDING_MATERIALS,
            { name: 'Wooden_Building_Materials', extension: ImageFileType.JPEG },
        ],
        [
            ProjectType.WOODY_BIOMASS_BURIAL,
            { name: 'Woody_Biomass_Burial', extension: ImageFileType.JPG },
        ],
    ])
    return mappings.get(projectType) as ImageAndExtension
}

export const getProjectTypeImgSrc = (
    projectType: ProjectType | undefined,
    imgTypeEnumValue: ProjectTypeImgType,
): string => {
    const imgType = imgTypeEnumValue === ProjectTypeImgType.BANNER ? 'banner' : 'square'
    const projectTypeKeys = Object.values(ProjectType)
    if (!projectType || !projectTypeKeys.includes(projectType)) {
        throw new Error(`Unknown Project Type: ${projectType}`)
    }
    const currentProjectTypeImage = getProjectImageNameAndExtension(projectType)
    return `/assets/project_types/${imgType}/${encodeURIComponent(currentProjectTypeImage.name)}.${
        currentProjectTypeImage.extension
    }`
}
