import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import PayoutDetailsOverview from '../../modules/payouts/components/PayoutDetailsOverview'

const AdminPayoutDetails = () => {
    return (
        <AdminLayout selectedItem={AdminSubMenuActions.SELLER_PAYOUTS}>
            <h3 className="font-semibold underline underline-offset-2 mb-2">Payout Details</h3>
            <PayoutDetailsOverview />
        </AdminLayout>
    )
}

export default AdminPayoutDetails
