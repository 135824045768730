import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import TradeHistoryTable from '../../modules/tradeHistory/components/TradeHistoryTable'
import OrdersSubMenu from './components/OrdersSubMenu'
import { OrderSubMenuActions } from './enums/OrderSubMenuActions'

const AllTrades = (): JSX.Element => {
    return (
        <Layout
            title=""
            subTitle=""
            dataCy="all-trades-layout"
            selectedNavBarItem={NavBarItemName.ORDERS}
        >
            <OrdersSubMenu selectedItem={OrderSubMenuActions.ALL_TRADES} />
            <TradeHistoryTable />
        </Layout>
    )
}

export default AllTrades
