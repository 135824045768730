import { RoleName } from '../../user/enums/RoleName'

export interface Tab<T> {
    type: T
    text: string
    mode?: TabModes
    linkData?: LinkData
    allowedRoles?: RoleName[] // leave undefined or empty for no access restrictions
}

export type LinkData = {
    url: string
}

export enum TabModes {
    button,
    link,
}
