import { useEffect, useState } from 'react'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import { AdminRetirementRequestDto } from '../../modules/retirement/http/types/AdminRetirementRequestDto'
import { useNavigate, useParams } from 'react-router-dom'
import { useToastMessageStore } from '../../../modules/toastMessage/context/store'
import { AxiosError } from 'axios'
import { ToastMessageType } from '../../../modules/toastMessage/enums/ToastMessageType'
import { Page } from '../../../modules/common/enums/Pages'
import { AdminRetirementRequestsApi } from '../../modules/retirement/http/AdminRetirementRequestsApi'
import { CompleteRetirementRequestParams } from '../../modules/retirement/http/types/CompleteRetirementRequestParams'
import CompleteRetirementRequestForm from '../../modules/retirement/components/CompleteRetirementRequestForm'

const CompleteRetirementRequest = (): JSX.Element => {
    const [retirementRequest, setRetirementRequest] = useState<AdminRetirementRequestDto>()
    const [fetchingItem, setFetchingItem] = useState<boolean>(false)
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()
    const [apiResponseError, setApiResponseError] = useState<string | string[]>()
    const { setShowToastMessage } = useToastMessageStore()

    const fetchRetirementRequest = async (): Promise<void> => {
        if (id) {
            try {
                setFetchingItem(true)
                const response = await AdminRetirementRequestsApi.getById(id)
                if (response) {
                    setRetirementRequest(response.data)
                }
            } catch (e) {
                if (e instanceof AxiosError && e?.response?.status === 404) {
                    setApiResponseError(e?.response?.data.message)
                    setShowToastMessage({
                        text: 'Something went wrong',
                        type: ToastMessageType.ERROR,
                    })
                }
            } finally {
                setFetchingItem(false)
            }
        }
    }

    useEffect(() => {
        void fetchRetirementRequest()
    }, [id])

    const onCompleteRetirementRequestHandler = async (
        payload: CompleteRetirementRequestParams,
    ): Promise<void> => {
        try {
            await AdminRetirementRequestsApi.complete(payload)
            setShowToastMessage({
                text: 'Retirement request completed successfully',
                type: ToastMessageType.SUCCESS,
            })
            navigate(Page.ADMIN_RETIREMENT_REQUESTS)
        } catch (e) {
            if (e instanceof AxiosError && e?.response?.status === 422) {
                setShowToastMessage({
                    text: 'Something went wrong',
                    type: ToastMessageType.ERROR,
                })
                setApiResponseError(e.response.data.message)
                return
            }
            throw e
        }
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.RETIREMENT_REQUESTS}>
            {retirementRequest && !fetchingItem ? (
                <CompleteRetirementRequestForm
                    retirementRequest={retirementRequest}
                    onCompleteRequestHandler={onCompleteRetirementRequestHandler}
                    apiResponseError={apiResponseError}
                />
            ) : fetchingItem ? (
                <div>Fetching retirement request</div>
            ) : (
                <div>Retirement request not found.</div>
            )}
        </AdminLayout>
    )
}

export default CompleteRetirementRequest
