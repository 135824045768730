import { OrderType } from '../enums/OrderType'
import { SearchMyOrdersResponse } from '../http/types/SearchMyOrdersResponse'

export const SET_SELECTED_ORDER_TYPE = 'SET_SELECTED_ORDER_TYPE'
export const SET_ORDERS_RESPONSE = 'SET_ORDERS_RESPONSE'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_CURRENT_MODAL = 'SET_CURRENT_MODAL'

export type MyOrdersAction =
    | SetSelectedOrderTypeAction
    | SetOrdersResponseAction
    | SetIsLoadingAction
    | SetCurrentModalAction

export interface SetSelectedOrderTypeAction {
    type: typeof SET_SELECTED_ORDER_TYPE
    payload: OrderType
}

export interface SetOrdersResponseAction {
    type: typeof SET_ORDERS_RESPONSE
    payload: SetOrdersResponsePayload
}

export interface SetOrdersResponsePayload {
    response: SearchMyOrdersResponse | null
}

export interface SetIsLoadingAction {
    type: typeof SET_IS_LOADING
    payload: { isLoading: boolean }
}

export interface SetCurrentModalAction {
    type: typeof SET_CURRENT_MODAL
    payload: { modal: ModalType }
}

export enum ModalTypes {
    CANCEL = 'cancel',
    EDIT = 'edit',
}

export type ModalType = ModalTypes | null

export type SellOrderColumns =
    | 'project_name'
    | 'project_type'
    | 'project_location'
    | 'project_registry'
    | 'quantity'
    | 'price'
    | 'vintage'
    | 'created_at'
    | 'sdgs'
    | 'location'
    | 'tokenized'
    | 'action'

export type SellOrderTableColumns =
    | 'project_name'
    | 'project_type'
    | 'project_registry'
    | 'quantity'
    | 'price'
    | 'vintage'
    | 'sdgs'
    | 'location'
    | 'tokenized'
    | 'action'

export type SortSellOrderColumns = Exclude<SellOrderColumns, 'sdgs' | 'action' | 'tokenized'>
