import { useState } from 'react'
import * as Sentry from '@sentry/react'
import { DangerButton, SecondaryButton } from '../../../modules/common/components/Button'
import Note, { NoteType } from '../../../modules/note/components/Note'
import BaseModal from '../../../modules/modal/components/BaseModal'
import ModalButtonContainer from '../../../modules/modal/components/ModalButtonContainer'
import { UserApi } from '../../../modules/user/http/UserApi'

interface Props {
    show: boolean
    onClose?: (success: boolean) => any
}

export const DisableMfaModal = ({ show, onClose = () => {} }: Props): JSX.Element => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [enableButtons, setEnableButtons] = useState<boolean>(true)

    const disableMfa = async (): Promise<void> => {
        try {
            setEnableButtons(false)
            setError(null)
            setShowSpinner(true)
            await UserApi.setMfaEnabled({
                is_mfa_enabled: false,
            })
            setEnableButtons(true)
            setShowSpinner(false)
            onClose(true)
        } catch (err) {
            setEnableButtons(true)
            setError('There was a problem disabling MFA. Please refresh the page and try again.')
            setShowSpinner(false)
            Sentry.captureException(err)
        }
    }

    const close = (): any => {
        setShowSpinner(false)
        setError(null)
        onClose(false)
    }

    if (!show) {
        // exit early
        return <></>
    }

    return (
        <BaseModal
            show={show}
            title={`Would you like to disable MFA?`}
            id="disable-mfa-modal"
            position="center"
            onClose={close}
            error={error}
            showSpinner={showSpinner}
            bottomComponent={
                <ModalButtonContainer>
                    <SecondaryButton onClick={close} disabled={!enableButtons}>
                        Close
                    </SecondaryButton>
                    <DangerButton onClick={disableMfa} disabled={!enableButtons}>
                        Disable MFA
                    </DangerButton>
                </ModalButtonContainer>
            }
        >
            <Note type={NoteType.WARNING} containerClassNames="mb-4">
                Disabling Multi-Factor Authentication makes your account less secure, we recommend
                you leave MFA enabled.
            </Note>
        </BaseModal>
    )
}

export default DisableMfaModal
