import { DateTime } from 'luxon'
import { AxiosHeaders, InternalAxiosRequestConfig } from 'axios'
import { v4 as uuid } from 'uuid'
import { CookieKey } from '../../cookies/enums/CookieKey'
import { getCookie, setCookie } from '../../cookies/useCookie'

const requestInterceptor = async (
    config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
    let browserId = getCookie(CookieKey.BROWSER_ID)
    // let csrfToken = getCookie(CookieKey.CSRF_TOKEN)
    const jwtToken = getCookie(CookieKey.JWT_TOKEN)
    if (!browserId) {
        browserId = uuid()
        setCookie(CookieKey.BROWSER_ID, browserId, {
            expires: DateTime.utc().plus({ years: 99 }).toJSDate().toUTCString(),
        })
    }
    if (!config.headers) {
        config.headers = new AxiosHeaders()
    }
    config.headers['thallo-browser-id'] = browserId
    // config.headers['thallo-csrf-token'] = csrfToken

    if (!config.headers.Authorization) {
        config.headers['Authorization'] = `Bearer ${jwtToken}`
    }
    return config
}

export default requestInterceptor
