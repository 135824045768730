import PriceFilter from './PriceFilter'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SecondaryButton, ModerateDangerButton } from '../../../modules/common/components/Button'
import { useSellOrdersStore } from '../../../modules/sellOrders/context/store'
import { defaultFilters } from '../filters'
import MarketFilterTags from './MarketFilterTags'
import ProjectTypeFilter from './ProjectTypeFilter'
import { ProjectType } from '../../../modules/project/enums/ProjectType'
import { ProjectLocation } from '../../../modules/project/enums/ProjectLocation'
import { SDG } from '../../../modules/project/enums/SDG'
import { SortOrder } from '../../../modules/sellOrders/http/types/SearchSellOrdersParams'
import { SortSellOrderColumns } from '../../../modules/myOrders/context/types'

const MarketFilters = (): JSX.Element => {
    const {
        searchSellOrderFilters,
        isFormDisabled,
        setSearchSellOrderFilters,
        setIsFiltersModalVisible,
    } = useSellOrdersStore()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        setSearchSellOrderFilters({
            projectName: searchParams.get('name') ?? '',
            projectType: searchParams.getAll('type') as ProjectType[],
            price: {
                min: Number(searchParams.get('min_price')) ?? 0,
                max: Number(searchParams.get('max_price')) ?? 0,
            },
            locations: searchParams.getAll('location') as ProjectLocation[],
            sdgs: searchParams.getAll('sdg') as SDG[],
            vintages: searchParams.getAll('vintage').map((vintage: string) => Number(vintage)),
            sortByColumnName: (searchParams.get('sort_by_column_name') ??
                null) as SortSellOrderColumns | null,
            sortByOrder: (searchParams.get('sort_order') ?? null) as SortOrder | null,
        })
    }, [])

    const updateQueryParams = (removeAll: boolean = false): void => {
        if (removeAll) {
            setSearchParams({})
            return
        }
        const filters = Object.keys(searchSellOrderFilters).reduce((acc, current) => {
            if (current === 'projectName' && searchSellOrderFilters.projectName.length) {
                return {
                    ...acc,
                    name: searchSellOrderFilters.projectName,
                }
            }
            if (current === 'projectType' && searchSellOrderFilters.projectType.length) {
                return {
                    ...acc,
                    type: searchSellOrderFilters.projectType,
                }
            }
            if (current === 'price') {
                let tmpFilters = acc
                if (searchSellOrderFilters.price.min > 0) {
                    tmpFilters = {
                        ...tmpFilters,
                        min_price: searchSellOrderFilters.price.min,
                    }
                }
                if (searchSellOrderFilters.price.max > 0) {
                    tmpFilters = {
                        ...tmpFilters,
                        max_price: searchSellOrderFilters.price.max,
                    }
                }
                return tmpFilters
            }
            if (current === 'locations' && searchSellOrderFilters.locations.length) {
                return {
                    ...acc,
                    location: searchSellOrderFilters.locations,
                }
            }
            if (current === 'sdgs' && searchSellOrderFilters.sdgs.length) {
                return {
                    ...acc,
                    sdg: searchSellOrderFilters.sdgs,
                }
            }
            if (current === 'vintages' && searchSellOrderFilters.vintages.length) {
                return {
                    ...acc,
                    vintage: searchSellOrderFilters.vintages,
                }
            }
            if (current === 'sortByColumnName' && searchSellOrderFilters.sortByColumnName) {
                return {
                    ...acc,
                    sort_by_column_name: searchSellOrderFilters.sortByColumnName,
                }
            }
            if (current === 'sortByOrder' && searchSellOrderFilters.sortByOrder) {
                return {
                    ...acc,
                    sort_order: searchSellOrderFilters.sortByOrder,
                }
            }
            return acc
        }, {})
        setSearchParams(filters)
    }

    useEffect(() => {
        updateQueryParams()
    }, [searchSellOrderFilters])

    const onProjectNameInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (!isFormDisabled) {
            setSearchSellOrderFilters({
                ...searchSellOrderFilters,
                projectName: e.target.value,
            })
        }
    }

    const onClearFiltersHandler = (): void => {
        setSearchSellOrderFilters(defaultFilters)
        updateQueryParams(true)
    }

    return (
        <div className="bg-gray-50">
            <div className="p-5 flex flex-col xl:flex-row items-center">
                <div className="flex flex-col sm:flex-row gap-3 w-full">
                    <div className="flex flex-col w-full sm:w-1/3 lg:w-[344px]">
                        <label className="text-gray-700 text-sm font-semibold mb-1.5">
                            Project search
                        </label>
                        <div className="relative text-gray-600 focus-within:text-gray-400">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                <button
                                    type="submit"
                                    className="p-1 focus:outline-none focus:shadow-outline"
                                >
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z"
                                            stroke="#667085"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                            </span>
                            <input
                                type="search"
                                autoFocus={true}
                                className={`h-11 w-full rounded-lg border border-gray-300 py-2.5 pl-10 text-gray-500 ${
                                    isFormDisabled ? 'bg-gray-100' : null
                                }`}
                                placeholder="Search"
                                value={searchSellOrderFilters.projectName}
                                onChange={onProjectNameInputChangeHandler}
                            />
                        </div>
                    </div>
                    <ProjectTypeFilter />
                    <PriceFilter />
                </div>
                <div className="w-full lg:w-1/2 flex flex-row items-center gap-3 justify-between xl:justify-end pt-26">
                    <SecondaryButton
                        className="w-32"
                        onClick={() => setIsFiltersModalVisible(true)}
                        disabled={isFormDisabled}
                        title="More filters"
                    >
                        <span className="flex justify-center items-center gap-3">
                            <svg
                                width="18"
                                height="12"
                                viewBox="0 0 18 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4 6H14M1.5 1H16.5M6.5 11H11.5"
                                    stroke="#344054"
                                    strokeWidth="1.67"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className="block lg:hidden xl:block">More filters</span>
                        </span>
                    </SecondaryButton>
                    <ModerateDangerButton className="w-16" onClick={onClearFiltersHandler}>
                        Clear
                    </ModerateDangerButton>
                </div>
            </div>
            <MarketFilterTags />
        </div>
    )
}
export default MarketFilters
