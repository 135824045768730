import StatusPill, { CircleStyle, Colour } from '../../statusPill/components/StatusPill'
import { SellOrderStatus } from '../enums/SelOrderStatus'

interface Props {
    status: SellOrderStatus
}

const SellOrderStatusPill = ({ status }: Props): JSX.Element => {
    switch (status) {
        case SellOrderStatus.LIVE:
            return (
                <StatusPill colour={Colour.BLUE} circleStyle={CircleStyle.SEMI_FILLED}>
                    Live
                </StatusPill>
            )
        // case SellOrderStatus.DELETED:
        //     return (
        //         <StatusPill colour={Colour.RED} circleStyle={CircleStyle.FILLED}>
        //             Closed
        //         </StatusPill>
        //     )
        case SellOrderStatus.CANCELED:
            return (
                <StatusPill colour={Colour.RED} circleStyle={CircleStyle.FILLED}>
                    Cancelled
                </StatusPill>
            )
        case SellOrderStatus.FILLED:
            return (
                <StatusPill colour={Colour.GREEN} circleStyle={CircleStyle.FILLED}>
                    Filled
                </StatusPill>
            )
        default:
            throw new Error(`Unknown buy order status: ${status}`)
    }
}

export default SellOrderStatusPill
