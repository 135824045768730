import Stats from '../../../modules/dashboard/components/Stats'

export interface Props {
    title: string
    value: string
    children?: React.ReactNode
}

const PayoutStat = ({ title, value, children }: Props): JSX.Element => {
    return (
        <div className="w-full md:flex-1">
            <Stats title={title} amount={value} additionalClassNames="md:min-h-[162px]">
                {children}
            </Stats>
        </div>
    )
}

export default PayoutStat
