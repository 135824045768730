import { Pagination, ToggleSwitch } from 'flowbite-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { SearchFeatureFlagsResponse } from '../../../../modules/featureFlags/http/types/SearchFeatureFlagsResponse'
import { FeatureFlag } from '../../../../modules/featureFlags/types/FeatureFlag'
import Spinner from '../../../../modules/common/components/Spinner'
import ConfirmationModal from '../../common/components/ConfirmationModal'
import { AdminFeatureFlagApi } from '../http/AdminFeatureFlagApi'
import { Page } from '../../../../modules/common/enums/Pages'
import { generatePathWithQueryString } from '../../user/http/utils/generatePathWithQueryString'

interface FeatureFlagsTableProps {
    searchFeatureFlagsResponse: SearchFeatureFlagsResponse | null
    onPageChangeHandler: (pageNumber: number) => void
}

interface ToggleIsFeatureFlagEnabledProps {
    featureFlag: FeatureFlag
    onChange: () => void
}

const ToggleIsFeatureFlagEnabled = ({
    featureFlag,
    onChange,
}: ToggleIsFeatureFlagEnabledProps): JSX.Element | null => {
    const [isToggleDisabled, setIsToggleDisabled] = useState<boolean>(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)

    const onToggleHandler = async (): Promise<void> => {
        if (isToggleDisabled) return
        setIsToggleDisabled(true)
        await AdminFeatureFlagApi.setIsFeatureFlagEnabled(
            featureFlag.id,
            !featureFlag.enabled_system_wide,
        )
        onChange()
        setIsToggleDisabled(false)
        setShowConfirmationModal(false)
    }
    return (
        <div className="relative flex items-center justify-center min-w-[120px]">
            <ToggleSwitch
                checked={featureFlag.enabled_system_wide}
                label={featureFlag.enabled_system_wide ? 'Enabled' : 'Disabled'}
                onChange={() => setShowConfirmationModal(true)}
            />
            <div className="absolute">{isToggleDisabled && <Spinner />}</div>
            <ConfirmationModal
                show={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={onToggleHandler}
            />
        </div>
    )
}

const FeatureFlagsTable = ({
    searchFeatureFlagsResponse,
    onPageChangeHandler,
}: FeatureFlagsTableProps): JSX.Element => {
    return (
        <div>
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                NAME
                            </th>
                            <th scope="col" className="py-3 px-6">
                                DESCRIPTION
                            </th>
                            <th scope="col" className="py-3 px-6">
                                IS ENABLED
                            </th>
                            <th scope="col" className="py-3 px-6">
                                LAST MODIFIED AT
                            </th>
                            <th scope="col" className="py-3 px-6">
                                ACTION
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchFeatureFlagsResponse?.results.map((featureFlag) => {
                            return (
                                <tr
                                    key={featureFlag?.id}
                                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                >
                                    <td
                                        scope="row"
                                        className="py-4 px-6 font-medium text-gray-900 dark:text-white"
                                    >
                                        {featureFlag.name}
                                    </td>
                                    <td
                                        scope="row"
                                        className="py-4 px-6 font-medium text-gray-900 dark:text-white"
                                    >
                                        {featureFlag.description}
                                    </td>
                                    <td
                                        scope="row"
                                        className="py-4 px-6 font-medium text-gray-900 dark:text-white"
                                    >
                                        <ToggleIsFeatureFlagEnabled
                                            featureFlag={featureFlag}
                                            onChange={() =>
                                                // refresh table
                                                onPageChangeHandler(
                                                    searchFeatureFlagsResponse.pagination
                                                        .current_page,
                                                )
                                            }
                                        />
                                    </td>
                                    <td
                                        scope="row"
                                        className="py-4 px-6 font-medium text-gray-900 dark:text-white"
                                    >
                                        {new Date(featureFlag.updated_at).toLocaleString()}
                                    </td>
                                    <td className="flex-1 py-4 px-6">
                                        <div className="flex gap-1">
                                            <Link
                                                to={generatePathWithQueryString(
                                                    Page.ADMIN_EDIT_FEATURE_FLAG,
                                                    { id: featureFlag.id },
                                                )}
                                                className="w-6 h-6 cursor-pointer flex "
                                                title="Edit"
                                            >
                                                <img
                                                    src="/assets/icon_pencil_alt.svg"
                                                    alt="icon_trash"
                                                />
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-center mt-4">
                {searchFeatureFlagsResponse?.pagination?.total_pages &&
                    searchFeatureFlagsResponse?.pagination?.total_pages > 1 && (
                        <Pagination
                            currentPage={searchFeatureFlagsResponse.pagination.current_page}
                            totalPages={searchFeatureFlagsResponse.pagination.total_pages}
                            onPageChange={onPageChangeHandler}
                        />
                    )}
            </div>
        </div>
    )
}

export default FeatureFlagsTable
