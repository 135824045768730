import { CsvReportResponse } from './types/CsvReportResponse'
import { AxiosResponse } from 'axios'
import HttpService from '../../../../modules/http/httpService'

export interface AdminReportsApiInterface {
    downloadAllTradesReportCsv(): Promise<AxiosResponse<CsvReportResponse>>
    downloadLoginAuditLogs(): Promise<AxiosResponse<Blob>>
    downloadPlatformStats(): Promise<AxiosResponse<Blob>>
}

export const AdminReportsApi: AdminReportsApiInterface = Object.freeze({
    downloadAllTradesReportCsv(): Promise<AxiosResponse<CsvReportResponse>> {
        return HttpService.get('/admin/reports/all-trades-report-csv', null)
    },
    downloadLoginAuditLogs(): Promise<AxiosResponse<Blob>> {
        return HttpService.get('/admin/reports/login-audit-log', null, { responseType: 'blob' })
    },
    downloadPlatformStats(): Promise<AxiosResponse<Blob>> {
        return HttpService.get('/admin/reports/platform-stats', null, { responseType: 'blob' })
    },
})
