import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import SellOrderTable from '../../modules/sellOrders/components/SellOrderTable'
import OrdersSubMenu from './components/OrdersSubMenu'
import { OrderSubMenuActions } from './enums/OrderSubMenuActions'

const SellOrders = (): JSX.Element => {
    return (
        <Layout
            title=""
            subTitle=""
            dataCy="sell-orders-layout"
            selectedNavBarItem={NavBarItemName.ORDERS}
        >
            <OrdersSubMenu selectedItem={OrderSubMenuActions.SELL_ORDERS} />
            <SellOrderTable />
        </Layout>
    )
}

export default SellOrders
