// @ts-nocheck
import { OrderType } from '../../enums/OrderType'
import {
    setSelectedOrderTypeAction,
    setOrdersResponseAction,
    setIsLoadingAction,
    setIsEditModalVisibleAction,
} from './actions'
import { initialMyOrdersState, OrdersReducer } from './reducer'
import { Modal } from './types'
import { createContext, useContext, useReducer } from 'react'

export const MyOrdersStore = createContext(initialMyOrdersState)

export const useOrdersStore = (): any => useContext(MyOrdersStore)

export const MyOrdersStoreProvider = ({ children }: { children: any }): any => {
    const [state, dispatch] = useReducer(OrdersReducer, initialMyOrdersState)

    const setSelectedOrderType = (ordersType: OrderType) => {
        setSelectedOrderTypeAction(ordersType, dispatch)
    }

    const setOrdersResponse = (response: OrdersResponse | null) => {
        setOrdersResponseAction(response, dispatch)
    }

    const setIsLoading = (isLoading: boolean) => {
        setIsLoadingAction(isLoading, dispatch)
    }

    const setModal = (modal: Modal) => {
        setIsEditModalVisibleAction(modal, dispatch)
    }

    return (
        <MyOrdersStore.Provider
            value={{
                state,
                setSelectedOrderType,
                setOrdersResponse,
                setIsLoading,
                setModal,
            }}
        >
            {children}
        </MyOrdersStore.Provider>
    )
}
