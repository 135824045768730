import { SortSellOrderColumns } from '../../../myOrders/context/types'
import { ProjectLocation } from '../../../project/enums/ProjectLocation'
import { ProjectType } from '../../../project/enums/ProjectType'
import { SDG } from '../../../project/enums/SDG'
import { SellOrderStatus } from '../../enums/SelOrderStatus'

export enum SortOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}

export interface SearchSellOrdersParams {
    page?: number
    per_page?: number
    sort_by_column_name?: SortSellOrderColumns | null
    sort_order?: SortOrder
    name?: string
    type?: ProjectType[]
    min_price?: number
    max_price?: number
    location?: ProjectLocation[]
    sdg?: SDG[]
    vintage?: number[]
    count_only?: boolean
    status?: SellOrderStatus
}
