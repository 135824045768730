export enum RegistryIds {
    GLOBAL_CARBON_COUNCIL = '005yuwbp97mxjnklnz',
    GOLD_STANDARD = '005a86q13pgj9c3612',
    VERRA = '0050yd569dw0qbw5oy',
    AMERICAN_CARBON_REGISTRY = '005y75kep1v7snof1x',
    CLIMATE_ACTION_RESERVE = '005oymz2l3624dokjf',
    BIOCARBON_REGISTRY = '005h8koa2ioo0qgq3x',
    CERCARBONO_ECOREGISTRY = '0057qpby5eqk470euc',
    PLAN_VIVO = '005r76093fm1wjqd13',
    UNFCCC_CLEAN_DEVELOPMENT_MECHANISM = '0056bnn0ernrsikdbr',
    PURO = '005yuwbp97mxjnk122',
    CENTIGRADE = '005vgugi19i1pqveb6',
    SOCIAL_CARBON = '005c5sqk87b8fdjtmv',
}
