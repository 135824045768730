import { AxiosResponse } from 'axios'
import { SearchInvoicesParams } from './types/SearchInvoicesParams'
import { SearchInvoicesResponse } from './types/SearchInvoicesResponse'
import HttpService from '../../../../modules/http/httpService'
import { Tenant } from '../../../../modules/tenant/types/Tenant'
import { EmptyResponse } from '../../../../modules/http/types/EmptyResponse'
import { DownloadInvoiceSignedUrlResponse } from './types/DownloadInvoiceSignedUrlResponse'
import { DownloadSignedUrlResponse } from '../../../../modules/http/types/DownloadSignedUrlResponse'
import { AdminInvoiceDto } from './types/AdminInvoiceDto'

export interface AdminInvoiceApiInterface {
    search(params: SearchInvoicesParams): Promise<AxiosResponse<SearchInvoicesResponse> | undefined>
    searchTenantsForInvoices(search: string): Promise<AxiosResponse<Tenant[]>>
    settle(id: string): Promise<AxiosResponse<EmptyResponse> | undefined>
    close(id: string): Promise<AxiosResponse<EmptyResponse> | undefined>
    cancel(id: string): Promise<AxiosResponse<EmptyResponse> | undefined>
    generateInvoiceDownloadSignedUrl(
        invoiceid: string,
    ): Promise<AxiosResponse<DownloadInvoiceSignedUrlResponse> | undefined>
    generatePreSignedURL(id: string): Promise<AxiosResponse<DownloadSignedUrlResponse> | undefined>
    getById(id: string): Promise<AxiosResponse<AdminInvoiceDto> | undefined>
}

export const AdminInvoiceApi: AdminInvoiceApiInterface = Object.freeze({
    search(
        params: SearchInvoicesParams,
    ): Promise<AxiosResponse<SearchInvoicesResponse> | undefined> {
        return HttpService.get('/admin/invoices', null, params)
    },
    searchTenantsForInvoices(search: string): Promise<AxiosResponse<Tenant[]>> {
        return HttpService.get('/admin/invoices/tenants', null, { search: search })
    },
    settle(id: string): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post(`/admin/invoices/${id}/settle`, {})
    },
    close(id: string): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post(`/admin/invoices/${id}/close`, {})
    },
    cancel(id: string): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post(`/admin/invoices/${id}/cancel`, {})
    },
    generateInvoiceDownloadSignedUrl(
        invoiceid: string,
    ): Promise<AxiosResponse<DownloadInvoiceSignedUrlResponse> | undefined> {
        return HttpService.post('/admin/invoices/signed-url', {
            invoice_id: invoiceid,
        })
    },
    generatePreSignedURL(
        id: string,
    ): Promise<AxiosResponse<DownloadSignedUrlResponse> | undefined> {
        return HttpService.get('/admin/invoices/signed-url', id)
    },
    getById(id: string): Promise<AxiosResponse<AdminInvoiceDto> | undefined> {
        return HttpService.get('/admin/invoices', id)
    },
})
