import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../common/components/Button'
import Layout from '../../common/components/Layout'
import { Page } from '../../common/enums/Pages'
import { CookieKey } from '../../cookies/enums/CookieKey'
import { deleteCookie } from '../../cookies/useCookie'
import { useUserStore } from '../../user/context/store'
import { ErrorResponse } from '../../http/types/ErrorResponse'
import { TermsAndConditionApi } from '../http/api'

function TermsAndConditions() {
    const [termsAndConditions, setTermsAndConditions] = useState<string | null>()
    const navigate = useNavigate()
    const { setUser } = useUserStore()

    const signOut = (): void => {
        deleteCookie(CookieKey.JWT_TOKEN)
        deleteCookie(CookieKey.REFRESH_TOKEN)
        setUser(null)
        navigate(Page.SIGNIN)
    }

    const getTermsAndConditions = async () => {
        const response = await TermsAndConditionApi.getTermsAndConditions()
        if (response) {
            const { data } = response
            setTermsAndConditions(data.content)
        }
    }

    const acceptTermsAndConditions = async () => {
        try {
            await TermsAndConditionApi.acceptTermsAndConditions()
            navigate(Page.MARKET)
        } catch (e: unknown) {
            const error = e as AxiosError<ErrorResponse>
            if (error?.response?.status === 422) {
                navigate(Page.MARKET)
                return
            } else {
                throw e
            }
        }
    }

    useEffect(() => {
        void getTermsAndConditions()
    }, [])

    return (
        <Layout renderMenu={false} title="Terms and conditions" subTitle="">
            <div className="whitespace-pre-line" data-testid="tandc-content">
                {termsAndConditions}
            </div>
            <div className="flex justify-end items-center my-6 mr-6 gap-3">
                <div
                    className="px-4 text-primary-700 font-semibold text-sm cursor-pointer flex justify-center"
                    title="Logout"
                    onClick={signOut}
                >
                    I Do Not Accept
                </div>
                <PrimaryButton
                    className="px-8 h-11"
                    onClick={acceptTermsAndConditions}
                    id="tandc-accept"
                >
                    Accept
                </PrimaryButton>
            </div>
        </Layout>
    )
}

export default TermsAndConditions
