interface HamburgerMenuProps {
    isOpen: boolean
    onClick: () => void
}

const HamburgerMenu = ({ isOpen, onClick }: HamburgerMenuProps): JSX.Element => {
    return (
        <div className="cursor-pointer w-6" onClick={onClick}>
            {isOpen ? (
                <img src="/assets/icon_x.svg" alt="close icon" />
            ) : (
                <img src="/assets/hamburger_icon.svg" alt="hamburger icon" />
            )}
        </div>
    )
}

export default HamburgerMenu
