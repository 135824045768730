import ProjectName, { ProjectNameDisplay } from '../../../modules/project/components/ProjectName'
import Location from '../../../modules/project/components/Location'
import AlignMiddle from '../../../modules/common/components/AlignMiddle'
import { MarketProject } from '../types/MarketProject'
import { convertCentsToLocaleUsd } from '../../../utils/conversion'
import RecordField from '../../../modules/recordDetails/components/RecordField'
import { Link } from 'react-router-dom'

interface Props {
    project: MarketProject
}

const getVintageText = (project: MarketProject): string => {
    if (!project.vintages.max) return 'N/A'
    if (project.vintages.max !== project.vintages.min)
        return `${project.vintages.min} - ${project.vintages.max}`
    return `${project.vintages.max}`
}

const MarketCard = ({ project }: Props): JSX.Element => {
    return (
        <div
            className="rounded-lg flex flex-col text-xs gap-1 sm:gap-2 lg:gap-1 w-full sm:w-[32%] lg:w-[24%] xl:min-w-[270px] shadow-md pb-2"
            data-type="market-card"
        >
            <div className="bg-white rounded-lg align-text-bottom">
                <AlignMiddle direction="row" className="mx-auto">
                    <ProjectName
                        squareImageUrl={project.squareImageUrl}
                        type={project.type}
                        name={project.name}
                        id={project.id}
                        sellOrderId={project.sellOrderId}
                        display={ProjectNameDisplay.CARD}
                    />
                </AlignMiddle>
            </div>
            <div className="flex flex-col lg:flex-row min-h-[55px] gap-2 lg:gap-0">
                <div className="px-2 rounded-lg w-full lg:w-1/2 font-semibold">{project.type}</div>
                <div className="px-2 rounded-lg w-full lg:w-1/2 font-semibold">
                    <Location location={project.location} />
                </div>
            </div>
            <div className="flex flex-row">
                <div className="px-2 rounded-lg flex-1">
                    <RecordField label="Vintages">
                        <span className="font-semibold">{getVintageText(project)}</span>
                    </RecordField>
                </div>
                <div className="px-2 rounded-lg flex-1 text-right">
                    <RecordField label="From">
                        <Link
                            className="link font-semibold"
                            to={`/project/${project.id}?sell_order_id=${project.sellOrderId}`}
                        >
                            {convertCentsToLocaleUsd(project.price)}
                        </Link>
                    </RecordField>
                </div>
            </div>
        </div>
    )
}

export default MarketCard
