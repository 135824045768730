import { FeatureFlag } from '../types/FeatureFlag'
import { SetFeatureFlagsAction, SET_FEATURE_FLAGS } from './types'
import { Dispatch } from 'react'

export const setFeatureFlagsAction = (
    featureFlags: FeatureFlag[],
    dispatch: Dispatch<SetFeatureFlagsAction>,
): void => {
    dispatch({
        type: SET_FEATURE_FLAGS,
        payload: { featureFlags },
    })
}
