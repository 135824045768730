import React from 'react'
import { useParams } from 'react-router-dom'
import BackToLogin from '../../modules/common/components/BackToLogin'
import SignInSignUpLayout from '../signup/components/SignInSignUpLayout'
import { IconName } from '../signup/enums/IconName'

const EmailVerification = (): JSX.Element => {
    const { email } = useParams<{ email: string }>()
    return (
        <SignInSignUpLayout
            title="Check your email"
            subtitle={
                <React.Fragment>
                    <span>
                        If an account exists with email{' '}
                        <span className="font-semibold">{email}</span>, a password reset link has
                        been sent.
                    </span>
                </React.Fragment>
            }
            iconName={IconName.EMAIL}
        >
            <div className="w-90 flex flex-col justify-center">
                <div className="h-5 mt-8 mb-8">
                    <BackToLogin />
                </div>
            </div>
        </SignInSignUpLayout>
    )
}

export default EmailVerification
