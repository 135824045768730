import { SDG } from '../enums/SDG'

interface SdgLogoProps {
    sdg: SDG
    className?: string
}

const SdgLogo = ({ sdg, className }: SdgLogoProps): JSX.Element => {
    return (
        <div className={`w-6 h-6 ${className}`}>
            <img className="w-6 h-6" src={`/assets/sdg_icons/${sdg}.svg`} alt={sdg} />
        </div>
    )
}

export default SdgLogo
