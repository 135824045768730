import { RoleName } from '../enums/RoleName'
import { User } from '../types/User'
import { SET_USER, UserAction } from './types'
import * as Sentry from '@sentry/react'

export interface UserState {
    user: User | null
    setUser: (user: User | null) => void
    fetchUser: () => Promise<void>
    checkUserHasAtLeastOneRole: (pageRoles: RoleName[]) => boolean
    isCaasUser: () => boolean
    isCaasProUser: () => boolean
    isCaasOrCaasProUser: () => boolean
    isMarketplaceUser: () => boolean
}

export const initialUserStoreState: UserState = {
    user: null,
    setUser: () => {},
    fetchUser: async () => {},
    checkUserHasAtLeastOneRole: () => false,
    isCaasUser: () => false,
    isCaasProUser: () => false,
    isCaasOrCaasProUser: () => false,
    isMarketplaceUser: () => false,
}

export const userReducer = (state = initialUserStoreState, action: UserAction): UserState => {
    switch (action.type) {
        case SET_USER:
            if (action.payload.user === null) {
                Sentry.setUser(null)
            } else {
                Sentry.setUser({
                    id: action.payload.user?.id,
                    email: action.payload.user?.email,
                })
            }
            return {
                ...state,
                user: action.payload.user,
            }

        default:
            return state
    }
}
