import { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios'
import { RedirectedToLoginScreenError } from './erros/RedirectedToLoginScreenError'
import { TAndCNotAcceptedError } from './erros/TAndCNotAcceptedError'
import { CreateHttpClient } from './httpClient'

const apiService = {
    async get(
        resource: string,
        id: string | null = null,
        queryParams: any = {},
    ): Promise<any | undefined> {
        const apiClient = await CreateHttpClient()
        if (id) {
            resource += '/' + id
        }
        try {
            const response = await apiClient.get(resource, { params: queryParams })
            return response
        } catch (e) {
            if (e instanceof RedirectedToLoginScreenError || e instanceof TAndCNotAcceptedError) {
                return
            } else {
                throw e
            }
        }
    },
    async post(
        resource: string,
        data: any,
        headers: RawAxiosRequestHeaders = {},
        useAuthToken: boolean = true,
    ) {
        try {
            const apiClient = await CreateHttpClient(useAuthToken)
            return apiClient.post(
                resource,
                Object.keys(data).reduce(
                    (acc, key) => (data[key] === undefined ? acc : { ...acc, [key]: data[key] }),
                    {},
                ),
                {
                    headers,
                },
            )
        } catch (e) {
            if (e instanceof RedirectedToLoginScreenError || e instanceof TAndCNotAcceptedError) {
                return
            } else {
                throw e
            }
        }
    },
    async patch(resource: string, data: any = {}) {
        try {
            const apiClient = await CreateHttpClient()
            return apiClient.patch(resource, data)
        } catch (e) {
            if (e instanceof RedirectedToLoginScreenError || e instanceof TAndCNotAcceptedError) {
                return
            } else {
                throw e
            }
        }
    },
    async delete(resource: string, data: any = {}) {
        try {
            const apiClient = await CreateHttpClient()
            return apiClient.delete(resource, {
                data,
            })
        } catch (e) {
            if (e instanceof RedirectedToLoginScreenError || e instanceof TAndCNotAcceptedError) {
                return
            } else {
                throw e
            }
        }
    },
    async put(
        resource: string,
        data: any = {},
        config?: AxiosRequestConfig<any>,
        useAuthToken: boolean = true,
    ) {
        try {
            const apiClient = await CreateHttpClient(useAuthToken)
            return apiClient.put(resource, data, config)
        } catch (e) {
            if (e instanceof RedirectedToLoginScreenError || e instanceof TAndCNotAcceptedError) {
                return
            } else {
                throw e
            }
        }
    },
}

export default apiService
