export enum AdminSubMenuActions {
    PROJECTS,
    USERS,
    CARBON_CREDIT_BALANCES,
    FEATURE_FLAGS,
    INVOICES,
    UNBRIDGE_REQUESTS,
    RETIREMENT_REQUESTS,
    REPORTS,
    SELLER_PAYOUTS,
    BUY_ORDERS,
}
