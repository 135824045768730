import { Page } from '../../../modules/common/enums/Pages'
import SubMenu from '../../../modules/subMenu/components/SubMenu'
import { TabModes } from '../../../modules/subMenu/types/Tab'
import { RoleName } from '../../../modules/user/enums/RoleName'
import { AccountSettingsSubMenuActions } from '../enums/AccountSettingsSubMenuActions'

interface AccountSettingsSubMenuInterface {
    selectedItem: AccountSettingsSubMenuActions
}

const AccountSettingsSubMenu = ({ selectedItem }: AccountSettingsSubMenuInterface): JSX.Element => {
    return (
        <div className="my-6 w-full">
            <SubMenu<AccountSettingsSubMenuActions>
                tabs={[
                    {
                        type: AccountSettingsSubMenuActions.ACCOUNT_DETAILS,
                        text: 'Account Details',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ACCOUNT_SETTINGS_DETAILS,
                        },
                        allowedRoles: [
                            RoleName.CAAS_USER,
                            RoleName.CAAS_PRO,
                            RoleName.MARKETPLACE_USER,
                            RoleName.ADMIN,
                        ],
                    },
                    {
                        type: AccountSettingsSubMenuActions.SECURITY,
                        text: 'Security',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ACCOUNT_SETTINGS_SECURITY,
                        },
                        allowedRoles: [
                            RoleName.CAAS_USER,
                            RoleName.CAAS_PRO,
                            RoleName.MARKETPLACE_USER,
                            RoleName.ADMIN,
                        ],
                    },
                ]}
                selectedItem={selectedItem}
            />
        </div>
    )
}

export default AccountSettingsSubMenu
