import AlignMiddle from '../../../modules/common/components/AlignMiddle'
import { SecondaryButton } from '../../../modules/common/components/Button'

interface Props {
    setShowAskUsForm: (show: boolean) => void
}

const AskUsCard = ({ setShowAskUsForm }: Props): JSX.Element => {
    return (
        <div
            id="ask-us-card"
            className="rounded-lg flex flex-col text-xs gap-1 sm:gap-2 lg:gap-1 w-full sm:w-[32%] lg:w-[24%] shadow-md md:min-w-[170px] lg:min-w-[230px] xl:min-w-[270px] min-h-[418px]"
        >
            <div className="rounded-lg align-text-bottom w-full aspect-square bg-patterned">
                <AlignMiddle direction="column" className="w-full aspect-square">
                    <AlignMiddle direction="row" className="">
                        <img src="/assets/logo_stacked.svg" className="h-20 w-20" />
                    </AlignMiddle>
                </AlignMiddle>
            </div>
            <div className="flex flex-col h-full py-2 gap-2">
                <div className="w-full font-semibold text-center flex flex-col gap-4 px-2 text-wrap whitespace-break-spaces">
                    <div className="">Can't find your perfect project?</div>
                    <div>Let us source it for you.</div>
                </div>
                <AlignMiddle direction="column" className="h-full">
                    <AlignMiddle direction="row" className="w-full">
                        <SecondaryButton
                            onClick={() => {
                                setShowAskUsForm(true)
                            }}
                            className="text-wrap whitespace-break-spaces"
                        >
                            Find Your Perfect Project
                        </SecondaryButton>
                    </AlignMiddle>
                </AlignMiddle>
            </div>
        </div>
    )
}

export default AskUsCard
