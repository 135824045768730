import { useUserStore } from '../../user/context/store'

const UserFullName = (): JSX.Element => {
    const { user } = useUserStore()
    return (
        <div className="px-3">
            <p className="text-base tex-gray-600">{`${user?.first_name} ${user?.last_name}`}</p>
        </div>
    )
}

export default UserFullName
