import StatusPill, { CircleStyle, Colour } from '../../statusPill/components/StatusPill'
import { RetirementStatuses } from '../enums/RetirementStatuses'

interface Props {
    status: RetirementStatuses
}

const RetirementStatusPill = ({ status }: Props): JSX.Element => {
    switch (status) {
        case RetirementStatuses.OPEN:
            return (
                <StatusPill colour={Colour.BLUE} circleStyle={CircleStyle.NOT_FILLED}>
                    Open
                </StatusPill>
            )
        case RetirementStatuses.CLOSED:
        case RetirementStatuses.IN_PROGRESS:
            return (
                <StatusPill colour={Colour.YELLOW} circleStyle={CircleStyle.SEMI_FILLED}>
                    Pending
                </StatusPill>
            )
        case RetirementStatuses.COMPLETE:
            return (
                <StatusPill colour={Colour.GREEN} circleStyle={CircleStyle.FILLED}>
                    Complete
                </StatusPill>
            )
        case RetirementStatuses.CANCELLED:
            return (
                <StatusPill colour={Colour.RED} circleStyle={CircleStyle.NOT_FILLED}>
                    Cancelled
                </StatusPill>
            )
        case RetirementStatuses.NOTHING_TO_PROCESS:
            return (
                <StatusPill colour={Colour.GREY} circleStyle={CircleStyle.NOT_FILLED}>
                    Empty
                </StatusPill>
            )
        default:
            throw new Error(`Unknown retirement status: ${status}`)
    }
}

export default RetirementStatusPill
