import { useForm } from 'react-hook-form'
import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import { FormElement } from '../../modules/form/types/Field'
import { PrimaryButton } from '../../modules/common/components/Button'
import { Button } from '../../modules/form/types/Button'
import Form from '../../modules/form/components/Form'
import { useEffect, useState } from 'react'
import { UserApi } from '../../modules/user/http/UserApi'
import { generateUniqueId } from '../../utils/generateUniqueId'
import { useNavigate } from 'react-router-dom'
import { ToastMessageType } from '../../modules/toastMessage/enums/ToastMessageType'
import { Page } from '../../modules/common/enums/Pages'
import { AxiosError } from 'axios'
import { useToastMessageStore } from '../../modules/toastMessage/context/store'

interface Props {}

const ContactSupport = ({}: Props): JSX.Element => {
    const [requestId, setRequestId] = useState<string | null>(null)
    const form = useForm<FormData>()
    const navigate = useNavigate()
    const { setShowToastMessage } = useToastMessageStore()

    useEffect(() => {
        setRequestId(generateUniqueId())
    }, [])

    const handleOnSubmit = async () => {
        //send api request
        if (!requestId) {
            throw new Error('Unable to send request to support - requestId is falsey')
        }
        try {
            await UserApi.sendEmailSupportRequest(
                { question: form.getValues('question') },
                requestId,
            )
            setShowToastMessage({
                text: 'Thank you! We will respond to you shortly.',
                type: ToastMessageType.SUCCESS,
            })
            navigate(Page.MARKET)
        } catch (err) {
            if (err instanceof AxiosError && err?.response?.status === 400) {
                setShowToastMessage({
                    text: err?.response?.data?.message,
                    type: ToastMessageType.ERROR,
                })
                return
            }
            throw err
        }
    }

    type FormData = {
        question: string
    }

    const fields: FormElement<FormData>[] = [
        {
            name: 'question',
            displayName: 'Question',
            type: 'textarea',
            rows: 8,
            options: {
                required: {
                    message: 'Please enter the deatils of your question or comment',
                    value: true,
                },
                minLength: {
                    message: 'Please enter 10 characters or more',
                    value: 10,
                },
                maxLength: {
                    message: 'Please enter less than 10,000 characters',
                    value: 10_000,
                },
            },
        },
    ]

    const buttons: Button[] = [
        {
            type: 'submit',
            id: 'submit-question',
            variation: PrimaryButton,
            label: 'Send',
        },
    ]

    return (
        <Layout
            title="Contact Support"
            subTitle="Send your question to the Thallo team"
            dataCy="contact-support-layout"
            selectedNavBarItem={NavBarItemName.NONE}
        >
            <Form
                onSubmit={() => {
                    handleOnSubmit()
                }}
                formId="contact-support-form"
                fields={fields}
                buttons={buttons}
                form={form}
                wrapperClassName=""
                formClassName="max-w-[600px]"
                outerWrapperClassName="mb-6"
                buttonInnerWrapperClassName="justify-end"
            />
        </Layout>
    )
}

export default ContactSupport
