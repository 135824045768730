import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { EmptyResponse } from '../../http/types/EmptyResponse'
import { RequestUnbridgePayload } from './types/RequestUnbridgePayload'
import { SearchUnbridgeParams } from './types/SearchUnbridgeParams'
import { SearchResponse } from './types/SearchResponse'

export interface UnbridgeRequestApiInterface {
    requestUnbridge(
        payload: RequestUnbridgePayload,
        requestId: string,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
    search(params: SearchUnbridgeParams): Promise<AxiosResponse<SearchResponse> | undefined>
}

export const UnbridgeRequestApi: UnbridgeRequestApiInterface = Object.freeze({
    requestUnbridge(
        payload: RequestUnbridgePayload,
        requestId: string,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('unbridge-request/request', payload, { 'request-id': requestId })
    },
    search(params: SearchUnbridgeParams): Promise<AxiosResponse<SearchResponse> | undefined> {
        return HttpService.get('unbridge-request', null, params)
    },
})
