import { CarbonCreditBalanceSingleResult } from '../../carbonCreditBalance/types/CarbonCreditBalance'
import RecordField from '../../recordDetails/components/RecordField'
import RegistryIcon from '../../registry/components/RegistryIcon'
import { Project } from '../types/Project'
import Location from './Location'
import Sdgs from './Sdgs'

interface ProjectDetailsTableProps {
    project: Project | CarbonCreditBalanceSingleResult
}

const ProjectDetailsTable = ({ project }: ProjectDetailsTableProps): JSX.Element => {
    return (
        <div className="flex flex-col gap-2">
            <div className="grid grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-4">
                <RecordField label="Type">
                    <div className="text-sm">{project.type}</div>
                </RecordField>
                <RecordField label="Location">
                    <div className="text-sm">
                        <Location location={project.location} />
                    </div>
                </RecordField>
                <RecordField label="Registry">
                    <div className="text-sm">
                        <RegistryIcon
                            registryId={project.registry.id}
                            registryLink={project.registry_url}
                        />
                    </div>
                </RecordField>
                <div className="flex-1"></div>
            </div>
            <div className="flex flex-row justify-between">
                {project.sdgs.length > 0 && (
                    <RecordField label="SDGs">
                        <div className="text-sm">
                            <Sdgs
                                sdgs={project.sdgs}
                                id={`${project.id}_details_sdgs`}
                                numberOfSdgsToShow={17}
                            />
                        </div>
                    </RecordField>
                )}
            </div>
        </div>
    )
}

export default ProjectDetailsTable
