import { AxiosError } from 'axios'
import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Select, { SingleValue } from 'react-select'
import { ErrorResponse } from '../../http/types/ErrorResponse'
import { SecondaryButton, PrimaryButton } from '../../common/components/Button'
import { Locations } from '../../common/enums/Locations'
import { UserApi } from '../http/UserApi'
import { FormElement } from '../../form/types/Field'
import { Button } from '../../form/types/Button'
import Form from '../../form/components/Form'

interface Props {
    onConfirm: (name: string, id: string | null) => void
    onClose: () => void
    setError: (error: string | null) => void
}

interface FormData {
    retireeId: string | null
    retireeName: string | null
    retireeTaxId: string | null
    retireeLocation: Locations | null
}

const CreateSubCustomerForm = ({ onConfirm, onClose, setError }: Props): JSX.Element => {
    const baseForm = useForm<FormData>()
    const [showSpinner, setShowSpinner] = useState<boolean>(false)

    const options = Object.entries(Locations).map(([country, code]) => {
        return {
            value: code,
            label: country,
        }
    })

    const createSubCustomer = async (form: FormData) => {
        try {
            await UserApi.createSubCustomer({
                external_customer_id: form.retireeId || undefined,
                external_customer_name: form.retireeName!,
                external_customer_tax_id: form.retireeTaxId!,
                external_customer_country: form.retireeLocation!,
            })
            onConfirm(form.retireeName!, form.retireeId)
            setError(null)
            baseForm.reset()
        } catch (e: any) {
            const error = e as AxiosError<ErrorResponse>
            if (error.response?.data.message && Array.isArray(error.response?.data.message)) {
                const validationErrors = error.response?.data.message
                setError(validationErrors.join('\n'))
            } else if (
                error.response?.data.message &&
                !Array.isArray(error.response?.data.message)
            ) {
                setError(error.response?.data.message)
            } else {
                setError('An unexpected error has occurred, please try again')
            }
        }
    }

    const onSubmitHandler = async (): Promise<void> => {
        try {
            setError(null)
            setShowSpinner(true)
            await createSubCustomer(baseForm.getValues())
            setShowSpinner(false)
        } catch (e) {
            setError('An unexpected error has occurred, please refresh the page and try again')
            setShowSpinner(false)
        }
    }

    const fields: FormElement<FormData>[] = [
        {
            name: 'retireeName',
            displayName: 'Retiree Name',
            type: 'text',
            required: true,
            id: 'retiree-name-create',
        },
        {
            name: 'retireeTaxId',
            displayName: 'Retiree Tax Id',
            type: 'text',
            required: true,
            id: 'retiree-tax-id-create',
        },
        {
            name: 'retireeLocation',
            displayName: 'Retiree Location',
            type: 'select',
            required: true,
            inputOverride: (
                <Controller
                    name="retireeLocation"
                    control={baseForm.control}
                    render={({ field }) => {
                        return (
                            <Select
                                className="flex-1"
                                value={
                                    options.find((option) => option.value === field.value) || {
                                        value: '' as Locations,
                                        label: '',
                                    }
                                }
                                options={options}
                                onChange={(
                                    newValue: SingleValue<{
                                        value: Locations
                                        label: string
                                    }>,
                                ) => {
                                    field.onChange(newValue?.value || null)
                                }}
                                inputId="retiree-location-create"
                            />
                        )
                    }}
                    rules={{
                        required: true,
                        validate: (value) => {
                            return options.some((option) => option.value === value)
                        },
                    }}
                    defaultValue={null}
                />
            ),
        },
        {
            name: 'retireeId',
            displayName: 'Retiree Id',
            type: 'text',
            required: false,
            id: 'retiree-id-create',
            tooltip:
                'Your internal ID for this customer. If one is not provided, Thallo will generate one for you.',
        },
    ]

    const buttons: Button[] = [
        {
            type: 'button',
            label: 'Back',
            onClick: () => {
                baseForm.reset()
                setError(null)
                onClose()
            },
            disabled: showSpinner,
            variation: SecondaryButton,
            id: 'back-create-sub-customer',
        },
        {
            type: 'submit',
            label: 'Create Retiree',
            id: 'submit-create-sub-customer',
            disabled: !baseForm.formState.isValid,
            className: 'flex-1',
            variation: PrimaryButton,
        },
    ]

    return (
        <>
            <Form
                title="Create Retiree"
                onSubmit={onSubmitHandler}
                formId="create-sub-customer-form"
                fields={fields}
                headerListItems={[]}
                buttons={buttons}
                buttonWrapperClassName="order-1 w-2/3"
                form={baseForm}
            />
        </>
    )
}

export default CreateSubCustomerForm
