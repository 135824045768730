import { ProjectLocation } from '../../project/enums/ProjectLocation'
import { ProjectType } from '../../project/enums/ProjectType'
import { SDG } from '../../project/enums/SDG'
import { SearchCarbonCreditBalancesResponse } from '../http/types/SearchCarbonCreditBalancesResponse'
import {
    CarbonCreditFiltersAction,
    SET_IS_CARBON_CREDIT_FILTERS_MODAL_VISIBLE,
    SET_IS_FORM_DISABLED,
    SET_SEARCH_RESPONSE,
    SET_SELECTED_FILTERS,
    SET_PROJECT_TYPE_FILTER_VISIBLE,
    SET_SHOW_ALL_LOCATIONS,
    SET_SHOW_ALL_SDGS,
} from './types'

export interface SelectedFilters {
    projectName: string
    projectType: ProjectType[]
    locations: ProjectLocation[]
    sdgs: SDG[]
    vintages: number[]
}

export const defaultCarbonCreditsFormFilters: SelectedFilters = {
    projectName: '',
    projectType: [],
    locations: [],
    sdgs: [],
    vintages: [],
}

export interface CarbonCreditFiltersState {
    searchResponse: SearchCarbonCreditBalancesResponse | null
    isProjectTypeFilterVisible: boolean
    selectedFilters: SelectedFilters
    isFormDisabled: boolean
    isFiltersModalVisible: boolean
    showAllLocations: boolean
    showAllSdgs: boolean
}

export const initialCarbonCreditFiltersStoreState: CarbonCreditFiltersState = {
    searchResponse: null,
    isProjectTypeFilterVisible: false,
    selectedFilters: defaultCarbonCreditsFormFilters,
    isFormDisabled: false,
    isFiltersModalVisible: false,
    showAllLocations: false,
    showAllSdgs: false,
}

export const carbonCreditFiltersReducer = (
    state = initialCarbonCreditFiltersStoreState,
    action: CarbonCreditFiltersAction,
): CarbonCreditFiltersState => {
    switch (action.type) {
        case SET_IS_CARBON_CREDIT_FILTERS_MODAL_VISIBLE:
            return {
                ...state,
                isFiltersModalVisible: action.payload.isFiltersModalVisible,
            }
        case SET_IS_FORM_DISABLED:
            return {
                ...state,
                isFormDisabled: action.payload.isDisabled,
            }
        case SET_SEARCH_RESPONSE:
            return {
                ...state,
                searchResponse: action.payload.response,
            }
        case SET_SELECTED_FILTERS:
            return {
                ...state,
                selectedFilters: action.payload.filters,
            }
        case SET_PROJECT_TYPE_FILTER_VISIBLE:
            return {
                ...state,
                isProjectTypeFilterVisible: action.payload.isVisible,
            }
        case SET_SHOW_ALL_LOCATIONS:
            return {
                ...state,
                showAllLocations: action.payload.show,
            }
        case SET_SHOW_ALL_SDGS:
            return {
                ...state,
                showAllSdgs: action.payload.show,
            }
        default:
            return state
    }
}
