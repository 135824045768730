import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import { Link, useParams } from 'react-router-dom'
import SubSection from '../../modules/common/components/SubSection'
import RecordField from '../../modules/recordDetails/components/RecordField'
import { convertCentsToLocaleUsd } from '../../utils/conversion'
import { Fragment, useEffect, useState } from 'react'
import DateTimeFormatted from '../../modules/dateTime/components/DateTimeFormatted'
import LoadingSpinner from '../../modules/loadingSpinner/components/LoadingSpinner'
import Note, { NoteType } from '../../modules/note/components/Note'
import * as Sentry from '@sentry/react'
import { PayoutApi } from '../../modules/payout/http/PayoutApi'
import { PayoutWithTradesDto } from '../../modules/payout/http/types/PayoutWithTradesDto'
import PayoutStatusPill from '../../modules/payout/components/PayoutStatusPill'
import PayoutTradesTable from '../../modules/payout/components/PayoutTradesTable'

const Payout = (): JSX.Element => {
    const [payout, setPayout] = useState<PayoutWithTradesDto>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string | null>(null)
    const { id } = useParams()

    const getRecord = async (): Promise<void> => {
        try {
            setErrMsg(null)
            setIsLoading(true)
            setPayout((await PayoutApi.getById(id!)).data)
            setIsLoading(false)
        } catch (err) {
            setErrMsg(
                'There was a problem loading your payout. Please refresh the page to try again.',
            )
            setIsLoading(false)
            Sentry.captureException(err)
        }
    }

    useEffect(() => {
        getRecord()
    }, [id])

    return (
        <Layout
            title="Payout"
            subTitle={payout ? payout.id : ''}
            dataCy="payout-layout"
            selectedNavBarItem={NavBarItemName.ORDERS}
            topLeftComponent={
                <Link className="link text-sm" to="/orders/payouts">
                    {'<< All Payouts'}
                </Link>
            }
        >
            <>
                {isLoading && <LoadingSpinner></LoadingSpinner>}
                {errMsg && <Note type={NoteType.ERROR}>{errMsg}</Note>}
                {payout && !isLoading && !errMsg && (
                    <Fragment>
                        <div className="grid grid-rows-4 md:grid-cols-4 md:grid-rows-1 gap-4">
                            <RecordField label="Status">
                                <PayoutStatusPill status={payout.status} />
                            </RecordField>
                            <RecordField label="Date">
                                <DateTimeFormatted date={new Date(payout.created_at)} />
                            </RecordField>
                            <RecordField label="Total">
                                <div className="font-bold">
                                    {convertCentsToLocaleUsd(payout.total)}
                                </div>
                            </RecordField>
                        </div>
                        <SubSection title="Trades">
                            <PayoutTradesTable trades={payout.trades} />
                        </SubSection>
                    </Fragment>
                )}
                {!payout && !isLoading && !errMsg && (
                    <Note type={NoteType.INFO}>No payout found</Note>
                )}
            </>
        </Layout>
    )
}

export default Payout
