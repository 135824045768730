import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import InvoicesTable from '../../modules/invoice/components/InvoicesTable'
import OrdersSubMenu from './components/OrdersSubMenu'
import { OrderSubMenuActions } from './enums/OrderSubMenuActions'

const Invoices = (): JSX.Element => {
    return (
        <Layout
            title=""
            subTitle=""
            dataCy="invoices-layout"
            selectedNavBarItem={NavBarItemName.ORDERS}
        >
            <OrdersSubMenu selectedItem={OrderSubMenuActions.INVOICES} />
            <InvoicesTable />
        </Layout>
    )
}

export default Invoices
