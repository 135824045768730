import { useState } from 'react'
import { SecondaryButton } from './Button'
import ImageDownload from './ImageDownload'

export interface Props {
    url: string
    heightClassName: string
    widthClassName: string
    overlayClassNames?: string
}

const DownloadableImage = ({
    url,
    heightClassName = 'h-[351px]',
    widthClassName = 'w-[544px]',
    overlayClassNames,
}: Props): JSX.Element => {
    const [copyBtnText, setCopyBtnText] = useState<string>('Copy Link')

    return (
        <div className={`${widthClassName} ${heightClassName} relative`}>
            <div
                className="absolute inset-0 bg-cover bg-center z-0"
                style={{
                    backgroundImage: `url('${url}')`,
                }}
            ></div>
            <div
                className={`opacity-0 hover:opacity-100 duration-200 
                absolute inset-0 flex justify-center items-center 
                bg-gray-300 bg-opacity-50 gap-4 ${overlayClassNames}`}
            >
                <SecondaryButton clickFirstChild={true}>
                    <ImageDownload url={url}>Download</ImageDownload>
                </SecondaryButton>
                <SecondaryButton
                    onClick={() => {
                        navigator.clipboard.writeText(url)
                        setCopyBtnText('Copied!')
                        setTimeout(() => {
                            setCopyBtnText('Copy Link')
                        }, 2000)
                    }}
                >
                    {copyBtnText}
                </SecondaryButton>
            </div>
        </div>
    )
}

export default DownloadableImage
