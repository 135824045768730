import StatusPill, { CircleStyle, Colour } from '../../statusPill/components/StatusPill'
import { PayoutStatus } from '../enums/PayoutStatus'

interface Props {
    status: PayoutStatus
}

const PayoutStatusPill = ({ status }: Props): JSX.Element => {
    switch (status) {
        case PayoutStatus.UNPAID:
            return (
                <StatusPill colour={Colour.YELLOW} circleStyle={CircleStyle.SEMI_FILLED}>
                    Pending
                </StatusPill>
            )
        case PayoutStatus.PAID:
            return (
                <StatusPill colour={Colour.GREEN} circleStyle={CircleStyle.FILLED}>
                    Complete
                </StatusPill>
            )
        default:
            throw new Error(`Unknown payout status: ${status}`)
    }
}

export default PayoutStatusPill
