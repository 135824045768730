import { FeatureFlagName } from '../../common/enums/FeatureFlagName'
import React from 'react'
import { useFeatureFlagStore } from '../context/store'

interface IsFeatureEnabledHocProps {
    featureFlagname: FeatureFlagName
    children: JSX.Element | JSX.Element[]
}

const IsFeatureEnabledHoc = ({
    featureFlagname,
    children,
}: IsFeatureEnabledHocProps): JSX.Element | null => {
    const { isFeatureFlagEnabled } = useFeatureFlagStore()
    const isEnabled = isFeatureFlagEnabled(featureFlagname)
    if (isEnabled) {
        return <React.Fragment>{children}</React.Fragment>
    }
    return null
}

export default IsFeatureEnabledHoc
