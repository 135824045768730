import { Page } from '../../../../modules/common/enums/Pages'
import SubMenu from '../../../../modules/subMenu/components/SubMenu'
import { TabModes } from '../../../../modules/subMenu/types/Tab'
import { RoleName } from '../../../../modules/user/enums/RoleName'
import { AdminSubMenuActions } from '../enums/AdminSubMenuActions'

interface Props {
    selectedItem: AdminSubMenuActions
}

const AdminSubMenu = ({ selectedItem }: Props): JSX.Element => {
    return (
        <div className="my-6">
            <SubMenu<AdminSubMenuActions>
                tabs={[
                    {
                        type: AdminSubMenuActions.PROJECTS,
                        text: 'Projects',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ADMIN_PROJECTS,
                        },
                        allowedRoles: [RoleName.ADMIN],
                    },
                    {
                        type: AdminSubMenuActions.USERS,
                        text: 'Users',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ADMIN_USERS,
                        },
                        allowedRoles: [RoleName.ADMIN],
                    },
                    {
                        type: AdminSubMenuActions.CARBON_CREDIT_BALANCES,
                        text: 'CC Balances',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ADMIN_CARBON_CREDIT_BALANCES,
                        },
                        allowedRoles: [RoleName.ADMIN],
                    },
                    {
                        type: AdminSubMenuActions.FEATURE_FLAGS,
                        text: 'Feature Flags',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ADMIN_FEATURE_FLAG,
                        },
                        allowedRoles: [RoleName.ADMIN],
                    },
                    {
                        type: AdminSubMenuActions.INVOICES,
                        text: 'Invoices',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ADMIN_INVOICES,
                        },
                        allowedRoles: [RoleName.ADMIN],
                    },
                    {
                        type: AdminSubMenuActions.UNBRIDGE_REQUESTS,
                        text: 'Unbridge Requests',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ADMIN_UNBRIDGE_REQUESTS,
                        },
                        allowedRoles: [RoleName.ADMIN],
                    },
                    {
                        type: AdminSubMenuActions.RETIREMENT_REQUESTS,
                        text: 'Retirement Requests',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ADMIN_RETIREMENT_REQUESTS,
                        },
                        allowedRoles: [RoleName.ADMIN],
                    },
                    {
                        type: AdminSubMenuActions.REPORTS,
                        text: 'Reports',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ADMIN_REPORTS,
                        },
                        allowedRoles: [RoleName.ADMIN],
                    },
                    {
                        type: AdminSubMenuActions.SELLER_PAYOUTS,
                        text: 'Seller Payouts',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ADMIN_PAYOUTS,
                        },
                        allowedRoles: [RoleName.ADMIN],
                    },
                    {
                        type: AdminSubMenuActions.BUY_ORDERS,
                        text: 'Buy Orders',
                        mode: TabModes.link,
                        linkData: {
                            url: Page.ADMIN_BUY_ORDERS,
                        },
                        allowedRoles: [RoleName.ADMIN],
                    },
                ]}
                selectedItem={selectedItem}
            />
        </div>
    )
}

export default AdminSubMenu
