import { DateTime } from 'luxon'
import { CookieKey } from './enums/CookieKey'
import { CookieOptions } from './types/CookieOptions'
import { useState } from 'react'
import { ConfigFactory } from '../../config/ConfigFactory'

export function stringifyOptions(options: any): string {
    return Object.keys(options).reduce((acc: string, key: string) => {
        if (options[key] === false) {
            return acc
        } else if (options[key] === true) {
            return `${acc}; ${key}`
        } else {
            return `${acc}; ${key}=${options[key]}`
        }
    }, '')
}

export const getCookie = (name: CookieKey) => {
    return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        if (parts[0] === name) {
            return decodeURIComponent(parts[1])
        }
        return r
    }, '')
}

export const setCookie = <T>(key: CookieKey, value: T, options: CookieOptions = {}): void => {
    const defaultExpiresIn = DateTime.utc().plus({ minutes: 30 }).toJSDate().toUTCString()

    document.cookie = `${key}=${value}${stringifyOptions({
        expires: defaultExpiresIn,
        path: '/',
        secure: ConfigFactory.getConfig().secureCookies,
        sameSite: true,
        ...options,
    })}`
}

export const deleteCookie = (name: CookieKey) => {
    document.cookie = `${name}=;${stringifyOptions({
        expires: 'Thu, 01 Jan 1970 00:00:01 GMT;`',
        path: '/',
        secure: ConfigFactory.getConfig().secureCookies,
        sameSite: true,
    })}`
}

function useCookies<T>(
    key: CookieKey,
    initialValue: T,
    options: CookieOptions = {},
): readonly [T, (input: T) => void] {
    const [cookieValue, setCookieValue] = useState<T>((): T => {
        try {
            const item = getCookie(key)
            // @ts-ignore
            return item ? item : initialValue
        } catch {
            return initialValue
        }
    })

    const setValue = (value: T): void => {
        setCookieValue(value)
        setCookie(key, value, options)
    }

    return [cookieValue, setValue]
}

export default useCookies
