import { useState } from 'react'
import { Link } from 'react-router-dom'
import { AdminCarbonCreditBalanceApi } from '../http/api'
import { Pagination } from 'flowbite-react'
import { useAdminStore } from '../../../../modules/admin/context/store'
import { CarbonCreditBalance } from '../../../../modules/carbonCreditBalance/types/CarbonCreditBalance'
import { Page } from '../../../../modules/common/enums/Pages'
import { TenantType } from '../../../../modules/tenant/enums/TenantType'
import { convertGramsToMetricTonesLocaleString } from '../../../../utils/conversion'
import ConfirmationModal from '../../common/components/ConfirmationModal'
import { generatePathWithQueryString } from '../../user/http/utils/generatePathWithQueryString'
import { AdminSearchCarbonCreditBalancesResponse } from '../http/types/AdminSearchCarbonCreditBalancesResponse'

interface CarbonCreditBalancesTableProps {
    searchCarbonCreditsResponse: AdminSearchCarbonCreditBalancesResponse | null
    onPageChangeHandler: (pageNumber: number) => void
    onItemDeleted: () => void
}

const CarbonCreditBalancesTable = ({
    searchCarbonCreditsResponse,
    onPageChangeHandler,
    onItemDeleted,
}: CarbonCreditBalancesTableProps): JSX.Element => {
    const { setShowAlertMessage } = useAdminStore()
    const [
        showDeleteConfirmationModalForCarbonCreditBalance,
        setShowDeleteConfirmationModalForCarbonCreditBalance,
    ] = useState<CarbonCreditBalance | null>(null)
    const onDeleteSellOrderHandler = async (): Promise<void> => {
        if (showDeleteConfirmationModalForCarbonCreditBalance) {
            try {
                await AdminCarbonCreditBalanceApi.delete(
                    showDeleteConfirmationModalForCarbonCreditBalance?.id,
                )
                setShowDeleteConfirmationModalForCarbonCreditBalance(null)
                onItemDeleted()
                setShowAlertMessage({
                    color: 'success',
                    text: 'Successfully deleted',
                })
            } catch (e) {
                setShowAlertMessage({
                    color: 'failure',
                    text: 'An unexpected error has occurred, please try again',
                })
                throw e
            }
        }
    }

    const tableData: any = searchCarbonCreditsResponse?.results.reduce(
        (acc: { [key: string]: any }, current) => {
            const key = current.tenant.id
            const elements = acc[key] ?? []
            return {
                ...acc,
                [current.tenant.id]: [...elements, current],
            }
        },
        {},
    )

    return (
        <div>
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                PROJECT NAME
                            </th>
                            <th scope="col" className="py-3 px-6">
                                VINTAGE
                            </th>
                            <th scope="col" className="py-3 px-6">
                                AVAILABLE BALANCE
                            </th>
                            <th scope="col" className="py-3 px-6">
                                AVAILABLE CAAS BALANCE
                            </th>
                            <th scope="col" className="py-3 px-6">
                                SOLD
                            </th>
                            <th scope="col" className="py-3 px-6">
                                FOR SALE
                            </th>
                            <th scope="col" className="py-3 px-6">
                                RETIRED
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    {Object.keys(tableData ?? []).map((key: string) => {
                        return (
                            <tbody key={key}>
                                <tr>
                                    <td colSpan={7} className="bg-gray-200 pl-5">
                                        {`Tenant ID: ${tableData[key][0]?.tenant.id ?? ''}   `}
                                        {tableData[key][0]?.tenant.entity_name
                                            ? `Entity Name: ${tableData[key][0]?.tenant.entity_name}`
                                            : `User: ${tableData[key][0]?.tenant?.users[0]?.first_name} ${tableData[key][0]?.tenant?.users[0]?.last_name} - ${tableData[key][0]?.tenant?.users[0]?.email}`}
                                    </td>
                                </tr>
                                {tableData[key].map((carbonCreditBalance: CarbonCreditBalance) => {
                                    return (
                                        <tr
                                            key={carbonCreditBalance?.id}
                                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                        >
                                            <th
                                                scope="row"
                                                className="py-4 px-6 font-medium text-gray-900 dark:text-white"
                                            >
                                                {carbonCreditBalance?.vintage?.project?.name}
                                            </th>
                                            <td
                                                scope="row"
                                                className="py-4 px-6 font-medium text-gray-900 dark:text-white"
                                            >
                                                {carbonCreditBalance?.vintage?.vintage}
                                            </td>
                                            <td
                                                scope="row"
                                                className="py-4 px-6 font-medium text-gray-900 dark:text-white"
                                            >
                                                {convertGramsToMetricTonesLocaleString(
                                                    Number(carbonCreditBalance?.liquid_amount),
                                                )}
                                            </td>
                                            <td
                                                scope="row"
                                                className="py-4 px-6 font-medium text-gray-900 dark:text-white"
                                            >
                                                {convertGramsToMetricTonesLocaleString(
                                                    Number(carbonCreditBalance?.caas_liquid_amount),
                                                )}
                                            </td>
                                            <td
                                                scope="row"
                                                className="py-4 px-6 font-medium text-gray-900 dark:text-white"
                                            >
                                                {convertGramsToMetricTonesLocaleString(
                                                    Number(carbonCreditBalance?.sold_amount),
                                                )}
                                            </td>
                                            <td
                                                scope="row"
                                                className="py-4 px-6 font-medium text-gray-900 dark:text-white"
                                            >
                                                {convertGramsToMetricTonesLocaleString(
                                                    Number(carbonCreditBalance?.sell_order_amount),
                                                )}
                                            </td>
                                            <td
                                                scope="row"
                                                className="py-4 px-6 font-medium text-gray-900 dark:text-white"
                                            >
                                                {convertGramsToMetricTonesLocaleString(
                                                    Number(carbonCreditBalance?.retired_amount),
                                                )}
                                            </td>
                                            <td className="flex-1 py-4 px-6">
                                                <div className="flex gap-1">
                                                    <Link
                                                        to={generatePathWithQueryString(
                                                            Page.ADMIN_EDIT_CARBON_CREDIT_BALANCE,
                                                            { id: carbonCreditBalance.id },
                                                        )}
                                                        className="w-6 h-6 cursor-pointer flex "
                                                        title="Edit"
                                                    >
                                                        <img
                                                            src="/assets/icon_pencil_alt.svg"
                                                            alt="icon_trash"
                                                        />
                                                    </Link>
                                                    <button
                                                        className="w-6 h-6 cursor-pointer flex "
                                                        title="Delete"
                                                        onClick={() =>
                                                            setShowDeleteConfirmationModalForCarbonCreditBalance(
                                                                carbonCreditBalance,
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src="/assets/icon_trash.svg"
                                                            alt="icon_trash"
                                                        />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        )
                    })}
                </table>
            </div>
            <div className="flex justify-center mt-4">
                {searchCarbonCreditsResponse?.pagination && (
                    <Pagination
                        currentPage={searchCarbonCreditsResponse.pagination.current_page}
                        totalPages={searchCarbonCreditsResponse.pagination.total_pages}
                        onPageChange={onPageChangeHandler}
                    />
                )}
            </div>
            <ConfirmationModal
                show={!!showDeleteConfirmationModalForCarbonCreditBalance}
                onClose={() => setShowDeleteConfirmationModalForCarbonCreditBalance(null)}
                onConfirm={onDeleteSellOrderHandler}
                question={
                    <span>
                        <div>
                            Delete Carbon Credit Balance:
                            <span className="pl-1 font-semibold text-gray-700">
                                {
                                    showDeleteConfirmationModalForCarbonCreditBalance?.vintage
                                        .project.name
                                }
                            </span>
                        </div>
                        <div>
                            <span> Vintage: </span>
                            <span className="pl-1 font-semibold text-gray-700">
                                {showDeleteConfirmationModalForCarbonCreditBalance?.vintage.vintage}
                            </span>
                        </div>
                        <div>
                            <span>
                                {showDeleteConfirmationModalForCarbonCreditBalance?.tenant.type ===
                                TenantType.ENTITY
                                    ? ' Entity: '
                                    : ' User:'}
                            </span>
                            <span className="pl-1 font-semibold text-gray-700">
                                {showDeleteConfirmationModalForCarbonCreditBalance?.tenant.type ===
                                TenantType.ENTITY
                                    ? `${showDeleteConfirmationModalForCarbonCreditBalance?.tenant.entity_name}`
                                    : `${
                                          showDeleteConfirmationModalForCarbonCreditBalance?.tenant
                                              .users
                                              ? `${showDeleteConfirmationModalForCarbonCreditBalance?.tenant.users[0]?.first_name} ${showDeleteConfirmationModalForCarbonCreditBalance?.tenant.users[0]?.first_name} - ${showDeleteConfirmationModalForCarbonCreditBalance?.tenant.users[0]?.email}`
                                              : ''
                                      }`}
                            </span>
                        </div>
                        <div>
                            <span>Amount of associated live sell orders</span>
                            <span className="pl-1 font-semibold text-gray-700">
                                {
                                    showDeleteConfirmationModalForCarbonCreditBalance?.sell_orders
                                        ?.length
                                }
                            </span>
                        </div>
                    </span>
                }
            />
        </div>
    )
}

export default CarbonCreditBalancesTable
