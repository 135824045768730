import BuyOrdersTable from '../../modules/buyOrders/components/BuyOrdersTable'
import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import OrdersSubMenu from './components/OrdersSubMenu'
import { OrderSubMenuActions } from './enums/OrderSubMenuActions'

const BuyOrders = (): JSX.Element => {
    return (
        <Layout
            title=""
            subTitle=""
            dataCy="buy-orders-layout"
            selectedNavBarItem={NavBarItemName.ORDERS}
        >
            <OrdersSubMenu selectedItem={OrderSubMenuActions.BUY_ORDERS} />
            <BuyOrdersTable project={null} />
        </Layout>
    )
}

export default BuyOrders
