import BaseModal from '../../modal/components/BaseModal'
import { PrimaryButton, SecondaryButton } from '../../common/components/Button'
import * as Sentry from '@sentry/react'
import ModalButtonContainer from '../../modal/components/ModalButtonContainer'
import { convertCentsToLocaleUsd } from '../../../utils/conversion'
import Note, { NoteType } from '../../note/components/Note'
import { useState } from 'react'
import { PayoutApi } from '../http/PayoutApi'

interface Props {
    show: boolean
    amountToWithdraw: string
    onClose?: (success: boolean) => any
}

export const CreatePayoutModal = ({
    show,
    amountToWithdraw,
    onClose = () => {},
}: Props): JSX.Element => {
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [enableButtons, setEnableButtons] = useState<boolean>(true)

    const createPayout = async (): Promise<void> => {
        try {
            setEnableButtons(false)
            setError(null)
            setShowSpinner(true)
            await PayoutApi.create()
            setEnableButtons(true)
            setShowSpinner(false)
            onClose(true)
        } catch (err) {
            setEnableButtons(true)
            setError(
                'There was a problem creating your payout. Please refresh the page and try again.',
            )
            setShowSpinner(false)
            Sentry.captureException(err)
        }
    }

    const close = (): any => {
        setShowSpinner(false)
        setError(null)
        onClose(false)
    }

    if (!show) {
        // exit early
        return <></>
    }

    return (
        <BaseModal
            show={show}
            title={`Would you like to withdraw ${convertCentsToLocaleUsd(amountToWithdraw)}?`}
            id="create-payout-modal"
            position="center"
            onClose={close}
            error={error}
            showSpinner={showSpinner}
            bottomComponent={
                <ModalButtonContainer>
                    <SecondaryButton onClick={close} disabled={!enableButtons}>
                        Close
                    </SecondaryButton>
                    <PrimaryButton onClick={createPayout} disabled={!enableButtons}>
                        Withdraw
                    </PrimaryButton>
                </ModalButtonContainer>
            }
        >
            <p>We'll wire the amount to your nominated bank account.</p>
            <Note type={NoteType.WARNING}>Bank and FX fees may apply</Note>
        </BaseModal>
    )
}

export default CreatePayoutModal
