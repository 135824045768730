import { SearchCarbonCreditBalancesResponse } from './types/SearchCarbonCreditBalancesResponse'
import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { SearchCarbonCreditBalanceParams } from './types/SearchCarbonCreditBalanceParams'
import { CarbonCreditBalance } from '../types/CarbonCreditBalance'

export interface CarbonCreditBalanceApiInterface {
    search(
        params: SearchCarbonCreditBalanceParams,
    ): Promise<AxiosResponse<SearchCarbonCreditBalancesResponse> | undefined>
    getById(id: string): Promise<AxiosResponse<CarbonCreditBalance> | undefined>
}

export const CarbonCreditBalanceApi: CarbonCreditBalanceApiInterface = Object.freeze({
    search(
        params: SearchCarbonCreditBalanceParams,
    ): Promise<AxiosResponse<SearchCarbonCreditBalancesResponse> | undefined> {
        return HttpService.get('/carbon-credit-balances', null, params)
    },
    getById(id: string): Promise<AxiosResponse<CarbonCreditBalance> | undefined> {
        return HttpService.get(`/carbon-credit-balances/${id}`)
    },
})
