import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import SellerPayoutsOverview from '../../modules/payouts/components/SellerPayouts'

const AdminSellerPayouts = () => {
    return (
        <AdminLayout selectedItem={AdminSubMenuActions.SELLER_PAYOUTS}>
            <h3 className="font-semibold underline underline-offset-2 mb-2">Seller Payouts</h3>
            <SellerPayoutsOverview />
        </AdminLayout>
    )
}

export default AdminSellerPayouts
