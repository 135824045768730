import { AxiosResponse } from 'axios'
import HttpService from '../../../modules/http/httpService'
import { EmptyResponse } from '../../../modules/http/types/EmptyResponse'
import { UnbridgeRequest } from './types/UnbridgeRequest'
import { CompleteUnbridgeRequestPayload } from './types/CompleteUnbridgeRequestPayload'
import { CancelUnbridgeRequestPayload } from './types/CancelUnbridgeRequestPayload'
import { RequestUnbridgePayload } from './types/RequestUnbridgePayload'

export interface UnbridgeRequestApiInterface {
    getPendingRequest(unbridgeRequestId: string): Promise<AxiosResponse<UnbridgeRequest>>
    getPendingRequests(): Promise<AxiosResponse<UnbridgeRequest[]>>
    getAllRequests(): Promise<AxiosResponse<UnbridgeRequest[]>>
    complete(
        payload: CompleteUnbridgeRequestPayload,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
    cancel(payload: CancelUnbridgeRequestPayload): Promise<AxiosResponse<EmptyResponse> | undefined>
    requestUnbridge(
        payload: RequestUnbridgePayload,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
    setInProgress(id: string): Promise<AxiosResponse<EmptyResponse> | undefined>
}

export const UnbridgeRequestApi: UnbridgeRequestApiInterface = Object.freeze({
    getPendingRequest(unbridgeRequestId: string): Promise<AxiosResponse<UnbridgeRequest>> {
        return HttpService.get('admin/unbridge-request/get-pending-request/' + unbridgeRequestId)
    },
    getPendingRequests(): Promise<AxiosResponse<UnbridgeRequest[]>> {
        return HttpService.get('admin/unbridge-request/get-pending-requests')
    },
    getAllRequests(): Promise<AxiosResponse<UnbridgeRequest[]>> {
        return HttpService.get('admin/unbridge-request/get-all-requests')
    },
    complete(
        payload: CompleteUnbridgeRequestPayload,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('admin/unbridge-request/complete', payload)
    },
    cancel(
        payload: CancelUnbridgeRequestPayload,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('admin/unbridge-request/cancel', payload)
    },
    requestUnbridge(
        payload: RequestUnbridgePayload,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('unbridge-request/request', payload)
    },
    setInProgress(id: string): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post(`admin/unbridge-request/${id}/set-in-progress`, {})
    },
})
