import App from './App'
import { ConfigFactory } from './config/ConfigFactory'
import './index.css'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { browserTracingIntegration } from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

if (ConfigFactory.getConfig().sentryEnabled) {
    Sentry.init({
        dsn: ConfigFactory.getConfig().sentryUrl,
        integrations: [browserTracingIntegration()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: ConfigFactory.getConfig().sentryTracingSampleRate,
        environment: ConfigFactory.getConfig().thalloEnv,
    })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
