import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { Interest } from './types/Interest'
import { SaveInterestPayload } from './types/SaveInterestPayload'

export interface InterestApiInterface {
    save(payload: SaveInterestPayload): Promise<AxiosResponse<Interest> | undefined>
}

export const InterestApi: InterestApiInterface = Object.freeze({
    save(payload: SaveInterestPayload): Promise<AxiosResponse<Interest> | undefined> {
        return HttpService.post('/interests', payload)
    },
})
