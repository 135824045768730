const TestEnvBanner = () => {
    return (
        <div className={`bg-primary-500 text-white shadow-md p-4 w-full`}>
            <p className="text-center text-base font-semibold">
                This is a test environment, the market does not reflect the real projects available
                and pricing is not accurate. All credits and purchases are simulated.
            </p>
        </div>
    )
}

export default TestEnvBanner
