import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import WithdrawalTable from '../../modules/withdrawal/components/WithdrawalTable'

const Withdrawal = (): JSX.Element => {
    return (
        <Layout
            title="Credit Withdrawals"
            subTitle="Withdraw your credits back into your registry account"
            dataCy="withdrawal-layout"
            selectedNavBarItem={NavBarItemName.CREDIT_WITHDRAWALS}
        >
            <WithdrawalTable />
        </Layout>
    )
}

export default Withdrawal
