import { DateTime, Info } from 'luxon'
import React, { useEffect, useState } from 'react'
import { PrimaryButton } from '../../modules/common/components/Button'
import Layout from '../../modules/common/components/Layout'
import { SDG } from '../../modules/project/enums/SDG'
import { RetirementRequestsApi } from '../../modules/retirement/http/api'
import { RetirementStats } from '../../modules/retirement/http/types/RetirementStats'
import SubMenu from '../../modules/subMenu/components/SubMenu'
import { Tab } from '../../modules/subMenu/types/Tab'
import { TradeHistoryApi } from '../../modules/tradeHistory/http/api'
import { AveragePriceData } from '../../modules/tradeHistory/http/types/AveragePriceData'
import { CreditMethodologyData } from '../../modules/tradeHistory/http/types/CreditMethodologyData'
import { PurchaseOrderStats } from '../../modules/tradeHistory/http/types/PurchaseOrderStats'
import { RegistryData } from '../../modules/tradeHistory/http/types/RegistryData'
import { TradeHistoryTotalMonthValueDetails } from '../../modules/tradeHistory/http/types/TradeHistoryTotalMonthValueDetails'
import { useUserStore } from '../../modules/user/context/store'
import {
    convertCentsToUsd,
    convertCentsToLocaleUsd,
    convertUsdToCents,
    convertGramsToMetricTonnes,
    bigIntToLocale,
} from '../../utils/conversion'
import Stats from '../../modules/dashboard/components/Stats'
import { formatGrams, formatStat, formatTonnes } from '../../utils/format'
import TotalTransactionValue from '../../modules/dashboard/components/TotalTransactionValue'
import CreditMethodology from '../../modules/dashboard/components/CreditMethodology'
import Registry from '../../modules/dashboard/components/Registry'
import SdgsCoverage from '../../modules/dashboard/components/SdgsCoverage'
import AveragePricePerTCOTwo from '../../modules/dashboard/components/AveragePricePerTCOTwo'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import ContextSwitcher, { ContextOption } from '../../modules/common/components/ContextSwitcher'

enum SubNavigationMenuItem {
    CARBON_OVERVIEW = 'Carbon Overview',
    FINANCIAL_OVERVIEW = 'Financial Overview',
}

const tabs: Tab<SubNavigationMenuItem>[] = [
    { type: SubNavigationMenuItem.CARBON_OVERVIEW, text: SubNavigationMenuItem.CARBON_OVERVIEW },
    {
        type: SubNavigationMenuItem.FINANCIAL_OVERVIEW,
        text: SubNavigationMenuItem.FINANCIAL_OVERVIEW,
    },
]

interface OverviewParams {
    fromDate: string | null
    toDate: string | null
}

const CarbonOverview = ({ fromDate, toDate }: OverviewParams): JSX.Element => {
    const [totalTransactionsValueCarbonData, setTotalTransactionsValueDataCarbonData] = useState<
        TradeHistoryTotalMonthValueDetails[]
    >([])
    const [sdgsDataByPurchasedCredits, setSdgsDataByPurchasedCredits] = useState<SDG[]>([])
    const fetchTotalTransactionsValueCarbonData = async (): Promise<void> => {
        const response = await TradeHistoryApi.getCarbonDataForTotalValueOfCreditsPurchasedGraph({
            from_date: fromDate ?? undefined,
            to_date: toDate ?? undefined,
        })
        setTotalTransactionsValueDataCarbonData(response?.data ?? [])
    }
    const [creditsMethodologyData, setCreditsMethodologyData] = useState<CreditMethodologyData[]>(
        [],
    )
    const [registryData, setRegistryData] = useState<RegistryData[]>([])

    const fetchSdgsData = async (): Promise<void> => {
        const response = await TradeHistoryApi.getSDGsDataByPurchasedCredits({
            from_date: fromDate ?? undefined,
            to_date: toDate ?? undefined,
        })
        setSdgsDataByPurchasedCredits(response?.data ?? [])
    }

    const fetchCreditsMethodologyData = async (): Promise<void> => {
        const response = await TradeHistoryApi.getCreditMethodologyData({
            from_date: fromDate ?? undefined,
            to_date: toDate ?? undefined,
        })
        setCreditsMethodologyData(response?.data ?? [])
    }

    const fetchRegistryData = async (): Promise<void> => {
        const response = await TradeHistoryApi.getRegistryData({
            from_date: fromDate ?? undefined,
            to_date: toDate ?? undefined,
        })
        setRegistryData(response?.data ?? [])
    }

    const [purchaseStats, setPurchaseStats] = useState<PurchaseOrderStats | null>(null)
    const [retirementStats, setRetirementStats] = useState<RetirementStats | null>(null)

    const fetchPurchaseStats = async () => {
        const response = await TradeHistoryApi.getPurchaseOrderStats({
            from_date: fromDate ?? undefined,
            to_date: toDate ?? undefined,
        })
        setPurchaseStats(response?.data)
    }

    const fetchRetirementStats = async () => {
        const response = await RetirementRequestsApi.getRetirementStats({
            from_date: fromDate ?? undefined,
            to_date: toDate ?? undefined,
        })
        setRetirementStats(response?.data)
    }

    useEffect(() => {
        void fetchRegistryData()
        void fetchSdgsData()
        void fetchTotalTransactionsValueCarbonData()
        void fetchCreditsMethodologyData()
        void fetchPurchaseStats()
        void fetchRetirementStats()
    }, [fromDate])

    return (
        <React.Fragment>
            <div className="mt-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pt-6 row-auto">
                    <Stats
                        title="Total Credits Purchased (tCO2e)"
                        amount={formatStat(purchaseStats?.total_credits_purchased ?? '0', 0)}
                    />
                    <Stats
                        title="Total Pending Retirement"
                        amount={formatTonnes(retirementStats?.total_tonnes_pending ?? '0')}
                        unitLabel={' (tCO2e)'}
                        subStatAmount={
                            Number(formatGrams(retirementStats?.total_tonnes_pending ?? '0')) > 0
                                ? formatGrams(retirementStats?.total_tonnes_pending ?? '0')
                                : undefined
                        }
                        subStatUnitLabel={
                            Number(formatGrams(retirementStats?.total_tonnes_pending ?? '0')) > 0
                                ? ' (grams)'
                                : undefined
                        }
                    />
                    <Stats
                        title="Total Credits Retired (tCO2e)"
                        amount={formatStat(retirementStats?.total_tonnes_retired ?? '0', 0)}
                    />
                </div>
            </div>
            <div className="mt-6">
                <TotalTransactionValue
                    title="Credits Purchased"
                    subtitle="tCO2e"
                    xAxisLabel="Month"
                    datasets={{
                        labels: totalTransactionsValueCarbonData.map((value) => value.month),
                        datasets: [
                            {
                                data: totalTransactionsValueCarbonData.map((value) => {
                                    return convertGramsToMetricTonnes(value.value)
                                }),
                                backgroundColor: '#E46313',
                                barThickness: 32,
                                borderRadius: 8,
                            },
                        ],
                    }}
                    tooltipCallback={(context) => {
                        const value = context.parsed.y || 0
                        return `${bigIntToLocale(value)} (tCO2e)`
                    }}
                    rangeLabelText="This year"
                />
            </div>
            <div className="mt-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pt-6 row-auto">
                    <CreditMethodology
                        labels={creditsMethodologyData.map((data) => data.type)}
                        data={creditsMethodologyData.map((data) => data.total_amount)}
                    />
                    <Registry
                        data={registryData.map((data) => {
                            return {
                                label: data.registry_name,
                                value: convertGramsToMetricTonnes(data.total_amount),
                            }
                        })}
                    />
                    <SdgsCoverage coveredSdgs={sdgsDataByPurchasedCredits} />
                </div>
            </div>
        </React.Fragment>
    )
}

const FinancialOverview = ({ fromDate, toDate }: OverviewParams): JSX.Element => {
    const [totalTransactionsValueFinancialData, setTotalTransactionsValueDataFinancialData] =
        useState<TradeHistoryTotalMonthValueDetails[]>([])
    const [purchaseStats, setPurchaseStats] = useState<PurchaseOrderStats | null>(null)
    const [averagePriceData, setAveragePriceData] = useState<AveragePriceData[]>([])

    const fetchTotalTransactionsValueFinancialData = async (): Promise<void> => {
        const response = await TradeHistoryApi.getFinancialDataForTotalValueOfCreditsPurchasedGraph(
            {
                from_date: fromDate ?? undefined,
                to_date: toDate ?? undefined,
            },
        )
        setTotalTransactionsValueDataFinancialData(response?.data ?? [])
    }

    const fetchPurchaseStats = async () => {
        const response = await TradeHistoryApi.getPurchaseOrderStats({
            from_date: fromDate ?? undefined,
            to_date: toDate ?? undefined,
        })
        setPurchaseStats(response.data)
    }

    const fetchAveragePriceData = async () => {
        const response = await TradeHistoryApi.getAveragePricePerTCO2e({
            from_date: fromDate ?? undefined,
            to_date: toDate ?? undefined,
        })
        setAveragePriceData(response?.data ?? [])
    }

    const formatStat = (stat: string) => {
        return parseFloat(stat).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
    }

    const formatTotalPurchased = (stat: string) => {
        const totalInDollars = (Number(stat) / 100).toString()
        return parseFloat(totalInDollars).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
    }

    useEffect(() => {
        void fetchTotalTransactionsValueFinancialData()
        void fetchPurchaseStats()
        void fetchAveragePriceData()
    }, [fromDate])

    const uniqueYears = [...new Set(totalTransactionsValueFinancialData.map((value) => value.year))]

    return (
        <React.Fragment>
            <div className="mt-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pt-6 row-auto">
                    <Stats
                        title="Total Credits Purchased (USD)"
                        amount={
                            '$' +
                            formatTotalPurchased(purchaseStats?.total_transaction_value ?? '0')
                        }
                    />
                    <Stats
                        title="Number of Transactions"
                        amount={purchaseStats?.number_of_transactions ?? '0'}
                    />
                    <Stats
                        title="Avg. price per tCO2e (USD)"
                        amount={'$' + formatStat(purchaseStats?.average_price_per_tonne ?? '0')}
                    />
                </div>
            </div>
            <div className="mt-6">
                <TotalTransactionValue
                    title="Total Transaction Value"
                    subtitle="USD ($)"
                    xAxisLabel="Month"
                    datasets={{
                        labels: totalTransactionsValueFinancialData.map((value) => value.month),
                        datasets: [
                            {
                                data: totalTransactionsValueFinancialData.map((value) =>
                                    convertCentsToUsd(value.value),
                                ),
                                backgroundColor: '#E46313',
                                barThickness: 32,
                                borderRadius: 8,
                            },
                        ],
                    }}
                    rangeLabelText={uniqueYears.length > 1 ? uniqueYears.join('-') : 'This year'}
                    tooltipCallback={(context) => {
                        const value = context.parsed.y || 0
                        return convertCentsToLocaleUsd(BigInt(convertUsdToCents(Number(value))))
                    }}
                />
            </div>
            <div className="mt-6">
                <AveragePricePerTCOTwo
                    title="Average Price per tCO2e"
                    subtitle="USD ($)"
                    xAxisLabel="Month"
                    datasets={{
                        labels: Info.months('short'),
                        datasets: [
                            {
                                data: averagePriceData.map((data) =>
                                    convertCentsToUsd(data.averagePrice),
                                ),
                                backgroundColor: 'rgba(228,	99,	19, 0.5)',
                                fill: true,
                                borderColor: '#BE5D29',
                            },
                        ],
                    }}
                    tooltipCallback={(context) => {
                        const value = context.parsed.y || 0
                        return convertCentsToLocaleUsd(BigInt(convertUsdToCents(Number(value))))
                    }}
                />
            </div>
        </React.Fragment>
    )
}

const CaaSDashboard = (): JSX.Element => {
    const { user } = useUserStore()
    const [selectedSubMenuItem, setSelectedSubMenuItem] = useState<SubNavigationMenuItem>(
        SubNavigationMenuItem.CARBON_OVERVIEW,
    )
    const [selectedYear, setSelectedYear] = useState<number>(DateTime.now().year)
    const [thisYear] = useState<number>(DateTime.now().year)
    const [fromDate, setFromDate] = useState<string | null>(DateTime.now().startOf('year').toISO())
    const [toDate, setToDate] = useState<string | null>(DateTime.now().endOf('year').toISO())

    useEffect(() => {
        const currentYear = DateTime.fromObject({ year: selectedYear })
        setFromDate(currentYear.startOf('year').toISO())
        setToDate(currentYear.endOf('year').toISO())
    }, [selectedYear])

    const contextOptions: ContextOption<number>[] = [
        {
            display: 'This Year',
            value: thisYear,
        },
        {
            display: 'Last Year',
            value: thisYear - 1,
        },
    ]

    return (
        <Layout
            title={`Welcome back, ${user?.first_name}`}
            subTitle="Here is an overview of your latest figures"
            showDividingLine={false}
            selectedNavBarItem={NavBarItemName.DASHBOARD}
        >
            <div className="mt-6 pb-24">
                <div className="flex flex-row justify-between">
                    <SubMenu
                        tabs={tabs}
                        selectedItem={selectedSubMenuItem}
                        onselect={(subMenuItem: SubNavigationMenuItem) =>
                            setSelectedSubMenuItem(subMenuItem)
                        }
                        id="dashboard-type"
                    />
                    <PrimaryButton className="hidden flex-row items-center gap-2 px-4 py-2.5 text-sm">
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.33325 10.0001V16.6667C3.33325 17.1088 3.50885 17.5327 3.82141 17.8453C4.13397 18.1578 4.55789 18.3334 4.99992 18.3334H14.9999C15.4419 18.3334 15.8659 18.1578 16.1784 17.8453C16.491 17.5327 16.6666 17.1088 16.6666 16.6667V10.0001M13.3333 5.00008L9.99992 1.66675M9.99992 1.66675L6.66659 5.00008M9.99992 1.66675V12.5001"
                                stroke="white"
                                strokeWidth="1.67"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>

                        <span className="text-sm">Export</span>
                    </PrimaryButton>
                    <div>
                        <ContextSwitcher
                            options={contextOptions}
                            onSelect={setSelectedYear}
                            selected={selectedYear}
                        />
                    </div>
                </div>
                {selectedSubMenuItem === SubNavigationMenuItem.CARBON_OVERVIEW ? (
                    <CarbonOverview fromDate={fromDate} toDate={toDate} />
                ) : (
                    <FinancialOverview fromDate={fromDate} toDate={toDate} />
                )}
            </div>
        </Layout>
    )
}

export default CaaSDashboard
