import { SearchCarbonCreditBalancesResponse } from '../http/types/SearchCarbonCreditBalancesResponse'
import { SelectedFilters } from './reducer'
import {
    SetIsCarbonCreditFiltersModalVisibleAction,
    SetSearchResponseAction,
    SetSelectedFiltersAction,
    SetProjectTypeFilterVisibleAction,
    SetShowAllLocationsAction,
    SetShowAlSdgsAction,
    SetIsFormDisabledAction,
    SET_IS_CARBON_CREDIT_FILTERS_MODAL_VISIBLE,
    SET_IS_FORM_DISABLED,
    SET_SEARCH_RESPONSE,
    SET_SELECTED_FILTERS,
    SET_PROJECT_TYPE_FILTER_VISIBLE,
    SET_SHOW_ALL_LOCATIONS,
    SET_SHOW_ALL_SDGS,
} from './types'
import { Dispatch } from 'react'

export const setIsCarbonCreditFiltersModalVisibleAction = (
    isFiltersModalVisible: boolean,
    dispatch: Dispatch<SetIsCarbonCreditFiltersModalVisibleAction>,
): void => {
    dispatch({
        type: SET_IS_CARBON_CREDIT_FILTERS_MODAL_VISIBLE,
        payload: { isFiltersModalVisible },
    })
}

export const setIsFormDisabledAction = (
    isDisabled: boolean,
    dispatch: Dispatch<SetIsFormDisabledAction>,
): void => {
    dispatch({
        type: SET_IS_FORM_DISABLED,
        payload: { isDisabled },
    })
}

export const setShowAllLocationsAction = (
    show: boolean,
    dispatch: Dispatch<SetShowAllLocationsAction>,
): void => {
    dispatch({
        type: SET_SHOW_ALL_LOCATIONS,
        payload: { show },
    })
}

export const setShowAllSdgsAction = (
    show: boolean,
    dispatch: Dispatch<SetShowAlSdgsAction>,
): void => {
    dispatch({
        type: SET_SHOW_ALL_SDGS,
        payload: { show },
    })
}

export const setSelectedFiltersAction = (
    filters: SelectedFilters,
    dispatch: Dispatch<SetSelectedFiltersAction>,
): void => {
    dispatch({
        type: SET_SELECTED_FILTERS,
        payload: { filters },
    })
}

export const setSearchResponseAction = (
    response: SearchCarbonCreditBalancesResponse,
    dispatch: Dispatch<SetSearchResponseAction>,
): void => {
    dispatch({
        type: SET_SEARCH_RESPONSE,
        payload: { response },
    })
}

export const setProjectTypeFilterVisibleAction = (
    isVisible: boolean,
    dispatch: Dispatch<SetProjectTypeFilterVisibleAction>,
): void => {
    dispatch({
        type: SET_PROJECT_TYPE_FILTER_VISIBLE,
        payload: { isVisible },
    })
}
