import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { AreTermsAndConditionsAcceptedResponse } from './types/AreTermsAndConditionsAcceptedResponse'

export interface TermsAndConditionsApiInterface {
    getTermsAndConditions(): Promise<AxiosResponse<any> | undefined>
    acceptTermsAndConditions(): Promise<AxiosResponse<any> | undefined>
    areTermsAndConditionsAccepted(): Promise<
        AxiosResponse<AreTermsAndConditionsAcceptedResponse> | undefined
    >
}

export const TermsAndConditionApi: TermsAndConditionsApiInterface = Object.freeze({
    getTermsAndConditions(): Promise<AxiosResponse<any> | undefined> {
        return HttpService.get('/agreements')
    },
    acceptTermsAndConditions(): Promise<AxiosResponse<any> | undefined> {
        return HttpService.post('/agreements/accept-agreement', {})
    },
    /* eslint-disable indent */
    areTermsAndConditionsAccepted(): Promise<
        AxiosResponse<AreTermsAndConditionsAcceptedResponse> | undefined
    > {
        return HttpService.get('/agreements/are-accepted')
    },
})
