import { CookiesManagementAction, SET_IS_ACCEPT_COOKIES_BANNER_VISIBLE } from './types'

export interface CookieManagement {
    state: CookiesManagementStore
    acceptCookies: () => void
    declineCookies: () => void
    areCookiesAccepted: () => boolean
    areCookiesDeclined: () => boolean
    setShowAcceptCookiesBannerVisible: (isVisible: boolean) => void
}

export interface CookiesManagementStore {
    isAcceptCookiesBannerVisible: boolean
}

export const initialCookiesManagementStoreState: CookiesManagementStore = {
    isAcceptCookiesBannerVisible: false,
}

export const initialState: CookieManagement = {
    state: initialCookiesManagementStoreState,
    acceptCookies: () => {},
    declineCookies: () => {},
    areCookiesAccepted: () => false,
    areCookiesDeclined: () => false,
    setShowAcceptCookiesBannerVisible: () => {},
}

export const cookiesManagementReducer = (
    state = initialState,
    action: CookiesManagementAction,
): CookieManagement => {
    switch (action.type) {
        case SET_IS_ACCEPT_COOKIES_BANNER_VISIBLE:
            return {
                ...state,
                state: {
                    ...state.state,
                    isAcceptCookiesBannerVisible: action.payload.isVisible,
                },
            }
        default:
            return state
    }
}
