import { getCountryNameFromCountryCode } from '../../../utils/getCountryNameFromCountryCode'
import { CountryCode } from '../enums/CountryCode'
import { ProjectLocation } from '../enums/ProjectLocation'

interface CountryFlagProps {
    location: ProjectLocation
}

type CountryCodeEnumKeyType = keyof typeof CountryCode

type ProjectLocationEntriesType = [keyof typeof ProjectLocation, ProjectLocation]

const CountryFlag = ({ location }: CountryFlagProps): JSX.Element => {
    const projectLocationEntries = Object.entries(ProjectLocation) as ProjectLocationEntriesType[]
    const [locationKey] = projectLocationEntries.find(
        ([, value]) => value === location,
    ) as ProjectLocationEntriesType
    const countryCodeKeys = Object.keys(CountryCode) as CountryCodeEnumKeyType[]

    if (!!locationKey && countryCodeKeys.includes(locationKey as CountryCodeEnumKeyType)) {
        return (
            <img
                className="w-5 h-5"
                src={`/assets/flags/${String(
                    CountryCode[locationKey as unknown as CountryCodeEnumKeyType],
                )}.png`}
                alt={getCountryNameFromCountryCode(location) ?? location}
            />
        )
    }
    return <p>{locationKey}</p>
}

export default CountryFlag
