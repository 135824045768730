import { AxiosResponse } from 'axios'
import { EmptyResponse } from '../../../../modules/http/types/EmptyResponse'
import HttpService from '../../../../modules/http/httpService'

export interface TradeHistoryApiInterface {
    resendSlackNotification(id: string): Promise<AxiosResponse<EmptyResponse> | undefined>
}

export const AdminTradeHistoryApi: TradeHistoryApiInterface = Object.freeze({
    resendSlackNotification(id: string): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post(`/admin/trade-history/resend-slack/${id}`, {})
    },
})
