import { useNavigate } from 'react-router-dom'
import { Page } from '../../common/enums/Pages'

const Logo = (): JSX.Element => {
    const navigate = useNavigate()

    const onLogoClickedHandler = (): void => navigate(Page.MARKET)

    return (
        <div
            className="flex items-center w-25 pl-2 sm:pl-0 cursor-pointer"
            onClick={onLogoClickedHandler}
            title="Home page"
        >
            <img src="/assets/logo_stacked.svg" alt="logo" />
        </div>
    )
}

export default Logo
