import UserFullName from './UserFullName'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AuthenticationApi } from '../../auth/http/AuthenticationApi'
import { Page } from '../../common/enums/Pages'
import useClickOutside from '../../common/hooks/useOutsideClick'
import { CookieKey } from '../../cookies/enums/CookieKey'
import { deleteCookie } from '../../cookies/useCookie'
import { useUserStore } from '../../user/context/store'

const UserMenu = (): JSX.Element => {
    const [isHidden, setIsHidden] = useState<boolean>(true)
    const { setUser } = useUserStore()
    const ref = useClickOutside<HTMLDivElement>(() => {
        if (!isHidden) {
            setIsHidden(!isHidden)
        }
    })
    const navigate = useNavigate()

    const toggleIsHidden = (): void => setIsHidden(!isHidden)

    const signOut = async (): Promise<void> => {
        await AuthenticationApi.signout()
        deleteCookie(CookieKey.JWT_TOKEN)
        deleteCookie(CookieKey.REFRESH_TOKEN)
        setUser(null)
        navigate(Page.SIGNIN)
    }

    return (
        <div
            className="relative inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0 cursor-pointer"
            onClick={toggleIsHidden}
            ref={ref}
        >
            <div className="ml-3">
                <UserFullName />
                <ul
                    className={`origin-top-right absolute right-0 mt-5 w-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 ${
                        isHidden ? 'hidden' : null
                    }`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabIndex={-1}
                >
                    <li className="hover:bg-gray-50 border-b l border-gray-100">
                        <Link
                            to={Page.ACCOUNT_SETTINGS_DETAILS}
                            title="account settings"
                            className="flex flex-row h-10 px-4 py-2.5 gap-4 items-center"
                        >
                            <img src="/assets/icon_edit.svg" alt="icon edit" className="w-3 h-3" />
                            <p className="text-gray-700 text-sm">Account Settings</p>
                        </Link>
                    </li>
                    <li className="hover:bg-gray-50 border-b l border-gray-100">
                        <Link
                            to={Page.CONTACT_SUPPORT}
                            title="contact support"
                            className="flex flex-row h-10 px-4 py-2.5 gap-4 items-center"
                        >
                            <img
                                src="/assets/icon_letter.svg"
                                alt="icon security"
                                className="w-3 h-3"
                            />
                            <p className="text-gray-700 text-sm">Contact Support</p>
                        </Link>
                    </li>
                    <li
                        onClick={signOut}
                        className="flex flex-row h-10 px-4 py-2.5 gap-4 items-center hover:bg-gray-50"
                    >
                        <img src="/assets/icon_logout.svg" alt="user icon" className="w-3 h-3" />
                        <p className="text-gray-700 text-sm">Log Out</p>
                    </li>
                </ul>
                {/*)}*/}
            </div>
        </div>
    )
}

export default UserMenu
