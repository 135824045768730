import { ActionName } from './enums/ActionName'

export const actionButtonText = (actionName: ActionName): string => {
    const mappping: Record<ActionName, string> = {
        [ActionName.BUY_RETIRE]: 'Confirm Retirement',
        [ActionName.PURCHASE]: 'Confirm Purchase',
        [ActionName.RETURN]: 'Withdraw Credits',
        [ActionName.SELL]: 'Create Order',
        [ActionName.RETIRE]: 'Retire Now',
        [ActionName.INTEREST]: 'Submit Interest',
        [ActionName.CANCEL]: 'Cancel Buy Order',
        [ActionName.UPDATE_SELL_ORDER]: 'Update Sell Order',
        [ActionName.CANCEL_SELL_ORDER]: 'Cancel Sell Order',
    }

    return mappping[actionName] || mappping[ActionName.SELL]
}

export const actionText = (actionName: ActionName): string => {
    const mappping: Record<ActionName, string> = {
        [ActionName.BUY_RETIRE]: 'Buy and Retire',
        [ActionName.PURCHASE]: 'Buy and Hold',
        [ActionName.RETURN]: 'Withdraw',
        [ActionName.SELL]: 'Create a Sell Order',
        [ActionName.RETIRE]: 'Retire',
        [ActionName.INTEREST]: 'Submit Interest',
        [ActionName.CANCEL]: 'Cancel Buy Order',
        [ActionName.UPDATE_SELL_ORDER]: 'Update Sell Order',
        [ActionName.CANCEL_SELL_ORDER]: 'Cancel Sell Order',
    }

    return mappping[actionName] || mappping[ActionName.SELL]
}

export const actionType = (actionName: ActionName): string => {
    const mappping: Record<ActionName, string> = {
        [ActionName.BUY_RETIRE]: 'Buy and Retire',
        [ActionName.PURCHASE]: 'Buy and Hold',
        [ActionName.RETURN]: 'Withdraw',
        [ActionName.SELL]: 'Create an Order',
        [ActionName.RETIRE]: 'Retire',
        [ActionName.INTEREST]: 'Submit Interest',
        [ActionName.CANCEL]: 'Cancel Buy Order',
        [ActionName.UPDATE_SELL_ORDER]: 'Update Sell Order',
        [ActionName.CANCEL_SELL_ORDER]: 'Cancel Sell Order',
    }

    return mappping[actionName] || mappping[ActionName.SELL]
}

export const actionDescription = (actionName: ActionName): string => {
    const mapping: Record<ActionName, string> = {
        [ActionName.BUY_RETIRE]:
            'Purchase and retire the carbon credits immediately. Retirements are permanent and cannot be undone.',
        [ActionName.PURCHASE]:
            'Purchase and hold the carbon credits on your Thallo account. They can later be re-sold or retired.',
        [ActionName.RETURN]:
            'This will return the credits on the corresponding registry. The credits will no longer be available to be sold or retired.',
        [ActionName.SELL]: `This price will be the price you receive, the marketplace view will include Thallo's platform fee`,
        [ActionName.RETIRE]: 'Retirements are permanent and cannot be undone.',
        [ActionName.INTEREST]: 'Submit interest to purchase the carbon credits.',
        [ActionName.CANCEL]:
            'This will cancel the order and the credits will be returned to the seller.',
        [ActionName.UPDATE_SELL_ORDER]: `This price will be the price you receive, the marketplace view will include Thallo's platform fee`,
        [ActionName.CANCEL_SELL_ORDER]:
            'This will cancel the sell order and the credits will be returned to you.',
    }

    return mapping[actionName] || mapping[ActionName.SELL]
}

export const confirmationDescription = (actionName: ActionName): string | undefined => {
    const mapping: Partial<Record<ActionName, string>> = {
        [ActionName.BUY_RETIRE]: 'Retirements are permanent and cannot be undone.',
        [ActionName.RETURN]:
            'This will return the credits on the corresponding registry. The credits will no longer be available to be sold or retired.',
        [ActionName.SELL]:
            "This price will be the price you receive, the marketplace view will include Thallo's platform fee",
        [ActionName.RETIRE]: 'Retirements are permanent and cannot be undone.',
        [ActionName.INTEREST]: 'Submit interest to purchase the carbon credits.',
        [ActionName.CANCEL]:
            'This will cancel the order and the credits will be returned to the seller.',
        [ActionName.PURCHASE]:
            'These credits will appear in your carbon credit balance once the invoice is settled.',
        [ActionName.UPDATE_SELL_ORDER]: `This price will be the price you receive, the marketplace view will include Thallo's platform fee`,
        [ActionName.CANCEL_SELL_ORDER]:
            'The credits will not be available for purchase and will be returned to your inventory.',
    }

    return mapping[actionName]
}
