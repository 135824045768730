import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AdminProjectApi } from '../../modules/projects/http/api'
import { SearchProjectsResponse } from '../../modules/projects/http/types/SearchProjectsResponse'
import { Page } from '../../../modules/common/enums/Pages'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { SearchProjectParams } from '../../modules/projects/http/types/SearchProjectParams'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import ProjectsTable from '../../modules/projects/components/ProjectsTable'

const AdminProjects = (): JSX.Element => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchProjectsResponse, setSearchProjectsResponse] =
        useState<SearchProjectsResponse | null>(null)

    const searchProjects = async (params: SearchProjectParams = {}): Promise<void> => {
        const response = await AdminProjectApi.search(params)
        if (response) {
            setSearchProjectsResponse(response.data)
        }
    }

    const navigate = useNavigate()

    useEffect(() => {
        void searchProjects({
            page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
        })
    }, [])

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.PROJECTS}>
            <>
                <div className="flex justify-end mb-2 max-h-[5%]">
                    <button
                        onClick={() => navigate(Page.ADMIN_CREATE_PROJECT)}
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                            />
                        </svg>
                        Create Project
                    </button>
                </div>
                <ProjectsTable
                    searchProjectsResponse={searchProjectsResponse}
                    onPageChangeHandler={(pageNumber: number) => {
                        setSearchParams({ page: String(pageNumber) })
                        void searchProjects({
                            page: pageNumber,
                        })
                    }}
                />
            </>
        </AdminLayout>
    )
}

export default AdminProjects
