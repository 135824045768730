import { useNavigate, useSearchParams } from 'react-router-dom'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import { useEffect, useRef, useState } from 'react'
import { Tenant } from '../../../modules/tenant/types/Tenant'
import { AxiosError } from 'axios'
import { useToastMessageStore } from '../../../modules/toastMessage/context/store'
import { ToastMessageType } from '../../../modules/toastMessage/enums/ToastMessageType'
import { Page } from '../../../modules/common/enums/Pages'
import SearchBar from '../../modules/common/components/SearchBar'
import RegistryIcon from '../../../modules/registry/components/RegistryIcon'
import DateTimeFormatted from '../../../modules/dateTime/components/DateTimeFormatted'
import { PrimaryButton, SecondaryButton } from '../../../modules/common/components/Button'
import { RetirementStatuses } from '../../../modules/retirement/enums/RetirementStatuses'
import { Pagination } from 'flowbite-react'
import ConfirmationModal from '../../modules/common/components/ConfirmationModal'
import { convertGramsToMetricTonesLocaleString } from '../../../utils/conversion'
import RetirementStatusPill from '../../../modules/retirement/components/RetirementStatusPill'
import { AdminRetirementRequestsApi } from '../../modules/retirement/http/AdminRetirementRequestsApi'
import { SearchRetirementRequestsParams } from '../../modules/retirement/http/types/SearchRetirementRequestsParams'
import { SearchRetirementRequestResponses } from '../../modules/retirement/http/types/SearchRetirementRequestResponses'
import { generatePathWithQueryString } from '../../modules/user/http/utils/generatePathWithQueryString'
import { AdminFeatureFlagApi } from '../../modules/featureFlag/http/AdminFeatureFlagApi'
import { AdminRetirementRequestDto } from '../../modules/retirement/http/types/AdminRetirementRequestDto'

const AdminRetirements = (): JSX.Element => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchRetirementRequestResponse, setSearchRetirementRequestResponse] =
        useState<SearchRetirementRequestResponses | null>(null)
    const [
        showSetInProgressConfirmationModalForRetirementRequest,
        setshowSetInProgressConfirmationModalForRetirementRequest,
    ] = useState<AdminRetirementRequestDto | null>(null)
    const [showCloseRetirementRequest, setShowCloseRetirementRequest] =
        useState<AdminRetirementRequestDto | null>(null)
    const [bridgeIntegrationEnabled, setBridgeIntegrationEnabled] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [searchTenantsResults, setSearchTenantsResults] = useState<Tenant[]>()
    const intervalRef = useRef<any>(null)
    const { setShowToastMessage } = useToastMessageStore()

    const searchRetirementRequests = async (
        params: SearchRetirementRequestsParams = {},
    ): Promise<void> => {
        const response = await AdminRetirementRequestsApi.search(params)
        setSearchRetirementRequestResponse(response.data)
    }

    const searchTenantsForRetirementRequest = async (searchTerm: string) => {
        const res = await AdminRetirementRequestsApi.searchTenantsForRetirementRequests(searchTerm)
        if (res.data) {
            setSearchTenantsResults(res.data)
        }
    }

    const getBridgeIntegrationEnabled = async (): Promise<void> => {
        const response = await AdminFeatureFlagApi.isBridgeIntegrationEnabled()
        setBridgeIntegrationEnabled(response.data)
    }

    useEffect(() => {
        if (searchParams.get('search') && !searchTerm) {
            setSearchTerm(searchParams.get('search') ?? '')
        }
        intervalRef.current = setTimeout(() => {
            void searchTenantsForRetirementRequest(searchTerm)
            void searchRetirementRequests({
                page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
                search: searchParams.get('search') ?? undefined,
            })
        }, 500)
        void getBridgeIntegrationEnabled()
        return () => clearTimeout(intervalRef.current)
    }, [searchParams])

    const setRetirementRequestInProgress = async (): Promise<void> => {
        if (showSetInProgressConfirmationModalForRetirementRequest) {
            try {
                await AdminRetirementRequestsApi.setInProgress(
                    showSetInProgressConfirmationModalForRetirementRequest?.id,
                )
                setshowSetInProgressConfirmationModalForRetirementRequest(null)
                await searchRetirementRequests({
                    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
                    search: searchParams.get('search') ?? undefined,
                })
            } catch (error) {
                if (
                    error instanceof AxiosError &&
                    error.response?.data.message &&
                    error.response?.data.statusCode === 422
                ) {
                    const errorMessage = error.response.data.message
                    setShowToastMessage({
                        text: errorMessage,
                        type: ToastMessageType.ERROR,
                    })
                } else {
                    setShowToastMessage({
                        text: 'Something went wrong please try again later',
                        type: ToastMessageType.ERROR,
                    })
                    throw error
                }
            } finally {
                setshowSetInProgressConfirmationModalForRetirementRequest(null)
            }
        }
    }

    const closeRetirementRequest = async (): Promise<void> => {
        if (showCloseRetirementRequest) {
            try {
                await AdminRetirementRequestsApi.close(showCloseRetirementRequest?.id)
                setShowCloseRetirementRequest(null)
                await searchRetirementRequests({
                    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
                })
            } catch (error) {
                if (
                    error instanceof AxiosError &&
                    error.response?.data.message &&
                    error.response?.data.statusCode === 422
                ) {
                    const errorMessage = error.response.data.message
                    setShowToastMessage({
                        text: errorMessage,
                        type: ToastMessageType.ERROR,
                    })
                } else {
                    setShowToastMessage({
                        text: 'Something went wrong please try again later',
                        type: ToastMessageType.ERROR,
                    })
                    throw error
                }
            } finally {
                setShowCloseRetirementRequest(null)
            }
        }
    }

    const complete = async (retirementRequestId: string): Promise<void> => {
        try {
            navigate(
                generatePathWithQueryString(Page.ADMIN_COMPLETE_RETIREMENT_REQUEST, {
                    id: retirementRequestId,
                }),
            )
            void searchRetirementRequests({
                page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
            })
        } catch (error) {
            setShowToastMessage({
                text: 'Something went wrong please try again later',
                type: ToastMessageType.ERROR,
            })
        }
    }

    useEffect(() => {
        setSearchParams({ search: searchTerm })
    }, [searchTerm])

    const handleSearchedTenantClicked = (id: string) => {
        setSearchTerm(id)
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.RETIREMENT_REQUESTS}>
            <div
                className="shadow-md sm:rounded-lg overflow-scroll md:overflow-auto"
                style={{
                    height: 'calc(95vh - 100px)',
                }}
            >
                <SearchBar
                    searchTerm={searchTerm}
                    onSearchChange={setSearchTerm}
                    searchResults={
                        searchTenantsResults?.map((tenant) => ({
                            id: tenant.id,
                            name: `${tenant.id} - ${tenant.entity_name}`,
                        })) ?? []
                    }
                    onSearchedItemClicked={handleSearchedTenantClicked}
                />
                <table className="w-full text-sm text-left text-gray-500 block md:table md:table-auto">
                    <thead className="text-xs text-gray-700 bg-gray-50">
                        <tr className="h-11 items-center bg-gray-50">
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                ID
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                STATUS
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                TENANT ID
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                TENANT NAME
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                REGISTRY
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                PROJECT NAME
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                VINTAGE
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                QUANTITY
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                MONTH
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                PROOF OF RETIREMENT NFT ID
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                PROOF OF RETIREMENT NFT URL
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                BRIDGE PROJECT ID
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                BRIDGE VINTAGE ID
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                RETIRED AT (UTC)
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                CREATED AT (UTC)
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-4">
                                ACTIONS
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchRetirementRequestResponse?.retirements.map(
                            (retirementRequest: AdminRetirementRequestDto) => {
                                return (
                                    <tr
                                        className="h-18 py-2 border-b items-center"
                                        key={retirementRequest.id}
                                    >
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal">
                                            {retirementRequest.id}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal">
                                            <RetirementStatusPill
                                                status={retirementRequest.status}
                                            />{' '}
                                            {`(${retirementRequest.status})`}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal">
                                            {retirementRequest.tenant_id}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal">
                                            {retirementRequest.tenant_name}
                                        </td>
                                        <td className="text-gray-500 min-w-[150px] text-sm text-left pl-4 font-normal">
                                            <RegistryIcon
                                                registryId={
                                                    retirementRequest.project_data.registry_id
                                                }
                                            />
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal">
                                            {retirementRequest.project_data.name}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal">
                                            {retirementRequest.vintage}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal">
                                            {convertGramsToMetricTonesLocaleString(
                                                Number(retirementRequest?.quantity),
                                            )}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal">
                                            {
                                                <DateTimeFormatted
                                                    date={new Date(retirementRequest.month)}
                                                    monthAndYearOnly={true}
                                                />
                                            }
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal max-w-[150px] break-words">
                                            {retirementRequest.proof_of_retirement_nft_id ?? 'N/A'}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal max-w-[150px] break-words">
                                            {retirementRequest.proof_of_retirement_nft_url ?? 'N/A'}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal">
                                            {retirementRequest.bridge_project_id ?? 'N/A'}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal">
                                            {retirementRequest.bridge_vintage_id ?? 'N/A'}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal">
                                            {/* eslint-disable indent */}
                                            {retirementRequest?.retired_at
                                                ? new Date(
                                                      retirementRequest?.retired_at,
                                                  ).toLocaleString('en-GB', {
                                                      timeZone: 'UTC',
                                                  })
                                                : 'N/A'}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal">
                                            {new Date(retirementRequest?.created_at).toLocaleString(
                                                'en-GB',
                                                {
                                                    timeZone: 'UTC',
                                                },
                                            )}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-4 font-normal px-4">
                                            {retirementRequest.status ===
                                                RetirementStatuses.CLOSED && (
                                                <SecondaryButton
                                                    type="submit"
                                                    onClick={async () => {
                                                        setshowSetInProgressConfirmationModalForRetirementRequest(
                                                            retirementRequest,
                                                        )
                                                    }}
                                                >
                                                    In progress
                                                </SecondaryButton>
                                            )}
                                            {retirementRequest.status ===
                                                RetirementStatuses.OPEN && (
                                                <SecondaryButton
                                                    type="submit"
                                                    className="mt-1 px-2.5 py-1"
                                                    onClick={async () => {
                                                        setShowCloseRetirementRequest(
                                                            retirementRequest,
                                                        )
                                                    }}
                                                >
                                                    Close
                                                </SecondaryButton>
                                            )}
                                            {retirementRequest.status ===
                                                RetirementStatuses.IN_PROGRESS &&
                                                !bridgeIntegrationEnabled && (
                                                    <PrimaryButton
                                                        type="submit"
                                                        onClick={() => {
                                                            complete(retirementRequest.id)
                                                        }}
                                                    >
                                                        Complete
                                                    </PrimaryButton>
                                                )}
                                        </td>
                                    </tr>
                                )
                            },
                        )}
                    </tbody>
                </table>
                <div className="flex justify-center mt-4">
                    {searchRetirementRequestResponse?.pagination &&
                        searchRetirementRequestResponse?.pagination.total_items >
                            searchRetirementRequestResponse.pagination.per_page && (
                            <Pagination
                                currentPage={
                                    searchRetirementRequestResponse.pagination.current_page
                                }
                                totalPages={searchRetirementRequestResponse.pagination.total_pages}
                                onPageChange={(pageNumber: number) => {
                                    setSearchParams({ page: String(pageNumber) })
                                }}
                            />
                        )}
                </div>
                <ConfirmationModal
                    show={!!showSetInProgressConfirmationModalForRetirementRequest}
                    onClose={() => setshowSetInProgressConfirmationModalForRetirementRequest(null)}
                    onConfirm={setRetirementRequestInProgress}
                    question={
                        <p>
                            Are you sure you want to set retirement request:{' '}
                            <b>{showSetInProgressConfirmationModalForRetirementRequest?.id}</b> in
                            progress
                        </p>
                    }
                />
                <ConfirmationModal
                    show={!!showCloseRetirementRequest}
                    onClose={() => setShowCloseRetirementRequest(null)}
                    onConfirm={closeRetirementRequest}
                    question={
                        <p>
                            Are you sure you want to close retirement request:{' '}
                            <b>{showCloseRetirementRequest?.id}</b>?
                        </p>
                    }
                />
            </div>
        </AdminLayout>
    )
}

export default AdminRetirements
