import { AxiosResponse } from 'axios'
import HttpService from '../../../../modules/http/httpService'
import { SearchBuyOrderParams } from '../../../../modules/buyOrders/http/types/SearchBuyOrderParams'
import { EmptyResponse } from '../../../../modules/http/types/EmptyResponse'
import { SearchBuyOrderResponse } from './types/SearchBuyOrderResponse'
import { AdminBuyOrder } from './types/BuyOrder'

export interface AdminBuyOrderApiInterface {
    search(params: SearchBuyOrderParams): Promise<AxiosResponse<SearchBuyOrderResponse> | undefined>
    getById(id: string): Promise<AxiosResponse<AdminBuyOrder> | undefined>
    cancel(id: string): Promise<AxiosResponse<EmptyResponse> | undefined>
}

export const AdminBuyOrderApi: AdminBuyOrderApiInterface = Object.freeze({
    search(
        params: SearchBuyOrderParams,
    ): Promise<AxiosResponse<SearchBuyOrderResponse> | undefined> {
        return HttpService.get('/admin/buy-order', null, params)
    },
    getById(id: string): Promise<AxiosResponse<AdminBuyOrder> | undefined> {
        return HttpService.get('/admin/buy-order', id)
    },
    cancel(id: string): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post(`/admin/buy-order/cancel/${id}`, {})
    },
})
