import { useEffect, useState } from 'react'
import { useSellOrdersStore } from '../../../modules/sellOrders/context/store'
import { MarketProject } from '../types/MarketProject'
import MarketCard from './MarketCard'
import AskUsCard from './AskUsCard'

interface Props {
    setShowAskUsForm: (show: boolean) => void
}

const MarketCardFormat = ({ setShowAskUsForm }: Props): JSX.Element => {
    const { searchSellOrdersResponse } = useSellOrdersStore()

    const [projects, setProjects] = useState<Map<string, MarketProject>>(new Map())

    useEffect(() => {
        if (!searchSellOrdersResponse) return
        const marketProjects = searchSellOrdersResponse.sell_orders.reduce(
            (acc: Map<string, MarketProject>, sellOrder) => {
                const existingProject = acc.get(sellOrder.carbon_credit_balance!.vintage.project.id)
                if (!existingProject) {
                    acc.set(sellOrder.carbon_credit_balance!.vintage.project.id, {
                        id: sellOrder.carbon_credit_balance!.vintage.project.id,
                        name: sellOrder.carbon_credit_balance!.vintage.project.name,
                        type: sellOrder.carbon_credit_balance!.vintage.project.type,
                        location: sellOrder.carbon_credit_balance!.vintage.project.location,
                        squareImageUrl:
                            sellOrder.carbon_credit_balance!.vintage.project.square_image_url,
                        vintages: {
                            max: sellOrder.carbon_credit_balance!.vintage.vintage,
                            min: sellOrder.carbon_credit_balance!.vintage.vintage,
                        },
                        price: sellOrder.price,
                        sellOrderId: sellOrder.id,
                    })
                    return acc
                }
                existingProject.vintages.max = Math.max(
                    existingProject.vintages.max ?? 0,
                    sellOrder.carbon_credit_balance!.vintage.vintage,
                )
                existingProject.vintages.min = Math.min(
                    existingProject.vintages.min,
                    sellOrder.carbon_credit_balance!.vintage.vintage,
                )
                if (Number(existingProject.price) > Number(sellOrder.price)) {
                    existingProject.price = sellOrder.price
                    existingProject.sellOrderId = sellOrder.id
                }
                return acc
            },
            new Map<string, MarketProject>(),
        )
        setProjects(marketProjects)
    }, [searchSellOrdersResponse])

    return (
        <div className="flex flex-row flex-wrap gap-y-4 sm:gap-x-[1%]" id="market-cards">
            {[...projects.values()].map((project) => {
                return <MarketCard key={project.id} project={project} />
            })}
            <AskUsCard setShowAskUsForm={setShowAskUsForm} />
        </div>
    )
}

export default MarketCardFormat
