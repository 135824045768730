export enum ForbiddenExceptionTypes {
    NO_CODE_2FA = 'NO_CODE_2FA',
    INVALID_2FA = 'INVALID_2FA',
    EXPIRED_2FA = 'EXPIRED_2FA',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    INVALID_PASSWORD_RESET_REQUEST = 'INVALID_PASSWORD_RESET_REQUEST',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    INVALID_JWT = 'INVALID_JWT',
    EXPIRED = 'EXPIRED',
    EXPIRED_JWT = 'EXPIRED_JWT',
    MISSING_BROWSER_ID = 'MISSING_BROWSER_ID',
    INACTIVE = 'INACTIVE',
    AGREEMENT_NOT_ACCEPTED = 'AGREEMENT_NOT_ACCEPTED',
    MISSING_USER = 'MISSING_USER',
    MISSING_CREDENTIALS = 'MISSING_CREDENTIALS',
    INVALID_ROLES = 'INVALID_ROLES',
    MFA_REQUIRED = 'MFA_REQUIRED',
}
