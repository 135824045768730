import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import LoadingSpinner from '../../loadingSpinner/components/LoadingSpinner'
import Note, { NoteType } from '../../note/components/Note'

interface Props {
    waitFor: () => Promise<void>
    errMsg: string
    children: React.ReactNode
    showHeadingOnLoad?: boolean
    heading?: React.ReactNode
    dependencyChanges?: any[]
}

const LoadingWrapper = ({
    waitFor,
    errMsg,
    children,
    showHeadingOnLoad,
    heading,
    dependencyChanges,
}: Props): JSX.Element => {
    const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(true)
    const [showErrMsg, setShowErrMsg] = useState<boolean>(false)
    const [showComponent, setShowComponent] = useState<boolean>(false)

    const waitForAsync = async () => {
        try {
            await waitFor()
            setShowErrMsg(false)
            setShowLoadingSpinner(false)
            setShowComponent(true)
        } catch (err) {
            setShowLoadingSpinner(false)
            setShowComponent(false)
            setShowErrMsg(true)
            Sentry.captureException(err)
        }
    }

    useEffect(() => {
        setShowLoadingSpinner(true)
        waitForAsync()
    }, dependencyChanges ?? [])

    return (
        <div>
            {showLoadingSpinner && <LoadingSpinner></LoadingSpinner>}
            {showHeadingOnLoad && showLoadingSpinner && heading}
            {showComponent && children}
            {showErrMsg && <Note type={NoteType.ERROR}>{errMsg}</Note>}
        </div>
    )
}

export default LoadingWrapper
