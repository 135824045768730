export const formatTonnes = (tonnes: string): string => {
    const tonnesInt = tonnes.split('.')[0]
    return parseFloat(tonnesInt).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })
}

export const formatGrams = (tonnes: string): string => {
    const reminder = Number(tonnes) % 1 // get everething after decimal point
    const grams = reminder * 1000000 // 1 tonne = 1,000,000 grams

    return parseFloat(grams.toString()).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })
}

export const formatStat = (stat: string, precision: number): string => {
    return parseFloat(stat).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: precision,
    })
}

/*
 * returns date in format
 * 2023_11_08_08_46_30
 * yyyy_MM_dd_hh_mm_ss
 * i.e. 8th Nov 2023 at 8:46:30 AM
 * useful for human readable but still filename and sorting friendly
 * passing no param will use the date time now
 */
export const formatDateWithTime = (date: Date = new Date()): string => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Month is zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')

    return `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`
}
