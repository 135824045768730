import { SearchSellOrdersResponse } from '../http/types/SearchSellOrdersResponse'
import {
    setIsFiltersModalVisibleAction,
    setIsFormDisabledAction,
    setSearchSellOrderFiltersAction,
    setSearchSellOrdersResponseAction,
    setShouldShowPriceFiltersAction,
    setShouldShowProjectTypeFilterAction,
    setShowAllLocationsAction,
    setShowAllSdgsAction,
} from './actions'
import { inititalSellOrdersState, SearchSellOrderFilters, SellOrdersReducer } from './reducer'
import { createContext, useContext, useReducer } from 'react'

export const SellOrdersStore = createContext(inititalSellOrdersState)

export const useSellOrdersStore = () => useContext(SellOrdersStore)

export const SellOrdersStoreProvider = ({ children }: { children: any }): any => {
    const [state, dispatch] = useReducer(SellOrdersReducer, inititalSellOrdersState)

    const setSearchSellOrdersResponse = (response: SearchSellOrdersResponse | null) => {
        setSearchSellOrdersResponseAction(response, dispatch)
    }

    const setSearchSellOrderFilters = (response: SearchSellOrderFilters) => {
        setSearchSellOrderFiltersAction(response, dispatch)
    }

    const setShouldShowPriceFilters = (show: boolean) => {
        setShouldShowPriceFiltersAction(show, dispatch)
    }

    const setIsFormDisabled = (isDisabled: boolean) => {
        setIsFormDisabledAction(isDisabled, dispatch)
    }

    const setIsFiltersModalVisible = (isVisible: boolean) => {
        setIsFiltersModalVisibleAction(isVisible, dispatch)
    }

    const setShowAllLocations = (isVisible: boolean) => {
        setShowAllLocationsAction(isVisible, dispatch)
    }

    const setShowAllSdgs = (isVisible: boolean) => {
        setShowAllSdgsAction(isVisible, dispatch)
    }

    const setShouldShowProjectTypeFilter = (show: boolean) => {
        setShouldShowProjectTypeFilterAction(show, dispatch)
    }

    const reset = () => {
        setSearchSellOrdersResponseAction(
            inititalSellOrdersState.searchSellOrdersResponse,
            dispatch,
        )
        setSearchSellOrderFiltersAction(inititalSellOrdersState.searchSellOrderFilters, dispatch)
        setShouldShowPriceFiltersAction(inititalSellOrdersState.shouldShowPriceFilters, dispatch)
        setIsFormDisabledAction(inititalSellOrdersState.isFormDisabled, dispatch)
        setIsFiltersModalVisibleAction(inititalSellOrdersState.isFiltersModalVisible, dispatch)
        setShowAllLocationsAction(inititalSellOrdersState.showAllLocations, dispatch)
        setShowAllSdgsAction(inititalSellOrdersState.showAllSdgs, dispatch)
        setShouldShowProjectTypeFilterAction(
            inititalSellOrdersState.shouldShowProjectTypeFilter,
            dispatch,
        )
    }

    return (
        <SellOrdersStore.Provider
            value={{
                ...state,
                setSearchSellOrdersResponse,
                setSearchSellOrderFilters,
                setShouldShowPriceFilters,
                setIsFormDisabled,
                setIsFiltersModalVisible,
                setShowAllLocations,
                setShowAllSdgs,
                setShouldShowProjectTypeFilter,
                reset,
            }}
        >
            {children}
        </SellOrdersStore.Provider>
    )
}
