import { useEffect, useState } from 'react'
import Table, { Column, TextAlignment, Values } from '../../table/components/Table'
import DateTime from '../../dateTime/components/DateTimeFormatted'
import ProjectName from '../../project/components/ProjectName'
import { convertGramsToMetricTonesLocaleString } from '../../../utils/conversion'
import { Project } from '../../project/types/Project'
import LoadingSpinner from '../../loadingSpinner/components/LoadingSpinner'
import Note, { NoteType } from '../../note/components/Note'
import * as Sentry from '@sentry/react'
import RegistryIcon from '../../registry/components/RegistryIcon'
import { useUserStore } from '../../user/context/store'
import ID from '../../common/components/ID'
import { SearchUnbridgeParams } from '../http/types/SearchUnbridgeParams'
import { UnbridgeRequestApi } from '../http/api'
import WithdrawalStatusPill from './WithdrawalStatusPill'

type Columns =
    | 'id'
    | 'status'
    | 'date'
    | 'project_name'
    | 'vintage'
    | 'registry'
    | 'quantity'
    | 'action'

interface Props {
    project?: Project
    displayHeading?: boolean
}

const WithdrawalTable = ({ project, displayHeading = false }: Props): JSX.Element => {
    const [unbridgeData, setUnbridgeData] = useState<Values<Columns>[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string | null>(null)
    const { isMarketplaceUser } = useUserStore()

    const marketplaceUser = isMarketplaceUser()

    const getWithdrawals = async (): Promise<void> => {
        if (!marketplaceUser) return
        try {
            setErrMsg(null)
            setIsLoading(true)
            const searchParams: SearchUnbridgeParams = {
                project_id: project?.id,
            }
            const requests = (await UnbridgeRequestApi.search(searchParams))?.data.unbridge_requests
            if (!requests) {
                throw new Error('Unable to get unbridge requests')
            }
            setUnbridgeData(
                requests.map((request) => {
                    return {
                        id: {
                            value: <ID>{request.unbridge_request_id}</ID>,
                        },
                        status: {
                            value: (
                                <WithdrawalStatusPill
                                    status={request.status}
                                ></WithdrawalStatusPill>
                            ),
                        },
                        date: {
                            value: <DateTime date={new Date(request.created_at)}></DateTime>,
                        },
                        project_name: {
                            value: (
                                <ProjectName
                                    squareImageUrl={request.square_image_url}
                                    type={request.project_type}
                                    name={request.project_name}
                                    id={request.project_id}
                                    vintage={request.vintage_year}
                                />
                            ),
                        },
                        vintage: {
                            value: request.vintage_year,
                        },
                        registry: {
                            value: <RegistryIcon registryId={request.registry_id} />,
                        },
                        quantity: {
                            value: convertGramsToMetricTonesLocaleString(Number(request.quantity)),
                            textAlignment: TextAlignment.RIGHT,
                        },
                        action: {
                            value: <></>,
                            textAlignment: TextAlignment.RIGHT,
                        },
                    }
                }),
            )
            setIsLoading(false)
        } catch (err) {
            setErrMsg(
                'There was a problem loading your retirements. Please refresh the page to try again.',
            )
            setIsLoading(false)
            Sentry.captureException(err)
        }
    }

    useEffect(() => {
        getWithdrawals()
    }, [])

    const columns: Column<Columns>[] = [
        {
            name: 'id',
            display: 'Withdrawal',
        },
        {
            name: 'status',
            display: 'Status',
        },
        {
            name: 'date',
            display: 'Date',
        },
        {
            name: 'project_name',
            display: 'Project',
        },
        {
            name: 'vintage',
            display: 'Vintage',
        },
        {
            name: 'registry',
            display: 'Registry',
        },
        {
            name: 'quantity',
            display: 'Quantity',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'action',
            display: '',
        },
    ]

    const heading: React.ReactNode = (
        <h3 className="text-gray-900 font-semibold text-xl mb-2">Withdrawals</h3>
    )

    return (
        <>
            {isLoading && <LoadingSpinner></LoadingSpinner>}
            {errMsg && <Note type={NoteType.ERROR}>{errMsg}</Note>}
            {unbridgeData.length > 0 && !isLoading && !errMsg && (
                <>
                    {displayHeading && heading}
                    <Table columns={columns} data={unbridgeData} />
                </>
            )}
            {unbridgeData.length === 0 && !isLoading && !errMsg && (
                <Note type={NoteType.INFO}>No credit withdrawal requests found</Note>
            )}
        </>
    )
}

export default WithdrawalTable
