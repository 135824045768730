import React from 'react'
import AlignMiddle from '../../common/components/AlignMiddle'

interface Props {
    children: React.ReactNode
}

const RecordDetailActions = ({ children }: Props): JSX.Element => {
    return (
        <AlignMiddle direction="column" childClassName="order-1">
            <div className="flex flex-row gap-2">{children}</div>
        </AlignMiddle>
    )
}

export default RecordDetailActions
