import { getCountryNameFromCountryCode } from '../../../utils/getCountryNameFromCountryCode'
import AlignMiddle from '../../common/components/AlignMiddle'
import { CountryCode } from '../enums/CountryCode'
import { ProjectLocation } from '../enums/ProjectLocation'
import CountryFlag from './CountryFlag'

interface LocationProps {
    location: ProjectLocation
    showFullCountryName?: boolean
}

const Location = ({ location, showFullCountryName = true }: LocationProps): JSX.Element => {
    if (showFullCountryName) {
        return (
            <AlignMiddle direction="column">
                <div
                    className="flex gap-2 items-center justify-start break-normal"
                    title={getCountryNameFromCountryCode(location) ?? location}
                >
                    <CountryFlag location={location} />
                    <p className="pl-1  w-[90px]">{getCountryNameFromCountryCode(location)}</p>
                </div>
            </AlignMiddle>
        )
    }
    const locationKey = Object.keys(ProjectLocation)[
        Object.values(ProjectLocation).indexOf(location)
    ] as keyof typeof CountryCode
    return (
        <div className="flex gap-2" title={getCountryNameFromCountryCode(location) ?? location}>
            <CountryFlag location={location} />
            {Object.keys(CountryCode).includes(locationKey) && (
                <p className="">{CountryCode[locationKey]}</p>
            )}
        </div>
    )
}

export default Location
