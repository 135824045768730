import { useEffect, useState } from 'react'
import Table, { Column, TextAlignment, Values } from '../../table/components/Table'
import DateTimeFormatted from '../../dateTime/components/DateTimeFormatted'
import ProjectName from '../../project/components/ProjectName'
import {
    convertCentsToLocaleUsd,
    convertGramsToMetricTonesLocaleString,
} from '../../../utils/conversion'
import ID from '../../common/components/ID'
import { PayoutTradeDto } from '../http/types/PayoutWithTradesDto'

type Columns =
    | 'trade'
    | 'date'
    | 'sell_order'
    | 'project_name'
    | 'vintage'
    | 'price'
    | 'quantity'
    | 'total'

interface Props {
    trades: PayoutTradeDto[]
}

const PayoutTradesTable = ({ trades }: Props): JSX.Element => {
    const [tradeData, setTradeData] = useState<Values<Columns>[]>([])

    useEffect(() => {
        setTradeData(
            trades.map((trade) => {
                return {
                    trade: {
                        value: <ID>{trade.id}</ID>,
                    },
                    date: {
                        value: <DateTimeFormatted date={new Date(trade.date)} />,
                    },
                    sell_order: {
                        value: (
                            <ID link={`/sell-order/${trade.sell_order_id}`}>
                                {trade.sell_order_id}
                            </ID>
                        ),
                    },
                    project_name: {
                        value: (
                            <ProjectName
                                squareImageUrl={trade.project_data.square_image_url}
                                type={trade.project_data.type}
                                name={trade.project_data.name}
                                id={trade.project_data.id}
                                sellOrderId={trade.sell_order_id}
                            />
                        ),
                    },
                    vintage: {
                        value: trade.vintage,
                    },
                    price: {
                        value: (
                            <div className="font-bold">
                                {convertCentsToLocaleUsd(trade.unit_net_price_cents)}
                            </div>
                        ),
                        textAlignment: TextAlignment.RIGHT,
                    },
                    quantity: {
                        value: convertGramsToMetricTonesLocaleString(Number(trade.quantity)),
                        textAlignment: TextAlignment.RIGHT,
                    },
                    total: {
                        value: (
                            <div className="font-bold">
                                {convertCentsToLocaleUsd(trade.total_net_price_cents)}
                            </div>
                        ),
                        textAlignment: TextAlignment.RIGHT,
                    },
                }
            }),
        )
    }, [trades])

    const columns: Column<Columns>[] = [
        {
            name: 'trade',
            display: 'Trade',
        },
        {
            name: 'date',
            display: 'Date',
        },
        {
            name: 'sell_order',
            display: 'Sell Order',
        },
        {
            name: 'project_name',
            display: 'Project',
        },
        {
            name: 'vintage',
            display: 'Vintage',
        },
        {
            name: 'price',
            display: 'Unit Price',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'quantity',
            display: 'Quantity',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'total',
            display: 'Total',
            textAlignment: TextAlignment.RIGHT,
        },
    ]

    return <Table columns={columns} data={tradeData} />
}

export default PayoutTradesTable
