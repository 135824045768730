export enum ProjectLocation {
    ANDORRA = 'AD',
    UNITED_ARAB_EMIRATES = 'AE',
    AFGHANISTAN = 'AF',
    ANTIGUA_AND_BARBUDA = 'AG',
    ANGUILLA = 'AI',
    ALBANIA = 'AL',
    ARMENIA = 'AM',
    ANGOLA = 'AO',
    ARGENTINA = 'AR',
    AMERICAN_SAMOA = 'AS',
    AUSTRIA = 'AT',
    AUSTRALIA = 'AU',
    ARUBA = 'AW',
    ALAND_ISLANDS = 'AX',
    AZERBAIJAN = 'AZ',
    BOSNIA_AND_HERZEGOVINA = 'BA',
    BARBADOS = 'BB',
    BANGLADESH = 'BD',
    BELGIUM = 'BE',
    BURKINA_FASO = 'BF',
    BULGARIA = 'BG',
    BAHRAIN = 'BH',
    BURUNDI = 'BI',
    BENIN = 'BJ',
    SAINT_BARTHELEMY = 'BL',
    BERMUDA = 'BM',
    BRUNEI_DARUSSALAM = 'BN',
    BOLIVIA = 'BO',
    BONAIRE = 'BQ',
    BRAZIL = 'BR',
    BAHAMAS = 'BS',
    BHUTAN = 'BT',
    BOTSWANA = 'BW',
    BELARUS = 'BY',
    BELIZE = 'BZ',
    CANADA = 'CA',
    COCOS_ISLANDS = 'CC',
    DEMOCRATIC_REPUBLIC_OF_THE_CONGO = 'CD',
    CENTRAL_AFRICAN_REPUBLIC = 'CF',
    CONGO = 'CG',
    SWITZERLAND = 'CH',
    COOK_ISLANDS = 'CK',
    CHILE = 'CL',
    CAMEROON = 'CM',
    CHINA = 'CN',
    COLOMBIA = 'CO',
    COSTA_RICA = 'CR',
    CUBA = 'CU',
    CURACAO = 'CW',
    CHRISTMAS_ISLAND = 'CX',
    CYPRUS = 'CY',
    CZECHIA = 'CZ',
    GERMANY = 'DE',
    DJIBOUTI = 'DJ',
    DENMARK = 'DK',
    DOMINICA = 'DM',
    DOMINICAN_REPUBLIC = 'DO',
    ALGERIA = 'DZ',
    ECUADOR = 'EC',
    ESTONIA = 'EE',
    EGYPT = 'EG',
    WESTERN_SAHARA = 'EH',
    ERITREA = 'ER',
    SPAIN = 'ES',
    ETHIOPIA = 'ET',
    FINLAND = 'FI',
    FIJI = 'FJ',
    FALKLAND_ISLANDS = 'FK',
    MICRONESIA = 'FM',
    FAROE_ISLANDS = 'FO',
    FRANCE = 'FR',
    GABON = 'GA',
    UNITED_KINGDOM = 'GB',
    GRENADA = 'GD',
    GEORGIA = 'GE',
    GUERNSEY = 'GG',
    GHANA = 'GH',
    GIBRALTAR = 'GI',
    GREENLAND = 'GL',
    GAMBIA = 'GM',
    GUINEA = 'GN',
    EQUATORIAL_GUINEA = 'GQ',
    GREECE = 'GR',
    GUATEMALA = 'GT',
    GUAM = 'GU',
    GUINEA_BISSAU = 'GW',
    GUYANA = 'GY',
    HONG_KONG = 'HK',
    HONDURAS = 'HN',
    CROATIA = 'HR',
    HAITI = 'HT',
    HUNGARY = 'HU',
    INDONESIA = 'ID',
    IRELAND = 'IE',
    ISRAEL = 'IL',
    ISLE_OF_MAN = 'IM',
    INDIA = 'IN',
    BRITISH_INDIAN_OCEAN_TERRITORY = 'IO',
    IRAQ = 'IQ',
    IRAN = 'IR',
    ICELAND = 'IS',
    ITALY = 'IT',
    JERSEY = 'JE',
    JAMAICA = 'JM',
    JORDAN = 'JO',
    JAPAN = 'JP',
    KENYA = 'KE',
    KYRGYZSTAN = 'KG',
    CAMBODIA = 'KH',
    KIRIBATI = 'KI',
    COMOROS = 'KM',
    SAINT_KITTS_AND_NEVIS = 'KN',
    NORTH_KOREA = 'KP',
    SOUTH_KOREA = 'KR',
    KUWAIT = 'KW',
    CAYMAN_ISLANDS = 'KY',
    KAZAKHSTAN = 'KZ',
    LAOS = 'LA',
    LEBANON = 'LB',
    SAINT_LUCIA = 'LC',
    LIECHTENSTEIN = 'LI',
    SRI_LANKA = 'LK',
    LIBERIA = 'LR',
    LESOTHO = 'LS',
    LITHUANIA = 'LT',
    LUXEMBOURG = 'LU',
    LATVIA = 'LV',
    LIBYA = 'LY',
    MOROCCO = 'MA',
    MONACO = 'MC',
    MOLDOVA = 'MD',
    MONTENEGRO = 'ME',
    MADAGASCAR = 'MG',
    MARSHALL_ISLANDS = 'MH',
    NORTH_MACEDONIA = 'MK',
    MALI = 'ML',
    MYANMAR = 'MM',
    MONGOLIA = 'MN',
    MACAO = 'MO',
    NORTHERN_MARIANA_ISLANDS = 'MP',
    MARTINIQUE = 'MQ',
    MAURITANIA = 'MR',
    MONTSERRAT = 'MS',
    MALTA = 'MT',
    MAURITIUS = 'MU',
    MALDIVES = 'MV',
    MALAWI = 'MW',
    MEXICO = 'MX',
    MALAYSIA = 'MY',
    MOZAMBIQUE = 'MZ',
    NAMIBIA = 'NA',
    NIGER = 'NE',
    NORFOLK_ISLAND = 'NF',
    NIGERIA = 'NG',
    NICARAGUA = 'NI',
    NETHERLANDS = 'NL',
    NORWAY = 'NO',
    NEPAL = 'NP',
    NAURU = 'NR',
    NIUE = 'NU',
    NEW_ZEALAND = 'NZ',
    OMAN = 'OM',
    PANAMA = 'PA',
    PERU = 'PE',
    FRENCH_POLYNESIA = 'PF',
    PAPUA_NEW_GUINEA = 'PG',
    PHILIPPINES = 'PH',
    PAKISTAN = 'PK',
    POLAND = 'PL',
    PITCAIRN = 'PN',
    PUERTO_RICO = 'PR',
    PALESTINE = 'PS',
    PORTUGAL = 'PT',
    PALAU = 'PW',
    PARAGUAY = 'PY',
    QATAR = 'QA',
    ROMANIA = 'RO',
    SERBIA = 'RS',
    RUSSIAN_FEDERATION = 'RU',
    RWANDA = 'RW',
    SAUDI_ARABIA = 'SA',
    SOLOMON_ISLANDS = 'SB',
    SEYCHELLES = 'SC',
    SUDAN = 'SD',
    SWEDEN = 'SE',
    SINGAPORE = 'SG',
    SLOVENIA = 'SI',
    SLOVAKIA = 'SK',
    SIERRA_LEONE = 'SL',
    SAN_MARINO = 'SM',
    SENEGAL = 'SN',
    SOMALIA = 'SO',
    SURINAME = 'SR',
    SOUTH_SUDAN = 'SS',
    SAO_TOME_AND_PRINCIPE = 'ST',
    EL_SALVADOR = 'SV',
    SINT_MAARTEN = 'SX',
    ESWATINI = 'SZ',
    CHAD = 'TD',
    TIMOR_LESTE = 'TL',
    TUNISIA = 'TN',
    TURKEY = 'TR',
    TRINIDAD_AND_TOBAGO = 'TT',
    UGANDA = 'UG',
    UKRAINE = 'UA',
    UNITED_STATES_OF_AMERICA = 'US',
    URUGUAY = 'UY',
    SAINT_VINCENT_AND_THE_GRENADINES = 'VC',
    VENEZUELA = 'VE',
    VIRGIN_ISLANDS_BRITISH = 'VG',
    VIRGIN_ISLANDS_US = 'VI',
    VIETNAM = 'VN',
    VANUATU = 'VU',
    SAMOA = 'WS',
    YEMEN = 'YE',
    SOUTH_AFRICA = 'ZA',
    ZAMBIA = 'ZM',
    ZIMBABWE = 'ZW',
}
