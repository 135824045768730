import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { Locations } from '../../common/enums/Locations'
import { SellOrderApi } from '../../sellOrders/http/SellOrderApi'
import {
    PurchaseAndRetirePayload,
    RetirePayload,
} from '../../sellOrders/http/types/PurchaseAndRetirePayload'
import { SellOrder } from '../../sellOrders/types/SellOrder'
import { useUserStore } from '../../user/context/store'
import { ActionName } from '../../action/enums/ActionName'
import { generateUniqueId } from '../../../utils/generateUniqueId'
import { ErrorResponse } from '../../http/types/ErrorResponse'
import {
    convertCentsToUsd,
    convertLocaleNumericStringToNumber,
    convertUsdToCents,
    convertMetricTonesToGramsNumeric,
} from '../../../utils/conversion'
import { SingleCarbonCreditBalance } from '../../carbonCreditBalance/types/SingleCarbonCreditBalance'
import BuyCreditsForm from './BuyCreditsForm'
import { InterestApi } from '../http/interest'
import { RetirementRequestsApi } from '../../retirement/http/api'
import CreditsConfirmation from '../../credits/components/CreditsConfirmation'
import { Vintage } from '../../vintage/types/Vintage'
import { Project } from '../../project/types/Project'
import BaseModal from '../../modal/components/BaseModal'
import { useForm } from 'react-hook-form'
import { VintageCarbonCreditBalance } from '../../vintage/types/VintageCarbonCreditBalance'
import { CarbonCreditBalanceSingleResult } from '../../carbonCreditBalance/types/CarbonCreditBalance'
import { useNavigate } from 'react-router-dom'
import { generatePathWithQueryString } from '../../../adminArea/modules/user/http/utils/generatePathWithQueryString'
import { Page } from '../../common/enums/Pages'
import { getCentsNumFromDollarStr } from '../../../utils/getCentsNumFromDollarStr'

interface Props {
    sellOrder?: SellOrder | null
    vintage?: Vintage | null
    project?: Project | CarbonCreditBalanceSingleResult | null
    carbonCreditBalance?: SingleCarbonCreditBalance | VintageCarbonCreditBalance | null
    show: boolean
    showPurchaseComplete: boolean
    defaultAction?: ActionName
    setShowPurchaseComplete: (value: boolean) => void
    setShow: (value: boolean) => void
    onComplete?: () => void
}

export interface BuyFormData {
    quantity: number
    price: string
    note: string
    retireeId: string | null
    retireeName: string | null
    retireeTaxId: string | null
    retireeLocation: Locations | null
}

const defaultFormState: BuyFormData = {
    quantity: 0,
    price: '0',
    note: '',
    retireeId: null,
    retireeName: null,
    retireeTaxId: null,
    retireeLocation: null,
}

const BuyRetireModal = ({
    sellOrder,
    vintage,
    project,
    carbonCreditBalance,
    show,
    showPurchaseComplete,
    defaultAction = ActionName.PURCHASE,
    setShowPurchaseComplete,
    setShow,
    onComplete = (): void => {},
}: Props): JSX.Element => {
    const { user, isCaasProUser, isCaasOrCaasProUser } = useUserStore()
    const tenantDisplayName = `${user!.tenant?.entity_name} (Your Company)`
    const caasProUser = isCaasProUser()
    const caasOrCaasProUser = isCaasOrCaasProUser()
    const navigate = useNavigate()

    const getFormDefault = (): BuyFormData => {
        return {
            ...defaultFormState,
            price: sellOrder?.price
                ? convertCentsToUsd(Number(sellOrder?.price))
                : defaultFormState.price,
            retireeId: user!.tenant_id,
            retireeName: tenantDisplayName,
            retireeTaxId: '-',
            retireeLocation: Locations['United Kingdom'],
        }
    }

    const form = useForm<BuyFormData>({ defaultValues: getFormDefault() })

    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [confirm, setConfirm] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [requestId, setRequestId] = useState<string | null>(null)
    const [action, setAction] = useState<ActionName>(defaultAction)
    const [createSubCustomer, setCreateSubCustomer] = useState<boolean>(false)
    const [expetecedTotalPrice, setExpectedTotalPrice] = useState<string>('0')

    useEffect(() => {
        const requestId = generateUniqueId()
        setRequestId(requestId)
        if (!caasOrCaasProUser) setAction(ActionName.INTEREST)
    }, [])

    const isSubmitButtonEnabled: boolean =
        (([ActionName.PURCHASE, ActionName.RETIRE].includes(action) && caasProUser) ||
            ![ActionName.PURCHASE, ActionName.RETIRE].includes(action)) &&
        form.getValues('quantity') > 0 &&
        ((![ActionName.RETURN, ActionName.RETIRE].includes(action) &&
            convertLocaleNumericStringToNumber(form.getValues('price')) > 0) ||
            true) &&
        !showSpinner &&
        !showPurchaseComplete &&
        !error

    const onClose = (showPurchaseComplete: boolean = false): void => {
        setShow(false)
        form.reset()
        setCreateSubCustomer(false)
        setShowPurchaseComplete(showPurchaseComplete)
        setConfirm(false)
        setError(null)
        setAction(defaultAction)
        // if retire forward to retire screen (need BE to give retirement details back)
        // else (must be buy and hold) forward to buy order screen (need BE to give buy order details back)
    }

    const saveInterest = async (): Promise<void> => {
        if (!sellOrder) return
        await InterestApi.save({
            sell_order_id: sellOrder?.id,
            price: convertUsdToCents(
                convertLocaleNumericStringToNumber(form.getValues('price')),
            ).toString(),
            quantity: BigInt(
                convertMetricTonesToGramsNumeric(Number(form.getValues('quantity'))),
            ).toString(),
            note: form.getValues('note'),
        })
    }

    const computeTotalExpectedPrice = (): string => {
        const priceNum = parseFloat(form.getValues('price').replace(/,/g, ''))

        try {
            return caasOrCaasProUser
                ? (BigInt(sellOrder!.price) * BigInt(form.getValues('quantity'))).toString()
                : (
                      BigInt(convertUsdToCents(priceNum)) * BigInt(form.getValues('quantity'))
                  ).toString()
        } catch {
            return BigInt('0').toString()
        }
    }

    useEffect(() => {
        setExpectedTotalPrice(computeTotalExpectedPrice())
    }, [form.watch('price'), form.watch('quantity')])

    const purchaseAndRetire = async (): Promise<void> => {
        if (!sellOrder) return
        const retireeId = form.getValues('retireeId')
        const retireeName = form.getValues('retireeName')
        const retireeLocation = form.getValues('retireeLocation')
        const retireeTaxId = form.getValues('retireeTaxId')
        const quantiy = form.getValues('quantity')

        const isSubCustomerTenant = retireeId === user!.tenant_id
        const subCustomer = {
            external_retiree_id: isSubCustomerTenant ? undefined : retireeId || undefined,
            external_retiree_location: isSubCustomerTenant
                ? undefined
                : retireeLocation || undefined,
            external_retiree_name: isSubCustomerTenant ? undefined : retireeName || undefined,
            external_retiree_tax_id: isSubCustomerTenant ? undefined : retireeTaxId || undefined,
        }
        const purchasePayload: PurchaseAndRetirePayload = {
            sell_order_id: sellOrder.id,
            expected_price_cents: sellOrder.price,
            quantity_grams: BigInt(convertMetricTonesToGramsNumeric(Number(quantiy))).toString(),
            vintage_id: vintage!.id,
            should_retire_external_customer: !!retireeId && retireeId !== user!.tenant_id,
            ...subCustomer,
        }

        const retirementData = await SellOrderApi.purchaseAndRetire(purchasePayload, requestId!)

        if (retirementData) {
            // forward to the new retirement details screen
            navigate(
                generatePathWithQueryString(Page.RETIREMENT, {
                    id: retirementData.data.retirement_request_id,
                }),
            )
        }
    }

    const buyAndHold = async (): Promise<void> => {
        if (!sellOrder) return
        const purchasePayload: PurchaseAndRetirePayload = {
            sell_order_id: sellOrder.id,
            expected_price_cents: sellOrder.price,
            quantity_grams: String(
                convertMetricTonesToGramsNumeric(Number(form.getValues('quantity'))),
            ),
            vintage_id: vintage!.id,
        }

        const purchaseData = await SellOrderApi.purchase(purchasePayload, requestId!)

        if (purchaseData) {
            // forward to the new buy order details screen
            navigate(
                generatePathWithQueryString(Page.ORDERS_BUY_ORDER, {
                    id: purchaseData.data.buy_order_id,
                }),
            )
        }
    }

    const getVintageId = (): string => {
        if (!carbonCreditBalance) return ''
        if ('vintage' in carbonCreditBalance) return carbonCreditBalance.vintage.id
        return carbonCreditBalance.vintage_id
    }

    const retireFromHold = async (): Promise<void> => {
        const retireeId = form.getValues('retireeId')
        const retireeName = form.getValues('retireeName')
        const retireeLocation = form.getValues('retireeLocation')
        const retireeTaxId = form.getValues('retireeTaxId')
        const quantity = form.getValues('quantity')

        const isSubCustomerTenant = retireeId === user!.tenant_id
        const subCustomer = {
            external_retiree_id: isSubCustomerTenant ? undefined : retireeId || undefined,
            external_retiree_location: isSubCustomerTenant
                ? undefined
                : retireeLocation || undefined,
            external_retiree_name: isSubCustomerTenant ? undefined : retireeName || undefined,
            external_retiree_tax_id: isSubCustomerTenant ? undefined : retireeTaxId || undefined,
        }
        const purchasePayload: RetirePayload = {
            quantity_grams: String(convertMetricTonesToGramsNumeric(Number(quantity))),
            vintage_id: getVintageId(),
            should_retire_external_customer: !!retireeId && retireeId !== user!.tenant_id,
            ...subCustomer,
        }

        await RetirementRequestsApi.retireFromHold(purchasePayload, requestId!)
    }

    const onSubmitHandler = async (): Promise<void> => {
        const methods: Partial<Record<ActionName, () => Promise<void>>> = {
            [ActionName.BUY_RETIRE]: purchaseAndRetire,
            [ActionName.INTEREST]: saveInterest,
            [ActionName.PURCHASE]: buyAndHold,
            [ActionName.RETIRE]: retireFromHold,
        }
        const method = methods[action]
        if (!method) {
            throw Error(`Action: ${action} not configured`)
        }
        try {
            setShowSpinner(true)
            if (!requestId) {
                throw new Error(
                    'An unexpected error has occurred, please refresh the page and try again',
                )
            }
            await method()
            onClose(true)
            onComplete()
        } catch (e: unknown) {
            const err = e as AxiosError<ErrorResponse>
            setError(
                (err?.response?.data?.message as string) ??
                    'An unexpected error has occurred, please refresh the page and try again',
            )
        } finally {
            setShowSpinner(false)
        }
    }

    const onReviewPurchase = (): void => {
        setConfirm(true)
    }

    const onEditPurchse = (): void => {
        setConfirm(false)
    }

    return (
        <BaseModal
            show={show}
            id="buy-retire-modal"
            position="center"
            onClose={() => onClose(false)}
            error={error}
            showSpinner={showSpinner}
        >
            <>
                {!confirm && (
                    <div className="">
                        <BuyCreditsForm
                            sellOrder={sellOrder}
                            carbonCreditBalance={carbonCreditBalance}
                            onSubmitHandler={onReviewPurchase}
                            form={form}
                            action={action}
                            expetecedTotalPrice={expetecedTotalPrice}
                            isSubmitButtonEnabled={isSubmitButtonEnabled}
                            showSpinner={showSpinner}
                            onClose={onClose}
                            createSubCustomer={createSubCustomer}
                            setCreateSubCustomer={setCreateSubCustomer}
                            setAction={setAction}
                            setError={setError}
                        />
                    </div>
                )}
                {confirm && (
                    <CreditsConfirmation
                        actionName={action}
                        showSpinner={showSpinner}
                        price={expetecedTotalPrice}
                        quantity={convertMetricTonesToGramsNumeric(form.getValues('quantity'))}
                        vintage={vintage!}
                        project={project!}
                        pricePerCredit={form.getValues('price')}
                        pricePerCreditCents={getCentsNumFromDollarStr(form.getValues('price'))}
                        onClose={onClose}
                        onBack={onEditPurchse}
                        onConfirm={onSubmitHandler}
                        retireeName={form.getValues('retireeName') || undefined}
                        isSubmitButtonEnabled={isSubmitButtonEnabled}
                    />
                )}
            </>
        </BaseModal>
    )
}

export default BuyRetireModal
