import { SetToastMessageAction, SET_TOAST_MESSAGE, ToastMessage } from './types'
import { Dispatch } from 'react'

export const setToastMessage = (
    message: ToastMessage | null,
    dispatch: Dispatch<SetToastMessageAction>,
): void => {
    dispatch({
        type: SET_TOAST_MESSAGE,
        payload: { message },
    })
}
