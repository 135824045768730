import Slider from 'rc-slider'

export interface QuantitySliderProps {
    value: number
    onChange: (value: number | number[]) => void
    min?: number
    max?: number
}

export default function QuantitySlider({
    value,
    onChange,
    min = 0,
    max = 100,
}: QuantitySliderProps) {
    return (
        <div className="w-full flex flex-row gap-2" data-context="slider">
            <div className="flex w-[90%]">
                <Slider
                    min={min}
                    max={max}
                    value={value}
                    onChange={onChange}
                    trackStyle={{
                        backgroundColor: '#BE5D29',
                        height: 8,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                    }}
                    railStyle={{
                        backgroundColor: '#FFEEE3',
                        height: 8,
                    }}
                    handleStyle={{
                        height: 8,
                        width: 8,
                        marginTop: 0,
                        backgroundColor: '#BE5D29',
                        border: 'none',
                        opacity: 1,
                        boxShadow: 'none',
                    }}
                />
            </div>
            <p className="text-right text-sm font-semibold text-gray-700">{value}%</p>
        </div>
    )
}
