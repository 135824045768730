import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { AdminSearchCarbonCreditBalanceParams } from '../../modules/carbonCreditBalance/http/types/AdminSearchCarbonCreditBalanceParams'
import { AdminCarbonCreditBalanceApi } from '../../modules/carbonCreditBalance/http/api'
import { Page } from '../../../modules/common/enums/Pages'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import CarbonCreditBalancesTable from '../../modules/carbonCreditBalance/components/CarbonCreditBalancesTable'
import { AdminSearchCarbonCreditBalancesResponse } from '../../modules/carbonCreditBalance/http/types/AdminSearchCarbonCreditBalancesResponse'

const AdminCarbonCreditBalances = (): JSX.Element => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchCarbonCreditsResponse, setSearchCarbonCreditsResponse] =
        useState<AdminSearchCarbonCreditBalancesResponse | null>(null)

    const searchCarbonCredits = async (
        params: AdminSearchCarbonCreditBalanceParams = {},
    ): Promise<void> => {
        const response = await AdminCarbonCreditBalanceApi.adminSearch(params)
        if (response) {
            setSearchCarbonCreditsResponse(response.data)
        }
    }

    useEffect(() => {
        void searchCarbonCredits({
            page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
        })
    }, [searchParams])

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.CARBON_CREDIT_BALANCES}>
            <div className="flex justify-end mb-4">
                <Link
                    to={Page.ADMIN_CREATE_CARBON_CREDIT_BALANCE}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                    </svg>
                    Add
                </Link>
            </div>
            <CarbonCreditBalancesTable
                searchCarbonCreditsResponse={searchCarbonCreditsResponse}
                onPageChangeHandler={(pageNumber: number) => {
                    setSearchParams({ page: String(pageNumber) })
                }}
                onItemDeleted={() => {
                    void searchCarbonCredits({
                        page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
                    })
                }}
            />
        </AdminLayout>
    )
}

export default AdminCarbonCreditBalances
