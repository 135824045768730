interface TagButtonProps {
    name: string
    onClose: () => void
    disabled: boolean
}

export const TagButton = ({ name, onClose, disabled }: TagButtonProps): JSX.Element => {
    return (
        <div className="py-2.5 px-4 flex gap-2 items-center justify-between bg-primary-50 rounded-lg mb-[25px] break-words">
            <p className="text-primary-700 font-bold text-sm">{name}</p>
            <button onClick={onClose} disabled={disabled}>
                <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11 1L1 11M1 1L11 11"
                        stroke="#A55224"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
        </div>
    )
}
