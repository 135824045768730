import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { EmptyResponse } from '../../http/types/EmptyResponse'
import { SearchDatetimeParams } from '../../http/types/SearchDatetimeParams'
import { SearchMyOrdersParams } from '../../myOrders/http/types/SearchMyOrdersParams'
import { SDG } from '../../project/enums/SDG'
import { TradeHistoryTotalMonthValueDetails } from './types/TradeHistoryTotalMonthValueDetails'
import { PurchaseOrders } from './types/PurchaseOrders'
import { PurchaseOrderStats } from './types/PurchaseOrderStats'
import { CreditMethodologyData } from './types/CreditMethodologyData'
import { RegistryData } from './types/RegistryData'
import { AveragePriceData } from './types/AveragePriceData'
import { TradeHistoryAllOrdersResponse } from './types/TradeHistoryAllOrdersResponse'

export interface TradeHistoryApiInterface {
    getFinancialDataForTotalValueOfCreditsPurchasedGraph(
        params: SearchDatetimeParams,
    ): Promise<AxiosResponse<TradeHistoryTotalMonthValueDetails[]> | undefined>
    getCarbonDataForTotalValueOfCreditsPurchasedGraph(
        params: SearchDatetimeParams,
    ): Promise<AxiosResponse<TradeHistoryTotalMonthValueDetails[]> | undefined>
    getPurchaseOrders(params: SearchDatetimeParams): Promise<AxiosResponse<PurchaseOrders>>
    getPurchaseOrderStats(params: SearchDatetimeParams): Promise<AxiosResponse<PurchaseOrderStats>>
    getSDGsDataByPurchasedCredits(
        params: SearchDatetimeParams,
    ): Promise<AxiosResponse<SDG[]> | undefined>
    getCreditMethodologyData(
        params: SearchDatetimeParams,
    ): Promise<AxiosResponse<CreditMethodologyData[]> | undefined>
    getRegistryData(
        params: SearchDatetimeParams,
    ): Promise<AxiosResponse<RegistryData[]> | undefined>
    getAveragePricePerTCO2e(
        params: SearchDatetimeParams,
    ): Promise<AxiosResponse<AveragePriceData[]> | undefined>
    getAllOrders(
        params: SearchMyOrdersParams,
    ): Promise<AxiosResponse<TradeHistoryAllOrdersResponse> | undefined>
    resendSlackNotification(id: string): Promise<AxiosResponse<EmptyResponse> | undefined>
}

export const TradeHistoryApi: TradeHistoryApiInterface = Object.freeze({
    getFinancialDataForTotalValueOfCreditsPurchasedGraph(
        params: SearchDatetimeParams,
    ): Promise<AxiosResponse<TradeHistoryTotalMonthValueDetails[]> | undefined> {
        return HttpService.get('/trade-history/orders/aggregate', null, params)
    },
    getCarbonDataForTotalValueOfCreditsPurchasedGraph(
        params: SearchDatetimeParams,
    ): Promise<AxiosResponse<TradeHistoryTotalMonthValueDetails[]> | undefined> {
        return HttpService.get('/trade-history/orders/aggregate-carbon-data', null, params)
    },
    getPurchaseOrders(params: SearchDatetimeParams): Promise<AxiosResponse<PurchaseOrders>> {
        return HttpService.get('/trade-history/purchase-orders', null, params)
    },
    getPurchaseOrderStats(
        params: SearchDatetimeParams,
    ): Promise<AxiosResponse<PurchaseOrderStats>> {
        return HttpService.get('/trade-history/purchase-orders/stats', null, params)
    },
    getSDGsDataByPurchasedCredits(params: SearchDatetimeParams): Promise<AxiosResponse<SDG[]>> {
        return HttpService.get('/trade-history/sdgs-by-credits-purchased', null, params)
    },
    getCreditMethodologyData(
        params: SearchDatetimeParams,
    ): Promise<AxiosResponse<CreditMethodologyData[]> | undefined> {
        return HttpService.get('/trade-history/credit-methodology-data', null, params)
    },
    getRegistryData(
        params: SearchDatetimeParams,
    ): Promise<AxiosResponse<RegistryData[]> | undefined> {
        return HttpService.get('/trade-history/registry-data', null, params)
    },
    getAveragePricePerTCO2e(
        params: SearchDatetimeParams,
    ): Promise<AxiosResponse<AveragePriceData[]> | undefined> {
        return HttpService.get('/trade-history/average-tco2e-price', null, params)
    },
    getAllOrders(
        params: SearchMyOrdersParams,
    ): Promise<AxiosResponse<TradeHistoryAllOrdersResponse> | undefined> {
        return HttpService.get('/trade-history/all-orders', null, params)
    },
    resendSlackNotification(id: string): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post(`/admin/trade-history/resend-slack/${id}`, {})
    },
})
