import React, { useEffect, useState } from 'react'
import AlignMiddle from '../../common/components/AlignMiddle'

export enum Colour {
    BLUE,
    GREEN,
    YELLOW,
    RED,
    GREY,
}

export enum CircleStyle {
    FILLED,
    SEMI_FILLED,
    NOT_FILLED,
    NONE,
}

interface Props {
    colour: Colour
    circleStyle: CircleStyle
    children: React.ReactNode
    width?: string
}

export const semiCircle = (className: string): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            zoomAndPan="magnify"
            viewBox="0 0 810 809.999993"
            preserveAspectRatio="xMidYMid meet"
            version="1.0"
        >
            <defs>
                <clipPath id="64904d4473">
                    <path d="M 28.761719 23.984375 L 780.953125 23.984375 L 780.953125 776.175781 L 28.761719 776.175781 Z M 28.761719 23.984375 " />
                </clipPath>
                <clipPath id="022f3026cc">
                    <path d="M 404.855469 23.984375 C 197.144531 23.984375 28.761719 192.371094 28.761719 400.082031 C 28.761719 607.792969 197.144531 776.175781 404.855469 776.175781 C 612.566406 776.175781 780.953125 607.792969 780.953125 400.082031 C 780.953125 192.371094 612.566406 23.984375 404.855469 23.984375 Z M 404.855469 23.984375 " />
                </clipPath>
                <clipPath id="6018211a1d">
                    <path d="M 61.550781 56 L 405.050781 56 L 405.050781 744 L 61.550781 744 Z M 61.550781 56 " />
                </clipPath>
            </defs>
            <g clipPath="url(#64904d4473)">
                <g clipPath="url(#022f3026cc)">
                    <path
                        strokeLinecap="butt"
                        transform="matrix(0.75, 0, 0, 0.75, 28.760058, 23.985456)"
                        fill="none"
                        d="M 501.460573 -0.00144175 C 224.512643 -0.00144175 0.0022148 224.514195 0.0022148 501.462125 C 0.0022148 778.410056 224.512643 1002.920484 501.460573 1002.920484 C 778.408504 1002.920484 1002.92414 778.410056 1002.92414 501.462125 C 1002.92414 224.514195 778.408504 -0.00144175 501.460573 -0.00144175 Z M 501.460573 -0.00144175 "
                        strokeWidth="400"
                    />
                </g>
            </g>
            <g clipPath="url(#6018211a1d)">
                <path d="M 61.492188 400.265625 C 61.492188 305.273438 99.96875 219.648438 162.203125 157.4375 C 224.433594 95.226562 310.421875 56.761719 405.113281 56.761719 C 405.113281 168.140625 405.113281 632.054688 405.113281 743.765625 C 310.425781 743.765625 224.433594 705.304688 162.203125 643.089844 C 99.96875 580.878906 61.492188 494.917969 61.492188 400.265625 Z M 61.492188 400.265625 " />
            </g>
        </svg>
    )
}

const fullCircle = (className: string): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            zoomAndPan="magnify"
            viewBox="0 0 810 809.999993"
            preserveAspectRatio="xMidYMid meet"
            version="1.0"
        >
            <defs>
                <clipPath id="c8130d6d63">
                    <path d="M 28.761719 23.984375 L 780.953125 23.984375 L 780.953125 776.175781 L 28.761719 776.175781 Z M 28.761719 23.984375 " />
                </clipPath>
                <clipPath id="a63cbd2eae">
                    <path d="M 404.855469 23.984375 C 197.144531 23.984375 28.761719 192.371094 28.761719 400.082031 C 28.761719 607.792969 197.144531 776.175781 404.855469 776.175781 C 612.566406 776.175781 780.953125 607.792969 780.953125 400.082031 C 780.953125 192.371094 612.566406 23.984375 404.855469 23.984375 Z M 404.855469 23.984375 " />
                </clipPath>
            </defs>
            <g clipPath="url(#c8130d6d63)">
                <g clipPath="url(#a63cbd2eae)">
                    <path
                        strokeLinecap="butt"
                        transform="matrix(0.75, 0, 0, 0.75, 28.760058, 23.985456)"
                        d="M 501.460573 -0.00144175 C 224.512643 -0.00144175 0.0022148 224.514195 0.0022148 501.462125 C 0.0022148 778.410056 224.512643 1002.920484 501.460573 1002.920484 C 778.408504 1002.920484 1002.92414 778.410056 1002.92414 501.462125 C 1002.92414 224.514195 778.408504 -0.00144175 501.460573 -0.00144175 Z M 501.460573 -0.00144175 "
                        strokeWidth="400"
                    />
                </g>
            </g>
        </svg>
    )
}

const StatusPill = ({ colour, circleStyle, children, width = 'w-[100px]' }: Props): JSX.Element => {
    const [wrapperClasses, setWrapperClasses] = useState<string>()
    const [circleClasses, setCircleClasses] = useState<string>('')

    useEffect(() => {
        let circleStrokeColour = ''
        let circleFillColour = ''
        switch (colour) {
            case Colour.GREY:
                circleStrokeColour = 'stroke-gray-500'
                circleFillColour = 'fill-gray-500'
                setWrapperClasses(`bg-gray-100 text-gray-500`)
                break
            case Colour.BLUE:
                circleStrokeColour = 'stroke-blue-500'
                circleFillColour = 'fill-blue-500'
                setWrapperClasses(`bg-blue-100 text-blue-500`)
                break
            case Colour.YELLOW:
                circleStrokeColour = 'stroke-yellow-500'
                circleFillColour = 'fill-yellow-500'
                setWrapperClasses(`bg-yellow-100 text-yellow-500`)
                break
            case Colour.GREEN:
                circleStrokeColour = 'stroke-green-500'
                circleFillColour = 'fill-green-500'
                setWrapperClasses(`bg-green-100 text-green-500`)
                break
            case Colour.RED:
                circleStrokeColour = 'stroke-red-500'
                circleFillColour = 'fill-red-500'
                setWrapperClasses(`bg-red-100 text-red-500`)
                break
            default:
                throw new Error(`Uknown status pill colour: ${colour}`)
        }

        if (circleStyle === CircleStyle.NOT_FILLED) {
            setCircleClasses(`w-[12px] h-[12px] ${circleStrokeColour} fill-transparent`)
        } else {
            setCircleClasses(`w-[12px] h-[12px] ${circleStrokeColour} ${circleFillColour}`)
        }
    }, [colour, circleStyle])

    return (
        <AlignMiddle direction="row" childClassName="-order-1">
            <div
                className={`py-1 px-2 ${wrapperClasses} text-center rounded-md flex flex-row gap-2 whitespace-nowrap ${width} text-sm`}
            >
                {circleStyle !== CircleStyle.NONE && (
                    <div className="flex flex-col">
                        <span className="flex-1"></span>
                        {circleStyle === CircleStyle.SEMI_FILLED && semiCircle(circleClasses)}
                        {(circleStyle === CircleStyle.FILLED ||
                            circleStyle === CircleStyle.NOT_FILLED) &&
                            fullCircle(`${circleClasses}`)}
                        <span className="flex-1"></span>
                    </div>
                )}
                <div className="mx-auto">{children}</div>
            </div>
        </AlignMiddle>
    )
}

export default StatusPill
