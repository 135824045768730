import { SyntheticEvent, useState } from 'react'

interface EllipsisTextProps {
    text: string
    maxLength: number
}

const EllipsisText = ({ text, maxLength }: EllipsisTextProps): JSX.Element => {
    const [showFullText, setShowFullText] = useState(false)

    const handleClick = (e: SyntheticEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setShowFullText(!showFullText)
    }

    return (
        <>
            {showFullText ? (
                <span>{text}</span>
            ) : (
                <span>
                    {text.length > maxLength ? `${text.substring(0, maxLength)}` : text}
                    {text.length > maxLength && <button onClick={handleClick}>...</button>}
                </span>
            )}
        </>
    )
}

export default EllipsisText
