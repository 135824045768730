import Layout from '../../modules/common/components/Layout'
import MarketOrders from './components/MarketOrders'
import MarketFiltersModal from './components/MarketFiltersModal'
import MarketFilters from './components/MarketFilters'
import { NavBarItemName } from '../../modules/header/components/NavBar'

const Market = (): JSX.Element => {
    return (
        <Layout
            title="Market"
            subTitle="Search our inventory for carbon credits that match your needs."
            dataCy="market-layout"
            selectedNavBarItem={NavBarItemName.MARKET}
        >
            <MarketFilters />
            <div className="relative mt-7.5 mb-20">
                <div className="container mx-auto">
                    <MarketOrders />
                </div>
            </div>
            <MarketFiltersModal />
        </Layout>
    )
}

export default Market
