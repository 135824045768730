import { PayoutTrade } from '../http/types/PayoutTrade'
import {
    convertGramsToMetricTonesLocaleString,
    convertCentsToLocaleUsd,
} from '../../../../utils/conversion'
import RegistryIcon from '../../../../modules/registry/components/RegistryIcon'
import DateTimeFormatted from '../../../../modules/dateTime/components/DateTimeFormatted'
import ProjectName from '../../../../modules/project/components/ProjectName'

interface PayoutTradesTableProps {
    trades: PayoutTrade[]
}

const PayoutTradesTable = ({ trades }: PayoutTradesTableProps) => {
    if (!trades.length) {
        return null
    }
    return (
        <div
            className="mt-3 mb-2 border border-gray-200 rounded-lg overflow-hidden shadow-md shadow-black"
            style={{
                boxShadow:
                    '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
            }}
        >
            <table className="w-full">
                <thead className="w-full">
                    <tr className="h-11 flex items-center w-full bg-gray-50">
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            TRADE ID
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            PROJECT ID
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            PROJECT NAME
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            VINTAGE
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            REGISTRY
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            DATE
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            QUANTITY
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            PRICE
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6">
                            AMOUNT
                        </th>
                    </tr>
                </thead>
                <tbody className="w-full">
                    {trades.map((trade) => (
                        <tr className="flex items-center w-full" key={trade.id}>
                            <td className="flex-1 py-4 text-gray-500 text-sm text-left pl-6 font-normal break-all">
                                {trade.id}
                            </td>
                            <td className="flex-1 py-4 text-gray-500 text-sm text-left pl-6 font-normal break-all">
                                {trade.project_id}
                            </td>
                            <td className="flex-1 py-4 text-gray-500 text-sm text-left pl-6 font-normal break-all">
                                <ProjectName
                                    squareImageUrl={trade.square_image_url}
                                    type={trade.project_type}
                                    name={trade.project_name}
                                    id={trade.project_id}
                                    vintage={trade.vintage}
                                />
                            </td>
                            <td className="flex-1 py-4 text-gray-500 text-sm text-left pl-6 font-normal break-all">
                                {trade.vintage}
                            </td>
                            <td className="flex-1 py-4 text-gray-500 text-sm text-left pl-6 font-normal break-all">
                                <RegistryIcon registryId={trade.registry_id} />
                            </td>
                            <td className="flex-1 py-4 text-gray-500 text-sm text-left pl-6 font-normal break-all">
                                <DateTimeFormatted date={new Date(trade.created_at)} />
                            </td>
                            <td className="flex-1 py-4 text-gray-500 text-sm text-left pl-6 font-normal break-all">
                                {convertGramsToMetricTonesLocaleString(
                                    Number(trade.quantity_grams),
                                )}
                            </td>
                            <td className="flex-1 py-4 text-gray-500 text-sm text-left pl-6 font-normal break-all">
                                {convertCentsToLocaleUsd(BigInt(trade.price_cents))}
                            </td>
                            <td className="flex-1 py-4 text-gray-500 text-sm text-left pl-6 font-normal break-all">
                                {convertCentsToLocaleUsd(BigInt(trade.total_credits_cost))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default PayoutTradesTable
