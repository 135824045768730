import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Page } from '../../../modules/common/enums/Pages'
import { useRegistryStore } from '../../../modules/registry/context/store'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import CreateCarbonCreditBalanceForm from '../../modules/carbonCreditBalance/components/CreateCarbonCreditBalanceForm'

const AdminCreateCarbonCreditBalance = (): JSX.Element => {
    const { fetchRegistries } = useRegistryStore()
    const navigate = useNavigate()

    useEffect(() => {
        fetchRegistries()
    }, [])

    const onSavedHandler = async (): Promise<void> => {
        navigate(Page.ADMIN_CARBON_CREDIT_BALANCES)
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.CARBON_CREDIT_BALANCES}>
            <CreateCarbonCreditBalanceForm onSaved={onSavedHandler} />
        </AdminLayout>
    )
}

export default AdminCreateCarbonCreditBalance
