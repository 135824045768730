import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Select from 'react-select'
import { AdminUserApi } from '../../user/http/AdminUserApi'
import { AdminVintageApi } from '../../vintage/http/api'
import { AdminCarbonCreditBalanceApi } from '../http/api'
import { useAdminStore } from '../../../../modules/admin/context/store'
import { BackedBySource } from '../../../../modules/carbonCreditBalance/enums/BackedBySource'
import { convertMetricTonesToGramsNumeric } from '../../../../utils/conversion'
import FieldErrorLabel from '../../common/components/FieldErrorLabel'
import { SearchTenantsParams } from '../../tenant/http/types/SearchTenantsParams'
import { SearchUsersResponse } from '../../user/http/types/SearchUsersResponse'
import { SearchVintagesParams } from '../../vintage/http/types/SearchVintagesParams'
import { SearchVintagesResponse } from '../../vintage/http/types/SearchVintagesResponse'
import { ErrorResponse } from '../../../../modules/http/types/ErrorResponse'

export interface CreateSellOrderForm {
    tenant_id: string
    vintage_id: string
    liquid_amount: number
    caas_liquid_amount: number
    backed_by_source: BackedBySource
}

export interface CreateSellOrderFormProps {
    onSaved: () => void
}

const CreateCarbonCreditBalanceForm = ({ onSaved }: CreateSellOrderFormProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [selectedVintageId, setSelectedVintageId] = useState<string | null>(null)
    const [selectedTenantId, setSelectedTenantId] = useState<string | null>(null)
    const [backedBySource, setBackedBySource] = useState<BackedBySource | null>(null)

    const [searchUserResponse, setsearchUserResponse] = useState<SearchUsersResponse | null>(null)
    const [searchVintagesResponse, setSearchVintagesResponse] =
        useState<SearchVintagesResponse | null>(null)

    const { setShowAlertMessage } = useAdminStore()

    const searchUsers = async (params: SearchTenantsParams = {}): Promise<void> => {
        setIsLoading(true)
        try {
            const response = await AdminUserApi.search({
                ...params,
            })
            if (response) {
                setsearchUserResponse(response.data)
            }
        } finally {
            setIsLoading(false)
        }
    }

    const searchVintages = async (params: SearchVintagesParams = {}): Promise<void> => {
        setIsLoading(true)
        try {
            const response = await AdminVintageApi.adminSearch({
                ...params,
            })
            if (response) {
                setSearchVintagesResponse(response.data)
            }
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        // TODO: in the future it should be replaced with some kind of searchable input field or maybe loading next page on scoll down to the end of the list
        void searchUsers({ per_page: 1000 })
        void searchVintages({ per_page: 1000 })
    }, [])

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<CreateSellOrderForm>()

    const onSubmit = async (data: any): Promise<void> => {
        if (!selectedTenantId) {
            setError('tenant_id', { type: 'required' })
            return
        } else if (!selectedVintageId) {
            setError('vintage_id', { type: 'required' })
            return
        } else if (!backedBySource) {
            setError('backed_by_source', { type: 'required' })
            return
        } else {
            try {
                await AdminCarbonCreditBalanceApi.create(
                    selectedTenantId,
                    selectedVintageId,
                    convertMetricTonesToGramsNumeric(Number(data.liquid_amount)).toString(),
                    convertMetricTonesToGramsNumeric(Number(data.caas_liquid_amount)).toString(),
                    backedBySource,
                )
                onSaved()
                setShowAlertMessage({
                    color: 'success',
                    text: 'Successfully saved',
                })
            } catch (e) {
                const err = e as AxiosError<ErrorResponse>
                if (err.response?.data?.message === 'Entity already exists') {
                    setShowAlertMessage({
                        color: 'failure',
                        text: 'Carbon credit balance already exists for the selected entity and vintage',
                    })
                } else {
                    setShowAlertMessage({
                        color: 'failure',
                        text: 'An unexpected error has occurred, please try again',
                    })
                    throw e
                }
            }
        }
    }
    const onVintageSelectedHandler = (selectedOption: any): void => {
        clearErrors()
        setSelectedVintageId(selectedOption.value)
    }

    const onTenantSelectedHandler = (selectedOption: any): void => {
        clearErrors()
        setSelectedTenantId(selectedOption.value)
    }

    const onBackedBySourceHandler = (selectedOption: any): void => {
        clearErrors()
        setBackedBySource(selectedOption.value)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-3/4 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">User / Entity</label>
                    <Select
                        options={searchUserResponse?.users?.map((user) => {
                            return {
                                value: user.tenant?.id,
                                label: `${user.first_name}  ${user.last_name} - ${user.email}`,
                            }
                        })}
                        styles={{
                            valueContainer: (provided: any) => ({
                                ...provided,
                                backgroundColor: '#f9fafb',
                            }),
                            control: (provided: any) => ({
                                ...provided,
                                backgroundColor: '#f9fafb',
                            }),
                            indicatorSeparator: () => ({ display: 'none' }),
                        }}
                        isLoading={isLoading}
                        isSearchable={true}
                        onInputChange={(value) => {
                            void searchUsers({
                                search: value,
                            })
                        }}
                        onChange={onTenantSelectedHandler}
                    />
                    {errors?.tenant_id?.type === 'required' && (
                        <FieldErrorLabel error="Tenant is required" />
                    )}
                </div>
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-3/4 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">Vintage</label>
                    <Select
                        options={searchVintagesResponse?.vintages.map((vintage) => {
                            return {
                                value: vintage.id,
                                label: `${vintage.project.name} - ${vintage.vintage}`,
                            }
                        })}
                        styles={{
                            valueContainer: (provided: any) => ({
                                ...provided,
                                backgroundColor: '#f9fafb',
                            }),
                            control: (provided: any) => ({
                                ...provided,
                                backgroundColor: '#f9fafb',
                            }),
                            indicatorSeparator: () => ({ display: 'none' }),
                        }}
                        isLoading={isLoading}
                        isSearchable={true}
                        onInputChange={(value) => {
                            void searchVintages({
                                project_name: value,
                            })
                        }}
                        onChange={onVintageSelectedHandler}
                    />
                    {errors?.vintage_id?.type === 'required' && (
                        <FieldErrorLabel error="Vintage is required" />
                    )}
                </div>
            </div>
            <div className="mb-6 text-left w-1/3">
                <label className="mb-2 text-sm font-medium text-gray-900">Liquid amount</label>
                <input
                    type="number"
                    step={0.000001}
                    min={0}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full"
                    placeholder="Quantity..."
                    {...register('liquid_amount', {
                        required: true,
                    })}
                />
                {errors?.liquid_amount?.type === 'required' && (
                    <FieldErrorLabel error="Liquid amount is required" />
                )}
            </div>
            <div className="mb-6 text-left w-1/3">
                <label className="mb-2 text-sm font-medium text-gray-900">CaaS Liquid amount</label>
                <input
                    type="number"
                    step={0.000001}
                    min={0}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full"
                    placeholder="Quantity..."
                    {...register('caas_liquid_amount', {
                        required: true,
                    })}
                />
                {errors?.caas_liquid_amount?.type === 'required' && (
                    <FieldErrorLabel error="CaaS Liquid amount is required" />
                )}
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-3/4 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        Backed By Source
                    </label>
                    <Select
                        options={Object.values(BackedBySource).map((source) => {
                            return {
                                value: source,
                                label: source,
                            }
                        })}
                        styles={{
                            valueContainer: (provided: any) => ({
                                ...provided,
                                backgroundColor: '#f9fafb',
                            }),
                            control: (provided: any) => ({
                                ...provided,
                                backgroundColor: '#f9fafb',
                            }),
                            indicatorSeparator: () => ({ display: 'none' }),
                        }}
                        isLoading={isLoading}
                        isSearchable={true}
                        onChange={onBackedBySourceHandler}
                    />
                    {errors?.backed_by_source?.type === 'required' && (
                        <FieldErrorLabel error="Backed By Source is required" />
                    )}
                </div>
            </div>
            <div className="flex items-center pt-6 space-x-2 border-t border-gray-200 rounded-b">
                <button
                    data-modal-toggle="defaultModal"
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save
                </button>
            </div>
        </form>
    )
}

export default CreateCarbonCreditBalanceForm
