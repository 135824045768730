import { DateTime } from 'luxon'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'
import { AuthenticationApi } from '../../../modules/auth/http/AuthenticationApi'
import { Page } from '../../../modules/common/enums/Pages'
import { useCookiesManagementStore } from '../../../modules/cookies/context/store'
import { CookieKey } from '../../../modules/cookies/enums/CookieKey'
import { setCookie, getCookie } from '../../../modules/cookies/useCookie'
import { useFeatureFlagStore } from '../../../modules/featureFlags/context/store'
import { useUserStore } from '../../../modules/user/context/store'
import { TermsAndConditionApi } from '../../../modules/termsAndConditions/http/api'
import { GoogleRecaptchaActionType } from '../enums/GoogleRecaptchaActionType'
import SignInForm, { SigninForm } from './SignInForm'
import { ErrorResponse } from '../../../modules/http/types/ErrorResponse'

const RightSection = (): JSX.Element => {
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
    const [grecaptchaToken, setGrecaptchaToken] = useState<string>()
    const [signInError, setSignInError] = useState<string | null>(null)

    const navigate = useNavigate()
    const { setUser } = useUserStore()
    const { state, areCookiesAccepted, setShowAcceptCookiesBannerVisible } =
        useCookiesManagementStore()
    const { fetchFeatureFlags } = useFeatureFlagStore()

    useEffect(() => {
        if (!state.isAcceptCookiesBannerVisible) {
            setSignInError(null)
        }
    }, [state.isAcceptCookiesBannerVisible])

    const areTermsAndConditionsAccepted = async (): Promise<boolean> => {
        const response = await TermsAndConditionApi.areTermsAndConditionsAccepted()
        return response?.data.is_accepted ?? false
    }

    const signIn = async (data: SigninForm): Promise<void> => {
        setSignInError(null)
        if (!areCookiesAccepted()) {
            setShowAcceptCookiesBannerVisible(true)
            setSignInError('Cookies are declined, you need accept them to continue.')
            return
        }
        try {
            if (!grecaptchaToken) {
                throw new Error('Google recatpcha not available')
            }

            const signinResponse = await AuthenticationApi.signin(
                data.email,
                data.password,
                data.rememberMe,
                grecaptchaToken,
            )

            if (
                signinResponse?.data &&
                'token' in signinResponse?.data &&
                'refresh_token' in signinResponse?.data
            ) {
                setCookie(CookieKey.JWT_TOKEN, signinResponse?.data.token, {
                    expires: DateTime.utc().plus({ minutes: 30 }).toJSDate().toUTCString(),
                })

                setCookie(CookieKey.REFRESH_TOKEN, signinResponse?.data.refresh_token, {
                    expires: data.rememberMe
                        ? DateTime.utc().plus({ days: 30 }).toJSDate().toUTCString()
                        : DateTime.utc().plus({ days: 1 }).toJSDate().toUTCString(),
                })

                if (getCookie(CookieKey.JWT_TOKEN).length !== 0) {
                    if (signinResponse?.data.require_two_factor_authentication_code) {
                        navigate(Page.CONFIRM_2FA)
                        return
                    } else {
                        const response = await AuthenticationApi.getAuthenticatedUser()
                        setUser(response!.data)
                        if (!(await areTermsAndConditionsAccepted())) {
                            navigate(Page.TERMS_AND_CONDITIONS)
                            setUser(response!.data)
                            return
                        }
                        fetchFeatureFlags()
                        navigate(Page.MARKET)
                        return
                    }
                } else {
                    throw new Error('JWT Token not set')
                }
            }
            throw new Error('Something went wrong, please try again')
        } catch (e: unknown) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.status === 403) {
                setSignInError(
                    (err.response.data.message as string) ??
                        'Something went wrong, please try again',
                )
            } else {
                setSignInError('Something went wrong, please try again')
                throw e
            }
        } finally {
            setRefreshReCaptcha((r) => !r)
        }
    }

    const onVerify = useCallback((token: string) => {
        setGrecaptchaToken(token)
    }, [])

    return (
        <div
            className="min-h-full bg-cover flex justify-center items-center"
            style={{
                backgroundImage:
                    'url(pattern_light.png), linear-gradient(211.04deg, rgba(62, 61, 61, 0.85) 0%, #3E3D3D 98.88%)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center right, bottom left',
            }}
        >
            <GoogleReCaptcha
                action={GoogleRecaptchaActionType.SIGN_IN}
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
            />
            <div className="min-h-screen flex items-center">
                <div className="w-96">
                    <h3 className="text-gray-900 font-semibold text-3xl">Welcome back</h3>
                    <p className="text-gray-500 text-base font-normal">
                        Welcome back! Please enter your details.
                    </p>
                    <SignInForm signInError={signInError} onSubmit={signIn} />
                </div>
            </div>
        </div>
    )
}

export default RightSection
