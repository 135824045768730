import { FeatureFlag } from '../types/FeatureFlag'

export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS'

export type FeatureFlagsAction = SetFeatureFlagsAction

export interface SetFeatureFlagsAction {
    type: typeof SET_FEATURE_FLAGS
    payload: {
        featureFlags: FeatureFlag[]
    }
}
