import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { InvoiceDto } from './types/InvoiceDto'
import { SearchInvoicesResponseDto } from './types/SearchInvoicesResponseDto'
import { DownloadSignedUrlResponse } from '../../http/types/DownloadSignedUrlResponse'

export interface InvoiceApiInterface {
    search(): Promise<AxiosResponse<SearchInvoicesResponseDto>>
    getById(id: string): Promise<AxiosResponse<InvoiceDto>>
    close(id: string): Promise<AxiosResponse<void> | undefined>
    generateInvoiceDownloadSignedUrl(
        invoiceId: string,
    ): Promise<AxiosResponse<DownloadSignedUrlResponse> | undefined>
}

export const InvoiceApi: InvoiceApiInterface = Object.freeze({
    search(): Promise<AxiosResponse<SearchInvoicesResponseDto>> {
        return HttpService.get('/invoice')
    },
    getById(id: string): Promise<AxiosResponse<InvoiceDto>> {
        return HttpService.get(`/invoice/${id}`)
    },
    close(id: string): Promise<AxiosResponse<void> | undefined> {
        return HttpService.post(`invoice/${id}/close`, {})
    },
    generateInvoiceDownloadSignedUrl(
        invoiceId: string,
    ): Promise<AxiosResponse<DownloadSignedUrlResponse> | undefined> {
        return HttpService.post('invoice/signed-url', {
            all_invoices: false,
            invoice_id: invoiceId,
        })
    },
})
