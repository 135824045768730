import { IconName } from '../enums/IconName'

export interface SignInSignUpLayoutProps {
    iconName: IconName
    title: string
    subtitle?: string | JSX.Element
    children: React.ReactNode
    childClassName?: string
}

const Header = ({ iconName, title, subtitle }: Partial<SignInSignUpLayoutProps>): JSX.Element => {
    return (
        <div className="w-90">
            <div className="flex justify-center">
                <img src={`/assets/${iconName}.svg`} alt={iconName} />
            </div>
            <h2 className="text-3xl mb-3 mt-6 text-center">{title}</h2>
            <p className="text-gray-500 text-center text-base">{subtitle}</p>
        </div>
    )
}

const Footer = (): JSX.Element => {
    return (
        <div className="absolute bottom-0 hidden lg:flex justify-between pl-20 pr-14 w-screen -z-10">
            <div>
                <img src="/assets/login_bottom_left.svg" alt="login_bottom" />
            </div>
            <div>
                <img src="/assets/login_bottom_right.svg" alt="login_bottom" />
            </div>
        </div>
    )
}

const SignInSignUpLayout = ({
    iconName,
    title,
    subtitle,
    children,
    childClassName,
}: SignInSignUpLayoutProps): JSX.Element => (
    <div
        className="min-h-screen bg-contain flex-1 pt-24"
        style={{ backgroundImage: 'url(pattern_light.png)' }}
    >
        <div className="flex flex-col items-center">
            <Header title={title} subtitle={subtitle} iconName={iconName} />
            <div className={childClassName}>{children}</div>
            <Footer />
        </div>
    </div>
)

export default SignInSignUpLayout
