import { AxiosResponse } from 'axios'
import HttpService from '../../../../modules/http/httpService'
import { EmptyResponse } from '../../../../modules/http/types/EmptyResponse'
import { PayoutDetails } from './types/PayoutDetails'
import { SearchPayoutsParams } from './types/SearchPayoutsParams'
import { SearchPayoutsResponse } from './types/SearchPayoutsResponse'
import { CreatePayoutResponse } from './types/CreatePayoutResponse'

export interface PayoutInterface {
    searchPayouts(
        params: SearchPayoutsParams,
    ): Promise<AxiosResponse<SearchPayoutsResponse | undefined>>
    getSellerPayouts(sellerTenantId: string): Promise<AxiosResponse<PayoutDetails[] | undefined>>
    getPayoutDetails(payoutId: string): Promise<AxiosResponse<PayoutDetails | undefined>>
    createPayout(sellerTenantId: string): Promise<AxiosResponse<CreatePayoutResponse> | undefined>
    setPayoutAsPaid(payoutId: string): Promise<AxiosResponse<EmptyResponse> | undefined>
}

export const PayoutApi: PayoutInterface = Object.freeze({
    searchPayouts(
        params: SearchPayoutsParams,
    ): Promise<AxiosResponse<SearchPayoutsResponse | undefined>> {
        return HttpService.get(`/admin/payouts/`, null, params)
    },
    getSellerPayouts(sellerTenantId: string): Promise<AxiosResponse<PayoutDetails[] | undefined>> {
        return HttpService.get('/admin/payouts/seller', sellerTenantId)
    },
    getPayoutDetails(payoutId: string): Promise<AxiosResponse<PayoutDetails | undefined>> {
        return HttpService.get('/admin/payouts/details', payoutId)
    },
    createPayout(sellerTenantId: string): Promise<AxiosResponse<CreatePayoutResponse> | undefined> {
        return HttpService.post('admin/payouts', { tenant_id: sellerTenantId })
    },
    setPayoutAsPaid(payoutId: string): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post(`/admin/payouts/${payoutId}/set-as-paid`, {})
    },
})
