import PayoutDetailsTable from './PayoutDetailsTable'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Page } from '../../../../modules/common/enums/Pages'
import { generatePathWithQueryString } from '../../user/http/utils/generatePathWithQueryString'
import { PayoutApi } from '../http/api'
import { PayoutDetails } from '../http/types/PayoutDetails'

const SellerPayoutsOverview = () => {
    const navigate = useNavigate()
    const { sellerTenantId } = useParams<{ sellerTenantId: string }>()
    const [sellerPayouts, setSellerPayouts] = useState<PayoutDetails[] | null>(null)

    const fetchSellerPayouts = async () => {
        if (sellerTenantId) {
            const response = await PayoutApi.getSellerPayouts(sellerTenantId)
            if (response.data) {
                setSellerPayouts(response.data)
            }
        }
    }

    useEffect(() => {
        void fetchSellerPayouts()
    }, [sellerTenantId])

    const onViewButtonClickedHandler = (payoutId: string) => {
        navigate(
            generatePathWithQueryString(Page.ADMIN_PAYOUTS_DETAILS, {
                payoutId,
            }),
        )
    }

    return (
        <div className="h-full overflow-auto">
            {!!sellerPayouts && sellerPayouts?.length > 0 && (
                <PayoutDetailsTable
                    payoutDetailsData={sellerPayouts}
                    onViewButtonClicked={onViewButtonClickedHandler}
                />
            )}
        </div>
    )
}

export default SellerPayoutsOverview
