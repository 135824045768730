import { Registry } from '../types/Registry'

export const SET_REGISTRIES = 'SET_REGISTRIES'

export type RegistryAction = SetRegistriesAction

export interface SetRegistriesAction {
    type: typeof SET_REGISTRIES
    payload: SetRegistriesPayload
}

export interface SetRegistriesPayload {
    registries: Registry[]
}
