import { Fragment, useEffect, useState } from 'react'
import AlignMiddle from '../../common/components/AlignMiddle'
import { SortOrder } from '../../sellOrders/http/types/SearchSellOrdersParams'
import Order from '../../common/components/Order'
import { DownChevron, UpChevron } from '../../common/components/Chevron'
import { isEven } from '../../../utils/isEven'
import { Tooltip } from 'react-tooltip'

export enum TextAlignment {
    LEFT,
    CENTRE,
    RIGHT,
}

export type Column<T> = {
    name: T
    display: string
    className?: string
    mergeStyles?: boolean
    sortable?: boolean
    tooltip?: string
    textAlignment?: TextAlignment
    onSort?: (order: SortOrder | null, reset: boolean) => void
}

export type Value = {
    value: string | number | JSX.Element
    className?: string
    mergeStyles?: boolean
    textAlignment?: TextAlignment
}

export type Values<T extends string> = Record<T, Value>

interface Props<T extends string | 'dropdown'> {
    columns: Column<Exclude<T, 'dropdown'>>[]
    data: Values<Exclude<T, 'dropdown'>>[]
    highlightOnHover?: boolean
    highlightColour?: string
    fontColour?: string
    overflow?: string
    rounded?: string
    headerText?: string
    rowtext?: string
    rowPadding?: string
    className?: string
    selectable?: boolean
    options?: Values<Exclude<T, 'dropdown'>>[]
    onOptionSelect?: (option: Values<Exclude<T, 'dropdown'>>) => void
    isSelected?: (option: Values<Exclude<T, 'dropdown'>>) => boolean
    showHeaders?: boolean
    showBlankDropdown?: boolean
    isChild?: boolean
    zebraRowColouring?: boolean
    rowHeight?: string
    id?: string
}

const Table = <T extends string | 'dropdown'>({
    columns,
    data,
    highlightOnHover = false,
    overflow = 'overflow-x-scroll md:overflow-auto',
    rounded = 'rounded-lg',
    headerText = 'text-left',
    rowtext = 'text-left',
    rowPadding = 'px-3 py-3',
    className = '',
    selectable = false,
    options = [],
    onOptionSelect = () => {},
    isSelected = () => false,
    showHeaders = true,
    showBlankDropdown = false,
    isChild = false,
    zebraRowColouring = true,
    rowHeight = 'h-[62px]',
    id,
}: Props<T | 'dropdown'>): JSX.Element => {
    const [selectedColumn, setSelectedColumn] = useState<T | null>(null)
    const [sortOrder, setSortOrder] = useState<SortOrder | null>(null)
    const [displayOptions, setDisplayOptions] = useState<boolean>(false)
    const [displayColumns, setDisplayColumns] = useState<Column<T>[]>(columns)
    const [displayData, setDisplayData] = useState<Values<T>[]>(data as Values<T>[])
    const [optionsData, setOptionsData] = useState<Values<Exclude<T, 'dropdown'>>[]>(options)
    const [dropdownIcon, setDropdownIcon] = useState<string | JSX.Element>(
        <div className="">
            <DownChevron />
        </div>,
    )
    const sort = (column: Column<T>) => {
        if (!column.sortable || !column.onSort) return
        let nextOrder: SortOrder | null = null
        let reset = false
        if (!sortOrder || selectedColumn !== column.name) {
            setSelectedColumn(column.name)
            nextOrder = SortOrder.ASC
        } else if (sortOrder === SortOrder.ASC) {
            nextOrder = SortOrder.DESC
        } else {
            reset = true
        }
        setSortOrder(nextOrder)
        column.onSort(nextOrder, reset)
    }

    const toggelDisplayOptions = () => {
        if (displayOptions) {
            // this is to cleanup the component data when closing the dropdown to avoid persisting `keys`
            setDropdownIcon(
                <div className="">
                    <DownChevron />
                </div>,
            )
            setOptionsData([])
            setDisplayOptions(false)
            return
        }
        if (options.length > 0 && selectable) {
            setDropdownIcon(
                <div className="">
                    <UpChevron />
                </div>,
            )
            setOptionsData(options)
            setDisplayOptions(true)
        }
    }
    useEffect(() => {
        if (selectable || showBlankDropdown) {
            setDisplayColumns([...columns, { name: 'dropdown', display: '' } as Column<T>])
            setDisplayData(
                data.map(
                    (row) =>
                        ({
                            ...row,
                            dropdown: { value: '', className: 'w-4' },
                        }) as Values<T>,
                ),
            )
            return
        }
        setDisplayColumns(columns)
        setDisplayData(data as Values<T>[])
    }, [data])

    const getColumnOrder = (textAlignment: TextAlignment = TextAlignment.LEFT): string => {
        switch (textAlignment) {
            case TextAlignment.CENTRE:
                return 'order-0'
            case TextAlignment.LEFT:
                return '-order-1'
            case TextAlignment.RIGHT:
                return 'order-1 justify-end text-right'
            default:
                throw new Error(`Unknown textAlignment: ${textAlignment}`)
        }
    }

    return (
        <div
            className={`border border-gray-200 break-normal relative overflow-visible ${rounded} ${overflow} ${className}`}
            style={{
                boxShadow:
                    '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
            }}
        >
            <div className="w-full table" id={id}>
                <div className="w-full table-row align-middle text-xs text-gray-600 bg-gray-50 font-semibold">
                    {displayColumns.map((column, index) => {
                        if (!showHeaders)
                            return (
                                <div
                                    key={`column-${index}-${String(isChild)}`}
                                    className="table-cell opacity-0 px-3 text-base-none"
                                >
                                    {column.display}
                                </div>
                            )
                        return (
                            <div
                                key={`column-${index}-${String(isChild)}`}
                                className={`${
                                    column.className && !(column.mergeStyles ?? true)
                                        ? column.className
                                        : `border-b border-gray-300 py-4 bg-gray-50 px-3 table-cell ${column.className ?? ''}`
                                }`}
                            >
                                <div
                                    className={`${column.sortable ? 'hover:cursor-pointer' : ''}`}
                                    onClick={() => {
                                        if (column.name === 'dropdown') return
                                        sort(column as Column<T>)
                                    }}
                                >
                                    <AlignMiddle
                                        direction="row"
                                        childClassName={`${getColumnOrder(column.textAlignment)} flex flex-row`}
                                    >
                                        <div className="">{column.display}</div>
                                        {column.tooltip && (
                                            <AlignMiddle direction="column" className="w-[13px]">
                                                <img
                                                    src="/assets/icon_info.svg"
                                                    className="w-[13px] h-[13px] ml-1"
                                                    alt="icon_info"
                                                    id={`${column.display}-tooltip`}
                                                />
                                                <Tooltip
                                                    anchorSelect={`#${column.display}-tooltip`}
                                                    place="top"
                                                >
                                                    {column.tooltip}
                                                </Tooltip>
                                            </AlignMiddle>
                                        )}
                                        {column.sortable && (
                                            <AlignMiddle
                                                direction="column"
                                                className="min-w-[13px] ml-1"
                                            >
                                                <div
                                                    className={
                                                        selectedColumn === column.name
                                                            ? 'w-[13px] h-[13px]'
                                                            : 'w-[13px] h-[13px] opacity-0'
                                                    }
                                                >
                                                    <Order order={sortOrder} />
                                                </div>
                                            </AlignMiddle>
                                        )}
                                    </AlignMiddle>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {displayData.map((row, index) => {
                    // if index is odd number then set background colour
                    let additionalClassNames = rowHeight + ' '
                    if (zebraRowColouring && !isEven(index) && !isSelected(row)) {
                        additionalClassNames += 'bg-gray-50 '
                    }
                    if (highlightOnHover) {
                        additionalClassNames += 'hover:bg-[rgb(228,99,19,0.1)] hover:text-gray-700 '
                    }
                    if (!selectable && isSelected(row)) {
                        additionalClassNames += 'bg-gray-200 text-gray-700 '
                    }
                    return (
                        <div
                            key={`data-${index}-${String(isChild)}`}
                            className={`text-xs md:text-sm text-gray-500 table-row group ${additionalClassNames}`}
                        >
                            {displayColumns.map((column, index) => {
                                const cell = row[column.name]
                                return (
                                    <AlignMiddle
                                        direction="column"
                                        key={`data-cell-${index}-${String(isChild)}`}
                                        className={`${
                                            cell.className && !(cell.mergeStyles ?? true)
                                                ? `table-cell ${cell.className ?? ''}`
                                                : `${rowtext} ${rowPadding} table-cell align-middle ${cell.className ?? ''} ${selectable || isChild ? 'hover:cursor-pointer' : ''}`
                                        }`}
                                        onClick={() => {
                                            if (isChild) {
                                                onOptionSelect(row)
                                            }
                                            toggelDisplayOptions()
                                        }}
                                    >
                                        <AlignMiddle
                                            direction="row"
                                            childClassName={getColumnOrder(cell.textAlignment)}
                                        >
                                            {column.name !== 'dropdown' ? (
                                                cell.value
                                            ) : showBlankDropdown ? (
                                                <div className="opacity-0">{dropdownIcon}</div>
                                            ) : (
                                                dropdownIcon
                                            )}
                                        </AlignMiddle>
                                    </AlignMiddle>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
            {selectable && options.length > 0 && displayOptions && (
                <Fragment>
                    <div className="absolute z-20  bg-white w-full shadow-md">
                        <div className="h-[2px] bg-primary-500"></div>
                        <div className="max-h-60 overflow-y-scroll lg:scrollbar-hide">
                            <Table
                                columns={columns}
                                data={optionsData}
                                highlightOnHover={true}
                                overflow="overflow-auto"
                                rounded={rounded}
                                className={`border-gray-500 ${className}`}
                                headerText={headerText}
                                rowtext={rowtext}
                                selectable={false}
                                isSelected={isSelected}
                                onOptionSelect={(row: Values<Exclude<T, 'dropdown'>>) => {
                                    onOptionSelect(row as Values<Exclude<T, 'dropdown'>>)
                                    toggelDisplayOptions()
                                }}
                                showHeaders={false}
                                showBlankDropdown={true}
                                isChild={true}
                            />
                        </div>
                    </div>
                    <div
                        className="fixed z-10 h-screen w-screen top-0 left-0"
                        onClick={() => toggelDisplayOptions()}
                    ></div>
                </Fragment>
            )}
        </div>
    )
}

export default Table
