import { useForm } from 'react-hook-form'
import { PrimaryButton, SecondaryButton } from '../../../modules/common/components/Button'
import { AxiosError } from 'axios'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import { AuthenticationApi } from '../../../modules/auth/http/AuthenticationApi'
import { Page } from '../../../modules/common/enums/Pages'
import { CookieKey } from '../../../modules/cookies/enums/CookieKey'
import { setCookie } from '../../../modules/cookies/useCookie'
import { useToastMessageStore } from '../../../modules/toastMessage/context/store'
import { ToastMessageText } from '../../../modules/toastMessage/enums/ToastMessageText'
import { ToastMessageType } from '../../../modules/toastMessage/enums/ToastMessageType'
import { useUserStore } from '../../../modules/user/context/store'
import { UserApi } from '../../../modules/user/http/UserApi'
import { ErrorResponse } from '../../../modules/http/types/ErrorResponse'
import { FormElement } from '../../../modules/form/types/Field'
import Form from '../../../modules/form/components/Form'
import { Button } from '../../../modules/form/types/Button'

export interface FormData {
    password: string
    confirmPassword: string
}

interface SetNewPasswordFormProps {
    error: string | null
    isButtonDisabled: boolean
    grecaptchaToken: string
    hash?: string
    refreshReCaptcha: boolean
    setError: (error: string | null) => void
    setIsButtonDisabled: (isButtonDisabled: boolean) => void
    setRefreshReCaptcha: (refreshReCaptcha: boolean) => void
}

const SetNewPasswordForm = ({
    error,
    isButtonDisabled,
    grecaptchaToken,
    hash,
    refreshReCaptcha,
    setError,
    setIsButtonDisabled,
    setRefreshReCaptcha,
}: SetNewPasswordFormProps): JSX.Element => {
    const navigate = useNavigate()
    const { setShowToastMessage } = useToastMessageStore()
    const { setUser } = useUserStore()
    const form = useForm<FormData>()

    const onSubmit = async (): Promise<void> => {
        if (!hash) return
        try {
            setIsButtonDisabled(true)
            setError(null)
            const response = await UserApi.setNewPassword(
                hash,
                form.getValues('password'),
                grecaptchaToken as string,
            )
            if (response) {
                setCookie(CookieKey.JWT_TOKEN, response.data.token, {
                    expires: DateTime.utc().plus({ minutes: 30 }).toJSDate().toUTCString(),
                })
                setCookie(CookieKey.REFRESH_TOKEN, response.data.refresh_token, {
                    expires: response.data.remember_me
                        ? DateTime.utc().plus({ days: 30 }).toJSDate().toUTCString()
                        : DateTime.utc().plus({ days: 1 }).toJSDate().toUTCString(),
                })
                const userResponse = await AuthenticationApi.getAuthenticatedUser()
                if (userResponse) {
                    setUser(userResponse.data)
                }
                setShowToastMessage({
                    text: ToastMessageText.PASSWORD_SET,
                    type: ToastMessageType.SUCCESS,
                })
                navigate(Page.MARKET)
            }
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (
                err.response?.status === 403 &&
                err.response.data.message === 'Permission denied according to assigned roles.'
            ) {
                setError(err.response.data.message)
            } else {
                setError('An unexpected error has occurred, please try again')
                throw e
            }
        } finally {
            setIsButtonDisabled(false)
            setRefreshReCaptcha(!refreshReCaptcha)
        }
    }

    const fields: FormElement<FormData>[] = [
        {
            name: 'password',
            displayName: 'Password',
            className: 'w-full',
            type: 'password',
            placeholder: 'Enter your new password',
            required: true,
        },
        {
            name: 'confirmPassword',
            displayName: 'Confirm Password',
            className: 'w-full',
            type: 'password',
            placeholder: 'Confirm your new password',
            required: true,
            options: {
                validate: (val: string) => {
                    if (form.watch('password') != val) {
                        return 'Your passwords do not match'
                    }
                },
            },
        },
    ]

    const buttons: Button[] = [
        {
            type: 'button',
            onClick: () => navigate(Page.SIGNIN),
            id: 'back-to-login-new-password-button',
            variation: SecondaryButton,
            label: 'Back to log in',
        },
        {
            type: 'submit',
            disabled: !form.formState.isValid || isButtonDisabled,
            id: 'submit-password-reset-button',
            variation: PrimaryButton,
            className: ' w-36',
            label: 'Reset password',
        },
    ]

    return (
        <Form
            formId="new-password-form"
            formClassName="w-full mt-6"
            form={form}
            fields={fields}
            buttons={buttons}
            onSubmit={onSubmit}
            error={error}
        />
    )
}

export default SetNewPasswordForm
