import { RegistryName } from '../../../registry/enums/RegistryName'

export enum AskUsPriceRanges {
    '0_8' = 'USD 0-8',
    '8_15' = 'USD 8-15',
    '15_25' = 'USD 15-25',
    '25+' = 'USD 25+',
}

export enum AskUsContinents {
    AFRICA = 'Africa',
    ASIA = 'Asia',
    EUROPE = 'Europe',
    NORTH_AMERICA = 'North America',
    OCEANIA = 'Oceania',
    SOUTH_AMERICA = 'South America',
    NO_PREFERENCE = 'No preference',
}

export interface AskUsRequest {
    project_description: string
    project_type: string
    indicative_volume: string
    price_range: AskUsPriceRanges[]
    registry: RegistryName[]
    location: AskUsContinents[]
}
