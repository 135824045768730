import { useState } from 'react'
import DownloadFromUrl from '../../uploadDownload/components/DownloadFromUrl'

export interface Props {
    url: string | null
    children?: React.ReactNode
}

const ImageDownload = ({ url, children }: Props): JSX.Element => {
    const [triggerDownload, setTriggerDownload] = useState<boolean>(false)
    const filename = url?.split('/').pop() || 'image.png'

    if (!url) {
        return <>{children}</>
    }

    return (
        <div
            onClick={() => {
                setTriggerDownload(true)
            }}
        >
            {children}
            <DownloadFromUrl
                url={url}
                filename={filename}
                triggerDownload={triggerDownload}
                onComplete={() => setTriggerDownload(false)}
            />
        </div>
    )
}

export default ImageDownload
