import { AxiosError } from 'axios'
import { useCallback, useEffect, useRef, useState } from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from '../../../modules/user/context/store'
import { useToastMessageStore } from '../../../modules/toastMessage/context/store'
import { useCookiesManagementStore } from '../../../modules/cookies/context/store'
import { useFeatureFlagStore } from '../../../modules/featureFlags/context/store'
import { TermsAndConditionApi } from '../../../modules/termsAndConditions/http/api'
import { getCookie, setCookie } from '../../../modules/cookies/useCookie'
import { CookieKey } from '../../../modules/cookies/enums/CookieKey'
import { AuthenticationApi } from '../../../modules/auth/http/AuthenticationApi'
import { Page } from '../../../modules/common/enums/Pages'
import { ToastMessageType } from '../../../modules/toastMessage/enums/ToastMessageType'
import { GoogleRecaptchaActionType } from '../enums/GoogleRecaptchaActionType'
import FieldErrorLabel from '../../../adminArea/modules/common/components/FieldErrorLabel'
import { PrimaryButton, SecondaryButton } from '../../../modules/common/components/Button'
import { ErrorResponse } from '../../../modules/http/types/ErrorResponse'
import { DateTime } from 'luxon'

export interface ForgotPasswordForm {
    email: string
}

const ConfirmMFAForm = (): JSX.Element => {
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
    const [grecaptchaToken, setGrecaptchaToken] = useState<string>()
    const [digits, setDigits] = useState<string[]>(Array(6).fill(''))
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [signIn2faError, setSignIn2faError] = useState<string | null>(null)
    const navigate = useNavigate()
    const { setUser } = useUserStore()
    const { setShowToastMessage } = useToastMessageStore()
    const {
        state: { isAcceptCookiesBannerVisible },
        areCookiesAccepted,
        setShowAcceptCookiesBannerVisible,
    } = useCookiesManagementStore()
    const { fetchFeatureFlags } = useFeatureFlagStore()
    const isSubmitEnabled = digits.filter((digit) => digit !== '').length === 6

    const areTermsAndConditionsAccepted = async (): Promise<boolean> => {
        const response = await TermsAndConditionApi.areTermsAndConditionsAccepted()
        return response?.data.is_accepted ?? false
    }

    const handleSubmitTwoFactorAuthenticationCode = async (mfaCode: string): Promise<void> => {
        setSignIn2faError(null)
        if (!areCookiesAccepted()) {
            setShowAcceptCookiesBannerVisible(true)
            setSignIn2faError('Cookies are declined, you need accept them to continue.')
            return
        }
        try {
            const response = await AuthenticationApi.submitOtpCode(
                mfaCode,
                grecaptchaToken as string,
            )

            if (response?.data && 'token' in response?.data && 'refresh_token' in response?.data) {
                setCookie(CookieKey.JWT_TOKEN, response?.data.token, {
                    expires: DateTime.utc().plus({ minutes: 30 }).toJSDate().toUTCString(),
                })

                const rememberMe = response.data.remember_me
                setCookie(CookieKey.REFRESH_TOKEN, response?.data.refresh_token, {
                    expires: rememberMe
                        ? DateTime.utc().plus({ days: 30 }).toJSDate().toUTCString()
                        : DateTime.utc().plus({ days: 1 }).toJSDate().toUTCString(),
                })

                if (getCookie(CookieKey.JWT_TOKEN).length !== 0) {
                    const response = await AuthenticationApi.getAuthenticatedUser()
                    if (!(await areTermsAndConditionsAccepted())) {
                        navigate(Page.TERMS_AND_CONDITIONS)
                        setUser(response?.data!!)
                        return
                    }
                    fetchFeatureFlags()
                    setUser(response?.data!!)
                    navigate(Page.MARKET)
                }
                return
            }
            throw new Error('Something went wrong, please try again')
        } catch (e: unknown) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.status === 403) {
                if (err.response.data.message === 'Code incorrect') {
                    setDigits(Array(6).fill(''))
                    setSignIn2faError(err.response.data.message as string)
                } else if (
                    err.response.data.message === 'Code no longer valid - too many attempts'
                ) {
                    setShowToastMessage({
                        text: 'Verification code invalid after 3 failed attempts. Please login again.',
                        type: ToastMessageType.ERROR,
                    })
                    setSignIn2faError(null)
                    navigate(Page.SIGNIN)
                } else {
                    // some unknown/unexpected error has occured
                    setShowToastMessage({
                        text: 'Unknown error occured, please login again.',
                        type: ToastMessageType.ERROR,
                    })
                    setSignIn2faError(null)
                    navigate(Page.SIGNIN)
                }

                // if (err.response.data.message === 'Verification code expired.') {
                //     setShowToastMessage({
                //         text: 'Verification code expired after 3 failed attempts. Please login again.', // To be modified from BE
                //         type: ToastMessageType.ERROR,
                //     })
                //     setSignIn2faError(null)
                //     navigate(Page.SIGNIN)
                // } else {
                //     setDigits(Array(6).fill(''))
                //     setSignIn2faError(
                //         (err.response.data.message as string) ??
                //             'Something went wrong, please try again',
                //     )
                // }
            } else {
                setSignIn2faError('Something went wrong, please try again')
                throw e
            }
        } finally {
            setRefreshReCaptcha((r) => !r)
        }
    }

    const onOtpSubmitted = async () => {
        try {
            if (digits.length === 6 && digits.some((digit) => !digit)) {
                setSignIn2faError('Verification code must be 6 numbers long.')
                return
            }
            setIsLoading(true)
            handleSubmitTwoFactorAuthenticationCode(digits.join(''))
        } catch (err) {
        } finally {
            setIsLoading(false)
        }
    }

    const handleChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setSignIn2faError(null)
        const newDigits = [...digits]
        newDigits[index] = e.target.value.slice(0, 1).replace(/[^0-9]/, '')
        setDigits(newDigits)

        // Move to next input if not last and if a digit was entered
        if (index < 5 && newDigits[index] !== '') {
            setTimeout(() => {
                const nextInput = document.getElementById(`digit-${index + 1}`) as HTMLInputElement
                nextInput && nextInput.focus()
            }, 0)
        }
    }

    const handleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace') {
            const target = e.target as HTMLInputElement
            const cursorPosition = target.selectionStart
            if (cursorPosition === 0 && index > 0) {
                // If cursor is at the start and not in the first input, move to the previous input
                e.preventDefault() // Prevent default to avoid deleting 2 chars at once
                setDigits(digits.map((digit, i) => (i === index - 1 ? '' : digit)))
                setTimeout(() => {
                    const prevInput = document.getElementById(
                        `digit-${index - 1}`,
                    ) as HTMLInputElement
                    prevInput && prevInput.focus()
                }, 0)
            } else {
                // Clear the current input
                setTimeout(() => {
                    const prevInput = document.getElementById(`digit-${index}`) as HTMLInputElement
                    prevInput && prevInput.focus()
                }, 0)
            }
        }

        if (e.key === 'Enter') {
            void onOtpSubmitted()
            return
        }
    }

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault()
        event.stopPropagation()
        setSignIn2faError(null)
        const text = event.clipboardData.getData('text')
        const digits = text.replace(/\D+/g, '')

        // Assert event.target as HTMLInputElement
        const inputElement = event.target as HTMLInputElement
        const inputElementIndex: number = Number(inputElement.id.split('-')[1]) ?? 1 // 1 based index
        const newDigits = [...digits]
        for (let i = inputElementIndex; i < 6; i++) {
            newDigits[i] = digits[i - inputElementIndex]
        }
        setDigits(newDigits)
    }

    useEffect(() => {
        if (!isAcceptCookiesBannerVisible) {
            setSignIn2faError(null)
        }
    }, [isAcceptCookiesBannerVisible])

    const onVerify = useCallback((token: string) => {
        setGrecaptchaToken(token)
    }, [])

    const inputRefs = useRef<Array<HTMLInputElement | null>>([])

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus()
        }
    }, [grecaptchaToken])

    return (
        <>
            <GoogleReCaptcha
                action={GoogleRecaptchaActionType.SIGN_IN_2FA}
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
            />
            <div className="w-90">
                <form className="flex justify-center mt-6" autoComplete="false">
                    <div className="flex flex-row gap-2">
                        {digits.map((digit, index) => {
                            return (
                                <input
                                    className="w-10 h-10 border-0 border-b-2 border-gray-300 focus:ring-0 focus:border-[#793d1d] text-gray-90 text-base font-semibold"
                                    key={index}
                                    id={`digit-${index}`}
                                    type="text"
                                    value={digit}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    onFocus={() => {
                                        inputRefs.current[index]?.select()
                                    }}
                                    onChange={handleChange(index)}
                                    onKeyDown={handleKeyDown(index)}
                                    onPaste={handlePaste}
                                    autoComplete="false"
                                />
                            )
                        })}
                    </div>
                </form>
                <div className="h-5 mt-5">
                    {signIn2faError && <FieldErrorLabel error={signIn2faError} />}
                </div>
                <div className="mt-5 flex justify-end">
                    <SecondaryButton
                        type="button"
                        className="h-11 w-1/2"
                        onClick={() => {
                            navigate(Page.SIGNIN)
                        }}
                    >
                        Close
                    </SecondaryButton>
                    <PrimaryButton
                        disabled={!isSubmitEnabled || isLoading || !!signIn2faError}
                        className="h-11 w-1/2 ml-[15px]"
                        onClick={() => void onOtpSubmitted()}
                        type="submit"
                    >
                        Confirm
                    </PrimaryButton>
                </div>
            </div>
        </>
    )
}

export default ConfirmMFAForm
