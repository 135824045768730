import { SearchSellOrdersResponse } from '../http/types/SearchSellOrdersResponse'
import { SearchSellOrderFilters } from './reducer'

export const SET_SEARCH_SELL_ORDERS_RESPONSE = 'SET_SEARCH_SELL_ORDERS_RESPONSE'
export const SET_SEARCH_SELL_ORDER_FILTERS = 'SET_SEARCH_SELL_ORDER_FILTERS'
export const SET_SHOULD_SHOW_PRICE_FILTERS = 'SET_SHOULD_SHOW_PRICE_FILTERS'
export const SET_IS_FORM_DISABLED = 'SET_IS_FORM_DISABLED'
export const SET_IS_FILTERS_MODAL_VISIBLE = 'SET_IS_FILTERS_MODAL_VISIBLE'
export const SET_SHOW_ALL_LOCATIONS = 'SET_SHOW_ALL_LOCATIONS'
export const SET_SHOW_ALL_SDGS = 'SET_SHOW_ALL_SDGS'
export const SET_SORT_BY_COLUMN_NAME = 'SET_SORT_BY_COLUMN_NAME'
export const SET_SORT_ORDER = 'SET_SORT_ORDER'
export const SET_SHOULD_SHOW_PROJECT_TYPE_FILTER = 'SET_SHOULD_SHOW_PROJECT_TYPE_FILTER'

export type SellOrdersAction =
    | SetSearchSellOrdersResponseAction
    | SetSearchSellOrderFiltersAction
    | SetShouldShowPriceFiltersAction
    | SetIsFormDisabledAction
    | SetIsFiltersModalVisibleAction
    | SetShowAllLocationsAction
    | SetShowAlSdgsAction
    | SetShouldShowProjectTypeFilterAction

export interface SetSearchSellOrdersResponseAction {
    type: typeof SET_SEARCH_SELL_ORDERS_RESPONSE
    payload: SetSearchSellOrdersResponsePayload
}

export interface SetSearchSellOrdersResponsePayload {
    response: SearchSellOrdersResponse | null
}

export interface SetSearchSellOrderFiltersAction {
    type: typeof SET_SEARCH_SELL_ORDER_FILTERS
    payload: SetSearchSellOrderFiltersPayload
}

export interface SetSearchSellOrderFiltersPayload {
    filters: SearchSellOrderFilters
}

export interface SetShouldShowPriceFiltersAction {
    type: typeof SET_SHOULD_SHOW_PRICE_FILTERS
    payload: { show: boolean }
}

export interface SetIsFormDisabledAction {
    type: typeof SET_IS_FORM_DISABLED
    payload: { isDisabled: boolean }
}

export interface SetIsFiltersModalVisibleAction {
    type: typeof SET_IS_FILTERS_MODAL_VISIBLE
    payload: { isVisible: boolean }
}

export interface SetShowAllLocationsAction {
    type: typeof SET_SHOW_ALL_LOCATIONS
    payload: {
        show: boolean
    }
}

export interface SetShowAlSdgsAction {
    type: typeof SET_SHOW_ALL_SDGS
    payload: {
        show: boolean
    }
}

export interface SetShouldShowProjectTypeFilterAction {
    type: typeof SET_SHOULD_SHOW_PROJECT_TYPE_FILTER
    payload: {
        show: boolean
    }
}
