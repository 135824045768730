import { useSellOrdersStore } from '../../../modules/sellOrders/context/store'
import MarketfiltersPriceBox from './MarketfiltersPriceBox'
import { useEffect, useRef } from 'react'

const PriceFilter = (): JSX.Element => {
    const ref = useRef<any>()
    const {
        searchSellOrderFilters,
        shouldShowPriceFilters,
        isFormDisabled,
        setShouldShowPriceFilters,
    } = useSellOrdersStore()

    /* eslint-disable indent  */
    const priceLabel =
        searchSellOrderFilters.price.min > 0 || searchSellOrderFilters.price.max > 0
            ? `$${Number(searchSellOrderFilters.price.min).toFixed(2)} - $${Number(
                  searchSellOrderFilters.price.max,
              ).toFixed(2)}`
            : 'Any'

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShouldShowPriceFilters(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
    }, [ref])

    const onPriceTitleClickHandler = (): void => {
        setShouldShowPriceFilters(!shouldShowPriceFilters)
    }

    return (
        <div className="flex flex-col w-full sm:w-1/3 lg:w-[224px] relative" ref={ref}>
            <label className="text-gray-700 text-sm font-semibold mb-1.5">Price</label>
            <button
                disabled={isFormDisabled}
                className={`w-full h-11 bg-white rounded-lg border border-gray-300 ${
                    isFormDisabled ? 'bg-gray-100' : null
                }`}
                onClick={onPriceTitleClickHandler}
            >
                <div className="px-4 flex items-center justify-between">
                    <div className="flex gap-4">
                        <img src="/assets/icon_dollar.svg" alt="dollar icon" />
                        <p className="text-gray-900 text-base font-normal">{priceLabel}</p>
                    </div>
                    <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1.5L6 6.5L11 1.5"
                            stroke="#667085"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </button>
            {shouldShowPriceFilters && <MarketfiltersPriceBox />}
        </div>
    )
}
export default PriceFilter
