import { AxiosResponse } from 'axios'
import { EmptyResponse } from '../../../../modules/http/types/EmptyResponse'
import { SearchUsersResponse } from './types/SearchUsersResponse'
import { SearchUserParams } from './types/SearchUserParams'
import { CreateUserPayload } from './types/CreateUserPayload'
import { User } from '../../../../modules/user/types/User'
import { PatchUserData } from './types/PatchUserData'
import HttpService from '../../../../modules/http/httpService'
import { Role } from '../../../../modules/user/types/Role'
import { ApiKeysResponse } from './types/ApiKeyResponse'
import { ApiKeyGenerateResponse } from './types/ApiKeyGenerateResponse.ts'

export interface AdminUserApiInterface {
    search(params: SearchUserParams): Promise<AxiosResponse<SearchUsersResponse> | undefined>
    createUser(payload: CreateUserPayload): Promise<AxiosResponse<EmptyResponse> | undefined>
    setIsUserActive(
        id: string,
        isActive: boolean,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
    getById(id: string): Promise<AxiosResponse<User> | undefined>
    patch(id: string, data: PatchUserData): Promise<AxiosResponse<EmptyResponse> | undefined>
    setIsTwoFactorAuthetnicationEnabled(
        userId: string,
        isEnabled: boolean,
    ): Promise<AxiosResponse<void> | undefined>
    updateUserRoles(
        roleIds: string[],
        userId: string,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
    getAllRoles(): Promise<AxiosResponse<Role[]> | undefined>
    getAllApiKeysByUserId(userId: string): Promise<AxiosResponse<ApiKeysResponse> | undefined>
    generateApiKeyByUserId(
        userId: string,
        name: string,
    ): Promise<AxiosResponse<ApiKeyGenerateResponse> | undefined>
    setApiKeyIsActive(apiKeyId: string, isActive: boolean): Promise<AxiosResponse<void> | undefined>
}

export const AdminUserApi: AdminUserApiInterface = Object.freeze({
    search(params: SearchUserParams): Promise<AxiosResponse<SearchUsersResponse>> {
        return HttpService.get('/admin/users', null, params)
    },
    createUser(payload: CreateUserPayload): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('/admin/users', payload)
    },
    setIsUserActive(
        id: string,
        isActive: boolean,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.patch(`/admin/users/${id}/is-active`, { is_active: isActive })
    },
    getById(id: string): Promise<AxiosResponse<User> | undefined> {
        return HttpService.get(`/admin/users/${id}`)
    },
    patch(id: string, data: PatchUserData): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.patch(`/admin/users/${id}`, data)
    },
    setIsTwoFactorAuthetnicationEnabled(
        userId: string,
        isEnabled: boolean,
    ): Promise<AxiosResponse<void> | undefined> {
        return HttpService.post('/admin/two-factor-authentication', {
            user_id: userId,
            is_mfa_enabled: isEnabled,
        })
    },
    updateUserRoles(
        roleIds: string[],
        userId: string,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.patch(`/admin/roles/users/${userId}`, {
            ids: roleIds,
        })
    },
    getAllRoles(): Promise<AxiosResponse<Role[]> | undefined> {
        return HttpService.get('/admin/roles/')
    },
    getAllApiKeysByUserId(userId: string): Promise<AxiosResponse<ApiKeysResponse> | undefined> {
        return HttpService.get(`/admin/api-keys/${userId}`)
    },
    generateApiKeyByUserId(
        userId: string,
        name: string,
    ): Promise<AxiosResponse<ApiKeyGenerateResponse> | undefined> {
        return HttpService.post('/admin/api-keys/', {
            user_id: userId,
            name,
        })
    },
    setApiKeyIsActive(
        apiKeyId: string,
        isActive: boolean,
    ): Promise<AxiosResponse<void> | undefined> {
        return HttpService.patch(`/admin/api-keys/${apiKeyId}`, {
            is_active: isActive,
        })
    },
})
