import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    PointElement,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

interface TotalTransactionValueProps {
    datasets: any
    title: string
    subtitle: string
    xAxisLabel: string
    rangeLabelText: string
    tooltipCallback: (context: any) => string
}
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, PointElement)

const TotalTransactionValue = ({
    datasets,
    title,
    subtitle,
    xAxisLabel,
    rangeLabelText,
    tooltipCallback,
}: TotalTransactionValueProps): JSX.Element => {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => tooltipCallback(context),
                },
            },
        },
        scales: {
            y: {
                ticks: { color: '#667085', beginAtZero: false, size: 12 },
            },
            x: {
                ticks: { color: '#667085', beginAtZero: true, size: 12 },
            },
        },
        y: {
            beginAtZero: true,
            stacked: true,
            ticks: {
                count: 6,
            },
        },
        x: {
            stacked: true,
        },
    }

    return (
        <div
            className="w-full flex flex-col rounded-lg border border-gray-200 p-6"
            style={{
                boxShadow:
                    '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}
        >
            <div className="flex flex-row items-center justify-between">
                <p className="text-lg font-semibold text-gray-900">{title}</p>
                <p className="hidden text-xs leading-[18px] font-semibold text-gray-400">
                    {rangeLabelText}
                </p>
            </div>
            <p className="mt-[1px] text-base text-gray-500 mb-5">{subtitle}</p>
            <div className="h-[240px] flex flex-row">
                <div className="w-full">
                    <div className="h-[220px]">
                        <Bar options={options} data={datasets} />
                    </div>
                    <p className="text-gray-500 text-xs leading-[18px] text-center">{xAxisLabel}</p>
                </div>
            </div>
        </div>
    )
}

export default TotalTransactionValue
