import { useNavigate } from 'react-router-dom'
import InvoiceStatusPill from '../../../../modules/invoice/components/InvoiceStatusPill'
import DateTimeFormatted from '../../../../modules/dateTime/components/DateTimeFormatted'
import { convertCentsToLocaleUsd } from '../../../../utils/conversion'
import { generatePathWithQueryString } from '../../user/http/utils/generatePathWithQueryString'
import { Page } from '../../../../modules/common/enums/Pages'
import EyeIcon from '../../common/components/EyeIcon'
import UploadIcon from '../../common/components/UploadIcon'
import DownloadIcon from '../../common/components/DownloadIcon'
import SettleIcon from '../../common/components/SettleIcon'
import { InvoiceStatus } from '../../../../modules/invoice/enums/InvoiceStatus'
import CloseInvoiceIcon from '../../common/components/CloseInvoiceIcon'
import CancelIcon from '../../common/components/CancelIcon'
import { Modal, Pagination } from 'flowbite-react'
import ConfirmationModal from '../../common/components/ConfirmationModal'
import UploadFileForm, { UploadFileTypes } from '../../common/components/UploadFileForm'
import { useState } from 'react'
import { SearchInvoicesResponse } from '../http/types/SearchInvoicesResponse'
import { AdminInvoiceApi } from '../http/AdminInvoiceApi'
import { AdminInvoiceDto } from '../http/types/AdminInvoiceDto'
import { ToastMessageType } from '../../../../modules/toastMessage/enums/ToastMessageType'
import { useToastMessageStore } from '../../../../modules/toastMessage/context/store'
import HttpService from '../../../../modules/http/httpService'

interface Props {
    searchInvoicesResponse: SearchInvoicesResponse | null
    showViewInvoiceDetailsButton: boolean
    onPageChangeHandler: (pageNumber: number) => void
    onInvoiceActionHandler: () => void
}

const AdminInvoicesTable = ({
    searchInvoicesResponse,
    showViewInvoiceDetailsButton,
    onPageChangeHandler,
    onInvoiceActionHandler,
}: Props): JSX.Element => {
    const [showSettleConfirmationModalForInvoice, setShowSettleConfirmationModalForInvoice] =
        useState<AdminInvoiceDto | null>(null)
    const [showUploadInvoiceModalForInvoice, setShowUploadInvoiceModalForInvoice] =
        useState<AdminInvoiceDto | null>(null)
    const [signedUrl, setSignedUrl] = useState<string | null>(null)
    const [showCloseConfirmationModalForInvoice, setShowCloseConfirmationModalForInvoice] =
        useState<AdminInvoiceDto | null>(null)
    const [showCancelConfirmationModalForInvoice, setShowCancelConfirmationModalForInvoice] =
        useState<AdminInvoiceDto | null>(null)
    const navigate = useNavigate()
    const { setShowToastMessage } = useToastMessageStore()

    const settleInvoice = async (): Promise<void> => {
        if (showSettleConfirmationModalForInvoice) {
            try {
                await AdminInvoiceApi.settle(showSettleConfirmationModalForInvoice.id)
            } catch (e: any) {
                setShowToastMessage({
                    text: e.response.data.message,
                    type: ToastMessageType.ERROR,
                })
            } finally {
                setShowSettleConfirmationModalForInvoice(null)
                onInvoiceActionHandler()
            }
        }
    }

    const closeInvoice = async (): Promise<void> => {
        if (showCloseConfirmationModalForInvoice) {
            try {
                await AdminInvoiceApi.close(showCloseConfirmationModalForInvoice.id)
            } catch (e: any) {
                setShowToastMessage({
                    text: e.response.data.message,
                    type: ToastMessageType.ERROR,
                })
            } finally {
                setShowCloseConfirmationModalForInvoice(null)
                onInvoiceActionHandler()
            }
        }
    }

    // eslint-disable-next-line no-unused-vars
    const cancelInvoice = async (): Promise<void> => {
        if (showCancelConfirmationModalForInvoice) {
            try {
                await AdminInvoiceApi.cancel(showCancelConfirmationModalForInvoice.id)
            } catch (e: any) {
                setShowToastMessage({
                    text: e.response.data.message,
                    type: ToastMessageType.ERROR,
                })
            } finally {
                setShowCancelConfirmationModalForInvoice(null)
                onInvoiceActionHandler()
            }
        }
    }

    const handleInvoiceDownload = async (invoice: AdminInvoiceDto): Promise<void> => {
        if (!invoice.invoice_uploaded) return
        const signedUrlResponse = await AdminInvoiceApi.generateInvoiceDownloadSignedUrl(invoice.id)
        if (!signedUrlResponse?.data?.url) return
        const response = await fetch(signedUrlResponse?.data?.url)
        const blob = await response.blob()
        const urlBlob = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = urlBlob
        a.download = signedUrlResponse?.data?.filename
        document.body.appendChild(a)
        a.click()
        a.remove()
    }

    const uploadInvoiceHandler = async (file: File): Promise<void> => {
        if (showUploadInvoiceModalForInvoice && signedUrl) {
            try {
                const reader = new FileReader()

                reader.onloadend = async () => {
                    const buffer = reader.result

                    await HttpService.put(
                        signedUrl,
                        buffer,
                        {
                            headers: {
                                'Content-Type': 'application/pdf',
                            },
                        },
                        false,
                    )
                    setShowUploadInvoiceModalForInvoice(null)
                    onInvoiceActionHandler()
                }

                reader.onerror = (e) => {
                    setShowToastMessage({
                        text: `Error reading file: ${e}`,
                        type: ToastMessageType.ERROR,
                    })
                }

                reader.readAsArrayBuffer(file)
            } catch (e: any) {
                setShowToastMessage({
                    text: e.response?.data?.message || 'An error occurred',
                    type: ToastMessageType.ERROR,
                })
            }
        }
    }

    const onUploadIconPressed = async (invoice: AdminInvoiceDto): Promise<void> => {
        try {
            const response = await AdminInvoiceApi.generatePreSignedURL(invoice.id)
            if (response?.status === 200) {
                setShowUploadInvoiceModalForInvoice(invoice)
                setSignedUrl(response.data.url)
            } else {
                setShowToastMessage({
                    text: 'Unexpected error has occured',
                    type: ToastMessageType.ERROR,
                })
            }
        } catch (e: any) {
            setShowToastMessage({
                text: e.response.data.message,
                type: ToastMessageType.ERROR,
            })
        }
    }

    return (
        <div
            className="mx-auto mt-1"
            // style={{
            //     boxShadow:
            //         '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
            // }}
        >
            <table className="block md:table mx-auto overflow-x-scroll scrollbar-hide w-full border border-gray-200 rounded-lg shadow-md">
                <thead className="">
                    <tr className="h-11 items-center bg-gray-50">
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            INVOICE ID
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            TENANT NAME
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            TENANT ID
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            STATUS
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            PURCHASE TYPE
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            MONTH
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            MONTH INDEX
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            CREATED AT
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            TOTAL
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            CONTAINS FRACTIONS
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left px-3">
                            ACTIONS
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {searchInvoicesResponse?.invoices.map((invoice) => (
                        <tr className="h-18 items-center" key={invoice.id}>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                {invoice.id}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                {invoice.tenant_name}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                {invoice.tenant_id}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                <InvoiceStatusPill status={invoice.status} />{' '}
                                {`(${invoice.status})`}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                {invoice.purchase_type}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                <DateTimeFormatted
                                    date={new Date(invoice.month)}
                                    monthAndYearOnly={true}
                                />
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                {invoice.month_index}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                {new Date(invoice.created_at).toLocaleString()}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                {convertCentsToLocaleUsd(invoice.total)}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                {invoice.contains_fractionalised ? 'Yes' : 'No'}
                            </td>
                            <td className="text-gray-500 text-sm text-left px-3 font-normal">
                                <div className="flex flex-row items-center justify-start text-gray-500 text-sm text-left font-normal">
                                    {showViewInvoiceDetailsButton && (
                                        <button
                                            disabled={!!showSettleConfirmationModalForInvoice}
                                            title="View Invoice Details"
                                            onClick={() =>
                                                navigate(
                                                    generatePathWithQueryString(
                                                        Page.ADMIN_INVOICE_DETAILS,
                                                        {
                                                            id: invoice.id,
                                                        },
                                                    ),
                                                )
                                            }
                                        >
                                            <EyeIcon />
                                        </button>
                                    )}
                                    <button
                                        title="Upload invoice"
                                        className="ml-4"
                                        onClick={() => onUploadIconPressed(invoice)}
                                    >
                                        <UploadIcon />
                                    </button>
                                    <button
                                        disabled={!invoice.invoice_uploaded}
                                        title="Download invoice"
                                        className="ml-4"
                                        onClick={() => handleInvoiceDownload(invoice)}
                                    >
                                        <DownloadIcon isDisabled={!invoice.invoice_uploaded} />
                                    </button>
                                    {invoice.status === InvoiceStatus.CLOSED && (
                                        <button
                                            disabled={!!showSettleConfirmationModalForInvoice}
                                            title="Settle Invoice"
                                            className="ml-4"
                                            onClick={() =>
                                                setShowSettleConfirmationModalForInvoice(invoice)
                                            }
                                        >
                                            <SettleIcon />
                                        </button>
                                    )}
                                    {invoice.status === InvoiceStatus.OPEN && (
                                        <button
                                            title="Close Invoice"
                                            className="ml-4"
                                            onClick={() =>
                                                setShowCloseConfirmationModalForInvoice(invoice)
                                            }
                                        >
                                            <CloseInvoiceIcon />
                                        </button>
                                    )}
                                    {![InvoiceStatus.SETTLED, InvoiceStatus.CANCELLED].includes(
                                        invoice.status,
                                    ) &&
                                        !invoice.contains_fractionalised && (
                                            <button
                                                title="Cancel Invoice"
                                                className="ml-4"
                                                onClick={() =>
                                                    setShowCancelConfirmationModalForInvoice(
                                                        invoice,
                                                    )
                                                }
                                            >
                                                <CancelIcon />
                                            </button>
                                        )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* eslint-disable indent */}
            <div className="flex justify-center mt-4">
                {searchInvoicesResponse?.pagination &&
                    searchInvoicesResponse.pagination.total_items >
                        searchInvoicesResponse.pagination.per_page && (
                        <Pagination
                            currentPage={searchInvoicesResponse?.pagination.current_page}
                            totalPages={searchInvoicesResponse?.pagination.total_pages}
                            onPageChange={onPageChangeHandler}
                        />
                    )}
            </div>
            <ConfirmationModal
                show={!!showSettleConfirmationModalForInvoice}
                onClose={() => setShowSettleConfirmationModalForInvoice(null)}
                onConfirm={settleInvoice}
                question={
                    <p>
                        Are you sure you want to settle the invoice{' '}
                        <b>{showSettleConfirmationModalForInvoice?.id}</b>
                    </p>
                }
            />
            {showUploadInvoiceModalForInvoice && (
                <Modal show={true} size="md" id="modal">
                    <UploadFileForm
                        onFileSubmitted={uploadInvoiceHandler}
                        onClose={() => setShowUploadInvoiceModalForInvoice(null)}
                        message="Upload File (only PDF format is supported )"
                        fileTypes={[UploadFileTypes.PDF]}
                    />
                </Modal>
            )}
            <ConfirmationModal
                show={!!showCloseConfirmationModalForInvoice}
                onClose={() => setShowCloseConfirmationModalForInvoice(null)}
                onConfirm={closeInvoice}
                question={
                    <p>
                        Are you sure you want to close the invoice{' '}
                        <b>{showCloseConfirmationModalForInvoice?.id}</b>
                    </p>
                }
            />
            <ConfirmationModal
                show={!!showCancelConfirmationModalForInvoice}
                onClose={() => setShowCancelConfirmationModalForInvoice(null)}
                onConfirm={cancelInvoice}
                question={
                    <p>
                        Are you sure you want to cancel the invoice{' '}
                        <b>{showCancelConfirmationModalForInvoice?.id}</b>
                    </p>
                }
            />
        </div>
    )
}

export default AdminInvoicesTable
