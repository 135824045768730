import { useEffect, useRef, useState } from 'react'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import { useSearchParams } from 'react-router-dom'
import SearchBar from '../../modules/common/components/SearchBar'
import { AdminInvoiceApi } from '../../modules/invoice/http/AdminInvoiceApi'
import { SearchInvoicesParams } from '../../modules/invoice/http/types/SearchInvoicesParams'
import { Tenant } from '../../../modules/tenant/types/Tenant'
import { SearchInvoicesResponse } from '../../modules/invoice/http/types/SearchInvoicesResponse'
import AdminInvoicesTable from '../../modules/invoice/components/AdminInvoicesTable'

const AdminInvoices = (): JSX.Element => {
    const [searchInvoicesResponse, setsSarchInvoicesResponse] =
        useState<SearchInvoicesResponse | null>(null)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [searchTenantsResults, setSearchTenantsResults] = useState<Tenant[]>()
    const [searchParams, setSearchParams] = useSearchParams()
    const intervalRef = useRef<any>(null)

    const searchTenantsForInvoices = async (searchTerm: string) => {
        const res = await AdminInvoiceApi.searchTenantsForInvoices(searchTerm)
        if (res.data.length) {
            setSearchTenantsResults(res.data)
        }
    }

    const searchInvoices = async (params: SearchInvoicesParams = {}): Promise<void> => {
        const response = await AdminInvoiceApi.search(params)
        if (response) {
            setsSarchInvoicesResponse(response.data)
        }
    }

    useEffect(() => {
        if (searchParams.get('search') && !searchTerm) {
            setSearchTerm(searchParams.get('search') ?? '')
        }
        intervalRef.current = setTimeout(() => {
            void searchTenantsForInvoices(searchTerm)
            void searchInvoices({
                page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
                search: searchParams.get('search') ?? undefined,
            })
        }, 500)
        return () => clearTimeout(intervalRef.current)
    }, [searchParams])

    useEffect(() => {
        setSearchParams({ search: searchTerm })
    }, [searchTerm])

    const handleSearchedTenantClicked = (id: string) => {
        setSearchTerm(id)
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.INVOICES}>
            <div className="mx-auto">
                <SearchBar
                    searchTerm={searchTerm}
                    onSearchChange={setSearchTerm}
                    searchResults={
                        searchTenantsResults?.map((tenant) => ({
                            id: tenant.id,
                            name: `${tenant.id} - ${tenant.entity_name}`,
                        })) ?? []
                    }
                    onSearchedItemClicked={handleSearchedTenantClicked}
                />
                <AdminInvoicesTable
                    searchInvoicesResponse={searchInvoicesResponse}
                    onPageChangeHandler={(pageNumber: number) => {
                        setSearchParams({ page: String(pageNumber) })
                    }}
                    onInvoiceActionHandler={() =>
                        searchInvoices({
                            page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
                            search: searchParams.get('search') ?? undefined,
                        })
                    }
                    showViewInvoiceDetailsButton={true}
                />
            </div>
        </AdminLayout>
    )
}

export default AdminInvoices
