import { useEffect, useRef, useState } from 'react'

interface SearchResult {
    id: string
    name: string
}

interface SearchBarProps {
    searchTerm: string
    onSearchChange: (value: string) => void
    searchResults: SearchResult[]
    onSearchedItemClicked: (itemId: string) => void
}

const SearchBar: React.FC<SearchBarProps> = ({
    searchTerm,
    onSearchChange,
    searchResults,
    onSearchedItemClicked,
}) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
    const inputRef = useRef<HTMLInputElement | null>(null)

    const handleOutsideClick = (event: MouseEvent) => {
        if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
            setIsDropdownVisible(false)
        }
    }

    const handleInputFocus = () => {
        setIsDropdownVisible(true)
    }

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick)

        return () => {
            document.removeEventListener('click', handleOutsideClick)
        }
    }, [])

    return (
        <div className="relative w-[350px]">
            <input
                type="text"
                placeholder="Search..."
                ref={inputRef}
                onFocus={handleInputFocus}
                // onBlur={handleInputBlur}
                value={searchTerm}
                onChange={(e) => onSearchChange(e.target.value)}
                className="w-full py-2 pl-8 pr-4 rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-500"
            />
            {searchTerm && (
                <button
                    className="absolute top-0 right-0 mt-2 mr-2 p-2 text-gray-600 hover:text-gray-800 cursor-pointer"
                    onClick={() => onSearchChange('')}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-4 w-4"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            )}
            {isDropdownVisible && (
                <ul className="mt-0.5 p-2 bg-white border border-gray-300 shadow-md absolute w-full">
                    {searchResults.length === 0 && (
                        <li
                            key={'No Items Found'}
                            className="py-2 border-b-[1px] border-b-gray-200 last-of-type:border-b-0"
                        >
                            No items found.
                        </li>
                    )}
                    {searchResults.length !== 0 &&
                        searchResults.map((result) => (
                            <li
                                key={result.id}
                                className="py-2 cursor-pointer border-b-[1px] border-b-gray-200 last-of-type:border-b-0"
                                onClick={() => onSearchedItemClicked(result.id)}
                            >
                                {result.name}
                            </li>
                        ))}
                </ul>
            )}
        </div>
    )
}

export default SearchBar
