import SignInSignUpLayout from '../signup/components/SignInSignUpLayout'
import { IconName } from '../signup/enums/IconName'
import ForgotPasswordForm from './components/ForgotPasswordForm'

const ForgotPassword = (): JSX.Element => {
    return (
        <SignInSignUpLayout
            iconName={IconName.KEY}
            title="Forgot password?"
            subtitle="No worries, we’ll send you reset instructions."
            childClassName="w-90"
        >
            <ForgotPasswordForm />
        </SignInSignUpLayout>
    )
}

export default ForgotPassword
