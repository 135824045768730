import { FlowbiteColors } from 'flowbite-react'

export const SET_ALERT_MESSAGE = 'SET_SET_ALERT_MESSAGE'

export type AdminAction = SetAlertMessageAction

export interface SetAlertMessageAction {
    type: typeof SET_ALERT_MESSAGE
    payload: SetAlertMessagePayload
}

export interface SetAlertMessagePayload {
    alertMessage: AlertMessage | null
}

export interface AlertMessage {
    color: FlowbiteColors
    text: string
}
