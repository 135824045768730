import { useEffect } from 'react'

interface Props {
    url: string
    filename: string
    triggerDownload: boolean
    onComplete?: () => void
}

const DownloadFromUrl = ({
    url,
    filename,
    triggerDownload,
    onComplete = () => {},
}: Props): JSX.Element => {
    const downloadFromUrl = async (): Promise<void> => {
        const response = await fetch(url)
        const blob = await response.blob()
        const urlBlob = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = urlBlob
        a.download = filename
        document.body.appendChild(a)
        a.click()
        a.remove()
        onComplete()
    }

    useEffect(() => {
        if (triggerDownload) {
            downloadFromUrl()
        }
    }, [triggerDownload])

    return <></>
}

export default DownloadFromUrl
