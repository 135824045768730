import { SDG } from '../../project/enums/SDG'

interface SdgsCoverageProps {
    coveredSdgs: SDG[]
}

const SdgsCoverage = ({ coveredSdgs }: SdgsCoverageProps): JSX.Element => {
    return (
        <div
            className="w-full flex flex-col justify-between rounded-lg border border-gray-200 h-full"
            style={{
                boxShadow:
                    '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}
        >
            <div>
                <div className="flex flex-row items-center justify-between px-6 pt-6">
                    <p className="text-lg font-semibold text-gray-900">SDGs Coverage</p>
                    <p className="hidden text-xs leading-[18px] font-semibold text-gray-400">
                        This Year
                    </p>
                </div>
                <p className="text-gray-500 px-6 text-base font-normal">
                    Sustainable Development Goal Coverage
                </p>
                {coveredSdgs && (
                    <div className="px-6 mt-[38px] grid grid-cols-4">
                        {[...(Object.keys(SDG) as Array<keyof typeof SDG>)].map((key) => {
                            return (
                                <div className="mb-[26px] flex justify-center" key={key}>
                                    <div className="flex gap-2" title={SDG[key]}>
                                        <img
                                            className="w-8 h-8"
                                            src={`/assets/sdg_icons/${SDG[key]}.svg`}
                                            alt={SDG[key]}
                                            style={
                                                !coveredSdgs.includes(SDG[key])
                                                    ? {
                                                          filter: 'grayscale(100%)',
                                                          opacity: 0.3,
                                                      }
                                                    : {}
                                            }
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
            <div className="hidden flex-row items-center justify-end p-4 bg-gray-100 w-full bottom-0">
                <button
                    className="text-sm font-semibold border border-gray-300 px-4 py-2.5 rounded-lg"
                    style={{
                        boxShadow:
                            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
                    }}
                >
                    More Details
                </button>
            </div>
        </div>
    )
}

export default SdgsCoverage
