import { AxiosResponse } from 'axios'
import { FeatureFlag } from '../types/FeatureFlag'
import { SearchFeatureFlagsParams } from './types/SearchFeatureFlagsParams'
import { SearchFeatureFlagsResponse } from './types/SearchFeatureFlagsResponse'
import HttpService from '../../http/httpService'

export interface FeatureFlagInterface {
    search(
        params: SearchFeatureFlagsParams,
    ): Promise<AxiosResponse<SearchFeatureFlagsResponse> | undefined>
    getAll(): Promise<AxiosResponse<FeatureFlag[]> | undefined>
}

export const FeatureFlagApi: FeatureFlagInterface = Object.freeze({
    search(
        params: SearchFeatureFlagsParams,
    ): Promise<AxiosResponse<SearchFeatureFlagsResponse> | undefined> {
        return HttpService.get('/feature-flags', null, params)
    },

    getAll(): Promise<AxiosResponse<FeatureFlag[]> | undefined> {
        return HttpService.get(`/feature-flags`)
    },
})
