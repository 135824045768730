export interface ContextOption<T> {
    display: string
    value: T
}

interface Props<T> {
    options: ContextOption<T>[]
    onSelect: (value: T) => void
    selected: T
    id?: string
}

const ContextSwitcher = <T,>({ options, onSelect, selected, id }: Props<T>): JSX.Element => {
    if (options.length === 0) return <></>
    return (
        <div className="" id={id}>
            <div className="flex flex-row mx-auto">
                {options.map((option, index) => {
                    let roundingClassName = ''
                    if (index === 0) {
                        // first item
                        roundingClassName = 'rounded-l-sm'
                    }
                    if (index === options.length - 1) {
                        // last item (may also be first item, so append so both sides are rounded)
                        roundingClassName += ' rounded-r-sm'
                    }
                    return (
                        <div
                            key={option.display}
                            id={option.display}
                            onClick={() => {
                                onSelect(option.value)
                            }}
                            className={`${roundingClassName} select-none px-6 py-1 min-w-20 font-semibold text-center bg-white border ${selected === option.value ? 'text-orange-500 border-orange-500' : 'text-gray-600 border-gray-300'} hover:cursor-pointer`}
                        >
                            {option.display}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ContextSwitcher
