import { DbTablePrefix } from '../enums/DbTablePrefix'

export const generateUniqueId = (
    prefix: DbTablePrefix = DbTablePrefix.REQUEST,
    length: number = 15,
): string => {
    let result = String(prefix)
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}
