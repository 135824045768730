import ReactSwitch from 'react-switch'
import AlignMiddle from './AlignMiddle'
import { useEffect } from 'react'

interface TitleProps {
    label: string
    checked: boolean
    onChange: (checked: boolean) => void
    disabled?: boolean
    id?: string
}

const ToggleSwitch = ({
    label,
    checked,
    onChange,
    disabled = false,
    id,
}: TitleProps): JSX.Element => {
    useEffect(() => {}, [checked])

    return (
        <label className="flex flex-row gap-2 h-[20px] w-max" id={id}>
            <AlignMiddle direction="column">
                <span className="text-sm align-text-top">{label}</span>
            </AlignMiddle>
            <ReactSwitch
                checked={checked}
                onChange={() => {
                    onChange(!checked)
                }}
                onColor="#E46313"
                uncheckedIcon={false}
                checkedIcon={false}
                className="h-[20px]"
                height={20}
                width={40}
                handleDiameter={18}
                activeBoxShadow=""
                disabled={disabled}
            />
        </label>
    )
}

export default ToggleSwitch
