import { AuthenticationApi } from '../../auth/http/AuthenticationApi'
import { RoleName } from '../enums/RoleName'
import { User } from '../types/User'
import { setUserAction } from './actions'
import { initialUserStoreState, userReducer } from './reducer'
import { createContext, useContext, useReducer } from 'react'

export const UserStore = createContext(initialUserStoreState)

export const useUserStore = () => useContext(UserStore)

export const UserStoreProvider = ({ children }: { children: JSX.Element }): any => {
    const [state, dispatch] = useReducer(userReducer, initialUserStoreState)
    const setUser = (user: User | null) => {
        setUserAction(user, dispatch)
    }

    const fetchUser = async () => {
        const response = await AuthenticationApi.getAuthenticatedUser()
        if (response) {
            setUser(response.data)
        }
    }

    const checkUserHasAtLeastOneRole = (rolesToCheckFor: RoleName[]): boolean => {
        return !!state.user?.roles.some((userRole) => rolesToCheckFor.includes(userRole.name))
    }

    const isCaasUser = (): boolean => {
        return !!state.user?.roles.some((role: any) => [RoleName.CAAS_USER].includes(role.name))
    }

    const isCaasProUser = (): boolean => {
        return !!state.user?.roles.some((role: any) => [RoleName.CAAS_PRO].includes(role.name))
    }

    const isCaasOrCaasProUser = (): boolean => {
        return checkUserHasAtLeastOneRole([RoleName.CAAS_USER, RoleName.CAAS_PRO])
    }

    const isMarketplaceUser = (): boolean => {
        return !!state.user?.roles.some((role: any) =>
            [RoleName.MARKETPLACE_USER].includes(role.name),
        )
    }

    return (
        <UserStore.Provider
            value={{
                user: state.user,
                setUser,
                fetchUser,
                checkUserHasAtLeastOneRole,
                isCaasUser,
                isCaasProUser,
                isCaasOrCaasProUser,
                isMarketplaceUser,
            }}
        >
            {children}
        </UserStore.Provider>
    )
}
