import { DateTime } from 'luxon'

interface Props {
    date?: Date | string
    monthAndYearOnly?: boolean
    suffix?: string
}

/**
 *
 * @returns e.g. 9 May 2024 12:33 PM
 * adjusted to user's local time zone
 */
const DateTimeFormatted = ({ date, monthAndYearOnly = false, suffix }: Props): JSX.Element => {
    if (!date) {
        return <div className="text-nowrap">N/A</div>
    }
    if (typeof date === 'string') {
        date = new Date(date)
    }
    const dt = DateTime.fromJSDate(date)
    let dtStr = 'DATE_FORMAT_ERROR'

    if (monthAndYearOnly) {
        const dtLocaleParts = dt.toLocaleParts({
            month: 'long',
            year: 'numeric',
        })
        dtStr = `
            ${dtLocaleParts.find((dtPart) => dtPart.type === 'month')?.value}
            ${dtLocaleParts.find((dtPart) => dtPart.type === 'year')?.value}`
    } else {
        const dtLocaleParts = dt.toLocaleParts({
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        })
        const dtLocaleTime = dt.toLocaleString({
            hour: 'numeric',
            minute: '2-digit',
            hourCycle: 'h12',
        })

        dtStr = `
            ${dtLocaleParts.find((dtPart) => dtPart.type === 'day')?.value} 
            ${dtLocaleParts.find((dtPart) => dtPart.type === 'month')?.value} 
            ${dtLocaleParts.find((dtPart) => dtPart.type === 'year')?.value}
            ${dtLocaleTime}`
    }

    if (suffix) {
        dtStr += suffix
    }

    return <div className="text-nowrap">{dtStr}</div>
}

export default DateTimeFormatted
