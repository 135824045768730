import Header from '../../header/components/Header'
import { useToastMessageStore } from '../../toastMessage/context/store'
import Title from './Title'
import ToastMessage from '../../toastMessage/components/ToastMessage'
import { NavBarItemName } from '../../header/components/NavBar'

interface LayoutProps {
    children: JSX.Element | JSX.Element[]
    title: string
    subTitle: string
    selectedNavBarItem?: NavBarItemName
    renderMenu?: boolean
    className?: string
    dataCy?: string
    showDividingLine?: boolean
    topRightComponent?: JSX.Element
    topLeftComponent?: JSX.Element
}
const Layout = ({
    children,
    title,
    subTitle,
    renderMenu = true,
    className = '',
    dataCy = '',
    showDividingLine = true,
    selectedNavBarItem,
    topRightComponent,
    topLeftComponent,
}: LayoutProps): JSX.Element => {
    const { state } = useToastMessageStore()
    let marginClass = 'mt-12'
    if (!title && !subTitle) {
        // if there's no title or subtitle remove the margin otherwise there's a big gap between
        //  the nav bar and the main content
        marginClass = ''
    }
    return (
        <div className={`mb-24 ${className}`} data-testid={dataCy}>
            {state.message && <ToastMessage type={state.message.type} text={state.message.text} />}
            <Header renderMenu={renderMenu} selectedNavBarItem={selectedNavBarItem} />
            <div className={`${marginClass} px-2 sm:px-8 container mx-auto h-full break-words`}>
                <Title
                    title={title}
                    subTitle={subTitle}
                    showDividingLine={showDividingLine}
                    topRightComponent={topRightComponent}
                    topLeftComponent={topLeftComponent}
                />
                {children}
            </div>
        </div>
    )
}

export default Layout
