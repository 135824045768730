import { Link } from 'react-router-dom'
import { Page } from '../enums/Pages'

const BackToLogin = (): JSX.Element => {
    return (
        <div className="flex justify-center content-center">
            <img
                src="/assets/icon_back_arrow_sm.svg"
                alt="icon_back_arrow_sm"
                className="mr-3 w-3"
            />
            <Link
                to={Page.SIGNIN}
                rel="signin page"
                className="text-gray-500 font-semibold text-xs"
            >
                Back to log in
            </Link>
        </div>
    )
}

export default BackToLogin
