import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ErrorResponse } from '../../../modules/http/types/ErrorResponse'
import { generatePathWithQueryString } from '../../../adminArea/modules/user/http/utils/generatePathWithQueryString'
import { PrimaryButton, SecondaryButton } from '../../../modules/common/components/Button'
import { Page } from '../../../modules/common/enums/Pages'
import { useCookiesManagementStore } from '../../../modules/cookies/context/store'
import { UserApi } from '../../../modules/user/http/UserApi'
import { getEmailRegex } from '../../../utils/getEmailRegex'
import { GoogleRecaptchaActionType } from '../../signin/enums/GoogleRecaptchaActionType'
import { FormElement } from '../../../modules/form/types/Field'
import { Button } from '../../../modules/form/types/Button'
import Form from '../../../modules/form/components/Form'

type FormData = {
    email: string
}

const ForgotPasswordForm = (): JSX.Element => {
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
    const [grecaptchaToken, setGrecaptchaToken] = useState<string>()
    const [error, setError] = useState<string | null>(null)
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false)
    const navigate = useNavigate()
    const { state, areCookiesAccepted, setShowAcceptCookiesBannerVisible } =
        useCookiesManagementStore()

    const form = useForm<FormData>({})

    useEffect(() => {
        if (!state.isAcceptCookiesBannerVisible) {
            setError(null)
        }
    }, [state.isAcceptCookiesBannerVisible])

    const onSubmit = async (): Promise<void> => {
        setError(null)
        if (!areCookiesAccepted()) {
            setShowAcceptCookiesBannerVisible(true)
            setError('Cookies are declined, you need accept them to continue')
            return
        }
        try {
            setIsFormDisabled(true)
            const email = form.getValues('email')
            await UserApi.forgotPassword(email, grecaptchaToken as string)
            navigate(generatePathWithQueryString(Page.EMAIL_VERIFICATION, { email }))
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.status === 403 && typeof err.response.data.message === 'string') {
                setError(err.response.data.message)
            } else {
                setError('An unexpected error has occurred, please try again')
                throw e
            }
        } finally {
            setIsFormDisabled(false)
            setRefreshReCaptcha((r) => !r)
        }
    }

    const onVerify = useCallback((token: string) => {
        setGrecaptchaToken(token)
    }, [])

    const fields: FormElement<FormData>[] = [
        {
            name: 'email',
            displayName: 'Email',
            className: 'w-full',
            type: 'email',
            placeholder: 'Enter your email',
            disabled: isFormDisabled,
            rules: {
                required: true,
                pattern: getEmailRegex(),
            },
        },
    ]

    const buttons: Button[] = [
        {
            type: 'button',
            onClick: () => navigate(Page.SIGNIN),
            id: 'back-to-login-password-reset-button',
            variation: SecondaryButton,
            label: 'Back to log in',
        },
        {
            type: 'submit',
            disabled: !form.formState.isValid || isFormDisabled,
            id: 'submit-password-reset-button',
            variation: PrimaryButton,
            className: ' w-36',
            label: 'Reset password',
        },
    ]

    return (
        <>
            <GoogleReCaptcha
                action={GoogleRecaptchaActionType.FORGOT_PASSWORD}
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
            />
            <Form
                formId="password-reset-form"
                formClassName="w-full mt-6"
                form={form}
                fields={fields}
                buttons={buttons}
                onSubmit={onSubmit}
                error={error}
            />
        </>
    )
}

export default ForgotPasswordForm
