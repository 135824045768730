// @ts-nocheck
import { setToastMessage } from './actions'
import { initialToastMessageStoreState, toastMessageReducer } from './reducer'
import { ToastMessage } from './types'
import { createContext, useContext, useEffect, useReducer } from 'react'

export const ToastMessageStore = createContext(initialToastMessageStoreState)

export const useToastMessageStore = (): PageBannerContext => useContext(ToastMessageStore)

export const ToastMessageStoreProvider = ({ children }): any => {
    const [state, dispatch] = useReducer(toastMessageReducer, initialToastMessageStoreState)

    const setShowToastMessage = (message: ToastMessage | null) => {
        setToastMessage(message, dispatch)
    }

    useEffect(() => {
        if (state.message) {
            const timer = setTimeout(() => setShowToastMessage(null), 3000)
            // clear timeout
            return () => clearTimeout(timer)
        }
    }, [state.message])

    return (
        <ToastMessageStore.Provider
            value={{
                state: state,
                setShowToastMessage,
            }}
        >
            {children}
        </ToastMessageStore.Provider>
    )
}
