import { Page } from '../../../common/enums/Pages'
import { FeatureFlagName } from '../../../common/enums/FeatureFlagName'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { ErrorResponse, Navigate, Route, Routes } from 'react-router-dom'
import { useUserStore } from '../../../user/context/store'
import { useFeatureFlagStore } from '../../../featureFlags/context/store'
import SignIn from '../../../../pages/signin/SignIn'
import { RoleName } from '../../../user/enums/RoleName'
import RouterGuard from './RouterGuard'
import { CookieKey } from '../../../cookies/enums/CookieKey'
import { getCookie } from '../../../cookies/useCookie'
import TermsAndConditions from '../../../termsAndConditions/components/TermsAndConditions'
import Market from '../../../../pages/market/Market'
import ProjectPage from '../../../../pages/project/Project'
import BuyOrders from '../../../../pages/orders/BuyOrders'
import SellOrders from '../../../../pages/orders/SellOrders'
import AllTrades from '../../../../pages/orders/AllTrades'
import Invoices from '../../../../pages/orders/Invoices'
import Payouts from '../../../../pages/orders/Payouts'
import BuyOrder from '../../../../pages/orders/BuyOrder'
import Invoice from '../../../../pages/orders/Invoice'
import Inventory from '../../../../pages/inventory/Inventory'
import Retirements from '../../../../pages/retirements/Retirements'
import Users from '../../../../adminArea/pages/users/Users'
import CreateUser from '../../../../adminArea/pages/users/CreateUser'
import EditUser from '../../../../adminArea/pages/users/EditUser'
import EditTenant from '../../../../adminArea/pages/users/EditTenant'
import AdminInvoices from '../../../../adminArea/pages/invoices/AdminInvoices'
import AdminInvoice from '../../../../adminArea/pages/invoices/AdminInvoice'
import AdminRetirements from '../../../../adminArea/pages/retirements/AdminRetirements'
import SellOrder from '../../../sellOrders/components/SellOrder'
import CompleteRetirementRequest from '../../../../adminArea/pages/retirements/CompleteRetirement'
import Retirement from '../../../../pages/retirements/Retirement'
import CaaSDashboard from '../../../../pages/dashboard/Dashboard'
import Withdrawal from '../../../../pages/withdrawal/Withdrawal'
import AdminPayouts from '../../../../adminArea/pages/payouts/AdminPayout'
import AdminPayoutDetails from '../../../../adminArea/pages/payoutDetails/AdminPayoutDetails'
import AdminSellerPayouts from '../../../../adminArea/pages/sellerPayouts/AdminSellerPayouts'
import AdminUnbridgeRequests from '../../../../adminArea/pages/unbridge/AdminUnbridgeRequests'
import AdminCompleteUnbridgeRequest from '../../../../adminArea/pages/unbridge/AdminCompleteUnbridgeRequest'
import Payout from '../../../../pages/orders/Payout'
import AccountDetails from '../../../../pages/accountSettings/AccountDetails'
import AccountSecurity from '../../../../pages/accountSettings/AccountSecurity'
import AdminBuyOrders from '../../../../adminArea/pages/buyOrders/AdminBuyOrders'
import Signup from '../../../../pages/signup/SignUp'
import AdminReports from '../../../../adminArea/pages/reports/AdminReports'
import AdminFeatureFlags from '../../../../adminArea/pages/featureFlags/FeatureFlags'
import AdminEditFeatureFlag from '../../../../adminArea/pages/featureFlags/EditFeatureFlag'
import AdminProjects from '../../../../adminArea/pages/projects/AdminProjects'
import AdminCreateProject from '../../../../adminArea/pages/projects/CreateProject'
import AdminEditProject from '../../../../adminArea/pages/projects/EditProject'
import AdminCreateVintage from '../../../../adminArea/pages/vintage/AdminCreateVintage'
import AdminCarbonCreditBalances from '../../../../adminArea/pages/carbonCreditBalance/AdminCarbonCreditBalances'
import AdminCreateCarbonCreditBalance from '../../../../adminArea/pages/carbonCreditBalance/AdminCreateCarbonCreditBalance'
import AdminEditCarbonCreditBalance from '../../../../adminArea/pages/carbonCreditBalance/AdminEditCarbonCreditBalance'
import ConfirmMFA from '../../../../pages/signin/ConfirmMFA'
import ForgotPassword from '../../../../pages/forgotPassword/ForgotPassword'
import EmailVerification from '../../../../pages/emailVerification/EmailVerification'
import NewPassword from '../../../../pages/newPassword/NewPassword'
import ContactSupport from '../../../../pages/contactSupport/ContactSupport'

interface Route {
    path: Page | '*'
    component: JSX.Element
    isAdminArea: boolean
    isAuthenticatedUserArea?: boolean
    isEnabledFeatureFlagName?: FeatureFlagName
    roles?: RoleName[]
}

const RouterWrapper = (): JSX.Element | null => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { fetchFeatureFlags } = useFeatureFlagStore()
    const { fetchUser, user: user } = useUserStore()
    const { isFeatureFlagEnabled } = useFeatureFlagStore()

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!getCookie(CookieKey.JWT_TOKEN) && !getCookie(CookieKey.REFRESH_TOKEN)) {
                    return
                }
                // fetch user must be done only in case we have JWT
                await fetchUser()
                if (user) {
                    await fetchFeatureFlags()
                }
            } catch (e: unknown) {
                const error = e as AxiosError<ErrorResponse>
                if (error.response?.status === 401 || error.response?.status === 403) {
                    return
                }
                throw e
            } finally {
                setIsLoading(true)
            }
        }
        void fetchData()
    }, [])

    if (!isLoading) {
        return null
    }

    const routes: Route[] = [
        {
            path: Page.SIGNIN,
            component: <SignIn />,
            isAdminArea: false,
            isAuthenticatedUserArea: false,
        },
        {
            path: Page.SIGNUP,
            component: <Signup />,
            isAdminArea: false,
            isAuthenticatedUserArea: false,
        },
        {
            path: Page.MARKET,
            component: <Market />,
            isAdminArea: false,
            roles: [RoleName.CAAS_USER, RoleName.MARKETPLACE_USER, RoleName.CAAS_PRO],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.ORDERS_BUY_ORDERS,
            component: <BuyOrders />,
            isAdminArea: false,
            roles: [RoleName.CAAS_USER, RoleName.CAAS_PRO],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.ORDERS_SELL_ORDERS,
            component: <SellOrders />,
            isAdminArea: false,
            roles: [RoleName.MARKETPLACE_USER, RoleName.CAAS_PRO],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.ORDERS_ALL_TRADES,
            component: <AllTrades />,
            isAdminArea: false,
            roles: [RoleName.CAAS_USER, RoleName.MARKETPLACE_USER, RoleName.CAAS_PRO],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.ORDERS_INVOICES,
            component: <Invoices />,
            isAdminArea: false,
            roles: [RoleName.CAAS_USER, RoleName.CAAS_PRO],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.ORDERS_PAYOUTS,
            component: <Payouts />,
            isAdminArea: false,
            roles: [RoleName.MARKETPLACE_USER, RoleName.CAAS_PRO],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.ORDERS_PAYOUT,
            component: <Payout />,
            isAdminArea: false,
            roles: [RoleName.MARKETPLACE_USER, RoleName.CAAS_PRO],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.ORDERS_BUY_ORDER,
            component: <BuyOrder />,
            isAdminArea: false,
            roles: [RoleName.CAAS_USER, RoleName.CAAS_PRO],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.ORDERS_SELL_ORDER,
            component: <SellOrder />,
            isAdminArea: false,
            roles: [RoleName.MARKETPLACE_USER, RoleName.CAAS_PRO],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.ORDERS_INVOICE,
            component: <Invoice />,
            isAdminArea: false,
            roles: [RoleName.CAAS_USER, RoleName.CAAS_PRO],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.RETIREMENTS,
            component: <Retirements />,
            isAdminArea: false,
            roles: [RoleName.CAAS_USER, RoleName.CAAS_PRO],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.RETIREMENT,
            component: <Retirement />,
            isAdminArea: false,
            roles: [RoleName.CAAS_USER, RoleName.CAAS_PRO],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.ACCOUNT_SETTINGS_DETAILS,
            component: <AccountDetails />,
            isAdminArea: false,
            roles: [
                RoleName.CAAS_USER,
                RoleName.CAAS_PRO,
                RoleName.MARKETPLACE_USER,
                RoleName.ADMIN,
            ],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.ACCOUNT_SETTINGS_SECURITY,
            component: <AccountSecurity />,
            isAdminArea: false,
            roles: [
                RoleName.CAAS_USER,
                RoleName.CAAS_PRO,
                RoleName.MARKETPLACE_USER,
                RoleName.ADMIN,
            ],
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.CONTACT_SUPPORT,
            component: <ContactSupport />,
            isAdminArea: false,
            roles: [
                RoleName.CAAS_USER,
                RoleName.CAAS_PRO,
                RoleName.MARKETPLACE_USER,
                RoleName.ADMIN,
            ],
            isAuthenticatedUserArea: true,
        },
        // {
        //     path: Page.MY_CREDIT,
        //     component: <MyCredit />,
        //     isAdminArea: false,
        //     isAuthenticatedUserArea: true,
        //     roles: [RoleName.MARKETPLACE_USER, RoleName.CAAS_PRO],
        // },
        {
            path: Page.PROJECT,
            component: <ProjectPage />,
            isAdminArea: false,
            isAuthenticatedUserArea: true,
            roles: [RoleName.MARKETPLACE_USER, RoleName.CAAS_USER, RoleName.CAAS_PRO],
        },
        // {
        //     path: Page.MY_ORDERS,
        //     component: <MyOrders />,
        //     isAdminArea: false,
        //     roles: [RoleName.MARKETPLACE_USER, RoleName.CAAS_USER, RoleName.CAAS_PRO],
        //     isAuthenticatedUserArea: true,
        // },
        // {
        //     path: Page.CONTACT_SUPPORT,
        //     component: <ContactSupport />,
        //     isAdminArea: false,
        //     isAuthenticatedUserArea: true,
        // },
        // {
        //     path: Page.CHANGE_PASSWORD,
        //     component: <ChangePassword />,
        //     isAdminArea: false,
        //     isAuthenticatedUserArea: true,
        // },
        {
            path: Page.FORGOT_PASSOWRD,
            component: <ForgotPassword />,
            isAdminArea: false,
            isAuthenticatedUserArea: false,
        },
        {
            path: Page.CONFIRM_2FA,
            component: <ConfirmMFA />,
            isAdminArea: false,
            isAuthenticatedUserArea: false,
        },
        {
            path: Page.EMAIL_VERIFICATION,
            component: <EmailVerification />,
            isAdminArea: false,
            isAuthenticatedUserArea: false,
        },
        {
            path: Page.SET_NEW_PASSWORD,
            component: <NewPassword />,
            isAdminArea: false,
            isAuthenticatedUserArea: false,
        },
        {
            path: Page.TERMS_AND_CONDITIONS,
            component: <TermsAndConditions />,
            isAdminArea: false,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.INVENTORY,
            component: <Inventory />,
            isAdminArea: false,
            isAuthenticatedUserArea: true,
            roles: [RoleName.MARKETPLACE_USER, RoleName.CAAS_PRO],
        },
        // {
        //     path: Page.WALLET,
        //     component: <Wallet />,
        //     isAdminArea: false,
        //     isAuthenticatedUserArea: true,
        //     isEnabledFeatureFlagName: FeatureFlagName.ENABLE_WALLET_PAGE,
        // },
        // {
        //     path: Page.LINK_BANK_ACCOUNT,
        //     component: <LinkBankAccount />,
        //     isAdminArea: false,
        //     isAuthenticatedUserArea: true,
        // },
        // {
        //     path: Page.ACCOUNT_SETTINGS,
        //     component: <AccountSettings />,
        //     isAdminArea: false,
        //     isAuthenticatedUserArea: true,
        // },
        {
            path: Page.CREDIT_WITHDRAWALS,
            component: <Withdrawal />,
            isAdminArea: false,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.CAAS_DASHBOARD,
            component: <CaaSDashboard />,
            isAdminArea: false,
            isAuthenticatedUserArea: true,
        },
        {
            path: '*',
            component: <Navigate to={Page.SIGNIN} />,
            isAdminArea: false,
            isAuthenticatedUserArea: false,
        },
    ]

    const adminRoutes: Route[] = [
        {
            path: Page.ADMIN_USERS,
            component: <Users />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_CREATE_USER,
            component: <CreateUser />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_EDIT_USER,
            component: <EditUser />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_EDIT_TENANT,
            component: <EditTenant />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_INVOICES,
            component: <AdminInvoices />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_INVOICE_DETAILS,
            component: <AdminInvoice />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_RETIREMENT_REQUESTS,
            component: <AdminRetirements />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_COMPLETE_RETIREMENT_REQUEST,
            component: <CompleteRetirementRequest />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_PAYOUTS,
            component: <AdminPayouts />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_PAYOUTS_DETAILS,
            component: <AdminPayoutDetails />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_SELLER_PAYOUTS,
            component: <AdminSellerPayouts />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_UNBRIDGE_REQUESTS,
            component: <AdminUnbridgeRequests />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_COMPLETE_UNBRIDGE_REQUEST,
            component: <AdminCompleteUnbridgeRequest />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_BUY_ORDERS,
            component: <AdminBuyOrders />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_REPORTS,
            component: <AdminReports />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_FEATURE_FLAG,
            component: <AdminFeatureFlags />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_EDIT_FEATURE_FLAG,
            component: <AdminEditFeatureFlag />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_PROJECTS,
            component: <AdminProjects />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_CREATE_PROJECT,
            component: <AdminCreateProject />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_EDIT_PROJECT,
            component: <AdminEditProject />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_CREATE_VINTAGE,
            component: <AdminCreateVintage />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_CARBON_CREDIT_BALANCES,
            component: <AdminCarbonCreditBalances />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_CREATE_CARBON_CREDIT_BALANCE,
            component: <AdminCreateCarbonCreditBalance />,
            isAdminArea: true,
        },
        {
            path: Page.ADMIN_EDIT_CARBON_CREDIT_BALANCE,
            component: <AdminEditCarbonCreditBalance />,
            isAdminArea: true,
        },
        // {
        //     path: Page.USERS,
        //     component: <Users />,
        //     isAdminArea: true,
        // },
        // {
        //     path: Page.CREATE_USER,
        //     component: <CreateUser />,
        //     isAdminArea: true,
        // },
        // {
        //     path: Page.EDIT_USER,
        //     component: <EditUser />,
        //     isAdminArea: true,
        // },
        // {
        //     path: Page.EDIT_TENANT,
        //     component: <EditTenant />,
        //     isAdminArea: true,
        // },
        // {
        //     path: Page.INVOICES,
        //     component: <Invoices />,
        //     isAdminArea: true,
        // },
        // {
        //     path: Page.INVOICE_DETAILS,
        //     component: <InvoiceDetails />,
        //     isAdminArea: true,
        // },
        // {
        //     path: Page.RETIREMENT_REQUESTS,
        //     component: <RetirementRequests />,
        //     isAdminArea: true,
        // },
        // {
        //     path: Page.COMPLETE_RETIREMENT_REQUEST,
        //     component: <CompleteRetirementRequest />,
        //     isAdminArea: true,
        // },
        // {
        //     path: Page.TRANSACTION_FEE,
        //     component: <TransactionFee />,
        //     isAdminArea: true,
        // },
        // {
        //     path: Page.EDIT_TRANSACTION_FEE,
        //     component: <EditTransactionFee />,
        //     isAdminArea: true,
        // },
        // {
        //     path: Page.PAYOUTS,
        //     component: <Payouts />,
        //     isAdminArea: true,
        // },
    ]

    return (
        <Routes>
            {[...routes, ...adminRoutes.map((route) => ({ ...route, roles: [RoleName.ADMIN] }))]
                .filter((route) => {
                    if (route.isEnabledFeatureFlagName) {
                        return isFeatureFlagEnabled(route.isEnabledFeatureFlagName)
                    }
                    return true
                })
                .map((route) => {
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <RouterGuard
                                    isAuthenticatedUserArea={route.isAuthenticatedUserArea}
                                    userRoles={user?.roles}
                                    pageRoles={route?.roles}
                                >
                                    {route.component}
                                </RouterGuard>
                            }
                        />
                    )
                })}
        </Routes>
    )
}
export default RouterWrapper
