import { Controller, useForm } from 'react-hook-form'
import { Alignment, FormElement, Placement } from '../../modules/form/types/Field'
import SignInSignUpLayout from './components/SignInSignUpLayout'
import { IconName } from './enums/IconName'
import { RegistryName } from '../../modules/registry/enums/RegistryName'
import Select, { MultiValue } from 'react-select'
import Form from '../../modules/form/components/Form'
import { PrimaryButton } from '../../modules/common/components/Button'
import { Button } from '../../modules/form/types/Button'
import { useEffect, useState } from 'react'
import { SignupRequest } from '../../modules/form/http/types/SignupRequest'
import { FormApi } from '../../modules/form/http/api'
import Note, { NoteType } from '../../modules/note/components/Note'

type FormData = {
    name: string
    email: string
    business: string[]
    projectDeveloper: string[]
    acceptTerms: string[]
    alreadyIssued?: string[]
    registries?: (RegistryName | string)[]
}

const Signup = (): JSX.Element => {
    const [hideField, setHideField] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)

    const form = useForm<FormData>({
        // defaultValues: {
        //     quantity: 0,
        //     price: String(0.0),
        // },
    })

    const registyOptions: { value: RegistryName | string; label: string }[] = [
        { value: RegistryName.GOLD_STANDARD, label: 'Gold Standard' },
        { value: RegistryName.VERRA, label: 'Verra' },
        { value: RegistryName.AMERICAN_CARBON_REGISTRY, label: 'American Carbon Registry (ACR)' },
        { value: RegistryName.CLIMATE_ACTION_RESERVE, label: 'Climate Action Reserve (CAR)' },
        { value: RegistryName.BIOCARBON_REGISTRY, label: 'BioCarbon Registry' },
        { value: RegistryName.PURO, label: 'Puro.earth' },
        { value: RegistryName.SOCIAL_CARBON, label: 'Social Carbon' },
        {
            value: RegistryName.INTERNATION_CARBON_REGISTRY,
            label: 'International Carbon Registry (ICR)',
        },
        { value: RegistryName.PLAN_VIVO, label: 'Plan Vivo' },
        { value: RegistryName.GLOBAL_CARBON_COUNCIL, label: 'Global Carbon Council (ICR)' },
        { value: 'Other', label: 'Other' },
        { value: 'Not sure', label: `I'm not sure` },
    ]

    const defaultFields: FormElement<FormData>[] = [
        {
            name: 'name',
            displayName: 'Name',
            type: 'text',
            required: true,
            alignment: Alignment.NEXTLINE,
        },
        {
            name: 'email',
            displayName: 'Work Email',
            type: 'email',
            required: true,
            alignment: Alignment.NEXTLINE,
        },
        <h3 id="signup-capacity-heading">
            In what capacity are you looking to connect with Thallo? (Check as many that apply)
        </h3>,
        {
            name: 'business',
            displayName: 'As a business looking to buy carbon credits/offsets',
            type: 'checkbox',
            labelClassName: 'text-sm text-gray-700',
            required: false,
            alignment: Alignment.INLINE,
            placement: Placement.BEFORE,
        },
        {
            name: 'projectDeveloper',
            displayName: 'As a project developer or seller of carbon credits/offsets',
            type: 'checkbox',
            labelClassName: 'text-sm text-gray-700',
            required: false,
            alignment: Alignment.INLINE,
            placement: Placement.BEFORE,
        },
        {
            name: 'alreadyIssued',
            displayName: 'I have already issued carbon credits/offsets',
            labelClassName: 'text-sm text-gray-700 font-semibold',
            type: 'checkbox',
            required: false,
            alignment: Alignment.INLINE,
            placement: Placement.BEFORE,
            spacing: 'pt-8',
            hide: hideField,
        },
        {
            name: 'registries',
            displayName: 'Which standard are your credits under?',
            type: 'select',
            required: false,
            spacing: 'pt-1',
            hide: hideField,
            inputOverride: (
                <Controller
                    name="registries"
                    control={form.control}
                    render={({ field }) => {
                        return (
                            <Select
                                className="w-full md:w-[400px]"
                                id="registry-select"
                                options={registyOptions}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                onChange={(
                                    newValue: MultiValue<{
                                        value: RegistryName | string
                                        label: string
                                    }>,
                                ) => {
                                    field.onChange(
                                        newValue?.flatMap((singleValue) => singleValue.value) ||
                                            null,
                                    )
                                }}
                            />
                        )
                    }}
                    rules={{
                        required: false,
                    }}
                    defaultValue={undefined}
                />
            ),
        },
        {
            name: 'acceptTerms',
            displayName:
                'I agree to receive communications from Thallo. For further information, review our Privacy Policy.',
            labelClassName: 'text-sm text-gray-700 font-semibold',
            type: 'checkbox',
            required: true,
            alignment: Alignment.INLINE,
            placement: Placement.BEFORE,
            spacing: 'pt-8',
        },
    ]

    useEffect(() => {
        setHideField(!form.getValues('projectDeveloper'))
    }, [form.watch('projectDeveloper')])

    const buttons: Button[] = [
        {
            type: 'submit',
            label: 'Submit',
            id: 'submit-register-interest',
            disabled: !form.formState.isValid,
            className: 'mx-auto',
            variation: PrimaryButton,
        },
    ]

    const onSubmit = async (): Promise<void> => {
        const signupRequest: SignupRequest = {
            name: form.getValues('name'),
            email: form.getValues('email'),
            privacy_policy_accepted: form.getValues('acceptTerms') as unknown as boolean,
            is_business: form.getValues('business') as unknown as boolean,
            is_project_developer: form.getValues('projectDeveloper') as unknown as boolean,
            has_credits_issued: form.getValues('alreadyIssued') as unknown as boolean,
            registries: form.getValues('registries') as (RegistryName | string)[],
        }

        try {
            setError(null)
            await FormApi.signup(signupRequest)
            setIsSuccess(true)
        } catch (e) {
            setError('An error occurred. Please try again later.')
        }
    }

    return (
        <SignInSignUpLayout title="Register Interest" iconName={IconName.THALLO}>
            <div className="p-2">
                <Form
                    title="Register Interest"
                    onSubmit={onSubmit}
                    formId="create-sub-customer-form"
                    fields={defaultFields}
                    headerListItems={[]}
                    buttons={buttons}
                    buttonWrapperClassName="w-full"
                    buttonAlignment={Alignment.INLINE}
                    form={form}
                />
            </div>
            {isSuccess && <Note type={NoteType.INFO}>Successfully sent the signup request</Note>}
            {error && <Note type={NoteType.ERROR}>{error}</Note>}
        </SignInSignUpLayout>
    )
}

export default Signup
