import { ConfigFactory } from './config/ConfigFactory'
import { loadScriptByURL } from './utils/loadScriptbyUrl'
import React, { useEffect } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { UserStoreProvider } from './modules/user/context/store'
import { AdminStoreProvider } from './modules/admin/context/store'
import RouterWrapper from './modules/auth/http/components/RouterWrapper'
import TestEnvBanner from './modules/common/components/TestEnvBanner'
import { FeatureFlagStoreProvider } from './modules/featureFlags/context/store'
import { MyOrdersStoreProvider } from './modules/myOrders/context/store'
import { RegistryStoreProvider } from './modules/registry/context/store'
import { SellOrdersStoreProvider } from './modules/sellOrders/context/store'
import { ToastMessageStoreProvider } from './modules/toastMessage/context/store'
import AcceptCookies from './modules/cookies/components/AcceptCookies'
import { CookiesManagementStoreProvider } from './modules/cookies/context/store'
import { CarbonCreditFiltersStoreProvider } from './modules/carbonCreditBalance/context/store'

interface StoreProviderProps {
    providers: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>
    children: React.ReactNode
}

const StoreProvider = ({ providers, children }: StoreProviderProps): JSX.Element => {
    return (
        <div>
            <React.Fragment>
                {providers.reduceRight((acc, Comp) => {
                    return <Comp>{acc}</Comp>
                }, children)}
            </React.Fragment>
        </div>
    )
}

function App() {
    const isTestEnv = ConfigFactory.getConfig().thalloEnv !== 'prod'

    useEffect(() => {
        // load script by passing the URL
        loadScriptByURL(
            'recaptcha-key',
            `https://www.google.com/recaptcha/api.js?render=${
                ConfigFactory.getConfig().googleSiteKey
            }`,
            function () {},
        )
    }, [])

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={ConfigFactory.getConfig().googleSiteKey}
            scriptProps={{
                async: false, // optional, default to false,
                defer: false, // optional, default to false
                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                nonce: undefined, // optional, default undefined
            }}
        >
            <div>
                <StoreProvider
                    providers={[
                        UserStoreProvider,
                        RegistryStoreProvider,
                        CarbonCreditFiltersStoreProvider,
                        AdminStoreProvider,
                        ToastMessageStoreProvider,
                        SellOrdersStoreProvider,
                        MyOrdersStoreProvider,
                        FeatureFlagStoreProvider,
                        CookiesManagementStoreProvider,
                    ]}
                >
                    {isTestEnv && <TestEnvBanner />}
                    <RouterWrapper />
                    <AcceptCookies />
                </StoreProvider>
            </div>
        </GoogleReCaptchaProvider>
    )
}

export default App
