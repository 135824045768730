import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useAdminStore } from '../../../modules/admin/context/store'
import { CsvReportResponse } from '../../modules/reports/http/types/CsvReportResponse'
import { AdminReportsApi } from '../../modules/reports/http/api'
import { AdminTradeHistoryApi } from '../../modules/tradeHistory/http/api'
import { formatDateWithTime } from '../../../utils/format'
import { SecondaryButton } from '../../../modules/common/components/Button'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'

const AdminReports = (): JSX.Element => {
    const { setShowAlertMessage } = useAdminStore()
    const [csvReportRows, setCsvReportRows] = useState<Array<string[]>>([])
    const [tradeHistoryId, setTradeHistoryId] = useState<string | null>(null)

    // this is an annoying work around because the asyncOnClick property from CSVLink (https://www.npmjs.com/package/react-csv)
    //  simply doesn't work, it doesn't wait for the function to complete and thus only ever downloads a blank csv
    // instead we listen to the csvReportRows variable changing and when it does we manually click the CSVLink
    // we can't use CSVDownload because it doesn't allow you to specify the filename
    useEffect(() => {
        if (csvReportRows.length > 0) {
            document.getElementById('csv-report-download-link')!.click()
        }
    }, [csvReportRows])

    const downloadTotalTradeStatsReportCsv = async (): Promise<void> => {
        try {
            setCsvReportRows([])
            const csvReportResponse: CsvReportResponse = (
                await AdminReportsApi.downloadAllTradesReportCsv()
            ).data
            setCsvReportRows(csvReportResponse.csv_data.rows)
        } catch (error) {
            if (
                error instanceof AxiosError &&
                error.response?.data.message &&
                error.response?.data.statusCode === 422
            ) {
                const errorMessage = error.response.data.message
                setShowAlertMessage({
                    text: errorMessage,
                    color: 'failure',
                })
            } else {
                setShowAlertMessage({
                    text: 'Something went wrong please try again later',
                    color: 'failure',
                })
                throw error
            }
            setShowAlertMessage({
                text: 'Something went wrong please try again later',
                color: 'failure',
            })
        }
    }

    const resendTradeSlackNotificaton = async () => {
        if (!tradeHistoryId) return
        try {
            await AdminTradeHistoryApi.resendSlackNotification(tradeHistoryId)
        } catch (error) {
            if (
                error instanceof AxiosError &&
                error.response?.data.message &&
                error.response?.data.statusCode === 422
            ) {
                const errorMessage = error.response.data.message
                setShowAlertMessage({
                    text: errorMessage,
                    color: 'failure',
                })
            } else {
                setShowAlertMessage({
                    text: 'Something went wrong please try again later',
                    color: 'failure',
                })
                throw error
            }
            setShowAlertMessage({
                text: 'Something went wrong please try again later',
                color: 'failure',
            })
        }
    }

    const downloadLoginAuditLogs = async (): Promise<void> => {
        //We do this because when using Axios the download won't automatically start
        //We could also send a JSON object back and create the CSV on the front end, but streaming the data is a better choice for scalability
        //and does not completely lock the client while the CSV is being generated if it is very large
        try {
            const res = await AdminReportsApi.downloadLoginAuditLogs()
            const blob = new Blob([res.data])
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.setAttribute('download', `thallo_login_audit_logs_${formatDateWithTime()}.csv`)
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            a.remove()
        } catch (error) {
            if (
                error instanceof AxiosError &&
                error.response?.data.message &&
                error.response?.data.statusCode === 422
            ) {
                const errorMessage = error.response.data.message
                setShowAlertMessage({
                    text: errorMessage,
                    color: 'failure',
                })
            } else {
                setShowAlertMessage({
                    text: 'Something went wrong please try again later',
                    color: 'failure',
                })
                throw error
            }
            setShowAlertMessage({
                text: 'Something went wrong please try again later',
                color: 'failure',
            })
        }
    }

    const downloadPlatformStats = async (): Promise<void> => {
        try {
            const res = await AdminReportsApi.downloadPlatformStats()
            const blob = new Blob([res.data])
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.setAttribute('download', `thallo_platform_stats_${formatDateWithTime()}.csv`)
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            a.remove()
        } catch (error) {
            if (
                error instanceof AxiosError &&
                error.response?.data.message &&
                error.response?.data.statusCode === 422
            ) {
                const errorMessage = error.response.data.message
                setShowAlertMessage({
                    text: errorMessage,
                    color: 'failure',
                })
            } else {
                setShowAlertMessage({
                    text: 'Something went wrong please try again later',
                    color: 'failure',
                })
                throw error
            }
            setShowAlertMessage({
                text: 'Something went wrong please try again later',
                color: 'failure',
            })
        }
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.REPORTS}>
            <div className="">
                <SecondaryButton
                    type="submit"
                    className="mt-1 px-2.5 py-1"
                    onClick={downloadTotalTradeStatsReportCsv}
                >
                    Download All Trades CSV
                </SecondaryButton>
                <CSVLink
                    data={csvReportRows}
                    filename={`thallo_all_trades_report_${formatDateWithTime()}.csv`}
                    className="hidden"
                    id="csv-report-download-link"
                ></CSVLink>
            </div>
            <div>
                <SecondaryButton
                    type="submit"
                    className="mt-1 px-2.5 py-1"
                    onClick={downloadLoginAuditLogs}
                >
                    Download Login Audit Logs CSV
                </SecondaryButton>
            </div>
            <div>
                <SecondaryButton
                    type="submit"
                    className="mt-1 px-2.5 py-1"
                    onClick={downloadPlatformStats}
                >
                    Download Platform Stats CSV
                </SecondaryButton>
            </div>
            <div className="flex flex-col mt-3">
                <div className="">Resend Slack notification for trade:</div>
                <div className="flex flex-row mt-2">
                    <input
                        className="border border-gray-300 px-3.5 py-2.5 rounded-lg"
                        type="text"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            event.preventDefault()
                            setTradeHistoryId(event.target.value || null)
                        }}
                    ></input>
                    <SecondaryButton
                        type="submit"
                        className="mt-1 px-2.5 py-1"
                        onClick={resendTradeSlackNotificaton}
                    >
                        Resend Slack Notification
                    </SecondaryButton>
                </div>
            </div>
        </AdminLayout>
    )
}

export default AdminReports
