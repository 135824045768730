import React from 'react'
import Layout from '../../../../modules/common/components/Layout'
import { NavBarItemName } from '../../../../modules/header/components/NavBar'
import Note, { NoteType } from '../../../../modules/note/components/Note'
import { AdminSubMenuActions } from '../enums/AdminSubMenuActions'
import AdminSubMenu from './AdminSubMenu'

interface Props {
    children: React.ReactNode
    selectedItem: AdminSubMenuActions
}

const AdminLayout = ({ children, selectedItem }: Props): JSX.Element => {
    return (
        <Layout
            title=""
            subTitle=""
            selectedNavBarItem={NavBarItemName.ADMIN}
            topLeftComponent={
                <Note type={NoteType.WARNING}>WARNING: you are in the admin area</Note>
            }
        >
            <>
                <AdminSubMenu selectedItem={selectedItem} />
                {children}
            </>
        </Layout>
    )
}

export default AdminLayout
