import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SearchBuyOrderResponse } from '../http/types/SearchBuyOrderResponse'
import { AdminSearchBuyOrder } from '../http/types/BuyOrder'
import { Pagination } from 'flowbite-react'
import { ActionName } from '../../../../modules/action/enums/ActionName'
import { Page } from '../../../../modules/common/enums/Pages'
import { convertCentsToLocaleUsd } from '../../../../utils/conversion'
import CancelIcon from '../../common/components/CancelIcon'
import EyeIcon from '../../common/components/EyeIcon'
import { generatePathWithQueryString } from '../../user/http/utils/generatePathWithQueryString'
import BuyOrderStatusPill from '../../../../modules/buyOrders/components/BuyOrderStatusPill'
import DateTimeFormatted from '../../../../modules/dateTime/components/DateTimeFormatted'
import BuyOrderTradeHistoryTable from './BuyOrderTradeHistoryTable'
import CreditsConfirmationModal from '../../../../modules/buyOrders/components/CreditsConfirmationModal'
import { Project } from '../../../../modules/project/types/Project'
import { Vintage } from '../../../../modules/vintage/types/Vintage'
import { XCircle } from '@phosphor-icons/react'
import Action from '../../../../modules/common/components/Action'

interface BuyOrderTableProps {
    buyOrdersResponse: SearchBuyOrderResponse | null
    canCancelBuyOrder: (buyOrder: AdminSearchBuyOrder) => boolean
    cancel: (buyOrder: AdminSearchBuyOrder) => Promise<void>
    onPageChangeHandler: (page: number) => void
    isAdmin?: boolean
}

const AdminBuyOrderTable = ({
    buyOrdersResponse,
    canCancelBuyOrder,
    cancel,
    onPageChangeHandler,
    isAdmin = false,
}: BuyOrderTableProps): JSX.Element => {
    const [showCancelConfirmModal, setShowCancelConfirmModal] =
        useState<AdminSearchBuyOrder | null>(null)
    const navigate = useNavigate()

    return (
        <div
            className={`mt-1 border border-gray-200 rounded-lg shadow-md w-full overflow-x-scroll ${
                isAdmin ? 'h-[calc(100vh-180px)]' : ''
            }`}
        >
            {buyOrdersResponse?.buy_orders.map((buyOrder) => (
                <Fragment key={buyOrder.id}>
                    <table className="w-full text-center block md:table">
                        <thead>
                            <tr className="h-11 bg-gray-50">
                                <th
                                    className="text-gray-500 font-semibold text-xs px-3"
                                    scope="col"
                                >
                                    Buy Order ID
                                </th>
                                <th
                                    className="text-gray-500 font-semibold text-xs px-3"
                                    scope="col"
                                >
                                    Status
                                </th>
                                <th
                                    className="text-gray-500 font-semibold text-xs px-3"
                                    scope="col"
                                >
                                    Invoice ID
                                </th>
                                {isAdmin && (
                                    <>
                                        <th className="text-gray-500 font-semibold text-xs px-3">
                                            Tenant ID
                                        </th>
                                        <th className="text-gray-500 font-semibold text-xs px-3">
                                            Tenant Name
                                        </th>
                                    </>
                                )}
                                <th
                                    className="text-gray-500 font-semibold text-xs px-3"
                                    scope="col"
                                >
                                    Purchase Date
                                </th>
                                {isAdmin && (
                                    <>
                                        <th className="text-gray-500 font-semibold text-xs px-3">
                                            Net Total
                                        </th>
                                    </>
                                )}
                                <th
                                    className="text-gray-500 font-semibold text-xs px-3"
                                    scope="col"
                                >
                                    {isAdmin ? 'Gross ' : ''}Total
                                </th>
                                <th
                                    className="text-gray-500 font-semibold text-xs px-3"
                                    scope="col"
                                >
                                    ACTIONS
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="h-18 text-gray-500 text-sm font-normal">
                                <td className="px-3">{buyOrder.id}</td>
                                <td className="px-3">
                                    <BuyOrderStatusPill status={buyOrder.status} />
                                </td>
                                <td className="px-3">{buyOrder.invoice_id}</td>
                                {isAdmin && (
                                    <>
                                        <td className="px-3">{buyOrder.tenant_id}</td>
                                        <td className="px-3">{buyOrder.tenant_name}</td>
                                    </>
                                )}
                                <td className="px-3">
                                    <DateTimeFormatted date={buyOrder.created_at!} />
                                </td>
                                {isAdmin && (
                                    <td className="px-3">
                                        {convertCentsToLocaleUsd(
                                            buyOrder.trades.reduce((acc, trade): number => {
                                                return acc + +(trade.net_total_cost ?? 0)
                                            }, 0),
                                        )}
                                    </td>
                                )}
                                <td className="px-3">
                                    {convertCentsToLocaleUsd(
                                        buyOrder.trades.reduce((acc, trade): number => {
                                            return acc + +(trade.gross_total_cost ?? 0)
                                        }, 0),
                                    )}
                                </td>
                                <td className="px-3">
                                    <div className="flex-row items-center justify-start">
                                        {isAdmin && (
                                            <button
                                                title="View Invoice Details"
                                                onClick={() =>
                                                    navigate(
                                                        generatePathWithQueryString(
                                                            Page.ADMIN_INVOICE_DETAILS,
                                                            {
                                                                id: buyOrder.invoice_id,
                                                            },
                                                        ),
                                                    )
                                                }
                                            >
                                                <EyeIcon />
                                            </button>
                                        )}
                                        {canCancelBuyOrder(buyOrder) && (
                                            <>
                                                {isAdmin ? (
                                                    <button
                                                        title="Cancel Buy Order"
                                                        className="ml-4"
                                                        onClick={() =>
                                                            setShowCancelConfirmModal(buyOrder)
                                                        }
                                                    >
                                                        <CancelIcon />
                                                    </button>
                                                ) : (
                                                    <Action
                                                        key={`buy-order-${buyOrder.id}`}
                                                        id={`buy-order-${buyOrder.id}`}
                                                        IconElement={XCircle}
                                                        tooltip="Cancel"
                                                        onClick={() => {
                                                            setShowCancelConfirmModal(buyOrder)
                                                        }}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan={isAdmin ? 9 : 6}
                                    className="border-b border-gray-200 px-2"
                                >
                                    <div className="">
                                        <BuyOrderTradeHistoryTable
                                            tradeHistory={buyOrder.trades}
                                            isAdmin={isAdmin}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Fragment>
            ))}
            {/* eslint-disable indent */}
            <div className="flex justify-center mt-4">
                {buyOrdersResponse?.pagination &&
                    buyOrdersResponse.pagination.total_items >
                        buyOrdersResponse.pagination.per_page && (
                        <Pagination
                            currentPage={buyOrdersResponse?.pagination.current_page}
                            totalPages={buyOrdersResponse?.pagination.total_pages}
                            onPageChange={onPageChangeHandler}
                        />
                    )}
            </div>
            <CreditsConfirmationModal
                show={!!showCancelConfirmModal}
                onClose={() => setShowCancelConfirmModal(null)}
                onConfirm={async () => {
                    await cancel(showCancelConfirmModal!)
                    setShowCancelConfirmModal(null)
                }}
                actionName={ActionName.CANCEL}
                buyOrder={showCancelConfirmModal!}
                vintage={
                    { vintage: showCancelConfirmModal?.trades[0].vintage } as unknown as Vintage
                }
                project={showCancelConfirmModal?.trades[0].project_data as unknown as Project}
                modalSize={'xl'}
                subtitle={
                    <div className="text-sm font-normal pt-2">
                        <span className="font-semibold">Buy Order ID:</span>{' '}
                        {showCancelConfirmModal?.id}
                    </div>
                }
            />
        </div>
    )
}

export default AdminBuyOrderTable
