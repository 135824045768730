import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Project } from '../../../modules/project/types/Project'
import { AdminProjectApi } from '../../modules/projects/http/api'
import { AdminVintageApi } from '../../modules/vintage/http/api'
import { Vintage } from '../../../modules/vintage/types/Vintage'
import FieldErrorLabel from '../../modules/common/components/FieldErrorLabel'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import AdminLayout from '../../modules/common/components/AdminLayout'

export interface CreateVintageForm {
    vintage: string
    tcc_address: string
    registry_url?: string
    bridge_vintage_id: string
}

const AdminCreateVintage = (): JSX.Element => {
    const { id } = useParams<{ id: string }>()
    const [project, setProject] = useState<Project | null>(null)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<CreateVintageForm>()

    const fetchProject = async (id: string): Promise<void> => {
        const response = await AdminProjectApi.getById(id)
        if (response) {
            setProject(response.data)
        }
    }

    useEffect(() => {
        if (id) {
            void fetchProject(id)
        }
    }, [])

    const addVintage = async (data: CreateVintageForm): Promise<void> => {
        await AdminVintageApi.create({
            vintage: Number(data.vintage),
            project_id: id!,
            tcc_address: data.tcc_address,
            registry_url: data.registry_url,
            bridge_vintage_id: data.bridge_vintage_id,
        })
        reset()
        await fetchProject(id!)
    }

    const renderAvailableOptions = (): JSX.Element[] => {
        const existingVintages = project?.vintages.map((vintage: Vintage) => vintage.vintage)
        const availableVintages = []
        for (let i = 2010; i <= new Date().getFullYear() + 1; i++) {
            if (!existingVintages?.includes(i)) {
                availableVintages.push(String(i))
            }
        }

        return availableVintages.map((vintage: string) => {
            return (
                <option key={vintage} value={vintage}>
                    {vintage}
                </option>
            )
        })
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.PROJECTS}>
            <div className="p-4">
                <p className="text-center py-8">
                    <span className="px-6 font-medium text-gray-900 whitespace-nowra">
                        Project Name:
                    </span>
                    <span>{project?.name}</span>
                </p>
                <p className="py-4">
                    <span className="font-medium text-gray-900 whitespace-nowra">
                        Project Location:
                    </span>
                    <span>&nbsp;{project?.location}</span>
                </p>
                <p className="py-4">
                    <span className="font-medium text-gray-900 whitespace-nowra">
                        Project Registry:
                    </span>
                    <span>&nbsp;{project?.registry.name}</span>
                </p>
                <div className="py-4">
                    <p className="font-medium text-gray-900 whitespace-nowra">Vintages:</p>
                    <div className="flex flex-wrap gap-2">
                        {project?.vintages.map((vintage: Vintage) => {
                            return (
                                <span
                                    key={vintage.id}
                                    className="bg-gray-200 h-9 w-9 flex justify-center items-center rounded-full"
                                >
                                    {vintage.vintage}
                                </span>
                            )
                        })}
                    </div>
                </div>
                <div className="py-4">
                    <p className="font-medium text-gray-900 whitespace-nowra mb-10">
                        Create Vintage:
                    </p>
                    <form onSubmit={handleSubmit(addVintage)}>
                        <div className="flex gap-2">
                            <div className="mb-6 text-left w-1/2">
                                <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    Tcc Address
                                </label>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    {...register('tcc_address', {
                                        required: true,
                                    })}
                                    className="block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                                <div className="h-5">
                                    {errors?.tcc_address?.type === 'required' && (
                                        <FieldErrorLabel error="Tcc address is required" />
                                    )}
                                </div>
                            </div>
                            <div className="mb-6 text-left w-1/2">
                                <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    Registry url
                                </label>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    {...register('registry_url')}
                                    className="block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                        </div>
                        <div className="flex gap-2">
                            <div className="grid mb-6 w-1/2">
                                <label className="mb-2 text-sm font-medium text-gray-900">
                                    Vintage
                                </label>
                                <select
                                    className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                    {...register('vintage', {
                                        required: true,
                                        validate: (type: string) => {
                                            return type !== 'Choose'
                                        },
                                    })}
                                >
                                    <option>Choose</option>
                                    {renderAvailableOptions()}
                                </select>
                                <div className="h-4">
                                    {errors?.vintage?.type === 'validate' && (
                                        <FieldErrorLabel error="Vintage is required" />
                                    )}
                                </div>
                            </div>
                            <div className="grid mb-6 w-1/2">
                                <label className="mb-2 text-sm font-medium text-gray-900">
                                    Bridge Vintage ID
                                </label>
                                <input
                                    type="text"
                                    placeholder="Bridge Vintage ID"
                                    {...register('bridge_vintage_id', {
                                        required: true,
                                    })}
                                    className="h-10 block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                                />
                                <div className="h-4">
                                    {errors?.bridge_vintage_id?.type === 'required' && (
                                        <FieldErrorLabel error="Bridge vintage id is required" />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center pt-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button
                                data-modal-toggle="defaultModal"
                                type="submit"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </AdminLayout>
    )
}

export default AdminCreateVintage
