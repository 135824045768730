import StatusPill, { CircleStyle, Colour } from '../../statusPill/components/StatusPill'
import { UnbridgeRequestStatuses } from '../enums/UnbridgeRequestStatuses'

interface Props {
    status: UnbridgeRequestStatuses
}

const WithdrawalStatusPill = ({ status }: Props): JSX.Element => {
    switch (status) {
        case UnbridgeRequestStatuses.PENDING:
        case UnbridgeRequestStatuses.IN_PROGRESS:
            return (
                <StatusPill colour={Colour.YELLOW} circleStyle={CircleStyle.SEMI_FILLED}>
                    Pending
                </StatusPill>
            )
        case UnbridgeRequestStatuses.COMPLETE:
            return (
                <StatusPill colour={Colour.GREEN} circleStyle={CircleStyle.FILLED}>
                    Complete
                </StatusPill>
            )
        case UnbridgeRequestStatuses.RETURNED:
            return (
                <StatusPill colour={Colour.RED} circleStyle={CircleStyle.FILLED}>
                    Cancelled
                </StatusPill>
            )
        case UnbridgeRequestStatuses.FAILED:
            return (
                <StatusPill colour={Colour.RED} circleStyle={CircleStyle.NOT_FILLED}>
                    Failed
                </StatusPill>
            )
        default:
            throw new Error(`Unknown retirement status: ${status}`)
    }
}

export default WithdrawalStatusPill
