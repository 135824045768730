import { BuyOrderStatus } from '../http/enums/BuyOrderStatus'
import StatusPill, { CircleStyle, Colour } from '../../statusPill/components/StatusPill'

interface Props {
    status: BuyOrderStatus
}

const BuyOrderStatusPill = ({ status }: Props): JSX.Element => {
    switch (status) {
        // case BuyOrderStatus.OPEN:
        //     return (
        //         <StatusPill colour={Colour.BLUE} circleStyle={CircleStyle.NOT_FILLED}>
        //             Added to Invoice
        //         </StatusPill>
        //     )
        case BuyOrderStatus.OPEN:
        case BuyOrderStatus.CLOSED:
        case BuyOrderStatus.PENDING_SETTLEMENT:
            return (
                <StatusPill colour={Colour.YELLOW} circleStyle={CircleStyle.SEMI_FILLED}>
                    Pending
                </StatusPill>
            )
        case BuyOrderStatus.SETTLED:
            return (
                <StatusPill colour={Colour.GREEN} circleStyle={CircleStyle.FILLED}>
                    Complete
                </StatusPill>
            )
        case BuyOrderStatus.CANCELLED:
            return (
                <StatusPill colour={Colour.RED} circleStyle={CircleStyle.NOT_FILLED}>
                    Cancelled
                </StatusPill>
            )
        default:
            throw new Error(`Unknown buy order status: ${status}`)
    }
}

export default BuyOrderStatusPill
