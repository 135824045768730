import { RoleName } from '../../../../modules/user/enums/RoleName'
import { Role } from '../../../../modules/user/types/Role'
import { User } from '../../../../modules/user/types/User'

interface RoleProps {
    user: User
}

export const RoleBadge = ({ role }: { role: Role }): JSX.Element => {
    let className: string
    switch (role.name) {
        case RoleName.ADMIN:
            className = 'bg-red-100 text-red-800'
            break
        case RoleName.API_USER:
            className = 'bg-blue-100 text-blue-800'
            break
        case RoleName.MARKETPLACE_USER:
            className = 'bg-yellow-100 text-yellow-800'
            break
        case RoleName.CAAS_PRO:
            className = 'bg-green-100 text-green-800'
            break
        default:
            className = ''
    }
    return (
        <span
            className={`${className} flex justify-center items-center text-center text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full`}
        >
            {role.display_name}
        </span>
    )
}

const Roles = ({ user }: RoleProps): JSX.Element => {
    return (
        <div className="flex flex-row">
            {user.roles?.slice(0, 1).map((role: Role) => <RoleBadge role={role} key={role.id} />)}
            {user.roles.length > 1 && (
                <span className="flex justify-center items-center text-center text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
                    {user.roles.length - 1} +
                </span>
            )}
        </div>
    )
}

export default Roles
