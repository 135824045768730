import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AdminFeatureFlagApi } from '../../modules/featureFlag/http/AdminFeatureFlagApi'
import { Spinner } from 'flowbite-react'
import { SearchFeatureFlagsParams } from '../../../modules/featureFlags/http/types/SearchFeatureFlagsParams'
import { SearchFeatureFlagsResponse } from '../../../modules/featureFlags/http/types/SearchFeatureFlagsResponse'
import AdminLayout from '../../modules/common/components/AdminLayout'
import FeatureFlagsTable from '../../modules/featureFlag/components/FeatureFlagsTable'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'

const AdminFeatureFlags = (): JSX.Element => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchInput, setSearchInput] = useState<string>('')
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [searchFeatureFlagsResponse, setSearchFeatureFlagsResponse] =
        useState<SearchFeatureFlagsResponse | null>(null)
    const intervalRef = useRef<any>(null)

    const searchFeatureFlags = async (params: SearchFeatureFlagsParams = {}): Promise<void> => {
        setShowSpinner(true)
        const response = await AdminFeatureFlagApi.adminSearch(params)
        if (response) {
            setSearchFeatureFlagsResponse(response.data)
        }
        setShowSpinner(false)
    }

    useEffect(() => {
        void searchFeatureFlags({
            page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
        })
    }, [])

    useEffect(() => {
        let queryParams: SearchFeatureFlagsParams = {
            search: searchInput,
            page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
        }
        intervalRef.current = setTimeout(() => {
            void searchFeatureFlags(queryParams)
        }, 500)
    }, [searchInput])

    const onInputSearchChangeHandler = async (
        e: React.ChangeEvent<HTMLInputElement>,
    ): Promise<void> => {
        setSearchInput(e.target.value)
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.FEATURE_FLAGS}>
            <div className="flex justify-between mb-4">
                <div className="relative text-gray-600 focus-within:text-gray-400">
                    <input
                        type="search"
                        autoFocus={true}
                        className={`h-11 w-full rounded-lg border border-gray-300 py-2.5 pl-10 text-gray-500 ${
                            showSpinner ? 'bg-gray-100' : null
                        }`}
                        disabled={showSpinner}
                        placeholder="Search"
                        value={searchInput}
                        onChange={onInputSearchChangeHandler}
                    />
                </div>
            </div>
            <div className="flex items-center w-full h-4 justify-center absolute left-1/2 z-10">
                {showSpinner && <Spinner />}
            </div>
            <FeatureFlagsTable
                searchFeatureFlagsResponse={searchFeatureFlagsResponse}
                onPageChangeHandler={(pageNumber: number): void => {
                    setSearchParams({ page: String(pageNumber) })
                    searchFeatureFlags({
                        page: pageNumber,
                    })
                }}
            />
        </AdminLayout>
    )
}

export default AdminFeatureFlags
