import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { RetirePayload } from '../../sellOrders/http/types/PurchaseAndRetirePayload'
import { SearchRetirementRequestsParams } from './types/SearchRetirementRequestsParams'
import { SearchDatetimeParams } from '../../http/types/SearchDatetimeParams'
import { RetirementStats } from './types/RetirementStats'
import { SearchResponseDto } from './types/SearchResponse'
import { RetirementRequestDto } from './types/RetirementRequestDto'

export interface RetirementRequestsApiInterface {
    search(
        params: SearchRetirementRequestsParams,
    ): Promise<AxiosResponse<SearchResponseDto> | undefined>
    getRetirementStats(params: SearchDatetimeParams): Promise<AxiosResponse<RetirementStats>>
    retireFromHold(
        params: RetirePayload,
        requestId: string,
    ): Promise<AxiosResponse<void> | undefined>
    getById(id: string): Promise<AxiosResponse<RetirementRequestDto>>
}

export const RetirementRequestsApi: RetirementRequestsApiInterface = Object.freeze({
    search(
        params: SearchRetirementRequestsParams,
    ): Promise<AxiosResponse<SearchResponseDto> | undefined> {
        return HttpService.get('/retirement-requests', null, params)
    },
    getRetirementStats(params: SearchDatetimeParams): Promise<AxiosResponse<RetirementStats>> {
        return HttpService.get('/retirement-requests/stats', null, params)
    },
    retireFromHold(
        params: RetirePayload,
        requestId: string,
    ): Promise<AxiosResponse<void> | undefined> {
        return HttpService.post('/retirement-requests', params, {
            'request-id': requestId,
        })
    },
    getById(id: string): Promise<AxiosResponse<RetirementRequestDto>> {
        return HttpService.get('/retirement-requests', id)
    },
})
