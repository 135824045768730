import { TagButton } from '../../../modules/common/components/TagButton'
import { useSellOrdersStore } from '../../../modules/sellOrders/context/store'

const MarketFilterTags = (): JSX.Element => {
    const {
        searchSellOrderFilters,
        isFormDisabled,
        setSearchSellOrderFilters,
        setShouldShowPriceFilters,
    } = useSellOrdersStore()
    return (
        <div className="flex flex-row gap-5.5 px-5">
            {searchSellOrderFilters.projectName && (
                <TagButton
                    name="Project Name"
                    onClose={() =>
                        setSearchSellOrderFilters({
                            ...searchSellOrderFilters,
                            projectName: '',
                        })
                    }
                    disabled={isFormDisabled}
                />
            )}
            {!!searchSellOrderFilters.projectType.length && (
                <TagButton
                    name={`Type (${searchSellOrderFilters.projectType.length})`}
                    onClose={() => {
                        setSearchSellOrderFilters({
                            ...searchSellOrderFilters,
                            projectType: [],
                        })
                    }}
                    disabled={isFormDisabled}
                />
            )}
            {(searchSellOrderFilters.price.min > 0 || searchSellOrderFilters.price.max > 0) && (
                <TagButton
                    name={`$${Number(searchSellOrderFilters.price?.min).toFixed(
                        2,
                    )} - $${Number(searchSellOrderFilters.price?.max).toFixed(2)}`}
                    onClose={() => {
                        setSearchSellOrderFilters({
                            ...searchSellOrderFilters,
                            price: {
                                min: 0,
                                max: 0,
                            },
                        })
                        setShouldShowPriceFilters(false)
                    }}
                    disabled={isFormDisabled}
                />
            )}
            {!!searchSellOrderFilters.locations.length && (
                <TagButton
                    name={`Location (${searchSellOrderFilters.locations.length})`}
                    onClose={() => {
                        setSearchSellOrderFilters({
                            ...searchSellOrderFilters,
                            locations: [],
                        })
                        setShouldShowPriceFilters(false)
                    }}
                    disabled={isFormDisabled}
                />
            )}
            {!!searchSellOrderFilters.sdgs.length && (
                <TagButton
                    name={`SDG (${searchSellOrderFilters.sdgs.length})`}
                    onClose={() => {
                        setSearchSellOrderFilters({
                            ...searchSellOrderFilters,
                            sdgs: [],
                        })
                        setShouldShowPriceFilters(false)
                    }}
                    disabled={isFormDisabled}
                />
            )}
            {!!searchSellOrderFilters.vintages.length && (
                <TagButton
                    name={`Vintage (${searchSellOrderFilters.vintages.length})`}
                    onClose={() => {
                        setSearchSellOrderFilters({
                            ...searchSellOrderFilters,
                            vintages: [],
                        })
                        setShouldShowPriceFilters(false)
                    }}
                    disabled={isFormDisabled}
                />
            )}
        </div>
    )
}
export default MarketFilterTags
