import { Registry } from '../types/Registry'
import { RegistryAction, SET_REGISTRIES } from './types'

export interface RegistryState {
    registries: Registry[]
    setRegistries: (registries: Registry[]) => void
    fetchRegistries: () => Promise<void>
}

export const initialRegistrytSoreState: RegistryState = {
    registries: [],
    setRegistries: () => {},
    fetchRegistries: async () => {},
}

export const registryReducer = (
    state = initialRegistrytSoreState,
    action: RegistryAction,
): RegistryState => {
    switch (action.type) {
        case SET_REGISTRIES:
            return {
                ...state,
                registries: action.payload.registries,
            }
        default:
            return state
    }
}
