import { FeatureFlagName } from '../../common/enums/FeatureFlagName'
import { Page } from '../../common/enums/Pages'
import IsFeatureEnabledHoc from '../../featureFlags/components/IsFeatureEnabledHoc'
import { useUserStore } from '../../user/context/store'
import { RoleName } from '../../user/enums/RoleName'
import NavBarItem from './NavBarItem'

export enum NavBarItemName {
    DASHBOARD = 'Dashboard',
    MARKET = 'Market',
    ORDERS = 'Orders',
    INVENTORY = 'Inventory',
    RETIREMENTS = 'Retirements',
    CREDIT_WITHDRAWALS = 'Credit Withdrawals',
    ADMIN = 'Admin',
    NONE = 'none',
}

export interface NavBarItemType {
    text: NavBarItemName
    page: Page
    roles: RoleName[]
    featureFlagControlEnabled: boolean
}

export interface NavBarProps {
    selectedNavBarItem: NavBarItemName
    isMobileVersion?: boolean
}

const NavBar = ({ selectedNavBarItem, isMobileVersion = false }: NavBarProps): JSX.Element => {
    const { checkUserHasAtLeastOneRole, isCaasUser, isCaasProUser } = useUserStore()
    const orderInitialNav: Partial<Record<RoleName, Page>> = {
        [RoleName.CAAS_USER]: Page.ORDERS_BUY_ORDERS,
        [RoleName.CAAS_PRO]: Page.ORDERS_BUY_ORDERS,
        [RoleName.MARKETPLACE_USER]: Page.ORDERS_SELL_ORDERS,
    }

    const ordersSubNav: Page =
        isCaasProUser() || isCaasUser()
            ? orderInitialNav[RoleName.CAAS_USER]!
            : orderInitialNav[RoleName.MARKETPLACE_USER]!

    const navBarItems: NavBarItemType[] = [
        {
            text: NavBarItemName.MARKET,
            page: Page.MARKET,
            roles: [RoleName.CAAS_USER, RoleName.MARKETPLACE_USER, RoleName.CAAS_PRO],
            featureFlagControlEnabled: false,
        },
        {
            text: NavBarItemName.DASHBOARD,
            page: Page.CAAS_DASHBOARD,
            roles: [RoleName.CAAS_USER, RoleName.CAAS_PRO],
            featureFlagControlEnabled: false,
        },
        {
            text: NavBarItemName.ORDERS,
            page: ordersSubNav,
            roles: [RoleName.MARKETPLACE_USER, RoleName.CAAS_USER, RoleName.CAAS_PRO],
            featureFlagControlEnabled: false,
        },
        {
            text: NavBarItemName.INVENTORY,
            page: Page.INVENTORY,
            roles: [RoleName.MARKETPLACE_USER, RoleName.CAAS_PRO],
            featureFlagControlEnabled: false,
        },
        {
            text: NavBarItemName.RETIREMENTS,
            page: Page.RETIREMENTS,
            roles: [RoleName.CAAS_USER, RoleName.CAAS_PRO],
            featureFlagControlEnabled: false,
        },
        {
            text: NavBarItemName.CREDIT_WITHDRAWALS,
            page: Page.CREDIT_WITHDRAWALS,
            roles: [RoleName.MARKETPLACE_USER],
            featureFlagControlEnabled: false,
        },
        {
            text: NavBarItemName.ADMIN,
            page: Page.ADMIN_USERS,
            roles: [RoleName.ADMIN],
            featureFlagControlEnabled: false,
        },
    ]

    return (
        <div
            className={
                isMobileVersion
                    ? 'px-2 pt-2 pb-3 space-y-1 absolute bg-white'
                    : 'flex gap-1 justify-start h-9'
            }
        >
            {navBarItems.map((item) => {
                if (checkUserHasAtLeastOneRole(item.roles)) {
                    const isSelected = item.text === selectedNavBarItem
                    if (item.featureFlagControlEnabled) {
                        return (
                            <IsFeatureEnabledHoc
                                key={item.text}
                                featureFlagname={FeatureFlagName.ENABLE_WALLET_PAGE}
                            >
                                <NavBarItem
                                    text={item.text}
                                    isSelected={isSelected}
                                    route={item.page}
                                />
                            </IsFeatureEnabledHoc>
                        )
                    }
                    return (
                        <NavBarItem
                            key={item.text}
                            text={item.text}
                            isSelected={isSelected}
                            route={item.page}
                        />
                    )
                }
            })}
        </div>
    )
}

export default NavBar
