import { OrderType } from '../enums/OrderType'
import { SearchMyOrdersResponse } from '../http/types/SearchMyOrdersResponse'
import {
    ModalType,
    SET_CURRENT_MODAL,
    SET_IS_LOADING,
    SET_ORDERS_RESPONSE,
    SET_SELECTED_ORDER_TYPE,
    SetCurrentModalAction,
    SetIsLoadingAction,
    SetOrdersResponseAction,
    SetSelectedOrderTypeAction,
} from './types'
import { Dispatch } from 'react'

export const setSelectedOrderTypeAction = (
    orderType: OrderType,
    dispatch: Dispatch<SetSelectedOrderTypeAction>,
): void => {
    dispatch({
        type: SET_SELECTED_ORDER_TYPE,
        payload: orderType,
    })
}

export const setOrdersResponseAction = (
    response: SearchMyOrdersResponse | null,
    dispatch: Dispatch<SetOrdersResponseAction>,
): void => {
    dispatch({
        type: SET_ORDERS_RESPONSE,
        payload: { response },
    })
}

export const setIsLoadingAction = (
    isLoading: boolean,
    dispatch: Dispatch<SetIsLoadingAction>,
): void => {
    dispatch({
        type: SET_IS_LOADING,
        payload: { isLoading },
    })
}

export const setIsEditModalVisibleAction = (
    modal: ModalType,
    dispatch: Dispatch<SetCurrentModalAction>,
): void => {
    dispatch({
        type: SET_CURRENT_MODAL,
        payload: { modal },
    })
}
