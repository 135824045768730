import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BackedBySource } from '../../carbonCreditBalance/enums/BackedBySource'
import { PrimaryButton } from '../../common/components/Button'
import { Page } from '../../common/enums/Pages'
import { SellOrder } from '../../sellOrders/types/SellOrder'
import { useUserStore } from '../../user/context/store'
import { ActionName } from '../../action/enums/ActionName'
import BuyRetireModal from './BuyRetireModal'
import { Vintage } from '../../vintage/types/Vintage'
import { Project } from '../../project/types/Project'
import { SellOrderStatus } from '../../sellOrders/enums/SelOrderStatus'

interface Props {
    sellOrder: SellOrder | null
    vintage: Vintage | null
    project: Project | null
}

const Confirmed = ({ caasUser }: { caasUser: boolean }): JSX.Element => {
    const textOne = caasUser ? 'Purchase complete!' : 'Your interest has been submitted!'

    const textTwo = caasUser
        ? 'Your credits have been added to your monthly retirement queue and invoice.'
        : 'Thallo’s purchasing team will be in touch shortly.'

    const navigate = useNavigate()

    return (
        <div className="flex flex-col justify-center items-center h-full min-h-[680px]">
            <div className="flex justify-center">
                <img src="/assets/icon_confirmed.svg" className="w-12 h-12" alt="icon_confirmed" />
            </div>
            <p className="text-gray-900 text-center text-lg font-semibold mb-2">{textOne}</p>
            <p className="px-[23px] text-sm text-gray-500 font-normal text-center">{textTwo}</p>
            <div className="px-[23px] mt-7 w-full">
                <PrimaryButton className="h-12 w-full" onClick={() => navigate(Page.MARKET)}>
                    Browse More
                </PrimaryButton>
            </div>
        </div>
    )
}

const BuyCreditsDetails = ({ sellOrder, vintage, project }: Props): JSX.Element => {
    const [showPurchaseComplete, setShowPurchaseComplete] = useState<boolean>(false)
    const [showBuyModal, setShowBuyModal] = useState<boolean>(false)
    const { user, isCaasUser, isCaasProUser } = useUserStore()
    if (
        !sellOrder ||
        sellOrder?.tenant_id === user?.tenant_id ||
        sellOrder.status !== SellOrderStatus.LIVE ||
        sellOrder.quantity === '0'
    )
        return <></>

    const caasUser = isCaasUser()
    const caasProUser = isCaasProUser()
    return (
        <>
            <div className="w-full xl:w-96 pt-8 pb-12 border border-gray-200 rounded-2xl buy-credits-form h-full mx-auto">
                {showPurchaseComplete && <Confirmed caasUser={caasUser || caasProUser} />}
                {!showPurchaseComplete && (
                    <div>
                        <div className="px-8 pb-3 border-b border-gray-200">
                            <h3 className="mb-4 text-2xl font-bold text-gray-700">
                                Purchase Credits
                            </h3>
                        </div>
                        <div className="px-6 mt-7">
                            <PrimaryButton
                                id="buy-credits-button"
                                className="w-full"
                                onClick={() => {
                                    setShowBuyModal(true)
                                }}
                            >
                                {caasUser || caasProUser ? 'Buy' : 'Submit Interest'}
                            </PrimaryButton>
                        </div>
                    </div>
                )}
            </div>
            {sellOrder.carbon_credit_balance?.backed_by_source ===
                BackedBySource.THALLO_TOKENISED_CREDITS && (
                <div
                    className="mt-4 p-8 border border-gray-200 rounded-2xl text-gray-500"
                    style={{
                        boxShadow:
                            '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
                        wordBreak: 'break-word',
                    }}
                >
                    <h2 className="font-normal text-md max-w-[320px]">
                        <span className="font-semibold inline-block mb-2">
                            These carbon credits are tokenized
                        </span>
                        <br />
                        This digital twin enables:
                    </h2>
                    <ol className="text-base font-normal whitespace-pre-line list-disc mt-2 pl-[20px]">
                        <li> A transparent, auditable record of data</li>
                        <li> Eliminates double counting</li>
                        <li> The ability to add more digital data to enrich an existing credit</li>
                    </ol>
                </div>
            )}
            <BuyRetireModal
                sellOrder={sellOrder}
                vintage={vintage}
                project={project}
                show={showBuyModal}
                showPurchaseComplete={showPurchaseComplete}
                setShowPurchaseComplete={setShowPurchaseComplete}
                setShow={setShowBuyModal}
                defaultAction={ActionName.PURCHASE}
            />
        </>
    )
}

export default BuyCreditsDetails
