import { AxiosResponse } from 'axios'
import HttpService from '../../../../modules/http/httpService'
import { EmptyResponse } from '../../../../modules/http/types/EmptyResponse'
import { BackedBySource } from '../../../../modules/carbonCreditBalance/enums/BackedBySource'
import { AdminSearchCarbonCreditBalanceParams } from './types/AdminSearchCarbonCreditBalanceParams'
import { CarbonCreditBalance } from '../../../../modules/carbonCreditBalance/types/CarbonCreditBalance'
import { PatchCarbonCreditBalanceParams } from './types/PatchCarbonCreditBalanceParams'
import { AdminSearchCarbonCreditBalancesResponse } from './types/AdminSearchCarbonCreditBalancesResponse'

export interface CarbonCreditBalanceApiInterface {
    create(
        tenantId: string,
        vintageId: string,
        liquidAmount: string,
        caasLiquidAmount: string,
        backed_by_source: BackedBySource,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
    adminSearch(
        params: AdminSearchCarbonCreditBalanceParams,
    ): Promise<AxiosResponse<AdminSearchCarbonCreditBalancesResponse> | undefined>
    delete(id: string): Promise<AxiosResponse<EmptyResponse> | undefined>
    adminGetById(id: string): Promise<AxiosResponse<CarbonCreditBalance> | undefined>
    patch(
        id: string,
        payload: PatchCarbonCreditBalanceParams,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
}

export const AdminCarbonCreditBalanceApi: CarbonCreditBalanceApiInterface = Object.freeze({
    create(
        tenant_id: string,
        vintage_id: string,
        liquid_amount: string,
        caas_liquid_amount: string,
        backed_by_source: BackedBySource,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('admin/carbon-credit-balances', {
            tenant_id,
            vintage_id,
            liquid_amount,
            caas_liquid_amount,
            backed_by_source,
        })
    },
    adminSearch(
        params: AdminSearchCarbonCreditBalanceParams,
    ): Promise<AxiosResponse<AdminSearchCarbonCreditBalancesResponse> | undefined> {
        return HttpService.get('/admin/carbon-credit-balances', null, params)
    },

    delete(id: string): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.delete(`/admin/carbon-credit-balances/${id}`)
    },

    adminGetById(id: string): Promise<AxiosResponse<CarbonCreditBalance> | undefined> {
        return HttpService.get(`/admin/carbon-credit-balances/${id}`)
    },

    patch(
        id: string,
        payload: PatchCarbonCreditBalanceParams,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.patch(`/admin/carbon-credit-balances/${id}`, payload)
    },
})
