import React, { useEffect, useState } from 'react'
import { SecondaryButton, PrimaryButton } from '../../common/components/Button'
import { SellOrder } from '../../sellOrders/types/SellOrder'
import { ActionName } from '../../action/enums/ActionName'
import { BuyFormData } from './BuyRetireModal'
import { SingleCarbonCreditBalance } from '../../carbonCreditBalance/types/SingleCarbonCreditBalance'
import { NewSubCustomerdetails } from '../types/NewSubCustomerDetails'
import { convertGramsToMetricTonnes, convertCentsToLocaleUsd } from '../../../utils/conversion'
import { actionDescription } from '../../action'
import CreateSubCustomerForm from '../../user/components/CreateSubCustomerForm'
import { useUserStore } from '../../user/context/store'
import { ListItem } from '../../form/types/ListItem'
import { FormElement } from '../../form/types/Field'
import { Button } from '../../form/types/Button'
import Form from '../../form/components/Form'
import { UseFormReturn } from 'react-hook-form'
import { SubCustomerResponse } from '../../user/types/SubCustomerResponse'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { Locations } from '../../common/enums/Locations'
import { Page } from '../../common/enums/Pages'
import { UserApi } from '../../user/http/UserApi'
import { ErrorResponse } from '../../http/types/ErrorResponse'
import Select, { SingleValue } from 'react-select'
import { VintageCarbonCreditBalance } from '../../vintage/types/VintageCarbonCreditBalance'
import ContextSwitcher, { ContextOption } from '../../common/components/ContextSwitcher'
import Note, { NoteType } from '../../note/components/Note'

interface Props {
    sellOrder?: SellOrder | null
    carbonCreditBalance?: SingleCarbonCreditBalance | VintageCarbonCreditBalance | null
    form: UseFormReturn<BuyFormData>
    isSubmitButtonEnabled: boolean
    showSpinner: boolean
    action: ActionName
    createSubCustomer: boolean
    onSubmitHandler: () => void
    expetecedTotalPrice: string
    onClose: () => void
    setCreateSubCustomer: (value: boolean) => void
    setAction: (action: ActionName) => void
    setError: (error: string | null) => void
}

const BuyCreditsForm = ({
    sellOrder,
    carbonCreditBalance,
    form,
    isSubmitButtonEnabled,
    showSpinner,
    action,
    createSubCustomer,
    onSubmitHandler,
    expetecedTotalPrice,
    onClose,
    setCreateSubCustomer,
    setAction,
    setError,
}: Props): JSX.Element => {
    const [newSubCustomerdetails, setNewSubCustomerDetails] =
        useState<NewSubCustomerdetails | null>(null)
    const [subCustomers, setSubCustomers] = useState<SubCustomerResponse[]>([])
    const navigate = useNavigate()

    const { user, isCaasOrCaasProUser, isCaasProUser } = useUserStore()
    const caasOrCaasProUser = isCaasOrCaasProUser()
    const caasProUser = isCaasProUser()
    const tenantDisplayName = `${user!.tenant?.entity_name} (Your Company)`

    const defaultRetiree = {
        retireeId: user!.tenant_id,
        retireeName: tenantDisplayName,
    }

    const [subCustomer, setSubCustomer] = useState<{ retireeId: string; retireeName: string }>(
        defaultRetiree,
    )

    const fetchSubCustomers = async (): Promise<void> => {
        try {
            const response = await UserApi.getSubCustomers()
            if (response) {
                setSubCustomers([
                    {
                        external_customer_id: user!.tenant_id,
                        external_customer_name: tenantDisplayName,
                        external_customer_tax_id: '-',
                        external_customer_country: Locations['United Kingdom'],
                    },
                    ...response.data.sort((a, b) => {
                        const aName = a.external_customer_name.toLowerCase()
                        const bName = b.external_customer_name.toLowerCase()
                        if (aName > bName) return 1
                        if (aName < bName) return -1
                        return 0
                    }),
                ])
            }
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.status === 404) {
                navigate(Page.MARKET)
                return
            }
        }
    }

    useEffect(() => {
        void fetchSubCustomers()
    }, [newSubCustomerdetails])

    const setFormSubCustomer = (subCustomer: SubCustomerResponse | null): void => {
        form.setValue('retireeId', subCustomer?.external_customer_id ?? null)
        form.setValue('retireeName', subCustomer?.external_customer_name ?? null)
        form.setValue('retireeTaxId', subCustomer?.external_customer_tax_id ?? null)
        form.setValue('retireeLocation', subCustomer?.external_customer_country ?? null)
        if (!subCustomer) {
            setSubCustomer(defaultRetiree)
        } else {
            setSubCustomer({
                retireeId: subCustomer.external_customer_id,
                retireeName: subCustomer.external_customer_name,
            })
        }
    }

    useEffect(() => {
        if (
            !subCustomers ||
            (!newSubCustomerdetails?.retireeId && !newSubCustomerdetails?.retireeName)
        )
            return
        const subCustomer = subCustomers.find((customer) => {
            return newSubCustomerdetails.retireeId
                ? customer.external_customer_id === newSubCustomerdetails.retireeId
                : customer.external_customer_name === newSubCustomerdetails.retireeName
        })
        if (!subCustomer) {
            return
        }
        setFormSubCustomer(subCustomer)
    }, [subCustomers, newSubCustomerdetails])

    const buyRetireTabs: ContextOption<ActionName>[] = [
        { value: ActionName.PURCHASE, display: 'Buy and Hold' },
        { value: ActionName.BUY_RETIRE, display: 'Buy and Retire' },
    ]
    const [tabs, setTabs] = useState<ContextOption<ActionName>[]>(buyRetireTabs)
    const headings: Partial<Record<ActionName, string>> = {
        [ActionName.PURCHASE]: 'Purchase Credits',
        [ActionName.BUY_RETIRE]: 'Purchase Credits',
        [ActionName.INTEREST]: 'Submit Interest',
        [ActionName.RETIRE]: 'Retire Credits',
    }

    const confirmCreateSubCustomer = async (retireeName: string, retireeId: string | null) => {
        setNewSubCustomerDetails({ retireeName, retireeId })
        setCreateSubCustomer(false)
    }

    const ctaText = (): string => {
        if (!caasOrCaasProUser) return 'Review'
        if (action === ActionName.PURCHASE) return 'Review Purchase'
        if (action === ActionName.BUY_RETIRE) return 'Review Retirement'
        if (action === ActionName.RETIRE) return 'Review Retirement'
        return 'Review'
    }

    useEffect(() => {
        if (action === ActionName.RETIRE) {
            setTabs([])
        } else {
            setTabs(buyRetireTabs)
        }
    }, [action])

    const listItems: ListItem[] =
        action === ActionName.RETIRE
            ? []
            : [
                  {
                      label: ['Project'],
                      value: sellOrder?.project_name ?? '',
                  },
                  {
                      label: ['Vintage'],
                      value: String(sellOrder?.vintage),
                  },
                  {
                      label: ['Cost per Credit', '(1 tCO2e)'],
                      value: convertCentsToLocaleUsd(sellOrder?.price ?? '0'),
                  },
              ]

    let fields: FormElement<BuyFormData>[] = [
        {
            name: 'quantity',
            displayName: 'Quantity',
            type: 'number_slider',
            required: true,
            min: 1,
            max: convertGramsToMetricTonnes(
                sellOrder?.quantity ?? carbonCreditBalance?.liquid_amount ?? '0',
            ),
            step: 1,
            id: 'purchase-quantity',
        },
    ]

    if (!caasOrCaasProUser) {
        const extraFields: FormElement<BuyFormData>[] = [
            {
                name: 'price',
                displayName: 'Offer',
                type: 'dollar',
                required: true,
                id: 'offer-input-quantity',
            },
            {
                name: 'note',
                displayName: 'Note',
                type: 'textarea',
                required: false,
                id: 'offer_note',
            },
        ]
        fields.push(...extraFields)
    }

    if (caasOrCaasProUser) {
        const extraFields: FormElement<BuyFormData>[] = [
            <>
                {tabs.length > 0 && (
                    <div className="py-6 flex">
                        <ContextSwitcher options={tabs} onSelect={setAction} selected={action} />
                    </div>
                )}
                <div className="text-left">
                    <Note type={NoteType.INFO}>
                        <span>{actionDescription(action)}</span>
                    </Note>
                    {action === ActionName.PURCHASE && !caasProUser && (
                        <div className="pt-4 italic">
                            <a
                                className="text-[#e46313] hover:underline hover:underline-offset-2"
                                href="mailto:support@thallo.io?subject=Upgrade%20Request"
                                target="_blank"
                            >
                                Upgrade now
                            </a>{' '}
                            to unlock Buy and Hold
                        </div>
                    )}
                </div>
            </>,
        ]
        fields.push(...extraFields)
    }

    if ([ActionName.BUY_RETIRE, ActionName.RETIRE].includes(action)) {
        const extraFields: FormElement<BuyFormData>[] = [
            {
                name: 'retireeName',
                displayName: 'Retiree',
                type: 'text',
                required: true,
                id: 'retiree-name',
                inputOverride: (
                    <div className="flex flex-row">
                        <Select
                            className="flex-1 basic-single"
                            id="retiree-name"
                            inputId="retiree-name-input"
                            classNamePrefix="select"
                            defaultValue={{
                                value: user!.tenant_id,
                                label: tenantDisplayName,
                            }}
                            value={{
                                value: subCustomer.retireeId,
                                label: subCustomer.retireeName,
                            }}
                            options={subCustomers.map((subCustomer) => {
                                return {
                                    value: subCustomer.external_customer_id,
                                    label: subCustomer.external_customer_name,
                                }
                            })}
                            onChange={(value: SingleValue<{ value: string; label: string }>) => {
                                if (
                                    !value &&
                                    subCustomers.find(
                                        (subCustomer) =>
                                            subCustomer.external_customer_id ===
                                            form.getValues('retireeId'),
                                    )
                                ) {
                                    setFormSubCustomer(null)
                                    return
                                }
                                const subCustomer = subCustomers.find(
                                    (subCustomer) =>
                                        subCustomer.external_customer_id === value?.value,
                                )

                                if (subCustomer) {
                                    return setFormSubCustomer(subCustomer)
                                }
                                form.setValue('retireeName', value?.label || null)
                            }}
                        />
                        <button
                            type="button"
                            id="create-sub-customer-plus"
                            onClick={() => {
                                setCreateSubCustomer(true)
                            }}
                            className="w-9 h-9 rounded-lg border border-gray-300 ml-2.5"
                            style={{
                                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                            }}
                        >
                            +
                        </button>
                    </div>
                ),
            },
        ]
        fields.push(...extraFields)
    }

    const buttons: Button[] = [
        {
            type: 'button',
            onClick: () => onClose(),
            disabled: showSpinner,
            label: 'Close',
            variation: SecondaryButton,
            id: 'buy-credits-close',
        },
        {
            type: 'button',
            className: 'w-full md:flex-1',
            onClick: onSubmitHandler,
            disabled: !isSubmitButtonEnabled,
            label: ctaText(),
            variation: PrimaryButton,
            id: 'review_purchase',
        },
    ]

    return (
        <>
            {!createSubCustomer && (
                <>
                    <Form
                        title={headings[action]!}
                        onSubmit={onSubmitHandler}
                        formId="buy_credits-form"
                        fields={fields}
                        headerListItems={listItems}
                        buttons={buttons}
                        buttonWrapperClassName="order-1 w-1/2"
                        form={form}
                    >
                        {![ActionName.RETIRE, ActionName.RETURN].includes(action) && (
                            <p className="font-semibold text-gray-700 mb-1.5 mt-2 text-xl pt-4">
                                {caasOrCaasProUser ? 'Total Price:' : 'Total Offer:'}
                                <span className="ml-1">
                                    {convertCentsToLocaleUsd(BigInt(expetecedTotalPrice))}
                                </span>
                            </p>
                        )}
                    </Form>
                </>
            )}
            {caasOrCaasProUser && createSubCustomer && (
                <CreateSubCustomerForm
                    onConfirm={confirmCreateSubCustomer}
                    onClose={() => {
                        setNewSubCustomerDetails(null)
                        setCreateSubCustomer(false)
                    }}
                    setError={setError}
                />
            )}
        </>
    )
}

export default BuyCreditsForm
