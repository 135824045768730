import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Project } from '../../modules/project/types/Project'
import { ProjectApi } from '../../modules/project/http/api'
import { Page } from '../../modules/common/enums/Pages'
import { ErrorResponse } from '../../modules/http/types/ErrorResponse'
import { AxiosError } from 'axios'
import Layout from '../../modules/common/components/Layout'
import {
    ProjectTypeImgType,
    getProjectTypeImgSrc,
} from '../../modules/project/getProjectTypeImgSrc'
import { SellOrder } from '../../modules/sellOrders/types/SellOrder'
import { Vintage } from '../../modules/vintage/types/Vintage'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import ProjectDetailsTable from '../../modules/project/components/ProjectDetailsTable'
import ProjectCarbonCreditBalance from '../../modules/carbonCreditBalance/components/ProjectCarbonCreditBalance'
import Credits from '../../modules/credits/components/Credits'
import PriceQuantityDetails from '../../modules/project/components/PriceQuantityDetails'
import LoadingWrapper from '../../modules/loadingWrapper/components/LoadingWrapper'
import BuyOrdersTable from '../../modules/buyOrders/components/BuyOrdersTable'
import RetirementsTable from '../../modules/retirement/components/RetirementsTable'
import SellOrderTable from '../../modules/sellOrders/components/SellOrderTable'
import { SellOrderStatus } from '../../modules/sellOrders/enums/SelOrderStatus'

interface Props {}

const ProjectPage = ({}: Props): JSX.Element => {
    const [project, setProject] = useState<Project | null>(null)
    const [vintages, setVintages] = useState<Map<number, Vintage>>(new Map())
    const [sellOrders, setSellOrders] = useState<Map<string, SellOrder>>(new Map())
    const [sellOrder, setSellOrder] = useState<SellOrder | null>(null)
    const [vintage, setVintage] = useState<Vintage | null>(null)

    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()

    const fetchProject = async () => {
        if (!id) {
            navigate(Page.MARKET)
            return
        }
        try {
            const response = await ProjectApi.get(id)
            setProject(response.data)
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.status === 404) {
                navigate(Page.MARKET)
                return
            }
        }
    }

    useEffect(() => {
        setVintages(new Map())
        setSellOrders(new Map())
        setSellOrder(null)
        setVintage(null)
        fetchProject()
    }, [])

    useEffect(() => {
        if (!project) return
        project.vintages = project.vintages.map((vintage) => {
            return {
                ...vintage,
                sell_orders: (vintage.sell_orders ?? []).sort(
                    (a, b) => Number(a.price) - Number(b.price),
                ),
            }
        })
        const newVintages = project.vintages.sort((a, b) => a.vintage - b.vintage)
        const orders = new Map(
            newVintages.flatMap<[string, SellOrder]>(
                (vintage) =>
                    (vintage.sell_orders ?? []).map((sellOrder): [string, SellOrder] => [
                        sellOrder.id,
                        sellOrder,
                    ]) ?? [],
            ),
        )
        setVintages(new Map(newVintages.map((vintage) => [vintage.vintage, vintage])))
        setSellOrders(orders)
    }, [project])

    const setParams = (sellOrderId: string = '', replace: boolean = false) => {
        const searchSellOrder = searchParams.get('sell_order_id')
        if (sellOrderId && searchSellOrder === sellOrderId) return
        setSearchParams({ ...searchParams, sell_order_id: sellOrderId }, { replace })
    }

    const isValidSellOrder = (sellOrder: SellOrder): boolean => {
        return Number(sellOrder.quantity) > 0 && sellOrder.status === SellOrderStatus.LIVE
    }

    useEffect(() => {
        const searchSellOrder = searchParams.get('sell_order_id')
        const stateVintage: string | undefined = location.state?.vintage
        let newSellOrder: SellOrder | null = sellOrders.get(searchSellOrder ?? '') ?? null
        let cheapestInvalidSellOrder: SellOrder | null = null
        let cheapestInvalidVintage: Vintage | null = null
        let newVintage: Vintage | null =
            vintages.get(newSellOrder?.vintage ?? Number(stateVintage ?? 0)) ?? null
        if (!newSellOrder && !searchSellOrder) {
            ;(newVintage ? [newVintage] : vintages).forEach((vin) =>
                vin.sell_orders?.forEach((vintageSellOrder) => {
                    if (
                        isValidSellOrder(vintageSellOrder) &&
                        (!newSellOrder ||
                            Number(vintageSellOrder.price) < Number(newSellOrder.price))
                    ) {
                        newSellOrder = vintageSellOrder
                        newVintage = vin
                    }
                    if (
                        !isValidSellOrder(vintageSellOrder) &&
                        (!cheapestInvalidSellOrder ||
                            Number(vintageSellOrder.price) < Number(cheapestInvalidSellOrder.price))
                    ) {
                        cheapestInvalidSellOrder = vintageSellOrder
                        cheapestInvalidVintage = vin
                    }
                }),
            )
        }
        // do this in the case where there are no valid sell orders
        // so select the cheapest non-live sell order
        if (!newSellOrder && !sellOrder && cheapestInvalidSellOrder) {
            newSellOrder = cheapestInvalidSellOrder
            newVintage = cheapestInvalidVintage
        }
        if (!newSellOrder) return
        setParams(newSellOrder.id, true)

        setSellOrder(newSellOrder)
        setVintage(newVintage)
    }, [searchParams, sellOrders])

    const selectSellOrder = (sellOrderId: string) => {
        setParams(sellOrderId, true)
    }

    if (!project) return <></>
    return (
        <Layout
            title={project?.name!}
            subTitle=""
            selectedNavBarItem={NavBarItemName.MARKET}
            topLeftComponent={
                <Link className="link text-sm" to="/market">
                    {'<< Market'}
                </Link>
            }
        >
            <LoadingWrapper waitFor={fetchProject} errMsg={`Error loading the project ${id}`}>
                <div className="max-w-full mb-28">
                    <div className="flex flex-col xl:flex-row gap-4">
                        <div className="flex flex-col gap-4">
                            <div className="h-100 md:h-60 overflow-hidden">
                                <img
                                    className="rounded-t-2xl w-full"
                                    src={
                                        project?.banner_image_url
                                            ? project?.banner_image_url
                                            : getProjectTypeImgSrc(
                                                  project?.type,
                                                  ProjectTypeImgType.BANNER,
                                              )
                                    }
                                    id={project?.id}
                                    alt="Project banner"
                                    onError={() => {
                                        // if for some reason the banner image url is not available, use the default image
                                        if (!project?.banner_image_url) return
                                        const img = document.getElementById(
                                            project?.id,
                                        ) as HTMLImageElement
                                        img.src = getProjectTypeImgSrc(
                                            project?.type,
                                            ProjectTypeImgType.BANNER,
                                        )
                                    }}
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="w-full">
                                    <ProjectDetailsTable project={project} />
                                </div>
                            </div>
                            <div>
                                <h3 className="text-gray-900 font-semibold text-xl h-7.5 mb-2">
                                    Description
                                </h3>
                                <div
                                    className="text-gray-500 text-base font-normal whitespace-pre-line"
                                    style={{ wordBreak: 'break-word' }}
                                >
                                    {project.description}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 min-w-[384px]">
                            <PriceQuantityDetails
                                sellOrder={sellOrder}
                                vintages={vintages}
                                onSelect={selectSellOrder}
                            />
                            <Credits
                                sellOrder={sellOrder}
                                vintage={vintage}
                                vintages={vintages}
                                project={project}
                                onChangeSellOrder={selectSellOrder}
                                refreshProject={fetchProject}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 w-full mt-2">
                        <ProjectCarbonCreditBalance
                            vintages={project.vintages}
                            project={project}
                            reloadProject={fetchProject}
                        />
                        <RetirementsTable
                            project={project}
                            displayHeading={true}
                            hideNoResults={true}
                        />
                        <BuyOrdersTable
                            project={project}
                            displayHeading={true}
                            hideNoResults={true}
                        />
                        <SellOrderTable
                            project={project}
                            displayHeading={true}
                            hideNoResults={true}
                            onReload={fetchProject}
                        />
                    </div>
                </div>
            </LoadingWrapper>
        </Layout>
    )
}

export default ProjectPage
