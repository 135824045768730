import { Modal, ModalPositions } from 'flowbite-react'
import { useEffect, useState } from 'react'
import LoadingSpinner from '../../loadingSpinner/components/LoadingSpinner'
import Note, { NoteType } from '../../note/components/Note'
import AlignMiddle from '../../common/components/AlignMiddle'

interface Props {
    children: React.ReactNode
    error: string | Record<string, string[]> | string[] | null
    show: boolean
    onClose?: (success: boolean) => void
    id: string
    showSpinner: boolean
    position: keyof ModalPositions
    title?: string
    bottomComponent?: JSX.Element
}

const BaseModal = ({
    children,
    error,
    show,
    id,
    onClose = () => {},
    showSpinner,
    title = '',
    bottomComponent,
}: Props): JSX.Element => {
    const [displayError, setDisplayError] = useState<string | JSX.Element | JSX.Element[] | null>(
        null,
    )
    useEffect(() => {
        if (!error || typeof error === 'string') {
            setDisplayError(error)
            return
        }
        if (Array.isArray(error)) {
            setDisplayError(
                error.map((err, index) => <div key={`error_${id}_${index}`}>{err}</div>),
            )
            return
        }
        const newError = Object.entries(error).reduce((acc: JSX.Element[], [key, value]) => {
            if (value.length === 0) return acc
            acc.push(
                <div key={key}>
                    <strong>{key}: </strong>
                    {value.join(', ')}
                </div>,
            )
            return acc
        }, [] as JSX.Element[])
        if (newError.length === 0) {
            setDisplayError(null)
            return
        }
        setDisplayError(newError)
    }, [error])
    return (
        <Modal show={show} id={id} position="center" dismissible onClose={() => onClose(false)}>
            <div className="py-2 overflow-y-scroll scrollbar-hide relative">
                <AlignMiddle direction="row" childClassName="order-1">
                    <div
                        className="hover:bg-gray-100 cursor-pointer rounded-full mr-1"
                        onClick={() => onClose(false)}
                    >
                        <svg
                            className="align-middle mx-1 h-[24px] w-[24px] text-gray-700"
                            viewBox="0 0 24 24"
                            version="1.1"
                            aria-hidden="true"
                            fill={`currentColor`}
                        >
                            <path d="M13.21 12l4.54-4.54c.33-.33.33-.88 0-1.21a.863.863 0 00-1.21 0L12 10.79 7.46 6.25a.863.863 0 00-1.21 0c-.33.33-.33.88 0 1.21L10.79 12l-4.54 4.54c-.33.33-.33.88 0 1.21.33.33.88.33 1.21 0L12 13.21l4.54 4.54c.33.33.88.33 1.21 0 .33-.33.33-.88 0-1.21L13.21 12z"></path>
                        </svg>
                    </div>
                </AlignMiddle>
                {title && (
                    <>
                        <div className="mb-4 text-xl font-bold text-gray-700 w-[90%] h-full mx-auto">
                            {title}
                        </div>
                        <hr className="w-full mt-2 mb-4" />
                    </>
                )}
                <div className="w-[90%] h-full mx-auto text-sm">
                    {children}
                    {displayError && (
                        <div className="mb-2">
                            <Note type={NoteType.ERROR}>{displayError}</Note>
                        </div>
                    )}
                </div>
                {showSpinner && (
                    <div className="absolute top-0 left-0 h-full w-full bg-gray-200 opacity-70 rounded-lg">
                        <div className="relative w-full h-full">
                            <div className="absolute left-[calc(50%-20px)] top-[calc(50%-20px)] z-10">
                                <LoadingSpinner></LoadingSpinner>
                            </div>
                        </div>
                    </div>
                )}
                {bottomComponent && (
                    <>
                        <hr className="w-full my-4" />
                        <div className="w-[90%] h-full mx-auto mb-4">{bottomComponent}</div>
                    </>
                )}
            </div>
        </Modal>
    )
}

export default BaseModal
