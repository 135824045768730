import { User } from '../types/User'

export const SET_USER = 'SET_USER'

export type UserAction = SetUserAction

export interface SetUserActionPayload {
    user: User | null
}

export interface SetUserAction {
    type: typeof SET_USER
    payload: SetUserActionPayload
}
