import { Tooltip } from 'react-tooltip'
import { SDG } from '../enums/SDG'
import SdgLogo from './SdgLogo'

interface SdgProps {
    sdgs: SDG[]
    numberOfSdgsToShow?: number
    iconWrapperClassName?: string
    shouldShowWithName?: boolean
    id: string
}

const FullWithSdg = ({ sdg }: { sdg: SDG }): JSX.Element => {
    return (
        <div className="flex gap-2" title={sdg}>
            <SdgLogo sdg={sdg} className="w-5 h-5" />
            <p className="text-gray-500 text-base font-semibold">{sdg}</p>
        </div>
    )
}

export const SmallSdgIcon = ({
    sdg,
    iconWrapperClassName,
}: {
    sdg: SDG
    iconWrapperClassName?: string
}): JSX.Element => {
    return (
        <div title={sdg} className={`rounded-full overflow-hidden mr-0.5 ${iconWrapperClassName}`}>
            <SdgLogo sdg={sdg} className="w-6 h-6" />
        </div>
    )
}

const Sdgs = ({
    sdgs,
    numberOfSdgsToShow,
    iconWrapperClassName,
    shouldShowWithName = false,
    id,
}: SdgProps): JSX.Element => {
    if (sdgs.length === 0) return <></>
    if (shouldShowWithName) {
        return (
            <div className="flex w-full ml-10 gap-2">
                {sdgs.map((sdg) => {
                    return <FullWithSdg sdg={sdg} key={sdg} />
                })}
            </div>
        )
    }
    return (
        <div
            className={`flex ${numberOfSdgsToShow && numberOfSdgsToShow < sdgs.length ? 'flex-nowrap' : 'flex-wrap'}`}
        >
            {sdgs.slice(0, numberOfSdgsToShow ?? sdgs.length).map((sdg) => {
                return (
                    <SmallSdgIcon sdg={sdg} key={sdg} iconWrapperClassName={iconWrapperClassName} />
                )
            })}
            {numberOfSdgsToShow && numberOfSdgsToShow < sdgs.length && (
                <div
                    className={`rounded-full overflow-hidden mr-0.5 bg-white ${iconWrapperClassName}`}
                >
                    <div
                        className="bg-[#F9FBFA] w-6 h-6 text-center text-gray-600 text-xs leading-6 font-semibold"
                        id={`SDG-${id}`}
                    >
                        {sdgs.length - numberOfSdgsToShow}+
                    </div>
                    <Tooltip anchorSelect={`#SDG-${id}`} place="top" className="bg-gray-200">
                        <div className="flex">
                            {sdgs.map((sdg) => {
                                return <SmallSdgIcon sdg={sdg} key={sdg} />
                            })}
                        </div>
                    </Tooltip>
                </div>
            )}
        </div>
    )
}

export default Sdgs
