import { DateTime } from 'luxon'
import { AxiosError, AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Page } from '../../common/enums/Pages'
import { TAndCNotAcceptedError } from '../erros/TAndCNotAcceptedError'
import { ForbiddenExceptionTypes } from '../enums/ForbiddenExceptionTypes'
import { ErrorResponse } from '../types/ErrorResponse'
import { AuthenticationApi } from '../../auth/http/AuthenticationApi'
import { CookieKey } from '../../cookies/enums/CookieKey'
import { deleteCookie, setCookie, getCookie } from '../../cookies/useCookie'
import { state } from './state'

const processQueue = (error: AxiosError | null, token: string | null = null) => {
    state.failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error)
        } else {
            prom.resolve(token)
        }
    })

    state.failedQueue = []
}

const redirect = (location: string = Page.SIGNIN) => {
    if (!window.location.href.includes(location)) {
        window.location.href = location
    }
}

const deleteTokensCookie = () => {
    deleteCookie(CookieKey.REFRESH_TOKEN)
    deleteCookie(CookieKey.JWT_TOKEN)
}

const windowLocationIncludes = (paths: string[]): boolean => {
    return paths.some((path) => window.location.href.includes(path))
}

const refresh = async (
    refreshToken: string,
    axios: AxiosInstance,
    request: AxiosRequestConfig & { retry: boolean },
): Promise<void> => {
    const response = await AuthenticationApi.refreshToken(refreshToken)
    if (response?.data?.token) {
        setCookie(CookieKey.JWT_TOKEN, response?.data.token, {
            expires: DateTime.utc().plus({ minutes: 30 }).toJSDate().toUTCString(),
        })
        axios.defaults.headers.common['Authorization'] = `Bearer ${response?.data?.token}`
        request.headers!.Authorization = `Bearer ${response?.data?.token}`
        processQueue(null, response.data.token)
    }
}

const error401 = async (
    error: AxiosError,
    refreshToken: string,
    jwtToken: string,
    axios: AxiosInstance,
    request: AxiosRequestConfig & { retry: boolean },
): Promise<AxiosResponse> => {
    if (!refreshToken && !jwtToken) {
        redirect()
        processQueue(null, null)
        return Promise.reject(error)
    }

    if (request.retry) {
        return Promise.reject(error)
    }

    if (state.isRefreshing) {
        return new Promise<string>((resolve, reject) => {
            state.failedQueue.push({ resolve, reject })
        }).then((token: string) => {
            if (!token) {
                return Promise.reject(error)
            }
            request.headers!['Authorization'] = `Bearer ${token}`
            return axios.request(request)
        })
    }

    request.retry = true
    state.isRefreshing = true

    try {
        state.refresh = refresh(refreshToken, axios, request)
        await state.refresh

        return await axios.request(request)
    } catch (err: unknown) {
        processQueue(err as AxiosError, null)
        return Promise.reject(err)
    } finally {
        state.refresh = undefined
        state.isRefreshing = false
    }
}

const error403 = (error: AxiosError<ErrorResponse>): Promise<AxiosResponse> => {
    const thalloError: ForbiddenExceptionTypes | 'Unprocessable Entity' | null =
        error.response?.data?.error || null

    if (!thalloError || thalloError === 'Unprocessable Entity') return Promise.reject(error)

    // T&C not accepted
    if (
        thalloError === ForbiddenExceptionTypes.AGREEMENT_NOT_ACCEPTED &&
        !windowLocationIncludes([Page.TERMS_AND_CONDITIONS])
    ) {
        redirect(Page.TERMS_AND_CONDITIONS)
        return Promise.reject(new TAndCNotAcceptedError())
    } else if (thalloError === ForbiddenExceptionTypes.AGREEMENT_NOT_ACCEPTED) {
        return Promise.reject(new TAndCNotAcceptedError())
    }

    // If invalid 2FA code, pass through the error for retry attempt
    if (
        [
            ForbiddenExceptionTypes.NO_CODE_2FA,
            ForbiddenExceptionTypes.INVALID_2FA,
            ForbiddenExceptionTypes.EXPIRED_2FA,
            ForbiddenExceptionTypes.MFA_REQUIRED,
            ForbiddenExceptionTypes.INVALID_CREDENTIALS,
        ].includes(thalloError)
    ) {
        return Promise.reject(error)
    }

    deleteTokensCookie()
    redirect()
    return Promise.reject(error)
}

const errorInterceptor =
    (axiosInstance: AxiosInstance) =>
    async (error: AxiosError): Promise<AxiosResponse> => {
        const originalRequest: AxiosRequestConfig & { retry: boolean } = {
            ...error.config,
            retry: false,
        }
        const refreshToken = getCookie(CookieKey.REFRESH_TOKEN)
        const jwtToken = getCookie(CookieKey.JWT_TOKEN)

        if (!error.isAxiosError) return Promise.reject(error)

        switch (error.response?.status) {
            case 401:
                return await error401(error, refreshToken, jwtToken, axiosInstance, originalRequest)
            case 403:
                return await error403(error as AxiosError<ErrorResponse>)
            default:
                return Promise.reject(error)
        }
    }

export default errorInterceptor
