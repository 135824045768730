import { AxiosResponse } from 'axios'
import HttpService from '../../../../modules/http/httpService'
import { SearchTenantsResponse } from './types/SearchTenantsResponse'
import { SearchTenantsParams } from './types/SearchTenantsParams'
import { Tenant } from '../../../../modules/tenant/types/Tenant'
import { UpdateTenantPayload } from './types/UpdateTenantPayload'

export interface AdminTenantApiInterface {
    search(params: SearchTenantsParams): Promise<AxiosResponse<SearchTenantsResponse> | undefined>
    getEntityTenants(): Promise<AxiosResponse<Tenant[]> | undefined>
    patch(tenantId: string, payload: UpdateTenantPayload): Promise<AxiosResponse<void> | undefined>
    getTenantById(id: string): Promise<AxiosResponse<any> | undefined>
}

export const AdminTenantApi: AdminTenantApiInterface = Object.freeze({
    search(params: SearchTenantsParams): Promise<AxiosResponse<SearchTenantsResponse> | undefined> {
        return HttpService.get('/admin/tenants', null, params)
    },
    getEntityTenants(): Promise<AxiosResponse<Tenant[]> | undefined> {
        return HttpService.get('/admin/tenants/entity')
    },
    patch(
        tenantId: string,
        payload: UpdateTenantPayload,
    ): Promise<AxiosResponse<void> | undefined> {
        return HttpService.patch(`/admin/tenants/${tenantId}`, payload)
    },
    getTenantById(id: string): Promise<AxiosResponse<any> | undefined> {
        return HttpService.get('/admin/tenants/get/' + id)
    },
})
