import { BackedBySource } from '../enums/BackedBySource'

interface Props {
    backedBySource: BackedBySource
}

const Tokenised = ({ backedBySource }: Props): JSX.Element => {
    return backedBySource === BackedBySource.THALLO_TOKENISED_CREDITS ? (
        <div className="h-6 w-6">
            <img src="/assets/icon_on_chain_grey.png" alt="Tokenized" />
        </div>
    ) : (
        <></>
    )
}

export default Tokenised
