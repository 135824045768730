import { Icon } from '@phosphor-icons/react'
import { PlacesType, Tooltip } from 'react-tooltip'

interface Props {
    id: string
    IconElement: Icon
    tooltip: string
    onClick?: () => void
    size?: number
    place?: PlacesType
    highlight?: boolean
    children?: React.ReactNode
    dataSelector?: string
}

const Action = ({
    id,
    IconElement,
    tooltip,
    onClick = () => {},
    size = 20,
    place = 'top',
    highlight = true,
    children,
    dataSelector,
}: Props): JSX.Element => {
    return (
        <div data-icon-selector={dataSelector}>
            <div
                id={id}
                className={`hover:cursor-pointer ${highlight ? 'group-hover:text-orange-500' : ''}`}
            >
                <IconElement size={size} onClick={onClick} />
                {children}
            </div>
            <Tooltip anchorSelect={`#${id}`} place={place} className="bg-gray-200">
                <div className="">{tooltip}</div>
            </Tooltip>
        </div>
    )
}

export default Action
