export enum ProjectType {
    AFFORESTATION_REFORESTATION_AND_RESTORATION = 'Afforestation, Reforestation & Restoration',
    AGRICULTURAL_METHANE_RECOVERY = 'Agricultural Methane Recovery',
    AVOIDED_DEFORESTATION = 'Avoided Deforestation',
    BIOCHAR = 'Biochar',
    BIOENERGY_CARBON_CAPTURE_AND_STORAGE = 'Bioenergy Carbon Capture & Storage',
    BIOGAS = 'Biogas',
    BIOMASS = 'Biomass',
    BOREHOLES = 'Boreholes',
    BRICK_MANUFACTURING = 'Brick Manufacturing',
    CAROBNATED_BUILDING_MATERIAL = 'Carbonated Building Materials',
    CEMENT_AND_CONCRETE = 'Cement & Concrete',
    COOKSTOVES = 'Cookstoves',
    DIRECT_AIR_CAPTURE = 'Direct Air Capture',
    DOMESTIC_ENERGY_EFFICIENCY = 'Domestic Energy Efficiency',
    DOMESTIC_FUEL_EFFICIENCY_BIODIGESTERS = 'Domestic Fuel Efficiency - Biodigesters',
    DOMESTIC_FUEL_EFFICIENCY_LIGHTING = 'Domestic Fuel Efficiency - Lighting',
    ENERGY_EFFICIENCY = 'Energy Efficiency',
    ENHANCED_OIL_RECOVERY = 'Enhanced Oil Recovery',
    ENHANCED_WEATHERING = 'Enhanced Weathering',
    FEED_ADDITIVES = 'Feed Additives',
    FUEL_SWITCH = 'Fuel Switch',
    FUGITIVE_EMISSIONS = 'Fugitive Emissions',
    GEOLOGICALLY_STORED = 'Geologically Stored',
    GEOTHERMAL = 'Geothermal',
    GRASSLANDS = 'Grasslands',
    HYDRO = 'Hydro',
    IMPROVED_FOREST_MANAGEMENT = 'Improved Forest Management',
    INDUSTRIAL_ENERGY_EFFICIENCY = 'Industrial Energy Efficiency',
    IRRIGATION = 'Irrigation',
    LANDFILL_GAS = 'Landfill Gas',
    MANGROVES = 'Mangroves',
    METAL_PRODUCTION = 'Metal production',
    METHANE_CAPTURE = 'Methane Capture',
    N2O_FROM_ADIPIC_ACID = 'N2O from Adipic Acid',
    N2O_FROM_NITRIC_ACID = 'N2O from Nitric Acid',
    NON_OIL_RECYCLING = 'Non-Oil Recycling',
    OIL_RECYCLING = 'Oil Recycling',
    OTHER_TRANSPORT = 'Other transport',
    OZONE_DEPLETING_SUBSTANCES = 'Ozone Depleting Substances',
    PEATLANDS = 'Peatlands',
    REDD_PLUS = 'REDD+',
    REGENERATIVE_AGRICULTURE = 'Regenerative Agriculture',
    RENEWABLES = 'Renewables',
    SEAGRASS_AND_SEAWEEDS = 'Seagrass & Seaweeds',
    SOIL_AMENDMENT_BASED_ON_CARBON_STORAGE_METHODOLOGY = 'Soil Amendment based on Carbon Storage Methodology',
    SOLAR = 'Solar',
    SULPHUR_EMISSIONS = 'Sulphur Emissions',
    SYNTHETIC_FUELS = 'Synthetic Fuels',
    WASTE_HEAT_RECOVERY = 'Waste Heat Recovery',
    WATER = 'Water',
    WATER_FILTERS = 'Water Filters',
    WETLAND_RESTORATION = 'Wetland Restoration',
    WIND = 'Wind',
    WOODEN_BUILDING_MATERIALS = 'Wooden Building Materials',
    WOODY_BIOMASS_BURIAL = 'Woody Biomass Burial',
}
