const LeftSection = (): JSX.Element => {
    return (
        <div
            className="min-h-screen flex flex-col justify-between"
            style={{
                backgroundImage:
                    'url(pattern_dark.png), linear-gradient(211.04deg, rgba(62, 61, 61, 0.85) 0%, #3E3D3D 98.88%)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center right, bottom left',
                backgroundSize: 'cover',
            }}
        >
            <div className="pl-7 pt-7">
                <img src="/assets/icon_thallo.png" alt="icon_thallo" />
            </div>
            <div>
                <div>
                    <img
                        className="block my-0 mx-auto"
                        src="/assets/login_splash.png"
                        alt="login_splash"
                    />
                    <p className="text-white mt-9 mx-16 text-3xl text-center font-medium">
                        <span>Your single access point to the carbon market</span>
                    </p>
                </div>
            </div>
            <div className="pb-8 pl-8 text-sm text-gray-25">
                © Thallo Limited {new Date().getFullYear()}
            </div>
        </div>
    )
}

export default LeftSection
