import { useUserStore } from '../../user/context/store'

const UserAvatar = (): JSX.Element => {
    const { user } = useUserStore()

    return (
        <div className="w-10 h-10 rounded-3xl bg-primary-500 flex justify-center items-center">
            <p className="text-white text-2xl">
                {user?.first_name?.charAt(0)}
                {user?.last_name?.charAt(0)}
            </p>
        </div>
    )
}

export default UserAvatar
