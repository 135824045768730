import { RegistryApi } from '../http/api'
import { Registry } from '../types/Registry'
import { setRegistriesAction } from './actions'
import { initialRegistrytSoreState, registryReducer } from './reducer'
import { createContext, useContext, useReducer } from 'react'

export const RegistryStore = createContext(initialRegistrytSoreState)

export const useRegistryStore = () => useContext(RegistryStore)

export const RegistryStoreProvider = ({ children }: { children: JSX.Element }): any => {
    const [state, dispatch] = useReducer(registryReducer, initialRegistrytSoreState)

    const setRegistries = (registries: Registry[]) => {
        setRegistriesAction(registries, dispatch)
    }

    const fetchRegistries = async () => {
        const response = await RegistryApi.getAll()
        setRegistries(response.data)
    }

    return (
        <RegistryStore.Provider
            value={{
                registries: state.registries,
                setRegistries,
                fetchRegistries,
            }}
        >
            {children}
        </RegistryStore.Provider>
    )
}
