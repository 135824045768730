import { useEffect, useState } from 'react'
import { PrimaryButton } from '../../modules/common/components/Button'
import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import LoadingSpinner from '../../modules/loadingSpinner/components/LoadingSpinner'
import Note, { NoteType } from '../../modules/note/components/Note'
import OrdersSubMenu from './components/OrdersSubMenu'
import PayoutStat from './components/PayoutStat'
import { OrderSubMenuActions } from './enums/OrderSubMenuActions'
import Table, { Column, TextAlignment, Values } from '../../modules/table/components/Table'
import { PayoutApi } from '../../modules/payout/http/PayoutApi'
import ID from '../../modules/common/components/ID'
import PayoutStatusPill from '../../modules/payout/components/PayoutStatusPill'
import DateTimeFormatted from '../../modules/dateTime/components/DateTimeFormatted'
import { convertCentsToLocaleUsd } from '../../utils/conversion'
import * as Sentry from '@sentry/react'
import { PayoutStats } from '../../modules/payout/http/types/GetPayoutsWithStatsResponse'
import CreatePayoutModal from '../../modules/payout/components/CreatePayoutModal'

type Columns = 'id' | 'status' | 'created_at' | 'total'

const Payouts = (): JSX.Element => {
    const [payoutTableData, setPayoutTableData] = useState<Values<Columns>[]>([])
    const [payoutStats, setPayoutStats] = useState<PayoutStats>()
    const [showCreatePayoutModal, setShowCreatePayoutModal] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string | null>(null)

    const getPayouts = async (): Promise<void> => {
        try {
            setErrMsg(null)
            setIsLoading(true)
            const payoutInfo = (await PayoutApi.getPayoutsWithStats()).data
            setPayoutStats(payoutInfo.stats)
            setPayoutTableData(
                payoutInfo.payouts.map((payout) => {
                    return {
                        id: {
                            value: <ID link={`/payout/${payout.id}`}>{payout.id}</ID>,
                        },
                        status: {
                            value: <PayoutStatusPill status={payout.status} />,
                        },
                        created_at: {
                            value: <DateTimeFormatted date={new Date(payout.created_at)} />,
                        },
                        total: {
                            value: (
                                <div className="font-bold">
                                    {convertCentsToLocaleUsd(payout.total)}
                                </div>
                            ),
                            textAlignment: TextAlignment.RIGHT,
                        },
                    }
                }),
            )
            setIsLoading(false)
        } catch (err) {
            setErrMsg(
                'There was a problem loading your payouts. Please refresh the page to try again.',
            )
            setIsLoading(false)
            Sentry.captureException(err)
        }
    }

    useEffect(() => {
        getPayouts()
    }, [])

    const columns: Column<Columns>[] = [
        {
            name: 'id',
            display: 'Payout',
        },
        {
            name: 'status',
            display: 'Status',
        },
        {
            name: 'created_at',
            display: 'Created',
        },
        {
            name: 'total',
            display: 'Total',
            textAlignment: TextAlignment.RIGHT,
        },
    ]

    return (
        <Layout
            title=""
            subTitle=""
            dataCy="payouts-layout"
            selectedNavBarItem={NavBarItemName.ORDERS}
        >
            <OrdersSubMenu selectedItem={OrderSubMenuActions.PAYOUTS} />
            <>
                {isLoading && <LoadingSpinner></LoadingSpinner>}
                {errMsg && <Note type={NoteType.ERROR}>{errMsg}</Note>}
                {payoutStats && !isLoading && !errMsg && (
                    <>
                        <div className="flex flex-row flex-wrap gap-4">
                            <PayoutStat
                                title="Sold (Pending Buyer Payment)"
                                value={convertCentsToLocaleUsd(payoutStats.pending_buyer_payment)}
                            />
                            <PayoutStat
                                title="Sold"
                                value={convertCentsToLocaleUsd(payoutStats.sold)}
                            >
                                <PrimaryButton
                                    className="w-28 mt-2"
                                    disabled={Number(payoutStats.sold) <= 0}
                                    onClick={() => {
                                        setShowCreatePayoutModal(true)
                                    }}
                                >
                                    Withdraw
                                </PrimaryButton>
                            </PayoutStat>
                            <PayoutStat
                                title="Payout In Progress"
                                value={convertCentsToLocaleUsd(payoutStats.payout_in_progress)}
                            />
                            <PayoutStat
                                title="Paid Out"
                                value={convertCentsToLocaleUsd(payoutStats.paid_out)}
                            />
                        </div>
                        <CreatePayoutModal
                            amountToWithdraw={payoutStats.sold}
                            show={showCreatePayoutModal}
                            onClose={(success) => {
                                setShowCreatePayoutModal(false)
                                if (success) {
                                    getPayouts()
                                }
                            }}
                        />
                    </>
                )}
                {payoutStats && payoutTableData.length > 0 && !isLoading && !errMsg && (
                    <Table columns={columns} data={payoutTableData} className="mt-6" />
                )}
                {payoutStats && payoutTableData.length === 0 && !isLoading && !errMsg && (
                    <Note type={NoteType.INFO}>No payouts found</Note>
                )}
            </>
        </Layout>
    )
}

export default Payouts
