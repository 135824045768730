import { SearchSellOrderFilters } from './types/SearchSellOrderFilters'

export const defaultFilters: SearchSellOrderFilters = {
    projectName: '',
    projectType: [],
    price: {
        min: 0,
        max: 0,
    },
    locations: [],
    sdgs: [],
    vintages: [],
    sortByColumnName: null,
    sortByOrder: null,
}
