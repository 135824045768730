import { useCallback, useEffect, useState } from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useParams } from 'react-router-dom'
import { UserApi } from '../../modules/user/http/UserApi'
import { GoogleRecaptchaActionType } from '../signin/enums/GoogleRecaptchaActionType'
import SignInSignUpLayout from '../signup/components/SignInSignUpLayout'
import { IconName } from '../signup/enums/IconName'
import SetNewPasswordForm from './components/SetNewPasswordForm'

const NewPassword = (): JSX.Element => {
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
    const [grecaptchaToken, setGrecaptchaToken] = useState<string>('')
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const { hash } = useParams<{ hash: string }>()

    const verifyHash = async (): Promise<void> => {
        setIsButtonDisabled(true)
        try {
            const response = await UserApi.validateForgotPasswordHash(hash ?? '')
            if (response && !response.data.is_valid) {
                setError(
                    "This reset link has expired or it's invalid, please use the forgot password link on the login page to send a new one.",
                )
            }
        } catch (e) {
            setError('An unexpected error has occurred, please try again')
            throw e
        } finally {
            setIsButtonDisabled(false)
        }
    }

    useEffect(() => {
        void verifyHash()
    }, [hash])

    const onVerify = useCallback((token: string) => {
        setGrecaptchaToken(token)
    }, [])

    return (
        <SignInSignUpLayout
            title="Set new password?"
            subtitle="Your new password must be different to previously used passwords."
            iconName={IconName.KEY}
        >
            <>
                <GoogleReCaptcha
                    action={GoogleRecaptchaActionType.SET_NEW_PASSWORD}
                    onVerify={onVerify}
                    refreshReCaptcha={refreshReCaptcha}
                />
                <SetNewPasswordForm
                    error={error}
                    isButtonDisabled={isButtonDisabled}
                    grecaptchaToken={grecaptchaToken}
                    hash={hash}
                    refreshReCaptcha={refreshReCaptcha}
                    setError={setError}
                    setIsButtonDisabled={setIsButtonDisabled}
                    setRefreshReCaptcha={setRefreshReCaptcha}
                />
            </>
        </SignInSignUpLayout>
    )
}

export default NewPassword
