import { AxiosResponse } from 'axios'
import HttpService from '../../../../modules/http/httpService'
import { EmptyResponse } from '../../../../modules/http/types/EmptyResponse'
import { SearchFeatureFlagsResponse } from '../../../../modules/featureFlags/http/types/SearchFeatureFlagsResponse'
import { SearchFeatureFlagsParams } from '../../../../modules/featureFlags/http/types/SearchFeatureFlagsParams'
import { FeatureFlag } from '../../../../modules/featureFlags/types/FeatureFlag'
import { PatchFeatureFlagParam } from '../../../../modules/featureFlags/http/types/PatchFeatureFlagParam'

export interface AdminFeatureFlagInterface {
    adminSearch(
        params: SearchFeatureFlagsParams,
    ): Promise<AxiosResponse<SearchFeatureFlagsResponse> | undefined>
    setIsFeatureFlagEnabled(
        id: string,
        isEnabled: boolean,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
    getById(id: string): Promise<AxiosResponse<FeatureFlag> | undefined>
    patch(
        id: string,
        payload: PatchFeatureFlagParam,
    ): Promise<AxiosResponse<EmptyResponse> | undefined>
    isBridgeIntegrationEnabled(): Promise<AxiosResponse<boolean>>
}

export const AdminFeatureFlagApi: AdminFeatureFlagInterface = Object.freeze({
    adminSearch(
        params: SearchFeatureFlagsParams,
    ): Promise<AxiosResponse<SearchFeatureFlagsResponse> | undefined> {
        return HttpService.get('/admin/feature-flags', null, params)
    },

    setIsFeatureFlagEnabled(
        id: string,
        isEnabled: boolean,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.patch(`/admin/feature-flags/${id}`, {
            enabled_system_wide: isEnabled,
        })
    },

    getById(id: string): Promise<AxiosResponse<FeatureFlag> | undefined> {
        return HttpService.get('/admin/feature-flags', id)
    },

    patch(
        id: string,
        payload: PatchFeatureFlagParam,
    ): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.patch(`/admin/feature-flags/${id}`, payload)
    },

    isBridgeIntegrationEnabled(): Promise<AxiosResponse<boolean>> {
        return HttpService.get(`/admin/feature-flags/bridge-integration-enabled`)
    },
})
