export enum RegistryName {
    GLOBAL_CARBON_COUNCIL = 'Global Carbon Council',
    GOLD_STANDARD = 'Gold Standard',
    VERRA = 'VERRA',
    AMERICAN_CARBON_REGISTRY = 'American Carbon Registry',
    CLIMATE_ACTION_RESERVE = 'Climate Action Reserve',
    BIOCARBON_REGISTRY = 'BioCarbon Registry',
    CERCARBONO_ECOREGISTRY = 'Cercarbono / EcoRegistry',
    PLAN_VIVO = 'Plan Vivo',
    UNFCCC_CLEAN_DEVELOPMENT_MECHANISM = 'UNFCCC Clean Development Mechanism',
    PURO = 'Puro.earth',
    CENTIGRADE = 'Centigrade',
    SOCIAL_CARBON = 'Social Carbon',
    INTERNATION_CARBON_REGISTRY = 'International Carbon Registry',
}
