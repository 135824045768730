import Layout from '../../modules/common/components/Layout'
import { NavBarItemName } from '../../modules/header/components/NavBar'
import { Link, useParams } from 'react-router-dom'
import RecordField from '../../modules/recordDetails/components/RecordField'
import { Fragment, useEffect, useState } from 'react'
import LoadingSpinner from '../../modules/loadingSpinner/components/LoadingSpinner'
import Note, { NoteType } from '../../modules/note/components/Note'
import * as Sentry from '@sentry/react'
import { RetirementRequestDto } from '../../modules/retirement/http/types/RetirementRequestDto'
import { RetirementRequestsApi } from '../../modules/retirement/http/api'
import RetirementStatusPill from '../../modules/retirement/components/RetirementStatusPill'
import DateTimeFormatted from '../../modules/dateTime/components/DateTimeFormatted'
import { TradeHistoryType } from '../../modules/tradeHistory/enums/TradeHistoryType'
import ID from '../../modules/common/components/ID'
import ProjectName from '../../modules/project/components/ProjectName'
import RegistryIcon from '../../modules/registry/components/RegistryIcon'
import { convertGramsToMetricTonesLocaleString } from '../../utils/conversion'
import { BuyOrderStatus } from '../../modules/buyOrders/http/enums/BuyOrderStatus'
import RetireeName from '../../modules/retirement/components/RetireeName'
import SubSection from '../../modules/common/components/SubSection'
import DownloadableImage from '../../modules/common/components/DownloadableImage'
import { RetirementStatuses } from '../../modules/retirement/enums/RetirementStatuses'
import Table, { Column, Values } from '../../modules/table/components/Table'
import RecordDetailActions from '../../modules/recordDetails/components/RecordDetailActions'
import { SecondaryButton } from '../../modules/common/components/Button'

type RegistrySerialColumns = 'serial' | 'certificate_url'

const Retirement = (): JSX.Element => {
    const [retirement, setRetirement] = useState<RetirementRequestDto>()
    const [buyOrderId, setBuyOrderId] = useState<string>()
    const [invoiceId, setInvoiceId] = useState<string>()
    const [isAttachedToBuyOrder, setIsAttachedToBuyOrder] = useState<boolean>()
    const [buyOrderStatus, setBuyOrderStatus] = useState<BuyOrderStatus>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string | null>(null)
    const [registrySerialData, setRegistrySerialData] = useState<Values<RegistrySerialColumns>[]>(
        [],
    )
    const { id } = useParams()

    const registrySerialColumns: Column<RegistrySerialColumns>[] = [
        {
            name: 'serial',
            display: 'Registry Serial Number',
        },
        // {
        //     name: 'certificate_url',
        //     display: 'Registry Certificate',
        // },
    ]

    const getRecord = async (): Promise<void> => {
        try {
            setErrMsg(null)
            setIsLoading(true)
            const retirement = (await RetirementRequestsApi.getById(id!)).data
            const isAttachedToBuyOrder = retirement.trades.every((trade) => {
                return trade.type !== TradeHistoryType.HOLD
            })
            setIsAttachedToBuyOrder(isAttachedToBuyOrder)
            if (isAttachedToBuyOrder && retirement.trades.length === 1) {
                setBuyOrderStatus(retirement.trades[0].buy_order_status)
                setBuyOrderId(retirement.trades[0].buy_order_id)
                setInvoiceId(retirement.trades[0].invoice_id)
            }
            setRegistrySerialData(
                retirement.serials.map((serial): Values<RegistrySerialColumns> => {
                    return {
                        serial: {
                            value: serial,
                        },
                        certificate_url: {
                            value: 'TODO',
                        },
                    }
                }),
            )
            setRetirement(retirement)
            setIsLoading(false)
        } catch (err) {
            setErrMsg(
                'There was a problem loading your retirement. Please refresh the page to try again.',
            )
            setIsLoading(false)
            Sentry.captureException(err)
        }
    }

    useEffect(() => {
        getRecord()
    }, [id])

    return (
        <Layout
            title="Retirement"
            subTitle={retirement ? retirement.id : ''}
            dataCy="retirement-layout"
            selectedNavBarItem={NavBarItemName.RETIREMENTS}
            topRightComponent={
                retirement && (
                    <RecordDetailActions>
                        {retirement.status === RetirementStatuses.COMPLETE && (
                            <>
                                <SecondaryButton clickFirstChild={true}>
                                    <Link to={retirement.proof_of_retirement_nft_url}>
                                        Download
                                    </Link>
                                </SecondaryButton>
                            </>
                        )}
                    </RecordDetailActions>
                )
            }
            topLeftComponent={
                <Link className="link text-sm" to="/retirements">
                    {'<< All Retirements'}
                </Link>
            }
        >
            <>
                {isLoading && <LoadingSpinner></LoadingSpinner>}
                {errMsg && <Note type={NoteType.ERROR}>{errMsg}</Note>}
                {retirement && !isLoading && !errMsg && (
                    <Fragment>
                        {isAttachedToBuyOrder &&
                            Number(retirement.quantity) >= 1_000_000 && // if the retirement is fractionalised and hasn't yet reached 1 tonne we cannot generate the ret cert, so don't show this message
                            (buyOrderStatus === BuyOrderStatus.OPEN ||
                                buyOrderStatus === BuyOrderStatus.PENDING_SETTLEMENT ||
                                buyOrderStatus === BuyOrderStatus.CLOSED) && (
                                <div className="mb-8">
                                    <Note type={NoteType.INFO}>
                                        Please pay invoice{' '}
                                        <ID link={`/invoice/${invoiceId}`}>{invoiceId}</ID> to
                                        recieve the retirement certificate
                                    </Note>
                                </div>
                            )}
                        <div className="grid grid-rows-4 md:grid-cols-4 md:grid-rows-1 gap-4">
                            <RecordField label="Status">
                                <RetirementStatusPill status={retirement.status} />
                            </RecordField>
                            <RecordField label="Month">
                                <DateTimeFormatted
                                    date={new Date(retirement.month)}
                                    monthAndYearOnly={true}
                                />
                            </RecordField>
                            <RecordField label="Retired At">
                                {retirement.retired_at ? (
                                    <DateTimeFormatted date={new Date(retirement.retired_at)} />
                                ) : (
                                    '-'
                                )}
                            </RecordField>
                            <RecordField label="Buy Order">
                                {buyOrderId ? (
                                    <ID link={`/buy-order/${buyOrderId}`}>{buyOrderId}</ID>
                                ) : (
                                    '-'
                                )}
                            </RecordField>
                            <RecordField label="Project">
                                <ProjectName
                                    squareImageUrl={retirement.project_data.square_image_url}
                                    type={retirement.project_data.type}
                                    name={retirement.project_data.name}
                                    id={retirement.project_data.id}
                                    vintage={retirement.vintage}
                                />
                            </RecordField>
                            <RecordField label="Vintage">{retirement.vintage}</RecordField>
                            <RecordField label="Registry">
                                <RegistryIcon registryId={retirement.project_data.registry_id} />
                            </RecordField>
                            <RecordField label="Quantity">
                                {convertGramsToMetricTonesLocaleString(Number(retirement.quantity))}
                            </RecordField>
                            <RecordField label="Retiree">
                                <RetireeName retirees={retirement.retirees} />
                            </RecordField>
                        </div>
                        {retirement.status === RetirementStatuses.COMPLETE && (
                            <>
                                <SubSection title="Certificate">
                                    {/* PUT THIS BACK IN WHEN WE HAVE THE BIGGER "MAIN" CERT <RecordField label="Mini Certificate"> */}
                                    <DownloadableImage
                                        url={retirement.proof_of_retirement_nft_url}
                                        heightClassName="h-[351px]"
                                        widthClassName="w-[544px]"
                                        overlayClassNames="rounded-3xl"
                                    />
                                    {/* </RecordField> */}
                                </SubSection>
                                <SubSection title="Registry Serial Numbers">
                                    <Table
                                        columns={registrySerialColumns}
                                        data={registrySerialData}
                                    />
                                </SubSection>
                            </>
                        )}
                        {!retirement && !isLoading && !errMsg && (
                            <Note type={NoteType.INFO}>No retirement found</Note>
                        )}
                    </Fragment>
                )}
            </>
        </Layout>
    )
}

export default Retirement
