import { useEffect, useState } from 'react'
import { ModerateDangerButton, PrimaryButton } from '../../../modules/common/components/Button'
import { useSellOrdersStore } from '../../../modules/sellOrders/context/store'
import PriceFilterForm from './forms/PriceFilterForm'

const MarketfiltersPriceBox = (): JSX.Element => {
    const {
        searchSellOrderFilters,
        searchSellOrdersResponse,
        setSearchSellOrderFilters,
        setShouldShowPriceFilters,
    } = useSellOrdersStore()
    const [minPrice, setMinPrice] = useState<string>('')
    const [maxPrice, setMaxPrice] = useState<string>('')

    useEffect(() => {
        // devide by 100 to get dollars
        const isPriceFilterApplied =
            searchSellOrderFilters.price.min > 0 || searchSellOrderFilters.price.max > 0
        setMinPrice(
            isPriceFilterApplied
                ? String(searchSellOrderFilters.price.min)
                : String((searchSellOrdersResponse?.market_minimum ?? 0) / 100),
        )
        setMaxPrice(
            isPriceFilterApplied
                ? String(searchSellOrderFilters.price.max)
                : String((searchSellOrdersResponse?.market_maximum ?? 0) / 100),
        )
    }, [])

    const onPriceConfirmButtonClickedHandler = (): void => {
        setSearchSellOrderFilters({
            ...searchSellOrderFilters,
            price: {
                min: Number(minPrice),
                max: Number(maxPrice),
            },
        })
        setShouldShowPriceFilters(false)
    }

    const onMinPriceChangeHandler = (value: string): void => {
        setMinPrice(value)
    }

    const onMaxPriceChangeHandler = (value: string): void => {
        setMaxPrice(value)
    }

    return (
        <div
            className="absolute bg-white top-20 px-7.5 pt-7.5 pb-6 rounded-lg z-10 w-full lg:w-auto"
            style={{
                boxShadow:
                    '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
            }}
        >
            <PriceFilterForm
                minPrice={minPrice}
                maxPrice={maxPrice}
                marketMinimum={searchSellOrdersResponse?.market_minimum ?? 0}
                marketMaximum={searchSellOrdersResponse?.market_maximum ?? 0}
                onMinPriceChange={onMinPriceChangeHandler}
                onMaxPriceChange={onMaxPriceChangeHandler}
            />
            <div className="flex justify-between mt-[33px]">
                <ModerateDangerButton
                    className="text-sm"
                    onClick={() => {
                        setSearchSellOrderFilters({
                            ...searchSellOrderFilters,
                            price: {
                                min: 0,
                                max: 0,
                            },
                        })
                        setShouldShowPriceFilters(false)
                    }}
                >
                    Clear
                </ModerateDangerButton>
                <PrimaryButton className="text-sm" onClick={onPriceConfirmButtonClickedHandler}>
                    Confirm
                </PrimaryButton>
            </div>
        </div>
    )
}
export default MarketfiltersPriceBox
