import { SortAscending, SortDescending } from '@phosphor-icons/react'
import { SortOrder } from '../../sellOrders/http/types/SearchSellOrdersParams'

interface Props {
    order: SortOrder | null
}

const Order = ({ order }: Props): JSX.Element => {
    switch (order) {
        case SortOrder.ASC:
            return <SortAscending size={16} />
        case SortOrder.DESC:
            return <SortDescending size={16} />
        default:
            return <></>
    }
}

export default Order
