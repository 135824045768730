import { useEffect, useState } from 'react'
import Table, { Column, TextAlignment, Values } from '../../table/components/Table'
import { convertCentsToLocaleUsd } from '../../../utils/conversion'
import { InvoiceApi } from '../http/InvoiceApi'
import InvoiceStatusPill from './InvoiceStatusPill'
import DateTimeFormatted from '../../dateTime/components/DateTimeFormatted'
import { InvoiceStatus } from '../enums/InvoiceStatus'
import PayInvoiceModal from './PayInvoiceModal'
import LoadingSpinner from '../../loadingSpinner/components/LoadingSpinner'
import { InvoiceDto } from '../http/types/InvoiceDto'
import Note, { NoteType } from '../../note/components/Note'
import * as Sentry from '@sentry/react'
import { ToastMessageType } from '../../toastMessage/enums/ToastMessageType'
import DownloadFromUrl from '../../uploadDownload/components/DownloadFromUrl'
import { useToastMessageStore } from '../../toastMessage/context/store'
import ID from '../../common/components/ID'
import Action from '../../common/components/Action'
import { DownloadSimple, Invoice } from '@phosphor-icons/react'

type Columns = 'id' | 'status' | 'month' | 'month_index' | 'item_count' | 'total' | 'action'

interface Props {
    hideNoResults?: boolean
}

const InvoicesTable = ({ hideNoResults = false }: Props): JSX.Element => {
    const [invoiceData, setInvoiceData] = useState<Values<Columns>[]>([])
    const [showPayModal, setShowPayModal] = useState<boolean>(false)
    const [selectedInvoice, setSelectedInvoice] = useState<InvoiceDto | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string | null>(null)
    // download PDF stuff - start
    const [signedUrl, setSignedUrl] = useState<string>('')
    const [filename, setFilename] = useState<string>('')
    const [triggerDownload, setTriggerDownload] = useState<boolean>(false)
    // download PDF stuff - end
    const { setShowToastMessage } = useToastMessageStore()

    const handleInvoiceDownload = async (invoiceId: string): Promise<void> => {
        try {
            const signedUrlData = (await InvoiceApi.generateInvoiceDownloadSignedUrl(invoiceId))
                ?.data
            if (!signedUrlData) {
                throw new Error(`signedUrlData is falsey. Invoice id: ${invoiceId}`)
            }
            setTriggerDownload(false)
            setSignedUrl(signedUrlData.url)
            setFilename(signedUrlData.filename)
            setTriggerDownload(true)
        } catch (err) {
            setShowToastMessage({
                text: 'There was a problem downloading your invoice. Please try again later.',
                type: ToastMessageType.ERROR,
            })
            Sentry.captureException(err)
        }
    }

    const getInvoices = async (): Promise<void> => {
        try {
            setErrMsg(null)
            setIsLoading(true)
            const invoices = (await InvoiceApi.search()).data.invoices
            setInvoiceData(
                invoices.map((invoice) => {
                    let itemCount = invoice.invoice_line_items.length
                    let total = invoice.total
                    // if the invoice is NOT cancelled we only want to count line items that have not been cancelled
                    //  if the whole invoice is cancelled then all of the ilis will be cancelled
                    //  rather then show zero we should show the count of cancelled items
                    if (invoice.status === InvoiceStatus.CANCELLED) {
                        itemCount = invoice.cancelled_line_items.length
                        total = invoice.cancelled_total
                    }
                    return {
                        id: {
                            value: <ID link={`/invoice/${invoice.id}`}>{invoice.id}</ID>,
                        },
                        status: {
                            value: <InvoiceStatusPill status={invoice.status} />,
                        },
                        month: {
                            value: (
                                <DateTimeFormatted
                                    date={new Date(invoice.month)}
                                    monthAndYearOnly={true}
                                />
                            ),
                        },
                        month_index: {
                            value: `#${invoice.month_index}`,
                        },
                        item_count: {
                            value: (
                                <ID link={`/invoice/${invoice.id}`}>
                                    {`${itemCount} item${itemCount > 1 ? 's' : ''}`}
                                </ID>
                            ),
                        },
                        total: {
                            value: (
                                <div className="font-bold">{convertCentsToLocaleUsd(total)}</div>
                            ),
                            textAlignment: TextAlignment.RIGHT,
                        },
                        action: {
                            value: (
                                <div>
                                    {invoice.status === InvoiceStatus.OPEN && (
                                        <Action
                                            key={`invoice-${invoice.id}`}
                                            id={`invoice-${invoice.id}`}
                                            IconElement={Invoice}
                                            tooltip="Pay Invoice"
                                            onClick={() => {
                                                setSelectedInvoice(invoice)
                                                setShowPayModal(true)
                                            }}
                                        />
                                    )}
                                    {invoice.invoice_uploaded && (
                                        <Action
                                            key={`invoice-download-${invoice.id}`}
                                            id={`invoice-download-${invoice.id}`}
                                            IconElement={DownloadSimple}
                                            tooltip="Download Invoice"
                                            onClick={() => {
                                                handleInvoiceDownload(invoice.id)
                                            }}
                                        />
                                    )}
                                </div>
                            ),
                            textAlignment: TextAlignment.CENTRE,
                        },
                    }
                }),
            )
            setIsLoading(false)
        } catch (err) {
            setErrMsg(
                'There was a problem loading your invoices. Please refresh the page to try again.',
            )
            setIsLoading(false)
            Sentry.captureException(err)
        }
    }

    useEffect(() => {
        getInvoices()
    }, [])

    const columns: Column<Columns>[] = [
        {
            name: 'id',
            display: 'Invoice',
        },
        {
            name: 'status',
            display: 'Status',
        },
        {
            name: 'month',
            display: 'Month',
        },
        {
            name: 'month_index',
            display: 'Month Invoice Number',
        },
        {
            name: 'item_count',
            display: 'Line Items',
        },
        {
            name: 'total',
            display: 'Total',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'action',
            display: 'Action',
            textAlignment: TextAlignment.CENTRE,
        },
    ]

    return (
        <>
            {isLoading && <LoadingSpinner></LoadingSpinner>}
            {errMsg && <Note type={NoteType.ERROR}>{errMsg}</Note>}
            {invoiceData.length > 0 && !isLoading && !errMsg && (
                <>
                    <Table columns={columns} data={invoiceData} />
                    <DownloadFromUrl
                        triggerDownload={triggerDownload}
                        url={signedUrl}
                        filename={filename}
                    />
                    <PayInvoiceModal
                        invoice={selectedInvoice!}
                        show={showPayModal && !!selectedInvoice}
                        onClose={(success) => {
                            setShowPayModal(false)
                            setSelectedInvoice(undefined)
                            if (success) {
                                getInvoices()
                            }
                        }}
                    />
                </>
            )}
            {invoiceData.length === 0 && !hideNoResults && !isLoading && !errMsg && (
                <Note type={NoteType.INFO}>No invoices found</Note>
            )}
        </>
    )
}

export default InvoicesTable
