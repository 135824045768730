import { useNavigate, useParams } from 'react-router-dom'
import AdminLayout from '../../modules/common/components/AdminLayout'
import { AdminSubMenuActions } from '../../modules/common/enums/AdminSubMenuActions'
import { useEffect, useState } from 'react'
import { User } from '@sentry/react'
import { AdminUserApi } from '../../modules/user/http/AdminUserApi'
import { Page } from '../../../modules/common/enums/Pages'
import LoadingSpinner from '../../../modules/loadingSpinner/components/LoadingSpinner'

const EditUser = (): JSX.Element => {
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()
    const [user, setUser] = useState<Partial<User> | null>(null)
    const [showSpinner, setShowSpinner] = useState<boolean>(false)

    const getUser = async (): Promise<void> => {
        try {
            if (id) {
                setShowSpinner(true)
                const response = await AdminUserApi.getById(id)
                if (response) {
                    setUser(response.data)
                }
            }
        } catch {
        } finally {
            setShowSpinner(false)
        }
    }

    useEffect(() => {
        void getUser()
    }, [])

    const onSubmit = async (e: any): Promise<void> => {
        e.preventDefault()
        if (id) {
            try {
                setShowSpinner(true)
                await AdminUserApi.patch(id, {
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                    email: user?.email,
                })
                navigate(Page.ADMIN_USERS)
            } finally {
                setShowSpinner(false)
            }
        }
    }

    return (
        <AdminLayout selectedItem={AdminSubMenuActions.USERS}>
            <form onSubmit={onSubmit}>
                <div className="mb-6 text-left">
                    <label className="text-sm font-semibold text-gray-700">ID:</label>
                    <p>{user?.id}</p>
                </div>
                <div className="mb-6 text-left">
                    <label className="text-sm font-semibold text-gray-700">Tenant:</label>
                    <p>{user?.tenant?.entity_name}</p>
                </div>
                <div className="mb-6 text-left flex flex-col">
                    <label className="text-sm font-semibold text-gray-700">First Name:</label>
                    <input
                        type="text"
                        value={user?.first_name ?? ''}
                        onChange={(e) => {
                            setUser({
                                ...user,
                                first_name: e.target.value,
                            })
                        }}
                        className="block p-2 mt-1.5 w-full sm:w-1/2 text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-6 text-left flex flex-col">
                    <label className="text-sm font-semibold text-gray-700">Last Name:</label>
                    <input
                        type="text"
                        value={user?.last_name ?? ''}
                        onChange={(e) => {
                            setUser({
                                ...user,
                                last_name: e.target.value,
                            })
                        }}
                        className="block p-2 mt-1.5 w-full sm:w-1/2 text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-6 text-left flex flex-col">
                    <label className="text-sm font-semibold text-gray-700">Email:</label>
                    <input
                        type="text"
                        value={user?.email ?? ''}
                        onChange={(e) => {
                            setUser({
                                ...user,
                                email: e.target.value,
                            })
                        }}
                        className="block p-2 mt-1.5 w-full sm:w-1/2 text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="flex items-center pt-6 space-x-2 border-t border-gray-200 rounded-b">
                    <input
                        type="submit"
                        value="Save"
                        className={`cursor-pointer text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                    />
                </div>
                {showSpinner && (
                    <div className="absolute flex justify-center w-full top-20">
                        <LoadingSpinner className="h-10 w-10" />
                    </div>
                )}
            </form>
        </AdminLayout>
    )
}

export default EditUser
