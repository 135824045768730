import { useEffect, useState } from 'react'
import Table, { Column, TextAlignment, Values } from '../../table/components/Table'
import DateTimeFormatted from '../../dateTime/components/DateTimeFormatted'
import ProjectName from '../../project/components/ProjectName'
import {
    convertCentsToLocaleUsd,
    convertGramsToMetricTonesLocaleString,
} from '../../../utils/conversion'
import { IliDto } from '../http/types/InvoiceDto'
import ID from '../../common/components/ID'

type Columns =
    | 'id'
    | 'trade'
    | 'date'
    | 'buy_order'
    | 'project_name'
    | 'vintage'
    | 'price'
    | 'quantity'
    | 'total'

interface Props {
    ilis: IliDto[]
}

const IlisTable = ({ ilis }: Props): JSX.Element => {
    const [iliData, setIliData] = useState<Values<Columns>[]>([])

    useEffect(() => {
        setIliData(
            ilis.map((ili) => {
                return {
                    id: {
                        value: <ID>{ili.id}</ID>,
                    },
                    trade: {
                        value: <ID>{ili.trade_id}</ID>,
                    },
                    date: {
                        value: <DateTimeFormatted date={new Date(ili.created_at)} />,
                    },
                    buy_order: {
                        value: <ID link={`/buy-order/${ili.buy_order_id}`}>{ili.buy_order_id}</ID>,
                    },
                    project_name: {
                        value: (
                            <ProjectName
                                squareImageUrl={ili.project_data.square_image_url}
                                type={ili.project_data.type}
                                name={ili.project_data.name}
                                id={ili.project_data.id}
                                sellOrderId={ili.sell_order_id}
                            />
                        ),
                    },
                    vintage: {
                        value: ili.vintage,
                    },
                    price: {
                        value: (
                            <div className="font-bold">
                                {convertCentsToLocaleUsd(ili.gross_unit_price)}
                            </div>
                        ),
                        textAlignment: TextAlignment.RIGHT,
                    },
                    quantity: {
                        value: convertGramsToMetricTonesLocaleString(Number(ili.quantity)),
                        textAlignment: TextAlignment.RIGHT,
                    },
                    total: {
                        value: (
                            <div className="font-bold">
                                {convertCentsToLocaleUsd(ili.gross_total_cost)}
                            </div>
                        ),
                        textAlignment: TextAlignment.RIGHT,
                    },
                }
            }),
        )
    }, [ilis])

    const columns: Column<Columns>[] = [
        {
            name: 'id',
            display: 'Line Item',
        },
        {
            name: 'date',
            display: 'Date',
        },
        {
            name: 'trade',
            display: 'Trade',
        },
        {
            name: 'buy_order',
            display: 'Buy Order',
        },
        {
            name: 'project_name',
            display: 'Project',
        },
        {
            name: 'vintage',
            display: 'Vintage',
        },
        {
            name: 'price',
            display: 'Unit Price',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'quantity',
            display: 'Quantity',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'total',
            display: 'Total',
            textAlignment: TextAlignment.RIGHT,
        },
    ]

    return <Table columns={columns} data={iliData} />
}

export default IlisTable
