import { SetIsAcceptCookiesBannerVisible, SET_IS_ACCEPT_COOKIES_BANNER_VISIBLE } from './types'
import { Dispatch } from 'react'

export const setIsAcceptCookiesBannerVisibleAction = (
    isVisible: boolean,
    dispatch: Dispatch<SetIsAcceptCookiesBannerVisible>,
): void => {
    dispatch({
        type: SET_IS_ACCEPT_COOKIES_BANNER_VISIBLE,
        payload: { isVisible },
    })
}
