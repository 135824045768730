import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { GetPayoutsWithStatsResponse } from './types/GetPayoutsWithStatsResponse'
import { CreatePayoutResponse } from './types/CreatePayoutResponse'
import { PayoutWithTradesDto } from './types/PayoutWithTradesDto'

export interface PayoutApiInterface {
    getPayoutsWithStats(): Promise<AxiosResponse<GetPayoutsWithStatsResponse>>
    create(): Promise<AxiosResponse<CreatePayoutResponse> | undefined>
    getById(id: string): Promise<AxiosResponse<PayoutWithTradesDto>>
}

export const PayoutApi: PayoutApiInterface = Object.freeze({
    getPayoutsWithStats(): Promise<AxiosResponse<GetPayoutsWithStatsResponse>> {
        return HttpService.get('/payouts')
    },
    create(): Promise<AxiosResponse<CreatePayoutResponse> | undefined> {
        return HttpService.post(`/payouts`, {})
    },
    getById(id: string): Promise<AxiosResponse<PayoutWithTradesDto>> {
        return HttpService.get('/payouts', id)
    },
})
