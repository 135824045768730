import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { SearchBuyOrdersResponseDto } from './types/SearchBuyOrdersResponse'
import { BuyOrderDto } from './types/BuyOrderDto'
import { SearchBuyOrderParams } from './types/SearchBuyOrderParams'

export interface BuyOrderApiInterface {
    search(params?: SearchBuyOrderParams): Promise<AxiosResponse<SearchBuyOrdersResponseDto>>
    getById(id: string): Promise<AxiosResponse<BuyOrderDto>>
    cancel(id: string): Promise<AxiosResponse<void> | undefined>
}

export const BuyOrderApi: BuyOrderApiInterface = Object.freeze({
    search(params: SearchBuyOrderParams = {}): Promise<AxiosResponse<SearchBuyOrdersResponseDto>> {
        return HttpService.get('/buy-order', null, params)
    },
    getById(id: string): Promise<AxiosResponse<BuyOrderDto>> {
        return HttpService.get(`/buy-order/${id}`)
    },
    cancel(id: string): Promise<AxiosResponse<void> | undefined> {
        return HttpService.post(`/buy-order/cancel/${id}`, {})
    },
})
