import { Modal, ModalSizes } from 'flowbite-react'
import { useRef, useEffect, useState } from 'react'
import { convertGramsToMetricTonnes } from '../../../utils/conversion'
import { ActionName } from '../../action/enums/ActionName'
import { Vintage } from '../../vintage/types/Vintage'
import CreditsConfirmation from '../../credits/components/CreditsConfirmation'
import { AdminSearchBuyOrder } from '../../../adminArea/modules/buyOrder/http/types/BuyOrder'
import { Project } from '../../project/types/Project'
import { CarbonCreditBalanceSingleResult } from '../../carbonCreditBalance/types/CarbonCreditBalance'

interface CreditsConfirmationModalProps {
    question?: string | JSX.Element
    show: boolean
    actionName: ActionName
    buyOrder: AdminSearchBuyOrder
    project: Project | CarbonCreditBalanceSingleResult
    vintage?: Vintage
    modalSize?: keyof ModalSizes
    subtitle?: JSX.Element
    onBack?: () => void
    onClose: () => void
    onConfirm: () => Promise<void>
}

const CreditsConfirmationModal = ({
    show,
    question,
    actionName,
    buyOrder,
    project,
    vintage,
    modalSize = '7xl',
    subtitle,
    onBack,
    onClose,
    onConfirm,
}: CreditsConfirmationModalProps): JSX.Element => {
    const [quantity, setQuantity] = useState<number>(0)
    // const [vintage, setVintage] = useState<Vintage | undefined>(undefined)
    const [pricePerCredit, setPricePerCredit] = useState<number>(0)
    const [price, setPrice] = useState<number>(0)
    const documentBodyRef = useRef(null)
    useEffect(() => {
        // @ts-ignore
        documentBodyRef.current = document.body
    })

    useEffect(() => {
        if (!buyOrder) return
        setQuantity(Number(buyOrder.trades[0].quantity ?? '0'))
        setPricePerCredit(Number(buyOrder.trades[0].gross_unit_price))
    }, [buyOrder])

    useEffect(() => {
        if (!pricePerCredit || !quantity) return
        setPrice(pricePerCredit * convertGramsToMetricTonnes(quantity))
    }, [pricePerCredit, quantity])
    return (
        <Modal
            show={show}
            size={modalSize}
            id="modal"
            // @ts-ignore
            root={documentBodyRef.current}
        >
            {!!buyOrder && (
                <>
                    <div className="w-full md:w-[90%] my-4 pt-8 h-full mx-auto overflow-y-scroll scrollbar-hide">
                        {question}
                        <CreditsConfirmation
                            actionName={actionName}
                            showSpinner={false}
                            price={price}
                            quantity={quantity}
                            vintage={vintage}
                            project={project}
                            pricePerCredit={String(pricePerCredit / 100)}
                            pricePerCreditCents={pricePerCredit / 100}
                            onClose={onClose}
                            onBack={onBack}
                            onConfirm={onConfirm}
                            retireeName={undefined}
                            subtitle={subtitle}
                        />
                    </div>
                </>
            )}
        </Modal>
    )
}

export default CreditsConfirmationModal
