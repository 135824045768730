import { AxiosResponse } from 'axios'
import { Modal } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AdminProjectApi } from '../http/api'
import { useAdminStore } from '../../../../modules/admin/context/store'
import CheckBoxWrapper from '../../../../modules/common/components/CheckBoxWrapper'
import { ImageFileType } from '../../../../modules/common/enums/ImageFileType'
import SdgLogo from '../../../../modules/project/components/SdgLogo'
import { ProjectLocation } from '../../../../modules/project/enums/ProjectLocation'
import { ProjectType } from '../../../../modules/project/enums/ProjectType'
import { SDG } from '../../../../modules/project/enums/SDG'
import { useRegistryStore } from '../../../../modules/registry/context/store'
import { Registry } from '../../../../modules/registry/types/Registry'
import { getCountryNameFromCountryCode } from '../../../../utils/getCountryNameFromCountryCode'
import UploadFileForm, { UploadFileTypes } from '../../common/components/UploadFileForm'
import { Project } from '../../../../modules/project/types/Project'
import { ProjectImageBusinessType } from '../enums/ProjectImageBusinessType'
import HttpService from '../../../../modules/http/httpService'

export interface CreateProjectFields {
    name: string
    description: string
    type: ProjectType
    location: ProjectLocation
    registry_id: string
    registry_url: string
    registry_project_id: string
    sdgs: []
    bridge_project_id: string
}

export interface EditProjectFormProps {
    projectData: Project
    onProjectSaved: () => void
}

const EditProjectForm = ({ onProjectSaved, projectData }: EditProjectFormProps): JSX.Element => {
    const [error, setError] = useState<string>('')
    const [bannerLogo, setBannerLogo] = useState<File>()
    const [squareLogo, setSquareLogo] = useState<File>()
    const [uploadFileModal, setUploadFileModal] = useState<ProjectImageBusinessType | null>(null)
    const { register, handleSubmit } = useForm<CreateProjectFields>()
    const { fetchRegistries, registries } = useRegistryStore()
    const { setShowAlertMessage } = useAdminStore()

    useEffect(() => {
        fetchRegistries()
    }, [])

    const uploadImageHandler = async (
        file: File,
        imageType: ProjectImageBusinessType,
        url: string,
    ): Promise<AxiosResponse<any, any> | void> => {
        if (url) {
            try {
                await HttpService.put(
                    url,
                    file,
                    {
                        headers: {
                            'Content-Type': file.type,
                        },
                    },
                    false,
                )
                return await AdminProjectApi.confirmUpload(
                    projectData.id,
                    imageType,
                    file.type.split('/')[1] as ImageFileType,
                )
            } catch (e: any) {
                setShowAlertMessage({
                    text: e.response?.data?.message || 'An error occurred',
                    color: 'failure',
                })
            }
        }
        return
    }

    const onUploadPressed = async (file: File) => {
        try {
            setError('')
            if (!uploadFileModal) {
                setError('No valid file type selected')
                return
            }
            if (!file) {
                setError(`Please provide the ${uploadFileModal} for the project.`)
                return
            }
            if (uploadFileModal === ProjectImageBusinessType.BANNER) {
                setBannerLogo(file)
            } else if (uploadFileModal === ProjectImageBusinessType.SQUARE) {
                setSquareLogo(file)
            }
            const signedUrlResponse = await AdminProjectApi.generateSignedUrl(
                projectData.id,
                uploadFileModal,
                file.type.split('/')[1] as ImageFileType,
            )
            if (!signedUrlResponse || signedUrlResponse.status !== 200) {
                setError(`Error getting url to upload the ${uploadFileModal} for the project.`)
                return
            }
            const response = await uploadImageHandler(
                file,
                uploadFileModal,
                signedUrlResponse.data.url,
            )
            if (!response || response.status !== 200) {
                setError(`Error uploading the ${uploadFileModal} for the project.`)
                return
            }

            if (response?.status === 200) {
                setShowAlertMessage({
                    text: `Image for project ${projectData.name} (${projectData.id}) updated successfully.`,
                    color: 'success',
                })
            }
            setUploadFileModal(null)
        } catch (err) {
            setShowAlertMessage({
                text: `Error reading file: ${err}`,
                color: 'failure',
            })
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onProjectSaved)}>
                <div className="mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Name
                    </label>
                    <input
                        type="text"
                        placeholder="Name"
                        {...register('name', {
                            required: true,
                        })}
                        value={projectData.name}
                        disabled={true}
                        className="block p-4 mb-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                </div>
                <div className="flex flex-row gap-4 justify-between">
                    <div className="w-1/3 mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Project type
                        </label>
                        <select
                            id="small"
                            className="block p-2 mb-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            disabled={true}
                            {...register('type', {
                                required: true,
                                validate: (type: ProjectType | 'Choose') => {
                                    return type !== 'Choose'
                                },
                            })}
                            defaultValue={projectData.type}
                        >
                            <option>Choose</option>
                            {(Object.keys(ProjectType) as Array<keyof typeof ProjectType>).map(
                                (key) => (
                                    <option key={key} value={ProjectType[key]}>
                                        {ProjectType[key]}
                                    </option>
                                ),
                            )}
                        </select>
                    </div>
                    <div className="w-1/3 mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Registry
                        </label>
                        <select
                            id="small"
                            disabled={true}
                            value={projectData.registry.id}
                            className="block p-2 mb-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            {...register('registry_id', {
                                required: true,
                                validate: (type: string) => {
                                    return type !== 'Choose'
                                },
                            })}
                        >
                            <option>Choose</option>
                            {registries.map((registry: Registry) => (
                                <option key={registry.id} value={registry.id}>
                                    {registry.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="w-1/3 mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Location
                        </label>
                        <select
                            id="small"
                            disabled={true}
                            className="block p-2 mb-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            {...register('location', {
                                required: true,
                                validate: (type: ProjectLocation | 'Choose') => {
                                    return type !== 'Choose'
                                },
                            })}
                            defaultValue={projectData.location}
                        >
                            <option>Choose</option>
                            {(
                                Object.keys(ProjectLocation) as Array<keyof typeof ProjectLocation>
                            ).map((key) => (
                                <option key={key} value={ProjectLocation[key]}>
                                    {getCountryNameFromCountryCode(ProjectLocation[key])}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Description
                    </label>
                    <textarea
                        rows={5}
                        id="large-input"
                        disabled={true}
                        value={projectData.description}
                        placeholder="Desription"
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        {...register('description', {
                            required: false,
                        })}
                    />
                </div>
                <div className="flex gap-4">
                    <div className="mb-6 text-left w-1/2">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Registry url
                        </label>
                        <input
                            type="text"
                            disabled={true}
                            value={projectData.registry_url}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Registry url"
                            {...register('registry_url', {
                                required: false,
                            })}
                        />
                    </div>
                    <div className="mb-6 text-left w-1/2">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Registry project id
                        </label>
                        <input
                            type="text"
                            disabled={true}
                            value={projectData?.registry_id ?? ''}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Registry Project id"
                            {...register('registry_project_id', {
                                required: false,
                            })}
                        />
                    </div>
                </div>
                <div className="mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        SDGs
                    </label>
                    <div>
                        <div className="flex flex-wrap mt-4">
                            {(Object.keys(SDG) as Array<keyof typeof SDG>).map((key) => {
                                return (
                                    <div className="w-1/4" key={key}>
                                        <CheckBoxWrapper
                                            key={key}
                                            disabled={true}
                                            checked={projectData.sdgs.includes(SDG[key])}
                                            label={
                                                <div className="flex">
                                                    <SdgLogo sdg={SDG[key]} />
                                                    {SDG[key]}
                                                </div>
                                            }
                                            onChange={() => {}}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="flex gap-4">
                    <div className="mb-6 text-left w-1/2">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Bridge project id
                        </label>
                        <input
                            type="text"
                            className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            disabled={true}
                            value={projectData.bridge_project_id ?? ''}
                            placeholder="Bridge project id"
                            {...register('bridge_project_id', {
                                required: true,
                            })}
                        />
                    </div>
                    <div className="mb-6 text-left w-1/2">
                        <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Update project images
                        </label>
                        <div className="flex">
                            <div className="flex flex-col">
                                {bannerLogo ? (
                                    <button
                                        title="Edit Project"
                                        className="border border-gray-200 rounded-lg font-semibold bg-white text-gray-600 hover:bg-gray-100 cursor-pointer mt-1 px-2.5 py-1"
                                        onClick={() =>
                                            setUploadFileModal(ProjectImageBusinessType.BANNER)
                                        }
                                    >
                                        Uploaded file: {bannerLogo.name}
                                    </button>
                                ) : (
                                    <button
                                        title="Edit Project"
                                        className="border border-gray-200 rounded-lg font-semibold bg-white text-gray-600 hover:bg-gray-100 cursor-pointer mt-1 px-2.5 py-1"
                                        onClick={() =>
                                            setUploadFileModal(ProjectImageBusinessType.BANNER)
                                        }
                                    >
                                        Update Banner
                                    </button>
                                )}
                                <div className="p-2">Ratio: 1024 x 320</div>
                            </div>

                            <div className="flex flex-col ml-2">
                                {squareLogo ? (
                                    <button
                                        title="Edit Project"
                                        className="border border-gray-200 rounded-lg font-semibold bg-white text-gray-600 hover:bg-gray-100 cursor-pointer mt-1 px-2.5 py-1"
                                        onClick={() =>
                                            setUploadFileModal(ProjectImageBusinessType.SQUARE)
                                        }
                                    >
                                        Uploaded file: {squareLogo.name}
                                    </button>
                                ) : (
                                    <button
                                        title="Edit Project"
                                        className="border border-gray-200 rounded-lg font-semibold bg-white text-gray-600 hover:bg-gray-100 cursor-pointer mt-1 px-2.5 py-1"
                                        onClick={() =>
                                            setUploadFileModal(ProjectImageBusinessType.SQUARE)
                                        }
                                    >
                                        Update Square
                                    </button>
                                )}

                                <div className="p-2">Ratio: 354 x 354</div>
                            </div>
                        </div>
                        <div className="h-5">
                            <p className="text-red-400">{error}</p>
                        </div>
                    </div>
                </div>
                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button
                        data-modal-toggle="defaultModal"
                        // disabled={!!error}
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        // onClick={onProjectEditSubmitHandler}
                        disabled={true}
                    >
                        Save
                    </button>
                </div>
            </form>
            <Modal show={uploadFileModal !== null} size="md" id="modal">
                <UploadFileForm
                    onFileSubmitted={onUploadPressed}
                    onClose={() => setUploadFileModal(null)}
                    message="Upload File (only JPG or PNG format are supported)"
                    fileTypes={[UploadFileTypes.PNG, UploadFileTypes.JPEG]}
                />
            </Modal>
        </>
    )
}

export default EditProjectForm
