// @ts-nocheck
import { SearchCarbonCreditBalancesResponse } from '../../api/types/SearchCarbonCreditBalancesResponse'
import {
    setIsCarbonCreditFiltersModalVisibleAction,
    setSearchResponseAction,
    setSelectedFiltersAction,
    setShowAllLocationsAction,
    setShowAllSdgsAction,
    setProjectTypeFilterVisibleAction,
    setIsFormDisabledAction,
} from './actions'
import {
    carbonCreditFiltersReducer,
    initialCarbonCreditFiltersStoreState,
    SelectedFilters,
} from './reducer'
import { createContext, useContext, useReducer } from 'react'

export const CarbonCreditFiltersStore = createContext(initialCarbonCreditFiltersStoreState)

export const useCarbonCreditFiltersStore = (): any => useContext(CarbonCreditFiltersStore)

export const CarbonCreditFiltersStoreProvider = ({ children }: { children: any }): any => {
    const [state, dispatch] = useReducer(
        carbonCreditFiltersReducer,
        initialCarbonCreditFiltersStoreState,
    )

    const setIsCarbonCreditFiltersModalVisible = (isFiltersModalVisible: boolean) => {
        setIsCarbonCreditFiltersModalVisibleAction(isFiltersModalVisible, dispatch)
    }

    const setShowAllLocations = (show: boolean) => {
        setShowAllLocationsAction(show, dispatch)
    }

    const setShowAllSdgs = (show: boolean) => {
        setShowAllSdgsAction(show, dispatch)
    }

    const setSelectedFilters = (filters: SelectedFilters) => {
        setSelectedFiltersAction(filters, dispatch)
    }

    const setSearchResults = (response: SearchCarbonCreditBalancesResponse) => {
        setSearchResponseAction(response, dispatch)
    }

    const setProjectTypeFilterVisible = (show: boolean) => {
        setProjectTypeFilterVisibleAction(show, dispatch)
    }

    const setIsFormDisabled = (isDisabled: boolean) => {
        setIsFormDisabledAction(isDisabled, dispatch)
    }

    return (
        <CarbonCreditFiltersStore.Provider
            value={{
                state,
                setIsCarbonCreditFiltersModalVisible,
                setShowAllLocations,
                setShowAllSdgs,
                setSelectedFilters,
                setSearchResults,
                setProjectTypeFilterVisible,
                setIsFormDisabled,
            }}
        >
            {children}
        </CarbonCreditFiltersStore.Provider>
    )
}
