import { useEffect, useState } from 'react'
import LoadingWrapper from '../../loadingWrapper/components/LoadingWrapper'
import Table, { Column, TextAlignment, Values } from '../../table/components/Table'
import ProjectName from '../../project/components/ProjectName'
import DateTime from '../../dateTime/components/DateTimeFormatted'
import {
    convertCentsToLocaleUsd,
    convertGramsToMetricTonesLocaleString,
} from '../../../utils/conversion'
import Note, { NoteType } from '../../note/components/Note'
import { TradeHistoryApi } from '../http/api'
import { TradeHistory } from '../http/types/TradeHistoryAllOrdersResponse'
import { TradeHistoryTypes } from '../enums/TradeHistoryTypes'
import TradeHsitoryTypePill from './TradeHsitoryTypePill'
import TradeHistoryStatusPill from './TradeHistoryStatusPill'
import { BuyOrderStatus } from '../../buyOrders/http/enums/BuyOrderStatus'
import ID from '../../common/components/ID'

interface Props {
    sellOnly?: boolean
    sellOrderId?: string
    projectId?: string
    displayHeading?: boolean
    hideNoResults?: boolean
}

type Columns =
    | 'id'
    | 'status'
    | 'buy_sell'
    | 'date'
    | 'project_name'
    | 'vintage'
    | 'quantity'
    | 'price'
    | 'order_id'
    | 'total'

const TradeHistoryTable = ({
    sellOnly = false,
    sellOrderId,
    projectId,
    displayHeading = false,
    hideNoResults = false,
}: Props): JSX.Element => {
    const [tableData, setTableData] = useState<Values<Columns>[]>([])
    const [trades, setTrades] = useState<TradeHistory[]>([])

    const getSellOrders = async (): Promise<void> => {
        const response = await TradeHistoryApi.getAllOrders({
            sell_order_id: sellOrderId,
            project_id: projectId,
        })
        if (response) {
            setTrades(
                response.data.trade_history.filter((trade) => {
                    return !(
                        trade.trade_type === TradeHistoryTypes.SELL &&
                        (!trade.status || trade.status === BuyOrderStatus.CANCELLED)
                    )
                }),
            )
        }
    }

    useEffect(() => {
        let data: Values<Columns>[] = trades.map((trade) => {
            const project = trade.project
            return {
                id: {
                    value: <ID>{trade.id}</ID>,
                },
                status: {
                    value: <TradeHistoryStatusPill status={trade.status} />,
                },
                buy_sell: {
                    value: <TradeHsitoryTypePill type={trade.trade_type} width="w-[70px]" />,
                },
                date: {
                    value: (
                        <DateTime
                            monthAndYearOnly={false}
                            date={new Date(trade.created_at!)}
                        ></DateTime>
                    ),
                    className: 'w-[120px]',
                },
                project_name: {
                    value: (
                        <ProjectName
                            squareImageUrl={project.square_image_url}
                            type={project.type}
                            name={project.name}
                            id={project.id}
                            sellOrderId={trade.sell_order_id}
                        />
                    ),
                },
                vintage: {
                    value: trade.vintage_year,
                    textAlignment: TextAlignment.RIGHT,
                },
                quantity: {
                    value: convertGramsToMetricTonesLocaleString(Number(trade.quantity)),
                    textAlignment: TextAlignment.RIGHT,
                },
                price: {
                    value: <div className="font-bold">{convertCentsToLocaleUsd(trade.price)}</div>,
                    textAlignment: TextAlignment.RIGHT,
                },
                total: {
                    value: (
                        <div className="font-bold">
                            {convertCentsToLocaleUsd(trade.total_price)}
                        </div>
                    ),
                    textAlignment: TextAlignment.RIGHT,
                },
                order_id: {
                    value:
                        trade.trade_type === TradeHistoryTypes.BUY ? (
                            <ID link={`/buy-order/${trade.buy_order_id}`}>{trade.buy_order_id}</ID>
                        ) : (
                            <ID link={`/sell-order/${trade.sell_order_id}`}>
                                {trade.sell_order_id}
                            </ID>
                        ),
                },
            }
        })
        setTableData(data)
    }, [trades])

    const columns: Column<Partial<Columns>>[] = [
        {
            name: 'id',
            display: 'Trade',
        },
        {
            name: 'status',
            display: 'Status',
        },
        {
            name: 'date',
            display: 'Date',
        },
        ...(sellOnly
            ? []
            : ([
                  {
                      name: 'buy_sell',
                      display: 'Buy/Sell',
                  },
              ] as Column<Columns>[])),
        {
            name: 'order_id',
            display: 'Order',
        },
        {
            name: 'project_name',
            display: 'Project',
        },
        {
            name: 'vintage',
            display: 'Vintage',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'price',
            display: 'Price',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'quantity',
            display: 'Quantity',
            textAlignment: TextAlignment.RIGHT,
        },
        {
            name: 'total',
            display: 'Total Price',
            textAlignment: TextAlignment.RIGHT,
        },
    ]
    const heading: React.ReactNode = (
        <h3 className="text-gray-900 font-semibold text-xl mb-2">Trade History</h3>
    )
    return (
        <LoadingWrapper waitFor={getSellOrders} errMsg="Error loading Trades">
            {tableData.length > 0 && (
                <>
                    {displayHeading && heading}
                    <Table columns={columns} data={tableData} />
                </>
            )}
            {tableData.length === 0 && !hideNoResults && (
                <Note type={NoteType.INFO}>No trades found</Note>
            )}
        </LoadingWrapper>
    )
}

export default TradeHistoryTable
