import { AxiosResponse } from 'axios'
import HttpService from '../../http/httpService'
import { SignupRequest } from './types/SignupRequest'
import { EmptyResponse } from '../../http/types/EmptyResponse'
import { AskUsRequest } from './types/AskUsRequest'

export interface API {
    signup(payload: SignupRequest): Promise<AxiosResponse<EmptyResponse> | undefined>
    askUs(payload: AskUsRequest): Promise<AxiosResponse<EmptyResponse> | undefined>
}

export const FormApi: API = Object.freeze({
    signup(payload: SignupRequest): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('/form/signup', payload, {}, false)
    },
    askUs(payload: AskUsRequest): Promise<AxiosResponse<EmptyResponse> | undefined> {
        return HttpService.post('/form/askus', payload, {}, true)
    },
})
